import { endpoints, replaceParams } from '@utils/axios';
import React, { useCallback, useEffect } from 'react';
import { BranchList, Convert } from '../types/BranchList';
import useAxios from '@routes/hooks/use-axios';
import { useNavigate } from 'react-router-dom';
import { paths } from '@routes/paths';
import { OutlinedButton } from '@components/button';
import { ButtonVariant } from '@components/button/outline-button';

function BranchTable({
  branches,
  searchQuery,
  viewBranchDetails,
}: {
  branches: BranchList[];
  searchQuery: string;
  viewBranchDetails: (
    branchId: string,
    branchName: string,
    entityId: string,
  ) => void;
}) {
  const filteredBranch = branches.filter((branch: BranchList) =>
    branch.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-2 w-full hidden xl:block">
        <div className="sm:flex sm:items-center"></div>
        <div className="mt-2 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Branch Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Branch Admin Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Branch Admin Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Branch Admin Phone
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredBranch.map(branch => (
                    <tr key={branch.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        <div className="flex items-center">
                          <span>{branch.name}</span>
                          {branch.isPrimary && (
                            <div className="bg-success-400 text-success-700 rounded-full py-1 px-4 ml-2">
                              Primary
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {branch.adminSalutation}. {branch.adminFirstName}{' '}
                        {branch.adminLastName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {branch.email}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {branch.phone}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <OutlinedButton
                          variant={ButtonVariant.SECONDARY}
                          type="button"
                          onClick={() =>
                            viewBranchDetails(
                              branch.id,
                              branch.name,
                              branch.entityId,
                            )
                          }
                          children="View"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="block xl:hidden w-full">
        <ul className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4 bg-gray-50 -mx-8 p-8 ">
          {filteredBranch.map(branch => (
            <div className="flex flex-col justify-between rounded-2xl shadow-md bg-white overflow-hidden">
              <div className="flex flex-row rounded-xl bg-white-300 items-center justify-between p-4">
                <div className="flex flex-col w-11/12">
                  <span className="flex-initial truncate text-md text-black font-bold">
                    {branch.name}
                  </span>

                  <span className="flex-initial truncate  text-md text-black font-bold">
                    {branch.adminSalutation}. {branch.adminFirstName}{' '}
                    {branch.adminLastName}
                  </span>

                  <span className="flex-initial truncate text-secondary-400">
                    {branch.email}
                  </span>
                  <span className="flex-initial text-secondary-400">
                    {branch.phone}
                  </span>
                </div>
              </div>
              <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4 mt-2">
                <div className="flex flex-row w-full justify-between">
                  {branch.isPrimary && (
                    <span className="bg-success-400 text-success-700 rounded-full py-1 px-4 ml-2">
                      Primary
                    </span>
                  )}
                  <OutlinedButton
                    variant={ButtonVariant.SECONDARY}
                    type="button"
                    onClick={() =>
                      viewBranchDetails(branch.id, branch.name, branch.entityId)
                    }
                    children="View"
                  />
                </div>
              </div>
            </div>
          ))}
        </ul>
      </div>
    </>
  );
}

function SearchBar({
  searchQuery,
  onSearch,
}: {
  searchQuery: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <div>
      <input
        type="text"
        value={searchQuery}
        onChange={onSearch}
        placeholder="Search for Branch Name"
        className="w-full px-4 py-2 mb-4 text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500"
      />
    </div>
  );
}

function BranchesComponent(props: { entityId: string }) {
  const [branches, setBranches] = React.useState<BranchList[] | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const axios = useAxios();
  const navigator = useNavigate();
  const [searchQuery, setSearchQuery] = React.useState('');

  const getBranchesList = useCallback(async () => {
    try {
      const paramsMap = new Map<string, string>([['entityId', props.entityId]]);
      const filteredEndpoint = replaceParams(
        endpoints.entity.branches,
        paramsMap,
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data['data'];

      const convertedBranchList = data.map((entity: any) =>
        Convert.toBranchList(JSON.stringify(entity)),
      );
      setBranches(convertedBranchList);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching branch list:', error);
      setError(error.message);
      setLoading(false);
    }
  }, [axios]);

  useEffect(() => {
    getBranchesList();
  }, [getBranchesList]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value);

  const viewBranchDetails = (
    branchId: string,
    branchName: string,
    entityId: string,
  ) => {
    const paramsMap = new Map<string, string>([
      ['branchId', branchId],
      ['branchName', branchName],
      ['entityId', entityId],
    ]);
    navigator(replaceParams(paths.branch.view.route, paramsMap));
  };

  return (
    <>
      <div className="mt-4 px-4 sm:px-6 lg:px-2">
        <SearchBar searchQuery={searchQuery} onSearch={handleSearch} />
      </div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {(!loading && !error && branches?.length == 0) ||
        (branches == null && <p>No branches</p>)}
      {!loading && !error && branches && (
        <BranchTable
          branches={branches}
          searchQuery={searchQuery}
          viewBranchDetails={viewBranchDetails}
        />
      )}
    </>
  );
}

export default BranchesComponent;
