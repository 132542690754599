// To parse this data:
//
//   import { Convert, ActiveExerciseProgram } from "./file";
//
//   const activeExerciseProgram = Convert.toActiveExerciseProgram(json);

export interface ActiveExercise {
  id: string;
  title: string;
  instructions: string;
  config?: Config;
}

export interface Config {
  sets?: number | undefined;
  repetitions?: number | undefined;
  hold?: number | undefined;
  interval?: number | undefined;
  side?: string | undefined;
  weights?: number | undefined;
  weightsUnit?: string | undefined;
  intervalUnit?: string | undefined;
  holdUnit?: string | undefined;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toActiveExerciseProgram(json: string): ActiveExercise {
    return JSON.parse(json);
  }

  public static activeExerciseProgramToJson(value: ActiveExercise): string {
    return JSON.stringify(value);
  }
}
