// To parse this data:
//
//   import { Convert, StaffData } from "./file";
//
//   const staffData = Convert.toStaffData(json);

export interface StaffData {
  id: string;
  entityId: string;
  entityBranchId: string;
  salutation: string;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  birthPlace: string;
  bloodGroup: string;
  dob: string;
  gender: string;
  isActive: boolean;
  address: Address;
  phone: number;
  emergencyPhone: number;
  phoneCode: number;
  timezone?: string;
  emergencyPhoneCode: number;
  audit: StaffDataAudit;
  role: Role;
  workingHours: WorkingHour[];
}

export interface Address {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

export interface StaffDataAudit {
  createdBy: null;
  lastUpdatedBy: null;
  createdAt: Date;
  lastUpdatedAt: Date;
}

export interface Role {
  role: string;
  audit: RoleAudit;
}

export interface RoleAudit {
  assignedBy: string;
  assignedAt: Date;
  lastUpdatedBy: string;
  lastupdatedAt: Date;
}

export interface WorkingHour {
  day: number;
  startTime: string;
  endTime: string;
  startTimeMinutes: number;
  endTimeMinutes: number;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toStaffData(json: string): StaffData {
    return JSON.parse(json);
  }

  public static staffDataToJson(value: StaffData): string {
    return JSON.stringify(value);
  }
}
