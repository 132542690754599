// To parse this data:
//
//   import { Convert, Expense } from "./file";
//
//   const expense = Convert.toExpense(json);

export type Expense = {
  id?: string;
  entityID?: string;
  entityBranchID?: string;
  type?: string;
  date?: Date;
  amount?: string;
  paidTo?: string;
  details?: string;
  createdBy?: string;
  createdDate?: Date;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toExpense(json: string): Expense {
    return JSON.parse(json);
  }

  public static expenseToJson(value: Expense): string {
    return JSON.stringify(value);
  }
}
