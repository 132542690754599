// These Interface are needed for drop down

export interface AssessmentParameterList {
  id: string;
  value: string;
  label: string;
  parameters: ParameterListItem[] | null;
}
export interface ParameterListItem {
  id: string;
  value: string;
  label: string;
}

export interface TypeOfParameter {
  id: string;
  value: string;
  label: string;
}

// JSON to AssessmentParameterList
export function jsonToAssessmentParameterList(
  json: string,
): AssessmentParameterList {
  const data = JSON.parse(json);
  const { id, value, label, parameters } = data;
  const parameterList: ParameterListItem[] | null = parameters
    ? parameters.map((param: any) => ({
        id: param.id,
        value: param.value,
        label: param.label,
      }))
    : null;
  return {
    id,
    value,
    label,
    parameters: parameterList,
  };
}

// AssessmentParameterList to JSON
export function assessmentParameterListToJson(
  parameterList: AssessmentParameterList,
): string {
  const { id, value, label, parameters } = parameterList;
  const params = parameters
    ? parameters.map(param => ({
        id: param.id,
        value: param.value,
        label: param.label,
      }))
    : null;
  return JSON.stringify({ id, value, label, parameters: params });
}
