// To parse this data:
//
//   import { Convert, AssignedExercises } from "./file";
//
//   const assignedExercises = Convert.toAssignedExercises(json);

export interface AssignedExercises {
  id: string;
  title: string;
  description: string;
  instructions: string;
  config: Config;
}

export interface Config {
  sets: number;
  repetitions: number;
  hold: number;
  interval: number;
  side: string;
  weights: number;
  weightsUnit: string;
  intervalUnit: string;
  holdUnit: string;
}

// Converts JSON strings to/from your types
export class ConvertAssignedExercises {
  public static toAssignedExercises(json: string): AssignedExercises {
    return JSON.parse(json);
  }

  public static assignedExercisesToJson(value: AssignedExercises): string {
    return JSON.stringify(value);
  }
}
