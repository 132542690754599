import React from "react";

export type RGBColor = {
  r: number;
  g: number;
  b: number;
};

export type AllColors = {
  [color in keyof typeof defaultColorScheme]: {
    [shade in keyof (typeof defaultColorScheme)[color]]: string;
  };
};

interface ContextType {
  colors: AllColors;
  setColors: React.Dispatch<React.SetStateAction<AllColors>>;
}

export const defaultColorScheme = {
  primaryShades: {
    50: "#EAE5FA",
    100: "#D9CFF6",
    200: "#B3A0EE",
    300: "#8E70E5",
    400: "#6841DD",
    500: "#4B23C2",
    600: "#3C1C9C",
    700: "#2D1575",
    800: "#1E0E4E",
    900: "#0F0727",
  },
  secondaryShades: {
    50: "#F2F7FD",
    100: "#E5EEFB",
    200: "#C1D8F5",
    300: "#9EC1F0",
    400: "#6EA3E8",
    500: "#2B79DD",
    600: "#226FD3",
    700: "#1E61B9",
    800: "#184E95",
    900: "#11376A",
  },
  tertiaryShades: {
    50: "#F7EDF4",
    100: "#EFDCEA",
    200: "#E0B8D5",
    300: "#D298C2",
    400: "#C275AC",
    500: "#B35297",
    600: "#91407A",
    700: "#6E305C",
    800: "#471F3C",
    900: "#23101E",
  },
};

export const CurrentColorContext = React.createContext<ContextType>({
  colors: defaultColorScheme,
  setColors: () => {},
});
