// To parse this data:
//
//   import { Convert, PersonalInformation } from "./file";
//
//   const personalInformation = Convert.toPersonalInformation(json);

export type PersonalInformationDetails = {
    salutation: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    email: string;
    isActive: boolean;
    isOnboarded: boolean;
    phone: number;
    phoneCode: number;
    emergencyPhone: number;
    emergencyPhoneCode: number;
    birthPlace?: string;
    bloodGroup: string;
    address: Address;
    age: number;
    gender: string;
    dob?: string;
    notes?: string;
    profilePictureUrl: string;
    referringHospital: string;
    referringUser: string;
    otherReferral: string;
    whatsAppConsent?: boolean;
};

export type Address = {
    address: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
};

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
    public static toPersonalInformation(
        json: string
    ): PersonalInformationDetails {
        return JSON.parse(json);
    }

    public static personalInformationToJson(
        value: PersonalInformationDetails
    ): string {
        return JSON.stringify(value);
    }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ""): never {
    const prettyTyp = prettyTypeName(typ);
    const parentText = parent ? ` on ${parent}` : "";
    const keyText = key ? ` for key "${key}"` : "";
    throw Error(
        `Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(
            val
        )}`
    );
}

function prettyTypeName(typ: any): string {
    if (Array.isArray(typ)) {
        if (typ.length === 2 && typ[0] === undefined) {
            return `an optional ${prettyTypeName(typ[1])}`;
        } else {
            return `one of [${typ
                .map((a) => {
                    return prettyTypeName(a);
                })
                .join(", ")}]`;
        }
    } else if (typeof typ === "object" && typ.literal !== undefined) {
        return typ.literal;
    } else {
        return typeof typ;
    }
}

function jsonToJSProps(typ: any): any {
    if (typ.jsonToJS === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
        typ.jsonToJS = map;
    }
    return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
    if (typ.jsToJSON === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
        typ.jsToJSON = map;
    }
    return typ.jsToJSON;
}

function transform(
    val: any,
    typ: any,
    getProps: any,
    key: any = "",
    parent: any = ""
): any {
    function transformPrimitive(typ: string, val: any): any {
        if (typeof typ === typeof val) return val;
        return invalidValue(typ, val, key, parent);
    }

    function transformUnion(typs: any[], val: any): any {
        // val must validate against one typ in typs
        const l = typs.length;
        for (let i = 0; i < l; i++) {
            const typ = typs[i];
            try {
                return transform(val, typ, getProps);
            } catch (_) { }
        }
        return invalidValue(typs, val, key, parent);
    }

    function transformEnum(cases: string[], val: any): any {
        if (cases.indexOf(val) !== -1) return val;
        return invalidValue(
            cases.map((a) => {
                return l(a);
            }),
            val,
            key,
            parent
        );
    }

    function transformArray(typ: any, val: any): any {
        // val must be an array with no invalid elements
        if (!Array.isArray(val)) return invalidValue(l("array"), val, key, parent);
        return val.map((el) => transform(el, typ, getProps));
    }

    function transformDate(val: any): any {
        if (val === null) {
            return null;
        }
        const d = new Date(val);
        if (isNaN(d.valueOf())) {
            return invalidValue(l("Date"), val, key, parent);
        }
        return d;
    }

    function transformObject(
        props: { [k: string]: any },
        additional: any,
        val: any
    ): any {
        if (val === null || typeof val !== "object" || Array.isArray(val)) {
            return invalidValue(l(ref || "object"), val, key, parent);
        }
        const result: any = {};
        Object.getOwnPropertyNames(props).forEach((key) => {
            const prop = props[key];
            const v = Object.prototype.hasOwnProperty.call(val, key)
                ? val[key]
                : undefined;
            result[prop.key] = transform(v, prop.typ, getProps, key, ref);
        });
        Object.getOwnPropertyNames(val).forEach((key) => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps, key, ref);
            }
        });
        return result;
    }

    if (typ === "any") return val;
    if (typ === null) {
        if (val === null) return val;
        return invalidValue(typ, val, key, parent);
    }
    if (typ === false) return invalidValue(typ, val, key, parent);
    let ref: any = undefined;
    while (typeof typ === "object" && typ.ref !== undefined) {
        ref = typ.ref;
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ)) return transformEnum(typ, val);
    if (typeof typ === "object") {
        return typ.hasOwnProperty("unionMembers")
            ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty("arrayItems")
                ? transformArray(typ.arrayItems, val)
                : typ.hasOwnProperty("props")
                    ? transformObject(getProps(typ), typ.additional, val)
                    : invalidValue(typ, val, key, parent);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== "number") return transformDate(val);
    return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
    return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
    return transform(val, typ, jsToJSONProps);
}

function l(typ: any) {
    return { literal: typ };
}

function a(typ: any) {
    return { arrayItems: typ };
}

function u(...typs: any[]) {
    return { unionMembers: typs };
}

function o(props: any[], additional: any) {
    return { props, additional };
}

function m(additional: any) {
    return { props: [], additional };
}

function r(name: string) {
    return { ref: name };
}

const typeMap: any = {
    PersonalInformation: o(
        [
            { json: "id", js: "id", typ: u(undefined, "") },
            { json: "entityId", js: "entityId", typ: u(undefined, "") },
            { json: "entityBranchId", js: "entityBranchId", typ: u(undefined, "") },
            { json: "salutation", js: "salutation", typ: u(undefined, "") },
            { json: "firstName", js: "firstName", typ: u(undefined, "") },
            { json: "middleName", js: "middleName", typ: u(undefined, "") },
            { json: "lastName", js: "lastName", typ: u(undefined, "") },
            { json: "email", js: "email", typ: u(undefined, "") },
            { json: "password", js: "password", typ: u(undefined, "") },
            { json: "isActive", js: "isActive", typ: u(undefined, true) },
            { json: "isOnboarded", js: "isOnboarded", typ: u(undefined, true) },
            { json: "phone", js: "phone", typ: u(undefined, 0) },
            { json: "phoneCode", js: "phoneCode", typ: u(undefined, 0) },
            { json: "emergencyPhone", js: "emergencyPhone", typ: u(undefined, 0) },
            {
                json: "emergencyPhoneCode",
                js: "emergencyPhoneCode",
                typ: u(undefined, 0),
            },
            { json: "birthPlace", js: "birthPlace", typ: u(undefined, "") },
            { json: "bloodGroup", js: "bloodGroup", typ: u(undefined, "") },
            { json: "notes", js: "notes", typ: u(undefined, "") },
            { json: "address", js: "address", typ: u(undefined, r("Address")) },
            { json: "age", js: "age", typ: u(undefined, 0) },
            { json: "gender", js: "gender", typ: u(undefined, "") },
            { json: "dob", js: "dob", typ: u(undefined, Date) },
        ],
        false
    ),
    Address: o(
        [
            { json: "address", js: "address", typ: u(undefined, "") },
            { json: "city", js: "city", typ: u(undefined, "") },
            { json: "state", js: "state", typ: u(undefined, "") },
            { json: "country", js: "country", typ: u(undefined, "") },
            { json: "postalCode", js: "postalCode", typ: u(undefined, "") },
        ],
        false
    ),
};
