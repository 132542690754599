import { OutlinedButton } from '@components/button';
import axios from 'axios';
import React from 'react';
import { endpoints, replaceParams } from '@utils/axios';
import { Appointment, Convert as ConvertAppointment, Appointment as PatientAppointment } from '@pages/appointments/types/Appointment';
import moment from 'moment';
import Button, { ButtonVariant } from '@components/button/outline-button';
import SolidButton from '@components/button/button';
import { PersonalInformationDetails } from '@pages/manage-patient/types/PatientPersonalDetails';
import { PatientPaymentDetailsDomain } from '@pages/manage-patient-new/types/PatientPaymentDetailsDomain';
import { Convert } from '@pages/appointments/types/PatientType';

import BillingAndPaymentDetails from '@pages/payments/BillingAndPaymentDetails';

import { Appointments } from '@pages/appointments/types/Appointments';
import AppointmentDetails from '@pages/dashboard/components/AppointmentDetails';

export default function UnbilledAppointments(props: {
  patientId: string;
  patientAppointments: Appointment[];
  totalAppointemnts: number;
}) {

  const [details, setDetails] = React.useState<PatientPaymentDetailsDomain | null>();
  const [patientAppointments, setPatientAppointments] = React.useState<Appointment[] | null>(props.patientAppointments);
  const [totalAppointemnts, setTotalAppointments] = React.useState(0);

  const [showAppointmentDetails, setShowAppointmentDetails] = React.useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = React.useState("");
  const [appointmentDetails, setAppointmentDetails] = React.useState<Appointments | null>(null);
  const [addPaymentDetailsForAppt, setAddPaymentDetailsForAppt] = React.useState(false);

  const confirmPaymentAndChangeStatus = async () => {
    try {
      setAddPaymentDetailsForAppt(false);
      handleStatusChange();
    } catch (error) {
      console.error(error);
    }
  };
  const showDialog = (appId: string) => {
    setSelectedAppointmentId(appId);
    setShowAppointmentDetails(true);
  };

  const handleStatusChange = async () => {

    if (props.patientId) {
      const paramsMap = new Map<string, string>([['id', props.patientId]]);
      const filteredEndpoint = replaceParams(
        endpoints.patient.getUnbilledAppointments,
        paramsMap,
      );
      const response = await axios.get(filteredEndpoint);

      const data = response.data['data'];
      const convertedAppointmentList = data.map((appointment: any) => {
        return ConvertAppointment.toAppointment(JSON.stringify(appointment));
      })

      setTotalAppointments(convertedAppointmentList.length)
      setPatientAppointments(convertedAppointmentList);
    }
  };

  return (
    <div className="flex h-full w-full flex-col ">
      <div className="flex flex-col mr-1">
        <div className="flex flex-row justify-between  px-2">
          <div className="flex font-bold py-2">Completed Unbilled Appointment/Sessions</div>
        </div>
        <div className="mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-left text-sm text-gray-500 rtl:text-right ">
            <thead className="text-xs uppercase text-gray-700 bg-tertiary-100 sticky top-0 z-10">
              <tr>
                <th scope="col" className="px-6 py-3 ">
                  Date/Time
                </th>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Practitioner
                </th>
                <th scope="col" className="px-6 py-3">
                  Bill Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  Payment Details
                </th>
              </tr>
            </thead>
            <tbody>
              {patientAppointments &&
                patientAppointments.map(appt => (
                  <tr className="border-b border-gray-200 dark:border-gray-700">
                    <td scope="row" className="px-6 py-4 ">
                      {moment(new Date(appt.apptdate)).format('DD-MM-YYYY hh:mm a')}
                    </td>
                    <td className="px-6 py-4">{appt.appointmentType}</td>
                    <td className="px-6 py-4 ">{appt.entityUserName}</td>
                    <td className="px-6 py-4">₹{appt.billAmount}</td>
                    <td className="px-6 py-4">
                      <div className="flex flex-row">
                        <div className="flex-shrink mr-2">
                          <OutlinedButton
                            variant={ButtonVariant.SECONDARY}
                            type="button"
                            onClick={() => showDialog(appt.id)}
                            children="View"
                          />
                        </div>
                        {appt.appointmentStatus && appt.appointmentStatus.toLowerCase() === 'completed' && (
                          <div className="flex-shrink">
                            {(appt.paymentStatus === '') && (
                              <OutlinedButton
                                variant={ButtonVariant.SECONDARY}
                                type="button"
                                onClick={() => {
                                  setSelectedAppointmentId(appt.id);
                                  setAppointmentDetails(appt);
                                  setAddPaymentDetailsForAppt(true);
                                }}
                                children="Add Bill amount"
                              />)}
                          </div>
                        )}
                      </div>
                    </td>

                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {addPaymentDetailsForAppt && (
        <BillingAndPaymentDetails
          appointmentDetails={appointmentDetails!}
          onClose={() => setAddPaymentDetailsForAppt(false)}
          onConfirm={confirmPaymentAndChangeStatus}
        />
      )}

      {showAppointmentDetails && (
        <AppointmentDetails
          onClose={() => setShowAppointmentDetails(false)}
          appId={selectedAppointmentId}
        />
      )}
    </div>
  );
}
