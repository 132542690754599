// src/components/DateSelector.tsx

import React, { useEffect, useState } from 'react';

const DateSelector = (props: {
  handleScrollableDateChange: (e: string) => void;
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    // setSelectedDate(new Date(currentDate));
  }, [currentDate]);

  const changeDate = (daysToAdd: number) => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + daysToAdd);
    setCurrentDate(newDate);
  };

  const formatDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const weekday = date.toLocaleDateString('en-IN', { weekday: 'short' });
    return `${weekday} ${day}/${month}`;
  };

  return (
    <div className="">
      <div className="flex items-center justify-center lg:space-x-4 space-x-2">
        <button
          type="button"
          onClick={() => changeDate(-7)}
          className="p-2 text-gray-500 hover:text-gray-700"
        >
          &lt;
        </button>
        {Array.from({ length: 7 }, (_, index) => {
          const date = new Date(currentDate);
          date.setDate(currentDate.getDate() + index - 3);
          return (
            <div
              key={date.toISOString()}
              className={`${
                date.toDateString() === selectedDate.toDateString()
                  ? 'bg-secondary-500 text-white text-center text-xs'
                  : 'bg-secondary-100 text-secondary-700 hover:bg-secondary-300 hover:text-white text-center text-xs'
              } p-2 rounded cursor-pointer transition duration-300 ease-in-out`}
              onClick={e => {
                setSelectedDate(date);
                props.handleScrollableDateChange(
                  new Date(date).toISOString().split('T')[0],
                );
              }}
            >
              {formatDate(date)}
            </div>
          );
        })}
        <button
          type="button"
          onClick={() => changeDate(7)}
          className="p-2 text-secondary-500 hover:text-secondary-700 transition duration-300 ease-in-out"
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default DateSelector;
