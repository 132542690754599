import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Dialog, Switch, Transition } from '@headlessui/react';
import * as z from 'zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AddNewPrescriptionDetails,
  Convert,
} from '../types/AddNewPrescriptionDetails';
import { endpoints, replaceParams } from '@utils/axios';
import axios from 'axios';
import moment from 'moment';
import { InputField, ValidationError } from '@components/hook-form';
import { Button } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import TextAreaField from '@components/hook-form/textarea-field';
import { ManagePatientContext } from './ManagePatient';
import { CheckIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import Applabel from '@components/hook-form/applabel';
import { getUserPersistedOnLocalStorage } from '@authentication/context/jwt/utils';
import Modal from '@components/modal';

export const TherapySchema = z.object({
  id: z.string(),
  therapyName: z.string(),
});
export type Therapy = z.infer<typeof TherapySchema>;

export const AddNewPrescriptionDetailsSchema = z.object({
  // clientId: z.string(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date().optional(),
  clientAdvice: z.string().optional(),
  precautions: z.string().optional(),
  sessionCount: z.coerce.number().optional(),
  sessionFrequency: z.coerce.number().optional(),
  sessionFrequencyUnit: z.string().optional(),
  type: z.string().optional(),
  prescribedTherapies: z.string().array().optional(),
});
export type AddNewPrescriptionDetailsType = z.infer<
  typeof AddNewPrescriptionDetailsSchema
>;

export function AddTreatmentPlan(props: { onClose: () => void }) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<AddNewPrescriptionDetailsType>({
    resolver: zodResolver(AddNewPrescriptionDetailsSchema),
  });

  const [prescription, setPrescription] =
    React.useState<AddNewPrescriptionDetails | null>(null);
  let [isOpen, setIsOpen] = useState(true);
  const [formData, setFormData] = useState({});
  const patientId = useContext(ManagePatientContext);
  const [selectedTherapy, setSelectedTherapy] = useState<string[]>();
  const [cancelDialog, setCancelDialog] = useState(false);
  const [apiError, setApiError] = React.useState('');
  const [radioValue, setRadioValue] = useState('Home');
  const [therapyList, setTherapyList] = useState<Therapy[]>([]);

  function closeModal() {
    setIsOpen(false);
    props.onClose();
  }

  React.useEffect(() => {
    const getTherapiesList = async () => {
      try {
        const userData = getUserPersistedOnLocalStorage();
        const paramsMap = new Map<string, string>([
          ['entityId', userData?.entity_id ?? ''],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.treatmentPlan.therapyList,
          paramsMap,
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data['data'];
        setTherapyList(data);
      } catch (error) {
        console.error('Error getting treatment plans', error);
      }
    };
    getTherapiesList();
  }, []);

  const onSubmit: SubmitHandler<AddNewPrescriptionDetailsType> = async data => {
    try {
      data.prescribedTherapies = selectedTherapy;
      const combinedData = { ...formData, ...data };

      AddNewPrescriptionDetailsSchema.parse(combinedData);
      setSelectedTherapy(combinedData.prescribedTherapies);
      await axios.post(endpoints.treatmentPlan.createPrescription, {
        clientAdvice: combinedData?.clientAdvice,
        clientId: patientId.patientId,
        endDate: moment(combinedData?.endDate).format(),
        precautions: combinedData.precautions,
        prescribedTherapies: combinedData.prescribedTherapies,
        sessionCount: combinedData.sessionCount,
        sessionFrequency: combinedData.sessionFrequency,
        sessionFrequencyUnit: combinedData.sessionFrequencyUnit,
        startDate: moment(new Date(combinedData?.startDate)).format(),
        type: combinedData.type ? combinedData.type : radioValue,
      });
      closeModal();
      window.location.reload();
      toast.success('Add new prescription added successfully');
    } catch (error) {
      console.error('Error submitting form:', error);
      setApiError(error.response.data['data'].toString());
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="flex flex-row w-full justify-between mx-4 my-2">
          <h3 className="text-md leading-6 font-bold text-gray-900">
            New Prescription
          </h3>
        </div>
        <hr className="w-full" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-2 overflow-auto h-80">
            <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
              <div className="flex flex-col  w-full md:w-1/2">
                <Applabel label="Start Date" />
                <input
                  {...register('startDate')}
                  name="startDate"
                  className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  type="date"
                  value={moment(watch('startDate')).format('YYYY-MM-DD')}
                  onChange={e => {
                    setValue(
                      'startDate',
                      new Date(moment(e.target.value).format('YYYY-MM-DD')),
                    );
                  }}
                ></input>
              </div>
              <div className="flex flex-col  w-full md:w-1/2">
                <Applabel label="End Date" />
                <input
                  {...register('endDate')}
                  name="endDate"
                  className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  type="date"
                  value={moment(watch('endDate')).format('YYYY-MM-DD')}
                  onChange={e => {
                    setValue(
                      'endDate',
                      new Date(moment(e.target.value).format('YYYY-MM-DD')),
                    );
                  }}
                ></input>
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
              <Applabel label="Prescribed Therapies" />
            </div>
            <div className="flex flex-col  w-full mt-1">
              <div className="max-h-56  h overflow-y-auto">
                <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
                  {therapyList!.map((therapy: Therapy) => {
                    return (
                      <div
                        key={therapy.therapyName}
                        className={`flex items-center justify-evenly rounded text-center p-2 hover:bg-gray-200 hover:text-black border text-xs font-medium ${
                          selectedTherapy?.some(
                            ex => ex === therapy.therapyName,
                          )
                            ? 'bg-tertiary-400 text-white'
                            : ''
                        }`}
                        onClick={() => {
                          if (!selectedTherapy) setSelectedTherapy([]);
                          const isAdded =
                            selectedTherapy &&
                            selectedTherapy?.findIndex(
                              ex => ex === therapy.therapyName,
                            ) >= 0;
                          if (isAdded) {
                            setSelectedTherapy(preAssignTherapies =>
                              preAssignTherapies!.filter(
                                ex => ex !== therapy.therapyName,
                              ),
                            );
                          } else {
                            setSelectedTherapy(preAssignTherapies => [
                              ...preAssignTherapies!,
                              therapy.therapyName,
                            ]);
                          }
                        }}
                      >
                        {selectedTherapy?.some(
                          ex => ex === therapy.therapyName,
                        ) ? (
                          <CheckIcon
                            className="h-4 w-4 mt-1 flex justify-evenly"
                            aria-hidden="true"
                          />
                        ) : (
                          <></>
                        )}

                        <span className="flex flex-col text-black">
                          {therapy.therapyName}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="flex items-center py-2 mt-4">
              <p className="block text-xs font-medium leading-6 text-grey-900 mt-2.5">
                Recommended
              </p>
              <input
                className="appearance-none bg-transparent border-0 border-b border-teal-500 w-1/5 text-gray-700 mr-3 ml-3 py-1 px-2 leading-tight focus:outline-none text-xs font-medium leading-6"
                {...register('sessionCount')}
                type="number"
                placeholder="10"
                value={watch('sessionCount')}
              />

              <p className="block text-xs font-medium leading-6 text-grey-900 mt-2.5">
                sessions,
              </p>
              <input
                className="appearance-none bg-transparent border-0 border-b border-teal-500  w-1/5 text-gray-700 ml-3 mr-3 py-1 px-2 focus:outline-none text-xs font-medium leading-6"
                {...register('sessionFrequency')}
                type="number"
                placeholder="2"
                value={watch('sessionFrequency')}
              />
              <p className="block text-xs font-medium leading-6 text-grey-900 mt-2.5">
                per
              </p>
              <select
                {...register('sessionFrequencyUnit')}
                placeholder="sessionFrequencyUnit"
                className={`appearance-none bg-transparent border-0 border-b border-teal-500 w-1/5 text-gray-700 mr-3 ml-3 py-1 px-2 focus:outline-none text-xs font-medium leading-6`}
                value={watch('sessionFrequencyUnit')}
              >
                <option key="" value="">
                  Select
                </option>
                {['Week', 'Month', 'Year'].map(sessionFrequencyUnit => {
                  return (
                    <option
                      key={sessionFrequencyUnit}
                      value={sessionFrequencyUnit}
                    >
                      {sessionFrequencyUnit}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="flex flex-col md:flex-row w-full mt-2 md:space-x-4">
              <div className="flex flex-col  w-full ">
                <Applabel label="Session Type" />
                <div className="flex">
                  {['Home', 'Clinic', 'Remote'].map(option => (
                    <label
                      key={option}
                      className="ml-4 text-xs font-medium leading-6"
                    >
                      <input
                        className={`ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 mr-1`}
                        type="radio"
                        name="radioValues"
                        value={option}
                        checked={radioValue === option}
                        onChange={e => {
                          setRadioValue(e.target.value),
                            setValue('type', e.target.value);
                        }}
                      />
                      {option}
                      <br />
                    </label>
                  ))}
                </div>
                {errors.sessionFrequencyUnit && (
                  <ValidationError
                    message={
                      errors.sessionFrequencyUnit?.message?.toString() ?? ''
                    }
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
              <div className="flex flex-col  w-full ">
                <Applabel label="Advice" />
                <span className="block text-xs font-medium text-grey-900">
                  The Advice that is to be given to the patient
                </span>
                <br />
                <div className="flex flex-row w-full pr-2">
                  <textarea
                    {...register('clientAdvice')}
                    key="clientAdvice"
                    name="clientAdvice"
                    placeholder="Enter advice details "
                    rows={3}
                    value={watch('clientAdvice')}
                    className="block w-full rounded-lg border-0 py-2 ring-1 placeholder:text-grey-400 sm:text-sm sm:leading-6"
                  />
                  {errors.clientAdvice && (
                    <ValidationError
                      message={errors.clientAdvice?.message?.toString() ?? ''}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
              <div className="flex flex-col  w-full ">
                <Applabel label="Precaution" />
                <span className="block text-xs font-medium text-grey-900">
                  The precaution that is to be given to the patient
                </span>
                <br />
                <div className="flex flex-row w-full pr-2">
                  <textarea
                    {...register('precautions')}
                    key="precautions"
                    name="precautions"
                    placeholder="Enter details"
                    rows={3}
                    value={watch('precautions')}
                    className="block w-full rounded-lg border-0 py-2 ring-1 placeholder:text-grey-400 sm:text-sm sm:leading-6"
                  />
                  {errors.precautions && (
                    <ValidationError
                      message={errors.precautions?.message?.toString() ?? ''}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full p-4 mt-4 bg-gray-100 justify-end">
            <div className="mt-4">
              <Button
                variant={ButtonVariant.PRIMARY}
                type="button"
                onClick={() => props.onClose()}
                children="Cancel"
                className="mr-2"
              />
            </div>
            <div className="mt-4">
              <Button variant={ButtonVariant.PRIMARY} type="submit">
                Submit
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
