// To parse this data:
//
//   import { Convert, Reflex } from "./file";
//
//   const reflex = Convert.toReflex(json);

export interface Reflex {
  id: string;
  masterAssessmentId: string;
  jawJerk: number;
  bicepsReflex: number;
  tricepsReflex: number;
  brachioradialisReflex: number;
  fingerReflex: number;
  abdominalReflex: number;
  kneeReflex: number;
  ankleReflex: number;
  cornealReflex: number;
  plantarReflex: number;
  abdominalReflexSuperficial: number;
  cremastericReflex: number;
  analReflex: number;
  bulbocavernosusReflex: number;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toReflex(json: string): Reflex {
    return JSON.parse(json);
  }

  public static reflexToJson(value: Reflex): string {
    return JSON.stringify(value);
  }
}
