// To parse this data:
//
//   import { Convert, MedicalHistoryDetails } from "./file";
//
//   const medicalHistoryDetails = Convert.toMedicalHistoryDetails(json);

export type MedicalHistoryDetails = {
  anyHealthCondition?: boolean;
  healthConditionDetails?: string;
  anySurgery?: boolean;
  surgeryDetails?: string;
  anyPastTreatment?: boolean;
  pastTreatmentDetails?: string;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toMedicalHistoryDetails(json: string): MedicalHistoryDetails {
    return JSON.parse(json);
  }

  public static medicalHistoryDetailsToJson(value: MedicalHistoryDetails): string {
    return JSON.stringify(value);
  }
}
