import { OutlinedButton } from "@components/button";
import Button, { ButtonVariant } from "@components/button/button";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import DetailsStep from "./DetailsStep";
import ConfigurationStep from "../../entities/view-entity/ConfigurationStep";
import DeclarationStep from "./DeclarationStep";
import { Convert } from "../types/Entity";
import { useNavigate } from "react-router-dom";
import { ValidationError } from "@components/hook-form";
import { toast } from "react-toastify";
import Applabel from "@components/hook-form/applabel";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import CountryInputSelect from "@components/hook-form/country-input-select";
import TimezoneData from "@assets/timezones";
import tzmoment from "moment-timezone";
import PhoneInput, {
  formatPhoneNumber,
  getCountryCallingCode,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { CountryCode, E164Number } from "libphonenumber-js";
import "react-phone-number-input/style.css";
import { convertPhoneNumberAndCodeToNumber } from "@utils/phoneUtils";

// TO DO  : Change Country state city from optional to required with respective messages

export const EntityAddressSchema = z.object({
  address: z.string().min(1, { message: "Address is required" }),
  city: z.string().optional(),
  state: z.string().optional(),
  country: z.string().optional(),
  postalCode: z.string().min(1, { message: "Postal Code/zip is required" }),
});
export type EntityAddress = z.infer<typeof EntityAddressSchema>;

export const EntitySchema = z.object({
  name: z.string().min(1, { message: "Entity name is required" }),
  ownerSalutation: z.string().min(1, { message: "Title is required" }),
  ownerFirstName: z.string().min(1, { message: "First name is required" }),
  ownerMiddleName: z.string().optional(),
  ownerLastName: z.string().min(1, { message: "Last name is required" }),
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .email({ message: "Must be a valid email" }),

  phoneCode: z.string().min(1, { message: "Phone Code is required" }),
  phone: z.string().superRefine((val, ctx) => {
    if (!val || val.trim() === "") {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please enter Phone`,
        fatal: true,
      });
      return z.NEVER;
    }
    if (!isValidPhoneNumber(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        fatal: true,
        message: "Invalid phone number format",
      });

      return z.NEVER;
    }

    if (!isPossiblePhoneNumber(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Invalid phone number length`,
        fatal: true,
      });
      return z.NEVER;
    }
  }),

  timezone: z.string().refine(
    () => {
      return tzmoment.tz.guess();
    },
    { message: "Please add Timezone" }
  ),
  address: EntityAddressSchema,
});
export type EntitySchemaType = z.infer<typeof EntitySchema>;

// const steps = [DetailsStep, ConfigurationStep, DeclarationStep];
const steps = [DetailsStep];
interface Timezone {
  value: string;
  abbr: string;
  text: string;
  utc: string[];
}

function AddEntityPage() {
  const [currentStep, setCurrentStep] = useState(0);
  const [error, setApiError] = useState<string | null>(null);

  const [utcTime, setUtcTime] = useState("");

  const handlePhoneNumberChange = (value: E164Number | undefined) => {
    setValue("phone", value?.toString() ?? "");
  };

  const navigator = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<EntitySchemaType>({
    resolver: zodResolver(EntitySchema),
  });

  useEffect(() => {
    const countryCode = getCountryCallingCode("IN");
    setValue("phoneCode", countryCode?.toString() ?? "");
    setValue("phone", "");
  }, []);

  useEffect(() => {
    TimezoneData.timezones.map((timezone: Timezone, index: number) =>
      timezone.utc.map((tz) => {
        if (tz === tzmoment.tz.guess()) {
          setUtcTime(timezone.text);
        }
      })
    );
  }, []);

  const onSubmit: SubmitHandler<EntitySchemaType> = async (data) => {
    try {
      EntitySchema.parse(data);

      const phoneData = convertPhoneNumberAndCodeToNumber({
        phoneNumber: data.phone,
        phoneCode: data.phoneCode,
      });

      const response = await axios.post(`${endpoints.entity.onboard}`, {
        ...data,
        phone: phoneData.phoneNumber,
        phoneCode: phoneData.phoneCode,
      });

      if (response.data.status === 200)
        toast.success(
          response.data.message === "success"
            ? "Entity Information added successfully"
            : response.data.message
        );
      navigator("/entity");
    } catch (error) {
      toast.error(error.response.data.data);
      setApiError(error.response.data["data"].toString());
    }
  };

  const StepComponent = steps[currentStep];

  // const StepTitles = ['Details', 'Configuration', 'Declaration'];
  const StepTitles = ["Details"];

  function checkDuplicateEmail() {
    var email = watch("email");

    if (email.trim() !== "")
      if (email) {
        const paramsMap = new Map<string, string>([["email", watch("email")]]);
        const filteredEndpoint = replaceParams(
          endpoints.staff.checkStaffEmail,
          paramsMap
        );
        axios
          .get(filteredEndpoint)
          .then((res) => {
            clearErrors("email");
          })
          .catch((error) => {
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.status === 500
            ) {
              setError("email", {
                type: "custom",
                message:
                  "Account with this email already exists. Please contact support.",
              });
              setValue("email", "");
            } else {
              toast.error(error.message);
              setValue("email", "");
            }
          });
      }
  }

  return (
    <>
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-col justify-start">
          <div className="flex flex-row justify-between">
            <h1 className="text-2xl font-bold">Add Entity</h1>
            <Button
              variant={ButtonVariant.PRIMARY}
              type="submit"
              className="ml-3"
              onClick={() => {
                navigator("/entity");
              }}
            >
              Back
            </Button>
          </div>
          <span className="text-gray-500">
            Add a new business entity to the system
          </span>
        </div>

        <hr className="my-4" />

        <div className="flex flex-row justify-between md:justify-start items-center w-full">
          {StepTitles.map((title, index) => (
            <div
              key={index}
              className={`flex flex-row py-2 px-3 mx-2 items-center justify-center rounded-xl ${
                index === currentStep
                  ? "bg-primary-100 text-primary"
                  : "bg-gray-200 text-gray-500"
              }`}
            >
              <div className="flex flex-col mr-2 h-6 w-6 rounded-full bg-primary-600 text-white text-sm text-primary justify-center items-center">
                <span>{index + 1}</span>
              </div>
              <span>{title}</span>
            </div>
          ))}
        </div>

        <hr className="my-4" />

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="mb-32">
            <div className="flex flex-col w-full space-y-4">
              <div className="flex flex-col w-full md:w-2/3 pr-2">
                <Applabel label="Timezone" mandatory />
                <select
                  {...register("timezone")}
                  value={watch("timezone")}
                  placeholder="Timezone"
                  className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                >
                  <option value={tzmoment.tz.guess()}>
                    {tzmoment.tz.guess()} - {utcTime}
                  </option>
                  {TimezoneData.timezones.map(
                    (timezone: Timezone, index: number) =>
                      timezone.utc.map((tz) => (
                        <option key={tz} value={tz}>
                          {tz} -{timezone.text}
                        </option>
                      ))
                  )}
                </select>
              </div>
              {/* Entity Name */}
              <div className="flex flex-col w-full md:w-2/3 pr-2">
                <Applabel label="Entity Name" mandatory />

                <input
                  type="text"
                  key="name"
                  {...register("name")}
                  value={watch("name")}
                  placeholder="Entity Name"
                  className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                />
                {errors.name && (
                  <ValidationError
                    message={errors.name?.message?.toString() ?? ""}
                  />
                )}
              </div>

              {/* Entity Owner */}
              <div className="flex flex-col md:flex-row w-full justify-start items-start mt-0 md:space-x-4">
                <div className="flex flex-col w-full md:w-1/3">
                  <div className="flex flex-row w-full justify-start space-x-2">
                    <div className="flex flex-col ">
                      <Applabel label="Title" mandatory />
                      <select
                        {...register("ownerSalutation")}
                        placeholder="Salutation"
                        className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        value={watch("ownerSalutation")}
                      >
                        <option key="" value="">
                          Select
                        </option>
                        {[
                          "Mr",
                          "Mrs",
                          "Ms",
                          "Miss",
                          "Dr",
                          "Prof",
                          "Rev",
                          "Hon",
                          "Capt",
                        ].map((salutation) => {
                          return (
                            <option key={salutation} value={salutation}>
                              {salutation}
                            </option>
                          );
                        })}
                      </select>
                      {errors.ownerSalutation && (
                        <ValidationError
                          message={
                            errors.ownerSalutation?.message?.toString() ?? ""
                          }
                        />
                      )}
                    </div>

                    <div className="flex flex-col grow">
                      <Applabel label="First Name" mandatory />
                      <input
                        {...register("ownerFirstName")}
                        placeholder="Entity Owner First Name"
                        value={watch("ownerFirstName")}
                        type="text"
                        className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                      />
                      {errors.ownerFirstName && (
                        <ValidationError
                          message={
                            errors.ownerFirstName?.message?.toString() ?? ""
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full md:w-1/3">
                  <Applabel label="Middle Name" />
                  <input
                    {...register("ownerMiddleName")}
                    placeholder="Entity Owner Middle Name"
                    value={watch("ownerMiddleName")}
                    className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                  />
                  {errors.ownerMiddleName && (
                    <ValidationError
                      message={
                        errors.ownerMiddleName?.message?.toString() ?? ""
                      }
                    />
                  )}
                </div>

                <div className="flex flex-col w-full md:w-1/3">
                  <Applabel label="Last Name" mandatory />
                  <input
                    {...register("ownerLastName")}
                    className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                    placeholder="Entity Owner Last Name"
                    value={watch("ownerLastName")}
                  />
                  {errors.ownerLastName && (
                    <ValidationError
                      message={errors.ownerLastName?.message?.toString() ?? ""}
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                <div className="flex flex-col md:w-1/3">
                  <Applabel label="Entity owner email" mandatory />
                  <input
                    {...register("email")}
                    placeholder="Entity owner email"
                    onBlur={checkDuplicateEmail}
                    className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                    value={watch("email")}
                    required
                  />
                  {errors.email && (
                    <ValidationError
                      message={errors.email?.message?.toString() ?? ""}
                    />
                  )}
                </div>

                <div className="flex flex-col  md:w-1/4">
                  <Applabel label="Mobile Number" mandatory />
                  <div className="flex flex-row">
                    <PhoneInput
                      onCountryChange={(country?: CountryCode | undefined) => {
                        if (country) {
                          const countryCode = getCountryCallingCode(country);
                          setValue("phoneCode", countryCode?.toString() ?? "");
                        }
                      }}
                      {...register("phone")}
                      international={true}
                      defaultCountry="IN"
                      countryCallingCodeEditable={false}
                      placeholder="Enter phone number"
                      value={watch("phone")}
                      onChange={(value?: E164Number | undefined) => {
                        clearErrors("phone");
                        handlePhoneNumberChange(value);
                      }}
                      onBlur={() => {
                        trigger("phone");
                      }}
                      className="w-full"
                    />
                  </div>
                  {errors.phone && (
                    <ValidationError
                      message={errors.phone?.message?.toString() ?? ""}
                    />
                  )}
                </div>

                <div className="flex flex-col md:w-1/3"></div>
              </div>
              {/* Entity Address */}
              <hr className="my-2" />
              <span className="flex-none text-lg font-medium w-1/6 mb-0">
                Address
              </span>
              <hr className="my-2" />
              <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                <div className="flex flex-col grow">
                  <Applabel label="Address" mandatory />

                  <input
                    {...register("address.address")}
                    placeholder="Entity Address"
                    className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                    required
                  />
                  {errors.address?.address && (
                    <ValidationError
                      message={
                        errors.address?.address?.message?.toString() ?? ""
                      }
                    />
                  )}
                </div>
                <div className="flex flex-col grow">
                  <Applabel label="Postal/Zip code" mandatory />

                  <input
                    {...register("address.postalCode")}
                    placeholder="Entity Zip Code"
                    className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                    required
                    value={watch("address.postalCode")}
                  />
                  {errors.address?.postalCode && (
                    <ValidationError
                      message={
                        errors.address.postalCode?.message?.toString() ?? ""
                      }
                    />
                  )}
                </div>
              </div>

              <CountryInputSelect
                isDisabled={false}
                register={register}
                setValue={setValue}
                watch={watch}
                country="address.country"
                state="address.state"
                city="address.city"
              />
            </div>
            <div className="h-20 mt-10"></div>

            <div className="fixed items-center w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-8 shadow bg-gray-100">
              <div className="flex flex-row ">
                <div>
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    type="submit"
                    className="ml-3"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="fixed items-start w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-8 shadow bg-gray-100">
            {currentStep > 0 && (
              <OutlinedButton
                variant={ButtonVariant.PRIMARY}
                onClick={() => setCurrentStep(currentStep - 1)}
                className="mr-2"
                type="button"
              >
                Previous
              </OutlinedButton>
            )}
            {currentStep < steps.length - 1 && (
              <OutlinedButton
                variant={ButtonVariant.PRIMARY}
                onClick={() => {
                  setCurrentStep(currentStep + 1);
                  setFormData({ ...formData, ...methods.getValues() });
                }}
                isDisabled={methods.formState.isDirty || !methods.formState.isValid}
              >
                Next
              </OutlinedButton>
            )}
            <div className="flex ">
              {currentStep === steps.length - 1 && (
                <Button
                  variant={ButtonVariant.PRIMARY}
                  onClick={() => {
                    setFormData({ ...formData, ...methods.getValues() });
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              )}
              <div className="ml-5">{error && <ValidationError message={error ?? ''} />}</div>
            </div>
          </div> */}
        </form>
      </div>
    </>
  );
}

export default AddEntityPage;
