import { OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useEffect, useState } from "react";
import useAxios from "@routes/hooks/use-axios";
import { useNavigate } from "react-router-dom";
import { Appointment, Convert } from "../types/Appointment";
import Modal from "@components/modal";
import moment from "moment";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

export default function AppointmentDetails(props: {
  appId: string;
  onClose: () => void;
}) {
  const userData = getUserPersistedOnLocalStorage();
  let [isOpen, setIsOpen] = useState(true);
  const [appointment, setAppointment] = React.useState<Appointment | null>(
    null
  );

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const axios = useAxios();
  const [waitingTime, setWaitingTime] = useState("");
  const [sessionTime, setSessionTime] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    const getAppointmentById = async () => {
      try {
        const paramsMap = new Map<string, string>([["id", props.appId]]);
        const filteredEndpoint = replaceParams(
          endpoints.appointment.view,
          paramsMap
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data["data"];

        const convertedAppointment = Convert.toAppointment(
          JSON.stringify(data)
        );
        let duration =
          convertedAppointment.endTimeMinutes -
          convertedAppointment.startTimeMinutes;

        convertedAppointment.apptdate = new Date(
          moment(convertedAppointment.apptdate).toLocaleString()
        );

        convertedAppointment.sessionStartTime = new Date(
          moment(convertedAppointment.sessionStartTime).toLocaleString()
        );

        convertedAppointment.checkInTime = new Date(
          moment(convertedAppointment.checkInTime).toLocaleString()
        );

        convertedAppointment.sessionCompleteTime = new Date(
          moment(convertedAppointment.sessionCompleteTime).toLocaleString()
        );

        getWaitingAndSessionTime(
          convertedAppointment.sessionStartTime,
          convertedAppointment.checkInTime,
          convertedAppointment.sessionCompleteTime
        );

        convertedAppointment.startTime = moment(
          convertedAppointment.apptdate
        ).format("hh:mm A");
        convertedAppointment.endTime = moment(
          convertedAppointment.startTime,
          "hh:mm A"
        )
          .add(duration, "minutes")
          .format("hh:mm A");
        convertedAppointment.startTimeMinutes =
          convertedAppointment.apptdate.getHours() * 60 +
          convertedAppointment.apptdate.getMinutes();
        convertedAppointment.endTimeMinutes =
          convertedAppointment.apptdate.getHours() * 60 +
          convertedAppointment.apptdate.getMinutes() +
          duration;
        setAppointment(convertedAppointment);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching appointment by id:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    getAppointmentById();
  }, []);

  const getWaitingAndSessionTime = (
    sessionStartTime: Date,
    checkInTime: Date,
    sessionCompleteTime: Date
  ) => {
    if (
      moment(new Date(checkInTime)).format("(DD-MM-YYYY) HH:mm") ===
        "(01-01-2001) 05:30" ||
      moment(new Date(sessionStartTime)).format("(DD-MM-YYYY) HH:mm") ===
        "(01-01-2001) 05:30"
    ) {
      setWaitingTime("N/A");
      setSessionTime("N/A");
    } else {
      const startedSessionMoment = moment(sessionStartTime);
      const checkInMoment = moment(checkInTime);
      const completedSessionMoment = moment(sessionCompleteTime);

      // Calculate waiting time in minutes, rounded to the nearest minute
      let waitingTimeMinutes = Math.round(
        startedSessionMoment.diff(checkInMoment, "minutes", true)
      );
      waitingTimeMinutes = waitingTimeMinutes < 0 ? 0 : waitingTimeMinutes;

      const waitingHours = Math.floor(waitingTimeMinutes / 60);
      const waitingMins = waitingTimeMinutes % 60;
      const formattedWaitingTime = `${
        waitingHours > 0 ? `${waitingHours}hrs ` : ""
      }${waitingMins}mins`;

      // Calculate session time in minutes, rounded to the nearest minute
      let sessionTimeMinutes = Math.round(
        completedSessionMoment.diff(startedSessionMoment, "minutes", true)
      );
      sessionTimeMinutes = sessionTimeMinutes < 0 ? 0 : sessionTimeMinutes;

      const sessionHours = Math.floor(sessionTimeMinutes / 60);
      const sessionMins = sessionTimeMinutes % 60;
      const formattedSessionTime = `${
        sessionHours > 0 ? `${sessionHours}hrs ` : ""
      }${sessionMins}mins`;

      setWaitingTime(formattedWaitingTime);
      setSessionTime(formattedSessionTime);
    }
  };

  const navigateToPatientScreen = (patientId: string) => {
    navigate(`/patient/manage/${patientId}`);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={props.onClose}>
        <div className="flex flex-row w-full justify-between mx-4 my-2">
          <h4 className="text-md leading-6 font-bold text-gray-900">
            View Appointment Details
          </h4>
        </div>
        <hr className="w-full" />
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {!loading && !error && appointment && (
          <div>
            <div className="flex flex-col w-full h-full px-4 py-2 space-y-2">
              <div className="flex flex-col">
                <h1 className="text-lg font-semibold">Patient Information</h1>
                <span>{appointment?.clientName}</span>
                <span>
                  {" "}
                  Contact Number:{" "}
                  {userData?.user_role === "ENTITY_OWNER" ||
                  userData?.user_role === "PLATFORM_ADMIN" ? (
                    <span>{appointment?.clientPhone}</span>
                  ) : appointment?.clientPhone.toString().length > 4 ? (
                    <span>{appointment?.clientPhone}</span>
                  ) : (
                    <span>******{appointment?.clientPhone}</span>
                  )}
                </span>
              </div>

              <div className="flex flex-col">
                <h1 className="text-lg font-semibold">
                  Appointment Information
                </h1>
                <span>
                  Date: {moment(appointment?.apptdate).format("DD-MM-YYYY")}
                </span>
                <span>Slot: {appointment?.startTime}</span>
                <span>Type: {appointment?.appointmentType}</span>
                <span>
                  Check in time:{" "}
                  {moment(new Date(appointment?.checkInTime)).format(
                    "(DD-MM-YYYY) HH:mm"
                  ) === "(01-01-2001) 05:30"
                    ? "N/A"
                    : moment(new Date(appointment?.checkInTime)).format(
                        "(DD-MM-YYYY) hh:mm A"
                      )}
                </span>
                <span>
                  Session start time:{" "}
                  {moment(new Date(appointment?.sessionStartTime)).format(
                    "(DD-MM-YYYY) HH:mm"
                  ) === "(01-01-2001) 05:30"
                    ? "N/A"
                    : moment(new Date(appointment?.sessionStartTime)).format(
                        "(DD-MM-YYYY) hh:mm A"
                      )}
                </span>
                <span>
                  Session completed time:{" "}
                  {moment(new Date(appointment?.sessionCompleteTime)).format(
                    "(DD-MM-YYYY) HH:mm"
                  ) === "(01-01-2001) 05:30"
                    ? "N/A"
                    : moment(new Date(appointment?.sessionCompleteTime)).format(
                        "(DD-MM-YYYY) hh:mm A"
                      )}
                </span>
                <div className="flex flex-row mt-2 w-full justify-center space-x-2">
                  <div className="flex items-center p-4 bg-white rounded-xl shadow-xs border border-1 w-1/2">
                    <div>
                      <p className="mb-2 text-sm font-medium text-gray-600 ">
                        Waiting Time
                      </p>
                      <p className="text-lg font-semibold text-gray-700 ">
                        {waitingTime}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center p-4 bg-white rounded-xl shadow-xs border border-1 w-1/2">
                    <div>
                      <p className="mb-2 text-sm font-medium text-gray-600 ">
                        Session Time
                      </p>
                      <p className="text-lg font-semibold text-gray-700 ">
                        {sessionTime}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <h1 className="text-lg font-semibold">
                  Consulting Physiotherapist
                </h1>
                <span>{appointment?.entityUserName}</span>
              </div>

              <div className="flex flex-col">
                <h1 className="text-lg font-semibold">
                  Additional Information
                </h1>
                <span className="text-xs">
                  Private note specific to the appointment for Physiotherapist
                  reference
                </span>
                <span className="text-sm">
                  {appointment?.notes === ""
                    ? "No additional information provided"
                    : appointment?.notes}
                </span>
              </div>
            </div>
            <div className="flex flex-row w-full mt-4 p-4 bg-gray-100 md:justify-end lg:justify-end justify-evenly">
              <OutlinedButton
                variant={ButtonVariant.SECONDARY}
                type="button"
                onClick={() => props.onClose()}
                children="Close"
              />

              <OutlinedButton
                variant={ButtonVariant.SECONDARY}
                type="button"
                onClick={() => {
                  navigateToPatientScreen(appointment.clientId);
                }}
                children="Manage Patient"
                className="ml-2"
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
