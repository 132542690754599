import React, { useState } from 'react';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  useFieldArray,
} from 'react-hook-form';
import Button, { ButtonVariant } from '@components/button/button';
import { OutlinedButton } from '@components/button';
import { v4 as uuidv4 } from 'uuid';
import { endpoints, replaceParams } from '@utils/axios';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  getUserPersistedOnLocalStorage,
  getUserTimeZone,
} from '@authentication/context/jwt/utils';

import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { convertTo24HourFormat } from '@utils/timeConversionUtil';

export const WorkingHourSchema = z.object({
  day: z.number().optional(),
  startTime: z.string().optional(),
  endTime: z.string().optional(),
});
export type WorkingHourT = z.infer<typeof WorkingHourSchema>;

export const WorkingHoursReasonSchema = z.object({
  workingHour: z.array(WorkingHourSchema).optional(),
});
export type WorkingHoursReasonSchemaType = z.infer<
  typeof WorkingHoursReasonSchema
>;

type WorkingHour = {
  uuid?: string;
  day: number;
  startTime: string;
  endTime: string;
};
export class Convert {
  public static toWorkingHours(json: string): WorkingHour {
    return JSON.parse(json);
  }

  public static workingHourToJson(value: WorkingHour): string {
    return JSON.stringify(value);
  }
}

type RequestWorkingHour = Omit<WorkingHour, 'uuid'>;

type Request = {
  workingHours: RequestWorkingHour[];
};

function workingHourListToRequest(workingHours: WorkingHour[]): Request {
  const requestWorkingHours = workingHours.map(workingHour => ({
    day: workingHour.day,
    startTime: moment(workingHour.startTime, ['HH:mm']).format('hh:mm A'),
    endTime: moment(workingHour.endTime, ['HH:mm']).format('hh:mm A'),
  }));

  return { workingHours: requestWorkingHours };
}

const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export default function ConfigurationStep(props: {
  branchId: string;
  entityId: string;
  timeZone: string;
  hasTimeZoneChanged: boolean;
}) {
  const [workingHours, setWorkingHours] = useState<WorkingHour[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);
  const [warningMessage, setWarningMessage] = React.useState('');

  const handleCheckboxChange = (day: string) => {
    // if (!workingHours) return;
    // if there are no working hours for the day, add one
    // else remove all working hours for the day
    if (getCountOfWorkingHours(day) === 0) {
      addNewWorkingHourItem(day);
    } else {
      // Remove working hours for the unchecked day
      let weekdayIndex = weekdays.indexOf(day);
      // weekdayIndex += 1; // Added One to match the rule ,week starts with Monday [1]
      setWorkingHours(
        workingHours?.filter(obj => obj.day !== weekdayIndex) ?? [],
      );
    }
  };

  const getCountOfWorkingHours = (day: string) => {
    let weekdayIndex = weekdays.indexOf(day);
    // weekdayIndex += 1; // Added One to match the rule ,week starts with Monday [1]
    return workingHours?.filter(obj => obj.day === weekdayIndex).length ?? 0;
  };

  const getWorkingHoursForDay = (day: string) => {
    let weekdayIndex = weekdays.indexOf(day);
    // weekdayIndex += 1; // Added One to match the rule ,week starts with Monday [1]
    return workingHours?.filter(obj => obj.day === weekdayIndex) ?? [];
  };

  const addNewWorkingHourItem = (day: string) => {
    const workingHour: WorkingHour = {
      uuid: uuidv4(),
      day: weekdays.indexOf(day),
      startTime: '10:00',
      endTime: '17:00',
    };
    const updatedWorkingHours = workingHours
      ? [...workingHours, workingHour]
      : [workingHour];
    setWorkingHours(updatedWorkingHours);
  };

  const handleWorkingHourChange = (workingHour: WorkingHour) => {
    const index = workingHours?.findIndex(
      obj => obj.day === workingHour.day && obj.uuid === workingHour.uuid,
    );
    if (index === undefined) return;
    workingHours?.splice(index, 1, workingHour);
    setWorkingHours([...(workingHours ?? [])]);
  };

  const deleteWorkingHour = (workingHour: WorkingHour) => {
    const index = workingHours?.findIndex(obj => obj.uuid === workingHour.uuid);
    if (index === undefined) return;
    workingHours?.splice(index, 1);
    setWorkingHours([...(workingHours ?? [])]);
  };

  const methods = useForm<WorkingHoursReasonSchemaType>({
    resolver: zodResolver(WorkingHoursReasonSchema),

    defaultValues: WorkingHoursReasonSchema.parse({
      workingHours: workingHours,
    }),
  });
  const control = methods.control;

  const { fields: workingHour } = useFieldArray({
    control,
    name: 'workingHour',
  });

  // below is the api call to get the list of patients in a react hook
  React.useEffect(() => {
    const initialize = async () => {
      try {
        const userData = getUserPersistedOnLocalStorage();
        const paramsMap = new Map<string, string>([
          ['entityId', userData?.entity_id!],
          ['branchId', props.branchId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.branch.getworkinghours,
          paramsMap,
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data['data'];
        const convertedWorkingHours: WorkingHour[] = data.map((data: any) => {
          data['startTime'] = convertTo24HourFormat(data['startTime']);
          data['endTime'] = convertTo24HourFormat(data['endTime']);
          data['uuid'] = uuidv4();
          return Convert.toWorkingHours(JSON.stringify(data));
        });

        const convertedWorkingHoursLocal = convertWorkingHoursToLocalTimes(
          convertedWorkingHours,
        );

        setWorkingHours(convertedWorkingHoursLocal);

        setLoading(false);
      } catch (error) {
        toast.warning('Please add working hours.');

        console.error('Error fetching branch working hours:', error);
        setError(error.message);
        setLoading(false);
      }
    };
    if (props.hasTimeZoneChanged) {
      setWorkingHours([]);
    } else {
      initialize();
    }
  }, []);

  function convertWorkingHoursToUtcTimes(
    workingHours: WorkingHour[],
  ): WorkingHour[] {
    // const timeZone = getUserTimeZone();
    const timeZone = props.timeZone;

    // Create a moment object for the input start time in the specified timezone
    const workingHourinUTC = workingHours.map(w => {
      let workVal = { ...w };

      const startMoment = moment.tz(workVal.startTime, 'HH:mm', timeZone!);

      // Create a moment object for the input end time in the specified timezone
      const endMoment = moment.tz(workVal.endTime, 'HH:mm', timeZone!);

      // Get UTC times
      workVal.startTime = startMoment.clone().utc().format('HH:mm');
      workVal.endTime = endMoment.clone().utc().format('HH:mm');
      return workVal;
    });
    return workingHourinUTC;
  }

  function convertWorkingHoursToLocalTimes(
    workingHours: WorkingHour[],
  ): WorkingHour[] {
    // const timeZone = getUserTimeZone();
    const timeZone = props.timeZone; // This is for branch working hours because we want the working hours of that branch according to TIMEZONE the one entered while on boarding.
    // Create a moment object for the input start time which is in UTC
    const workingHourInLocal = workingHours.map(w => {
      let workVal = { ...w };

      const startMoment = moment.utc(workVal.startTime, 'HH:mm');

      // Convert UTC start time to local time
      workVal.startTime = startMoment.tz(timeZone!).format('HH:mm');

      // Create a moment object for the input end time in UTC
      const endMoment = moment.utc(workVal.endTime, 'HH:mm');

      // Convert UTC end time to local time
      workVal.endTime = endMoment.tz(timeZone!).format('HH:mm');

      return workVal;
    });

    return workingHourInLocal;
  }
  React.useEffect(() => {
    validateWorkingHours();
  }, [workingHours]);

  const validateWorkingHours = async () => {
    try {
      const workingHoursConverted = convertWorkingHoursToUtcTimes(workingHours);
      const timeZone = props.timeZone;

      const response = await axios.post(
        endpoints.branch.validateWorkingHours,
        workingHourListToRequest(workingHoursConverted),
        {
          headers: {
            'Content-Type': 'application/json',
            timezone: timeZone, // Add the timezone string to the headers
          },
        },
      );
      setWarningMessage('');
    } catch (error) {
      console.error('Error submitting form:', error);
      setWarningMessage(error.response.data['data'].toString());
    }
  };

  const onSubmit: SubmitHandler<WorkingHoursReasonSchemaType> = async () => {
    setLoading(true);
    try {
      const userData = getUserPersistedOnLocalStorage();
      const paramsMap = new Map<string, string>([['id', props.branchId]]);
      const filteredEndpoint = replaceParams(
        endpoints.branch.updateworkinghours,
        paramsMap,
      );
      const workingHoursConverted = convertWorkingHoursToUtcTimes(workingHours);

      //const timeZone = getUserTimeZone();
      const timeZone = props.timeZone;

      const response = await axios.put(
        filteredEndpoint,
        workingHourListToRequest(workingHoursConverted),
        {
          headers: {
            'Content-Type': 'application/json',
            timezone: timeZone, // Add the timezone string to the headers
          },
        },
      );
      if (response.data.status === 200)
        toast.success(
          response.data.message === 'success'
            ? 'Working hours updated successfully'
            : response.data.message,
        );

      setSuccess(response.data['message']);
      setError(null);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      toast.error(error.response.data['data'].toString());

      console.error('Error submitting form:', error);
      setError(error.response.data['data'].toString());
      setSuccess(null);

      setLoading(false);
    }
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col flex-wrap justify-start items-start">
            <div className="flex flex-col space-y-1 mt-1 w-full mb-10">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Working Hours
              </h3>
              <span className="text-sm text-gray-500">
                Set the working hours of the entity
              </span>
              <hr className="my-4" />
              {props.hasTimeZoneChanged && (
                <div className="sticky rounded top-0 start-0 z-50 w-full p-4 border-b border-[#FFCE42] bg-[#FFCE42] dark:bg-[#FFCE42] dark:border-[#FFCE42]">
                  <div className="flex flex-shrink-0 w-full mx-auto sm:w-auto">
                    <label className="flex mb-2 me-auto text-sm font-medium md:mb-0 md:me-4 md:m-0">
                      TimeZone has been changed . Please fill the working hours
                      again
                    </label>
                  </div>
                </div>
              )}
              {warningMessage && (
                <div className="sticky rounded top-0 start-0 z-50 w-full p-4 border-b border-[#FFCE42] bg-[#FFCE42] dark:bg-[#FFCE42] dark:border-[#FFCE42]">
                  <div className="flex flex-shrink-0 w-full mx-auto sm:w-auto">
                    <label className="flex mb-2 me-auto text-sm font-medium md:mb-0 md:me-4 md:m-0">
                      Warning! Conflicting working hours. Please correct the
                      working hours
                    </label>
                  </div>
                </div>
              )}

              <div className="h-4"></div>

              <div className="flex flex-row flex-wrap w-full xl:w-1/3 justify-between items-start space-x-4">
                <div className="flex-1 flex-col justify-start items-start space-y-4">
                  {weekdays.map(day =>
                    WorkingHourCard({
                      day,
                      workingHours: getWorkingHoursForDay(day),
                      handleCheckboxChange,
                      deleteWorkingHour,
                      addNewWorkingHourItem,
                      workingHourChange: handleWorkingHourChange,
                      validateWorkingHours: validateWorkingHours,
                    }),
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="my-4">
            <div className="fixed items-start w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-4 lg:-ml-8 shadow bg-gray-100">
              <Button variant={ButtonVariant.PRIMARY} type="submit">
                Submit
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

type WorkingHourCardProps = {
  day: string;
  workingHours: WorkingHour[];
  handleCheckboxChange: (day: string) => void;
  deleteWorkingHour: (workingHour: WorkingHour) => void;
  addNewWorkingHourItem: (day: string) => void;
  workingHourChange: (workingHour: WorkingHour) => void;
  validateWorkingHours: () => Promise<void>;
};

function WorkingHourCard({
  day,
  workingHours,
  handleCheckboxChange,
  deleteWorkingHour,
  addNewWorkingHourItem,
  workingHourChange: handleWorkingHourChange,
  validateWorkingHours: validateWorkingHours,
}: WorkingHourCardProps) {
  let isCheckboxChecked = workingHours.length !== 0;
  let bgColor = isCheckboxChecked ? 'bg-primary-100' : 'bg-white';
  let textColor = isCheckboxChecked ? 'text-primary' : 'text-gray-500';
  let outlineColor = isCheckboxChecked
    ? 'outline-primary-400'
    : 'outline-gray-200';

  return (
    <div
      className="flex flex-col w-full justify-between items-center"
      key={day}
    >
      <div
        className={`flex flex-row w-full justify-start items-center p-4 outline ${outlineColor} outline-1 hover:outline-2 rounded-xl shadow ${bgColor}`}
        onClick={() => handleCheckboxChange(day)}
      >
        <input
          type="checkbox"
          checked={isCheckboxChecked}
          className="h-4 w-4 mr-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
        />
        <span className={`text-sm ${textColor}`}>{day}</span>
      </div>

      {isCheckboxChecked && (
        <>
          <div className="flex flex-col w-full justify-start items-center my-4 space-y-2">
            {workingHours.map((workingHour, index) => (
              <WorkingHourItem
                index={index}
                key={`${day}-${index}`}
                workingHour={workingHour}
                validateWorkingHours={validateWorkingHours}
                handleWorkingHourChange={workingHour =>
                  handleWorkingHourChange(workingHour)
                }
                deleteWorkingHour={workingHour =>
                  deleteWorkingHour(workingHour)
                }
              />
            ))}
          </div>
          <OutlinedButton
            variant={ButtonVariant.TERTIARY}
            type="button"
            className="flex w-full my-2"
            onClick={() => addNewWorkingHourItem(day)}
          >
            + Add slot
          </OutlinedButton>
        </>
      )}
    </div>
  );
}

type WorkingHourItemProps = {
  index: number;
  workingHour: WorkingHour;
  handleWorkingHourChange: (workingHour: WorkingHour) => void;
  deleteWorkingHour: (workingHour: WorkingHour) => void;
  validateWorkingHours: () => Promise<void>;
};

function WorkingHourItem({
  index,
  workingHour,
  handleWorkingHourChange,
  deleteWorkingHour,
  validateWorkingHours,
}: WorkingHourItemProps) {
  const changeTime = (startTime: string, endTime: string) => {
    const updatedWorkingHour = {
      ...workingHour,
      startTime,
      endTime,
    };
    handleWorkingHourChange(updatedWorkingHour);
  };

  return (
    <div className="flex flex-row w-full justify-start items-center p-4 outline outline-gray-200 outline-1 rounded-xl shadow bg-white space-x-2">
      <input
        name={`workingHour[${index}].startTime`}
        type="time"
        className="w-1/3 h-8 px-2 text-sm text-gray-500 placeholder-gray-500 border rounded-lg focus:shadow-outline"
        placeholder="Start Time"
        step={900}
        onChange={e => changeTime(e.target.value, workingHour.endTime)}
        value={workingHour.startTime}
      />

      <input
        name={`workingHour[${index}].endTime`}
        type="time"
        className="w-1/3 h-8 px-2 text-sm text-gray-500 placeholder-gray-500 border rounded-lg focus:shadow-outline"
        placeholder="End Time"
        onChange={e => changeTime(workingHour.startTime, e.target.value)}
        value={workingHour.endTime}
      />

      <button
        type="button"
        className="w-1/3 h-8 px-2 text-sm text-primary-500 placeholder-primary-500 border hover:border-gray-400 rounded-lg focus:shadow-outline"
        onClick={() => deleteWorkingHour(workingHour)}
      >
        Delete
      </button>
    </div>
  );
}
