import { useFormContext } from 'react-hook-form';
import React from 'react';

// ----------------------------------------------------------------------

type Props = {
  placeholder?: string; // 'string' | React.ReactNode;
  type?: string; // 'text' | 'number' | 'password' | 'email' | 'search' | 'tel' | 'url' | 'date' | 'time' | 'datetime-local' | 'month' | 'week' | 'currency';

  name: string;
  label?: string;
  value?: string | number | readonly string[] | undefined;
  required?: boolean;
  generateOptions?: () => JSX.Element[];
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  accept?: string;
  checked?: boolean;
  mandatoryLabel?: boolean;
};

export default function InputField({
  name,
  label,
  placeholder,
  type,
  ...other
}: Props) {
  const {
    register,
    formState: { errors, isValid },
  } = useFormContext();

  return (
    <>
      <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
        {label}
        {other.mandatoryLabel && <span className="text-red-500"> *</span>}
      </label>
      <div className="mt-1">
        {type === 'select' ? (
          <select
            {...other}
            {...register(name, {
              required: other.required,
              onChange: other.onChange,
              onBlur: other.onBlur,
              disabled: other.disabled,
            })}
            // helperText={helperText}
            placeholder={placeholder}
            required={other.required}
            key={name}
            value={other.value}
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${other.className}`}
          >
            <option key="" value="">
              Select
            </option>
            {other.generateOptions?.()}
          </select>
        ) : type === 'tel' ? (
          <input
            {...other}
            {...register(name, {
              required: other.required,
              valueAsNumber: true,
              onChange: other.onChange,
              onBlur: other.onBlur,
              disabled: other.disabled,
            })}
            // helperText={helperText}
            placeholder={placeholder}
            required={other.required}
            value={other.value}
            key={name}
            type="tel"
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${other.className}`}
          />
        ) : type === 'number' ? (
          <input
            {...other}
            {...register(name, {
              required: other.required,
              valueAsNumber: true,
              onChange: other.onChange,
              onBlur: other.onBlur,
              disabled: other.disabled,
            })}
            // helperText={helperText}
            placeholder={placeholder}
            required={other.required}
            value={other.value}
            key={name}
            type="number"
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${other.className}`}
          />
        ) : type === 'radio' ? (
          <input
            {...other}
            {...register(name, { required: other.required })}
            // helperText={helperText}
            required={other.required}
            key={name}
            type={type}
            value={other.value}
            onChange={other.onChange}
            checked={other.checked}
            className={`lock rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${other.className}`}
          />
        ) : type === 'textarea' ? (
          <textarea
            {...other}
            {...register(name, {
              required: other.required,
              onChange: other.onChange,
              onBlur: other.onBlur,
              disabled: other.disabled,
            })}
            placeholder={placeholder}
            required={other.required}
            key={name}
            value={other.value}
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${other.className}`}
          />
        ) : type === 'checkbox' ? (
          <input
            {...other}
            {...register(name, { required: other.required })}
            // helperText={helperText}
            required={other.required}
            key={name}
            type={type}
            value={other.value}
            onChange={other.onChange}
            checked={other.checked}
            className={``}
          />
        ) : (
          <input
            {...other}
            {...register(name, {
              required: other.required,
              onChange: other.onChange,
              onBlur: other.onBlur,
              disabled: other.disabled,
            })}
            // helperText={helperText}
            value={other.value}
            placeholder={placeholder}
            required={other.required}
            key={name}
            type={type}
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${other.className}`}
          />
        )}
      </div>
    </>
  );
}
