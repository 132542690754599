import React, { Fragment, useEffect, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Button } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import { v4 as uuidv4 } from 'uuid';

export interface TherapiesFieldData {
  tid: string;
  therapyName: string;
}

export interface TherapiesFieldDataProps {
  fieldDataList: TherapiesFieldData[];
  fieldName: String;

  addTherapyTo: any;
}

export default function TherapiesAutocompleteInput({
  fieldDataList,
  fieldName,
  addTherapyTo,
}: TherapiesFieldDataProps) {
  const [selected, setSelected] = useState(fieldDataList[0]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    setSelected(fieldDataList[0]);
  }, [fieldDataList]);

  const filteredField =
    query === ''
      ? fieldDataList
      : fieldDataList.filter(fld =>
          fld.therapyName
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, '')),
        );

  return (
    <>
      <div className="flex">
        <div className="w-72">
          <Combobox
            value={selected}
            onChange={e => {
              setSelected(e);
            }}
          >
            <div className="relative mt-1">
              <div className="relative w-full cursor-default overflow-hidden rounded-lg text-left shadow-sm border-0  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                <Combobox.Input
                  className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                  displayValue={(fld: any) => fld.therapyName}
                  onChange={event => setQuery(event.target.value)}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {/*                 {query.length > 0 && (
                  <Combobox.Option value={{ id: '', name: query }}>
                    Create "{query}"
                  </Combobox.Option>
                )} */}

                  {filteredField.map(fld => (
                    <Combobox.Option
                      key={fld.tid}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? 'bg-primary-100 text-primary-700'
                            : 'text-gray-900'
                        }`
                      }
                      value={fld}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {fld.therapyName}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active
                                  ? 'text-primary-100 bg-primary-100'
                                  : 'text-teal-600'
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
      </div>
      <div>
        <Button
          variant={ButtonVariant.PRIMARY}
          type="button"
          isDisabled={query === '' && fieldDataList.length == 0}
          className="ml-3"
          onClick={() => {
            addTherapyTo(
              filteredField.length === 0 && query !== ''
                ? { tid: uuidv4(), therapyName: query }
                : selected,
            );
          }}
        >
          Add
        </Button>
      </div>
    </>
  );
}
