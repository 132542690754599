import { Button, OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import useAxios from "@routes/hooks/use-axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ManagePatientNewContext } from "./ManagePatientNew";
import { endpoints, replaceParams } from "@utils/axios";
import { StarIcon } from "@heroicons/react/20/solid";

import { useForm } from "react-hook-form";

import Applabel from "@components/hook-form/applabel";
import { Convert, Rating } from "../types/Rating";
import moment from "moment";

export const RatingDetailsListSchema = z.object({
  id: z.string(),
  clientId: z.string(),
  entityId: z.string(),
  entityBranchId: z.string(),
  entityUserId: z.string(),
  entityUserRating: z.number(),
  entityUserRatingComment: z.string(),
  entityRating: z.number(),
  entityRatingComment: z.string(),
  treatmentPlanRating: z.number(),
  treatmentPlanRatingComment: z.string(),
  createdDate: z.date(),
  updatedDate: z.date(),
});

export type RatingDetailsListSchemaType = z.infer<
  typeof RatingDetailsListSchema
>;

export default function PatientRating(props: { patientId: string }) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<RatingDetailsListSchemaType>({
    resolver: zodResolver(RatingDetailsListSchema),
  });

  const [patientRating, setPatientRating] = React.useState<Rating[] | null>(
    null
  );
  const [staffRating, setStaffRating] = React.useState<Rating[] | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [isEditable, setIsEditable] = React.useState(false);
  const [selected, setSelected] = React.useState<Rating[] | null>(null);
  const [activeTab, setActiveTab] = useState("Rating");

  const navigate = useNavigate();

  const axios = useAxios();

  const handleTabClick = (
    tab: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setActiveTab(tab);
  };
  //const patientId = useContext(ManagePatientNewContext);
  const getRatingList = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["clientid", props.patientId],
      ]);
      const filteredEndpoint = replaceParams(endpoints.rating.view, paramsMap);
      const response = await axios.get(filteredEndpoint);

      if (response.status === 200) {
        if (response.data && response.data["data"]) {
          const data = response.data["data"];
          const convertedRatingList = data.map((rating: Rating) =>
            Convert.toRating(JSON.stringify(rating))
          );
          // Sort rating list by createdDate in descending order
          const sortedRatingList = convertedRatingList.sort((a: Rating, b: Rating) => {
            return moment(b.createdDate).valueOf() - moment(a.createdDate).valueOf();
          });
          setPatientRating(sortedRatingList);
          if (sortedRatingList.length > 0) {
            setSelected(sortedRatingList[0]);
          }
        }
      }
      setIsEditable(false);
      setLoading(false);
    } catch (error) {
      console.error("Error getting Rating list", error);
      setLoading(false);
    }
  };

  const getEntityUserRatingList = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["clientid", props.patientId],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.rating.entityUserView,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);

      if (response.status === 200) {
        if (response.data && response.data["data"]) {
          const data = response.data["data"];
          const convertedRatingList = data.map((rating: Rating) =>
            Convert.toRating(JSON.stringify(rating))
          );
          // Sort rating list by createdDate in descending order
          const sortedRatingList = convertedRatingList.sort((a: Rating, b: Rating) => {
            return moment(b.createdDate).valueOf() - moment(a.createdDate).valueOf();
          });
          setStaffRating(sortedRatingList);
        }
      }
      setIsEditable(false);
      setLoading(false);
    } catch (error) {
      console.error("Error getting Rating list", error);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    setIsEditable(false);

    getRatingList();
    getEntityUserRatingList();
  }, []);

  return (
    <div className="grid-cols-1 gap-x-8 gap-y-8 pt-2 md:grid-cols-3 bg-grey-200 ">
      <form>
        {!loading ? (
          <>
            <div className="flex flex-col w-full space-y-4">
              <div className="flex space-x-4">
                <button
                  className={`px-4 py-2 rounded-md ${activeTab === "Rating" ? "bg-gray-200" : ""
                    }`}
                  onClick={(event) => handleTabClick("Rating", event)}
                >
                  Rating
                </button>
                {/* Tab 2 */}
                <button
                  className={`px-4 py-2 rounded-md ${activeTab === "Staff Rating" ? "bg-gray-200" : ""
                    }`}
                  onClick={(event) => handleTabClick("Staff Rating", event)}
                >
                  Staff Rating
                </button>
              </div>
              <div>
                {activeTab === "Rating" && (
                  <div>
                    {patientRating &&
                      patientRating.map((single) => (
                        <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                          <div className="flex flex-col w-full grow border-2 rounded-lg h-full mt-4 p-4">
                            <div key={single.id}>
                              <span className="font-semibold mt-2 text-sm">
                                Created Date
                              </span>
                              <div className="mt-2 flex items-center mb-2">
                                <span className="mb-2 text-sm">
                                  {single.createdDate
                                    ?
                                    moment(single.createdDate).format("DD-MM-YYYY").toLocaleString()
                                    : "No date available"}
                                </span>
                              </div>
                            </div>
                            <div key={single.id} className="flex items-center">
                              <div className="flex items-center sm:flex-col sm:items-start">
                                <span className="font-semibold mt-2 text-sm">
                                  Entity Rating
                                </span>
                                <div className="mt-2 flex items-center mb-2">
                                  {[0, 1, 2, 3, 4].map((rating) => (
                                    <StarIcon
                                      key={rating}
                                      className={`h-5 w-5 flex-shrink-0 ${single.entityRating &&
                                        single.entityRating > rating
                                        ? "text-[#FFDF00]"
                                        : "text-gray-200"
                                        }`}
                                      aria-hidden="true"
                                    />
                                  ))}
                                </div>
                                <span className="font-semibold mt-2 text-sm">
                                  Treatment plan Rating
                                </span>
                                <div className="mt-2 flex items-center mb-2">
                                  {[0, 1, 2, 3, 4].map((rating) => (
                                    <StarIcon
                                      key={rating}
                                      className={`h-5 w-5 flex-shrink-0 ${single.treatmentPlanRating &&
                                        single.treatmentPlanRating > rating
                                        ? "text-[#FFDF00]"
                                        : "text-gray-200"
                                        }`}
                                      aria-hidden="true"
                                    />
                                  ))}
                                </div>
                              </div>
                              <div className="order-2 mt-6 sm:ml-16 sm:mt-0">
                                <div>
                                  <span className="font-semibold mt-2 text-sm">
                                    Entity Rating Comment{" "}
                                  </span>
                                  <div className="mt-2 flex items-center mb-2">
                                    <span className="mb-2 text-sm">
                                      {single.entityRatingComment}{" "}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <span className="font-semibold mt-2 text-sm">
                                    Treatment plan Rating Comment{" "}
                                  </span>
                                  <div className="mt-2 flex items-center mb-2">
                                    <span className="mb-2 text-sm">
                                      {single.treatmentPlanRatingComment}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
                {activeTab === "Staff Rating" && (
                  <div>
                    {staffRating?.map((single) => (
                      <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                        <div className="flex flex-col w-full grow border-2 rounded-lg h-full mt-4 p-4">
                          <div key={single.id}>
                            <span className="font-semibold mt-2 text-sm">
                              Created Date
                            </span>
                            <div className="mt-2 flex items-center mb-2">
                              <span className="mb-2 text-sm">
                                {single.createdDate
                                  ?
                                  moment(single.createdDate).format("DD-MM-YYYY").toLocaleString()
                                  : "No date available"}
                              </span>
                            </div>
                          </div>
                          <div key={single.id} className="flex items-center">
                            <div className="flex items-center sm:flex-col sm:items-start">
                              <span className="font-semibold mt-2 text-sm">
                                Entity User Rating
                              </span>
                              <div className="mt-2 flex items-center mb-2">
                                {[0, 1, 2, 3, 4].map((rating) => (
                                  <StarIcon
                                    key={rating}
                                    className={`h-5 w-5 flex-shrink-0 ${single.entityUserRating &&
                                      single.entityUserRating > rating
                                      ? "text-[#FFDF00]"
                                      : "text-gray-200"
                                      }`}
                                    aria-hidden="true"
                                  />
                                ))}
                              </div>
                            </div>
                            <div className="order-2 mt-6 sm:ml-16 sm:mt-0">
                              <div>
                                <span className="font-semibold mt-2 text-sm">
                                  Entity User Rating Comment{" "}
                                </span>
                                <div>
                                  <span className="mb-2 text-sm">
                                    {single.entityUserRatingComment}{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </form>
    </div>
  );
}
