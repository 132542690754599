import React, { useContext, useEffect, useState } from "react";
import { ButtonVariant } from "@components/button/button";
import SolidButton from "@components/button/button";
import Button from "@components/button/button";
import CreatableSelect from "react-select/creatable";
import Select, { StylesConfig } from "react-select";

import { v4 as uuidv4 } from "uuid";
import Spreadsheet, {
  CellBase,
  EmptySelection,
  Matrix,
  Mode,
  Point,
  Selection,
} from "react-spreadsheet";
import moment from "moment";
import { assessmentOptions, typeOfParameter } from "./DropDownList";
import {
  Assessment,
  Convert,
  Parameters,
  Value,
} from "./types/AssessmentTypes";
import {
  AssessmentParameterList,
  ParameterListItem,
  TypeOfParameter,
  jsonToAssessmentParameterList,
} from "./types/DropDownTypes";
import { useNavigate } from "react-router-dom";
import useAxios from "@routes/hooks/use-axios";
import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";
import { endpoints, replaceParams } from "@utils/axios";
import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";
import "./caStyles.css";

import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import {
  SelectEditLRBN,
  SelectEditNGP,
  SelectEditYesNo,
  SelectViewLRBN,
  SelectViewNGP,
  SelectViewYesNo,
} from "./SelectDataComponents";

import { ActionMeta, SingleValue } from "react-select";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { log } from "console";
import { stringify } from "querystring";
import CustomAssessmentGraphs from "./CustomAssessmentGraphs";

type CustomAsessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function CustomAssessmentComponent(props: CustomAsessmentProps) {
  const [selectedAssessment, setSelectedAssessment] =
    useState<AssessmentParameterList | null>(null);
  const [selectedParameter, setSelectedParameter] =
    useState<ParameterListItem | null>(null);

  const [selectedType, setSelectedType] = useState<TypeOfParameter | null>();
  const [selectedUnits, setSelectedUnits] = useState<string>("");

  const [selectedDate, setSelectedDate] = React.useState<string>();
  const [selectedData, setSelectedData] = useState<Assessment[]>([]);
  const [isOnchangeRecieved, setisOnchangeRecieved] = useState<boolean>(false);

  const [isCellSelected, setIsCellSelected] = useState<boolean>(false);
  const [cellEditMode, setCellEditMode] = React.useState<Mode>();

  const [customAssessmentId, setCustomAssessmentId] = useState<string>("");

  // const [selected, setSelected] = React.useState<Selection>(
  //   new EmptySelection(),
  // );
  // const [isDeletePressed, setIsDeletePressed] = React.useState(false);

  const [assessmentDropDownList, setAssessmentDropDownList] = useState<
    AssessmentParameterList[]
  >([]);

  const [matrix, setMatrix] = useState<Matrix<CustomAssessmentCell>>();
  const [shadowMatrix, setShadowMatrix] =
    useState<Matrix<CustomAssessmentCell>>();

  const [payLoadMatrix, setPayloadMatrix] =
    useState<Matrix<CustomAssessmentCell>>();

  const [columnLabels, setColumnLabels] = useState<string[]>([
    "Assessment",
    "Parameters",
  ]);

  const [submitted, setSubmitted] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const userData = getUserPersistedOnLocalStorage();

  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);

  React.useEffect(() => {
    const getDropdownList = async () => {
      try {
        const paramsMap = new Map<string, string>([
          ["entityId", userData?.entity_id!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.getDropDownFormat,
          paramsMap
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data["data"]?.format;
        const converted = data?.map((assessment: any) =>
          jsonToAssessmentParameterList(JSON.stringify(assessment))
        );
        setAssessmentDropDownList(converted);
      } catch (error) {
        toast.error(error.message);
      }
    };

    getDropdownList();
  }, []);

  React.useEffect(() => {
    const initializeAssessment = async () => {
      if (props.assessmentId !== "000000000000000000000000") {
        try {
          const paramsMap = new Map<string, string>([
            ["mid", props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getCustomAssessment,
            paramsMap
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data["data"].customAssessmentDetails;
          let converted;
          if (data) {
            converted = data.map((assessment: any) =>
              Convert.toAssessment(JSON.stringify(assessment))
            );
          }

          if (converted) {
            const dateColumns = extractUniqueDates(converted);
            setColumnLabels((prevColumns) => [...prevColumns, ...dateColumns]);
            setSelectedData(converted);
          }
          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          console.error("Error fetching Assessments:", error);

          setLoading(false);
        }
      }
    };

    const dateToday = moment().format("yyyy-MM-DD");
    setSelectedDate(dateToday);

    initializeAssessment();
  }, [submitted]);

  const onSubmit = async (payload: Assessment[]) => {
    if (props.assessmentId !== "000000000000000000000000") {
      try {
        const paramsMap = new Map<string, string>([
          ["id", props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateCustomAssessment,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, {
          masterAssessmentId: props.masterAssessmentId,
          customAssessmentDetails: payload,
          clientId: props.patientId,
          entityId: userData?.entity_id,
          entityBranchId: userData?.branch_id,
        });
        if (response.data.status === 200) {
          setSubmitted(true);
          toast.success(
            response.data.message === "success"
              ? "Custom Assessment updated successfully"
              : response.data.message
          );
        }
        // props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    } else {
      try {
        // MuscleTightnessSchema.parse(data);

        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createCustomAssessment,
          paramsMap
        );
        const response = await axios.post(filteredEndpoint, {
          masterAssessmentId: props.masterAssessmentId,
          customAssessmentDetails: payload,
          clientId: props.patientId,
          entityId: userData?.entity_id,
          entityBranchId: userData?.branch_id,
        });
        if (response.data.status === 200) {
          toast.success(
            response.data.message === "success"
              ? "Custom Assessment created successfully"
              : response.data.message
          );

          setCustomAssessmentId(response.data.data);
        }
        // props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    }
  };

  interface CustomAssessmentCell extends CellBase<any> {
    rowSpan?: number;
  }

  interface CustomParameterCell extends CellBase<any> {
    assessmentName?: string;
    validation?: string;
    units: string;
  }

  interface CustomDateCell extends CellBase<any> {
    date?: string;
  }

  const addNewValueToParametersColumn = (selectedDate: string) => {
    let toAdd: { date: string; value: any };

    const updateAsessments = selectedData.map((assessment) => ({
      ...assessment,
      parameters: assessment.parameters.map((parameter) => {
        switch (parameter.validation) {
          case "Text":
            toAdd = {
              date: selectedDate,
              value: "",
            };
            break;
          case "Number":
            toAdd = {
              date: selectedDate,
              value: "",
            };
            break;
          case "Yes/No":
            toAdd = {
              date: selectedDate,
              value: "no",
            };
            break;
          case "Left/Right/Both/None":
            toAdd = {
              date: selectedDate,
              value: "none",
            };
            break;
          case "Normal/Good/Poor":
            toAdd = {
              date: selectedDate,
              value: "normal",
            };
            break;
          default:
            toAdd = {
              date: selectedDate,
              value: "",
            };
        }
        return {
          ...parameter,
          values: [...parameter.values, toAdd],
          validation: parameter.validation || "string",
        };
      }),
    }));

    setSelectedData(updateAsessments);
  };

  const addColumn = (date: string) => {
    if (matrix) {
      const newColumnLabel = moment(date).format("DD/MM/YY");

      if (!columnLabels.includes(newColumnLabel)) {
        setColumnLabels((prevColumns) => [...prevColumns, newColumnLabel]);

        addNewValueToParametersColumn(newColumnLabel);
      }
    }
  };

  function extractUniqueDates(assessments: Assessment[]): string[] {
    const uniqueDatesSet = new Set<string>();

    assessments.forEach((assessments) => {
      assessments.parameters.forEach((parameter) => {
        parameter.values.forEach((value) => {
          uniqueDatesSet.add(value.date);
        });
      });
    });

    // Convert the set to an array and sort it in ascending order using moment.js
    const uniqueDatesArray = Array.from(uniqueDatesSet).sort((dateA, dateB) => {
      return moment(dateA, "DD/MM/YY").diff(moment(dateB, "DD/MM/YY"));
    });

    return uniqueDatesArray;
  }

  function createMatrixFromAssessmentsData(
    assessments: Assessment[]
  ): Matrix<CustomAssessmentCell> {
    const matrix: Matrix<CustomAssessmentCell> = [];

    assessments.forEach((assessment) => {
      for (let i = 0; i < assessment.parameters.length; i++) {
        const row: CustomDateCell[] = [];

        const assessmentNameCell: CustomAssessmentCell = {
          value: assessment.assessmentName,
          rowSpan: assessment.parameters.length,
        };
        const parameterCell: CustomParameterCell = {
          value: assessment.parameters[i].name,
          assessmentName: assessment.assessmentName,
          validation: assessment.parameters[i].validation,
          units: assessment.parameters[i].units,
        };

        for (let j = 2; j < columnLabels.length; j++) {
          const value = assessment.parameters[i].values.find(
            (val) => val.date === columnLabels[j]
          );

          if (assessment.parameters[i].validation === "Yes/No") {
            row.push({
              value: value ? (value.value ? value.value.toString() : "") : "",

              date: value ? value.date : columnLabels[j],

              DataEditor: SelectEditYesNo,
            });
          } else if (
            assessment.parameters[i].validation === "Left/Right/Both/None"
          ) {
            row.push({
              value: value ? (value.value ? value.value.toString() : "") : "",

              date: value ? value.date : columnLabels[j],

              DataEditor: SelectEditLRBN,
            });
          } else if (
            assessment.parameters[i].validation === "Normal/Good/Poor"
          ) {
            row.push({
              value: value ? (value.value ? value.value.toString() : "") : "",

              date: value ? value.date : columnLabels[j],

              DataEditor: SelectEditNGP,
            });
          } else {
            row.push({
              value: value ? (value.value ? value.value.toString() : "") : "",
              date: value ? value.date : columnLabels[j],
            });
          }
        }
        if (i === 0) {
          matrix.push([assessmentNameCell, parameterCell, ...row]);
        } else {
          matrix.push([undefined, parameterCell, ...row]);
        }
      }
    });

    return matrix;
  }

  const handleAssessmentChange = (
    newValue: SingleValue<AssessmentParameterList>,
    actionMeta: ActionMeta<AssessmentParameterList>
  ) => {
    const selectedAssessmentName = newValue;
    const assessment = assessmentDropDownList.find(
      (assessment) => assessment.value === selectedAssessmentName?.value
    );
    setSelectedAssessment(assessment || null);
    setSelectedParameter(null);
  };

  const handleAssessmentChangeOnCreate = (inputValue: string) => {
    const selectedAssessmentName = inputValue;
    if (selectedAssessmentName.trim() !== "")
      setSelectedAssessment({
        id: uuidv4(),
        value: selectedAssessmentName,
        label: selectedAssessmentName,
        parameters: [],
      });
    else setSelectedAssessment(null);

    setSelectedParameter(null);
  };

  const handleParameterChange = (
    newValue: SingleValue<ParameterListItem>,
    actionMeta: ActionMeta<ParameterListItem>
  ) => {
    const selectedParameterName = newValue;
    let parameter = null;
    if (selectedAssessment?.parameters) {
      parameter = selectedAssessment?.parameters.find(
        (parameter) => parameter.value === selectedParameterName?.value
      );
    }
    setSelectedParameter(parameter || null);
    setSelectedType(null);
  };

  const handleParameterChangeonCreate = (inputValue: string) => {
    const selectedParameterName = inputValue;

    if (selectedParameterName.trim() !== "")
      setSelectedParameter({
        id: uuidv4(),
        label: selectedParameterName,
        value: selectedParameterName,
      });
    else setSelectedParameter(null);

    setSelectedType(null);
  };

  const handleTypeChange = (
    newValue: SingleValue<TypeOfParameter>,
    actionMeta: ActionMeta<TypeOfParameter>
  ) => {
    const selectedTypeName = newValue;
    const parameter = typeOfParameter.find(
      (parameter) => parameter.value === selectedTypeName?.value
    );
    //
    setSelectedType(selectedTypeName);
  };

  // const handleSelect = React.useCallback((selection: Selection) => {
  //
  //   //console.log(selection.hasEntireRow(1));

  //   setSelected(selection);
  // }, []);

  const handleAddClick = () => {
    if (selectedAssessment && selectedParameter && selectedType) {
      const assessmentIndex = selectedData.findIndex(
        (assessment) => assessment.assessmentName === selectedAssessment.value
      );

      if (assessmentIndex !== -1) {
        // If the assessment already exists in selectedData
        const updatedSelectedData = [...selectedData];
        const parameterIndex = updatedSelectedData[
          assessmentIndex
        ].parameters.findIndex(
          (parameter) => parameter.name === selectedParameter.value
        );

        if (parameterIndex === -1) {
          // If the parameter doesn't exist, add it
          updatedSelectedData[assessmentIndex].parameters.push({
            id: selectedParameter.id,
            name: selectedParameter.value,
            values: [],
            validation: selectedType.value,
            units: selectedUnits,
          });
          setSelectedData(updatedSelectedData);
        } else {
          toast.warning("Parameter Already Exists");
        }
      } else {
        // If the assessment doesn't exist, add it with the parameter
        setSelectedData((prevSelectedData) => [
          ...prevSelectedData,
          {
            customAssessmentId: selectedAssessment.id,
            assessmentName: selectedAssessment.value,
            parameters: [
              {
                id: selectedParameter.id,
                name: selectedParameter.value,
                values: [],
                validation: selectedType.value,
                units: selectedUnits,
              },
            ],
          },
        ]);
      }
      setSelectedUnits("");
    } else {
      toast.warning("Select Assessment ,Parameter and Type");
      return;
    }
    setSelectedAssessment(null);
    setSelectedParameter(null);
    setSelectedType(null);
    setSelectedUnits("");
  };

  function convertData(data: any[][]): Assessment[] {
    //
    const assessments: Assessment[] = [];

    let currentAssessment: Assessment | null = null;
    let currentParameter: Parameters | null = null;

    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const assessmentCell: CustomAssessmentCell = row[0];
      const parameterCell: CustomParameterCell = row[1];
      const valueCells: Value[] = row.slice(2);
      const allValuesUndefined = valueCells.every(
        (cell) => cell.value === undefined
      );
      if (
        assessmentCell === undefined &&
        parameterCell.value === undefined &&
        allValuesUndefined
      ) {
        continue;
      } else if (
        assessmentCell &&
        assessmentCell.value === undefined &&
        parameterCell.value === undefined &&
        allValuesUndefined
      ) {
        if (assessmentCell.rowSpan) {
          if (assessmentCell.rowSpan === 1) {
            continue;
          } else {
            data[i + 1][0] = {
              value: data[i + 1][1].assessmentName,
              rowSpan: assessmentCell.rowSpan - 1,
            };
          }
        }
      } else {
        if (assessmentCell) {
          currentAssessment = {
            customAssessmentId: assessmentCell.value.toLowerCase(),
            assessmentName: assessmentCell.value,
            parameters: [],
          };
          assessments.push(currentAssessment);
        }

        // if (parameterCell.validation === 'number') {
        //   // throw new Error if the value is not a number
        //
        //   valueCells.forEach(cell => {
        //     if (cell.value) {
        //       // try to convert the value to a number
        //       const value = parseFloat(cell.value.toString());
        //       if (isNaN(value)) {
        //         throw new Error('Value is not a number');
        //       }
        //     }
        //   });
        // } else if (parameterCell.validation === 'string') {
        //   valueCells.forEach(cell => {
        //     if (cell.value) {
        //       cell.value = cell.value.toString();
        //     }
        //   });
        // }

        if (parameterCell) {
          currentParameter = {
            id: parameterCell.value.toLowerCase(),
            name: parameterCell.value,
            values: [],
            validation: parameterCell.validation || "string",
            units: parameterCell.units,
          };
          currentAssessment?.parameters.push(currentParameter);
        }

        if (currentParameter) {
          currentParameter.values = valueCells.map<Value>((cell) => {
            return {
              date: cell.date,
              value: cell.value,
            } as Value;
          });
        }
      }
    }

    return assessments;
  }

  useEffect(() => {
    const createdmatrix = createMatrixFromAssessmentsData(selectedData);

    setMatrix(createdmatrix);
  }, [selectedData]);

  const handleCellChange = (data: Matrix<CustomAssessmentCell>) => {};

  const colourStyles: StylesConfig<AssessmentParameterList> = {
    menu: (styles) => ({ ...styles, backgroundColor: "red" }),
  };

  return (
    <div className="flex flex-col space-y-4 w-full h-full">
      <div className="flex flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Custom Assessment
        </h3>
        <h5 className="text-md leading-6 font-bold text-gray-400">
          Please Click on Create/Update to Save
        </h5>
      </div>

      <hr className="w-full" />

      <div className="flex flex-col lg:flex-row justify-between justify-items-end align-bottom space-x-4 w-full h-full gap-2">
        <div className="flex flex-col lg:flex-row w-full gap-2">
          <div className="flex flex-col mx-2 w-full ">
            <label htmlFor="assessment">Assessment</label>
            <CreatableSelect
              id="assessment"
              isClearable
              value={selectedAssessment}
              styles={{
                menu: (base) => ({
                  ...base,
                  zIndex: "2",
                }),
              }}
              options={assessmentDropDownList}
              onChange={handleAssessmentChange}
              onCreateOption={handleAssessmentChangeOnCreate}
            />
          </div>
          <div className="flex flex-col mx-2 w-full">
            <label htmlFor="parameter">Parameters</label>
            <CreatableSelect
              id="parameter"
              isClearable
              value={selectedParameter}
              styles={{
                menu: (base) => ({
                  ...base,
                  zIndex: "2",
                }),
              }}
              options={selectedAssessment?.parameters ?? undefined}
              onChange={handleParameterChange}
              onCreateOption={handleParameterChangeonCreate}
            />
          </div>
          <div className="flex flex-col mx-2 w-full">
            <label htmlFor="parameter">Type</label>
            <Select
              value={selectedType}
              styles={{
                menu: (base) => ({
                  ...base,
                  zIndex: "2",
                }),
              }}
              options={typeOfParameter}
              onChange={handleTypeChange}
            />
          </div>

          <div className="flex flex-col mx-2 self-center justify-end flex-shrink-0">
            <SolidButton
              variant={ButtonVariant.PRIMARY}
              className="mr-2 mt-2"
              onClick={handleAddClick}
              isDisabled={
                !selectedAssessment || !selectedParameter || !selectedType
              }
            >
              Add Assesment Parameter
            </SolidButton>
          </div>
        </div>
        {/* /vertical divider */}
        <div className="flex flex-row flex-shrink-0">
          <div className="flex flex-col mx-2 ">
            <label htmlFor="date">Date</label>
            <input
              id="date"
              name="appointmentDate"
              className={`flex lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
              type="date"
              value={selectedDate}
              onChange={(e) => {
                setSelectedDate(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col mx-2 self-center flex-shrink-0">
            <div className="flex flex-row ">
              <SolidButton
                variant={ButtonVariant.PRIMARY}
                className="mr-2 mt-2   flex-shrink-0 w-full"
                onClick={() => {
                  if (selectedDate) addColumn(selectedDate);
                }}
              >
                Add Date
              </SolidButton>
            </div>
          </div>
        </div>
      </div>

      <hr className="w-full " />

      {matrix ? (
        <Spreadsheet
          data={matrix}
          columnLabels={columnLabels}
          onChange={(data) => {
            setShadowMatrix(data);
          }}
          hideRowIndicators={true}
          onBlur={() => {
            try {
              if (shadowMatrix) {
                const payload = convertData(shadowMatrix);

                setSelectedData(payload);
              }
            } catch (error) {
              console.error("Error converting data:", error);

              toast.error("Error converting data");
            }
          }}
          className="flex-grow"
        />
      ) : (
        <></>
      )}

      <hr className="w-full" />

      <CustomAssessmentGraphs
        selectedData={selectedData}
        columnLabels={columnLabels}
      ></CustomAssessmentGraphs>

      <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
        <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
          <OutlinedButton
            type="button"
            onClick={() => {
              props.postSubmitCallback();
            }}
            className="mr-2"
            variant={ButtonVariant.PRIMARY}
          >
            Close
          </OutlinedButton>
          {props.assessmentId !== "000000000000000000000000" ||
          customAssessmentId !== "" ? (
            <Button
              type="submit"
              key="UpdateButton"
              onClick={() => {
                onSubmit(selectedData);
              }}
              variant={ButtonVariant.PRIMARY}
            >
              Update
            </Button>
          ) : (
            <Button
              type="submit"
              key="CreateButton"
              onClick={() => {
                onSubmit(selectedData);
              }}
              variant={ButtonVariant.PRIMARY}
            >
              Create
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
