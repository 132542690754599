import { Button, OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import useAxios from "@routes/hooks/use-axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ManagePatientNewContext } from "./ManagePatientNew";
import { endpoints, replaceParams } from "@utils/axios";

import { useForm } from "react-hook-form";

import Applabel from "@components/hook-form/applabel";
import { Convert, FeedbackDetails } from "../types/FeedbackDetails";
import moment from "moment";

export const FeedbackSchema = z.object({
  question: z.string(),
  answer: z.string(),
});
export type Feedback = z.infer<typeof FeedbackSchema>;

export const FeedbackDetailsSchema = z.object({
  id: z.string(),
  clientId: z.string(),
  entityId: z.string(),
  entityBranchId: z.string(),
  entityUserId: z.string(),
  feedbackdate: z.coerce.date(),
  feedback: z.array(FeedbackSchema),
});
export type FeedbackDetailsSchemaType = z.infer<typeof FeedbackDetailsSchema>;

export default function PatientFeedback(props: {
  handleCloseRightOffCanvas: () => void;
  patientId: string;
}) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<FeedbackDetailsSchemaType>({
    resolver: zodResolver(FeedbackDetailsSchema),
  });

  const [patientFeedback, setPatientFeedback] = React.useState<
    FeedbackDetails[] | null
  >(null);

  const [loading, setLoading] = React.useState(true);
  const [isEditable, setIsEditable] = React.useState(false);
  const [selected, setSelected] = React.useState<FeedbackDetails | null>(null);

  const navigate = useNavigate();

  const axios = useAxios();

  //const patientId = useContext(ManagePatientNewContext);

  useEffect(() => {
    setIsEditable(false);
    const getFeedbackList = async () => {
      try {
        const paramsMap = new Map<string, string>([
          ["clientid", props.patientId],
        ]);
        const filteredEndpoint = replaceParams(endpoints.feedback.view, paramsMap);
        const response = await axios.get(filteredEndpoint);
        if (response.status === 200) {
          if (response.data && response.data["data"]) {
            const data = response.data["data"];
            const convertedfeedbackList = data.map(
              (feedback: FeedbackDetails) =>
                Convert.toFeedbackDetails(JSON.stringify(feedback))
            );
            // Sort feedback by date in descending order
            const sortedFeedback = convertedfeedbackList.sort((a: FeedbackDetails, b: FeedbackDetails) => {
              return moment(b.feedbackdate).valueOf() - moment(a.feedbackdate).valueOf();
            });
            setPatientFeedback(sortedFeedback);

            if (sortedFeedback.length > 0) {
              setSelected(sortedFeedback[0]);
            }
          }
        }

        setIsEditable(false);
        setLoading(false);
      } catch (error) {
        console.error("Error getting feedback list", error);
        setLoading(false);
      }
    };
    getFeedbackList();
  }, []);

  return (
    <div className="grid-cols-1 gap-x-8 gap-y-8 pt-2 md:grid-cols-3 bg-grey-200 ">
      <form>
        {!loading && patientFeedback && patientFeedback != undefined ? (
          <>
            <div className="flex flex-col w-full space-y-4">
              <div className="flex flex-col ">
                <Applabel label=" Feedback List"></Applabel>
                <select
                  onChange={(e) => {
                    const d = patientFeedback.map((single) => {
                      if (single.id === e.target.value) setSelected(single);
                    });
                  }}
                  className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                >
                  {patientFeedback.map((feedback) => (
                    <option key={feedback.id} value={feedback.id}>
                      {moment(feedback.feedbackdate).format("MMM DD, YYYY")} (
                      {moment(feedback.feedbackdate).fromNow()})
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {selected && (
              <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                <div className="flex flex-col w-full grow border-2 rounded-lg h-full mt-4 p-4">
                  {selected.feedback.map((single) => (
                    <>
                      <span className="font-semibold mt-2 text-sm">
                        {single.question}
                      </span>
                      <span className="mb-2 text-sm">{single.answer}</span>
                    </>
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          <div>
            <p>No Feedback added yet. Add a feedback to view</p>
          </div>
        )}
      </form>
    </div>
  );
}
