import { Switch } from "@headlessui/react";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import React, { useEffect } from "react";
import { toast } from "react-toastify";

function SecurityComponent(props: { entityId: string }) {
  const [enabled, setEnabled] = React.useState(false);

  const getEntityPhoneMask = async () => {
    try {
      const paramsMap = new Map<string, string>([["id", props.entityId]]);
      const filteredEndpoint = replaceParams(
        endpoints.entity.getEntityPhoneNumberMask,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];

      setEnabled(data.isMaskPhoneNo);
    } catch (error) {}
  };

  const handleSave = async (updatedEnabled: boolean) => {
    try {
      const paramsMap = new Map<string, string>([["id", props.entityId]]);
      const filteredEndpoint = replaceParams(
        endpoints.entity.updateEntityPhoneNumberMask,
        paramsMap
      );

      axios.put(
        filteredEndpoint,

        {
          ismaskphoneno: updatedEnabled,
        }
      );
      toast.success("Security setting updated successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    const _init = async () => {
      await getEntityPhoneMask();
    };
    _init();
  }, []);

  const handleChange = async () => {
    const updatedEnabled = !enabled;
    setEnabled(updatedEnabled);
    await handleSave(updatedEnabled);
  };

  return (
    <div className="flex flex-col p-4 ml-10">
      <span className="font-bold text-md">Security</span>
      <div className="mt-2 flex flex-col md:flex-row w-1/2 justify-between">
        <div className="flex-shrink-0">Mask Patient Phone number -</div>
        <div className="flex items-center ">
          <Switch
            onChange={handleChange}
            checked={enabled}
            className={`${enabled ? "bg-secondary-600" : "bg-secondary-50"}
                                relative inline-flex h-[38px] w-[74px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 `}
          >
            <span
              aria-hidden="true"
              className={`${enabled ? "translate-x-9" : "translate-x-0"}
                                pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
          {enabled ? (
            <span className="text-gray-500 ml-5">Enable</span>
          ) : (
            <span className="text-gray-500 ml-5">Disable</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default SecurityComponent;
