import { useEffect, useMemo, useState } from "react";

import React from "react";

import { OutlinedButton, Button } from "@components/button";
import { ButtonVariant } from "@components/button/button";

import { endpoints, replaceParams } from "@utils/axios";
import useAxios from "@routes/hooks/use-axios";
import { useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { Convert, Expense } from "../types/Expense";
import { Pagination } from "@pages/appointments/appointment-index-table/Pagination";
import {
  filterRows,
  paginateRows,
  sortRows,
} from "@pages/appointments/appointment-index-table/Helpers";
import ExpensesComboBox from "@pages/expenses/ExpensesComboBox";
import Applabel from "@components/hook-form/applabel";
import SolidButton from "@components/button/button";
import * as z from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ValidationError } from "@components/hook-form";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { toast } from "react-toastify";
import moment from "moment";
import { MonthYearPicker } from "@utils/MonthYearPicker";
import { BillingDetails, ConvertBilling } from "../types/BillingDetails";
import RightOffcanvas from "@components/right-offcanvas";
import BillDetailsPage from "./BillDetailsPage";
import { PersonalInformationDetails } from "@pages/manage-patient/types/PatientPersonalDetails";
import { paths } from "@routes/paths";
import { ScrollArea, ScrollBar } from "@components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@components/ui/table";

export default function BillingIndexPage(props: {
  patientId?: string;
  patientInfo?: PersonalInformationDetails;
  getPatientBillingDetails?: () => Promise<void>;
}) {
  const [billing, setBilling] = React.useState<BillingDetails[] | undefined>(
    []
  );
  const [loading, setLoading] = React.useState(true);

  const axios = useAxios();
  const [rows, setRows] = useState<BillingDetails[]>([]);
  const [isRightOffcanvasBilling, setIsRightOffcanvasBilling] =
    React.useState(false);

  const [selectedInvoice, setSelectedInvoice] = React.useState("");
  const navigator = useNavigate();

  const columns = [
    { accessor: "invoiceNumber", label: "Invoice Number" },
    { accessor: "invoiceDate", label: "Date" },
    { accessor: "clientName", label: "Patient Name" },

    { accessor: "totalAmount", label: "Amount" },
    { accessor: "status", label: "Status" },
    { accessor: "actions", label: "Actions" },
  ];

  const [activePage, setActivePage] = useState<number>(1);
  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [sort, setSort] = useState<{ order: string; orderBy: string }>({
    order: "",
    orderBy: "",
  });
  // Function to determine the border color based on the selected status
  const getTextColor = (selectedStatus: string) => {
    if (selectedStatus === "partlyPaid") {
      return "text-warning-500"; // Red border for 'pending' status
    } else if (selectedStatus === "paid") {
      return "text-success-500"; // Green border for 'paid' status
    } else {
      return "text-red-500"; // Default gray border
    }
  };
  const rowsPerPage: number = 10;
  const filteredRows = useMemo(
    () => filterRows(rows, filters),
    [rows, filters]
  );
  const sortedRows = useMemo(
    () => sortRows(filteredRows, sort),
    [filteredRows, sort]
  );

  const calculatedRows = useMemo(
    () => paginateRows(sortedRows, activePage, rowsPerPage),
    [sortedRows, activePage, rowsPerPage]
  );

  const count: number = filteredRows.length;
  const totalPages: number = Math.ceil(count / rowsPerPage);
  const userData = getUserPersistedOnLocalStorage();
  const naviagtor = useNavigate();

  const navigateToReferralBilling = () => {
    naviagtor(paths.referralBilling.index.route);
  };

  const getBilling = async () => {
    try {
      let endpoint = "";
      if (props.patientId) {
        const paramsMap = new Map<string, string>([
          ["clientId", props.patientId],
        ]);
        endpoint = replaceParams(
          endpoints.invoice.getBillsForClient,
          paramsMap
        );
      } else {
        const paramsMap = new Map<string, string>([
          ["entityId", userData?.entity_id!],
          ["branchId", userData?.branch_id ?? ""],
        ]);
        if (userData?.user_role === "ENTITY_OWNER") {
          endpoint = replaceParams(
            endpoints.invoice.getBillsForEntity,
            paramsMap
          );
        } else {
          endpoint = replaceParams(
            endpoints.invoice.getBillsForBranch,
            paramsMap
          );
        }
      }

      const response = await axios.get(endpoint);
      const data = response.data["data"];
      if (data !== null) {
        const convertedList = data.map((expense: any) => {
          return ConvertBilling.toBilling(JSON.stringify(expense));
        });

        setBilling(convertedList);
        setRows(convertedList);
      } else {
        setBilling([]);
        setRows([]);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching expenses details:", error);

      setLoading(false);
    }
  };
  useEffect(() => {
    getBilling();
  }, []);

  function handleSearch(value: string) {
    if (value === "") {
      setFilters({});
      setSort({ order: "", orderBy: "" });
      if (billing) {
        setRows(billing);
      } // Reset rows to the original billing array
    } else {
      if (billing) {
        const filteredRows = billing.filter((row: any) => {
          return (
            row.invoiceNumber.toLowerCase().includes(value.toLowerCase()) ||
            row.clientName?.toLowerCase().includes(value.toLowerCase()) ||
            row.totalAmount
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            row.status.toLowerCase().includes(value.toLowerCase()) ||
            moment(row.dueDate)
              .format("DD-MM-YYYY")
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          );
        });
        setFilters({}); // Reset filters
        setSort({ order: "", orderBy: "" }); // Reset sorting
        setRows(filteredRows);
      }
    }
  }

  //console.log("patient id" + props.patientId);
  //console.log("patient info" + props.patientInfo);

  return (
    <>
      {/* For Widescreen layout */}

      <div className=" flex flex-row ">
        <div className="flex flex-col w-full  ">
          <div className="flex m-2 flex-row justify-normal w-full ">
            <input
              type="text"
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              placeholder="Search for billings by Invoice Number, Date, Patient Name, Amount and Status"
              className="w-1/2 h-min px-4 py-2 mb-4 text-sm text-gray-900 rounded-lg border border-gray-600 focus:ring-0 focus:border-primary-500"
            ></input>
            {/*             {props.patientId && (
              <OutlinedButton
                variant={ButtonVariant.PRIMARY}
                type="button"
                className="w-2/12 h-min justify-center ml-2 mr-2"
                onClick={() => createNewBill()}
                children="Add New Bill"
              />
            )} */}
            {(userData?.user_role === "BRANCH_ADMIN" ||
              userData?.user_role === "ENTITY_OWNER") &&
              !props.patientId && (
                <div className="flex    flex-col justify-normal m-0">
                  <OutlinedButton
                    variant={ButtonVariant.PRIMARY}
                    type="button"
                    onClick={navigateToReferralBilling}
                    children="Referral Billing"
                    className=" w-fit justify-center align-top mx-2"
                  />
                </div>
              )}
          </div>
          <ScrollArea className="w-full whitespace-nowrap rounded-md border">
            <div className="overflow-x-auto">
              {/* Billing table start */}
              <Table>
                <TableHeader>
                  <TableRow>
                    {columns.map((column) => {
                      return (
                        <TableHead
                          key={column.accessor}
                          className="px-4 py-3 border-b border-gray-300 text-sm"
                        >
                          <div className="flex items-center space-x-1">
                            <span>{column.label}</span>
                          </div>
                        </TableHead>
                      );
                    })}
                  </TableRow>
                </TableHeader>
                <TableBody className="bg-white">
                  {calculatedRows.map((row, index) => {
                    return (
                      <TableRow
                        key={row.id}
                        className={(() => {
                          // if not last row then add border
                          if (index !== calculatedRows.length - 1) {
                            return "border-b border-gray-200";
                          }

                          return "";
                        })()}
                      >
                        <TableCell
                          key={"invoiceNumber"}
                          className={`px-4 py-3 ${"text-gray-500"}`}
                        >
                          {row["invoiceNumber"]}
                        </TableCell>

                        <TableCell
                          key={"invoiceDate"}
                          className={`px-4 py-3 ${"text-gray-500"}`}
                        >
                          {moment(row["invoiceDate"])
                            .format("DD-MM-YYYY")
                            .toString()}
                        </TableCell>
                        <TableCell
                          key={"clientName"}
                          className={`px-4 py-3 ${"text-gray-500"}`}
                        >
                          {row["clientName"]}
                        </TableCell>

                        <TableCell
                          key={"totalAmount"}
                          className={`px-4 py-3 ${"text-gray-500"}`}
                        >
                          ₹{row["totalAmount"]}
                        </TableCell>
                        <TableCell
                          key={"status"}
                          className={`px-4 py-3 ${"text-gray-500"}`}
                        >
                          <span
                            className={`font-bold ${getTextColor(
                              row["status"]
                            )}`}
                          >
                            {(() => {
                              switch (row["status"]) {
                                case "partlyPaid":
                                  return "Partly Paid";
                                case "paid":
                                  return "Paid";
                                case "pending":
                                  return "Pending";
                              }
                            })()}
                          </span>
                        </TableCell>

                        <TableCell className={`px-4 py-3 ${"text-gray-500"}`}>
                          <div className="flex flex-row">
                            <div className="flex-shrink mr-2">
                              <OutlinedButton
                                variant={ButtonVariant.SECONDARY}
                                type="button"
                                onClick={() => {
                                  setIsRightOffcanvasBilling(true);
                                  setSelectedInvoice(row["invoiceNumber"]);
                                }}
                                children={
                                  row["status"] === "paid" ? "View" : "Open"
                                }
                              />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {/* Billing table end  */}
            </div>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
          {count > 0 ? (
            <Pagination
              activePage={activePage}
              count={count}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              setActivePage={setActivePage}
            />
          ) : (
            <p>No data found</p>
          )}
        </div>
        <hr className="my-4" />
      </div>
      <RightOffcanvas
        isOpen={isRightOffcanvasBilling}
        onClose={() => {
          setIsRightOffcanvasBilling(false);
          getBilling();
        }}
        sizeClasses="w-full md:w-[100vh]"
      >
        {isRightOffcanvasBilling && billing && (
          <BillDetailsPage
            onClose={() => {
              setIsRightOffcanvasBilling(false);
              getBilling();
            }}
            bill={
              billing.find((item) => item.invoiceNumber === selectedInvoice) ||
              ConvertBilling.toBilling("{}")
            }
            getPatientBillingDetails={props.getPatientBillingDetails}
          />
        )}
      </RightOffcanvas>
    </>
  );
}
