// To parse this data:
//
//   import { Convert, Appointment } from "./file";
//
//   const appointment = Convert.toAppointment(json);

export interface Appointment {
  id: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  entityUserId: string;
  clientName: string;
  email: string;
  clientPhone: number;
  entityUserName: string;
  appointmentType: string;
  date: string;
  apptdate: Date;
  startTime: string;
  endTime: string;
  startTimeMinutes: number;
  endTimeMinutes: number;
  notes: string;
  appointmentStatus: string;
  statusChangeComment: string;
  paymentStatus: string;
  createdAt: Date;
  updatedAt: Date;
  billAmount: string;
  payoutamount: number;
  payoutStatus: string;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toAppointment(json: string): Appointment {
    return JSON.parse(json);
  }

  public static appointmentToJson(value: Appointment): string {
    return JSON.stringify(value);
  }
}
