import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

import { useParams, useSearchParams } from "react-router-dom";

import { TreatmentPlanTab } from "./TreatmentPlanTab";
import GoalsTab from "./GoalsTab";
import PatientFeedback from "./PatientFeedback";
import PatientRating from "./PatientRating";
import { OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
interface Tab {
  name: string;
  current: boolean;
  
}

function FeedbackRating(props: {
  handleCloseRightOffCanvas: () => void;
  patientId: string;
  isPopup?: boolean;

}) {
  const [activeTab, setActiveTab] = useState("Feedback");
  const [isRightOffcanvasOpen, setIsRightOffcanvasOpen] = React.useState(false);

  const handleCloseRightOffCanvas = () => {
    setIsRightOffcanvasOpen(false);
  };
  const tabComponents: Record<string, React.ReactNode> = {
    Feedback: (
      <>
        <PatientFeedback
          patientId={props.patientId}
          handleCloseRightOffCanvas={handleCloseRightOffCanvas}
        />
      </>
    ),
    Rating: (
      <>
        <PatientRating patientId={props.patientId}></PatientRating>
      </>
    ),
  };

  const tabs: Tab[] = [
    { name: "Feedback", current: activeTab === "Feedback" },
    {
      name: "Rating",
      current: activeTab === "Rating",
    },
  ];

  function handleTabClick(tabName: string) {
    setActiveTab(tabName);
  }
  return (
    <>
      <div className="flex flex-col">
        {props.isPopup && (
        <div className="ml-auto">
          <OutlinedButton
            type="button"
            onClick={props.handleCloseRightOffCanvas}
            variant={ButtonVariant.PRIMARY}
          >
            Close
          </OutlinedButton>
        </div>
        )}
        <div className="flex-shrink">
          <div className="border-b border-gray-200 pb-5 sm:pb-0">
            <div className="mt-3 sm:mt-4">
              <div className="block md:hidden">
                <label htmlFor="current-tab" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="current-tab"
                  name="current-tab"
                  className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-lg focus:border-primary-500 focus:outline-none focus:ring-primary-500"
                  value={activeTab}
                  onChange={(e) => handleTabClick(e.target.value)}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name} value={tab.name}>
                      {tab.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-3 sm:mt-4 hidden md:block">
                <nav className="-mb-px flex space-x-8">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      className={`${
                        tab.current
                          ? "border-primary-500 text-primary-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      } whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium focus:outline-none`}
                      onClick={() => {
                        handleTabClick(tab.name);
                      }}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>
        {tabComponents[activeTab]}
      </div>
    </>
  );
}

export default FeedbackRating;

// Setting default props
FeedbackRating.defaultProps = {
  isPopup: true,
};