import React from 'react';
import SpecialTestDetails from '../type/SpecialTestDetails';

export default function SpecialTestDetailsCard(props: {
  specialTest: SpecialTestDetails;
  specialTestList: SpecialTestDetails[];
  setSpecialTestDetailsList: React.Dispatch<
    React.SetStateAction<SpecialTestDetails[]>
  >;
  isDisabled: boolean;
}) {
  const handleRemove = (id: string) => {
    const updatedSpecialTestDetailsList = props.specialTestList.filter(
      item => item.id !== id,
    );

    props.setSpecialTestDetailsList(updatedSpecialTestDetailsList);
  };

  const handleObservationChange = (e: string) => {
    const targetIndex = props.specialTestList.findIndex(
      item => item.id === props.specialTest.id,
    );
    if (targetIndex !== -1) {
      props.setSpecialTestDetailsList(prevDetailsList =>
        prevDetailsList.map((item, index) =>
          index === targetIndex ? { ...item, observation: e } : item,
        ),
      );
    }
  };

  const handleNameChange = (e: string) => {
    const targetIndex = props.specialTestList.findIndex(
      item => item.id === props.specialTest.id,
    );
    if (targetIndex !== -1) {
      props.setSpecialTestDetailsList(prevDetailsList =>
        prevDetailsList.map((item, index) =>
          index === targetIndex ? { ...item, name: e } : item,
        ),
      );
    }
  };

  return (
    <div className="flex flex-col border-b-2 shadow mx-2">
      <div className="flex flex-col md:flex-row w-full md:justify-between p-4">
        <div className="flex flex-col md:flex-row md:justify-between w-full md:space-x-2">
          <div className="flex flex-col md:w-6/12">
            <label htmlFor="">Special Test Name </label>
            <input
              type="text"
              value={props.specialTest.name}
              onChange={e => handleNameChange(e.target.value)}
              placeholder="Special Test Name "
              className="border p-2 rounded-lg border-gray-400"
              disabled={props.isDisabled}
            />
          </div>
          <div className="flex flex-col md:w-4/12">
            <label htmlFor="">Observation</label>
            <textarea
              placeholder="Observation"
              value={props.specialTest.observation}
              onChange={e => handleObservationChange(e.target.value)}
              rows={2}
              className="border p-2 rounded-lg border-gray-400"
              disabled={props.isDisabled}
            />
          </div>
          <div className="flex  flex-col  py-1 pr-4 justify-center items-end">
            <div>
              <button
                className="custom-button h-full w-full text-red-500 flex flex-row"
                type="button"
                disabled={props.isDisabled}
                onClick={() => {
                  handleRemove(props.specialTest.id);
                }}
              >
                <span className="icon-container justify-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    className="component-iconify MuiBox-root css-1t9pz9x iconify iconify--solar"
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M3 6.386c0-.484.345-.877.771-.877h2.665c.529-.016.996-.399 1.176-.965l.03-.1l.115-.391c.07-.24.131-.45.217-.637c.338-.739.964-1.252 1.687-1.383c.184-.033.378-.033.6-.033h3.478c.223 0 .417 0 .6.033c.723.131 1.35.644 1.687 1.383c.086.187.147.396.218.637l.114.391l.03.1c.18.566.74.95 1.27.965h2.57c.427 0 .772.393.772.877s-.345.877-.771.877H3.77c-.425 0-.77-.393-.77-.877"
                    ></path>
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M11.596 22h.808c2.783 0 4.174 0 5.08-.886c.904-.886.996-2.339 1.181-5.245l.267-4.188c.1-1.577.15-2.366-.303-2.865c-.454-.5-1.22-.5-2.753-.5H8.124c-1.533 0-2.3 0-2.753.5c-.454.5-.404 1.288-.303 2.865l.267 4.188c.185 2.906.277 4.36 1.182 5.245c.905.886 2.296.886 5.079.886m-1.35-9.811c-.04-.434-.408-.75-.82-.707c-.413.043-.713.43-.672.864l.5 5.263c.04.434.408.75.82.707c.413-.043.713-.43.672-.864zm4.329-.707c.412.043.713.43.671.864l-.5 5.263c-.04.434-.409.75-.82.707c-.413-.043-.713-.43-.672-.864l.5-5.263c.04-.434.409-.75.82-.707"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
