// routes
import { paths } from '@routes/paths';
import {
  TokenDecodedTypeConvert,
  TokenDecodedType,
  UserRole,
} from '@authentication/types';
import { STORAGE_KEYS } from '@constants/storage-keys';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

export function getUserRoleFromString(): UserRole {
  // Logic here to get current user role
  const role: string | undefined = getCurrentUserRole();

  // the role can be {PLATFORM_ADMIN, ENTITY_OWNER, BRANCH_ADMIN, PRACTITIONER, ASSISTANT}
  // we will convert it to {platformAdmin, businessAdmin, branchAdmin, practitioner, assistant}
  if (role === 'PLATFORM_ADMIN') return 'platformAdmin';
  if (role === 'ENTITY_OWNER') return 'entityOwner';
  if (role === 'BRANCH_ADMIN') return 'branchAdmin';
  if (role === 'PRACTITIONER') return 'practitioner';
  if (role === 'ASSISTANT') return 'assistant';
  else return 'unauthorized';
}

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  let expiredTimer;

  const currentTime = Date.now();

  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {

    sessionStorage.removeItem('accessToken');

    window.location.href = paths.auth.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (
  accessToken: string | null,
  refreshToken: string | null,
  googleAccessToken: string | null,
  googleRefreshToken: string | null,
) => {
  if (accessToken && refreshToken && googleAccessToken && googleRefreshToken) {
    sessionStorage.setItem(STORAGE_KEYS.APP_STATE.ACCESS_TOKEN, accessToken);
    sessionStorage.setItem(STORAGE_KEYS.APP_STATE.REFRESH_TOKEN, refreshToken);
    sessionStorage.setItem(
      STORAGE_KEYS.APP_STATE.GOOGLE_ACCESS_TOKEN,
      googleAccessToken,
    );
    sessionStorage.setItem(
      STORAGE_KEYS.APP_STATE.GOOGLE_REFRESH_TOKEN,
      googleRefreshToken,
    );
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else if (accessToken && refreshToken) {
    sessionStorage.setItem(STORAGE_KEYS.APP_STATE.ACCESS_TOKEN, accessToken);
    sessionStorage.setItem(
      STORAGE_KEYS.APP_STATE.REFRESH_TOKEN,
      refreshToken ?? '',
    );

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    // TODO: Do a better way to handle token expired
    // tokenExpired(exp);
  } else if (accessToken) {
    sessionStorage.setItem(STORAGE_KEYS.APP_STATE.ACCESS_TOKEN, accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem(STORAGE_KEYS.APP_STATE.ACCESS_TOKEN);
    sessionStorage.removeItem(STORAGE_KEYS.APP_STATE.REFRESH_TOKEN);
    sessionStorage.removeItem(STORAGE_KEYS.APP_STATE.GOOGLE_ACCESS_TOKEN);
    sessionStorage.removeItem(STORAGE_KEYS.APP_STATE.GOOGLE_REFRESH_TOKEN);
    sessionStorage.removeItem(STORAGE_KEYS.USER_DATA);
    sessionStorage.removeItem(STORAGE_KEYS.IS_GOOGLE_AUTH_ENABLED);
    sessionStorage.removeItem(STORAGE_KEYS.ENTITY_TIMEZONE);
    delete axios.defaults.headers.common.Authorization;
  }
};

export const setUserData = (token: string) => {
  const decoded = jwtDecode(token) as TokenDecodedType;

  sessionStorage.setItem(STORAGE_KEYS.USER_DATA, JSON.stringify(decoded));
};

export const getUserPersistedOnLocalStorage = () => {
  const user = sessionStorage.getItem(STORAGE_KEYS.USER_DATA);

  return user ? TokenDecodedTypeConvert.toTokenDecodedType(user) : null;
};

export const getUserTimeZone = () => {
  const user = sessionStorage.getItem(STORAGE_KEYS.ENTITY_TIMEZONE);

  return user;
};

export const getCurrentUserRole = () => {
  const user: TokenDecodedType | null = getUserPersistedOnLocalStorage();

  return user?.user_role;
};

export const getUIVersion = () => {
  const version = sessionStorage.getItem(STORAGE_KEYS.UI_VERSION);

  return version ? version : '';
};
