// To parse this data:
//
//   import { Convert, MasterAssessmentLedger } from "./file";
//
//   const masterAssessmentLedger = Convert.toMasterAssessmentLedger(json);

export interface MasterAssessmentLedger {
  id: string;
  clientId: string;
  entityId: string;
  entityUserId: string;
  entityUserName: string;
  assessmentNo: number;
  assessmentDate: Date;
  diagnosisId: string;
  medicalHistoryId: string;
  treatmentPlanId: string;
  vitalAssessmentId: string;
  painAssessmentId: string;
  postureAssessmentId: string;
  reflexAssessmentId: string;
  gaitAssessmentId: string;
  rangeOfMotionAssessmentId: string;
  muscleToneAssessmentId: string;
  treatmentProtocolId: string;
  activetill: string;
}

// Converts JSON strings to/from your types
export class MasterAssessmentLedgerConvert {
  public static toMasterAssessmentLedger(json: string): MasterAssessmentLedger {
    return JSON.parse(json);
  }

  public static masterAssessmentLedgerToJson(
    value: MasterAssessmentLedger
  ): string {
    return JSON.stringify(value);
  }
}
