import { OutlinedButton } from "@components/button";
import Button, { ButtonVariant } from "@components/button/outline-button";
import { ValidationError } from "@components/hook-form";
import Applabel from "@components/hook-form/applabel";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Convert } from "@pages/manage-patient-new/types/ReportDetails";
import { toast } from "react-toastify";

export default function Reports(props: {
  patientId: string;
  onClose: () => void;
}) {
  const [media, setMedia] = useState<any>([]);
  const [imageMediaMessage, setImageMediaMessage] = useState(false);
  const [file, setFile] = useState<File | null>();
  const inputOpenFileRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = () => {
    const files = inputOpenFileRef.current?.files;

    if (files && files.length > 0 && file) {
      handlePdfUpload({ target: { files } });
    } else {
      setImageMediaMessage(true);
    }
  };

  function handleSetName(e: any) {
    const files = e.target.files;
    if (files && files.length > 0) {
      let size = 0;

      for (let j = 0; j < files.length; j++) {
        const file = files[j];
        const type = file.type;
        const fileSize = file.size; // Get the file size in bytes

        if (fileSize > 5 * 1024 * 1024) {
          // Check if file size exceeds 5MB
          toast.error("File size cannot exceed 5MB");

          setFile(null);
          return; // Exit the function if file size exceeds limit
        }

        if (
          type === "application/pdf" ||
          type === "image/jpg" ||
          type === "image/JPG" ||
          type === "image/jpg" ||
          type === "image/jpeg" ||
          type === "image/JPEG" ||
          type === "image/png" ||
          type === "image/PNG"
        ) {
          setFile(file);
          size += file.size;
          setImageMediaMessage(false);
        } else {
          setImageMediaMessage(true);
          setFile(null);
        }
      }
    }
  }

  function handlePdfUpload(e: any) {
    const files = e.target.files;
    if (files && files.length > 0) {
      let size = 0;

      for (let j = 0; j < files.length; j++) {
        const file = files[j];
        const type = file.type;
        const fileSize = file.size; // Get the file size in bytes

        if (fileSize > 5 * 1024 * 1024) {
          // Check if file size exceeds 5MB
          toast.error("File size cannot exceed 5MB");

          setFile(null);
          return; // Exit the function if file size exceeds limit
        }

        if (
          type === "application/pdf" ||
          type === "image/jpg" ||
          type === "image/JPG" ||
          type === "image/jpg" ||
          type === "image/jpeg" ||
          type === "image/JPEG" ||
          type === "image/png" ||
          type === "image/PNG"
        ) {
          setFile(file);
          size += file.size;
          setImageMediaMessage(false);
        } else {
          setImageMediaMessage(true);
          setFile(null);
        }
      }
      if (!imageMediaMessage) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          const formData = new FormData();
          formData.append("file", file);
          const paramsMap = new Map<string, string>([
            ["patientId", props.patientId],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.reports.addReport,
            paramsMap
          );
          axios
            .post(filteredEndpoint, formData)
            .then((response) => {
              if (response.status === 200) {
                toast.success(
                  response.data.message === "success"
                    ? "File upload successfully"
                    : response.data.message
                );
                setFile(null);
                getAllReportsOfPatient();
              }
            })

            .catch((error) => {
              toast.error(error.message);
            });
        }
      }
    }
  }

  async function getAllReportsOfPatient() {
    try {
      const paramsMap = new Map<string, string>([
        ["patientId", props.patientId],
      ]);
      const filteredEndpoint = replaceParams(endpoints.reports.view, paramsMap);
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];

      if (data && data.length > 0) {
        var pdfFiles = [];
        for (let i = 0; i < data.length; i++) {
          var media = data[i];
          var type = media.contentType;
        }
        setMedia(data);
      }
      setImageMediaMessage(false);
      setFile(null);
    } catch (error) {}
  }

  useEffect(() => {
    getAllReportsOfPatient();
  }, []);

  const downloadFile = async (item: any) => {
    try {
      const paramsMap = new Map<string, string>([
        ["patientId", props.patientId],
        ["reportId", item.id],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.reports.downloadReport,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      const converted = Convert.toReportDetails(JSON.stringify(data));

      window.open(converted.url, "_blank");
    } catch (error) {}
  };

  const handleClick = () => {
    inputOpenFileRef.current?.click();
  };

  const removeFile = () => {
    setFile(null);
  };
  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
              Reports
            </h2>
          </div>

          <div className="flex mt-4 flex-shrink-0 md:ml-4 md:mt-0 justify-end">
            <OutlinedButton
              type="button"
              onClick={props.onClose}
              className="mr-2"
              variant={ButtonVariant.PRIMARY}
            >
              Close
            </OutlinedButton>
          </div>
        </div>

        <hr className="my-2" />

        <div className="flex flex-col w-full uploadVideo">
          <Applabel label="Upload File" />

          <div
            className={`border border-dashed border-gray-500 relative ${
              file && "hidden"
            } `}
          >
            <input
              name="uploadFile"
              key="uploadFile"
              type="file"
              ref={inputOpenFileRef}
              accept="application/pdf,image/jpeg,image/jpg,image/png,image/JPEG,image/PNG,image/JPG"
              onChange={(e) => handleSetName(e)}
              className={`cursor-pointer relative block opacity-0 w-full h-full p-20 z-50`}
            />
            <div className="font-medium text-gray-600 text-center p-10 absolute top-0 right-0 left-0 m-auto">
              <h4>
                Drop file anywhere to upload
                <br />
                or
              </h4>
              <p className="">Select File</p>
            </div>
          </div>
          <div className="flex justify-center w-full">
            <div
              className={`flex flex-col justify-center rounded-2xl border shadow-md overflow-hidden w-1/2 ${
                !file && "hidden"
              }`}
            >
              <span className="flex-initial text-md text-black font-bold p-3">
                {file?.name}
              </span>

              <div className="flex flex-row rounded-bl-2xl rounded-br-2xl items-center p-4 mt-2">
                <div className="flex flex-row w-full justify-end">
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    type="button"
                    className="ml-2"
                    onClick={removeFile}
                  >
                    Remove
                  </Button>
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    type="button"
                    className="ml-2"
                    onClick={handleClick}
                  >
                    Change
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {imageMediaMessage && (
            <ValidationError
              message={
                'Please select valid PDF, JPG, JPEG, PNG files. Only files with the ".pdf/.jpg/.jpeg/.png/.JPEG/.JPG/.PNG" extension are allowed.'
              }
            />
          )}
        </div>

        <div className="flex flex-row w-full justify-start mt-2">
          <input
            type="text"
            placeholder="No File"
            value={file?.name ?? ""}
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400  sm:text-sm sm:leading-6`}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            type="button"
            className="ml-2"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>

        <div className="flex mt-2"></div>
      </div>

      <hr className="my-2" />
      <ul
        className="grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-3
      2xl:grid-cols-3 bg-gray-50 -mx-8 p-8"
      >
        {media.map((item: any) => (
          <div className="flex flex-col justify-between rounded-2xl shadow-md bg-white overflow-hidden">
            <span className="flex-initial text-md text-black font-bold p-3">
              {item.name}
            </span>

            <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4 mt-2">
              <div className="flex flex-row w-full justify-end">
                <OutlinedButton
                  variant={ButtonVariant.SECONDARY}
                  type="button"
                  onClick={() => {
                    downloadFile(item);
                  }}
                  children="View"
                />
              </div>
            </div>
          </div>
        ))}
      </ul>
    </>
  );
}
