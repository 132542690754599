import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams, useSearchParams } from "react-router-dom";

import BranchesComponent from "./BranchesComponent";
import StaffComponent from "./StaffComponent";
import PatientComponent from "./PatientComponent";
import EntityInformation from "./EntityInformation";
import ConfigurationStep from "./ConfigurationStep";
import TherapyInformation from "./TherapyInformation";
import FeedbackComponent from "./FeedbackComponent";
import ConsentComponent from "./ConsentComponent";
import NotificationComponent from "./NotificationComponent";
import { Button } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import AppointmentSettingComponent from "./AppointmentSettingComponent";
import SecurityComponent from "./SecurityComponent";

interface Tab {
  name: string;
  current: boolean;
}

function ViewEntityPage() {
  const [activeTab, setActiveTab] = useState("Information");
  const [timeZone, setEntityTimeZone] = useState("");

  let { entityId, entityName } = useParams();
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");

  const tabComponents: Record<string, React.ReactNode> = {
    Information: (
      <EntityInformation
        entityId={entityId ?? ""}
        setEntityTimeZone={setEntityTimeZone}
      />
    ),
    "Working Hours": (
      <ConfigurationStep entityId={entityId ?? ""} timeZone={timeZone} />
    ),
    "Treatment Modalities": <TherapyInformation entityId={entityId ?? ""} />,
    Branches: <BranchesComponent entityId={entityId ?? ""} />,
    Staff: <StaffComponent entityId={entityId ?? ""} />,
    Patients: <PatientComponent entityId={entityId ?? ""} />,
    "Patient Feedback Form": <FeedbackComponent entityId={entityId ?? ""} />,
    Consent: <ConsentComponent entityId={entityId ?? ""} />,
    "Notification Settings": (
      <NotificationComponent entityId={entityId ?? ""} />
    ),
    "Appointment Settings": (
      <AppointmentSettingComponent entityId={entityId ?? ""} />
    ),
    Security: <SecurityComponent entityId={entityId ?? ""} />,
  };

  const tabs: Tab[] = [
    { name: "Information", current: activeTab === "Information" },
    { name: "Working Hours", current: activeTab === "Working Hours" },
    {
      name: "Treatment Modalities",
      current: activeTab === "Treatment Modalities",
    },
    { name: "Branches", current: activeTab === "Branches" },
    { name: "Staff", current: activeTab === "Staff" },
    { name: "Patients", current: activeTab === "Patients" },
    {
      name: "Patient Feedback Form",
      current: activeTab === "Patient Feedback Form",
    },
    {
      name: "Consent",
      current: activeTab === "Consent",
    },
    {
      name: "Notification Settings",
      current: activeTab === "Notification Settings",
    },
    {
      name: "Appointment Settings",
      current: activeTab === "Appointment Settings",
    },
    {
      name: "Security",
      current: activeTab === "Security",
    },
  ];

  function handleTabClick(tabName: string) {
    setActiveTab(tabName);
  }
  return (
    <>
      <div className="flex flex-col">
        <div className="flex-shrink">
          <div className="border-b border-gray-200 pb-5 sm:pb-0">
            <div className="flex flex-row justify-between">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                {name}
              </h3>

              <Button
                variant={ButtonVariant.PRIMARY}
                type="submit"
                className="ml-3"
                onClick={() => {
                  navigator("/dashboard");
                }}
              >
                Back
              </Button>
            </div>
            <div className="mt-3 sm:mt-8">
              <div className="block xl:hidden">
                <label htmlFor="current-tab" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="current-tab"
                  name="current-tab"
                  className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  value={activeTab}
                  onChange={(e) => handleTabClick(e.target.value)}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name}>{tab.name}</option>
                  ))}
                </select>
              </div>
              <div className="mt-3 sm:mt-8 hidden xl:block">
                <nav className="flex flex-wrap gap-8">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      className={`${
                        tab.current
                          ? "border-primary-500 text-primary-600 hover:border-primary-500 hover:text-primary-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      } border-b-2 px-1 pb-4 text-sm font-medium focus:outline-none`}
                      onClick={() => handleTabClick(tab.name)}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>
        {tabComponents[activeTab]}
      </div>
    </>
  );
}

export default ViewEntityPage;
