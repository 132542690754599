import React, { useState } from "react";
import * as z from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Convert, PrescriptionDetails } from "@pages/manage-patient-new/types/PrescriptionDetails";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import moment from "moment";
import { ValidationError } from "@components/hook-form";

import {
  CheckBadgeIcon,
  CheckCircleIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";

import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { NewMasterAssessmentData } from "@pages/manage-patient-new/types/NewMasterAssessment";
import { Button } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import jsPDF from "jspdf";
import OutReferralSearch from "../components/OutReferralSearch";
import { ConvertOutReferrals, OutReferrals } from "@pages/manage-patient-new/types/OutReferrals";

interface PersonalInformationDetails {
  salutation: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  isActive: boolean;
  isOnboarded: boolean;
  phone: number;
  phoneCode: number;
  emergencyPhone: number;
  emergencyPhoneCode: number;
  birthPlace?: string;
  bloodGroup: string;
  address: Address;
  age: number;
  gender: string;
  dob: Date;
  notes?: string;
}

interface Address {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

interface ActiveExercise {
  id: string;
  title: string;
  instructions: string;
  config?: Config;
}
interface Config {
  sets?: number | undefined;
  repetitions?: number | undefined;
  hold?: number | undefined;
  interval?: number | undefined;
  side?: string | undefined;
  weights?: number | undefined;
  weightsUnit?: string | undefined;
  intervalUnit?: string | undefined;
  holdUnit?: string | undefined;
}

interface InitialData {
  EntityBranchAddress: EntityBranchAddress;
  EntityBranchName?: string;
  EntityBranchPhone: string;
  EntityName?: string;
  isEntityDetailsCompleted: boolean;
  isTherapiesConfigurationCompleted: boolean;
  entityTimeZone: string;
  isWorkingHoursConfigurationCompleted: boolean;
  isConsentCompleted: boolean;
  isEntityWorkingHoursConfigCompleted: boolean;
  isEntityBranchWorkingHoursConfigCompleted: boolean;
  RoleName?: string;
}

interface EntityBranchAddress {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: number;
}

export const TherapySchema = z.object({
  id: z.string(),
  therapyName: z.string(),
});
export type Therapy = z.infer<typeof TherapySchema>;

export const AddNewPrescriptionDetailsSchema = z.object({
  advice: z.string().optional(),
  precautions: z.string().optional(),
  testadviced: z.string().optional(),
  outReferral: z.string().optional().default(""),
  sessionCount: z.coerce.number().optional(),
  sessionFrequency: z.coerce.number().optional(),
  sessionFrequencyUnit: z.string().optional(),
  type: z.string().optional(),
  prescribedThrapeuticProcedures: z.string().array().optional(),
  shortTermGoals: z.string().optional(),
  midTermGoals: z.string().optional(),
  longTermGoals: z.string().optional(),
});
export type PrescriptionDetailsType = z.infer<
  typeof AddNewPrescriptionDetailsSchema
>;

export function TreatmentPlanTab(props: {
  patientId: string;
  masterAssessmentData: NewMasterAssessmentData | null;
  getMasterAssessmentLedger: () => Promise<void>;
}) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<PrescriptionDetailsType>({
    resolver: zodResolver(AddNewPrescriptionDetailsSchema),
  });
  const [formData, setFormData] = useState({});
  const [selectedTherapy, setSelectedTherapy] = useState<string[]>();
  const [radioValue, setRadioValue] = useState("Home");
  const [therapyList, setTherapyList] = useState<Therapy[]>([]);
  const [treatmentPlanData, setTreatmentPlanData] =
    useState<PrescriptionDetails>();
  const [patientPersonalInformation, setPatientPersonalInformation] =
    React.useState<PersonalInformationDetails | null>(null);
  const [activeExerciseProgram, setActiveExerciseProgram] = React.useState<
    ActiveExercise[] | null
  >(null);
  const [initialData, setInitialData] = useState<InitialData>();
  const userData = getUserPersistedOnLocalStorage();
  const [selectedOutReferralName, setSelectedOutReferralName] = useState("");
  const [referringData, setReferringData] = React.useState<OutReferrals | null>(
    null
  ); // This is the list of doctors

  const getEntityData = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["userid", userData?.user_id ?? ""],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.dashboardInitialData.initialData,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      if (data != null) {
        setInitialData(data);
      }
    } catch (error) {
      console.error("Error fetching staff data:", error);
    }
  };

  const getPatientData = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["patientId", props.patientId],
      ]);
      const filteredEndpoint = replaceParams(endpoints.patient.view, paramsMap);
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      setPatientPersonalInformation(data);
    } catch (error) {
      console.error("Error getting patient information", error);
    }
  };

  const getAssignedExercises = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["id", props.masterAssessmentData?.treatmentPlanId!],
      ]);
      const response = await axios.get(
        replaceParams(endpoints.managePatient.getAssignedExercises, paramsMap)
      );
      const data = response.data["data"]["homeExerciseProgram"];

      const toSet: ActiveExercise[] = [];
      if (data !== null) {
        for (const exercise of data) {
          toSet.push(exercise);
        }
      }
      setActiveExerciseProgram(toSet);
    } catch (error) {
      console.error("Error fetching active exercise program:", error);
    }
  };

  const getTherapiesList = async () => {
    try {
      const userData = getUserPersistedOnLocalStorage();
      const paramsMap = new Map<string, string>([
        ["entityId", userData?.entity_id ?? ""],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.treatmentPlan.therapyList,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      if (data) {
        setTherapyList(data);
      } else {
        setTherapyList([]);
      }
    } catch (error) {
      console.error("Error getting treatment plans", error);
    }
  };

  const getTreatmentPlanData = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["id", props.masterAssessmentData?.treatmentPlanId ?? ""],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.treatmentPlan.view,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      const convertedTreatmentPlanData = Convert.toAddNewPrescriptionDetails(
        JSON.stringify(data)
      );

      if (data.outReferral != "") {
        setSelectedOutReferralName(data.outReferral);
      }

      if (convertedTreatmentPlanData.prescribedThrapeuticProcedures === null) {
        convertedTreatmentPlanData.prescribedThrapeuticProcedures = [];
      }

      if (convertedTreatmentPlanData.type === "") {
        convertedTreatmentPlanData.type = "Home";
      }

      reset({ ...convertedTreatmentPlanData });
      setSelectedTherapy(
        convertedTreatmentPlanData.prescribedThrapeuticProcedures
      );
      setRadioValue(convertedTreatmentPlanData.type);
      setTreatmentPlanData(convertedTreatmentPlanData);
    } catch (error) {
      setRadioValue("Home");
      setSelectedTherapy([]);
      reset({
        advice: "",
        longTermGoals: "",
        midTermGoals: "",

        precautions: "",
        prescribedThrapeuticProcedures: [],
        sessionCount: 0,
        sessionFrequency: 0,
        sessionFrequencyUnit: "",
        type: "Home",
        shortTermGoals: "",
      });
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    getPatientData();
    getAssignedExercises();
    getEntityData();
    getTherapiesList();
    fetchOutReferrals();
  }, []);

  React.useEffect(() => {
    getTreatmentPlanData();
  }, [props.masterAssessmentData]);

  const onSubmit: SubmitHandler<PrescriptionDetailsType> = async (data) => {
    if (
      props.masterAssessmentData?.treatmentPlanId === "000000000000000000000000"
    ) {
      try {
        data.prescribedThrapeuticProcedures = selectedTherapy;
        const combinedData = { ...formData, ...data };
        AddNewPrescriptionDetailsSchema.parse(combinedData);
        setSelectedTherapy(combinedData.prescribedThrapeuticProcedures);
        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentData?.id!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.treatmentPlan.createPrescription,
          paramsMap
        );

        const response = await axios.post(filteredEndpoint, {
          advice: combinedData?.advice,
          precautions: combinedData.precautions,
          testadviced: combinedData.testadviced,
          type: combinedData.type ? combinedData.type : radioValue,
          prescribedThrapeuticProcedures:
            combinedData.prescribedThrapeuticProcedures,
          shortTermGoals: combinedData?.shortTermGoals,
          midTermGoals: combinedData?.midTermGoals,
          longTermGoals: combinedData?.longTermGoals,
          sessionCount: combinedData.sessionCount,
          sessionFrequency: combinedData.sessionFrequency,
          sessionFrequencyUnit: combinedData.sessionFrequencyUnit,
          outReferral: combinedData.outReferral,
        });
        props.getMasterAssessmentLedger();
        await getTreatmentPlanData();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      try {
        data.prescribedThrapeuticProcedures = selectedTherapy;
        const combinedData = { ...formData, ...data };

        AddNewPrescriptionDetailsSchema.parse(combinedData);
        setSelectedTherapy(combinedData.prescribedThrapeuticProcedures);
        const paramsMap = new Map<string, string>([
          ["id", props.masterAssessmentData?.treatmentPlanId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.treatmentPlan.update,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, {
          advice: combinedData?.advice,
          precautions: combinedData.precautions,
          testadviced: combinedData.testadviced,
          type: combinedData.type ? combinedData.type : radioValue,
          prescribedThrapeuticProcedures:
            combinedData.prescribedThrapeuticProcedures,
          shortTermGoals: combinedData?.shortTermGoals,
          midTermGoals: combinedData?.midTermGoals,
          longTermGoals: combinedData?.longTermGoals,
          sessionCount: combinedData.sessionCount,
          sessionFrequency: combinedData.sessionFrequency,
          sessionFrequencyUnit: combinedData.sessionFrequencyUnit,
          outReferral: combinedData.outReferral,
        });
        await getTreatmentPlanData();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const fetchOutReferrals = async () => {
    try {
      const response = await axios.get(endpoints.treatmentPlan.getOutReferrals);

      const data = response.data["data"];
      const converted = ConvertOutReferrals.toOutReferrals(
        JSON.stringify(data)
      );

      setReferringData(converted);
    } catch (error) {
      console.error("Error fetching staff list:", error);
    }
  };

  return (
    <>
      <form>
        <div className="gap-5">
          <div className="flex flex-col md:flex-row w-full mt-2 md:space-x-4 text-sm font-semibold">
            Select Modalities
          </div>

          <div className="flex flex-col  w-full mt-1">
            <div className="max-h-56  h overflow-y-auto">
              <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 lg:grid-cols-4">
                {therapyList &&
                  therapyList.map((therapy: Therapy) => {
                    return (
                      <div
                        key={therapy.id}
                        tabIndex={0} // Make the div focusable
                        onBlur={handleSubmit(onSubmit)}
                        className={`flex items-center justify-evenly mx-1 my-1 rounded-lg text-center text-xs py-2 px-1 max-h-10 hover:bg-tertiary-100 border transition ease-in-out duration-150 
                           ${
                             selectedTherapy?.some(
                               (ex) => ex === therapy.therapyName
                             )
                               ? "bg-tertiary-100 border-tertiary-500"
                               : "bg-white"
                           }`}
                        onClick={() => {
                          if (!selectedTherapy) setSelectedTherapy([]);
                          const isAdded =
                            selectedTherapy &&
                            selectedTherapy?.findIndex(
                              (ex) => ex === therapy.therapyName
                            ) >= 0;
                          if (isAdded) {
                            setSelectedTherapy((preAssignTherapies) =>
                              preAssignTherapies!.filter(
                                (ex) => ex !== therapy.therapyName
                              )
                            );
                          } else {
                            setSelectedTherapy((preAssignTherapies) => [
                              ...preAssignTherapies!,
                              therapy.therapyName,
                            ]);
                          }
                        }}
                      >
                        <div className="flex flex-row items-center">
                          {selectedTherapy?.some(
                            (ex) => ex === therapy.therapyName
                          ) ? (
                            <CheckCircleIcon
                              className="h-6 w-6 text-tertiary-500 font-semibold flex"
                              aria-hidden="true"
                            />
                          ) : (
                            <></>
                          )}
                          <span
                            className={`flex flex-col  ${
                              selectedTherapy?.some(
                                (ex) => ex === therapy.therapyName
                              )
                                ? "text-tertiary-500 font-semibold"
                                : "text-black font-semibold "
                            }`}
                          >
                            {therapy.therapyName}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row lg:flex-row justify-start py-2 mt-4 w-full text-sm font-semibold">
            Recommended
            <input
              className="appearance-none bg-transparent border-0 border-b border-teal-500 w-full lg:w-1/5 text-gray-700 mr-3 ml-3 py-1 px-2 focus:outline-none text-xs font-medium leading-6"
              {...register("sessionCount")}
              type="number"
              value={watch("sessionCount")}
              onBlur={handleSubmit(onSubmit)}
            />
            Sessions
            <input
              className="appearance-none bg-transparent border-0 border-b border-teal-500 w-full lg:w-1/5 text-gray-700 ml-3 mr-3 py-1 px-2 focus:outline-none text-xs font-medium leading-6"
              {...register("sessionFrequency")}
              type="number"
              value={watch("sessionFrequency")}
              onBlur={handleSubmit(onSubmit)}

              // onKeyDown="return event.keyCode !== 69 && event.keyCode !== 189"
            />
            <span className="flex-none font-medium mb-0">Per</span>
            <select
              {...register("sessionFrequencyUnit")}
              className={`appearance-none bg-transparent border-0 border-b border-teal-500 w-full lg:w-1/5 text-gray-700 mr-3 ml-3 py-1 px-2 focus:outline-none text-xs font-medium leading-6`}
              value={watch("sessionFrequencyUnit")}
              onBlur={handleSubmit(onSubmit)}
            >
              {["Week", "Month", "Year", "Days"].map((sessionFrequencyUnit) => {
                return (
                  <option
                    key={sessionFrequencyUnit}
                    value={sessionFrequencyUnit}
                  >
                    {sessionFrequencyUnit}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="flex flex-col md:flex-row w-full mt-2 md:space-x-4">
            <div className="flex flex-row  w-full text-sm font-semibold ">
              Session Type
              <div className="flex">
                {["Home", "Clinic", "Remote"].map((option) => (
                  <label
                    key={option}
                    className="flex-none font-sm font-normal mx-2"
                  >
                    <input
                      className={`ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm font-normal leading-6 mr-1`}
                      type="radio"
                      name="radioValues"
                      onBlur={handleSubmit(onSubmit)}
                      value={option}
                      checked={radioValue === option}
                      onChange={(e) => {
                        setRadioValue(e.target.value),
                          setValue("type", e.target.value);
                      }}
                    />
                    {option}
                    <br />
                  </label>
                ))}
              </div>
              {errors.sessionFrequencyUnit && (
                <ValidationError
                  message={
                    errors.sessionFrequencyUnit?.message?.toString() ?? ""
                  }
                />
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
            <div className="flex flex-col  w-full text-sm">
              <span className="font-semibold">Advice</span>
              <div className="flex flex-row w-full mt-1">
                <textarea
                  {...register("advice")}
                  key="advice"
                  name="advice"
                  placeholder="Enter advice details "
                  rows={3}
                  value={watch("advice")}
                  onBlur={handleSubmit(onSubmit)}
                  className="block w-full rounded-lg border-0 py-2 ring-1 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
                {errors.advice && (
                  <ValidationError
                    message={errors.advice?.message?.toString() ?? ""}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
            <div className="flex flex-col  w-full text-sm">
              <span className="font-semibold">Precaution</span>
              <div className="flex flex-row w-full mt-1">
                <textarea
                  {...register("precautions")}
                  key="precautions"
                  name="precautions"
                  placeholder="Enter details"
                  rows={3}
                  value={watch("precautions")}
                  onBlur={handleSubmit(onSubmit)}
                  className="block w-full rounded-lg border-0 py-2 ring-1 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
                {errors.precautions && (
                  <ValidationError
                    message={errors.precautions?.message?.toString() ?? ""}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
            <div className="flex flex-col  w-full text-sm">
              <span className="font-semibold">Test Advised</span>
              <div className="flex flex-row w-full mt-1">
                <textarea
                  {...register("testadviced")}
                  key="testadviced"
                  name="testadviced"
                  placeholder="Enter details"
                  rows={3}
                  value={watch("testadviced")}
                  onBlur={handleSubmit(onSubmit)}
                  className="block w-full rounded-lg border-0 py-2 ring-1 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
                {errors.precautions && (
                  <ValidationError
                    message={errors.precautions?.message?.toString() ?? ""}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
            <div className="flex flex-col  w-full text-sm">
              <span className="font-semibold">Out Referral</span>
              <div className="flex flex-row w-full mt-1">
                <OutReferralSearch
                  data={referringData?.OutReferrals || []}
                  setSelectedOutReferralName={setSelectedOutReferralName}
                  selectedOutReferralName={selectedOutReferralName}
                  register={register}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                />
                {errors.precautions && (
                  <ValidationError
                    message={errors.precautions?.message?.toString() ?? ""}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
