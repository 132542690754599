// To parse this data:
//
//   import { Convert, MuscleToneAssessment } from "./file";
//
//   const muscleToneAssessment = Convert.toMuscleToneAssessment(json);

export type MuscleToneAssessment = {
  id: string;
  masterAssessmentId?: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  hipFlexorsLeft: number;
  hipFlexorsRight: number;
  hipExtensorsLeft: number;
  hipExtensorsRight: number;
  hipAbductorsLeft: number;
  hipAbductorsRight: number;
  hipAdductorsLeft: number;
  hipAdductorsRight: number;
  hipMedialRotationLeft: number;
  hipMedialRotationRight: number;
  hipLateralRotationLeft: number;
  hipLateralRotationRight: number;
  shoulderFlexorsLeft: number;
  shoulderFlexorsRight: number;
  shoulderExtensorsLeft: number;
  shoulderExtensorsRight: number;
  shoulderAbductorsLeft: number;
  shoulderAbductorsRight: number;
  shoulderAdductorsLeft: number;
  shoulderAdductorsRight: number;
  shoulderMedialRotationLeft: number;
  shoulderMedialRotationRight: number;
  shoulderLateralRotationLeft: number;
  shoulderLateralRotationRight: number;
  shoulderElevatorsLeft: number;
  shoulderElevatorsRight: number;
  shoulderDepressorsLeft: number;
  shoulderDepressorsRight: number;
  shoulderAntepulsorsLeft: number;
  shoulderAntepulsorsRight: number;
  shoulderRetropulsorsLeft: number;
  shoulderRetropulsorsRight: number;
  kneeFlexorsLeft: number;
  kneeFlexorsRight: number;
  kneeExtensorsLeft: number;
  kneeExtensorsRight: number;
  elbowFlexorsLeft: number;
  elbowFlexorsRight: number;
  elbowExtensorsLeft: number;
  elbowExtensorsRight: number;
  ankleDorsiFlexorsLeft: number;
  ankleDorsiFlexorsRight: number;
  anklePlantarFlexorsLeft: number;
  anklePlantarFlexorsRight: number;
  ankleInversorsLeft: number;
  ankleInversorsRight: number;
  ankleEversorsLeft: number;
  ankleEversorsRight: number;
  forearmPronatorsLeft: number;
  forearmPronatorsRight: number;
  forearmSupinatorsLeft: number;
  forearmSupinatorsRight: number;
  footFlexorsLeft: number;
  footFlexorsRight: number;
  footExtensorsLeft: number;
  footExtensorsRight: number;
  wristFlexorsLeft: number;
  wristFlexorsRight: number;
  wristExtensorsLeft: number;
  wristExtensorsRight: number;
  trunkFlexorsLeft: number;
  trunkFlexorsRight: number;
  trunkExtensorLeft: number;
  trunkExtensorRight: number;
  trunkRBendingLeft: number;
  trunkRBendingRight: number;
  trunkLBendingLeft: number;
  trunkLBendingRight: number;
  trunkRRotationLeft: number;
  trunkRRotationRight: number;
  trunkLRotationLeft: number;
  trunkLRotationRight: number;
  fingersFlexorsLeft: number;
  fingersFlexorsRight: number;
  fingersExtensorsLeft: number;
  fingersExtensorsRight: number;
  fingersAbductorsLeft: number;
  fingersAbductorsRight: number;
  fingersOppositionLeft: number;
  fingersOppositionRight: number;
};

export type MuscleToneDetails = {
  name?: string;
  category?: string;
  right?: number;
  left?: number;
};

// Converts JSON strings to/from your types
export class ConvertMuscleTone {
  public static toMuscleToneAssessment(json: string): MuscleToneAssessment {
    return JSON.parse(json);
  }

  public static muscleToneAssessmentToJson(
    value: MuscleToneAssessment
  ): string {
    return JSON.stringify(value);
  }
}
