import { getPageTitleByPath } from '@routes/paths';
import { BrowserRouter as Router, Route, Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { HomeIcon } from '@heroicons/react/24/outline';
import navigation from './nav-items';

export function Breadcrumb() {
  const location = useLocation();

  const currentPath = location.pathname;

  const pathSegments = currentPath.split('/').filter(segment => segment !== '');
  const breadcrumbTrail: { label: string; path: string }[] = [];
  const idStack: any[] = [];

  for (let i = 0; i < pathSegments.length; i++) {
    const segment = pathSegments[i];

    if (segment === ':entityId') {
      continue;
    }
    const decodedSegment = decodeURIComponent(segment.replace(/\+/g, ' '));
    if (/^[0-9a-fA-F]{24}$/.test(segment)) {
      idStack.push(segment);
      continue;
    }
    const path = `/${pathSegments.slice(0, i + 1).join('/')}`;
    const title = getPageTitleByPath(decodedSegment);
    breadcrumbTrail.push({ label: title, path });
  }

  const handleBreadcrumbClick = (index: number) => {
    const breadcrumb = breadcrumbTrail[index];
    if (index === breadcrumbTrail.length - 1 && breadcrumb.label === "view") {
      return;
    }
    else if (index > 0 && idStack.length > 0) {
      const id = idStack[index - 1];
      window.location.href = `/patient/manage/${id}`;
    } else
      window.location.href = breadcrumb.path;
  };

  return (
    <nav aria-label="Breadcrumb" className='flex items-center text-lg font-bold leading-7 text-gray-900 sm:text-xl sm:truncate'>
      <ol className="flex items-center space-x-2">
        {breadcrumbTrail.map((breadcrumb, index) => (
          <li key={index} className="flex items-center">
            {index === breadcrumbTrail.length - 1 ? (
              <span className="text-gray-700">{breadcrumb.label}</span>
            ) : (
              <button onClick={() => handleBreadcrumbClick(index)} className="text-gray-700 hover:text-gray-500">{breadcrumb.label}</button>
            )}
            {index !== breadcrumbTrail.length - 1 && (
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-400 ml-1 inline"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
            )}
          </li>
        ))}
      </ol>
    </nav>

  );
}