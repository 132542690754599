import Button, { ButtonVariant } from '@components/button/button';
import React, { Fragment } from 'react';
import { EditorState, Editor, RichUtils, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import draftToHtml from 'draftjs-to-html';
import { endpoints, replaceParams } from '@utils/axios';
import axios from 'axios';
import { Consent } from '../types/Consent';
import { Dialog, Transition } from '@headlessui/react';
import { toast } from 'react-toastify';

interface IProps {
  entityId: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getClientConsent: () => Promise<void>;
  initialEditorState: EditorState;
}

const RichTextEditor: React.FC<IProps> = ({
  entityId,
  isOpen,
  setIsOpen,
  getClientConsent,
  initialEditorState,
}) => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(),
  );

  const [clientConsent, setClientConsent] = React.useState<Consent>();

  React.useEffect(() => {
    setEditorState(initialEditorState);
    getClientConsent();
  }, [isOpen]);

  React.useEffect(() => {
    setClientConsent({
      id: '',
      entityId: entityId,
      content: `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}`,
      title: '',
      version: '',
      audit: {
        createdBy: null,
        lastUpdatedBy: null,
        createdAt: new Date(),
        lastUpdatedAt: new Date(),
      },
    });
  }, [editorState]);

  const closeModal = () => {
    getClientConsent;
  };

  const onChange = (newEditorState: React.SetStateAction<EditorState>) => {
    setEditorState(newEditorState);
  };

  const handleKeyCommand = (command: string) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      onChange(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const onToggleInlineStyle = (style: string) => {
    onChange(RichUtils.toggleInlineStyle(editorState, style));
  };

  const onToggleBlockType = (blockType: string) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const onToggleUnderline = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
  };

  const onBoldClick = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
  };

  const onItalicClick = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
  };

  const addClientConsent = async () => {
    try {
      const paramsMap = new Map<string, string>([['id', entityId]]);
      const filteredEndpoint = replaceParams(
        endpoints.entity.addClientConsent,
        paramsMap,
      );
      const response = await axios.put(filteredEndpoint, clientConsent);
      if (response.data.status == 200) {
        toast.success(
          response.data.message === 'success'
            ? 'Consent saved successfully.'
            : response.data.message,
        );
      }
    } catch (error) {
      toast.warning('Please add consent.');
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full lg:max-w-4xl md:max-w-2xl sm:max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Client Consent
                  </Dialog.Title>
                  <div className="flex flex-col">
                    <div className="border rounded h-20 p-1 flex flex-1 justify-between mb-2">
                      <button onClick={() => onToggleBlockType('header-one')}>
                        H1
                      </button>
                      <button onClick={() => onToggleBlockType('header-two')}>
                        H2
                      </button>
                      <button onClick={() => onToggleBlockType('header-three')}>
                        H3
                      </button>
                      <button onClick={() => onToggleBlockType('header-four')}>
                        H4
                      </button>
                      <button onClick={() => onToggleBlockType('header-five')}>
                        H5
                      </button>
                      <button onClick={() => onToggleBlockType('header-six')}>
                        H6
                      </button>
                      <button onClick={() => onToggleBlockType('blockquote')}>
                        Blockquote
                      </button>
                      <button
                        onClick={() => onToggleBlockType('unordered-list-item')}
                      >
                        Bulleted List
                      </button>
                      <button
                        onClick={() => onToggleBlockType('ordered-list-item')}
                      >
                        Numbered List
                      </button>
                    </div>
                    <div className="border rounded p-1 flex flex-row justify-between mb-2">
                      <button onClick={onBoldClick}>Bold</button>
                      <button onClick={onItalicClick}>Italic</button>
                      <button onClick={onToggleUnderline}>Underline</button>
                      <button onClick={() => onToggleInlineStyle('CODE')}>
                        Monospace
                      </button>
                    </div>
                    <div className="border rounded p-4 lg:h-96 md:h-72 overflow-y-auto w-full">
                      <Editor
                        editorState={editorState}
                        onChange={setEditorState}
                        handleKeyCommand={handleKeyCommand}
                        placeholder="Please enter client consent here..."
                      />
                    </div>
                    <div className="flex flex-row w-10 my-2">
                      <Button
                        className="mr-2"
                        variant={ButtonVariant.PRIMARY}
                        type="submit"
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant={ButtonVariant.PRIMARY}
                        type="submit"
                        onClick={() => {
                          addClientConsent();
                          setIsOpen(false);
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default RichTextEditor;
