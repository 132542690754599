// Modal.tsx
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon, HeartIcon, PencilIcon, PlusIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useState } from 'react';

interface OffcanvasProps {
  children: React.ReactNode;
  isOpen: boolean;
  sizeClasses: string;
  onClose: () => void;
}

const RightOffcanvas: React.FC<OffcanvasProps> = ({ children, sizeClasses, isOpen, onClose }) => {
  const cancelButtonRef = React.useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className={`pointer-events-auto relative ${sizeClasses}`}>

                  <div className="h-full overflow-y-auto bg-white p-8">
                    <div className="pb-16">
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
  </Transition.Root>
  );
};

export default RightOffcanvas;
