// To parse this data:
//
//   import { Convert, Pain } from "./file";
//
//   const pain = Convert.toPain(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface Pain {
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  onset: number;
  progression: string;
  painIntensity: number;
  painSite: string;
  painSide: string;
  headPainLeft: boolean;
  headPainRight: boolean;
  neckPainLeft: boolean;
  neckPainRight: boolean;
  shoulderPainLeft: boolean;
  shoulderPainRight: boolean;
  wristPainLeft: boolean;
  wristPainRight: boolean;
  handPainLeft: boolean;
  handPainRight: boolean;
  elbowPainLeft: boolean;
  elbowPainRight: boolean;
  chestPainLeft: boolean;
  chestPainRight: boolean;
  backPainLeft: boolean;
  backPainRight: boolean;
  hipPainLeft: boolean;
  hipPainRight: boolean;
  kneePainLeft: boolean;
  kneePainRight: boolean;
  anklePainLeft: boolean;
  anklePainRight: boolean;
  footPainLeft: boolean;
  footPainRight: boolean;
  constantIntermittentLeft: boolean;
  constantIntermittentRight: boolean;
  sharpLeft: boolean;
  sharpRight: boolean;
  shootingLeft: boolean;
  shootingRight: boolean;
  burningLeft: boolean;
  burningRight: boolean;
  throbbingLeft: boolean;
  throbbingRight: boolean;
  numbnessLeft: boolean;
  numbnessRight: boolean;
  pinsAndNeedlesLeft: boolean;
  pinsAndNeedlesRight: boolean;
  aggravatingFactors: string;
  relievingFactors: string;
  activityLimitation: string;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class ConvertPain {
  public static toPain(json: string): Pain {
    return cast(JSON.parse(json), r("Pain"));
  }

  public static painToJson(value: Pain): string {
    return JSON.stringify(uncast(value, r("Pain")), null, 2);
  }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ""): never {
  const prettyTyp = prettyTypeName(typ);
  const parentText = parent ? ` on ${parent}` : "";
  const keyText = key ? ` for key "${key}"` : "";
  throw Error(
    `Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(
      val
    )}`
  );
}

function prettyTypeName(typ: any): string {
  if (Array.isArray(typ)) {
    if (typ.length === 2 && typ[0] === undefined) {
      return `an optional ${prettyTypeName(typ[1])}`;
    } else {
      return `one of [${typ
        .map((a) => {
          return prettyTypeName(a);
        })
        .join(", ")}]`;
    }
  } else if (typeof typ === "object" && typ.literal !== undefined) {
    return typ.literal;
  } else {
    return typeof typ;
  }
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(
  val: any,
  typ: any,
  getProps: any,
  key: any = "",
  parent: any = ""
): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val, key, parent);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) {}
    }
    return invalidValue(typs, val, key, parent);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(
      cases.map((a) => {
        return l(a);
      }),
      val,
      key,
      parent
    );
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue(l("array"), val, key, parent);
    return val.map((el) => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue(l("Date"), val, key, parent);
    }
    return d;
  }

  function transformObject(
    props: { [k: string]: any },
    additional: any,
    val: any
  ): any {
    if (val === null || typeof val !== "object" || Array.isArray(val)) {
      return invalidValue(l(ref || "object"), val, key, parent);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key)
        ? val[key]
        : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, key, ref);
    });
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key, ref);
      }
    });
    return result;
  }

  if (typ === "any") return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val, key, parent);
  }
  if (typ === false) return invalidValue(typ, val, key, parent);
  let ref: any = undefined;
  while (typeof typ === "object" && typ.ref !== undefined) {
    ref = typ.ref;
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === "object") {
    return typ.hasOwnProperty("unionMembers")
      ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty("arrayItems")
      ? transformArray(typ.arrayItems, val)
      : typ.hasOwnProperty("props")
      ? transformObject(getProps(typ), typ.additional, val)
      : invalidValue(typ, val, key, parent);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== "number") return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function l(typ: any) {
  return { literal: typ };
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function m(additional: any) {
  return { props: [], additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  Pain: o(
    [
      { json: "id", js: "id", typ: "" },
      { json: "masterAssessmentId", js: "masterAssessmentId", typ: "" },
      { json: "clientId", js: "clientId", typ: "" },
      { json: "entityId", js: "entityId", typ: "" },
      { json: "entityBranchId", js: "entityBranchId", typ: "" },
      { json: "onset", js: "onset", typ: 0 },
      { json: "progression", js: "progression", typ: "" },
      { json: "painIntensity", js: "painIntensity", typ: 0 },
      { json: "painSite", js: "painSite", typ: "" },
      { json: "painSide", js: "painSide", typ: "" },
      { json: "headPainLeft", js: "headPainLeft", typ: true },
      { json: "headPainRight", js: "headPainRight", typ: true },
      { json: "neckPainLeft", js: "neckPainLeft", typ: true },
      { json: "neckPainRight", js: "neckPainRight", typ: true },
      { json: "shoulderPainLeft", js: "shoulderPainLeft", typ: true },
      { json: "shoulderPainRight", js: "shoulderPainRight", typ: true },
      { json: "wristPainLeft", js: "wristPainLeft", typ: true },
      { json: "wristPainRight", js: "wristPainRight", typ: true },
      { json: "handPainLeft", js: "handPainLeft", typ: true },
      { json: "handPainRight", js: "handPainRight", typ: true },
      { json: "elbowPainLeft", js: "elbowPainLeft", typ: true },
      { json: "elbowPainRight", js: "elbowPainRight", typ: true },
      { json: "chestPainLeft", js: "chestPainLeft", typ: true },
      { json: "chestPainRight", js: "chestPainRight", typ: true },
      { json: "backPainLeft", js: "backPainLeft", typ: true },
      { json: "backPainRight", js: "backPainRight", typ: true },
      { json: "hipPainLeft", js: "hipPainLeft", typ: true },
      { json: "hipPainRight", js: "hipPainRight", typ: true },
      { json: "kneePainLeft", js: "kneePainLeft", typ: true },
      { json: "kneePainRight", js: "kneePainRight", typ: true },
      { json: "anklePainLeft", js: "anklePainLeft", typ: true },
      { json: "anklePainRight", js: "anklePainRight", typ: true },
      { json: "footPainLeft", js: "footPainLeft", typ: true },
      { json: "footPainRight", js: "footPainRight", typ: true },
      {
        json: "constantIntermittentLeft",
        js: "constantIntermittentLeft",
        typ: true,
      },
      {
        json: "constantIntermittentRight",
        js: "constantIntermittentRight",
        typ: true,
      },
      { json: "sharpLeft", js: "sharpLeft", typ: true },
      { json: "sharpRight", js: "sharpRight", typ: true },
      { json: "shootingLeft", js: "shootingLeft", typ: true },
      { json: "shootingRight", js: "shootingRight", typ: true },
      { json: "burningLeft", js: "burningLeft", typ: true },
      { json: "burningRight", js: "burningRight", typ: true },
      { json: "throbbingLeft", js: "throbbingLeft", typ: true },
      { json: "throbbingRight", js: "throbbingRight", typ: true },
      { json: "numbnessLeft", js: "numbnessLeft", typ: true },
      { json: "numbnessRight", js: "numbnessRight", typ: true },
      { json: "pinsAndNeedlesLeft", js: "pinsAndNeedlesLeft", typ: true },
      { json: "pinsAndNeedlesRight", js: "pinsAndNeedlesRight", typ: true },
      { json: "aggravatingFactors", js: "aggravatingFactors", typ: "" },
      { json: "relievingFactors", js: "relievingFactors", typ: "" },
      { json: "activityLimitation", js: "activityLimitation", typ: "" },
    ],
    false
  ),
};
