// To parse this data:
//
//   import { Convert, Balance } from "./file";
//
//   const balance = Convert.toBalance(json);

export interface Balance {
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  singleLegStance: string;
  tandemLegStance: string;
  rhombergTest: string;
  functionalReachTest: string;
  bergsBalanceScaleScore: string;
  tugTestValue: string;
  tinettiPerformanceOrientedMobility: string;
  dynamicGaitIndex: string;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toBalance(json: string): Balance {
    return JSON.parse(json);
  }

  public static balanceToJson(value: Balance): string {
    return JSON.stringify(value);
  }
}
