import { useCallback, useEffect, useMemo, useState } from "react";

import React from "react";

import { OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";

import { endpoints, replaceParams } from "@utils/axios";
import useAxios from "@routes/hooks/use-axios";
import { useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { Convert, Expense } from "../types/Expense";
import { Pagination } from "@pages/appointments/appointment-index-table/Pagination";
import {
  filterRows,
  paginateRows,
  sortRows,
} from "@pages/appointments/appointment-index-table/Helpers";
import ExpensesComboBox from "@pages/expenses/ExpensesComboBox";
import Applabel from "@components/hook-form/applabel";
import SolidButton from "@components/button/button";
import Button from "@components/button/button";
import * as z from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ValidationError } from "@components/hook-form";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { toast } from "react-toastify";
import moment from "moment";
import { MonthYearPicker } from "@utils/MonthYearPicker";
import DeleteConfirmation from "@components/delete/DeleteConfirmation";
import { EntityBranch, EntityBranchConvert } from "../types/EntityBranch";

export const ExpenseSchema = z.object({
  entityId: z.string().optional(),
  entityBranchId: z.string().optional(),
  type: z.string().min(1, { message: "Expense type is required" }),
  date: z.coerce.date({
    required_error: "Date is required",
  }),
  amount: z
    .number({
      required_error: "Amount is required",
      invalid_type_error: "Amount must be a number",
    })
    .nonnegative(),
  paidTo: z.string().min(1, { message: "Paid to is required" }),
  details: z.string().min(1, { message: "Details are required" }),
});
export type ExpenseSchemaType = z.infer<typeof ExpenseSchema>;

function ExpenseIndexPage() {
  const [expenses, setExpenses] = React.useState<Expense[] | undefined>([]);
  const [loading, setLoading] = React.useState(true);
  const [branches, setBranches] = React.useState<EntityBranch[]>([]);
  const [selectedBranch, setSelectedBranch] = React.useState<string>("0");
  const axios = useAxios();
  const [rows, setRows] = useState([]);
  const [dateSelected, setDateSelected] = useState<Date | null>(null);
  const [clearFilterCalled, setclearFilterCalled] = useState<boolean>(false);

  const columns = [
    { accessor: "type", label: "Expense type" },

    { accessor: "amount", label: "Amount" },
    { accessor: "paidTo", label: "Paid To" },
    { accessor: "details", label: "Details" },
    { accessor: "date", label: "Date" },
    { accessor: "actions", label: "Actions" },
  ];

  const [activePage, setActivePage] = useState<number>(1);
  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [sort, setSort] = useState<{ order: string; orderBy: string }>({
    order: "",
    orderBy: "",
  });

  const rowsPerPage: number = 8;
  const filteredRows = useMemo(
    () => filterRows(rows, filters),
    [rows, filters]
  );
  const sortedRows = useMemo(
    () => sortRows(filteredRows, sort),
    [filteredRows, sort]
  );
  const calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage);

  const count: number = filteredRows.length;
  const totalPages: number = Math.ceil(count / rowsPerPage);
  const userData = getUserPersistedOnLocalStorage();

  const getBranches = async () => {
    const userData = getUserPersistedOnLocalStorage();
    const paramsMap = new Map<string, string>([
      ["entityId", userData?.entity_id ?? ""],
    ]);
    const filteredEndpoint = replaceParams(
      endpoints.ownerDashboard.branches,
      paramsMap
    );
    const response = await axios.get(filteredEndpoint);
    const data = response.data["data"];
    const converted = [];

    for (const branch of data) {
      const temp = EntityBranchConvert.toEntityBranch(JSON.stringify(branch));
      converted.push(temp);
    }

    setBranches(converted);
  };

  const handleSelectChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedBranchId = event.target.value;
    setSelectedBranch(selectedBranchId);
  };

  const getExpenses = async () => {
    try {
      const paramsMap = new Map<string, string>([["id", selectedBranch]]);
      const filteredEndpoint = replaceParams(
        endpoints.expense.getExpenseForEntity,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      if (data !== null) {
        const convertedList = data.map((expense: any) => {
          let expenseShadow = Convert.toExpense(JSON.stringify(expense));

          return expenseShadow;
        });

        setExpenses(convertedList);
        setRows(convertedList);
      } else {
        setExpenses([]);
        setRows([]);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching expenses details:", error);

      setLoading(false);
    }
  };

  useEffect(() => {
    getBranches();
  }, []);

  useEffect(() => {
    getExpenses();
  }, [selectedBranch]);

  const validateExpense = (expense: any) => {
    try {
      ExpenseSchema.parse(expense);
      return true;
    } catch (error) {
      return false;
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<ExpenseSchemaType>({
    resolver: zodResolver(ExpenseSchema),
    defaultValues: {
      entityId: userData?.entity_id,
      entityBranchId: userData?.branch_id,
    },
  });

  const deleteExpense = async (id: string) => {
    try {
      const paramsMap = new Map<string, string>([["id", id ?? ""]]);
      const filteredEndpoint = replaceParams(
        endpoints.expense.deleteExpense,
        paramsMap
      );
      const response = await axios.delete(filteredEndpoint);

      if (response.data.status === 200) {
        toast.success(
          response.data.message === "success"
            ? "Expense Deleted successfully"
            : response.data.message
        );
        getExpenses();
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching expenses details:", error);
      toast.error("Error Deleting Expense");
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<ExpenseSchemaType> = async (data) => {
    try {
      ExpenseSchema.parse(data);
      let combinedData = { ...data };

      if (
        userData?.user_role === "ENTITY_OWNER" ||
        userData?.user_role === "PLATFORM_ADMIN"
      ) {
        if (selectedBranch != "0") combinedData.entityBranchId = selectedBranch;
      }
      const response = await axios.post(endpoints.expense.addExpense, {
        ...combinedData,
      });
      if (response.data.status === 200) {
        toast.success(
          response.data.message === "success"
            ? "Expense Added successfully"
            : response.data.message
        );
        getExpenses();
        reset();
        setDateSelected(null);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(error.message);
    }
  };
  const handleSearch = (value: string) => {
    setActivePage(1);
    if (expenses) {
      const filteredExpenses: any = expenses.filter(
        (app: Expense) =>
          app.type?.toLowerCase().includes(value.toLowerCase()) ||
          app.paidTo
            ?.replace(/\s/g, "")
            .trim()
            .toLowerCase()
            .includes(value.replace(/\s/g, "").trim().toLowerCase()) ||
          app.details
            ?.replace(/\s/g, "")
            .trim()
            .toLowerCase()
            .includes(value.replace(/\s/g, "").trim().toLowerCase()) ||
          app.amount?.toString().toLowerCase().includes(value.toLowerCase()) ||
          moment(app.date)
            .format("DD-MM-YYYY")
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
      );

      setRows(filteredExpenses);
    }
  };

  const handleExpensesDateChange = (
    selectedMonth: number,
    selectedYear: number,
    startDate: Date,
    endDate: Date
  ) => {
    const filteredExpenses: any = expenses?.filter((expense) => {
      if (expense.date) {
        const expenseDate = new Date(expense.date?.toString());
        const expenseMonth = expenseDate.getMonth() + 1; // Month is zero-based
        const expenseYear = expenseDate.getFullYear();

        return (
          expenseMonth === selectedMonth &&
          expenseYear === selectedYear &&
          expenseDate >= startDate &&
          expenseDate <= endDate
        );
      }
    });

    setDateSelected(startDate);
    setRows(filteredExpenses);
    setclearFilterCalled(false);
  };

  return (
    <>
      <>
        <div className="w-full flex flex-col md:flex-row justify-between">
          <div className="flex flex-col justify-start w-full">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              Expenses
            </h1>
            <p className="mt-2 text-sm text-black w-full">
              List of all the expenses you have added on the platform. You can
              view, filter and add expenses based on types.
            </p>
          </div>
          <div className="flex flex-row justify-between sm:justify-end w-full space-x-2">
            {(userData?.user_role === "ENTITY_OWNER" ||
              userData?.user_role === "PLATFORM_ADMIN") && (
              <div className="flex flex-col w-1/2 sm:w-1/3">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Branch
                </label>
                <select
                  className="border border-1 rounded-md w-full cursor-pointer"
                  disabled={false}
                  onChange={handleSelectChange}
                >
                  <option key={"All"} value={"0"}>
                    All
                  </option>
                  {branches.map((branch) => (
                    <option key={branch.id} value={branch.id}>
                      {branch.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="flex flex-col w-1/2 sm:w-1/3 ml-auto">
              <label className="block text-sm font-medium leading-6 text-gray-900 w-full">
                Filter
              </label>
              <MonthYearPicker
                onChange={handleExpensesDateChange}
                ferchedStartDate={
                  clearFilterCalled === true ? null : dateSelected
                }
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <p
            className="self-end underline hover:cursor-pointer"
            onClick={() => {
              getExpenses();
              setclearFilterCalled(true);
            }}
          >
            Clear Filter
          </p>
        </div>
      </>
      {/* For Widescreen layout */}

      <div className=" flex flex-row ">
        <div className="flex flex-col w-full  ">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className=" flex flex-col md:flex-row md:space-x-2 my-4  ">
              <div className="flex flex-col  w-full ">
                <Applabel label="Expense type" mandatory />

                <ExpensesComboBox
                  register={register}
                  name="type"
                  setValue={setValue}
                  watch={"type"}
                  isDisabled={false}
                  clearErrors={clearErrors}
                />
                {errors.type && (
                  <ValidationError
                    message={errors.type?.message?.toString() ?? ""}
                  />
                )}
              </div>
              <div className="flex flex-col  w-full ">
                <Applabel label="Amount" mandatory />
                <input
                  placeholder="Enter Amount"
                  type="number"
                  {...register("amount", {
                    valueAsNumber: true,
                  })}
                  name="amount"
                  className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                />
                {errors.amount && (
                  <ValidationError
                    message={errors.amount?.message?.toString() ?? ""}
                  />
                )}
              </div>

              <div className="flex flex-col  w-full ">
                <Applabel label="Paid to" mandatory />

                <input
                  placeholder="Paid to"
                  type="text"
                  {...register("paidTo")}
                  name="paidTo"
                  className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                />
                {errors.paidTo && (
                  <ValidationError
                    message={errors.paidTo?.message?.toString() ?? ""}
                  />
                )}
              </div>
              <div className="flex flex-col  w-full ">
                <Applabel label="Details" mandatory />

                <input
                  placeholder="Details"
                  type="text"
                  {...register("details")}
                  name="details"
                  className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                />
                {errors.details && (
                  <ValidationError
                    message={errors.details?.message?.toString() ?? ""}
                  />
                )}
              </div>
              <div className=" w-full ">
                <Applabel label="Date" mandatory />

                <input
                  type="date"
                  {...register("date")}
                  name="date"
                  className={`flex lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 cursor-pointer`}
                />
                {errors.date && (
                  <ValidationError
                    message={errors.date?.message?.toString() ?? ""}
                  />
                )}
              </div>
              <div className=" w-full">
                <div className="mt-2.5">
                  <label className="text-xs font-medium leading-6 text-grey-900  invisible">
                    Add
                  </label>
                </div>
                <SolidButton
                  variant={ButtonVariant.PRIMARY}
                  type="submit"
                  className="w-full justify-center"
                >
                  Add Expense
                </SolidButton>
              </div>
            </div>
          </form>
          <div className="flex flex-col w-full">
            <SearchBar handleSearch={handleSearch} />
          </div>
          <div className="flex flex-col w-full">
            <Table
              rows={expenses || []}
              columns={columns}
              calculatedRows={calculatedRows}
              activePage={activePage}
              count={count}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              setActivePage={setActivePage}
              getExpenses={getExpenses}
              deleteExpense={deleteExpense}
            />
          </div>
          {count > 0 ? (
            <Pagination
              activePage={activePage}
              count={count}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              setActivePage={setActivePage}
            />
          ) : (
            <p>No data found</p>
          )}
        </div>
        <hr className="my-4" />
      </div>
    </>
  );
}

export default ExpenseIndexPage;

interface Column {
  accessor: string;
  label: string;
  format?: (value: any) => string | JSX.Element;
}

interface TableProps {
  columns: Column[];
  rows: any[];

  calculatedRows: Record<string, any>[];
  activePage: number;
  count: number;
  rowsPerPage: number;
  totalPages: number;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
  getExpenses: () => Promise<void>;
  deleteExpense: (id: string) => Promise<void>;
}

export const Table: React.FC<TableProps> = ({
  columns,
  rows,
  calculatedRows,
  activePage,
  count,
  rowsPerPage,
  totalPages,
  setActivePage,
  getExpenses,
  deleteExpense,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedExpenseId, setSelectedExpenseId] = useState("");
  return (
    <>
      <table className="w-full  shadow outline outline-gray-200 outline-1 p-2 mb-4">
        <thead className="bg-gray-50 rounded-t-lg">
          <tr>
            {columns.map((column) => {
              return (
                <th
                  key={column.accessor}
                  className="px-4 py-3 border-b border-gray-300"
                >
                  <div className="flex items-center space-x-1">
                    <span>{column.label}</span>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="bg-white">
          {calculatedRows.map((row, index) => {
            return (
              <tr
                key={row.id}
                className={(() => {
                  // if not last row then add border
                  if (index !== calculatedRows.length - 1) {
                    return "border-b border-gray-200";
                  }

                  return "";
                })()}
              >
                <td key={"type"} className={`px-4 py-3 ${"text-gray-500"}`}>
                  {row["type"]}
                </td>

                <td key={"amount"} className={`px-4 py-3 ${"text-gray-500"}`}>
                  {row["amount"]}
                </td>

                <td key={"paidTo"} className={`px-4 py-3 ${"text-gray-500"}`}>
                  {row["paidTo"]}
                </td>
                <td key={"details"} className={`px-4 py-3 ${"text-gray-500"}`}>
                  {row["details"]}
                </td>
                <td key={"date"} className={`px-4 py-3 ${"text-gray-500"}`}>
                  {moment(row["date"]).format("DD-MM-YYYY").toString()}
                </td>

                <td className={`px-4 py-3 ${"text-gray-500"}`}>
                  <div className="flex flex-row">
                    <div className="flex-shrink mr-2">
                      <OutlinedButton
                        variant={ButtonVariant.SECONDARY}
                        type="button"
                        onClick={() => {
                          setSelectedExpenseId(row.id);
                          setShowDeleteConfirmation(true);
                        }}
                        children="Delete"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {showDeleteConfirmation && (
        <DeleteConfirmation
          isOpen={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={() => {
            deleteExpense(selectedExpenseId);
            setShowDeleteConfirmation(false);
          }}
          selectedExpenseId={selectedExpenseId}
        />
      )}
    </>
  );
};

function SearchBar({ handleSearch }: { handleSearch: (value: any) => void }) {
  return (
    <div>
      <input
        type="text"
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search for Expenses by type,amount,paid to,details,date"
        className="w-full px-4 py-2 mb-4 text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-0 focus:border-primary-500"
      />
    </div>
  );
}
