// storage key constants for local storage and session storage
export const STORAGE_KEYS = {
  // User stringified storage key
  USER_DATA: 'userData',
  // App state stringified storage key
  APP_STATE: {
    ACCESS_TOKEN: 'access_token',
    REFRESH_TOKEN: 'refresh_token',
    GOOGLE_ACCESS_TOKEN: 'google_access_token',
    GOOGLE_REFRESH_TOKEN: 'google_refresh_token',
  },
  IS_GOOGLE_AUTH_ENABLED: 'isGoogleAuthEnabled',
  ENTITY_TIMEZONE: 'entityTimeZone',
  UI_VERSION: 'uiVersion',

};
