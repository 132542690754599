// To parse this data:
//
//   import { Convert } from "./file";
//
//   const externalLink = Convert.toExternalLink(json);

export interface ExternalLink {
    externalLinkId: string;
    linkTitle:      string;
    url:            string;
    source:         string;
    audit:          Audit;
    isFree:         boolean;
}

export interface Audit {
    createdBy:     null;
    lastUpdatedBy: null;
    createdAt:     Date;
    lastUpdatedAt: Date;
}

// Converts JSON strings to/from your types
export class Converted {
    public static toExternalLink(json: string): ExternalLink[] {
        return JSON.parse(json);
    }

    public static externalLinkToJson(value: ExternalLink[]): string {
        return JSON.stringify(value);
    }
}
