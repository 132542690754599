import { useState } from "react";

import React from "react";
import { Appointment } from "../types/Appointment";
import { OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/outline-button";
import AppointmentDetails, {
  CancelAppointmentDialog,
} from "./AppointmentDetails";
import { Appointments } from "../types/Appointments";
import { endpoints, replaceParams } from "@utils/axios";
import useAxios from "@routes/hooks/use-axios";
import { useNavigate } from "react-router-dom";
import RescheduleAppointmentPage from "../reschedule-appointment/RescheduleAppointmentPage";
import ViewPaymentDetails from "./ViewPaymentDetails";
import TransferAppointmentPage from "../transfer-appointment/TransferAppointment";
import BillingAndPaymentDetails from "@pages/payments/BillingAndPaymentDetails";
import MarkCompleteConfirmation from "./MarkCompleteConfirmation";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
interface Column {
  accessor: string;
  label: string;
  format?: (value: any) => string | JSX.Element;
}

interface MyAppointmentCardProps {
  appointmentRecord: Record<string, any>;
  getAppointments: () => Promise<void>;
  patientSelected: (clientId: string) => Promise<void>;
  isSelected: boolean;
}

export const MyAppointmentCard: React.FC<MyAppointmentCardProps> = ({
  appointmentRecord,
  getAppointments,
  patientSelected,
  isSelected,
}) => {
  const userData = getUserPersistedOnLocalStorage();
  const [markCompleteConfirmation, setMarkCompleteConfirmation] =
    useState(false);

  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState("");
  const [selectedPatientId, setSelectedPatientId] = useState("");

  const [appointmentDetails, setAppointmentDetails] =
    useState<Appointments | null>(null);
  const [addPaymentDetails, setAddPaymentDetails] = useState(false);
  const axios = useAxios();
  const navigator = useNavigate();
  const [showAppointmentDetails, setShowAppointmentDetails] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [appointmentId, setAppointmentId] = useState("");
  const [rescheduleAppointmentDialog, setRescheduleAppointmentDialog] =
    useState(false);
  const [transferAppointmentDialog, setTransferAppointmentDialog] =
    useState(false);
  const [appointment, setAppointment] = React.useState<Appointment | null>(
    null
  );
  const showDialog = (appId: string) => {
    setAppointmentId(appId);
    setShowAppointmentDetails(true);
  };

  const handleStatusChange = async (
    appointmentId: string,
    appointmentStatus: string
  ) => {
    try {
      const paramsMap = new Map<string, string>([
        ["id", appointmentId],
        ["status", appointmentStatus],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.assistantDashboard.updateAppointmentStatus,
        paramsMap
      );
      await axios.put(filteredEndpoint);
      getAppointments();
    } catch (error) {
      console.error(error);
    }
  };
  const navigate = useNavigate();

  const navigateToPatientScreen = (patientId: string) => {
    navigate(`/patient/manage/${patientId}`);
  };
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
    patientSelected(appointmentRecord["clientId"]);
  };

  return (
    <>
      <div
        className={`flex flex-col justify-between rounded-2xl border shadow-md bg-white mb-2 mr-1 hover:ring-1 focus:ring-2 ${
          isSelected ? "ring-2 ring-primary-300" : ""
        }`}
        onClick={handleClick}
      >
        <div className="flex flex-appointment rounded-xl bg-white-300 items-center justify-between p-4 pb-0">
          <div className="flex flex-col  w-3/4">
            <span
              className={`flex-initial text-base lg:text-base md:text-sm ${
                isSelected ? "text-primary-800" : "text-gray-800"
              }  font-semibold truncate`}
            >
              {appointmentRecord["clientName"]}
            </span>
            <span className="flex-initial text-sm text-gray-800">
              <span className="font-semibold">
                {appointmentRecord["startTime"]}
              </span>
            </span>
            <span className="flex-initial text-xs text-gray-800 ">
              {appointmentRecord["appointmentType"]},{" "}
              {appointmentRecord["modeOfConsultation"]}
            </span>
            <hr className="mt-2 h-0.5 bg-white-300 opacity-100 dark:opacity-50" />
          </div>
          {isSelected ? (
            <div
              className={`p-3 rounded-xl text-xs ${
                isSelected ? "animate-blink bg-primary-600" : "bg-primary-300"
              }`}
            ></div>
          ) : (
            <div className="p-3 rounded-xl text-xs font-semibold">
              {appointmentRecord.appointmentStatus}
            </div>
          )}
        </div>
        <div className="flex flex-appointment rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4 mt-2">
          <div className="flex flex-appointment w-full justify-end">
            <OutlinedButton
              variant={ButtonVariant.GRAY}
              type="button"
              className="mr-2 text-xs"
              onClick={() => showDialog(appointmentRecord.id)}
              children="View"
            />
            {appointmentRecord.paymentStatus !== "" && (
              <OutlinedButton
                variant={ButtonVariant.SECONDARY}
                className="self-end text-xs"
                onClick={() => {
                  setSelectedAppointmentId(appointmentRecord.id.toString());
                  setAppointmentDetails(appointmentRecord as Appointments);
                  setAddPaymentDetails(true);
                }}
              >
                Bill Paid
              </OutlinedButton>
              /*                     <OutlinedButton
                variant={ButtonVariant.SECONDARY}
                type="button"
                onClick={() => {
                  setSelectedAppointmentId(appointment.id.toString());
                  setAppointmentDetails(appointment as Appointments);
                  setShowPaymentDetails(true);
                }}
                children="Paid"
              /> */
            )}
            {appointmentRecord.paymentStatus === "" &&
              appointmentRecord.appointmentStatus === "Completed" && (
                <>
                  {appointmentRecord.billAmount > 0 ? (
                    <OutlinedButton
                      variant={ButtonVariant.GRAY}
                      className="self-end text-xs"
                      onClick={() => {
                        setSelectedAppointmentId(
                          appointmentRecord.id.toString()
                        );
                        setAppointmentDetails(
                          appointmentRecord as Appointments
                        );
                        setAddPaymentDetails(true);
                      }}
                    >
                      View Bill Details
                    </OutlinedButton>
                  ) : (
                    <OutlinedButton
                      variant={ButtonVariant.PRIMARY}
                      className="self-end text-xs"
                      onClick={() => {
                        setSelectedAppointmentId(
                          appointmentRecord.id.toString()
                        );
                        setAppointmentDetails(
                          appointmentRecord as Appointments
                        );
                        setAddPaymentDetails(true);
                      }}
                    >
                      Add Bill Details
                    </OutlinedButton>
                  )}
                </>
              )}
            {appointmentRecord.appointmentStatus !== "Completed" && (
              <OutlinedButton
                variant={
                  isSelected ? ButtonVariant.PRIMARY : ButtonVariant.GRAY
                }
                type="button"
                className="text-xs"
                onClick={() => {
                  setMarkCompleteConfirmation(true);
                  setSelectedAppointmentId(appointmentRecord.id.toString());
                  setAppointmentDetails(appointmentRecord as Appointments);
                }}
                children="Mark Complete"
              />
            )}
          </div>
        </div>
      </div>
      {markCompleteConfirmation && (
        <MarkCompleteConfirmation
          isOpen={markCompleteConfirmation}
          onClose={() => setMarkCompleteConfirmation(false)}
          onConfirm={() => {
            handleStatusChange(selectedAppointmentId, "Completed");
            setMarkCompleteConfirmation(false);
          }}
          selectedAppointment={appointmentDetails}
        />
      )}
      {showAppointmentDetails && (
        <AppointmentDetails
          appId={appointmentId}
          setShowAppointmentDetails={setShowAppointmentDetails}
          setRescheduleAppointmentDialog={setRescheduleAppointmentDialog}
          setTransferAppointmentDialog={setTransferAppointmentDialog}
          setCancelDialog={setCancelDialog}
          setAppointment={setAppointment}
          appointment={appointment}
        />
      )}
      {showPaymentDetails && (
        <ViewPaymentDetails
          onClose={() => setShowPaymentDetails(false)}
          onConfirm={() => {}}
          appointmentId={selectedAppointmentId}
          appointmentDetails={appointmentDetails!}
        />
      )}
      {addPaymentDetails && (
        <BillingAndPaymentDetails
          appointmentDetails={appointmentDetails!}
          onClose={() => setAddPaymentDetails(false)}
          onConfirm={() =>
            handleStatusChange(selectedAppointmentId, "Completed")
          }
        />
      )}
      {cancelDialog && (
        <CancelAppointmentDialog
          setCancelDialog={setCancelDialog}
          appId={appointmentId}
          getAppointments={getAppointments}
        />
      )}
      {rescheduleAppointmentDialog && (
        <>
          {appointmentRecord && (
            <RescheduleAppointmentPage
              onClose={() => setRescheduleAppointmentDialog(false)}
              appointmentDetails={appointment!}
              getAppointments={getAppointments}
            />
          )}
        </>
      )}
      {transferAppointmentDialog && (
        <>
          {appointmentRecord && (
            <TransferAppointmentPage
              onClose={() => setTransferAppointmentDialog(false)}
              appointmentDetails={appointment!}
              getAppointments={getAppointments}
            />
          )}
        </>
      )}
    </>
  );
};
