// To parse this data:
//
//   import { Convert, Posture } from "./file";
//
//   const posture = Convert.toPosture(json);

export type Posture = {
  id?: string;
  masterAssessmentID?: string;
  lateralHead?: string;
  lateralCervicalSpine?: string;
  lateralThoracicSpine?: string;
  lateralLumbarSpine?: string;
  lateralPelvis?: string;
  lateralHipJoint?: string;
  lateralKneeJoint?: string;
  lateralAnkleJoint?: string;
  anteriorPosteriorFacialAsymmetry?: string;
  anteriorPosteriorCervicalSpine?: string;
  anteriorPosteriorShoulderAsymmetry?: string;
  anteriorPosteriorScapulae?: string;
  anteriorPosteriorThoracicSpine?: string;
  anteriorPosteriorLumbarSpine?: string;
  anteriorPosteriorAbdomen?: string;
  anteriorPosteriorPelvis?: string;
  anteriorPosteriorHipJoint?: string;
  anteriorPosteriorKneeJoint?: string;
  anteriorPosteriorAnkleJoint?: string;
  anteriorPosteriorFeet?: string;
  anteriorPosteriorSpine?: string;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toPosture(json: string): Posture {
    return JSON.parse(json);
  }

  public static postureToJson(value: Posture): string {
    return JSON.stringify(value);
  }
}
