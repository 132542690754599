import React from 'react';
import maintenance from '../../public/maintenance.png'; // Tell webpack this JS file uses this image

// /logo.84287d09.png

function Maintenance() {
  // Import result is the URL of your image
  return (
    <>
      <div className="flex  flex-col items-center justify-center h-screen">
        <img
          className=" inset-0 h-1/2 w-1/2 object-contain"
          src={maintenance}
          alt=""
        />
        <p className="text-3xl">
          {' '}
          We are under maintenance . Please visit after sometime !
        </p>
      </div>
    </>
  );
}

export default Maintenance;
