import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from '@routes/paths';
//
import { useAuthContext } from '../hooks';
import { useRouter } from '@routes/hooks';
import React from 'react';
import { InitialData } from '@layouts/dashboard/dashboard';
import { getUserPersistedOnLocalStorage } from '@authentication/context/jwt/utils';
import { replaceParams } from '@utils/axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type Props = {
  children: React.ReactNode;
  value: InitialData | undefined;
};

// setup guard is to check if the initial setup of entity, staff or branch is completed
export default function SetupGuard({ children, value }: Props) {
  const userData = getUserPersistedOnLocalStorage();

  const navigator = useNavigate();

  const router = useRouter();

  const { authenticated } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const navigateViewEntity = () => {
    const paramsMap = new Map<string, string>([
      ['entityId', userData?.entity_id ?? ''],
      ['entityName', value?.EntityName ?? ''],
    ]);
    // closeModal();

    navigator(replaceParams(paths.staff.entityManage.route, paramsMap));
  };

  const navigateViewBranch = () => {
    const paramsMap = new Map<string, string>([
      ['branchId', userData?.branch_id ?? ''],
      ['branchName', value?.EntityBranchName ?? ''],
    ]);
    // closeModal();
    router.replace(replaceParams(paths.branch.view.route, paramsMap));
  };

  const navigateViewStaff = () => {
    const paramsMap = new Map<string, string>([
      ['staffId', userData?.user_id ?? ''],
    ]);
    // closeModal();
    router.replace(replaceParams(paths.staff.view.route, paramsMap));
  };

  const check = useCallback(() => {
    if (authenticated && value) {
      // Check if value is defined

      if (value.RoleName === 'ENTITY_OWNER') {
        if (
          value.isTherapiesConfigurationCompleted === false ||
          value.isEntityWorkingHoursConfigCompleted === false
        ) {
          toast.info(
            'Please complete Working Hours Configuration and Therapy Configuration to assess all screens.',
          );
          navigateViewEntity();
        }
      }

      if (value.RoleName === 'BRANCH_ADMIN') {
        if (value.isEntityBranchWorkingHoursConfigCompleted === false) {
          toast.info(
            'Please complete Branch Admin Working Hours Configuration to access all screens.',
          );
          navigateViewBranch();
        }
      }

      if (value.RoleName === 'PRACTITIONER' || value.RoleName === 'ASSISTANT') {
        if (value.isWorkingHoursConfigurationCompleted === false) {
          toast.info(
            'Please complete Staff Working Hours Configuration to access all screens',
          );
          navigateViewStaff();
        }
      }

      // router.replace(href);
    } else {
      setChecked(true);
    }
  }, [authenticated, value]); // Add 'value' as a dependency

  useEffect(() => {
    check();
  }, [check, value]); // Add 'check' and 'value' as dependencies

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
