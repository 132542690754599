// To parse this data:
//
//   import { Convert, LimbGirth } from "./file";
//
//   const limbGirth = Convert.toLimbGirth(json);

export type LimbGirth = {
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  observations?: string;
};

// Converts JSON strings to/from your types
export class ConvertLimbGirth {
  public static toLimbGirth(json: string): LimbGirth {
    return JSON.parse(json);
  }

  public static limbGirthToJson(value: LimbGirth): string {
    return JSON.stringify(value);
  }
}
