export type TreatmentPlanHistory = {
    id:                   string;
    clientId:             string;
    entityId:             string;
    entityBranchId:       string;
    startDate:            string;
    endDate:              string;
    clientAdvice:         string;
    precautions:          string;
    sessionCount:         number;
    sessionFrequency:     number;
    sessionFrequencyUnit: string;
    type:                 string;
    prescribedTherapies:  string[];
}

// Converts JSON strings to/from your types
export class Convert {
    public static toTreatmentPlanHistory(json: string): TreatmentPlanHistory {
        return JSON.parse(json);
    }

    public static treatmentPlanHistoryToJson(value: TreatmentPlanHistory): string {
        return JSON.stringify(value);
    }
}
