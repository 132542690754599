// To parse this data:
//
//   import { Convert } from "./file";
//
//   const occupancyByEachStaff = Convert.toOccupancyByEachStaff(json);

export interface OccupancyByEachStaff {
  entityuser: string;
  totalappointments: number;
}

// Converts JSON strings to/from your types
export class OccupancyByEachStaffConvert {
  public static toOccupancyByEachStaff(json: string): OccupancyByEachStaff[] {
    return JSON.parse(json);
  }

  public static occupancyByEachStaffToJson(
    value: OccupancyByEachStaff[]
  ): string {
    return JSON.stringify(value);
  }
}
