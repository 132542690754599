import React from "react";
import { NerveTest } from "../type/NerveTest";

type NerveTestProps = {
  nerveTest: NerveTest | null;
};

const NerveTestAssessment = (props: NerveTestProps) => {
  return (
    <div className="">
      <h3 className="mb-4 font-bold text-tertiary-500">Nerve Test</h3>
      <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="border px-2 py-1 border-slate-300 text-left">
              Muscle Name
            </th>
            <th className="border px-2 py-1 border-slate-300 text-left">
              Grading
            </th>
          </tr>
        </thead>
        <tbody>
          {props.nerveTest &&
            props.nerveTest.nerveTestDetails?.length > 0 &&
            props.nerveTest.nerveTestDetails.map((e, index) =>
              e.name !== "" ? (
                <tr key={index}>
                  <td className="border px-2 py-1 text-left border-slate-300">
                    <p>{e.name}</p>
                  </td>
                  <td className="border px-2 py-1 text-left border-slate-300">
                    <p className="px-2 py-1 flex text-wrap">{e.observation}</p>
                  </td>
                </tr>
              ) : (
                <div></div>
              )
            )}
        </tbody>
      </table>
      <hr className=" my-12 border-tertiary-500"></hr>
    </div>
  );
};

export default NerveTestAssessment;
