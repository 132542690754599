import React from 'react';

export default function PatientExtras() {
  return (
    <div>
      <h2 className="text-xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
        Patient Extras
      </h2>
    </div>
  );
}
