import { Button, FabButton, OutlinedButton } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import { Divider } from '@components/divider';
import { paths } from '@routes/paths';
import { endpoints, replaceParams } from '@utils/axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Convert, Entity } from './types/EntityType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faBuilding,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import useAxios from '@routes/hooks/use-axios';

function EntityCard({ entity }: { entity: Entity }) {
  const naviagtor = useNavigate();

  const navigateViewEntity = (entityId: string, entityName: string) => {
    const paramsMap = new Map<string, string>([
      ['entityId', entityId],
      ['entityName', entityName],
    ]);
    const queryParams = new Map<string, string>([
      ['name', entityName]
    ])
    naviagtor(replaceParams(paths.entity.manage.route, paramsMap, queryParams));
  };

  return (
    <div className="flex flex-col justify-between rounded-2xl shadow-md bg-white overflow-hidden">
      <div className="flex flex-row items-center">
        {entity.logoUrl === '' || entity.logoUrl === null ? (
          <div className="ml-2 w-20 h-20 flex items-center justify-center bg-gray-300 rounded-lg p-3 border border-gray-300 mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="100%"
              viewBox="0 0 448 512"
              className="text-gray-500"
            >
              <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
            </svg>
          </div>
        ) : (
          <div className="ml-2 w-20 h-20 overflow-hidden rounded-lg">
            <img
              src={entity.logoUrl}
              alt="Profile"
              className="w-full h-full object-fill"
            />
          </div>
        )}

        <div className="flex flex-col justify-between p-4 leading-normal w-3/4">
          <span className="flex-initial text-md text-black font-bold truncate">
            {entity.name}
          </span>
          <hr className="mt-2 h-0.5 bg-white-300 opacity-100 dark:opacity-50" />
          <div className="flex flex-col mt-2">
            <span className="flex-initial text-black">
              {entity.phoneCode} {entity.phone}
            </span>
            <span className="flex-initial truncate text-secondary-400">
              {entity.email}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4">
        <div className="flex flex-row w-full justify-between">
          <OutlinedButton
            variant={ButtonVariant.TERTIARY}
            type="button"
            onClick={() => {
              navigateViewEntity(entity.id, entity.name);
            }}
            children={
              <div className="flex flex-row items-center">
                <FontAwesomeIcon
                  icon={faSliders}
                  className="h-5 w-5 mr-4 text-tertiary-500"
                  aria-hidden="true"
                />
                <span className="text-sm text-tertiary-600 font-semibold">
                  Manage
                </span>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}

function EntityList({
  entityList,
  searchQuery,
}: {
  entityList: Entity[];
  searchQuery: string;
}) {
  const filteredStaff = entityList.filter(
    (entity: { name: string; email: string; phone: number }) =>
      entity.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      entity.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      entity.phone.toString().toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const filteredCount = filteredStaff.length;
  const [listRange, setListRange] = React.useState(9);

  return (
    <>
      <ul className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4 bg-gray-50 -mx-8 p-8">
        {filteredStaff.slice(0, listRange).map((entity: Entity) => (
          <EntityCard key={entity.id} entity={entity} />
        ))}
      </ul>
      {listRange < filteredCount && (
        <div className="flex justify-center">
          <Button
            variant={ButtonVariant.TERTIARY}
            type="button"
            onClick={() => {
              setListRange(prevValue => prevValue + 9);
            }}
            children="Load More"
            className="m-5 px-2 py-1 lg:px-14 lg:py-2 "
          />
        </div>
      )}
    </>
  );
}

function SearchBar({
  searchQuery,
  onSearch,
}: {
  searchQuery: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <div>
      <input
        type="text"
        value={searchQuery}
        onChange={onSearch}
        placeholder="Search for Entity by entity name, email id, mobile number"
        className="w-full px-4 py-2 mb-4 text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500"
      />
    </div>
  );
}

export default function EntitiesIndexPage() {
  const [entities, setEntities] = React.useState<Entity[] | null>(null); // this is the list of entities [{}]
  const [searchQuery, setSearchQuery] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const axios = useAxios();

  const naviagtor = useNavigate();

  const navigateCreateEntity = () => {
    naviagtor(paths.entity.onboard.route);
  };

  // below is the api call to get the list of entities in a react hook
  useEffect(() => {
    const getEntities = async () => {
      try {
        const response = await axios.get(endpoints.entity.list);
        const data = response.data['data'];
        const convertedEntityList = data.map((entity: any) =>
          Convert.toEntity(JSON.stringify(entity)),
        );
        setEntities(convertedEntityList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching entity list:', error);
        setError(error.message);
        setLoading(false);
      }
    };
    getEntities();
  }, [axios]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value);

  return (
    <>
      {/* a fixed to top header with title, description and a button */}
      <div className="flex flex-col justify-start lg:flex-row lg:justify-between lg:items-center p-6 rounded-2xl shadow bg-gradient-to-r from-primary-100 to-primary-50">
        <div className="flex flex-row lg:flex-col">
          <div className="flex flex-col justify-start">
            <span className="text-2xl font-bold text-black">Entities</span>
            <span className="text-sm text-black">
              List of all the entities onboarded on the platform, you can create
              a new entity or view an existing entity.
            </span>
          </div>
        </div>
        <div className="flex flex-row mt-2 justify-start lg:flex-col lg:justify-normal lg:m-0">
          <Button
            variant={ButtonVariant.PRIMARY}
            type="button"
            onClick={navigateCreateEntity}
            children="Onboard Entity"
          />
        </div>
      </div>

      <Divider />

      <div className="relative mt-4 pb-5 sm:pb-0">
        <SearchBar searchQuery={searchQuery} onSearch={handleSearch} />

        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}

        {!loading && !error && entities && (
          <>
            <EntityList entityList={entities} searchQuery={searchQuery} />
            <FabButton
              variant={ButtonVariant.TERTIARY}
              className="m-5 px-2 py-1 lg:px-8 lg:py-4"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }}
              children={
                <div className="flex flex-row items-center">
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  <span className="ml-2 text-white">Go to top</span>
                </div>
              }
            />
          </>
        )}
      </div>
    </>
  );
}
