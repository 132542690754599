import Button, { ButtonVariant } from "@components/button/button";
import React, { Fragment } from "react";
import { EditorState, Editor, RichUtils, convertToRaw } from "draft-js";
import "draft-js/dist/Draft.css";
import draftToHtml from "draftjs-to-html";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import {
  faBold,
  faItalic,
  faUnderline,
  faListOl,
  faListUl,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  editorState: EditorState;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
}

const RichTextEditor: React.FC<IProps> = ({ setEditorState, editorState }) => {
  React.useEffect(() => {
    setEditorState(editorState);
  }, []);

  const onChange = (newEditorState: React.SetStateAction<EditorState>) => {
    setEditorState(newEditorState);
  };

  const handleKeyCommand = (command: string) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      onChange(newState);
      return "handled";
    }

    return "not-handled";
  };

  const onToggleInlineStyle = (style: string) => {
    onChange(RichUtils.toggleInlineStyle(editorState, style));
  };

  const onToggleBlockType = (blockType: string) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const onToggleUnderline = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  };

  const onBoldClick = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };

  const onItalicClick = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="border rounded h-20 p-1 px-2 flex flex-1 justify-between mb-2">
          <button onClick={() => onToggleBlockType("header-one")}>H1</button>
          <button onClick={() => onToggleBlockType("header-two")}>H2</button>
          <button onClick={() => onToggleBlockType("header-three")}>H3</button>
          <button onClick={() => onToggleBlockType("header-four")}>H4</button>
          <button onClick={() => onToggleBlockType("header-five")}>H5</button>
          <button onClick={() => onToggleBlockType("header-six")}>H6</button>
          <button onClick={() => onToggleBlockType("blockquote")}>
            Blockquote
          </button>
          <button onClick={() => onToggleBlockType("unordered-list-item")}>
            <FontAwesomeIcon icon={faListUl} />
          </button>
          <button onClick={() => onToggleBlockType("ordered-list-item")}>
            <FontAwesomeIcon icon={faListOl} />
          </button>
          <button onClick={onBoldClick}>
            <FontAwesomeIcon icon={faBold} />
          </button>
          <button onClick={onItalicClick}>
            {" "}
            <FontAwesomeIcon icon={faItalic} />
          </button>
          <button onClick={onToggleUnderline}>
            {" "}
            <FontAwesomeIcon icon={faUnderline} />
          </button>
        </div>
        <div className="border rounded p-4 h-40 overflow-y-auto w-full">
          <Editor
            editorState={editorState}
            onChange={setEditorState}
            handleKeyCommand={handleKeyCommand}
            placeholder="Please enter content here..."
          />
        </div>
      </div>
    </>
  );
};

export default RichTextEditor;
