import React from "react";
import { Balance } from "../type/Balance";

type BalanceProps = {
  balance: Balance | null;
};

const BalanceAssessment = (props: BalanceProps) => {
  return (
    <div className="flex flex-col w-full h-full ">
      {/* Conditional rendering of header */}
      {props.balance?.bergsBalanceScaleScore === "" &&
      props.balance?.tugTestValue === "" &&
      props.balance?.tinettiPerformanceOrientedMobility === "" &&
      props.balance?.dynamicGaitIndex === "" &&
      props.balance?.singleLegStance === "" &&
      props.balance?.tandemLegStance === "" &&
      props.balance?.rhombergTest === "" &&
      props.balance?.functionalReachTest === "" ? null : (
        <h3 className="mb-4 font-bold text-tertiary-500">Balance Assessment</h3>
      )}

      <div className="flex flex-col items-stretch">
        {/* Conditional rendering of Static Balance */}
        {props.balance?.singleLegStance === "" &&
        props.balance?.tandemLegStance === "" &&
        props.balance?.rhombergTest === "" &&
        props.balance?.functionalReachTest === "" ? null : (
          <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-300 text-white">
            Static Balance
          </div>
        )}
        {/* Single Leg Stance */}
        {props.balance?.singleLegStance === "" ? (
          <div></div>
        ) : (
          <div className="mt-4">
            <div className="mt-2">
              <div className="flex flex-row justify-between w-full bg-tertiary-100 px-2 py-1 font-medium">
                Single Leg Stance
              </div>
              <p className="px-2 py-1 flex whitespace-normal text-lg">
                {props.balance?.singleLegStance}
              </p>
            </div>
          </div>
        )}
        {/* Tandem Leg Stance */}
        {props.balance?.tandemLegStance === "" ? null : (
          <div className="mt-4">
            <div className="mt-2">
              <div className="flex flex-row justify-between w-full bg-tertiary-100 px-2 py-1 font-medium">
                Tandem Stance
              </div>
              <p className="px-2 py-1 flex whitespace-normal text-lg">
                {props.balance?.tandemLegStance}
              </p>
            </div>
          </div>
        )}
        {/* Rhomberg Test */}
        {props.balance?.rhombergTest === "" ? null : (
          <div className="mt-4">
            <div className="mt-2">
              <div className="flex flex-row justify-between w-full bg-tertiary-100 px-2 py-1 font-medium">
                Rhomberg Test
              </div>
              <p className="px-2 py-1 flex whitespace-normal text-lg">
                {props.balance?.rhombergTest}
              </p>
            </div>
          </div>
        )}
        {/* Functional Reach Test */}
        {props.balance?.functionalReachTest === "" ? null : (
          <div className="mt-4">
            <div className="mt-2">
              <div className="flex flex-row justify-between w-full bg-tertiary-100 px-2 py-1 font-medium">
                Functional Reach Test
              </div>
              <p className="px-2 py-1 flex whitespace-normal text-lg">
                {props.balance?.functionalReachTest}
              </p>
            </div>
          </div>
        )}
        {/* Conditional rendering of Dynamic Balance */}
        {props.balance?.bergsBalanceScaleScore === "" &&
        props.balance?.tugTestValue === "" &&
        props.balance?.tinettiPerformanceOrientedMobility === "" &&
        props.balance?.dynamicGaitIndex === "" ? null : (
          <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-300 text-white">
            Dynamic Balance
          </div>
        )}
        {/* Bergs Balance Scale Score */}
        {props.balance?.bergsBalanceScaleScore === "" ? null : (
          <div className="mt-4">
            <div className="mt-2">
              <div className="flex flex-row justify-between w-full bg-tertiary-100 px-2 py-1 font-medium">
                Bergs Balance Scale Score
              </div>
              <p className="px-2 py-1 flex whitespace-normal text-lg">
                {props.balance?.bergsBalanceScaleScore}
              </p>
            </div>
          </div>
        )}
        {/* Tug Test Value */}
        {props.balance?.tugTestValue === "" ? null : (
          <div className="mt-4">
            <div className="mt-2">
              <div className="flex flex-row justify-between w-full bg-tertiary-100 px-2 py-1 font-medium">
                Tug Test Value
              </div>
              <p className="px-2 py-1 flex whitespace-normal text-lg">
                {props.balance?.tugTestValue}
              </p>
            </div>
          </div>
        )}
        {/* Tinetti Performance Oriented Mobility */}
        {props.balance?.tinettiPerformanceOrientedMobility === "" ? null : (
          <div className="mt-4">
            <div className="mt-2">
              <div className="flex flex-row justify-between w-full bg-tertiary-100 px-2 py-1 font-medium">
                Tinetti Performance Oriented Mobility
              </div>
              <p className="px-2 py-1 flex whitespace-normal text-lg">
                {props.balance?.tinettiPerformanceOrientedMobility}
              </p>
            </div>
          </div>
        )}
        {/* Dynamic Gait Index */}
        {props.balance?.dynamicGaitIndex === "" ? null : (
          <div className="mt-4">
            <div className="mt-2">
              <div className="flex flex-row justify-between w-full bg-tertiary-100 px-2 py-1 font-medium">
                Dynamic Gait Index
              </div>
              <p className="px-2 py-1 flex whitespace-normal text-lg">
                {props.balance?.dynamicGaitIndex}
              </p>
            </div>
          </div>
        )}
      </div>
      <hr className=" my-12 border-tertiary-500"></hr>
    </div>
  );
};

export default BalanceAssessment;
