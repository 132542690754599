import { endpoints, replaceParams } from "@utils/axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Appointments } from "../types/Appointments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Divider from "@components/divider/divider";

import useAxios from "@routes/hooks/use-axios";
import ViewPaymentDetails from "./ViewPaymentDetails";
import { CancelAppointmentDialog } from "./CancelAppointmentDialog";
import AppointmentDetails from "./AppointmentDetails";
import BillingAndPaymentDetails from "../../payments/BillingAndPaymentDetails";
import { OutlinedButton } from "@components/button";
import Button, { ButtonVariant } from "@components/button/button";
import {
  ConvertSessionCredit,
  SessionCredits,
} from "@pages/manage-patient-new/types/SessionCredits";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "lucide-react";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTrigger,
} from "@components/ui/sheet";
import PatientRecord from "@pages/manage-patient-new-v2/view/PatientRecord";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  getUIVersion,
  getUserPersistedOnLocalStorage,
} from "@authentication/context/jwt/utils";
import Modal from "@components/modal";
import RightOffcanvas from "@components/right-offcanvas/right-offcanvas";

export default function AppointmentDashboardCards(props: {
  appointmentStatus: string;
  buttonName: string;
  appointmentList: Appointments[];
  callback: () => void;
  dateSwitcher: string;
}) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [addPaymentDetails, setAddPaymentDetails] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [showPaymentDetails, setShowAddPaymentDetails] = useState(false);
  const [showAppointmentDetails, setShowAppointmentDetails] = useState(false);
  const [isRightOffcanvasPatient, setIsRightOffcanvasPatient] = useState(false);

  const [selectedAppointmentId, setSelectedAppointmentId] = useState("");
  const [appointmentDetails, setAppointmentDetails] =
    useState<Appointments | null>(null);

  const axios = useAxios();

  const navigate = useNavigate();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredAppointments = props.appointmentList?.filter((appointment) => {
    return appointment.clientName
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
  });

  const checkStatus = async (
    appStatus: string,
    appId: string,
    appointmentDetails: Appointments
  ) => {
    type Status =
      | "Scheduled"
      | "Waiting"
      | "Ongoing"
      | "Cancelled"
      | "Completed"
      | "Complete";
    const statusTransitions: Record<Status, Status> = {
      Scheduled: "Waiting",
      Waiting: "Ongoing",
      Ongoing: "Completed",
      Cancelled: "Cancelled",
      Completed: "Complete",
      Complete: "Complete",
    };

    if (statusTransitions.hasOwnProperty(appStatus)) {
      const nextStatus = statusTransitions[appStatus as Status];

      if (nextStatus === "Complete") {
        setAppointmentDetails(appointmentDetails);
        setSelectedAppointmentId(appId);
        setShowAddPaymentDetails(true);
      } else if (nextStatus === "Cancelled") {
        setSelectedAppointmentId(appId);
        setCancelDialog(true);
      } else {
        handleStatusChange(appId, nextStatus);
      }
    }
  };

  const handleStatusChange = async (
    appointmentId: string,
    appointmentStatus: string
  ) => {
    try {
      const paramsMap = new Map<string, string>([
        ["id", appointmentId],
        ["status", appointmentStatus],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.assistantDashboard.updateAppointmentStatus,
        paramsMap
      );
      await axios.put(filteredEndpoint);
      props.callback();
    } catch (error) {
      console.error(error);
    }
  };

  const confirmPaymentAndChangeStatus = async () => {
    try {
      setAddPaymentDetails(false);
      props.callback();
      // handleStatusChange(selectedAppointmentId, 'Completed');
    } catch (error) {
      console.error(error);
    }
  };

  const handleJoinNowButton = (meetLink: string) => {
    window.open(meetLink, "_blank");
  };

  const navigateToPatientScreen = (patientId: string) => {
    navigate(`/patient/manage/${patientId}`);
  };

  const uiVersion = getUIVersion();

  return (
    <div className="rounded-md bg-gray-100 shadow-md">
      <div className="">
        <div className="flex items-center justify-between rounded-tl-md rounded-tr-md p-2 bg-tertiary-500">
          <span className="text-base text-white font-bold">
            {props.appointmentStatus} ({props.appointmentList.length})
          </span>
        </div>

        <div className="mt-2 p-4">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="flex rounded-md shadow-sm">
            <div className="relative flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                {/* Heroicon name: search */}
                <FontAwesomeIcon
                  icon={["fas", "search"]}
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                name="search"
                id="search"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded sm:text-sm border-gray-300"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2 overflow-auto  min-h-[300px] 2xl:min-h-[600px] p-2">
        {filteredAppointments.length != 0 ? (
          <></>
        ) : (
          <div className="flex flex-col justify-center items-center y-2">
            <div className="flex-shrink">
              <span className="font-bold text-tertiary-500 text-sm">
                No Patient is {props.appointmentStatus}
              </span>
            </div>
          </div>
        )}
        {filteredAppointments.map((appointment: Appointments) => (
          <div className="flex flex-col justify-between rounded-2xl shadow-md bg-white mb-2 mr-1 hover:cursor-pointer">
            {/* ...content of the staff card... */}
            <div
              className="flex flex-row rounded-xl bg-white-300 items-center justify-between p-4 pb-0 "
              onClick={() => {
                setSelectedAppointmentId(appointment.id);
                setShowAppointmentDetails(true);
              }}
            >
              <div className="flex flex-col w-3/4">
                <span className="flex-initial text-md text-primary-800 font-bold truncate">
                  {appointment.clientName}
                </span>
                <span className="flex-initial text-sm text-gray-800">
                  <span className="font-semibold">{appointment.startTime}</span>
                </span>
                <span className="flex-initial text-xs text-gray-800 ">
                  {appointment.appointmentType},{" "}
                  {appointment.modeOfConsultation}
                </span>
                <hr className="mt-2 h-0.5 bg-white-300 opacity-100 dark:opacity-50" />
              </div>
              <div className="p-3 bg-gray-200 rounded-xl text-xs">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 text-primary-500"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <div
              className="flex flex-row items-center justify-between pb-0"
              onClick={() => {
                setSelectedAppointmentId(appointment.id);
                setShowAppointmentDetails(true);
              }}
            >
              <div className="flex flex-col items-start px-4 mt-2 w-full">
                <span className="flex-initial  text-xs ">Appointment With</span>
                <span className="flex-initial truncate text-sm font-semibold w-full">
                  {appointment.entityUserName}
                </span>
              </div>
              {props.appointmentStatus === "Waiting" && (
                <div className="flex flex-col items-center pr-4 font-semibold">
                  <span className="text-xs">Waiting</span>
                  <WaitingTimeCounter t={appointment.checkInTime} />
                </div>
              )}
              {props.appointmentStatus === "Ongoing" && (
                <div className="flex flex-col items-center pr-4 font-semibold">
                  <span className="text-xs text-center">In Session</span>
                  <WaitingTimeCounter t={appointment.sessionStartTime} />
                </div>
              )}
            </div>
            <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100  p-2 mt-2  justify-between">
              <div className="flex flex-row w-full justify-between gap-2">
                {props.appointmentStatus === "Ongoing" && (
                  <>
                    {uiVersion === "v1" ? (
                      <>
                        <OutlinedButton
                          variant={ButtonVariant.SECONDARY}
                          type="button"
                          onClick={() => {
                            navigateToPatientScreen(appointment.clientId);
                          }}
                          children="Manage Patient"
                        />
                      </>
                    ) : (
                      <>
                        <OutlinedButton
                          variant={ButtonVariant.SECONDARY}
                          type="button"
                          onClick={() => {
                            setIsRightOffcanvasPatient(true);
                          }}
                          children="Patient Records"
                        />
                        {isRightOffcanvasPatient && (
                          <RightOffcanvas
                            isOpen={isRightOffcanvasPatient}
                            onClose={() => {
                              setIsRightOffcanvasPatient(false);
                            }}
                            sizeClasses="w-full"
                          >
                            <div className="flex flex-col w-full">
                              <div className="flex justify-end items-end w-full mb-2">
                                <OutlinedButton
                                  variant={ButtonVariant.SECONDARY}
                                  type="button"
                                  onClick={() => {
                                    setIsRightOffcanvasPatient(false);
                                  }}
                                  children="Close"
                                />
                              </div>
                              <div className="">
                                <PatientRecord
                                  inputPatientId={appointment.clientId}
                                  defaultTab="pe"
                                />
                              </div>
                            </div>
                          </RightOffcanvas>
                        )}
                      </>
                      /*                       <Sheet>
                                              <SheetTrigger>
                                                <div>
                                                  <OutlinedButton
                                                    variant={ButtonVariant.SECONDARY}
                                                    type="button"
                                                    onClick={() => {}}
                                                    children="Patient Records"
                                                  />
                                                </div>
                                              </SheetTrigger>
                                              <SheetContent className="w-full md:w-3/4 justify-end ">
                                                <div className="flex flex-col">
                                                  <div className="flex justify-end items-end w-full mb-2">
                                                    <SheetClose className="border-2 rounded-md p-1 bg-primary-200 font-medium">
                                                      Close
                                                    </SheetClose>
                                                  </div>
                                                  <div className="">
                                                    <PatientRecord
                                                      inputPatientId={appointment.clientId}
                                                      defaultTab="pe"
                                                    />
                                                  </div>
                                                </div>
                                              </SheetContent>
                                              </Sheet> */
                    )}
                  </>
                )}
                {props.appointmentStatus === "Scheduled" ? (
                  <div>
                    <OutlinedButton
                      variant={ButtonVariant.SECONDARY}
                      type="button"
                      onClick={() =>
                        checkStatus("Cancelled", appointment.id, appointment)
                      }
                      children="Cancel"
                    />
                  </div>
                ) : (
                  <></>
                )}
                {/* If status is completed then show primary - payment details button only if payment status is paid  */}
                {props.appointmentStatus !== "Completed" && (
                  <div>
                    <OutlinedButton
                      variant={ButtonVariant.TERTIARY}
                      type="button"
                      isDisabled={
                        !(
                          props.dateSwitcher ===
                          moment(new Date()).format("DD-MM-YYYY")
                        )
                      }
                      onClick={() =>
                        checkStatus(
                          props.appointmentStatus,
                          appointment.id,
                          appointment
                        )
                      }
                      children={props.buttonName}
                    />
                  </div>
                )}

                {props.appointmentStatus !== "Completed" &&
                  appointment.meetLink && (
                    <div>
                      <button
                        className="self-end bg-secondary-300 hover:bg-secondary-500 text-white font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline mr-3"
                        onClick={() =>
                          handleJoinNowButton(appointment.meetLink)
                        }
                      >
                        Join
                      </button>
                    </div>
                  )}

                {props.appointmentStatus === "Completed" && (
                  <>
                    {appointment.paymentStatus !== "" ? (
                      <div>
                        <OutlinedButton
                          variant={ButtonVariant.SECONDARY}
                          className="self-end"
                          onClick={() => {
                            setAppointmentDetails(appointment);
                            setAddPaymentDetails(true);
                          }}
                        >
                          Bill Paid
                        </OutlinedButton>
                      </div>
                    ) : (
                      <>
                        {appointment.billAmount > 0 ? (
                          <div>
                            <OutlinedButton
                              variant={ButtonVariant.SECONDARY}
                              className="self-end"
                              onClick={() => {
                                setAppointmentDetails(appointment);
                                setAddPaymentDetails(true);
                              }}
                            >
                              View Bill Details
                            </OutlinedButton>
                          </div>
                        ) : (
                          <div>
                            <Button
                              variant={ButtonVariant.PRIMARY}
                              className="self-end"
                              onClick={() => {
                                setAppointmentDetails(appointment);
                                setAddPaymentDetails(true);
                              }}
                            >
                              Add Bill Details
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {addPaymentDetails && (
        <BillingAndPaymentDetails
          appointmentDetails={appointmentDetails!}
          onClose={() => setAddPaymentDetails(false)}
          onConfirm={confirmPaymentAndChangeStatus}
        />
      )}
      {showPaymentDetails && (
        <ViewPaymentDetails
          onClose={() => setShowAddPaymentDetails(false)}
          onConfirm={confirmPaymentAndChangeStatus}
          appointmentId={selectedAppointmentId}
          appointmentDetails={appointmentDetails!}
          setAddPaymentDetails={setAddPaymentDetails}
        />
      )}
      {cancelDialog && (
        <CancelAppointmentDialog
          onClose={() => setCancelDialog(false)}
          appId={selectedAppointmentId}
          callback={props.callback}
        />
      )}
      {showAppointmentDetails && (
        <AppointmentDetails
          onClose={() => setShowAppointmentDetails(false)}
          appId={selectedAppointmentId}
        />
      )}
    </div>
  );
}
function WaitingTimeCounter(props: { t: Date }) {
  const [waitingTime, setWaitingTime] = useState<string>("");

  useEffect(() => {
    const updateWaitingTime = () => {
      const duration = moment.duration(moment().diff(moment(props.t)));
      const hours = duration.hours();
      const minutes = duration.minutes();

      let timeString = "";
      if (hours > 0) {
        timeString += `${hours}h `;
      }
      timeString += `${minutes}m`;

      setWaitingTime(timeString);
    };

    updateWaitingTime();
    const intervalId = setInterval(updateWaitingTime, 60000);

    return () => clearInterval(intervalId);
  }, [props.t]);

  return <span className="text-xs">{waitingTime}</span>;
}
