import React from "react";
import { MuscleToneAssessment } from "../type/MuscleTone";

type MuscletoneProps = {
  muscleTone: MuscleToneAssessment | null;
};

const MuscletoneAssessment = (props: MuscletoneProps) => {
  return (
    <>
      {props.muscleTone === null ? (
        <div></div>
      ) : (
        <div className="flex flex-col w-full h-full ">
          <h3 className="mb-4 font-bold text-tertiary-500">Muscle Tone</h3>

          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Hip
                </div>
                <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Hip-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.hipFlexorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.hipFlexorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Hip-Extensors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.hipExtensorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.hipExtensorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Hip-Abductors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.hipAbductorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.hipAbductorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Hip-Adductors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.hipAdductorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.hipAdductorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Hip-Lateral Rot.
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.hipLateralRotationLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.hipLateralRotationRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Hip-Medial Rot.
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.hipLateralRotationRight}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.hipMedialRotationRight}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Shoulder
                </div>
                <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Shoulder-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderFlexorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderFlexorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Shoulder-Extensors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderExtensorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderExtensorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Shoulder-Abductors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderAbductorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderAbductorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Shoulder-Adductors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderAdductorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderAdductorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Shoulder-Lateral Rot.
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderLateralRotationLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderLateralRotationRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Shoulder-Medial Rot.
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderMedialRotationLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderMedialRotationRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Shoulder-Elevators
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderElevatorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderElevatorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Shoulder-Depressors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderDepressorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderDepressorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Shoulder-Antepulsors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderAntepulsorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderAntepulsorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Shoulder-Retropulsors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderRetropulsorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.shoulderRetropulsorsRight}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Knee
                </div>
                <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Knee-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.kneeFlexorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.kneeFlexorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Knee-Extensors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.kneeExtensorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.kneeExtensorsRight}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Elbow
                </div>
                <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Elbow-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.elbowFlexorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.elbowFlexorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300">
                        Elbow-Extensors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.elbowExtensorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.elbowExtensorsRight}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Ankle
                </div>
                <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Ankle-Dorsi Flex.
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.ankleDorsiFlexorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.ankleDorsiFlexorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Ankle-Plantar Flex.
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.anklePlantarFlexorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.anklePlantarFlexorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Ankle-Inversors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.ankleInversorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.ankleInversorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Ankle-Eversors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.ankleEversorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.ankleEversorsRight}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Fore-arm
                </div>
                <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Forearm-Supinators
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.forearmSupinatorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.forearmSupinatorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Forearm-Pronators
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.forearmPronatorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.forearmPronatorsRight}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Foot
                </div>
                <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Foot-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.footFlexorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.footFlexorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Foot-Extensors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.footExtensorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.footExtensorsRight}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Wrist
                </div>
                <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Wrist-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.wristFlexorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.wristFlexorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Wrist-Extensors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.wristExtensorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.wristExtensorsRight}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Trunk
                </div>
                <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Trunk-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.trunkFlexorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.trunkFlexorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Trunk-Extensor
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.trunkExtensorLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.trunkExtensorRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Trunk-R. Bending
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.trunkRBendingLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.trunkRBendingRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Trunk-L. Bending
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.trunkLBendingLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.trunkLBendingRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Trunk-R. Rotation
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.trunkRRotationLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.trunkRRotationRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Trunk-L. Rotation
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.trunkLRotationLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.trunkLRotationRight}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col items-stretch">
                <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                  Fingers
                </div>
                <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Name
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Left{" "}
                      </th>
                      <th className="border px-2 py-1 border-slate-300 text-center">
                        Right{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Fingers-Flexors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.fingersFlexorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.fingersFlexorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Fingers-Extensors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.fingersExtensorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.fingersExtensorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Fingers-Abductors
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.fingersAbductorsLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.fingersAbductorsRight}</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Fingers-Opposition
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.fingersOppositionLeft}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.muscleTone.fingersOppositionRight}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <hr className=" my-12 border-tertiary-500"></hr>
        </div>
      )}
    </>
  );
};

export default MuscletoneAssessment;
