// To parse this data:
//
//   import { Convert, PaymentDetails } from "./file";
//
//   const paymentDetails = Convert.toPaymentDetails(json);

export interface PaymentDetails {
  id: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  entityUserId: string;
  appointmentId: string;
  entityUserName: string;
  clientName: string;
  amount: number;
  paymentStatus: string;
  paymentType: string;
  notes: string;
  date: Date;
  createdBy: string;
  createdDate: string;
  billamount: string;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toPaymentDetails(json: string): PaymentDetails {
    return JSON.parse(json);
  }

  public static paymentDetailsToJson(value: PaymentDetails): string {
    return JSON.stringify(value);
  }
}
