// To parse this data:
//
//   import { Convert, LimbGirth } from "./file";
//
//   const limbGirth = Convert.toLimbGirth(json);

export type StructuralDeformitiesType = {
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  observations?: string;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toStructuralDeformitiesType(
    json: string,
  ): StructuralDeformitiesType {
    return JSON.parse(json);
  }

  public static structuralDeformitiesTypeToJson(
    value: StructuralDeformitiesType,
  ): string {
    return JSON.stringify(value);
  }
}
