import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { paths } from "@routes/paths";
import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";
import moment from "moment";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

import { Grid } from "gridjs-react";
import Button from "@components/button/button";
import { ButtonVariant } from "@components/button/button";

import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";

import { ValidationError } from "@components/hook-form";
import { Convert } from "./types/Vertigo";

import * as z from "zod";

export const VertigoSchema = z.object({
  id: z.union([z.null(), z.string()]).optional(),
  masterAssessmentId: z.union([z.null(), z.string()]).optional(),
  clientId: z.union([z.null(), z.string()]).optional(),
  entityId: z.union([z.null(), z.string()]).optional(),
  entityBranchId: z.union([z.null(), z.string()]).optional(),
  isSmoothPursuitChk: z.union([z.boolean(), z.null()]).optional(),
  smoothPursuitObservation: z.union([z.null(), z.string()]).optional(),
  isSaccadesChk: z.union([z.boolean(), z.null()]).optional(),
  saccadesObservation: z.union([z.null(), z.string()]).optional(),
  isFukudaStepTestChk: z.union([z.boolean(), z.null()]).optional(),
  fukudaStepTestObservation: z.union([z.null(), z.string()]).optional(),
  isMigraineChk: z.union([z.boolean(), z.null()]).optional(),
  migraineObservation: z.union([z.null(), z.string()]).optional(),
  isNeckTightnessChk: z.union([z.boolean(), z.null()]).optional(),
  neckTightnessObservation: z.union([z.null(), z.string()]).optional(),
  isAnxietyChk: z.union([z.boolean(), z.null()]).optional(),
  anxietyObservation: z.union([z.null(), z.string()]).optional(),
  isSleepChk: z.union([z.boolean(), z.null()]).optional(),
  sleepObservation: z.union([z.null(), z.string()]).optional(),
  isApetiteChk: z.union([z.boolean(), z.null()]).optional(),
  apetiteObservation: z.union([z.null(), z.string()]).optional(),
  isNauseaChk: z.union([z.boolean(), z.null()]).optional(),
  nauseaObservation: z.union([z.null(), z.string()]).optional(),
  isBowelBladderChk: z.union([z.boolean(), z.null()]).optional(),
  bowelBladderObservation: z.union([z.null(), z.string()]).optional(),
  isAcidityChk: z.union([z.boolean(), z.null()]).optional(),
  acidityObservation: z.union([z.null(), z.string()]).optional(),
  isThyroidVitLevelChk: z.union([z.boolean(), z.null()]).optional(),
  thyroidVitLevelObservation: z.union([z.null(), z.string()]).optional(),
  isHeadHeavinessChk: z.union([z.boolean(), z.null()]).optional(),
  headHeavinessObservation: z.union([z.null(), z.string()]).optional(),
  isEyeHeavinessChk: z.union([z.boolean(), z.null()]).optional(),
  eyeHeavinessObservation: z.union([z.null(), z.string()]).optional(),
  isTongueHeavinessChk: z.union([z.boolean(), z.null()]).optional(),
  tongueHeavinessObservation: z.union([z.null(), z.string()]).optional(),
  isSlurringOfSpeechChk: z.union([z.boolean(), z.null()]).optional(),
  slurringOfSpeechObservation: z.union([z.null(), z.string()]).optional(),
  isWeaknessChk: z.union([z.boolean(), z.null()]).optional(),
  weaknessObservation: z.union([z.null(), z.string()]).optional(),
  isFatigueChk: z.union([z.boolean(), z.null()]).optional(),
  fatigueObservation: z.union([z.null(), z.string()]).optional(),
  isHydrationChk: z.union([z.boolean(), z.null()]).optional(),
  hydrationObservation: z.union([z.null(), z.string()]).optional(),
  isStressChk: z.union([z.boolean(), z.null()]).optional(),
  stressObservation: z.union([z.null(), z.string()]).optional(),
  isFingureNoseChk: z.union([z.boolean(), z.null()]).optional(),
  fingureNoseObservation: z.union([z.null(), z.string()]).optional(),
  isDisdiakokinesisChk: z.union([z.boolean(), z.null()]).optional(),
  disdiakokinesisObservation: z.union([z.null(), z.string()]).optional(),
  isHeelShinChk: z.union([z.boolean(), z.null()]).optional(),
  heelShinObservation: z.union([z.null(), z.string()]).optional(),
  isTinnitusChk: z.union([z.boolean(), z.null()]).optional(),
  isTinnitusLeft: z.union([z.boolean(), z.null()]).optional(),
  isTinnitusRight: z.union([z.boolean(), z.null()]).optional(),
  tinnitusObservation: z.union([z.null(), z.string()]).optional(),
  isSNHLChk: z.union([z.boolean(), z.null()]).optional(),
  isSNHLLeft: z.union([z.boolean(), z.null()]).optional(),
  isSNHLRight: z.union([z.boolean(), z.null()]).optional(),
  sNHLObservation: z.union([z.null(), z.string()]).optional(),

  isOnPillowEyesOpenedChk: z.union([z.boolean(), z.null()]).optional(),
  isOnPillowEyesClosedChk: z.union([z.boolean(), z.null()]).optional(),

  onPillowEyesOpenObservation: z.union([z.null(), z.string()]).optional(),
  onPillowEyesClosedObservation: z.union([z.null(), z.string()]).optional(),

  isOnGroundEyesClosedChk: z.union([z.boolean(), z.null()]).optional(),
  isOnGroundEyesOpenedChk: z.union([z.boolean(), z.null()]).optional(),

  onGroundEyesOpenObservation: z.union([z.null(), z.string()]).optional(),
  onGroundEyesClosedObservation: z.union([z.null(), z.string()]).optional(),
  isPositionalTestACChk: z.union([z.boolean(), z.null()]).optional(),
  isPositionalTestACLeft: z.union([z.boolean(), z.null()]).optional(),
  isPositionalTestACRight: z.union([z.boolean(), z.null()]).optional(),
  positionalTestACObservation: z.union([z.null(), z.string()]).optional(),
  isPositionalTestPCChk: z.union([z.boolean(), z.null()]).optional(),
  isPositionalTestPCLeft: z.union([z.boolean(), z.null()]).optional(),
  isPositionalTestPCRight: z.union([z.boolean(), z.null()]).optional(),
  positionalTestPCObservation: z.union([z.null(), z.string()]).optional(),
  isPositionalTestHCChk: z.union([z.boolean(), z.null()]).optional(),
  isPositionalTestHCLeft: z.union([z.boolean(), z.null()]).optional(),
  isPositionalTestHCRight: z.union([z.boolean(), z.null()]).optional(),
  positionalTestHCObservation: z.union([z.null(), z.string()]).optional(),
});

export type VertigoSchemaType = z.infer<typeof VertigoSchema>;

type VertigoAsssessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function VertigoAssessmentComponent(
  props: VertigoAsssessmentProps
) {
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const userData = getUserPersistedOnLocalStorage();

  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm<VertigoSchemaType>({
    resolver: zodResolver(VertigoSchema),
    defaultValues: {
      masterAssessmentId: props.masterAssessmentId,
      entityId: userData?.entity_id,
      clientId: props.patientId,
      entityBranchId: userData?.branch_id,
    },
  });

  React.useEffect(() => {
    const initializeAssessment = async () => {
      if (props.assessmentId !== "000000000000000000000000") {
        try {
          const paramsMap = new Map<string, string>([
            ["mid", props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getVertigoAssessment,
            paramsMap
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data["data"];
          const convertedAssessment = Convert.toVertigo(JSON.stringify(data));

          reset(convertedAssessment);
          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          console.error("Error fetching Vertigo assessment:", error);

          setLoading(false);
        }
      }
    };
    initializeAssessment();
  }, []);

  const navigateToMasterAssessment = (masterAssessmentId: string) => {
    const paramsMap = new Map<string, string>([
      ["patientId", patientId.patientId],
      ["masterAssessmentId", masterAssessmentId],
    ]);
    navigate(
      `${replaceParams(paths.patient.masterAssessment.route, paramsMap)}`
    );
  };

  const onSubmit: SubmitHandler<VertigoSchemaType> = async (data: any) => {
    if (props.assessmentId !== "000000000000000000000000") {
      try {
        VertigoSchema.parse(data);

        const paramsMap = new Map<string, string>([
          ["id", props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateVertigoAssessment,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Vertigo Assessment updated successfully"
              : response.data.message
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    } else {
      try {
        VertigoSchema.parse(data);

        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createVertigoAssessment,
          paramsMap
        );
        const response = await axios.post(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Vertigo Assessment created successfully"
              : response.data.message
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Vertigo Assessment
        </h3>
      </div>

      <hr className="w-full" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-stretch">
          <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
            Clinical
          </div>
          <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="border px-2 py-1 border-slate-300 "
                  style={{ width: "30%" }}
                >
                  Name
                </th>
                <th
                  className="border px-2 py-1 border-slate-300 "
                  style={{ width: "20%" }}
                >
                  Select (if applicable)
                </th>
                <th
                  className="border px-2 py-1 border-slate-300"
                  style={{ width: "50%" }}
                >
                  Observation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Smooth Pursuit
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isSmoothPursuitChk")}
                    name={`isSmoothPursuitChk`}
                    type="checkbox"
                    // checked={methods.watch('isSmoothPursuitChk')}
                    disabled={isDisabled}
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("smoothPursuitObservation", "");
                      }
                    }}
                  />
                </td>

                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("smoothPursuitObservation")}
                    key={`smoothPursuitObservation`}
                    name={`smoothPursuitObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("smoothPursuitObservation");

                      if (textValue !== "") {
                        setValue("isSmoothPursuitChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Saccades</td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isSaccadesChk")}
                    name={`isSaccadesChk`}
                    type="checkbox"
                    // checked={methods.watch('isSaccadesChk')}
                    disabled={isDisabled}
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("saccadesObservation", "");
                      }
                    }}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("saccadesObservation")}
                    key={`saccadesObservation`}
                    name={`saccadesObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("saccadesObservation");

                      if (textValue !== "") {
                        setValue("isSaccadesChk", true);
                      }
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Fukuda Step Test
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isFukudaStepTestChk")}
                    name={`isFukudaStepTestChk`}
                    type="checkbox"
                    // checked={methods.watch('isFukudaStepTestChk')}
                    disabled={isDisabled}
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("fukudaStepTestObservation", "");
                      }
                    }}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("fukudaStepTestObservation")}
                    key={`fukudaStepTestObservation`}
                    name={`fukudaStepTestObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("fukudaStepTestObservation");

                      if (textValue !== "") {
                        setValue("isFukudaStepTestChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Migraine</td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isMigraineChk")}
                    name={`isMigraineChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("migraineObservation", "");
                      }
                    }}
                    // checked={methods.watch('isMigraineChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("migraineObservation")}
                    key={`migraineObservation`}
                    name={`migraineObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("migraineObservation");

                      if (textValue !== "") {
                        setValue("isMigraineChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Neck Tightness
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isNeckTightnessChk")}
                    name={`isNeckTightnessChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("neckTightnessObservation", "");
                      }
                    }}
                    // checked={methods.watch('isNeckTightnessChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("neckTightnessObservation")}
                    key={`neckTightnessObservation`}
                    name={`neckTightnessObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("neckTightnessObservation");

                      if (textValue !== "") {
                        setValue("isNeckTightnessChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Anxiety</td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isAnxietyChk")}
                    name={`isAnxietyChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("anxietyObservation", "");
                      }
                    }}
                    // checked={methods.watch('isAnxietyChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("anxietyObservation")}
                    key={`anxietyObservation`}
                    name={`anxietyObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("anxietyObservation");

                      if (textValue !== "") {
                        setValue("isAnxietyChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Sleep</td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isSleepChk")}
                    name={`isSleepChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("sleepObservation", "");
                      }
                    }}
                    // checked={methods.watch('isSleepChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("sleepObservation")}
                    key={`sleepObservation`}
                    name={`sleepObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("sleepObservation");

                      if (textValue !== "") {
                        setValue("isSleepChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Apetite</td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isApetiteChk")}
                    name={`isApetiteChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("apetiteObservation", "");
                      }
                    }}
                    // checked={methods.watch('isApetiteChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("apetiteObservation")}
                    key={`apetiteObservation`}
                    name={`apetiteObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("apetiteObservation");

                      if (textValue !== "") {
                        setValue("isApetiteChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Nausea/Vomitting
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isNauseaChk")}
                    name={`isNauseaChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("nauseaObservation", "");
                      }
                    }}
                    // checked={methods.watch('isNauseaChk')}
                    disabled={isDisabled}
                  />
                </td>

                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("nauseaObservation")}
                    key={`nauseaObservation`}
                    name={`nauseaObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("nauseaObservation");

                      if (textValue !== "") {
                        setValue("isNauseaChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Bowel Bladder
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isBowelBladderChk")}
                    name={`isBowelBladderChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("bowelBladderObservation", "");
                      }
                    }}
                    // checked={methods.watch('isBowelBladderChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("bowelBladderObservation")}
                    key={`bowelBladderObservation`}
                    name={`bowelBladderObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("bowelBladderObservation");

                      if (textValue !== "") {
                        setValue("isBowelBladderChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Acidity</td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isAcidityChk")}
                    name={`isAcidityChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("acidityObservation", "");
                      }
                    }}
                    // checked={methods.watch('isAcidityChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("acidityObservation")}
                    key={`acidityObservation`}
                    name={`acidityObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("acidityObservation");

                      if (textValue !== "") {
                        setValue("isAcidityChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Thyroid Vitamin Levels
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isThyroidVitLevelChk")}
                    name={`isThyroidVitLevelChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("thyroidVitLevelObservation", "");
                      }
                    }}
                    // checked={methods.watch('isThyroidVitLevelChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("thyroidVitLevelObservation")}
                    key={`thyroidVitLevelObservation`}
                    name={`thyroidVitLevelObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("thyroidVitLevelObservation");

                      if (textValue !== "") {
                        setValue("isThyroidVitLevelChk", true);
                      }
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Head Heaviness
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isHeadHeavinessChk")}
                    name={`isHeadHeavinessChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("headHeavinessObservation", "");
                      }
                    }}
                    // checked={methods.watch('isHeadHeavinessChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("headHeavinessObservation")}
                    key={`headHeavinessObservation`}
                    name={`headHeavinessObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("headHeavinessObservation");

                      if (textValue !== "") {
                        setValue("isHeadHeavinessChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Eye Heaviness
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isEyeHeavinessChk")}
                    name={`isEyeHeavinessChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("eyeHeavinessObservation", "");
                      }
                    }}
                    // checked={methods.watch('isEyeHeavinessChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("eyeHeavinessObservation")}
                    key={`eyeHeavinessObservation`}
                    name={`eyeHeavinessObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("eyeHeavinessObservation");

                      if (textValue !== "") {
                        setValue("isEyeHeavinessChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Tongue Heaviness
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isTongueHeavinessChk")}
                    name={`isTongueHeavinessChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("tongueHeavinessObservation", "");
                      }
                    }}
                    // checked={methods.watch('isTongueHeavinessChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("tongueHeavinessObservation")}
                    key={`tongueHeavinessObservation`}
                    name={`tongueHeavinessObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("tongueHeavinessObservation");

                      if (textValue !== "") {
                        setValue("isTongueHeavinessChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Sluring Of Speech
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isSlurringOfSpeechChk")}
                    name={`isSlurringOfSpeechChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("slurringOfSpeechObservation", "");
                      }
                    }}
                    // checked={methods.watch('isSlurringOfSpeechChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("slurringOfSpeechObservation")}
                    key={`slurringOfSpeechObservation`}
                    name={`slurringOfSpeechObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues(
                        "slurringOfSpeechObservation"
                      );

                      if (textValue !== "") {
                        setValue("isSlurringOfSpeechChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Weakness</td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isWeaknessChk")}
                    name={`isWeaknessChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("weaknessObservation", "");
                      }
                    }}
                    // checked={methods.watch('isWeaknessChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("weaknessObservation")}
                    key={`weaknessObservation`}
                    name={`weaknessObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("weaknessObservation");

                      if (textValue !== "") {
                        setValue("isWeaknessChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Fatigue</td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isFatigueChk")}
                    name={`isFatigueChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("fatigueObservation", "");
                      }
                    }}
                    // checked={methods.watch('isFatigueChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("fatigueObservation")}
                    key={`fatigueObservation`}
                    name={`fatigueObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("fatigueObservation");

                      if (textValue !== "") {
                        setValue("isFatigueChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Hydration
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isHydrationChk")}
                    name={`isHydrationChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("hydrationObservation", "");
                      }
                    }}
                    // checked={methods.watch('isHydrationChk')}
                    disabled={isDisabled}
                  />
                </td>

                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("hydrationObservation")}
                    key={`hydrationObservation`}
                    name={`hydrationObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("hydrationObservation");

                      if (textValue !== "") {
                        setValue("isHydrationChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Stress</td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isStressChk")}
                    name={`isStressChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("stressObservation", "");
                      }
                    }}
                    // checked={methods.watch('isStressChk')}
                    disabled={isDisabled}
                  />
                </td>

                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("stressObservation")}
                    key={`stressObservation`}
                    name={`stressObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("stressObservation");

                      if (textValue !== "") {
                        setValue("isStressChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-col items-stretch">
          <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
            Co-ordination
          </div>

          <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="border px-2 py-1 border-slate-300 "
                  style={{ width: "30%" }}
                >
                  Name
                </th>
                <th
                  className="border px-2 py-1 border-slate-300 "
                  style={{ width: "20%" }}
                >
                  Select (if applicable)
                </th>
                <th
                  className="border px-2 py-1 border-slate-300"
                  style={{ width: "50%" }}
                >
                  Observation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Finger-Nose
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isFingureNoseChk")}
                    name={`isFingureNoseChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("fingureNoseObservation", "");
                      }
                    }}
                    // checked={methods.watch('isFingureNoseChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("fingureNoseObservation")}
                    key={`fingureNoseObservation`}
                    name={`fingureNoseObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("fingureNoseObservation");

                      if (textValue !== "") {
                        setValue("isFingureNoseChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Disdiakokinesis
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isDisdiakokinesisChk")}
                    name={`isDisdiakokinesisChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("disdiakokinesisObservation", "");
                      }
                    }}
                    // checked={methods.watch('isDisdiakokinesisChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("disdiakokinesisObservation")}
                    key={`disdiakokinesisObservation`}
                    name={`disdiakokinesisObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("disdiakokinesisObservation");

                      if (textValue !== "") {
                        setValue("isDisdiakokinesisChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Heel Shin
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isHeelShinChk")}
                    name={`isHeelShinChk`}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === false) {
                        setValue("heelShinObservation", "");
                      }
                    }}
                    // checked={methods.watch('isHeelShinChk')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("heelShinObservation")}
                    key={`heelShinObservation`}
                    name={`heelShinObservation`}
                    disabled={isDisabled}
                    onBlur={() => {
                      const textValue = getValues("heelShinObservation");

                      if (textValue !== "") {
                        setValue("isHeelShinChk", true);
                      }
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-col items-stretch">
          <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
            Assessment
          </div>

          <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="border px-2 py-1 border-slate-300 "
                  style={{ width: "30%" }}
                >
                  Name
                </th>

                <th
                  className="border px-2 py-1 border-slate-300"
                  style={{ width: "10%" }}
                >
                  Left
                </th>
                <th
                  className="border px-2 py-1 border-slate-300"
                  style={{ width: "10%" }}
                >
                  Right
                </th>
                <th
                  className="border px-2 py-1 border-slate-300"
                  style={{ width: "50%" }}
                >
                  Observation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Tinnitus</td>

                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isTinnitusLeft")}
                    name={`isTinnitusLeft`}
                    type="checkbox"
                    // checked={methods.watch('isTinnitusLeft')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isTinnitusRight")}
                    name={`isTinnitusRight`}
                    type="checkbox"
                    // checked={methods.watch('isTinnitusRight')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("tinnitusObservation")}
                    key={`tinnitusObservation`}
                    name={`tinnitusObservation`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">SNHL</td>

                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isSNHLLeft")}
                    name={`isSNHLLeft`}
                    type="checkbox"
                    // checked={methods.watch('isSNHLLeft')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isSNHLRight")}
                    name={`isSNHLRight`}
                    type="checkbox"
                    // checked={methods.watch('isSNHLRight')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("sNHLObservation")}
                    key={`sNHLObservation`}
                    name={`sNHLObservation`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-col items-stretch">
          <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
            Rhombergs
          </div>

          <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <th
                className="border px-2 py-1 border-slate-300 "
                style={{ width: "30%" }}
              >
                Name
              </th>

              <th
                className="border px-2 py-1 border-slate-300 "
                style={{ width: "70%" }}
              >
                Observation
              </th>
            </thead>
            <tbody>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  On Pillow
                </td>

                <td>
                  <table className="w-full">
                    <tbody>
                      <tr>
                        <td className="border px-2 py-4 text-center border-slate-300">
                          <input
                            {...register("isOnPillowEyesOpenedChk")}
                            name={`isOnPillowEyesOpenedChk`}
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked === false) {
                                setValue("onPillowEyesOpenObservation", "");
                              }
                            }}
                            // checked={methods.watch('isOnPillowEyesOpenedChk')}
                            disabled={isDisabled}
                          />
                        </td>
                        <td className="border px-2 py-4 text-center border-slate-300">
                          Eyes Open
                        </td>
                        <td className="border px-2 py-4 text-center border-slate-300 ">
                          <textarea
                            className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            {...register("onPillowEyesOpenObservation")}
                            key={`onPillowEyesOpenObservation`}
                            name={`onPillowEyesOpenObservation`}
                            disabled={isDisabled}
                            onBlur={() => {
                              const textValue = getValues(
                                "onPillowEyesOpenObservation"
                              );

                              if (textValue !== "") {
                                setValue("isOnPillowEyesOpenedChk", true);
                              }
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-4 text-center border-slate-300">
                          <input
                            {...register("isOnPillowEyesClosedChk")}
                            name={`isOnPillowEyesClosedChk`}
                            type="checkbox"
                            // checked={methods.watch('isOnPillowEyesClosedChk')}
                            disabled={isDisabled}
                            onChange={(e) => {
                              if (e.target.checked === false) {
                                setValue("onPillowEyesClosedObservation", "");
                              }
                            }}
                          />
                        </td>
                        <td className="border px-2 py-4 text-center border-slate-300 ">
                          Eyes Closed
                        </td>
                        <td className="border px-2 py-4 text-center border-slate-300 ">
                          <textarea
                            className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            {...register("onPillowEyesClosedObservation")}
                            key={`onPillowEyesClosedObservation`}
                            name={`onPillowEyesClosedObservation`}
                            disabled={isDisabled}
                            onBlur={() => {
                              const textValue = getValues(
                                "onPillowEyesClosedObservation"
                              );

                              if (textValue !== "") {
                                setValue("isOnPillowEyesClosedChk", true);
                              }
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  On Ground
                </td>

                <td>
                  <table className="w-full">
                    <tbody>
                      <tr>
                        <td className="border px-2 py-4 text-center border-slate-300">
                          <input
                            {...register("isOnGroundEyesOpenedChk")}
                            name={`isOnGroundEyesOpenedChk`}
                            type="checkbox"
                            // checked={methods.watch('isOnGroundEyesOpenedChk')}
                            disabled={isDisabled}
                            onChange={(e) => {
                              if (e.target.checked === false) {
                                setValue("onGroundEyesOpenObservation", "");
                              }
                            }}
                          />
                        </td>
                        <td className="border px-2 py-4 text-center border-slate-300">
                          Eyes Open
                        </td>
                        <td className="border px-2 py-4 text-center border-slate-300 ">
                          <textarea
                            className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            {...register("onGroundEyesOpenObservation")}
                            key={`onGroundEyesOpenObservation`}
                            name={`onGroundEyesOpenObservation`}
                            disabled={isDisabled}
                            onBlur={() => {
                              const textValue = getValues(
                                "onGroundEyesOpenObservation"
                              );

                              if (textValue !== "") {
                                setValue("isOnGroundEyesOpenedChk", true);
                              }
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-4 text-center border-slate-300">
                          <input
                            {...register("isOnGroundEyesClosedChk")}
                            name={`isOnGroundEyesClosedChk`}
                            type="checkbox"
                            // checked={methods.watch('isOnGroundEyesClosedChk')}
                            disabled={isDisabled}
                            onChange={(e) => {
                              if (e.target.checked === false) {
                                setValue("onGroundEyesClosedObservation", "");
                              }
                            }}
                          />
                        </td>
                        <td className="border px-2 py-4 text-center border-slate-300 ">
                          Eyes Closed
                        </td>
                        <td className="border px-2 py-4 text-center border-slate-300 ">
                          <textarea
                            className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            {...register("onGroundEyesClosedObservation")}
                            key={`onGroundEyesClosedObservation`}
                            name={`onGroundEyesClosedObservation`}
                            disabled={isDisabled}
                            onBlur={() => {
                              const textValue = getValues(
                                "onGroundEyesClosedObservation"
                              );

                              if (textValue !== "") {
                                setValue("isOnGroundEyesClosedChk", true);
                              }
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-col items-stretch">
          <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
            Positional Test
          </div>

          <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="border px-2 py-1 border-slate-300 "
                  style={{ width: "30%" }}
                >
                  Name
                </th>

                <th
                  className="border px-2 py-1 border-slate-300"
                  style={{ width: "10%" }}
                >
                  Left
                </th>
                <th
                  className="border px-2 py-1 border-slate-300"
                  style={{ width: "10%" }}
                >
                  Right
                </th>
                <th
                  className="border px-2 py-1 border-slate-300"
                  style={{ width: "50%" }}
                >
                  Observation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Positional Test - AC
                </td>

                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isPositionalTestACLeft")}
                    name={`isPositionalTestACLeft`}
                    type="checkbox"
                    // checked={methods.watch('isPositionalTestACLeft')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isPositionalTestACRight")}
                    name={`isPositionalTestACRight`}
                    type="checkbox"
                    // checked={methods.watch('isPositionalTestACRight')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("positionalTestACObservation")}
                    key={`positionalTestACObservation`}
                    name={`positionalTestACObservation`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Positional Test - PC
                </td>

                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isPositionalTestPCLeft")}
                    name={`isPositionalTestPCLeft`}
                    type="checkbox"
                    // checked={methods.watch('isPositionalTestPCLeft')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isPositionalTestPCRight")}
                    name={`isPositionalTestPCRight`}
                    type="checkbox"
                    // checked={methods.watch('isPositionalTestPCRight')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("positionalTestPCObservation")}
                    key={`positionalTestPCObservation`}
                    name={`positionalTestPCObservation`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Positional Test - HC
                </td>

                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isPositionalTestHCLeft")}
                    name={`isPositionalTestHCLeft`}
                    type="checkbox"
                    // checked={methods.watch('isPositionalTestHCLeft')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-4 text-center border-slate-300">
                  <input
                    {...register("isPositionalTestHCRight")}
                    name={`isPositionalTestHCRight`}
                    type="checkbox"
                    // checked={methods.watch('isPositionalTestHCRight')}
                    disabled={isDisabled}
                  />
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("positionalTestHCObservation")}
                    key={`positionalTestHCObservation`}
                    name={`positionalTestHCObservation`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
          <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
            <OutlinedButton
              type="button"
              onClick={() => {
                props.postSubmitCallback();
              }}
              className="mr-2"
              variant={ButtonVariant.PRIMARY}
            >
              Close
            </OutlinedButton>
            {isDisabled ? (
              <Button
                type="button"
                key="EditButton"
                onClick={() => {
                  setIsDisabled(false);
                }}
                variant={ButtonVariant.PRIMARY}
              >
                Edit
              </Button>
            ) : props.assessmentId !== "000000000000000000000000" ? (
              <Button
                type="submit"
                key="UpdateButton"
                onClick={() => {}}
                variant={ButtonVariant.PRIMARY}
              >
                Update
              </Button>
            ) : (
              <Button
                type="submit"
                key="CreateButton"
                onClick={() => {}}
                variant={ButtonVariant.PRIMARY}
              >
                Create
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
