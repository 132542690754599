// To parse this data:
//
//   import { Convert, ReferringUsersHospitals } from "./file";
//
//   const referringUsersHospitals = Convert.toReferringUsersHospitals(json);

export interface ReferringUsersHospitals {
  OtherReferrals: string[];
  ReferringHospitals: string[];
  ReferringUsers: string[];
}

// Converts JSON strings to/from your types
export class ConvertReferringUsersHospitals {
  public static toReferringUsersHospitals(
    json: string
  ): ReferringUsersHospitals {
    return JSON.parse(json);
  }

  public static referringUsersHospitalsToJson(
    value: ReferringUsersHospitals
  ): string {
    return JSON.stringify(value);
  }
}
