import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { paths } from "@routes/paths";
import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";
import Button from "@components/button/button";
import { ButtonVariant } from "@components/button/button";
import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";

import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { generateForm } from "./InitialADLQuestions";
import { Disclosure, RadioGroup, Tab } from "@headlessui/react";
import {
  CheckCircleIcon,
  CheckIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { ADLAnswers } from "./types/ADLAnswers";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { Convert, Datum } from "./types/ADLGraphData";
import moment from "moment";
import { ADLCategory } from "./types/ADLcategory";

type ADLAssessmentGraphProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  adlCategories: ADLCategory[];
  postSubmitCallback: () => void;
};

export default function ADLAssessmentGraph(props: ADLAssessmentGraphProps) {
  // Define the type for the map
  type MyMapType = {
    [key: string]: Datum[];
  };
  const userData = getUserPersistedOnLocalStorage();

  const [loading, setLoading] = React.useState(true);
  const [graphdata, setGraphsData] = React.useState<MyMapType>({});
  const handleDataUpdate = (category: string, data: Datum[]) => {
    setGraphsData((prevState) => ({
      ...prevState,
      [category]: data,
    }));
  };

  //console.log(graphdata);
  useEffect(() => {
    const getPatients = async (categoryData: ADLCategory) => {
      try {
        const paramsMap = new Map<string, string>([
          ["id", props.patientId!],
          ["category", categoryData.categoryCode],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.getADLGraphData,
          paramsMap
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data["data"];
        const convertedPersonalInformation = Convert.toADLGraphData(
          JSON.stringify(data)
        );

        if (data) {
          //console.log("hi");
          handleDataUpdate(categoryData.category, data);
        }
      } catch (error) {
        console.error("Error getting patient information", error);
      }
    };

    setLoading(true);
    props.adlCategories.map(async (val) => {
      await getPatients(val);
    });
    setLoading(false);
  }, []);

  const Questions = {
    Neck: [
      {
        value: 9,
        date: "28/05/1999",
      },
      {
        value: 83,
        date: "01/09/2024",
      },
      {
        value: 42,
        date: "14/06/2024",
      },
      {
        value: 60,
        date: "07/07/2024",
      },
      {
        value: 28,
        date: "23/05/2024",
      },
      {
        value: 75,
        date: "19/08/2024",
      },
    ],
    Back: [
      {
        value: 7,
        date: "15/02/2024",
      },
      {
        value: 64,
        date: "03/04/2024",
      },
      {
        value: 31,
        date: "22/06/2024",
      },
      {
        value: 50,
        date: "09/08/2024",
      },
      {
        value: 88,
        date: "17/07/2024",
      },
      {
        value: 12,
        date: "28/09/2024",
      },
    ],
    Shoulder: [
      {
        value: 45,
        date: "05/03/2024",
      },
      {
        value: 78,
        date: "12/05/2024",
      },
      {
        value: 23,
        date: "19/07/2024",
      },
      {
        value: 60,
        date: "25/09/2024",
      },
      {
        value: 91,
        date: "08/08/2024",
      },
      {
        value: 36,
        date: "30/06/2024",
      },
    ],
  };

  return (
    <div className="flex flex-col w-full h-full">
      {loading ? (
        <h1>Loading</h1>
      ) : (
        <>
          {Object.keys(graphdata).map((category: string) => {
            const patientSatisfactionOptions: ApexOptions = {
              series: [
                {
                  name: "ADL",
                  data: graphdata[category].map((val) => val.score),
                },
              ],

              labels: graphdata[category].map((val) =>
                moment(val.date).format("DD/MM/YYYY")
              ),
              chart: {
                type: "area",
                stacked: false,
                height: 350,
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: false,
                },
                toolbar: {
                  show: false,
                },
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 5, // Size of the marker
                // colors: ["#FF4560"], // Color of the marker
              },

              fill: {
                type: "gradient",
                gradient: {
                  shadeIntensity: 1,
                  inverseColors: false,
                  opacityFrom: 0.5,
                  opacityTo: 0,
                  stops: [0, 90, 100],
                },
              },
              yaxis: {
                title: {
                  text: "ADL Score",
                },
              },

              // xaxis: {
              //   type: "numeric",
              // },
              tooltip: {
                shared: false,
                y: {
                  formatter: function (val) {
                    return val.toFixed(0);
                  },
                },
              },
            };

            return (
              <>
                <div className="inline-flex items-center rounded-xl text-sm px-4 py-2 font-medium focus:outline-none transition ease-in-out duration-150 hover:shadow bg-secondary-50 hover:bg-secondary-100 focus:ring-secondary-500 focus:ring-offset-secondary-200 text-secondary shadow-sm border border-secondary-600 w-full">
                  {category}
                </div>

                <ReactApexChart
                  options={patientSatisfactionOptions}
                  series={patientSatisfactionOptions.series}
                  type={patientSatisfactionOptions.chart?.type}
                />
              </>
            );
          })}
        </>
      )}
    </div>
  );
}
