import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { AvaialableExercises, Converto } from '../../type/AvailableExercises';
import { AssignExercise } from '../../type/AssignExercise';
import { Button } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import axios from 'axios';
import { endpoints, replaceParams } from '@utils/axios';
import { toast } from 'react-toastify';
import { Switch } from '@headlessui/react';
import CountCard from '@components/count-card/count-card';

type AvailableHomeExerciseProps = {
  treatmentPlanId: string;
  postSubmitCallback: () => void;
  onClose: () => void;
};

export default function AvailableHomeExercise(
  props: AvailableHomeExerciseProps,
) {
  let [assignExercises, setAssignExercises] = React.useState<AssignExercise[]>(
    [],
  );
  const [availableExercises, setAvailableExercises] = React.useState<
    AvaialableExercises[] | null
  >(null);
  const [filteredAvailableExercises, setFilteredAvailableExercises] =
    React.useState<AvaialableExercises[] | undefined>(undefined);

  const [searchQuery, setSearchQuery] = React.useState('');
  const [enabled, setEnabled] = React.useState(false);

  useEffect(() => {
    getAvailableExercisesFromCurrentEntity();
  }, []);

  const getAvailableExercisesFromCurrentEntity = async () => {
    try {
      // Getting exercises of Current entity

      const paramsMap = new Map<string, string>([
        ['id', props.treatmentPlanId],
      ]);
      const response = await axios.get(
        replaceParams(endpoints.managePatient.getAvailableExercises, paramsMap),
      );
      const data = response.data['data'];
      const toSet: AvaialableExercises[] = [];
      for (const exercise of data) {
        const converted = Converto.toAvaialableExercises(
          JSON.stringify(exercise),
        );
        toSet.push(converted);
      }
      setAvailableExercises(toSet);
    } catch (error) {
      console.error('Error fetching active exercise program:', error);
    }
  };

  useEffect(() => {
    let filteredExercises = availableExercises?.filter(e => {
      return (
        e.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
        e.includedInPlatformLibrary === enabled
      );
    });

    setFilteredAvailableExercises(filteredExercises);
  }, [enabled, availableExercises, searchQuery]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleExercisLibrarySwitch = async () => {
    if (enabled) {
      setEnabled(false);
    } else {
      setEnabled(true);
    }
  };

  const handleAssignExerciseSubmit = async () => {
    try {
      const paramsMapTreatmentPlanId = new Map<string, string>([
        ['id', props.treatmentPlanId!],
      ]);

      const response = await axios.post(
        replaceParams(
          endpoints.managePatient.updateClientHomeExercisePromramme,
          paramsMapTreatmentPlanId,
        ),
        assignExercises,
      );
      if (response.data.status === 200)
        toast.success(
          response.data.message === 'success'
            ? 'Exercises assigned Successfully'
            : response.data.message,
        );

      props.postSubmitCallback();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div>
        <div className="flex flex-col justify-between items-start">
          <div className="">
            <h2 className="text-xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
              Add Exercise to Program
            </h2>
          </div>

          <div className="flex flex-row  items-center justify-between w-full mt-2">
            <div className="flex items-center justify-start mt-2">
              <Switch
                onChange={handleExercisLibrarySwitch}
                checked={enabled}
                className={`${enabled ? 'bg-secondary-600' : 'bg-secondary-50'}
                                relative inline-flex h-[38px] w-[74px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 mr-2`}
              >
                <span
                  aria-hidden="true"
                  className={`${enabled ? 'translate-x-9' : 'translate-x-0'}
                                pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
              {enabled ? (
                <span className="text-gray-500 flex-shrink">
                  Gritup Exercises
                </span>
              ) : (
                <span className="text-gray-500 flex-shrink">
                  Entity Exercises
                </span>
              )}
            </div>
            <div>
              <CountCard
                number={assignExercises.length}
                text={'Selected'}
                textColor="text-primary-500"
              ></CountCard>
            </div>
          </div>
        </div>
        <div className="relative mt-4 pb-5 sm:pb-0">
          <SearchBar searchQuery={searchQuery} onSearch={handleSearch} />
          <ul className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 bg-gray-50 -mx-8 p-8">
            {filteredAvailableExercises &&
              filteredAvailableExercises.map(e => (
                <ExerciseCard
                  key={e.id}
                  exercise={e}
                  isSelected={assignExercises.some(ex => ex.id === e.id)}
                  onClick={() => {
                    const exerciseIndex = assignExercises.findIndex(
                      ex => ex.id === e.id,
                    );

                    if (exerciseIndex === -1) {
                      setAssignExercises(prevAssignExercises => [
                        ...prevAssignExercises,
                        {
                          id: e.id,
                          title: e.title,
                          config: e.defaultExerciseConfig,
                          instructions: e.instructions,
                        },
                      ]);
                    } else {
                      setAssignExercises(prevAssignExercises =>
                        prevAssignExercises.filter(ex => ex.id !== e.id),
                      );
                    }
                  }}
                />
              ))}
          </ul>
          <div className="fixed items-center w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-8 shadow bg-white">
            <div className="flex flex-row ">
              <div>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  type="button"
                  className="ml-3"
                  onClick={() => {
                    handleAssignExerciseSubmit();
                  }}
                >
                  Save
                </Button>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  type="button"
                  className="ml-3"
                  onClick={() => {
                    props.onClose();
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SearchBar({
  searchQuery,
  onSearch,
}: {
  searchQuery: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <div>
      <input
        type="text"
        value={searchQuery}
        onChange={onSearch}
        placeholder="Search"
        className="w-full px-4 py-2 mb-4 text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500"
      />
    </div>
  );
}

type ExerciseProps = {
  exercise: AvaialableExercises;
  isSelected: boolean;
  onClick: () => void;
};

function ExerciseCard(props: ExerciseProps) {
  return (
    <div
      className={`flex flex-col justify-between rounded-2xl shadow-md bg-white px-1 py-1 w-full hover:border-2  border-success-600 cursor-pointer   ${
        props.isSelected ? 'border-2  border-success-600' : 'border-0  '
      }`}
      onClick={props.onClick}
    >
      {/* ...content of the staff card... */}
      <div className="flex flex-col  rounded-xl bg-white-300 items-center justify-between p-4 pb-0 ">
        {props.isSelected ? (
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="h-5 w-5 text-black-400 self-end text-success-600"
            aria-hidden="true"
          />
        ) : (
          <div className="h-5 w-5 "></div>
        )}

        <div className="flex flex-col self-start">
          <span className="flex-initial text-md text-black font-bold">
            {props.exercise.title} {props.isSelected}
          </span>
          {/* <span className="flex-initial text-sm text-gray-400">{staff.role.role}</span> */}
          <hr className="mt-2 h-0.5 bg-white-300 opacity-100 dark:opacity-50" />
        </div>
      </div>
      <div className="flex flex-col items-start px-4 mt-2">
        {/* <span className="flex-initial text-black">
          {props.exercise.difficulty}
        </span> */}
      </div>
      <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4 mt-2">
        {/* <div className="bg-tertiary-50 hover:bg-tertiary-100 focus:ring-tertiary-500 focus:ring-offset-tertiary-200 text-tertiary shadow-sm border border-tertiary-600 inline-flex items-center rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none transition ease-in-out duration-150 hover:shadow">
          <span className="flex-initial text-sm text-tertiary-600 font-semibold">
            {props.exercise.difficulty}
          </span>
        </div> */}
      </div>
    </div>
  );
}
