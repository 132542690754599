import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import BranchInformationComponent from "./BranchInformationComponent";
import StaffComponent from "./StaffComponent";
import { useParams, useSearchParams } from "react-router-dom";
import ConfigurationStep from "./ConfigurationStep";
import { json } from "stream/consumers";
import { Button } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import LetterHeadPage from "./LetterHead";
interface Tab {
  name: string;
  current: boolean;
}

function ViewBranchPage() {
  const [activeTab, setActiveTab] = useState("Information");
  let { branchId, branchName, entityId } = useParams();
  const [timeZone, setBranchTimeZone] = useState("");
  const [hasTimeZoneChanged, setHastimeZoneChanged] = useState(false);

  const userData = getUserPersistedOnLocalStorage();

  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");

  const tabComponents: Record<string, React.ReactNode> = {
    Information: (
      <BranchInformationComponent
        branchId={branchId ?? ""}
        entityId={userData?.entity_id ?? ""}
        setBranchTimeZone={setBranchTimeZone}
        hasTimeZoneChanged={setHastimeZoneChanged}
      />
    ),
    "Working Hours": (
      <ConfigurationStep
        branchId={branchId ?? ""}
        entityId={userData?.entity_id ?? ""}
        timeZone={timeZone}
        hasTimeZoneChanged={hasTimeZoneChanged}
      />
    ),

    Staff: (
      <StaffComponent
        branchId={branchId ?? ""}
        entityId={userData?.entity_id ?? ""}
      />
    ),
    "Letter Head": <LetterHeadPage branchId={branchId ?? ""}></LetterHeadPage>,
    // Patients: (
    //   <PatientComponent branchId={location.state.id} entityId={userData?.entity_id ?? ''} />
    // ),
  };

  const tabs: Tab[] = [
    { name: "Information", current: activeTab === "Information" },
    { name: "Working Hours", current: activeTab === "Working Hours" },
    { name: "Staff", current: activeTab === "Staff" },
    { name: "Letter Head", current: activeTab === "Letter Head" },
  ];

  function handleTabClick(tabName: string) {
    setActiveTab(tabName);
  }
  return (
    <>
      <div className="flex flex-col">
        <div className="flex-shrink">
          <div className="border-b border-gray-200 pb-5 sm:pb-0">
            <div className="flex flex-row justify-between">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                {name}
              </h3>
              <Button
                variant={ButtonVariant.PRIMARY}
                type="submit"
                className="ml-3"
                onClick={() => {
                  navigator(-1);
                }}
              >
                Back
              </Button>
            </div>
            <div className="mt-3 sm:mt-4">
              <div className="block md:hidden">
                <label htmlFor="current-tab" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="current-tab"
                  name="current-tab"
                  className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  value={activeTab}
                  onChange={(e) => handleTabClick(e.target.value)}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name} value={tab.name}>
                      {tab.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-3 sm:mt-4 hidden md:block">
                <nav className="-mb-px flex space-x-8">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      className={`${
                        tab.current
                          ? "border-primary-500 text-primary-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      } whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium focus:outline-none`}
                      onClick={() => handleTabClick(tab.name)}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>
        {tabComponents[activeTab]}
      </div>
    </>
  );
}

export default ViewBranchPage;
