import useAxios from '@routes/hooks/use-axios';
import { endpoints, replaceParams } from '@utils/axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Convert as ConvertStaff, Staff } from './types/Staff';
import { ValidationError } from '@components/hook-form';
import * as z from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { paths } from '@routes/paths';
import { ManagePatientContext } from '@pages/manage-patient/view/ManagePatient';
import { Button, OutlinedButton } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import moment from 'moment';
import { getUserPersistedOnLocalStorage } from '@authentication/context/jwt/utils';
import { CancelTokenSource } from 'axios';

export const MasterAssessmentSchema = z.object({
  clientId: z.string(),
  entityId: z.string(),
  entityUserId: z.string(),
  entityUserName: z.string(),
  assessmentDate: z.coerce.date(),
  vitalAssessmentId: z.string(),
  painAssessmentId: z.string(),
  postureAssessmentId: z.string(),
  gaitAssessmentId: z.string(),
  rangeOfMotionAssessmentId: z.string(),
  muscleToneAssessmentId: z.string(),
});
export type MasterAssessment = z.infer<typeof MasterAssessmentSchema>;

export default function NewMasterAssessment(props: {
  handleCloseModal: () => void;
}) {
  const [staff, setStaff] = React.useState<Staff[] | null>(null); // this is the list of staff [{}]
  const [loading, setLoading] = React.useState<boolean>(false);
  const [postInProgress, setPostInProgress] = React.useState<boolean>(false);
  const [error, setError] = React.useState('');

  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = React.useContext(ManagePatientContext);

  const cancelToken = React.useRef<CancelTokenSource | null>(null);

  const getStaff = React.useCallback(async () => {
    try {
      setLoading(true);

      const params = new URLSearchParams([['role', 'PRACTITIONER']]);
      const response = await axios.get(endpoints.staff.list, { params });
      const data = response.data['data'];
      const convertedStaffList = data.map((staff: any) =>
        ConvertStaff.toStaff(JSON.stringify(staff)),
      );
      setStaff(convertedStaffList);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching staff list:', error);
      setError(error.message);
    }
  }, [axios]);

  const initialize = () => {
    const userData = getUserPersistedOnLocalStorage();
    // set default values for the form
    return {
      assessmentDate: new Date(moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')),
      clientId: patientId.patientId,
      entityId: userData?.entity_id,
      entityUserId: '',
      entityUserName: '',
      gaitAssessmentId: '000000000000000000000000',
      muscleToneAssessmentId: '000000000000000000000000',
      painAssessmentId: '000000000000000000000000',
      postureAssessmentId: '000000000000000000000000',
      rangeOfMotionAssessmentId: '000000000000000000000000',
      vitalAssessmentId: '000000000000000000000000',
    };
  };

  React.useEffect(() => {
    getStaff();

    return () => {
      setStaff(null);
      setLoading(false);
    };
  }, [getStaff]);

  function findEmployeeName(id: string) {
    const employee = staff?.find(staff => staff.id === id);
    return `${employee?.salutation}. ${employee?.firstName} ${employee?.middleName} ${employee?.lastName}`;
  }

  const methods = useForm<MasterAssessment>({
    resolver: zodResolver(MasterAssessmentSchema),
    defaultValues: MasterAssessmentSchema.parse(initialize()),
  });

  const onSubmit: SubmitHandler<MasterAssessment> = async data => {
    if (postInProgress) return;

    methods.trigger();

    if (cancelToken.current) {
      cancelToken.current.cancel('Request already in progress!');
    }

    cancelToken.current = axios.CancelToken.source();

    try {
      MasterAssessmentSchema.parse(data);
      setPostInProgress(true);
      const response = await axios.post(
        `${endpoints.assessment.createMasterAssessment}`,
        data,
        {
          cancelToken: cancelToken.current.token,
        },
      );
      setPostInProgress(false);

      props.handleCloseModal();
    } catch (error) {
      console.error('Error submitting form:', error);
      setError(error.response.data['data'].toString());
      setPostInProgress(false);
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          New Master Assessment
        </h3>
      </div>

      <hr className="w-full" />

      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col w-full h-full px-4 py-2 space-y-2">
          <div className="flex flex-col w-full justify-between">
            <span className="text-gray-700 text-sm">Assessment Date</span>
            <input
              type="date"
              className="flex lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 "
              value={moment(methods.watch('assessmentDate')).format(
                'YYYY-MM-DD',
              )}
              {...methods.register('assessmentDate', {
                onChange(event) {
                  methods.setValue(
                    'assessmentDate',
                    new Date(moment(event.target.value).format('YYYY-MM-DD')),
                  );
                },
              })}
            />
          </div>

          <div className="flex flex-col w-full justify-between">
            <span className="text-gray-700  text-sm">Physiotherapist</span>
            <select
              {...methods.register('entityUserId', {
                required: true,
                onChange(event) {
                  methods.setValue(
                    'entityUserName',
                    findEmployeeName(event.target.value),
                  );
                },
              })}
              className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
            >
              {staff?.map(staff => (
                <option key={staff.id} value={staff.id}>
                  {`${staff.salutation}. ${staff.firstName} ${staff.middleName} ${staff.lastName}`}
                </option>
              ))}
            </select>
          </div>
        </div>
        {error && <ValidationError message={error} />}
        <div className="flex flex-row w-full mt-4 p-4 bg-gray-100 justify-end">
          <OutlinedButton
            variant={ButtonVariant.PRIMARY}
            type="button"
            onClick={props.handleCloseModal}
            children="Close"
            className="mr-2"
          />
          <Button
            type="submit"
            onClick={() => { }}
            variant={ButtonVariant.PRIMARY}
          >
            Create
          </Button>
        </div>
      </form>
    </div>
  );
}
