import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { paths } from "@routes/paths";
import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";
import moment from "moment";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

import { Grid } from "gridjs-react";
import Button from "@components/button/button";
import { ButtonVariant } from "@components/button/button";

import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";

import { ValidationError } from "@components/hook-form";
import { Convert } from "./types/Posture";

export const PostureSchema = z.object({
  id: z.union([z.null(), z.string()]).optional(),
  masterAssessmentId: z.union([z.null(), z.string()]).optional(),
  clientId: z.union([z.null(), z.string()]).optional(),
  entityId: z.union([z.null(), z.string()]).optional(),
  entityBranchId: z.union([z.null(), z.string()]).optional(),
  lateralHead: z.union([z.null(), z.string()]).optional(),
  lateralCervicalSpine: z.union([z.null(), z.string()]).optional(),
  lateralThoracicSpine: z.union([z.null(), z.string()]).optional(),
  lateralLumbarSpine: z.union([z.null(), z.string()]).optional(),
  lateralPelvis: z.union([z.null(), z.string()]).optional(),
  lateralHipJoint: z.union([z.null(), z.string()]).optional(),
  lateralKneeJoint: z.union([z.null(), z.string()]).optional(),
  lateralAnkleJoint: z.union([z.null(), z.string()]).optional(),
  anteriorPosteriorFacialAsymmetry: z.union([z.null(), z.string()]).optional(),
  anteriorPosteriorCervicalSpine: z.union([z.null(), z.string()]).optional(),
  anteriorPosteriorShoulderAsymmetry: z
    .union([z.null(), z.string()])
    .optional(),
  anteriorPosteriorScapulae: z.union([z.null(), z.string()]).optional(),
  anteriorPosteriorThoracicSpine: z.union([z.null(), z.string()]).optional(),
  anteriorPosteriorLumbarSpine: z.union([z.null(), z.string()]).optional(),
  anteriorPosteriorAbdomen: z.union([z.null(), z.string()]).optional(),
  anteriorPosteriorPelvis: z.union([z.null(), z.string()]).optional(),
  anteriorPosteriorHipJoint: z.union([z.null(), z.string()]).optional(),
  anteriorPosteriorKneeJoint: z.union([z.null(), z.string()]).optional(),
  anteriorPosteriorAnkleJoint: z.union([z.null(), z.string()]).optional(),
  anteriorPosteriorFeet: z.union([z.null(), z.string()]).optional(),
  anteriorPosteriorSpine: z.union([z.null(), z.string()]).optional(),
});
export type PostureSchemaType = z.infer<typeof PostureSchema>;

type PostureAsssessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function PostureAssessmentComponent(
  props: PostureAsssessmentProps
) {
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const userData = getUserPersistedOnLocalStorage();

  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<PostureSchemaType>({
    resolver: zodResolver(PostureSchema),
    defaultValues: {
      masterAssessmentId: props.masterAssessmentId,
      entityId: userData?.entity_id,
      clientId: props.patientId,
      entityBranchId: userData?.branch_id,
    },
  });

  React.useEffect(() => {
    const initializeAssessment = async () => {
      if (props.assessmentId !== "000000000000000000000000") {
        try {
          const paramsMap = new Map<string, string>([
            ["mid", props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getPostureAssessment,
            paramsMap
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data["data"];
          const convertedAssessment = Convert.toPosture(JSON.stringify(data));

          reset(convertedAssessment);
          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          console.error("Error fetching Posture assessment:", error);

          setLoading(false);
        }
      }
    };
    initializeAssessment();
  }, []);

  const navigateToMasterAssessment = (masterAssessmentId: string) => {
    const paramsMap = new Map<string, string>([
      ["patientId", patientId.patientId],
      ["masterAssessmentId", masterAssessmentId],
    ]);
    navigate(
      `${replaceParams(paths.patient.masterAssessment.route, paramsMap)}`
    );
  };

  const onSubmit: SubmitHandler<PostureSchemaType> = async (data: any) => {
    if (props.assessmentId !== "000000000000000000000000") {
      try {
        PostureSchema.parse(data);

        const paramsMap = new Map<string, string>([
          ["id", props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updatePostureAssessment,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Posture Assessment updated successfully"
              : response.data.message
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    } else {
      try {
        PostureSchema.parse(data);

        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createPostureAssessment,
          paramsMap
        );
        const response = await axios.post(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Posture Assessment created successfully"
              : response.data.message
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Posture Assessment
        </h3>
      </div>

      <hr className="w-full" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-stretch">
          <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
            Lateral/Side View
          </div>

          <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="border px-2 py-1 border-slate-300 "
                  style={{ width: "30%" }}
                >
                  Name
                </th>
                <th
                  className="border px-2 py-1 border-slate-300"
                  style={{ width: "70%" }}
                >
                  Observation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Head</td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("lateralHead")}
                    key={`lateralHead`}
                    name={`lateralHead`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Cervical Spine
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("lateralCervicalSpine")}
                    key={`lateralCervicalSpine`}
                    name={`lateralCervicalSpine`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Thoracic Spine
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("lateralThoracicSpine")}
                    key={`lateralThoracicSpine`}
                    name={`lateralThoracicSpine`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Lumbar Spine
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("lateralLumbarSpine")}
                    key={`lateralLumbarSpine`}
                    name={`lateralLumbarSpine`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Pelvis</td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("lateralPelvis")}
                    key={`lateralPelvis`}
                    name={`lateralPelvis`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Hip Joint
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("lateralHipJoint")}
                    key={`lateralHipJoint`}
                    name={`lateralHipJoint`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Knee Joint
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("lateralKneeJoint")}
                    key={`lateralKneeJoint`}
                    name={`lateralKneeJoint`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Ankle Joint
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("lateralAnkleJoint")}
                    key={`lateralAnkleJoint`}
                    name={`lateralAnkleJoint`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-col items-stretch">
          <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
            Anterior and Posterior/Front View
          </div>

          <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="border px-2 py-1 border-slate-300 "
                  style={{ width: "30%" }}
                >
                  Name
                </th>
                <th
                  className="border px-2 py-1 border-slate-300"
                  style={{ width: "70%" }}
                >
                  Observation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Facial Asymmetry(Bridge of Nose,Center of Chin)
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("anteriorPosteriorFacialAsymmetry")}
                    key={`anteriorPosteriorFacialAsymmetry`}
                    name={`anteriorPosteriorFacialAsymmetry`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Cervical Spine ( Lateral Flexion/Rotation)
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("anteriorPosteriorCervicalSpine")}
                    key={`anteriorPosteriorCervicalSpine`}
                    name={`anteriorPosteriorCervicalSpine`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Shoulder Symmetry (level/ elevation,depression)
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("anteriorPosteriorShoulderAsymmetry")}
                    key={`anteriorPosteriorShoulderAsymmetry`}
                    name={`anteriorPosteriorShoulderAsymmetry`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Scapulae</td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("anteriorPosteriorScapulae")}
                    key={`anteriorPosteriorScapulae`}
                    name={`anteriorPosteriorScapulae`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Thoracic Spine
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("anteriorPosteriorThoracicSpine")}
                    key={`anteriorPosteriorThoracicSpine`}
                    name={`anteriorPosteriorThoracicSpine`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Lumbar Spine
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("anteriorPosteriorLumbarSpine")}
                    key={`anteriorPosteriorLumbarSpine`}
                    name={`anteriorPosteriorLumbarSpine`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Abdomen</td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("anteriorPosteriorAbdomen")}
                    key={`anteriorPosteriorAbdomen`}
                    name={`anteriorPosteriorAbdomen`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Pelvis: ( ASIS / PSIS level)
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("anteriorPosteriorPelvis")}
                    key={`anteriorPosteriorPelvis`}
                    name={`anteriorPosteriorPelvis`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Hip Joint: (level / abducted,adducted)
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("anteriorPosteriorHipJoint")}
                    key={`anteriorPosteriorHipJoint`}
                    name={`anteriorPosteriorHipJoint`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Knee Joint
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("anteriorPosteriorKneeJoint")}
                    key={`anteriorPosteriorKneeJoint`}
                    name={`anteriorPosteriorKneeJoint`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Ankle Joint
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("anteriorPosteriorAnkleJoint")}
                    key={`anteriorPosteriorAnkleJoint`}
                    name={`anteriorPosteriorAnkleJoint`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">Feet</td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("anteriorPosteriorFeet")}
                    key={`anteriorPosteriorFeet`}
                    name={`anteriorPosteriorFeet`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border px-2 py-1 border-slate-300 ">
                  Spine(Total Curve)
                </td>
                <td className="border px-2 py-1 text-center border-slate-300">
                  <textarea
                    className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    {...register("anteriorPosteriorSpine")}
                    key={`anteriorPosteriorSpine`}
                    name={`anteriorPosteriorSpine`}
                    disabled={isDisabled}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
          <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
            <OutlinedButton
              type="button"
              onClick={() => {
                props.postSubmitCallback();
              }}
              className="mr-2"
              variant={ButtonVariant.PRIMARY}
            >
              Close
            </OutlinedButton>
            {isDisabled ? (
              <Button
                type="button"
                key="EditButton"
                onClick={() => {
                  setIsDisabled(false);
                }}
                variant={ButtonVariant.PRIMARY}
              >
                Edit
              </Button>
            ) : props.assessmentId !== "000000000000000000000000" ? (
              <Button
                type="submit"
                key="UpdateButton"
                onClick={() => {}}
                variant={ButtonVariant.PRIMARY}
              >
                Update
              </Button>
            ) : (
              <Button
                type="submit"
                key="CreateButton"
                onClick={() => {}}
                variant={ButtonVariant.PRIMARY}
              >
                Create
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
