export const generateForm = (masterAssessmentId: string) => {
  const myAssessment = {
    masterAssessmentId: masterAssessmentId,
    frontalPlaneObservation: '',
    saggitalPlaneObservation: '',
    frontalQuality: [
      {
        name: 'Cadence',
        type: 'cadence',
        isNormal: false,
        isGood: false,
        isPoor: false,

        comments: '',
      },
      {
        name: 'Fatigue',
        type: 'fatigue',
        isNormal: false,
        isGood: false,
        isPoor: false,

        comments: '',
      },
      {
        name: 'Speed',
        type: 'speed',
        isNormal: false,
        isGood: false,
        isPoor: false,

        comments: '',
      },
    ],
  };
  return myAssessment;
};
