import { endpoints, replaceParams } from '@utils/axios';
import React, { useCallback, useEffect } from 'react';
import { Convert, PatientList } from '../types/PatientList';
import useAxios from '@routes/hooks/use-axios';
import { useNavigate } from 'react-router-dom';
import { paths } from '@routes/paths';
import { OutlinedButton } from '@components/button';
import { ButtonVariant } from '@components/button/outline-button';

function PatientTable({
  patientList,
  searchQuery,
  viewPatientDetails,
}: {
  patientList: PatientList[];
  searchQuery: string;
  viewPatientDetails: (staffId: string) => void;
}) {
  const filteredPatient = patientList.filter((patient: PatientList) =>
    patient.firstName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-2 w-full xl:block hidden">
        <div className="sm:flex sm:items-center"></div>
        <div className="mt-2 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Patient Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Patient Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Patient Phone
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Active/InActive
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredPatient.map(patient => (
                    <tr key={patient.id}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {patient.salutation}. {patient.firstName}{' '}
                        {patient.lastName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {patient.email}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {patient.phone}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {patient.isActive ? 'Active' : 'InActive'}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <OutlinedButton
                          variant={ButtonVariant.SECONDARY}
                          type="button"
                          onClick={() => viewPatientDetails(patient.id)}
                          children="View"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="block xl:hidden w-full">
        <ul className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4 bg-gray-50 -mx-8 p-8 ">
          {filteredPatient.map(patient => (
            <div className="flex flex-col justify-between rounded-2xl shadow-md bg-white overflow-hidden">
              <div className="flex flex-row rounded-xl bg-white-300 items-center justify-between p-4">
                <div className="flex flex-col w-11/12">
                  <span className="flex-initial truncate text-md text-black font-bold">
                    {patient.salutation}. {patient.firstName} {patient.lastName}
                  </span>
                  <span className="flex-initial truncate text-secondary-400">
                    {patient.email}
                  </span>
                  <span className="flex-initial text-secondary-400">
                    {patient.phone}
                  </span>
                  <span className="flex-initial text-400 text-black">
                    {patient.isActive ? 'Active' : 'InActive'}
                  </span>
                  <span className="flex-initial text-tertiary-400">
                    <OutlinedButton
                      variant={ButtonVariant.SECONDARY}
                      type="button"
                      onClick={() => viewPatientDetails(patient.id)}
                      children="View"
                    />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </ul>
      </div>
    </>
  );
}

function SearchBar({
  searchQuery,
  onSearch,
}: {
  searchQuery: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <div>
      <input
        type="text"
        value={searchQuery}
        onChange={onSearch}
        placeholder="Search for Patient Name"
        className="w-full px-4 py-2 mb-4 text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500"
      />
    </div>
  );
}

function PatientComponent(props: { entityId: string }) {
  const [patientList, setPatientList] = React.useState<PatientList[] | null>(
    null,
  );
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const axios = useAxios();
  const navigator = useNavigate();
  const [searchQuery, setSearchQuery] = React.useState('');

  const getPatientList = useCallback(async () => {
    try {
      const paramsMap = new Map<string, string>([['entityId', props.entityId]]);
      const filteredEndpoint = replaceParams(
        endpoints.entity.patients,
        paramsMap,
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data['data'];

      if (data != null) {
        const convertedPatientList = data.map((entity: any) =>
          Convert.toPatientList(JSON.stringify(entity)),
        );
        setPatientList(convertedPatientList);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching patient list:', error);
      setError(error.message);
      setLoading(false);
    }
  }, [axios]);

  useEffect(() => {
    getPatientList();
  }, [getPatientList]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value);

  const viewPatientDetails = (patientId: string) => {
    const paramsMap = new Map<string, string>([['patientId', patientId]]);
    navigator(replaceParams(paths.patient.manage.route, paramsMap));
  };

  return (
    <>
      <div className="mt-4 px-4 sm:px-6 lg:px-2">
        <SearchBar searchQuery={searchQuery} onSearch={handleSearch} />
      </div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {(!loading && !error && patientList?.length == 0) ||
        (patientList == null && <p>No patients</p>)}
      {!loading && !error && patientList && (
        <PatientTable
          patientList={patientList}
          searchQuery={searchQuery}
          viewPatientDetails={viewPatientDetails}
        />
      )}
    </>
  );
}

export default PatientComponent;
