// To parse this data:
//
//   import { Convert, PatientProgress } from "./file";
//
//   const patientProgress = Convert.toPatientProgress(json);

export interface PatientProgress {
  exerciseTrackingCountsDaily: ExerciseTrackingCountsDaily[];
  TotalCompletedCount: number;
  TotalPrescribedCount: number;
  TotalNoOfExercises: number;
  PercentProgress: number;
}

export interface ExerciseTrackingCountsDaily {
  Date: Date;
  CompletedCount: number;
  PrescribedCount: number;
  NoOfExercises: number;
  DailyPercentProgress: number;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toPatientProgress(json: string): PatientProgress {
    return JSON.parse(json);
  }

  public static patientProgressToJson(value: PatientProgress): string {
    return JSON.stringify(value);
  }
}
