export default function createInitials(input: string): string {
  // Split the input string into an array of words
  const words = input.split(' ');
  if (words.length < 1) throw new Error('Input must contain at least 1 word');

  // Initialize an array to store the initials
  const initials: string[] = [];

  // Extract initials from the first two words
  for (let i = 0; i < Math.min(words.length, 2); i++) {
    if (words[i]) {
      initials.push(words[i][0].toUpperCase());
    }
  }

  // Join the extracted initials to form the final initials string
  const initialsString = initials.join('');

  return initialsString;
}
