import React from "react";
import { MuscleTightness } from "../type/MuscleTightness";

type MuscleTightnessProps = {
  muscleTightness: MuscleTightness | null;
};

const MuscleTightnessAssessment = (props: MuscleTightnessProps) => {
  return (
    <div>
      {props.muscleTightness?.details?.length === 0 ||
      props.muscleTightness?.details?.every((e) => e.name === "") ? (
        <div></div>
      ) : (
        <div>
          <h3 className="mb-4 font-bold text-tertiary-500 my-4">
            Muscle Tightness
          </h3>
          <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="border px-2 py-1 border-slate-300 text-left">
                  Muscle Name
                </th>
                <th className="border px-2 py-1 border-slate-300 text-left">
                  Grading
                </th>
              </tr>
            </thead>
            <tbody>
              {props.muscleTightness &&
                props.muscleTightness.details?.length > 0 &&
                props.muscleTightness.details.map((e, index) =>
                  e.name !== "" ? (
                    <tr key={index}>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{e.name}</p>
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{e.grading}</p>
                      </td>
                    </tr>
                  ) : (
                    <div></div>
                  )
                )}
            </tbody>
          </table>
          <hr className=" my-12 border-tertiary-500"></hr>
        </div>
      )}
    </div>
  );
};

export default MuscleTightnessAssessment;
