// To parse this data:
//
//   import { Convert } from "./file";
//
//   const whatsappMessageDetails = Convert.toWhatsappMessageDetails(json);

export interface WhatsappMessageDetails {
  id: string;
  entityId: string;
  entityBranchId: string;
  clientId: string;
  clientName: string;
  clientPhoneCode: number;
  clientPhone: number;
  message: string;
  messageSentAt: Date;
  messageType: string;
  audit: Audit;
}

export interface Audit {
  createdBy: string;
  lastUpdatedBy: null;
  createdAt: Date;
  lastUpdatedAt: Date;
}

// Converts JSON strings to/from your types
export class WhatsappMsgConvert {
  public static toWhatsappMessageDetails(
    json: string
  ): WhatsappMessageDetails[] {
    return JSON.parse(json);
  }

  public static whatsappMessageDetailsToJson(
    value: WhatsappMessageDetails[]
  ): string {
    return JSON.stringify(value);
  }
}
