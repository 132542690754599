import useAxios from '@routes/hooks/use-axios';
import { endpoints, replaceParams } from '@utils/axios';
import React, { useContext, useEffect } from 'react';
import {
  Convert,
  MasterAssessmentLedger,
} from '../types/MasterAssessmentLedger';
import { ManagePatientContext } from './ManagePatient';
import { Button, OutlinedButton } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import { Divider } from '@components/divider';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { paths } from '@routes/paths';
import Modal from '../../../components/modal/modal';
import NewMasterAssessment from '@pages/new-master-assessment/NewMasterAssessment';
import { Grid } from 'gridjs-react';
import { _ } from 'gridjs-react';
import { toast } from 'react-toastify';

export default function AssessmentHistory() {
  const [masterAssessmentLedger, setMasterAssessmentLedger] = React.useState<
    MasterAssessmentLedger[] | null
  >(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const axios = useAxios();
  const patientId = useContext(ManagePatientContext);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const getMasterAssessmentLedger = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ['patientId', patientId.patientId],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.assessment.masterAssessmentList,
        paramsMap,
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data['data'];
      if (data != null) {
        const convertedMasterAssessmentLedger = data.map((entity: any) =>
          Convert.toMasterAssessmentLedger(JSON.stringify(entity)),
        );
        setMasterAssessmentLedger(convertedMasterAssessmentLedger);
      } else setMasterAssessmentLedger(null);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching master assessment list:', error);
      setError(error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    getMasterAssessmentLedger();
  }, []);

  const handleDeleteExerciseFromProgram = async (assessmentId: string) => {
    try {
      const paramsMaId = new Map<string, string>([['id', assessmentId]]);
      const response = await axios.delete(
        replaceParams(endpoints.assessment.deleteMasterAssessment, paramsMaId),
      );
      if (response.data.status === 200)
        toast.success(
          response.data.message === 'success'
            ? 'Exercises deleted Successfully'
            : response.data.message,
        );
      getMasterAssessmentLedger();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const navigateToMasterAssessment = (masterAssessmentId: string) => {
    const paramsMap = new Map<string, string>([
      ['patientId', patientId.patientId],
      ['masterAssessmentId', masterAssessmentId],
    ]);
    navigate(
      `${replaceParams(paths.patient.masterAssessment.route, paramsMap)}`,
    );
  };

  const generateGridData = () => {
    const gridData = masterAssessmentLedger?.map((masterAssessment, index) => {
      return [
        index + 1,
        masterAssessment.assessmentDate,
        masterAssessment.entityUserName,
        _(
          <>
            <OutlinedButton
              variant={ButtonVariant.TERTIARY}
              type="button"
              onClick={() =>
                handleDeleteExerciseFromProgram(masterAssessment.id!)
              }
              children="Delete"
            />
            <Button
              variant={ButtonVariant.PRIMARY}
              type="button"
              className="ml-3"
              onClick={() => navigateToMasterAssessment(masterAssessment.id!)}
              children="View"
            />
          </>,
        ),
      ];
    });
    return gridData;
  };

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <NewMasterAssessment handleCloseModal={handleCloseModal} />
      </Modal>
      <div className="sticky top-0 right-0 z-1 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
            Assessment History
          </h2>
        </div>
        <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          <Button
            variant={ButtonVariant.PRIMARY}
            type="button"
            className="ml-3"
            onClick={handleOpenModal}
            children="New Assessment"
          />
        </div>
      </div>

      <Divider />

      <div className="relative mt-4 pb-5 sm:pb-0">
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {(!loading && !error && masterAssessmentLedger?.length == 0) ||
          (masterAssessmentLedger == null && (
            <p className=""> No Previous Assessment History Found</p>
          ))}
        {!loading && !error && masterAssessmentLedger && (
          <Grid
            data={generateGridData}
            columns={[
              'Sr. No.',
              {
                name: 'Assessment Date',
                formatter: (cell: any) => {
                  return moment(cell).format('DD/MM/YYYY');
                },
                sort: (a: any, b: any) => {
                  if (moment(a).isBefore(b)) {
                    return -1;
                  } else if (moment(a).isAfter(b)) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
              },
              'Physiotherapist',
              'Actions',
            ]}
            sort={true}
            pagination={{
              limit: 6,
            }}
            className={{
              table:
                'min-w-full divide-y divide-gray-300 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg',
              th: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase ',
              td: 'px-6 py-4 whitespace-nowrap text-sm text-gray-500',
              tbody: 'bg-white divide-y divide-gray-300',
              pagination:
                'bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6',
              paginationButton:
                'relative inline-flex items-center px-4 py-2 mx-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50',
              paginationButtonCurrent:
                'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-50',
              paginationButtonNext:
                'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
              paginationButtonPrev:
                'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
              sort: 'relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
            }}
          />
        )}
      </div>
    </>
  );
}
