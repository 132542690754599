import React from "react";
import DiagnosisWithId from "../types/DiagnosisWithId";

const DiagnosisMobileCard = (props: {
  diagnosis: DiagnosisWithId;
  diagnosisList: DiagnosisWithId[];
  setDiagnosis: React.Dispatch<React.SetStateAction<DiagnosisWithId[]>>;
  onSubmit: () => Promise<void>;
}) => {
  const handleCheckboxChange = () => {
    const targetIndex = props.diagnosisList.findIndex(
      (item) => item.id === props.diagnosis.id
    );
    if (targetIndex !== -1) {
      const updatedDiagnosisList = [...props.diagnosisList];
      updatedDiagnosisList[targetIndex] = {
        ...updatedDiagnosisList[targetIndex],
        isChecked: !updatedDiagnosisList[targetIndex].isChecked,
      };
      props.setDiagnosis(updatedDiagnosisList);
    }
  };

  const handleObservationChange = (e: string) => {
    const targetIndex = props.diagnosisList.findIndex(
      (item) => item.id === props.diagnosis.id
    );
    if (targetIndex !== -1) {
      props.setDiagnosis((prevDetailsList) =>
        prevDetailsList.map((item, index) =>
          index === targetIndex ? { ...item, value: e } : item
        )
      );
    }
  };

  return (
    <div className="border border-slate-800 p-4 rounded-lg my-2">
      <div className="flex flex-row justify-between">
        <p className="font-medium">{props.diagnosis.name}</p>
        <input
          type="checkbox"
          onChange={handleCheckboxChange}
          onBlur={props.onSubmit}
          checked={props.diagnosis.isChecked}
          id="checkbox"
          className="form-checkbox h-6 w-6 text-blue-500 rounded focus:ring-blue-400 focus:ring-offset-2 focus:ring-opacity-50 "
        />
      </div>
      <div className="flex flex-col mt-4">
        <textarea
          rows={2}
          placeholder="details"
          onBlur={props.onSubmit}
          value={props.diagnosis.value}
          onChange={(e) => handleObservationChange(e.target.value)}
          className="border p-2 rounded-lg border-gray-400 h-full w-full"
        />
      </div>
    </div>
  );
};

export default DiagnosisMobileCard;
