// To parse this data:
//
//   import { Convert, RangeOfMotionAssessment } from "./file";
//
//   const rangeOfMotionAssessment = Convert.toRangeOfMotionAssessment(json);

export interface RangeOfMotionAssessment {
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  hipFlexionLeft: number;
  hipFlexionRight: number;
  hipExtensionLeft: number;
  hipExtensionRight: number;
  hipAbductionLeft: number;
  hipAbductionRight: number;
  hipAdductionLeft: number;
  hipAdductionRight: number;
  hipMedialRotationLeft: number;
  hipMedialRotationRight: number;
  hipLateralRotationLeft: number;
  hipLateralRotationRight: number;
  shoulderFlexionLeft: number;
  shoulderFlexionRight: number;
  shoulderExtensionLeft: number;
  shoulderExtensionRight: number;
  shoulderAbductionLeft: number;
  shoulderAbductionRight: number;
  shoulderAdductionLeft: number;
  shoulderAdductionRight: number;
  shoulderMedialRotationLeft: number;
  shoulderMedialRotationRight: number;
  shoulderLateralRotationLeft: number;
  shoulderLateralRotationRight: number;
  kneeFlexionLeft: number;
  kneeFlexionRight: number;
  kneeExtensionLeft: number;
  kneeExtensionRight: number;
  elbowFlexionLeft: number;
  elbowFlexionRight: number;
  elbowExtensionLeft: number;
  elbowExtensionRight: number;
  ankleFootDorsiFlexionLeft: number;
  ankleFootDorsiFlexionRight: number;
  ankleFootPlantarFlexionLeft: number;
  ankleFootPlantarFlexionRight: number;
  ankleFootInversionLeft: number;
  ankleFootInversionRight: number;
  ankleFootEversionLeft: number;
  ankleFootEversionRight: number;
  forearmPronationLeft: number;
  forearmPronationRight: number;
  forearmSupinationLeft: number;
  forearmSupinationRight: number;
  neckFlexionLeft: number;
  neckFlexionRight: number;
  neckExtensionLeft: number;
  neckExtensionRight: number;
  neckLateroFlexionRLeft: number;
  neckLateroFlexionRRight: number;
  neckLateroFlexionLLeft: number;
  neckLateroFlexionLRight: number;
  neckRotationRLeft: number;
  neckRotationRRight: number;
  neckRotationLLeft: number;
  neckRotationLRight: number;
  wristFlexionLeft: number;
  wristFlexionRight: number;
  wristExtensionLeft: number;
  wristExtensionRight: number;
  wristAbductionLeft: number;
  wristAbductionRight: number;
  wristAdductionLeft: number;
  wristAdductionRight: number;
  trunkGlobalFlexionLeft: number;
  trunkGlobalFlexionRight: number;
  trunkThoracicFlexionLeft: number;
  trunkThoracicFlexionRight: number;
  trunkLumbarFlexionLeft: number;
  trunkLumbarFlexionRight: number;
  trunkGlobalExtensionLeft: number;
  trunkGlobalExtensionRight: number;
  trunkLateroFlexionRLeft: number;
  trunkLateroFlexionRRight: number;
  trunkLateroFlexionLLeft: number;
  trunkLateroFlexionLRight: number;
  trunkRotationRLeft: number;
  trunkRotationRRight: number;
  trunkRotationLLeft: number;
  trunkRotationLRight: number;
  fingersThumboppositionLeft: number;
  fingersThumboppositionRight: number;
  fingersMPFlexionRight: number;
  fingersMPFlexionLeft: number;
  fingersMPExtensionLeft: number;
  fingersMPExtensionRight: number;
  fingersIPFlexionLeft: number;
  fingersIPFlexionRight: number;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toRangeOfMotionAssessment(
    json: string,
  ): RangeOfMotionAssessment {
    return JSON.parse(json);
  }

  public static rangeOfMotionAssessmentToJson(
    value: RangeOfMotionAssessment,
  ): string {
    return JSON.stringify(value);
  }
}
