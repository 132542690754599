// components
//
import { AuthContext } from './auth-context';
import LoadingScreen from '@components/loading/loading';
import Maintenance from '@components/maintenance';

import React from 'react';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthConsumer({ children }: Props) {
  return (
    <AuthContext.Consumer>
      {(auth) =>
        auth.loading ? <LoadingScreen /> : auth.isUnderMaintenance ? <Maintenance /> : children
      }
    </AuthContext.Consumer>
  );
}
