import { Button } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import { ValidationError } from '@components/hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { toast } from 'react-toastify';
import { endpoints, replaceParams } from '@utils/axios';
import { Convert, MedicalHistoryDetails } from '../types/MedicalHistoryDetails';
import useAxios from '@routes/hooks/use-axios';
import { ManagePatientContext } from './ManagePatient';
import Applabel from '@components/hook-form/applabel';

export const MedicalHistorySchema = z
  .object({
    anyHealthCondition: z.boolean(),
    healthConditionDetails: z.string(),
    anySurgery: z.boolean(),
    surgeryDetails: z.string(),
    anyPastTreatment: z.boolean(),
    pastTreatmentDetails: z.string(),
  })
  .superRefine((schema, ctx) => {
    if (schema.anyHealthCondition === true && !schema.healthConditionDetails) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Health Condition is required',
        path: ['healthConditionDetails'],
      });
    } else if (
      schema.anyPastTreatment === true &&
      !schema.pastTreatmentDetails
    ) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Past Treatment Detail is required',
        path: ['pastTreatmentDetails'],
      });
    } else if (schema.anySurgery === true && !schema.surgeryDetails) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Surgery Detail is required',
        path: ['surgeryDetails'],
      });
    }
  });
export type MedicalHistory = z.infer<typeof MedicalHistorySchema>;

export default function MedicalHistory(props: { patientId: string }) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<MedicalHistory>({ resolver: zodResolver(MedicalHistorySchema) });

  const [history, setHistory] = React.useState<MedicalHistoryDetails | null>(
    null,
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const [healthCondition, setHealthCondition] = useState(false);
  const [pastTreatment, setPastTreatment] = useState(false);
  const [surgery, setSurgery] = useState(false);
  const [formData, setFormData] = useState({});
  const [apiError, setApiError] = React.useState('');

  const patientId = useContext(ManagePatientContext);

  const initialize = () => {
    // set default values for the form
    return {
      anyHealthCondition: history?.anyHealthCondition ?? false,
      anyPastTreatment: history?.anyPastTreatment ?? false,
      anySurgery: history?.anySurgery ?? false,
      healthConditionDetails: history?.healthConditionDetails ?? '',
      pastTreatmentDetails: history?.pastTreatmentDetails ?? '',
      surgeryDetails: history?.surgeryDetails ?? '',
    };
  };

  const handleToggleEdit = () => {
    setIsDisabled(prevState => !prevState);
  };

  useEffect(() => {
    setIsDisabled(false);
    const getMedicalData = async () => {
      try {
        const paramsMap = new Map<string, string>([
          ['patientId', patientId.patientId],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.medicalHistory.view,
          paramsMap,
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data['data'];
        // console the medical history
        const convertedMedicalHistory = Convert.toMedicalHistoryDetails(
          JSON.stringify(data.medicalHistory),
        );
        setHistory(convertedMedicalHistory);
        setHealthCondition(convertedMedicalHistory.anyHealthCondition ?? false);
        setPastTreatment(convertedMedicalHistory.anyPastTreatment ?? false);
        setSurgery(convertedMedicalHistory.anySurgery ?? false);

        reset({ ...convertedMedicalHistory });
        setIsDisabled(false);

        // setLoading(false);
      } catch (error) {
        console.error('Error fetching entity data:', error);

        // setLoading(false);
      }
    };
    getMedicalData();
  }, []);

  const axios = useAxios();
  const onSubmit: SubmitHandler<MedicalHistory> = async data => {
    try {
      MedicalHistorySchema.parse(data);
      const paramsMap = new Map<string, string>([
        ['patientId', patientId.patientId],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.medicalHistory.updateMedicalHistory,
        paramsMap,
      );
      data.anyHealthCondition = healthCondition;
      data.anyPastTreatment = pastTreatment;
      data.anySurgery = surgery;
      const response = await axios.put(filteredEndpoint, data);
      toast.success('Medical history updated successfully');

      setIsDisabled(false);
      //   navigateToMasterAssessment(response.data['data']);
    } catch (error) {
      console.error('Error submitting form:', error);
      setApiError(error.response.data['data'].toString());
    }
  };

  return (
    <div className="grid-cols-1 gap-x-8 gap-y-8 pt-2 md:grid-cols-3 bg-grey-200 ">
      <div className="sticky top-0 right-0 z-1 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
            Medical History
          </h2>
        </div>
        <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          <Button
            variant={ButtonVariant.PRIMARY}
            type="button"
            className="ml-3"
            onClick={isDisabled ? handleSubmit(onSubmit) : handleToggleEdit}
          >
            {isDisabled ? 'Save' : 'Edit'}
          </Button>
        </div>
      </div>
      <form>
        <hr className="my-2" />
        <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
          <div className="flex flex-col grow">
            <span className="flex-none font-medium mb-0">
              Pre-existing Health Conditions
            </span>
            <span className="text-gray-500">
              Does patient have any pre-existing health conditions?
            </span>
          </div>
          <div className="flex grow place-content-end">
            <select
              value={healthCondition ? 'true' : 'false'}
              className="rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              disabled={!isDisabled}
              onChange={e => {
                setHealthCondition(e.target.value === 'true' ? true : false),
                  setValue(
                    'anyHealthCondition',
                    e.target.value === 'true' ? true : false,
                  );
              }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>
        {healthCondition && (
          <>
            <hr className="my-2" />
            <div>
              <Applabel label="Specify the particulars" mandatory />
              <textarea
                {...register('healthConditionDetails')}
                disabled={!isDisabled}
                placeholder="Enter the details"
                value={watch('healthConditionDetails')}
                rows={4}
                className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
              />
              {errors.healthConditionDetails && (
                <ValidationError
                  message={
                    errors.healthConditionDetails?.message?.toString() ?? ''
                  }
                />
              )}
            </div>
          </>
        )}

        <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
          <div className="flex flex-col grow">
            <span className="flex-none font-medium mb-0">Past Treatments</span>
            <span className="text-gray-500">
              Does patient have any past treatments?
            </span>
          </div>
          <div className="flex grow place-content-end">
            <select
              value={pastTreatment ? 'true' : 'false'}
              className="rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              disabled={!isDisabled}
              onChange={e => {
                setPastTreatment(e.target.value === 'true' ? true : false),
                  setValue(
                    'anyPastTreatment',
                    e.target.value === 'true' ? true : false,
                  );
              }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>

        {pastTreatment && (
          <>
            <hr className="my-2" />
            <div>
              <Applabel label="Specify the particulars" mandatory />
              <textarea
                {...register('pastTreatmentDetails')}
                disabled={!isDisabled}
                placeholder="Enter the details"
                value={watch('pastTreatmentDetails')}
                rows={4}
                className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
              />
              {errors.pastTreatmentDetails && (
                <ValidationError
                  message={
                    errors.pastTreatmentDetails?.message?.toString() ?? ''
                  }
                />
              )}
            </div>
          </>
        )}
        <div className="flex flex-row md:flex-row w-full mt-4 md:space-x-4">
          <div className="flex flex-col grow">
            <span className="flex-none font-medium mb-0">Surgeries</span>
            <span className="text-gray-500">
              Does patient have any surgeries?
            </span>
          </div>

          <div className="flex grow place-content-end">
            <select
              value={surgery ? 'true' : 'false'}
              className="rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              disabled={!isDisabled}
              onChange={e => {
                setSurgery(e.target.value == 'true' ? true : false),
                  setValue(
                    'anySurgery',
                    e.target.value === 'true' ? true : false,
                  );
              }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>
        {surgery && (
          <>
            <hr className="my-2" />
            <div>
              <Applabel label="Specify the particulars" mandatory />
              <textarea
                {...register('surgeryDetails')}
                disabled={!isDisabled}
                placeholder="Enter the details"
                value={watch('surgeryDetails')}
                rows={4}
                className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
              />
              {errors.surgeryDetails && (
                <ValidationError
                  message={errors.surgeryDetails?.message?.toString() ?? ''}
                />
              )}
            </div>
          </>
        )}
      </form>
    </div>
  );
}
