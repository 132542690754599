import React from "react";
import { Vitals } from "../type/VitalAssessment";

type VitalsProps = {
  vitalAssessment: Vitals | null;
};

const VitalsAssessment = (props: VitalsProps) => {
  return (
    <div>
      {props.vitalAssessment !== null ? (
        <div>
          <h3 className="mb-4 font-bold text-tertiary-500">Vitals</h3>
          <div className="flex flex-row border-2 border-tertiary-500">
            <div className="flex flex-col w-1/2 bg-tertiary-100  font-medium">
              <span className="w-full py-4 border-b-2 border-r-2 border-tertiary-500">
                <p className="px-6">Body Tempreature</p>
              </span>
              <span className="w-full py-4 border-b-2 border-r-2 border-tertiary-500">
                <p className="px-6">BMI</p>
              </span>
              <span className="w-full py-4 border-b-2 border-r-2 border-tertiary-500">
                <p className="px-6">SpO2</p>
              </span>
              <span className="w-full py-4 border-b-2 border-r-2 border-tertiary-500">
                <p className="px-6">Blood Pressure - Systolic</p>
              </span>
              <span className="w-full py-4 border-b-2 border-r-2 border-tertiary-500">
                <p className="px-6">Blood Pressure - Diastolic</p>
              </span>
              <span className="w-full py-4 border-b-2 border-r-2 border-tertiary-500">
                <p className="px-6">Pulse Rate</p>
              </span>
              <span className="w-full py-4 border-b-2 border-r-2 border-tertiary-500">
                <p className="px-6">Respiratory Rate</p>
              </span>
              <span className="w-full py-4 border-b-2 border-r-2 border-tertiary-500">
                <p className="px-6">Height</p>
              </span>
              <span className="w-full py-4 border-r-2 border-tertiary-500">
                <p className="px-6">Weight</p>
              </span>
            </div>
            <div className="flex flex-col w-1/2">
              <span className="w-full py-4 border-b-2 border-tertiary-500">
                <p className="px-6">{props.vitalAssessment?.bodyTemperature}</p>
              </span>
              <span className="w-full py-4 border-b-2 border-tertiary-500">
                <p className="px-6">{props.vitalAssessment?.bmi} </p>
              </span>
              <span className="w-full py-4 border-b-2 border-tertiary-500">
                <p className="px-6">
                  {props.vitalAssessment?.oxygenPercentage}
                </p>
              </span>
              <span className="w-full py-4 border-b-2 border-tertiary-500">
                <p className="px-6">
                  {props.vitalAssessment?.bloodPressureSyst}
                </p>
              </span>
              <span className="w-full py-4 border-b-2 border-tertiary-500">
                <p className="px-6">
                  {props.vitalAssessment?.bloodPressureDias}
                </p>
              </span>
              <span className="w-full py-4 border-b-2 border-tertiary-500">
                <p className="px-6">{props.vitalAssessment?.pulseRate}</p>
              </span>
              <span className="w-full py-4 border-b-2 border-tertiary-500">
                <p className="px-6">{props.vitalAssessment?.respiratoryRate}</p>
              </span>
              <span className="w-full py-4 border-b-2 border-tertiary-500">
                <p className="px-6">{props.vitalAssessment?.height} cms.</p>
              </span>
              <span className="w-full py-4">
                <p className="px-6">{props.vitalAssessment?.weight} Kgs.</p>
              </span>
            </div>
          </div>
          <hr className=" my-12 border-tertiary-500"></hr>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default VitalsAssessment;
