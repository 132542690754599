// To parse this data:
//
//   import { Convert, AddNewPrescriptionDetails } from "./file";
//
//   const addNewPrescriptionDetails = Convert.toAddNewPrescriptionDetails(json);

export type PrescriptionDetails = {
  id: string;
  advice: string;
  precautions: string;
  type: string;
  prescribedThrapeuticProcedures?: string[];
  shortTermGoals: string;
  midTermGoals: string;
  testadviced: string;
  longTermGoals: string;
  sessionCount: number;
  sessionFrequency: number;
  sessionFrequencyUnit: string;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toAddNewPrescriptionDetails(json: string): PrescriptionDetails {
    return JSON.parse(json);
  }

  public static addNewPrescriptionDetailsToJson(
    value: PrescriptionDetails,
  ): string {
    return JSON.stringify(value);
  }
}
