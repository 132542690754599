export type BillingDetails = {
  id?: string;
  entityId?: string;
  entityBranchId?: string;
  clientId?: string;
  clientName?: string;
  invoiceNumber: string;
  invoiceDate: Date;
  dueDate: Date;
  items: Item[];
  totalAmount: number;
  status: string;
  billForAppointments: boolean;
  totalPaid: number;
  tax: number;
  outStandingAmount: number;
  paymentDetails: PaymentDetails[];
};


export type PaymentDetails = {
  amountPaid: number;
  paymentDate: Date;
  paymentMode: string;
  note: string;
  transactionID: string;
  audit: Audit;
};

export type Audit =  {
  createdBy: string,
  lastUpdatedBy: Date,
  createdAt: string,
  lastUpdatedAt: Date,
}

export type AppointmentDetail = {
  _id: string;
  type: string;
  date: string;
  billedAmount: number;
};

export type Item = {
  itemId: string;
  description?: string;
  quantity?: number;
  amount?: number;
  type?: string;
  appointments: AppointmentDetail[];
};

export class ConvertBilling {
  public static toBilling(json: string): BillingDetails {
    return JSON.parse(json);
  }

  public static billingToJson(value: BillingDetails): string {
    return JSON.stringify(value);
  }
}