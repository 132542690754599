import * as React from 'react';
import Select from 'react-select';
import {
  CellBase,
  DataEditorComponent,
  DataViewerComponent,
} from 'react-spreadsheet';

type Value = string | undefined;
type Cell = CellBase<Value> & {
  value: Value;
};

const YESNO_OPTIONS = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

export const SelectViewYesNo: DataViewerComponent<Cell> = ({ cell }) => {
  const option = React.useMemo(
    () => cell && YESNO_OPTIONS.find(option => option.value === cell.value),
    [cell],
  );
  return <Select value={option} options={YESNO_OPTIONS} isDisabled />;
};

export const SelectEditYesNo: DataEditorComponent<Cell> = ({
  cell,
  onChange,
  exitEditMode,
}) => {
  const handleChange = React.useCallback(
    (selection: any) => {
      onChange({ ...cell, value: selection ? selection.value : null });
    },
    [cell, onChange],
  );
  const option = React.useMemo(
    () => cell && YESNO_OPTIONS.find(option => option.value === cell.value),
    [cell],
  );
  return (
    <Select
      value={option}
      onChange={handleChange}
      options={YESNO_OPTIONS}
      autoFocus
      defaultMenuIsOpen
      onMenuClose={() => exitEditMode()}
    />
  );
};

const LRBN_OPTIONS = [
  { value: 'left', label: 'Left' },
  { value: 'right', label: 'Right' },
  { value: 'both', label: 'Both' },
  { value: 'none', label: 'None' },
];

export const SelectViewLRBN: DataViewerComponent<Cell> = ({ cell }) => {
  const option = React.useMemo(
    () => cell && LRBN_OPTIONS.find(option => option.value === cell.value),
    [cell],
  );
  return <Select value={option} options={LRBN_OPTIONS} isDisabled />;
};

export const SelectEditLRBN: DataEditorComponent<Cell> = ({
  cell,
  onChange,
  exitEditMode,
}) => {
  const handleChange = React.useCallback(
    (selection: any) => {
      onChange({ ...cell, value: selection ? selection.value : null });
    },
    [cell, onChange],
  );
  const option = React.useMemo(
    () => cell && LRBN_OPTIONS.find(option => option.value === cell.value),
    [cell],
  );
  return (
    <Select
      value={option}
      onChange={handleChange}
      options={LRBN_OPTIONS}
      autoFocus
      defaultMenuIsOpen
      onMenuClose={() => exitEditMode()}
    />
  );
};

const NGP_OPTIONS = [
  { value: 'normal', label: 'Normal' },
  { value: 'good', label: 'Good' },
  { value: 'poor', label: 'Poor' },
];

export const SelectViewNGP: DataViewerComponent<Cell> = ({ cell }) => {
  const option = React.useMemo(
    () => cell && NGP_OPTIONS.find(option => option.value === cell.value),
    [cell],
  );
  return <Select value={option} options={NGP_OPTIONS} isDisabled />;
};

export const SelectEditNGP: DataEditorComponent<Cell> = ({
  cell,
  onChange,
  exitEditMode,
}) => {
  const handleChange = React.useCallback(
    (selection: any) => {
      onChange({ ...cell, value: selection ? selection.value : null });
    },
    [cell, onChange],
  );
  const option = React.useMemo(
    () => cell && NGP_OPTIONS.find(option => option.value === cell.value),
    [cell],
  );
  return (
    <Select
      value={option}
      onChange={handleChange}
      options={NGP_OPTIONS}
      autoFocus
      defaultMenuIsOpen
      onMenuClose={() => exitEditMode()}
    />
  );
};
