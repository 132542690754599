// To parse this data:
//
//   import { Convert, Appointments } from "./file";
//
//   const appointments = Convert.toAppointments(json);

export interface Appointments {
  id: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  entityUserId: string;
  clientName: string;
  email: string;
  clientPhone: number;
  entityUserName: string;
  appointmentType: string;
  date: string;
  apptdate: Date;
  startTime: string;
  endTime: string;
  startTimeMinutes: number;
  endTimeMinutes: number;
  notes: string;
  appointmentStatus: string;
  statusChangeComment: string;
  paymentStatus: string;
  meetingLink: string;
  meetLink: string;
  isOnline: boolean;
  createdAt: Date;
  updatedAt: Date;
  modeOfConsultation: string;
  billAmount: number;
  payoutamount: number;
  checkInTime: Date;
  sessionStartTime: Date;
  sessionCompleteTime: Date;
}

// Converts JSON strings to/from your types
export class AppointmentConvert {
  public static toAppointments(json: string): Appointments {
    return JSON.parse(json);
  }

  public static appointmentsToJson(value: Appointments): string {
    return JSON.stringify(value);
  }
}
