// To parse this data:
//
//   import { Convert, Patient } from "./file";
//
//   const patient = Convert.toPatient(json);

export interface Patient {
  id: string;
  entityId: string;
  entityBranchId: string;
  salutation: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  phone: number;
  phoneCode: number;
  profilePictureUrl: string;
  profilePictureId: string;
  referringHospital: string;
  referringUser: string;
  otherReferral: string;
  address: Address;
}

export interface Address {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toPatient(json: string): Patient {
    return JSON.parse(json);
  }

  public static patientToJson(value: Patient): string {
    return JSON.stringify(value);
  }
}
