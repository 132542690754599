import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

interface Details {
  id: string;
  description: string;
  service: string;
  quantity: number;
  price: number;
  total: number;
}

function DetailsCard(props: {
  detail: Details;
  detailsList: Details[];
  setDetailsList: React.Dispatch<React.SetStateAction<Details[]>>;
}) {
  const handleRemove = (id: string) => {
    const updatedDetailsList = props.detailsList.filter(
      (item) => item.id !== id
    );

    props.setDetailsList(updatedDetailsList);
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetIndex = props.detailsList.findIndex(
      (item) => item.id === props.detail.id
    );

    if (targetIndex !== -1) {
      props.setDetailsList((prevDetailsList) =>
        prevDetailsList.map((item, index) =>
          index === targetIndex
            ? { ...item, quantity: Number(e.target.value) }
            : item
        )
      );
    }
    props.setDetailsList((updatedDetailsList) => {
      calculateTotals(updatedDetailsList);
      return updatedDetailsList;
    });
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetIndex = props.detailsList.findIndex(
      (item) => item.id === props.detail.id
    );

    if (targetIndex !== -1) {
      props.setDetailsList((prevDetailsList) =>
        prevDetailsList.map((item, index) =>
          index === targetIndex
            ? { ...item, price: Number(e.target.value) }
            : item
        )
      );
    }
    props.setDetailsList((updatedDetailsList) => {
      calculateTotals(updatedDetailsList);
      return updatedDetailsList;
    });
  };

  const calculateTotals = (updatedDetailsList: Details[]) => {
    const updatedList = updatedDetailsList.map((item) => ({
      ...item,
      total: item.quantity * item.price,
    }));
    props.setDetailsList(updatedList);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetIndex = props.detailsList.findIndex(
      (item) => item.id === props.detail.id
    );

    if (targetIndex !== -1) {
      props.setDetailsList((prevDetailsList) =>
        prevDetailsList.map((item, index) =>
          index === targetIndex
            ? { ...item, description: e.target.value }
            : item
        )
      );
    }
  };

  const handleServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetIndex = props.detailsList.findIndex(
      (item) => item.id === props.detail.id
    );

    if (targetIndex !== -1) {
      props.setDetailsList((prevDetailsList) =>
        prevDetailsList.map((item, index) =>
          index === targetIndex ? { ...item, service: e.target.value } : item
        )
      );
    }
  };

  return (
    <div className="flex flex-col border-b-2 shadow mx-2">
      <div className="flex flex-col md:flex-row w-full md:justify-between p-4">
        <div className="flex flex-col md:flex-row md:justify-between w-full md:space-x-2">
          <div className="flex flex-col md:w-1/5">
            <label htmlFor="">Description</label>
            <input
              type="text"
              placeholder="Description"
              onChange={handleDescriptionChange}
              className="border p-2 rounded-lg border-gray-400"
            />
          </div>
          <div className="flex flex-col md:w-1/5">
            <label htmlFor="">Service</label>
            <input
              type="text"
              placeholder="Service"
              onChange={handleServiceChange}
              className="border p-2 rounded-lg border-gray-400"
            />
          </div>
          <div className="flex flex-col md:w-1/6">
            <label htmlFor="">Quantity</label>
            <input
              type="number"
              placeholder="Quantity"
              onChange={handleQuantityChange}
              className="border p-2 rounded-lg border-gray-400"
            />
          </div>
          <div className="flex flex-col md:w-1/6">
            <label htmlFor="">Price</label>
            <input
              type="number"
              placeholder="Price"
              onChange={handlePriceChange}
              className="border p-2 rounded-lg border-gray-400"
            />
          </div>
          <div className="flex flex-col md:w-1/6">
            <label htmlFor="">Total</label>
            <input
              disabled={true}
              type="number"
              placeholder="Total"
              value={props.detail.total}
              className="border p-2 rounded-lg border-gray-400"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full justify-end py-1 pr-4">
        <button
          className="custom-button text-red-500"
          type="button"
          onClick={() => {
            handleRemove(props.detail.id);
          }}
        >
          <span className="icon-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              className="component-iconify MuiBox-root css-1t9pz9x iconify iconify--solar"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M3 6.386c0-.484.345-.877.771-.877h2.665c.529-.016.996-.399 1.176-.965l.03-.1l.115-.391c.07-.24.131-.45.217-.637c.338-.739.964-1.252 1.687-1.383c.184-.033.378-.033.6-.033h3.478c.223 0 .417 0 .6.033c.723.131 1.35.644 1.687 1.383c.086.187.147.396.218.637l.114.391l.03.1c.18.566.74.95 1.27.965h2.57c.427 0 .772.393.772.877s-.345.877-.771.877H3.77c-.425 0-.77-.393-.77-.877"
              ></path>
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M11.596 22h.808c2.783 0 4.174 0 5.08-.886c.904-.886.996-2.339 1.181-5.245l.267-4.188c.1-1.577.15-2.366-.303-2.865c-.454-.5-1.22-.5-2.753-.5H8.124c-1.533 0-2.3 0-2.753.5c-.454.5-.404 1.288-.303 2.865l.267 4.188c.185 2.906.277 4.36 1.182 5.245c.905.886 2.296.886 5.079.886m-1.35-9.811c-.04-.434-.408-.75-.82-.707c-.413.043-.713.43-.672.864l.5 5.263c.04.434.408.75.82.707c.413-.043.713-.43.672-.864zm4.329-.707c.412.043.713.43.671.864l-.5 5.263c-.04.434-.409.75-.82.707c-.413-.043-.713-.43-.672-.864l.5-5.263c.04-.434.409-.75.82-.707"
                clip-rule="evenodd"
              ></path>
            </svg>
          </span>
          Remove
          <span className="ripple"></span>
        </button>
      </div>
    </div>
  );
}

function AddBillPage() {
  const [detailsList, setDetailsList] = React.useState<Details[]>([
    {
      id: uuidv4(),
      description: "",
      price: 0,
      quantity: 0,
      service: "",
      total: 0,
    },
  ]);
  const [taxesPercentage, setTaxesPercentage] = React.useState<number>(0);
  const [taxAmount, setTaxAmount] = React.useState<number>(0);

  const [subTotal, setSubTotal] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);

  useEffect(() => {
    setSubTotal(detailsList.reduce((acc, detail) => acc + detail.total, 0));
  }, [detailsList]);

  useEffect(() => {
    const taxesAmount = (subTotal * taxesPercentage) / 100;
    setTotal(subTotal + taxesAmount);
    setTaxAmount(taxesAmount)
  }, [taxesPercentage, subTotal]);

  return (
    <div className="flex justify-center w-full">
      <div className="flex flex-col m-3 border border-gray-400 shadow rounded-lg bg-white md:w-4/5">
        <div className="flex flex-col w-full md:flex-row md:justify-evenly p-4 md:divide-x-2">
          <div className="flex-shrink-0 md:w-1/2 p-4">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between">
                <div className="font-bold text-gray-400 text-lg">From:</div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  className="component-iconify MuiBox-root css-1t9pz9x iconify iconify--solar w-6"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="m11.4 18.161l7.396-7.396a10.289 10.289 0 0 1-3.326-2.234a10.29 10.29 0 0 1-2.235-3.327L5.839 12.6c-.577.577-.866.866-1.114 1.184a6.556 6.556 0 0 0-.749 1.211c-.173.364-.302.752-.56 1.526l-1.362 4.083a1.06 1.06 0 0 0 1.342 1.342l4.083-1.362c.775-.258 1.162-.387 1.526-.56c.43-.205.836-.456 1.211-.749c.318-.248.607-.537 1.184-1.114m9.448-9.448a3.932 3.932 0 0 0-5.561-5.561l-.887.887l.038.111a8.754 8.754 0 0 0 2.092 3.32a8.754 8.754 0 0 0 3.431 2.13z"
                  ></path>
                </svg>
              </div>
              <div className="flex-shrink-0 font-bold mt-2">Jayvion Simon</div>
              <div className="flex-shrink-0">
                19034 Verna Unions Apt. 164 - Honolulu, RI / 87535
              </div>
              <div className="flex-shrink-0">365-374-4961</div>
            </div>
          </div>

          <div className="flex-shrink-0 md:w-1/2 p-4">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between">
                <div className="font-bold text-gray-400 text-lg">To:</div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  className="component-iconify MuiBox-root css-1t9pz9x iconify iconify--solar w-6"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="m11.4 18.161l7.396-7.396a10.289 10.289 0 0 1-3.326-2.234a10.29 10.29 0 0 1-2.235-3.327L5.839 12.6c-.577.577-.866.866-1.114 1.184a6.556 6.556 0 0 0-.749 1.211c-.173.364-.302.752-.56 1.526l-1.362 4.083a1.06 1.06 0 0 0 1.342 1.342l4.083-1.362c.775-.258 1.162-.387 1.526-.56c.43-.205.836-.456 1.211-.749c.318-.248.607-.537 1.184-1.114m9.448-9.448a3.932 3.932 0 0 0-5.561-5.561l-.887.887l.038.111a8.754 8.754 0 0 0 2.092 3.32a8.754 8.754 0 0 0 3.431 2.13z"
                  ></path>
                </svg>
              </div>
              <div className="flex-shrink-0 font-bold mt-2">Lucian Obrien</div>
              <div className="flex-shrink-0">
                1147 Rohan Drive Suite 819 - Burlington, VT / 82021
              </div>
              <div className="flex-shrink-0">904-966-2836</div>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:justify-evenly md:space-x-1 p-4 bg-gray-200">
          <div className="flex flex-col md:w-1/4">
            <label>Invoice Number</label>
            <input
              type="text"
              className="border p-2 rounded-lg border-gray-400"
              placeholder="Invoice number"
            />
          </div>
          <div className="flex flex-col md:w-1/4">
            <label htmlFor="">Status</label>
            <select className="border p-2 rounded-lg border-gray-400 ">
              <option value="Status1">Status 1</option>
              <option value="Status2">Status 2</option>
              <option value="Status3">Status 3</option>
              <option value="Status4">Status 4</option>
            </select>
          </div>
          <div className="flex flex-col md:w-1/4">
            <label htmlFor="">Billing Date</label>
            <input
              type="date"
              className="border p-2 rounded-lg border-gray-400 "
            />
          </div>
        </div>

        <div className="font-bold text-gray-400 text-lg p-4">Details:</div>
        <div className="w-full">
          {detailsList.map((detail) => (
            <DetailsCard
              key={detail.id}
              detail={detail}
              detailsList={detailsList}
              setDetailsList={setDetailsList}
            />
          ))}
        </div>
        <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" />
        <div className="flex flex-col">
          <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 w-full justify-between p-4">
            <button
              className="custom-button text-lg"
              type="button"
              onClick={() => {
                setDetailsList((all) => [
                  ...all,
                  {
                    id: uuidv4(),
                    description: "",
                    price: 0,
                    quantity: 0,
                    service: "",
                    total: 0,
                  },
                ]);
              }}
            >
              <span className="flex items-center">
                <span className="icon-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    className="component-iconify MuiBox-root css-1t9pz9x iconify iconify--solar"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      className="text-success-500"
                      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                    ></path>
                  </svg>
                </span>
                <span className=" ml-1 text-md text-success-500">Add Item</span>
              </span>
            </button>
            <div className="flex flex-col space-y-2 md:flex-row md:space-x-1 md:space-y-0">
              <input
                type="number"
                className="border p-2 rounded-lg border-gray-400"
                placeholder="Taxes%"
                onChange={(e) => setTaxesPercentage(Number(e.target.value))}
              />
            </div>
          </div>
          <div className="flex flex-col items-end w-full p-6 space-y-3">
            <div className="flex flex-row w-2/5 justify-between">
              <div className="flex text-gray-500 text-base w-1/2 justify-end">
                Subtotal
              </div>
              <div className="text-gray-500 text-base">₹{subTotal}</div>
            </div>

            <div className="flex flex-row w-2/5 justify-between">
              <div className="flex text-gray-500 text-base w-1/2 justify-end">
                Taxes1
              </div>
              <div className="text-gray-500 text-base">₹{taxAmount} ({taxesPercentage}%)</div>
            </div>
            <div className="flex flex-row w-2/5 justify-between mb-2">
              <div className="flex text-black font-bold w-1/2 justify-end">
                Total
              </div>
              <div className="text-black text-base">₹{total}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBillPage;
