// To parse this data:
//
//   import { Convert, VitalAssessment } from "./file";
//
//   const vitalAssessment = Convert.toVitalAssessment(json);

export interface VitalAssessment {
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  bodyTemperature: number;
  bmi: number;
  oxygenPercentage: number;
  bloodPressureSyst: number;
  bloodPressureDias: number;
  pulseRate: number;
  respiratoryRate: number;
  height: number;
  weight: number;
  other: string;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toVitalAssessment(json: string): VitalAssessment {
    return JSON.parse(json);
  }

  public static vitalAssessmentToJson(value: VitalAssessment): string {
    return JSON.stringify(value);
  }
}
