import { AxiosResponse } from 'axios';

export type ActionMapType<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type AuthTokensType = null | Record<string, any>;

export type AuthStateType = {
  status?: string;
  loading: boolean;
  tokens?: AuthTokensType;
  // user: AuthUserType;
};

// ----------------------------------------------------------------------

type CanRemove = {
  login?: (email: string, password: string) => Promise<void>;
  loginWithGoogle?: (response: AuthTokensType) => Promise<void>;
  forgotPassword?: (email: string) => Promise<void>;
  newPassword?: (
    email: string,
    code: string,
    password: string,
  ) => Promise<void>;
};

export type JWTContextType = CanRemove & {
  // user: AuthUserType;
  loading: boolean;
  authenticated: boolean;
  unauthenticated: boolean;
  isUnderMaintenance: boolean;
  login: (email: string, password: string) => Promise<void>;
  loginWithGoogle: (response: AuthTokensType) => Promise<void>;
  logout: () => Promise<void>;
  reset: () => Promise<void>;
};

// ----------------------------------------------------------------------

export type AuthContextType = CanRemove & {
  // user: AuthUserType;
  loading: boolean;
  authenticated: boolean;
  unauthenticated: boolean;
};

// To parse this data:
//
//   import { Convert, TokenDecodedType } from "./file";
//
//   const tokenDecodedType = Convert.toTokenDecodedType(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface TokenDecodedType {
  user_id: string;
  name: string;
  entity_id: string;
  branch_id: string;
  phone_number: number;
  phone_code: number;
  user_role: string;
  email: string;
  username: string;
  is_active: boolean;
  iss: string;
  sub: string;
  aud: string[];
  exp: number;
  iat: number;
  jti: string;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class TokenDecodedTypeConvert {
  public static toTokenDecodedType(json: string): TokenDecodedType {
    return cast(JSON.parse(json), r('TokenDecodedType'));
  }

  public static tokenDecodedTypeToJson(value: TokenDecodedType): string {
    return JSON.stringify(uncast(value, r('TokenDecodedType')), null, 2);
  }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ''): never {
  const prettyTyp = prettyTypeName(typ);
  const parentText = parent ? ` on ${parent}` : '';
  const keyText = key ? ` for key "${key}"` : '';
  throw Error(
    `Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(
      val,
    )}`,
  );
}

function prettyTypeName(typ: any): string {
  if (Array.isArray(typ)) {
    if (typ.length === 2 && typ[0] === undefined) {
      return `an optional ${prettyTypeName(typ[1])}`;
    } else {
      return `one of [${typ
        .map(a => {
          return prettyTypeName(a);
        })
        .join(', ')}]`;
    }
  } else if (typeof typ === 'object' && typ.literal !== undefined) {
    return typ.literal;
  } else {
    return typeof typ;
  }
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(
  val: any,
  typ: any,
  getProps: any,
  key: any = '',
  parent: any = '',
): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val, key, parent);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) {}
    }
    return invalidValue(typs, val, key, parent);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(
      cases.map(a => {
        return l(a);
      }),
      val,
      key,
      parent,
    );
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue(l('array'), val, key, parent);
    return val.map(el => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue(l('Date'), val, key, parent);
    }
    return d;
  }

  function transformObject(
    props: { [k: string]: any },
    additional: any,
    val: any,
  ): any {
    if (val === null || typeof val !== 'object' || Array.isArray(val)) {
      return invalidValue(l(ref || 'object'), val, key, parent);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach(key => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key)
        ? val[key]
        : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, key, ref);
    });
    Object.getOwnPropertyNames(val).forEach(key => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key, ref);
      }
    });
    return result;
  }

  if (typ === 'any') return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val, key, parent);
  }
  if (typ === false) return invalidValue(typ, val, key, parent);
  let ref: any = undefined;
  while (typeof typ === 'object' && typ.ref !== undefined) {
    ref = typ.ref;
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === 'object') {
    return typ.hasOwnProperty('unionMembers')
      ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty('arrayItems')
      ? transformArray(typ.arrayItems, val)
      : typ.hasOwnProperty('props')
      ? transformObject(getProps(typ), typ.additional, val)
      : invalidValue(typ, val, key, parent);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== 'number') return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function l(typ: any) {
  return { literal: typ };
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function m(additional: any) {
  return { props: [], additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  TokenDecodedType: o(
    [
      { json: 'user_id', js: 'user_id', typ: '' },
      { json: 'name', js: 'name', typ: '' },
      { json: 'entity_id', js: 'entity_id', typ: '' },
      { json: 'branch_id', js: 'branch_id', typ: '' },
      { json: 'phone_number', js: 'phone_number', typ: 0 },
      { json: 'phone_code', js: 'phone_code', typ: 0 },
      { json: 'user_role', js: 'user_role', typ: '' },
      { json: 'email', js: 'email', typ: '' },
      { json: 'username', js: 'username', typ: '' },
      { json: 'is_active', js: 'is_active', typ: true },
      { json: 'iss', js: 'iss', typ: '' },
      { json: 'sub', js: 'sub', typ: '' },
      { json: 'aud', js: 'aud', typ: a('') },
      { json: 'exp', js: 'exp', typ: 0 },
      { json: 'iat', js: 'iat', typ: 0 },
      { json: 'jti', js: 'jti', typ: '' },
    ],
    false,
  ),
};

export type UserRole =
  | 'practitioner'
  | 'assistant'
  | 'platformAdmin'
  | 'entityOwner'
  | 'branchAdmin'
  | 'unauthorized';
