import GritupLogo from '@assets/logo';
import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import Modal from '@components/modal';
import { PersonalInformationDetails } from '@pages/manage-patient-new/types/PatientPersonalDetails';
import { Dialog, Switch, Transition } from '@headlessui/react';
import { getUserPersistedOnLocalStorage } from '@authentication/context/jwt/utils';
import { Convert, Entity } from '@pages/manage-patient-new/types/Entity';
import { toast } from 'react-toastify';
import { endpoints, replaceParams } from '@utils/axios';
import axios from 'axios';
import { OutlinedButton } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import logo from './../../../../public/logo.png';
import { PaymentDetail } from '@pages/manage-patient-new/types/PatientPaymentDetailsDomain';

export default function ViewBill(props: {
  selectedRow: PaymentDetail | undefined;
  handleViewBillClose: () => void;
  patientPersonalInformation: PersonalInformationDetails | null;
}) {
  const componentRef = React.useRef(null);
  const [entity, setEntity] = React.useState<Entity | null>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const userData = getUserPersistedOnLocalStorage();

  const getEntityData = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ['entityId', userData?.entity_id ?? ''],
      ]);
      const filteredEndpoint = replaceParams(endpoints.entity.view, paramsMap);
      const response = await axios.get(filteredEndpoint);
      const data = response.data['data'];

      const convertedEntity = Convert.toEntity(JSON.stringify(data));
      setEntity(convertedEntity);
    } catch (error) {
      toast.error(error.message);

      console.error('Error fetching entity data:', error);
    }
  };

  React.useEffect(() => {
    const _init = async () => {
      await getEntityData();
    };
    _init();
  }, []);

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={props.handleViewBillClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        {/*  */}
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-11/12 lg:w-9/12 xl:w-3/5 transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 px-6 pt-6 text-gray-900"
                >
                  <div className="flex flex-row w-full justify-between my-2">
                    <h3 className="text-md leading-6 font-bold text-gray-900">
                      Invoice
                    </h3>
                  </div>
                </Dialog.Title>
                <hr className="w-full" />
                <div className="flex flex-col w-full">
                  <div className="flex ml-3">
                    {/* <h5 className="font-sans">
                      {props.selectedRow?.paymentNo ?? ''}
                    </h5> */}
                  </div>
                  {/* <div className="flex flex-row space-x-4 my-4 ml-3"> */}
                  {/* <svg
                      className="w-[24px] h-[24px] text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M11.3 6.2H5a2 2 0 0 0-2 2V19a2 2 0 0 0 2 2h11c1.1 0 2-1 2-2.1V11l-4 4.2c-.3.3-.7.6-1.2.7l-2.7.6c-1.7.3-3.3-1.3-3-3.1l.6-2.9c.1-.5.4-1 .7-1.3l3-3.1Z"
                        clip-rule="evenodd"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M19.8 4.3a2.1 2.1 0 0 0-1-1.1 2 2 0 0 0-2.2.4l-.6.6 2.9 3 .5-.6a2.1 2.1 0 0 0 .6-1.5c0-.2 0-.5-.2-.8Zm-2.4 4.4-2.8-3-4.8 5-.1.3-.7 3c0 .3.3.7.6.6l2.7-.6.3-.1 4.7-5Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <svg
                      className="w-[24px] h-[24px] text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5 7.8C6.7 6.3 9.2 5 12 5s5.3 1.3 7 2.8a12.7 12.7 0 0 1 2.7 3.2c.2.2.3.6.3 1s-.1.8-.3 1a2 2 0 0 1-.6 1 12.7 12.7 0 0 1-9.1 5c-2.8 0-5.3-1.3-7-2.8A12.7 12.7 0 0 1 2.3 13c-.2-.2-.3-.6-.3-1s.1-.8.3-1c.1-.4.3-.7.6-1 .5-.7 1.2-1.5 2.1-2.2Zm7 7.2a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                        clip-rule="evenodd"
                      />
                    </svg> */}

                  {/* <svg
                      onClick={handlePrint}
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 3a2 2 0 0 0-2 2v3h12V5a2 2 0 0 0-2-2H8Zm-3 7a2 2 0 0 0-2 2v5c0 1.1.9 2 2 2h1v-4c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v4h1a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2H5Zm4 11a1 1 0 0 1-1-1v-4h8v4c0 .6-.4 1-1 1H9Z"
                        clip-rule="evenodd"
                      />
                    </svg> */}
                  {/* <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M17.5 3A3.5 3.5 0 0 0 14 7L8.1 9.8A3.5 3.5 0 0 0 2 12a3.5 3.5 0 0 0 6.1 2.3l6 2.7-.1.5a3.5 3.5 0 1 0 1-2.3l-6-2.7a3.5 3.5 0 0 0 0-1L15 9a3.5 3.5 0 0 0 6-2.4c0-2-1.6-3.5-3.5-3.5Z" />
                    </svg> */}
                  {/* </div> */}
                  <div
                    ref={componentRef}
                    className="flex flex-col m-3 border border-gray-400 shadow rounded-lg bg-white"
                  >
                    <div className="flex flex-col w-full md:flex-row md:justify-between p-4">
                      <div className="flex-shrink-0 md:w-1/2 p-4">
                        <div className="flex shrink-0 items-center">
                          <img
                            src={
                              entity?.logoUrl === '' ||
                              entity?.logoUrl === null ||
                              entity?.logoUrl === undefined
                                ? logo
                                : entity?.logoUrl
                            }
                            className={
                              entity?.logoUrl
                                ? 'object-fill h-20'
                                : 'object-fill h-12'
                            }
                          />
                        </div>
                      </div>

                      <div className="flex flex-col md:w-1/2 p-4">
                        <div className="flex w-full md:justify-end text-lg text-success-500 font-bold">
                          Paid
                        </div>
                        <div className="flex w-full md:justify-end text-lg font-bold">
                          {props.selectedRow?.paymentNo ?? ''}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col w-full md:flex-row md:justify-evenly px-4">
                      <div className="flex-shrink-0 md:w-1/2 p-4">
                        <div className="flex flex-col">
                          <div className="flex flex-row justify-between">
                            <div className="font-bold text-[#212B36] text-sm">
                              Invoice From
                            </div>
                          </div>
                          <div className="flex-shrink-0 mt-2">
                            {entity?.ownerFirstName} {entity?.ownerLastName}
                          </div>
                          <div className="flex-shrink-0">
                            {entity?.address?.address}
                          </div>
                          <div className="flex-shrink-0">
                            {entity?.phoneCode} {entity?.phone}
                          </div>
                        </div>
                      </div>

                      <div className="flex-shrink-0 md:w-1/2 p-4">
                        <div className="flex flex-col">
                          <div className="flex flex-row justify-between">
                            <div className="font-bold text-[#212B36] text-sm">
                              Invoice To
                            </div>
                          </div>
                          <div className="flex-shrink-0 mt-2">
                            {props.patientPersonalInformation?.firstName}{' '}
                            {props.patientPersonalInformation?.lastName}
                          </div>
                          <div className="flex-shrink-0">
                            {props.patientPersonalInformation?.address.address}
                          </div>
                          <div className="flex-shrink-0">
                            {props.patientPersonalInformation?.phoneCode}{' '}
                            {props.patientPersonalInformation?.phone}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col w-full md:flex-row px-4">
                      <div className="flex-shrink-0 md:w-1/2 p-4">
                        <div className="flex flex-col">
                          <div className="flex flex-row justify-between">
                            <div className="font-bold text-[212B36] text-sm">
                              Billing Date
                            </div>
                          </div>
                          <div className="flex-shrink-0 text-sm mt-2">
                            {props.selectedRow?.createdDate}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="relative overflow-x-auto p-4">
                      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                          <tr>
                            <th scope="col" className="px-6 py-3">
                              Description
                            </th>

                            <th scope="col" className="px-6 py-3">
                              No. of sessions
                            </th>

                            <th scope="col" className="px-6 py-3">
                              Total Paid
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              Physiotherapy session
                            </th>
                            <td className="px-6 py-4">
                              {props.selectedRow?.noOfSessions ?? 1}
                            </td>

                            <td className="px-6 py-4">
                              {props.selectedRow?.amount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="flex flex-col">
                      <div className="flex flex-col items-end w-full p-6 space-y-3">
                        <div className="flex flex-row w-2/5 justify-between mb-2">
                          <div className="flex text-black font-bold w-1/2 justify-end">
                            Total
                          </div>
                          <div className="text-black text-base">
                            {props.selectedRow?.amount}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-full mt-4 p-4 bg-gray-100 justify-end">
                    <OutlinedButton
                      variant={ButtonVariant.PRIMARY}
                      type="button"
                      onClick={props.handleViewBillClose}
                      children="Close"
                      className="mr-2"
                    />
                    <OutlinedButton
                      variant={ButtonVariant.PRIMARY}
                      onClick={handlePrint}
                      className="mr-2"
                      type="button"
                    >
                      Print
                    </OutlinedButton>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
