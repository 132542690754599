import { OutlinedButton } from '@components/button';
import Button, { ButtonVariant } from '@components/button/button';
import { Dialog, Transition } from '@headlessui/react';

import React, { Fragment, useEffect } from 'react';

function BranchChangePopUp(props: {
  setBranchChangeDialog: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  let [isOpen, setIsOpen] = React.useState(true);

  function closeModal() {
    props.setBranchChangeDialog(false);
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold leading-6 px-6 pt-6 text-gray-900"
                  >
                    Warning
                  </Dialog.Title>

                  <div className="max-h-[75vh] overflow-auto px-6">
                    Changing branch of a staff may lead to loss of scheduled
                    appointments. Please transfer the appointments before
                    changing branch
                  </div>
                  <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4 mt-2">
                    <div className="flex flex-row w-full justify-end">
                      <OutlinedButton
                        variant={ButtonVariant.PRIMARY}
                        type="button"
                        onClick={() => {
                          props.setBranchChangeDialog(false);
                          setIsOpen(false);
                        }}
                        children="Close"
                        className="mr-2"
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default BranchChangePopUp;
