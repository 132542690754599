import React from "react";
import { Pain } from "../type/PainAssessment";

type PainType = {
  painAssessment: Pain | null;
  intensity: number;
};

const PainAssessment = (props: PainType) => {
  return (
    <div>
      {props.painAssessment !== null ? (
        <div className="">
          <h3 className="mb-4 font-bold text-tertiary-600">Pain Assessment</h3>
          <div className="flex flex-col">
            <table className="table-auto  text-left text-md border-collapse border border-tertiary-500 w-full">
              <tbody>
                {props.painAssessment?.progression === "" ? (
                  <tr></tr>
                ) : (
                  <tr className="border  border-2 border-tertiary-500">
                    <td className="border border-r-2 border-tertiary-500 px-6 py-4 font-medium">
                      Progression
                    </td>
                    <td className="flex justify-center px-6 py-4 ">
                      <p>{props.painAssessment.progression}</p>
                    </td>
                  </tr>
                )}
                <tr className="border border-2 border-tertiary-500">
                  <td className="border border-r-2 border-tertiary-500 px-6 py-4 w-1/2 font-medium">
                    Pain Intensity
                  </td>
                  <td className="flex justify-center px-6 py-4">
                    <div className="flex flex-row">
                      <div className="flex-shrink">
                        <input
                          className=""
                          type="range"
                          name="painIntensity"
                          step={1}
                          min={0}
                          max={10}
                          value={props.intensity}
                          disabled={true}
                        />
                      </div>
                      <div className="w-14 ml-4">{props.intensity}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table-auto text-left text-md border-collapse border border-2 border-tertiary-500 w-full mt-5">
              <thead className="bg-gray-50 bg-tertiary-100">
                <tr>
                  <th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-center">
                    Pain Area
                  </th>
                  <th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-center">
                    Left
                  </th>
                  <th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-center">
                    Right
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr key={"Head"} className="border border-tertiary-500">
                  <td className="px-2 py-4 border border-right border-tertiary-500">
                    Head
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.headPainLeft === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.headPainRight === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                </tr>

                <tr key={"Neck"} className="border border-tertiary-500">
                  <td className="px-2 py-4 border border-right border-tertiary-500">
                    Neck
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.neckPainLeft === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.neckPainRight === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                </tr>

                <tr key={"Shoulder"} className="border border-tertiary-500">
                  <td className="px-2 py-4 border border-right border-tertiary-500">
                    Shoulder
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.shoulderPainLeft === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.shoulderPainRight === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                </tr>

                <tr key={"Wrist"} className="border border-tertiary-500">
                  <td className="px-2 py-4 border border-right border-tertiary-500">
                    Wrist
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.wristPainLeft === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.wristPainRight === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                </tr>

                <tr key={"Hand"} className="border border-tertiary-500">
                  <td className="px-2 py-4 border border-right border-tertiary-500">
                    Hand
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.handPainLeft === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.handPainRight === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                </tr>

                <tr key={"Elbow"} className="border border-tertiary-500">
                  <td className="px-2 py-4 border border-right border-tertiary-500">
                    Elbow
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.elbowPainLeft === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.elbowPainRight === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                </tr>

                <tr key={"Chest"} className="border border-tertiary-500">
                  <td className="px-2 py-4 border border-right border-tertiary-500">
                    Chest
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.chestPainLeft === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.chestPainRight === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                </tr>

                <tr key={"Back"} className="border border-tertiary-500">
                  <td className="px-2 py-4 border border-right border-tertiary-500">
                    Back
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.backPainLeft === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.backPainRight === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                </tr>

                <tr key={"Hip"} className="border border-tertiary-500">
                  <td className="px-2 py-4 border border-right border-tertiary-500">
                    Hip
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.hipPainLeft === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.hipPainRight === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                </tr>

                <tr key={"Knee"} className="border border-tertiary-500">
                  <td className="px-2 py-4 border border-right border-tertiary-500">
                    Knee
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.kneePainLeft === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.kneePainRight === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                </tr>

                <tr key={"Ankle"} className="border border-tertiary-500">
                  <td className="px-2 py-4 border border-right border-tertiary-500">
                    Ankle
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.anklePainLeft === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.anklePainRight === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                </tr>

                <tr key={"Foot"} className="border border-tertiary-500">
                  <td className="px-2 py-4 border border-right border-tertiary-500">
                    Foot
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.footPainLeft === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                  <td className="border px-8 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.footPainRight === true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table-auto  text-left text-md border-collapse border border-2 border-tertiary-500 w-full mt-5">
              <thead className="bg-gray-50 bg-tertiary-100">
                <tr>
                  <th className="border lg:px-6 px-6 py-4 border-2 border-tertiary-500">
                    Pain Type
                  </th>
                  <th className="border lg:px-6 px-6 py-4 border-2 border-tertiary-500">
                    Left{" "}
                  </th>
                  <th className="border lg:px-6 px-6 py-4 border-2 border-tertiary-500 ">
                    Right{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr key={"ConstantIntermittent"}>
                  <td className="border px-6 py-4 border-2 border-tertiary-500 font-medium">
                    Constant Intermittent
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.constantIntermittentLeft ===
                      true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.constantIntermittentRight === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                </tr>
                <tr key={"Sharp"}>
                  <td className="border px-6 py-4 border-2 border-tertiary-500 font-medium">
                    Sharp
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.sharpLeft === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.sharpRight === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                </tr>
                <tr key={"Shooting"}>
                  <td className="border px-6 py-4 border-2 border-tertiary-500 font-medium">
                    Shooting
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.shootingLeft === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.shootingRight === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                </tr>
                <tr key={"Burning"}>
                  <td className="border px-6 py-4 border-2 border-tertiary-500 font-medium">
                    Burning
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.burningLeft === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.burningRight === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                </tr>
                <tr key={"Throbbing"}>
                  <td className="border px-6 py-4 border-2 border-tertiary-500 font-medium">
                    Throbbing
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.throbbingLeft === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.throbbingRight === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                </tr>
                <tr key={"Numbness"}>
                  <td className="border px-6 py-4 border-2 border-tertiary-500 font-medium">
                    Numbness
                  </td>
                  <td className="border px-6 py-4 border-2 justify-center border-tertiary-500">
                    {props.painAssessment.numbnessLeft === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                  <td className="border px-6 py-4 border-2 border-tertiary-500">
                    {props.painAssessment.numbnessRight === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                </tr>
                <tr key={"PinsAndNeedles"}>
                  <td className="border px-6 py-4 border-2 border-tertiary-500 font-medium">
                    Pins And Needles
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.pinsAndNeedlesLeft === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.pinsAndNeedlesRight === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="mt-4">
              {props.painAssessment.activityLimitation !== "" ? (
                <div>
                  <div className="flex flex-row justify-between w-full mt-4 bg-tertiary-100 px-2 py-1 font-medium">
                    Activity Limitation
                  </div>
                  <article className="text-wrap">
                    <p className="px-2 py-1 ">
                      {props.painAssessment.activityLimitation}
                    </p>
                  </article>
                </div>
              ) : (
                <div></div>
              )}

              {props.painAssessment.aggravatingFactors !== "" ? (
                <div className="mt-2">
                  <div className="flex flex-row justify-between w-full mt-4 bg-tertiary-100 px-2 py-1 font-medium">
                    Aggravating Factors
                  </div>
                  <p className="px-2 py-1 text-wrap">
                    {props.painAssessment.aggravatingFactors}
                  </p>
                </div>
              ) : (
                <div></div>
              )}

              {props.painAssessment.relievingFactors !== "" ? (
                <div className="mt-2">
                  <div className="flex flex-row justify-between w-full mt-4 bg-tertiary-100 px-2 py-1 font-medium">
                    Relieving Factors
                  </div>
                  <p className="px-2 py-1 flex text-wrap">
                    {props.painAssessment.relievingFactors}
                  </p>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <hr className=" my-12 border-tertiary-500"></hr>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default PainAssessment;
