import { Button, OutlinedButton } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import Modal from '@components/modal';
import { Dialog, Transition } from '@headlessui/react';
import useAxios from '@routes/hooks/use-axios';
import { endpoints, replaceParams } from '@utils/axios';
import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';

export function CancelAppointmentDialog(props: {
  onClose: () => void;
  appId: string;
  callback: () => void;
}) {
  let [isOpen, setIsOpen] = useState(true);
  const [cancellationReason, setCancellationReason] = useState('');
  const axios = useAxios();
  function closeModal() {
    setIsOpen(false);
    props.onClose();
  }

  const cancelAppointment = async () => {
    try {
      const paramsMap = new Map<string, string>([['id', props.appId]]);
      const filteredEndpoint = replaceParams(
        endpoints.appointment.cancelAppointment,
        paramsMap,
      );
      await axios.post(filteredEndpoint, {
        cancellationReason: cancellationReason,
      });
      closeModal();
      props.callback();
      toast.success('Appointment Cancelled Successfully!');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="flex flex-row w-full justify-between mx-4 my-2">
          <h3 className="text-md leading-6 font-bold text-gray-900">
            Confirmation
          </h3>
        </div>
        <hr className="w-full" />
        <div className="flex flex-col w-full h-full px-4 py-2 space-y-2">
          <label>Specify the reason to cancel the appointment</label>
          <p className="text-sm text-gray-500 mt-2">
            <textarea
              id="message"
              onChange={e => setCancellationReason(e.target.value)}
              className="mt-2 block p-2.5 lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
            ></textarea>
          </p>
        </div>

        <div className="flex flex-row w-full mt-4 p-4 bg-gray-100 justify-end">
          <OutlinedButton
            variant={ButtonVariant.PRIMARY}
            type="button"
            onClick={closeModal}
            children="Close"
            className="mr-2"
          />

          <Button
            variant={ButtonVariant.PRIMARY}
            type="button"
            onClick={cancelAppointment}
            children="Cancel Appointment"
            className="mr-2"
          />
        </div>
      </Modal>
    </>
  );
}
