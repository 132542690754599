import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';

import { replaceParams, endpoints } from '@utils/axios';
import axios from 'axios';

import { OutlinedButton } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import { Appointments } from '../types/Appointments';
import { Convert, PaymentDetails } from '../types/PaymentDetails';
import Modal from '@components/modal';

export default function ViewPaymentDetails(props: {
  onClose: () => void;
  onConfirm: () => void;
  appointmentId: string;
  appointmentDetails: Appointments;
}) {
  let [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const [paymentDetails, setPaymentDetails] =
    React.useState<PaymentDetails | null>(null);

  function closeModal() {
    setIsOpen(false);
    props.onClose();
  }

  useEffect(() => {
    const getBranches = async () => {
      try {
        const paramsMap = new Map<string, string>([
          ['appointmentId', props.appointmentId],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.payment.view,
          paramsMap,
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data['data'];

        const converted = Convert.toPaymentDetails(JSON.stringify(data));
        const payDetails = converted;
        setPaymentDetails(payDetails);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching payment details:', error);
        setPaymentDetails(null);
        setLoading(false);
      }
    };

    getBranches();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="flex flex-row w-full justify-between mx-4 my-2">
          <h3 className="text-md leading-6 font-bold text-gray-900">
            Payment Information
          </h3>
        </div>
        <hr className="w-full" />
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {!loading && !error && paymentDetails === null && (
          <>
            <div className="flex flex-col w-full h-full my-2">
              <span className="text-center">No payment details</span>
            </div>
            <div className="flex flex-row w-full mt-4 p-4 bg-gray-100 justify-end">
              <OutlinedButton
                variant={ButtonVariant.PRIMARY}
                type="button"
                onClick={closeModal}
                children="Close"
                className="mr-2"
              />
              
            </div>
          </>
        )}
        {!loading && !error && paymentDetails && (
          <div className="">
            <div className="flex flex-col w-full h-full px-4 py-2 space-y-2">
              <div className="mb-4">
                <div className="flex flex-col">
                  <span className="text-sm">
                    Billing Amount: ₹{paymentDetails?.billamount}
                  </span>
                  <span className="text-sm">
                    Amount Paid: ₹{paymentDetails?.amount}
                  </span>
                  <span className="text-sm">
                    Payment Type: {paymentDetails?.paymentType}
                  </span>
                  <span className="text-sm">
                    Payment Status: {paymentDetails?.paymentStatus}
                  </span>
                  <span className="text-sm">
                    Client Name: {paymentDetails?.clientName}
                  </span>
                  <span className="text-sm">
                    Practitioner Name: {paymentDetails?.entityUserName}
                  </span>
                </div>
                <div className="mt-4">
                  <div className="flex flex-col">
                    <h1 className="text-lg font-semibold">
                      Additional Information
                    </h1>
                    <span className="text-sm">
                      Notes: {paymentDetails?.notes}
                    </span>
                    <span className="text-sm">
                      Paid Date: {paymentDetails?.createdDate}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row w-full mt-4 p-4 bg-gray-100 justify-end">
              <OutlinedButton
                variant={ButtonVariant.SECONDARY}
                type="button"
                onClick={closeModal}
                children="Close"
                className="mb-2 mr-2"
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
