// To parse this data:
//
//   import { Convert, Rating } from "./file";
//
//   const rating = Convert.toRating(json);

export type Rating = {
    id?:                         string;
    clientId?:                   string;
    entityId?:                   string;
    entityBranchId?:             string;
    entityUserId?:               string;
    entityUserRating?:           number;
    entityUserRatingComment?:    string;
    entityRating?:               number;
    entityRatingComment?:        string;
    treatmentPlanRating?:        number;
    treatmentPlanRatingComment?: string;
    createdDate?:                Date;
    updatedDate?:                Date;
}

// Converts JSON strings to/from your types
export class Convert {
    public static toRating(json: string): Rating {
        return JSON.parse(json);
    }

    public static ratingToJson(value: Rating): string {
        return JSON.stringify(value);
    }
}
