import React from "react";
import { LimbLength } from "../type/LimbLengthType";

type LimbLengthProps = {
  limbLength: LimbLength | null;
};

const LimbLengthAssessment = (props: LimbLengthProps) => {
  return (
    <div className="flex flex-col w-full h-full ">
      <h3 className="mb-4 font-bold text-tertiary-500">Limb Length</h3>
      <div className="flex flex-col items-stretch">
        <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
          Limb Length
        </div>
        <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="border px-2 py-1 border-slate-300 text-center">
                Name
              </th>
              <th className="border px-2 py-1 border-slate-300 text-center">
                Left{" "}
              </th>
              <th className="border px-2 py-1 border-slate-300 text-center">
                Right{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-2 py-1 border-slate-300 text-center">
                True Limb Length
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.limbLength?.trueLimbLengthLeft}</p>
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.limbLength?.trueLimbLengthRight}</p>
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1 border-slate-300 text-center">
                Apprant Limb Length
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.limbLength?.apparentLimbLengthLeft}</p>
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.limbLength?.apparentLimbLengthRight}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
          Segmental Limb Length
        </div>

        <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="border px-2 py-1 border-slate-300 text-center">
                Name
              </th>
              <th className="border px-2 py-1 border-slate-300 text-center">
                Left{" "}
              </th>
              <th className="border px-2 py-1 border-slate-300 text-center">
                Right{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-2 py-1 border-slate-300 text-center">
                Humeral Limb Length
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.limbLength?.humeralLimbLengthLeft}</p>
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.limbLength?.humeralLimbLengthRight}</p>
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1 border-slate-300 text-center">
                Ulnar Limb Length
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.limbLength?.ulnarLimbLengthLeft}</p>
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.limbLength?.ulnarLimbLengthRight}</p>
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1 border-slate-300 text-center">
                Femoral Limb Length
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.limbLength?.femoralLimbLengthLeft}</p>
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.limbLength?.femoralLimbLengthRight}</p>
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1 border-slate-300 text-center">
                Tibial Limb Length
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.limbLength?.tibialLimbLengthLeft}</p>
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.limbLength?.tibialLimbLengthRight}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr className=" my-12 border-tertiary-500"></hr>
    </div>
  );
};

export default LimbLengthAssessment;
