import React, { ReactNode, useRef, useState } from "react";

interface Props {
  children: ReactNode;
  content: ReactNode;
  trigger?: "click" | "hover";
}

function ReactPopover({ children, content, trigger = "click" }: Props) {
  const [show, setShow] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleMouseLeave = () => {
    if (trigger === "click") {
      setShow(false);
    }
  };

  return (
    <div
      ref={wrapperRef}
      onBlur={handleMouseLeave}
      className="w-fit h-fit relative flex justify-center"
    >
      <div onClick={() => setShow(!show)}>{children}</div>
      <div
        hidden={!show}
        className="min-w-fit w-[200px] max-h-20 absolute bottom-[100%] z-50 transition-all overflow-auto border border-black"
      >
        <div className="rounded bg-white p-3 mb-[10px]">{content}</div>
      </div>
    </div>
  );
}

export default ReactPopover;
