import React, { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import {
  Country,
  State,
  City,
  ICountry,
  IState,
  ICity,
} from "country-state-city";
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/react/24/outline";
import ValidationError from "./validation-error";

interface Selectable {
  isoCode: string;
  name: string;
}

function Selector({
  data,
  selected,
  setSelected,
  disabled,
  register,
  setValue,
  name,
}: {
  data: Selectable[];
  selected: string;
  setSelected: (value: string) => void;
  disabled: boolean;
  register: any; //UseFormRegister<FieldValues>;
  setValue?: any; //UseFormSetValue<FieldValues>;
  name: string;
}) {
  const [query, setQuery] = useState("");

  const filteredData = data.filter((item) =>
    item.name
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );

  return (
    <div className="w-full">
      <Combobox
        value={selected}
        disabled={disabled}
        onChange={(e: string) => {
          setSelected(e);
          setValue(name, e);
        }}
      >
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              {...register(name)}
              className="w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 "
              onChange={(event) => {
                setQuery(event.target.value);
                setSelected(event.target.value);
              }}
              placeholder="Select an option"
              autoComplete="off"
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={React.Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md z-[99]  border border-gray-300">
              {filteredData.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredData.map((item) => (
                  <Combobox.Option
                    key={item.isoCode}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 bg-white ${
                        active ? "bg-grey-200" : "text-gray-900"
                      }`
                    }
                    value={item.name}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {item.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-grey-600"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
type Props = {
  isDisabled: boolean;
  register?: any; //UseFormRegister<FieldValues>;
  setValue?: any; //UseFormSetValue<FieldValues>;
  watch?: any; //UseFormWatch<FieldValues>;
  country?: string;
  state?: string;
  city?: string;
  isRequired?: boolean;
  clearErrors?: any;
  errors?: any;
};
function CountryInputSelect({
  isDisabled,
  register,
  setValue,
  watch,
  country,
  state,
  city,
  isRequired,
  clearErrors,
  errors,
}: Props) {
  const countryData: ICountry[] = Country.getAllCountries();
  const stateData: IState[] = State.getAllStates();

  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");

  const [selectedCountryCode, setSelectedCountryCode] = useState<string>("");
  const [selectedStateCode, setSelectedStateCode] = useState<string>("");
  const [selectedCityCode, setSelectedCityCode] = useState<string>("");

  useEffect(() => {
    const countryObj = countryData.find(
      (countryObj) => countryObj.name === watch(country)
    );
    setSelectedCountry(
      countryObj ? countryObj.name : selectedCountry ? selectedCountry : "India"
    );
    setSelectedCountryCode(
      countryObj
        ? countryObj.isoCode
        : selectedCountryCode
        ? selectedCountryCode
        : "IN"
    );
    if (countryObj === undefined) setValue(country, "India");
    if (countryObj && countryObj.name == "India") {
      if (selectedState !== watch(state)) setStateValue(watch(state));
      if (selectedCity !== watch(city)) setCityValue(watch(city));
    }
  });

  useEffect(() => {
    setStateValue(watch(state));
  }, [selectedCountry]);

  useEffect(() => {
    setCityValue(watch(city));
  }, [selectedState]);

  function setStateValue(value: string) {
    const countryObj = countryData.find(
      (countryObj) => countryObj.name === watch(country)
    );
    const countryCode =
      selectedCountryCode && selectedCountryCode !== ""
        ? selectedCountryCode
        : countryObj
        ? countryObj.isoCode
        : selectedCountryCode;
    const stateDataObj = State.getStatesOfCountry(countryCode ?? "").find(
      (stateObj) => stateObj.name === value
    );
    setSelectedState(stateDataObj ? stateDataObj.name : "");
    setSelectedStateCode(stateDataObj ? stateDataObj.isoCode : "");
    //setValue(state, stateDataObj ? stateDataObj.name : '');
  }

  function setCityValue(value: string) {
    if (value && value != "") setSelectedCityCode(value);
    const cityCode =
      value && value != "" && selectedCityCode != value
        ? value
        : selectedCityCode;
    const cityList = City.getCitiesOfState(
      selectedCountryCode ? selectedCountryCode : "",
      selectedStateCode ? selectedStateCode : ""
    );
    const cityObj = cityList.find((cityObj) => cityObj.name === cityCode);
    setSelectedCity(cityObj ? cityObj.name : "");
    // setValue(city, cityObj ? cityObj.name : '');
  }

  return (
    <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 mb-4">
      <div className="flex flex-col  w-full md:w-1/3">
        <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
          Country{isRequired && <span className="text-red-500"> *</span>}
        </label>
        <Selector
          data={countryData.map((country) => ({
            isoCode: country.isoCode,
            name: country.name,
          }))}
          selected={selectedCountry ? selectedCountry : ""}
          setSelected={(value) => {
            const countryObj = countryData.find(
              (country) => country.name === value
            );
            setSelectedCountry(countryObj ? countryObj.name : value);
            setSelectedCountryCode(countryObj ? countryObj.isoCode : "");
            if (countryObj && countryObj.name) {
              setValue(country, countryObj.name);
              if (clearErrors) clearErrors(country);
              setValue(state, "");
              setValue(city, "");
            }
          }}
          disabled={isDisabled}
          register={register}
          setValue={setValue}
          name={country ?? ""}
        />
        {errors && errors.address && errors.address?.country && (
          <ValidationError
            message={errors.address.country?.message?.toString() ?? ""}
          />
        )}
      </div>
      <div className="flex flex-col  w-full md:w-1/3">
        {/* State Selector */}
        <div>
          <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
            State{isRequired && <span className="text-red-500"> *</span>}
          </label>
          <Selector
            data={stateData
              .filter((state) =>
                state.countryCode === selectedCountryCode
                  ? selectedCountryCode
                  : ""
              )
              .map((state) => ({ isoCode: state.isoCode, name: state.name }))}
            selected={selectedState ? selectedState : ""}
            setSelected={(value) => {
              const stateObj = stateData.find((state) => state.name === value);
              setSelectedState(stateObj ? stateObj.name : value);
              setSelectedStateCode(stateObj ? stateObj.isoCode : "");
              if (stateObj && stateObj.name) {
                setValue(state, stateObj.name);
                if (clearErrors) clearErrors(state);
                setValue(city, "");
              }
            }}
            disabled={isDisabled}
            register={register}
            setValue={setValue}
            name={state ?? ""}
          />
        </div>
        {errors && errors.address && errors.address?.state && (
          <ValidationError
            message={errors.address.state?.message?.toString() ?? ""}
          />
        )}
      </div>
      <div className="flex flex-col  w-full md:w-1/3">
        {/* City Selector */}

        <div>
          <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
            City{isRequired && <span className="text-red-500"> *</span>}
          </label>
          <Selector
            data={City.getCitiesOfState(
              selectedCountryCode ? selectedCountryCode : "",
              selectedStateCode ? selectedStateCode : ""
            ).map((city) => ({ isoCode: city.name, name: city.name }))}
            selected={selectedCity ? selectedCity : ""}
            setSelected={(value) => {
              const cityObj = City.getCitiesOfState(
                selectedCountryCode ? selectedCountryCode : "",
                selectedStateCode ? selectedStateCode : ""
              ).find((city) => city.name === value);
              setSelectedCity(cityObj ? cityObj.name : value);
              setSelectedCityCode(cityObj ? cityObj.name : "");
              if (cityObj && cityObj.name) {
                setValue(city, cityObj.name);
                if (clearErrors) clearErrors(city);
              }
            }}
            disabled={isDisabled}
            register={register}
            setValue={setValue}
            name={city ?? ""}
          />
        </div>

        {errors && errors.address && errors.address?.city && (
          <ValidationError
            message={errors.address.city?.message?.toString() ?? ""}
          />
        )}
      </div>
    </div>
  );
}

export default CountryInputSelect;
