// To parse this data:
//
//   import { Convert, SessionNotesDetails } from "./file";
//
//   const sessionNotesDetails = Convert.toSessionNotesDetails(json);

export interface SessionNotesDetails {
  id: string;
  clientId: string;
  entityId: string;
  entityUserId: string;
  entityUserName: string;
  assessmentId: string;
  notes: string;
  createdDate: Date;
  createdBy: string;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toSessionNotesDetails(json: string): SessionNotesDetails {
    return JSON.parse(json);
  }

  public static sessionNotesDetailsToJson(value: SessionNotesDetails): string {
    return JSON.stringify(value);
  }
}
