import { OutlinedButton } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import SolidButton from "@components/button/button";
import { Appointments } from '../types/Appointments';
import moment from 'moment';

interface MarkCompleteConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  selectedAppointment: Appointments | null;
}

const MarkCompleteConfirmation: React.FC<MarkCompleteConfirmationProps> = ({ isOpen, onClose, onConfirm, selectedAppointment }) => {


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-xl font-bold leading-6 px-6 pt-6 text-gray-900">
                  Confirmation
                </Dialog.Title>
                {selectedAppointment ? (
                  <>
                    <div className="max-h-[75vh] overflow-auto px-6 pt-6">
                      <p className="mb-4 font-bold">Appointment</p>
                      <p className="mb-4">{selectedAppointment.clientName}, {selectedAppointment.appointmentType}, {moment(selectedAppointment.apptdate).format("DD-MM-YYYY hh:mm A")},
                      </p>
                      <p className="mb-4">With {selectedAppointment.entityUserName}</p>
                    </div>
                    <div className="max-h-[75vh] overflow-auto px-6 pt-6">

                      <p className="mb-4">Appointment status is <span className="text-red-500 text-lg font-bold">{selectedAppointment.appointmentStatus}</span>. Do you want to change it as <span className="text-red-500 text-lg font-bold">Completed</span> ?</p>
                    </div>
                    <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4 mt-2">
                      <div className="flex flex-row w-full justify-end">

                        <SolidButton
                          variant={ButtonVariant.PRIMARY}
                          className="mr-2"
                          onClick={onConfirm}
                        >
                          Confirm
                        </SolidButton>
                        <OutlinedButton
                          variant={ButtonVariant.SECONDARY}
                          type="button"
                          onClick={onClose}
                          children="Cancel"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="max-h-[75vh] overflow-auto px-6 pt-6">
                      <p className="mb-4">No Appointment Selected.</p>
                      <OutlinedButton
                        variant={ButtonVariant.SECONDARY}
                        type="button"
                        onClick={onClose}
                        children="Cancel"
                      />
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};


export default MarkCompleteConfirmation;
