// To parse this data:
//
//   import { Convert, PatientPaymentDetails } from "./file";
//
//   const patientPaymentDetails = Convert.toPatientPaymentDetails(json);

export interface PatientPaymentDetailsDomain {
  appointmentsCount: AppointmentsCount;
  paymentDetails?: PaymentDetail[];
}

export interface AppointmentsCount {
  Consultation?: number;
  'Follow-up'?: number;
  Therapy?: number;
}

export interface PaymentDetail {
  id: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  entityUserId: string;
  appointmentId: string;
  entityUserName: string;
  clientName: string;
  amount: number;
  paymentNo: string;
  isAdvancePay: boolean;
  paymentType: string;
  paymentMode: string;
  paymentStatus: string;
  noOfSessions: number;
  startDate: Date;
  date: Date;
  notes: string;
  createdBy: string;
  createdDate: string;
  outstandingAmount: number;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toPatientPaymentDetails(
    json: string,
  ): PatientPaymentDetailsDomain {
    return JSON.parse(json);
  }

  public static patientPaymentDetailsToJson(
    value: PatientPaymentDetailsDomain,
  ): string {
    return JSON.stringify(value);
  }
}
