// To parse this data:
//
//   import { Convert, AvailableTimeSlots } from "./file";
//
//   const availableTimeSlots = Convert.toAvailableTimeSlots(json);

export interface AvailableTimeSlots {
  entityUserId: string;
  entityUserName: string;
  entityUserPhone: number;
  morningSlots: number;
  aftrenonSlots: number;
  eveningSlots: number;
  totalSlots: number;
  totalFreeSlots: number;
  freeslots: Freeslot[];
  isGoogleAuthEnabled: boolean;
}

export interface Freeslot {
  startTimeMinutes: number;
  endTimeMinutes: number;
  startTime: string;
  endTime: string;
}

// Converts JSON strings to/from your types
export class AvailableTimeSlotConvert {
  public static toAvailableTimeSlots(json: string): AvailableTimeSlots {
    return JSON.parse(json);
  }

  public static availableTimeSlotsToJson(value: AvailableTimeSlots): string {
    return JSON.stringify(value);
  }
}
