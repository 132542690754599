// To parse this data:
//
//   import { Convert } from "./file";
//
//   const therapy = Convert.toTherapy(json);

export type Therapy = {
  tid: string;
  therapyName: string;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toTherapy(json: string): Therapy[] {
    return JSON.parse(json);
  }

  public static therapyToJson(value: Therapy[]): string {
    return JSON.stringify(value);
  }
}
