import { lazy } from 'react';
import { Outlet, Route } from 'react-router-dom';
import GeneralLayout from '@layouts/general/general';
import React from 'react';

// ----------------------------------------------------------------------

const NotFoundPage = lazy(() => import('@pages/NotFoundPage'));

// ----------------------------------------------------------------------

export const mainRoutes = (
  <Route element={<GeneralLayout />}>
    <Route path="404" element={<NotFoundPage />} />
  </Route>
);
