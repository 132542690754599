// To parse this data:
//
//   import { Convert, Branch } from "./file";
//
//   const branch = Convert.toBranch(json);

export type Branch = {
  id?: string;
  entityId?: string;
  name?: string;
  adminSalutation?: string;
  adminFirstName?: string;
  adminMiddleName?: string;
  adminLastName?: string;
  email?: string;
  phone?: number;
  timezone?: string;
  phoneCode?: number;
  address?: Address;
};

export type Address = {
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toBranch(json: string): Branch {
    return JSON.parse(json);
  }

  public static branchToJson(value: Branch): string {
    return JSON.stringify(value);
  }
}
