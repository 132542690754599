import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { paths } from "@routes/paths";
import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";
import moment from "moment";
import Button from "@components/button/button";
import { ButtonVariant } from "@components/button/button";

import {
  Convert,
  PersonalInformationDetails,
} from "../types/PatientPersonalDetails";
import { ValidationError } from "@components/hook-form";
import CountryInputSelect from "@components/hook-form/country-input-select";
import Applabel from "@components/hook-form/applabel";

import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";

export const AddressSchema = z.object({
  address: z.string(), //.min(1, { message: "Address is required" }),
  city: z.string(), //.min(1, { message: "City is required" }),
  state: z.string(), //.min(1, { message: "State is required" }),
  country: z.string(), //.min(1, { message: "Country is required" }),
  postalCode: z.string(), //.min(1, { message: "Postal Code/zip is required" }),
});
export type Address = z.infer<typeof AddressSchema>;

export const PersonalInformationSchema = z.object({
  salutation: z.string().optional(),
  firstName: z.string().min(1, { message: "First name is required" }),
  middleName: z.string().optional(),
  lastName: z.string().min(1, { message: "Last name is required" }),
  email: z
    .string()
    .email({ message: "Must be a valid email" })
    .optional()
    .or(z.literal("")),
  isActive: z.boolean().optional(),
  isOnboarded: z.boolean().optional(),
  phone: z.number(),
  phoneCode: z.number(),
  emergencyPhone: z.number(),
  emergencyPhoneCode: z.number(),
  birthPlace: z.string().optional(),
  bloodGroup: z.string(),
  address: z.union([AddressSchema, z.null()]).optional(),
  age: z.coerce.number().positive(),
  gender: z.string().min(1, { message: "Gender is required" }),
  dob: z.string().refine(
    (val) => {
      const dobDate = new Date(val);
      return !isNaN(dobDate.getTime());
    },
    { message: "Please enter date of birth" }
  ),
});

export type PersonalInformationType = z.infer<typeof PersonalInformationSchema>;

export default function PersonalInformation() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<PersonalInformationType>({
    resolver: zodResolver(PersonalInformationSchema),
  });

  const handleToggleEdit = () => {
    setIsEditable((prevState) => !prevState);
  };

  const onSubmit: SubmitHandler<PersonalInformationType> = async (data) => {
    try {
      PersonalInformationSchema.parse(data);
      const paramsMap = new Map<string, string>([
        ["patientId", patientId.patientId],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.patient.updateInformation,
        paramsMap
      );
      const response = await axios.put(filteredEndpoint, data);

      setIsEditable(false);
      window.location.reload();
      // props.postSubmitCallback();
      //   navigateToMasterAssessment(response.data['data']);
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiError(error.response.data["data"].toString());
    }
  };

  const [patientPersonalInformation, setPatientPersonalInformation] =
    React.useState<PersonalInformationDetails | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [isEditable, setIsEditable] = React.useState(false);
  const [apiError, setApiError] = React.useState("");
  const [phoneNumber, setPhoneNumber] = useState("91");
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState("91");
  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);

  // below is the api call to get the list of patients in a react hook
  React.useEffect(() => {
    setIsEditable(false);
    const getPatients = async () => {
      try {
        const paramsMap = new Map<string, string>([
          ["patientId", patientId.patientId],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.patient.view,
          paramsMap
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data["data"];

        setPhoneNumber(
          data.phoneCode.toString().toString() +
          data.phone.toString().toString()
        );
        setEmergencyPhoneNumber(
          data.emergencyPhoneCode.toString().toString() +
          data.emergencyPhone.toString().toString()
        );
        const convertedPersonalInformation = Convert.toPersonalInformation(
          JSON.stringify(data)
        );
        setPatientPersonalInformation(convertedPersonalInformation);
        reset({ ...convertedPersonalInformation });
        setIsEditable(false);
        setLoading(false);
      } catch (error) {
        console.error("Error getting patient information", error);
        setLoading(false);
      }
    };
    getPatients();
  }, []);

  const handleRequestFormLink = async () => {
    try {
      const response = await axios.post(
        endpoints.patient.resendOnboardFormLink,
        {},
        {
          params: {
            id: patientId.patientId,
          },
        }
      );
      toast.success("Onboarding form link sent");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Something went wrong. Please try again after sometime.");
    }
  };

  const navigateToMasterAssessment = (masterAssessmentId: string) => {
    const paramsMap = new Map<string, string>([
      ["patientId", patientId.patientId],
      ["masterAssessmentId", masterAssessmentId],
    ]);
    navigate(
      `${replaceParams(paths.patient.masterAssessment.route, paramsMap)}`
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="sticky top-0 right-0 z-1 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
            Personal Information
          </h2>
        </div>

        <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          <Button
            variant={ButtonVariant.PRIMARY}
            type="button"
            className="ml-3"
            onClick={isEditable ? handleSubmit(onSubmit) : handleToggleEdit}
          >
            {isEditable ? "Save" : "Edit"}
          </Button>
        </div>
      </div>
      <hr className="my-2" />
      {!patientPersonalInformation?.isOnboarded && (
        <div className="flex flex-col justify-start lg:flex-row lg:justify-between lg:items-center p-6 rounded-2xl shadow bg-gradient-to-r from-primary-50 to-primary-50">
          <div className="flex flex-row lg:flex-col">
            <div className="flex flex-col justify-start">
              <span className="text-2xl font-bold text-black">
                Patient Onboard Form Link
              </span>
              <span className="text-sm text-black">
                Patient not onboarded yet. Please send onboard link.
              </span>
            </div>
          </div>
          <div className="flex flex-row mt-2 justify-start lg:flex-col lg:justify-normal lg:m-0">
            <div className="flex flex-col mt-4 lg:flex-row lg:mt-0">
              <Button
                variant={ButtonVariant.PRIMARY}
                type="button"
                onClick={handleRequestFormLink}
                children="Send Email"
                className="mb-2 lg:mb-0 lg:mr-2"
              />
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
        <div className="flex flex-col  w-full md:w-1/4">
          <Applabel label="Title" />
          <select
            {...register("salutation")}
            placeholder="Salutation"
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
            disabled={!isEditable}
            value={watch("salutation")}
          >
            <option key="" value="">
              Select
            </option>
            {[
              "Mr",
              "Mrs",
              "Ms",
              "Miss",
              "Dr",
              "Prof",
              "Rev",
              "Hon",
              "Capt",
            ].map((salutation) => {
              return (
                <option key={salutation} value={salutation}>
                  {salutation}
                </option>
              );
            })}
          </select>
          {errors.salutation && (
            <ValidationError
              message={errors.salutation?.message?.toString() ?? ""}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
        <div className="flex flex-col  w-full md:w-1/3">
          <Applabel label="First Name" mandatory />
          <input
            {...register("firstName")}
            disabled={!isEditable}
            placeholder="Patient First Name"
            value={watch("firstName")}
            type="text"
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
          />
          {errors.firstName && (
            <ValidationError
              message={errors.firstName?.message?.toString() ?? ""}
            />
          )}
        </div>
        <div className="flex flex-col  w-full md:w-1/3">
          <Applabel label="Middle Name" />
          <input
            {...register("middleName")}
            placeholder="Patient Middle Name"
            disabled={!isEditable}
            value={watch("middleName")}
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
          />
          {errors.middleName && (
            <ValidationError
              message={errors.middleName?.message?.toString() ?? ""}
            />
          )}
        </div>
        <div className="flex flex-col  w-full md:w-1/3">
          <Applabel label="Last Name" mandatory />
          <input
            {...register("lastName")}
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
            placeholder="Patient Last Name"
            disabled={!isEditable}
            value={watch("lastName")}
          />
          {errors.lastName && (
            <ValidationError
              message={errors.lastName?.message?.toString() ?? ""}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
        <div className="flex flex-col  w-full md:w-1/3">
          <Applabel label="Date of Birth" mandatory />
          <input
            {...register("dob")}
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
            type="date"
            disabled={!isEditable}
            value={moment(watch("dob")).format("YYYY-MM-DD")}
            onChange={(event) => {
              const birthdate = new Date(event.target.value);

              const currentDate = new Date();
              let age = currentDate.getFullYear() - birthdate.getFullYear();

              // Check if the birthdate has occurred this year already
              if (
                birthdate.getMonth() > currentDate.getMonth() ||
                (birthdate.getMonth() === currentDate.getMonth() &&
                  birthdate.getDate() > currentDate.getDate())
              ) {
                age = age - 1;
              }
              setValue("age", age);
              setValue("dob", moment(event.target.value).format("YYYY-MM-DD"));
            }}
          />
          {errors.dob && (
            <ValidationError message={errors.dob?.message?.toString() ?? ""} />
          )}
        </div>
        <div className="flex flex-col  w-full md:w-1/3">
          <Applabel label="Age" />
          <input
            {...register("age")}
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
            type="number"
            min={1}
            max={200}
            placeholder="Patient Age"
            disabled
            value={watch("age")}
          />
          {errors.age && (
            <ValidationError message={errors.age?.message?.toString() ?? ""} />
          )}
        </div>
        <div className="flex flex-col  w-full md:w-1/3">
          <Applabel label="Place of Birth" />
          <input
            {...register("birthPlace")}
            placeholder="Patient Place of Birth"
            disabled={!isEditable}
            value={watch("birthPlace")}
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
          />
          {errors.birthPlace && (
            <ValidationError
              message={errors.birthPlace?.message?.toString() ?? ""}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 mb-4">
        <div className="flex flex-col  w-full md:w-1/3">
          <Applabel label="Gender" mandatory />
          <select
            {...register("gender")}
            placeholder="Patient Gender"
            disabled={!isEditable}
            value={watch("gender")}
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
          >
            <option key="" value="">
              Select
            </option>
            {["Male", "Female", "Other"].map((Gender) => {
              return (
                <option key={Gender} value={Gender}>
                  {Gender}
                </option>
              );
            })}
          </select>
          {errors.gender && (
            <ValidationError
              message={errors.gender?.message?.toString() ?? ""}
            />
          )}
        </div>
        <div className="flex flex-col  w-full md:w-1/3">
          <Applabel label="Email" />
          <input
            {...register("email")}
            placeholder="Patient Email"
            disabled={!isEditable}
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
            value={watch("email")}
            required
          />
          {errors.email && (
            <ValidationError
              message={errors.email?.message?.toString() ?? ""}
            />
          )}
        </div>
        <div className="flex flex-col  w-full md:w-1/3">
          <Applabel label="Mobile Number" mandatory />
          <PhoneInput
            country={"IN"}
            autoFormat={false}
            preferredCountries={["IN", "US", "UK", "AU"]}
            countryCodeEditable={false}
            enableSearch={true}
            specialLabel="Mobile Number"
            inputClass=" w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 mt-1"
            value={phoneNumber}
            disabled={!isEditable}
            inputProps={{ name: "phone" }}
            onChange={(value, data: any) => {
              setPhoneNumber(value);
              if (value) {
                setValue(
                  "phoneCode",
                  parseInt(value.substring(0, data.dialCode.length))
                );
                setValue(
                  "phone",
                  parseInt(value.substring(data.dialCode.length))
                );
              } else {
                setValue("phone", 0);
                setValue("phoneCode", 0);
              }
            }}
          />
          {errors.phone && (
            <ValidationError
              message={errors.phone?.message?.toString() ?? ""}
            />
          )}
        </div>
      </div>
      <span className="flex-none font-medium w-1/6 mb-0">
        Correspondence Address
      </span>
      <br />
      <span className="text-gray-500">
        Please fill the patient's most recent correspondence address below
      </span>
      <hr className="my-2" />
      <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
        <div className="flex flex-col grow">
          <Applabel label="Address" mandatory />

          <input
            {...register("address.address")}
            placeholder="Patient Address"
            disabled={!isEditable}
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
            required
          />
          {errors.address?.address && (
            <ValidationError
              message={errors.address?.address?.message?.toString() ?? ""}
            />
          )}
        </div>
        <div className="flex flex-col grow">
          <Applabel label="Postal/Zip code" mandatory />

          <input
            {...register("address.postalCode")}
            placeholder="Patient Zip Code"
            disabled={!isEditable}
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
            required
            value={watch("address.postalCode")}
          />
          {errors.address?.postalCode && (
            <ValidationError
              message={errors.address.postalCode?.message?.toString() ?? ""}
            />
          )}
        </div>
      </div>
      <CountryInputSelect
        isDisabled={!isEditable}
        register={register}
        setValue={setValue}
        watch={watch}
        country="address.country"
        state="address.state"
        city="address.city"
      />
      <span className="flex-none font-medium w-1/6 mb-0">Medical Details</span>
      <br />
      <span className="text-gray-500">
        Please enter the patient's medical information below
      </span>
      <hr className="my-2" />
      <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 mb-4">
        <div className="flex flex-col grow">
          <Applabel label="Blood Group" />

          <select
            {...register("bloodGroup")}
            placeholder="BloodGroup"
            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
            disabled={!isEditable}
            value={watch("bloodGroup")}
          >
            <option key="" value="">
              Select
            </option>
            {[
              "A +ve",
              "A -ve",
              "B +ve",
              "B -ve",
              "AB +ve",
              "AB -ve",
              "O +ve",
              "O -ve",
            ].map((bloodGroup) => {
              return (
                <option key={bloodGroup} value={bloodGroup}>
                  {bloodGroup}
                </option>
              );
            })}
          </select>
          {errors.bloodGroup && (
            <ValidationError
              message={errors.bloodGroup?.message?.toString() ?? ""}
            />
          )}
        </div>
        <div className="flex flex-col grow">
          <Applabel label="Emergency Phone" />
          <PhoneInput
            country={"IN"}
            autoFormat={false}
            preferredCountries={["IN", "US", "UK", "AU"]}
            countryCodeEditable={false}
            enableSearch={true}
            specialLabel="Emergency Mobile Number"
            inputClass=" w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 mt-1"
            value={emergencyPhoneNumber}
            disabled={!isEditable}
            inputProps={{ name: "emergencyPhone" }}
            onChange={(value, data: any, event, formattedValue) => {
              setEmergencyPhoneNumber(value);
              if (value) {
                setValue(
                  "emergencyPhoneCode",
                  parseInt(value.substring(0, data.dialCode.length))
                );
                setValue(
                  "emergencyPhone",
                  parseInt(value.substring(data.dialCode.length))
                );
              } else {
                setValue("emergencyPhone", 0);
                setValue("emergencyPhoneCode", 0);
              }
            }}
          />
          {errors.emergencyPhone && (
            <ValidationError
              message={errors.emergencyPhone?.message?.toString() ?? ""}
            />
          )}
        </div>
      </div>
    </form>
  );
}
