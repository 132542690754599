import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import React, { useEffect } from "react";

import * as z from "zod";

import { Button, OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPlus,
  faPlusSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import RightOffcanvas from "@components/right-offcanvas/right-offcanvas";
import { ActiveExercise, Convert } from "../../type/ActiveExerciseProgram";
import Applabel from "@components/hook-form/applabel";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import AvailableHomeExercise from "./AvailableHomeExercise";
import { ValidationError } from "@components/hook-form";
import { Switch } from "@headlessui/react";
import ViewIndividualExercise from "./ViewIndividualExercise";
import { NewMasterAssessmentData } from "@pages/manage-patient-new/types/NewMasterAssessment";

export const ConfigSchema = z.object({
  sets: z.number().optional().default(0),
  repetitions: z.number().optional().default(0),
  hold: z.number().optional().default(0),
  interval: z.number().optional().default(0),
  side: z.string().optional(),
  weights: z.number().optional().default(0),
  weightsUnit: z.string().optional(),
  intervalUnit: z.string().optional(),
  holdUnit: z.string().optional(),
  dailyFrequency: z.number().optional().default(1),
});
export type Config = z.infer<typeof ConfigSchema>;

export const AssignedExerciseSchema = z.object({
  id: z.string(),
  title: z.string(),
  config: ConfigSchema.optional(),
  instructions: z.string(),
});
export type AssignedExercise = z.infer<typeof AssignedExerciseSchema>;

interface HomeExerciseStatus {
  homeExerciseProgramStatus: boolean;
}

export const ExerciseSchema = z.object({
  homeExerciseProgram: z.array(AssignedExerciseSchema),
  homeExerciseProgramStatus: z.boolean(),
});
export type ExerciseType = z.infer<typeof ExerciseSchema>;

export default function NewHomeExerciseProgram(props: {
  masterAssessmentData: NewMasterAssessmentData | null;
}) {
  const [activeExerciseProgram, setActiveExerciseProgram] = React.useState<
    ActiveExercise[] | null
  >(null);
  const [enabled, setEnabled] = React.useState<HomeExerciseStatus>({
    homeExerciseProgramStatus: true,
  });

  const [isRightOffcanvasOpenToAssignExe, setIsRightOffcanvasOpenToAssignExe] =
    React.useState(false);
  const [isRightOffcanvasOpenToViewExe, setIsRightOffcanvasOpenToViewExe] =
    React.useState(false);
  const [selectedExercise, setSelectedExercise] =
    React.useState<ActiveExercise>();

  // let { patientId } = useParams();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm<ExerciseType>({
    resolver: zodResolver(ExerciseSchema),
  });

  const { fields: activeHomeExerciseProgramList, remove } = useFieldArray({
    keyName: "_id", // This line is important because useField array will make its own field named id which will overwrite our id field in the object .so to prevent this we are telling useFieldArray to include its id as _id
    control,
    name: "homeExerciseProgram",
  });

  useEffect(() => {
    getAssignedExercises();
  }, [props.masterAssessmentData]);

  const postSubmitCallbackForAssignExe = () => {
    handleCloseRightOffCanvas();
    getAssignedExercises();
  };
  const postSubmitCallbackForViewExe = () => {
    setIsRightOffcanvasOpenToViewExe(false);
  };

  const getAssignedExercises = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["id", props.masterAssessmentData?.treatmentPlanId!],
      ]);
      const response = await axios.get(
        replaceParams(endpoints.managePatient.getAssignedExercises, paramsMap)
      );
      const data = response.data["data"]["homeExerciseProgram"];
      const data1 = response.data["data"]["homeExerciseProgramStatus"];

      const toSet: ActiveExercise[] = [];
      if (data !== null) {
        for (const exercise of data) {
          const converted = Convert.toActiveExerciseProgram(
            JSON.stringify(exercise)
          );
          toSet.push(converted);
        }
      }

      setActiveExerciseProgram(toSet);
      setEnabled({
        homeExerciseProgramStatus: data1,
      });
      reset({ homeExerciseProgram: toSet, homeExerciseProgramStatus: data1 });
    } catch (error) {
      toast.error(error.message);

      console.error("Error fetching active exercise program:", error);
    }
  };

  const handleOpenRightOffCanvas = () => {
    setIsRightOffcanvasOpenToAssignExe(true);
  };

  const handleCloseRightOffCanvas = () => {
    setIsRightOffcanvasOpenToAssignExe(false);
  };

  const handleDeleteExerciseFromProgram = async (exerciseId: string) => {
    try {
      const paramsMapTreatmentPlanId = new Map<string, string>([
        ["id", props.masterAssessmentData?.treatmentPlanId!],
        ["exerciseId", exerciseId],
      ]);
      const response = await axios.delete(
        replaceParams(
          endpoints.managePatient.deleteSingleAssignedExercice,
          paramsMapTreatmentPlanId
        )
      );
      if (response.data.status === 200)
        toast.success(
          response.data.message === "success"
            ? "Exercises deleted Successfully"
            : response.data.message
        );
      getAssignedExercises();
    } catch (error) {
      toast.error(error.message);
    }
  };
  const onEditSubmit: SubmitHandler<ExerciseType> = async (data) => {
    const updateAssignedExerciseDetails = async () => {
      try {
        const paramsMapTreatmentPlanId = new Map<string, string>([
          ["id", props.masterAssessmentData?.treatmentPlanId!],
        ]);

        axios.put(
          replaceParams(
            endpoints.managePatient.updateAssignedExerciseDetails,
            paramsMapTreatmentPlanId
          ),
          data
        );
        toast.success("Exercise updated successfully");
      } catch (error) {
        toast.error(error.data);
      }
    };
    updateAssignedExerciseDetails();
  };

  const handleProgramStatusChange = async (
    treatmentPlanId: string,
    homeProgramStatus: boolean
  ) => {
    try {
      const paramsMap = new Map<string, string>([
        ["id", treatmentPlanId],
        ["status", homeProgramStatus.toString()], // Convert boolean to string
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.managePatient.updateClientHomeExerciseProgrameStatus,
        paramsMap
      );
      setEnabled((prevEnabled) => {
        const newEnabled = {
          ...prevEnabled,
        };

        axios.put(filteredEndpoint, newEnabled).then((response) => {
          if (response.data.status === 200) {
            toast.success(
              response.data.message === "success"
                ? "Status updated successfully"
                : response.data.message
            );
          }
        });
        return newEnabled;
      });
    } catch (error) {
      console.error("Error updating home exercise program status:", error);
      toast.error(error.message);
    }
  };

  const handleActiveInactiveExeProgram = async () => {
    setEnabled((prevEnabled) => ({
      ...prevEnabled,
      homeExerciseProgramStatus: !prevEnabled?.homeExerciseProgramStatus,
    }));
    await handleProgramStatusChange(
      props.masterAssessmentData?.treatmentPlanId!,
      !enabled?.homeExerciseProgramStatus
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onEditSubmit)} noValidate>
        <div className="flex flex-col justify-start lg:flex-row lg:justify-between lg:items-center p-2 rounded-2xl shadow bg-tertiary-100 mt-1">
          <div className="flex flex-col lg:flex-row md:flex-row w-full justify-end my-2">
            <div className="flex flex-row self-end items-center">
              <div className="flex flex-col md:flex-row lg:flex-row items-center justify-start mt-2">
                <Switch
                  onChange={handleActiveInactiveExeProgram}
                  checked={enabled.homeExerciseProgramStatus}
                  className={`${
                    enabled.homeExerciseProgramStatus
                      ? "bg-secondary-600"
                      : "bg-secondary-50"
                  }
                relative inline-flex  h-6 w-12  flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 mr-2`}
                >
                  <span
                    aria-hidden="true"
                    className={`${
                      enabled.homeExerciseProgramStatus
                        ? "translate-x-6"
                        : "translate-x-1"
                    }
                pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
                {enabled.homeExerciseProgramStatus ? (
                  <span className="text-gray-500 flex-shrink pl-2">Active</span>
                ) : (
                  <span className="text-gray-500 flex-shrink pl-2">
                    InActive
                  </span>
                )}
              </div>
              <div>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  key="submitButton"
                  type="submit"
                  className="ml-3 "
                  children="Save Program"
                />
              </div>
              <div>
                <Button
                  variant={ButtonVariant.SECONDARY}
                  type="button"
                  className="ml-3 "
                  onClick={() => {
                    handleOpenRightOffCanvas();
                  }}
                  children={
                    <div className="flex flex-row items-center">
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="h-4 w-4 mr-2 "
                        aria-hidden="true"
                      />
                      <span className="text-sm ">Add Exercise</span>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="my-2"></hr>

        <RightOffcanvas
          isOpen={
            isRightOffcanvasOpenToAssignExe || isRightOffcanvasOpenToViewExe
          }
          onClose={handleCloseRightOffCanvas}
          sizeClasses="w-full md:w-[100vh]"
        >
          {isRightOffcanvasOpenToAssignExe ? (
            <AvailableHomeExercise
              treatmentPlanId={props.masterAssessmentData?.treatmentPlanId!}
              postSubmitCallback={postSubmitCallbackForAssignExe}
              onClose={handleCloseRightOffCanvas}
            />
          ) : isRightOffcanvasOpenToViewExe ? (
            <ViewIndividualExercise
              exercise={selectedExercise ?? undefined}
              postSubmitCallback={postSubmitCallbackForViewExe}
            />
          ) : null}
        </RightOffcanvas>
        <div className="flex flex-col space-y-6">
          {(activeHomeExerciseProgramList?.length == 0 ||
            activeHomeExerciseProgramList == null) && (
            <p>No Exercises Assigned </p>
          )}
          {activeHomeExerciseProgramList &&
            activeHomeExerciseProgramList.map((e, index) => (
              <div className="flex flex-col justify-between outline outline-gray-200 outline-1 rounded-2xl shadow-md bg-white overflow-hidden">
                <div className="flex md:flex-row flex-col rounded-xl bg-white-300 p-4">
                  <div className="flex flex-col w-full lg:w-2/3">
                    <div className="flex flex-col lg:flex-row w-full">
                      <div className="w-full lg:w-5/6">
                        <Applabel label="Title" />
                        <input
                          {...register(`homeExerciseProgram.${index}.title`)}
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          value={watch(`homeExerciseProgram.${index}.title`)}
                        />
                      </div>
                      <div className="w-1/2 lg:ml-2 lg:w-1/6">
                        <Applabel label="Daily frequency" />
                        <input
                          type="number"
                          {...register(
                            `homeExerciseProgram.${index}.config.dailyFrequency`,
                            {
                              valueAsNumber: true,
                            }
                          )}
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          min={1}
                          value={watch(
                            `homeExerciseProgram.${index}.config.dailyFrequency`
                          )}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-2 xl:grid-cols-6 xl:grid-rows-0  space-x-2 ">
                      <div className="flex flex-col flex-grow">
                        <Applabel label="Sets" />
                        <input
                          type="number"
                          {...register(
                            `homeExerciseProgram.${index}.config.sets`,
                            {
                              valueAsNumber: true,
                            }
                          )}
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          value={watch(
                            `homeExerciseProgram.${index}.config.sets`
                          )}
                        />
                      </div>

                      <div className="flex flex-col flex-grow">
                        <Applabel label="Reps" />
                        <input
                          type="number"
                          {...register(
                            `homeExerciseProgram.${index}.config.repetitions`,
                            {
                              valueAsNumber: true,
                            }
                          )}
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          value={watch(
                            `homeExerciseProgram.${index}.config.repetitions`
                          )}
                        />
                      </div>
                      <div className="flex flex-col flex-grow">
                        <Applabel label="Hold" />
                        <div className="w-full relative">
                          <input
                            {...register(
                              `homeExerciseProgram.${index}.config.hold`,
                              {
                                valueAsNumber: true,
                              }
                            )}
                            placeholder="Hold"
                            value={watch(
                              `homeExerciseProgram.${index}.config.hold`
                            )}
                            type="number"
                            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center">
                            <select
                              {...register(
                                `homeExerciseProgram.${index}.config.holdUnit`
                              )}
                              placeholder="Hold Unit"
                              className={`h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm`}
                              value={watch(
                                `homeExerciseProgram.${index}.config.holdUnit`
                              )}
                            >
                              {["Sec", "Min"].map((intervalUnit) => {
                                return (
                                  <option
                                    key={intervalUnit}
                                    value={intervalUnit}
                                  >
                                    {intervalUnit}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col flex-grow ">
                        <Applabel label="Interval" />
                        <div className="w-full relative">
                          <input
                            {...register(
                              `homeExerciseProgram.${index}.config.interval`,
                              {
                                valueAsNumber: true,
                              }
                            )}
                            placeholder="Interval"
                            value={watch(
                              `homeExerciseProgram.${index}.config.interval`
                            )}
                            type="number"
                            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center">
                            <select
                              {...register(
                                `homeExerciseProgram.${index}.config.intervalUnit`
                              )}
                              placeholder="Interval Unit"
                              className={`h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm`}
                              value={watch(
                                `homeExerciseProgram.${index}.config.intervalUnit`
                              )}
                            >
                              {["Sec", "Min"].map((intervalUnit) => {
                                return (
                                  <option
                                    key={intervalUnit}
                                    value={intervalUnit}
                                  >
                                    {intervalUnit}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col flex-grow basis-2/6">
                        <Applabel label="Side" />
                        <div className="w-full relative">
                          <select
                            {...register(
                              `homeExerciseProgram.${index}.config.side`
                            )}
                            placeholder="Side"
                            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                            value={watch(
                              `homeExerciseProgram.${index}.config.side`
                            )}
                          >
                            <option key="" value="">
                              Select
                            </option>
                            {["None", "Left", "Right", "Both"].map(
                              (difficulty) => {
                                return (
                                  <option key={difficulty} value={difficulty}>
                                    {difficulty}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="flex flex-col flex-grow">
                        <Applabel label="Weights" />
                        <div className="w-full relative">
                          <input
                            {...register(
                              `homeExerciseProgram.${index}.config.weights`,
                              {
                                valueAsNumber: true,
                                min: 0,
                              }
                            )}
                            placeholder="Weights"
                            value={watch(
                              `homeExerciseProgram.${index}.config.weights`
                            )}
                            type="number"
                            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center">
                            <select
                              {...register(
                                `homeExerciseProgram.${index}.config.weightsUnit`
                              )}
                              placeholder="Weight Unit"
                              className={`h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm`}
                              value={watch(
                                `homeExerciseProgram.${index}.config.weightsUnit`
                              )}
                            >
                              {["Kgs", "Lbs"].map((weightUnit) => {
                                return (
                                  <option key={weightUnit} value={weightUnit}>
                                    {weightUnit}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full lg:w-1/3 ml-2">
                    <Applabel label="Instruction" />
                    <textarea
                      {...register(`homeExerciseProgram.${index}.instructions`)}
                      rows={4}
                      placeholder="Instructions"
                      className={`lock w-full row-span-4 rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                      value={watch(`homeExerciseProgram.${index}.instructions`)}
                    />
                  </div>
                </div>
                <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-2">
                  <div className="flex flex-row w-full justify-end">
                    <Button
                      variant={ButtonVariant.PRIMARY}
                      type="button"
                      className="ml-3 bg-red-700 hover:bg-red-600 focus:ring-red-600 focus:ring-offset-red-200 text-white shadow-sm"
                      onClick={() => handleDeleteExerciseFromProgram(e.id!)}
                      children="Delete"
                    />
                    <Button
                      variant={ButtonVariant.SECONDARY}
                      type="button"
                      className="ml-3 "
                      onClick={() => {
                        setSelectedExercise(
                          getValues(`homeExerciseProgram.${index}`)
                        );
                        setIsRightOffcanvasOpenToViewExe(true);
                      }}
                      children="View"
                    />
                    {/* <OutlinedButton
                        variant={ButtonVariant.SECONDARY}
                        type="button"
                        onClick={() => {
                          setSelectedExercise(
                            getValues(`homeExerciseProgram.${index}`),
                          );
                          setIsRightOffcanvasOpenToViewExe(true);
                        }}
                        children="View"
                      /> */}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </form>
    </div>
  );
}
