import { OutlinedButton } from "@components/button";
import axios from "axios";
import React from "react";
import {
  Convert,
  PatientPaymentDetailsDomain,
  PaymentDetail,
} from "../types/PatientPaymentDetailsDomain";
import { PersonalInformationDetails } from "../types/PatientPersonalDetails";

import PaymentDetails from "@pages/payments/PaymentDetails";

import ViewBill from "./ViewBill";
import { endpoints, replaceParams } from "@utils/axios";
import {
  Appointment,
  Convert as ConvertAppointment,
  Appointment as PatientAppointment,
} from "@pages/appointments/types/Appointment";
import { ConvertSessionCredit, SessionCredits } from "../types/SessionCredits";
import moment from "moment";
import Button, { ButtonVariant } from "@components/button/outline-button";
import SolidButton from "@components/button/button";
import ViewPaymentDetails from "@pages/appointments/appointment-index-table/ViewPaymentDetails";
import AppointmentDetails, {
  CancelAppointmentDialog,
} from "@pages/appointments/appointment-index-table/AppointmentDetails";
import { Appointments } from "@pages/appointments/types/Appointments";
import RescheduleAppointmentPage from "@pages/appointments/reschedule-appointment/RescheduleAppointmentPage";
import BillingAndPaymentDetails from "@pages/payments/BillingAndPaymentDetails";

export default function PatientPaymentDetails(props: {
  patientId: string;
  onClose: () => void;
  patientPersonalInformation: PersonalInformationDetails | null;
}) {
  const [details, setDetails] =
    React.useState<PatientPaymentDetailsDomain | null>();
  const [credits, setCredits] = React.useState<SessionCredits | null>();

  const [patientAppointments, setPatientAppointments] = React.useState<
    PatientAppointment[] | null
  >(null);

  const [total, setTotal] = React.useState(0);
  const [totalAppointemnts, setTotalAppointments] = React.useState(0);
  const [showPaymentDetails, setShowPaymentDetails] = React.useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = React.useState("");
  const [appointmentDetails, setAppointmentDetails] =
    React.useState<Appointments | null>(null);
  const [addPaymentDetailsForAppt, setAddPaymentDetailsForAppt] =
    React.useState(false);

  const [addPaymentDetails, setAddPaymentDetails] = React.useState(false);
  const [patientChargesDialog, setPatientChargesDialog] = React.useState(false);

  const [viewBillDialog, setViewBill] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<PaymentDetail>();
  const [showAppointmentDetails, setShowAppointmentDetails] =
    React.useState(false);
  const [cancelDialog, setCancelDialog] = React.useState(false);
  const [appointmentId, setAppointmentId] = React.useState("");
  const [rescheduleAppointmentDialog, setRescheduleAppointmentDialog] =
    React.useState(false);
  const [appointment, setAppointment] = React.useState<Appointment | null>(
    null
  );
  const [activeTab, setActiveTab] = React.useState("Appointments Invoice");

  const getPatientPaymentDetails = async () => {
    const paramsMap = new Map<string, string>([["patientId", props.patientId]]);
    const filteredEndpoint = replaceParams(
      endpoints.patient.getPaymentDetails,
      paramsMap
    );
    const response = await axios.get(filteredEndpoint);
    const data = response.data["data"];

    const convertedDetails = Convert.toPatientPaymentDetails(
      JSON.stringify(data)
    );
    setDetails(convertedDetails);
    var toAddPayment: number = 0;
    if (convertedDetails.paymentDetails) {
      convertedDetails.paymentDetails.map((count) => {
        if (!isNaN(count.amount)) {
          toAddPayment = toAddPayment + count.amount;
        }
      });
      setTotal(toAddPayment);
    } else {
      setTotal(0);
    }
    setTotalAppointments(
      (convertedDetails.appointmentsCount.Consultation ?? 0) +
        (convertedDetails.appointmentsCount["Follow-up"] ?? 0) +
        (convertedDetails.appointmentsCount.Therapy ?? 0)
    );
  };
  const getPatientAppointments = async () => {
    const response = await axios.get(endpoints.appointment.list, {
      params: { clientid: props.patientId },
    });

    const data = response.data["data"];
    const convertedAppointmentList = data.map((appointment: any) => {
      let appointmentShadow = ConvertAppointment.toAppointment(
        JSON.stringify(appointment)
      );
      let duration = appointment.endTimeMinutes - appointment.startTimeMinutes;

      appointmentShadow.apptdate = new Date(
        moment(appointmentShadow.apptdate).toLocaleString()
      );

      appointmentShadow.startTime = moment(appointmentShadow.apptdate).format(
        "hh:mm A"
      );
      appointmentShadow.endTime = moment(appointment.startTime, "hh:mm A")
        .add(duration, "minutes")
        .format("hh:mm A");
      appointmentShadow.startTimeMinutes =
        appointmentShadow.apptdate.getHours() * 60 +
        appointmentShadow.apptdate.getMinutes();
      appointmentShadow.endTimeMinutes =
        appointmentShadow.apptdate.getHours() * 60 +
        appointmentShadow.apptdate.getMinutes() +
        duration;
      return appointmentShadow;
    });
    setPatientAppointments(convertedAppointmentList);
  };

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };
  /*  const getPatientAppointments = async () => {
     const paramsMap = new Map<string, string>([['id', props.patientId]]);
     const filteredEndpoint = replaceParams(
       endpoints.patient.getPaymentAppointments,
       paramsMap,
     );
     const response = await axios.get(filteredEndpoint);
     const data = response.data['data'];
 
     const convertedList = data.map((staff: any) =>
       Conversion.toPatientAppointment(JSON.stringify(staff)),
     );
     setPatientAppointments(convertedList);
   };
  */
  const getPatientRemainingCredits = async () => {
    const paramsMap = new Map<string, string>([["id", props.patientId]]);
    const filteredEndpoint = replaceParams(
      endpoints.patient.getPatientRemainingCredits,
      paramsMap
    );
    const response = await axios.get(filteredEndpoint);
    const data = response.data["data"];
    const converted = ConvertSessionCredit.toSessionCredits(
      JSON.stringify(data)
    );
    setCredits(converted);
  };

  React.useEffect(() => {
    const _init = async () => {
      await getPatientPaymentDetails();
      await getPatientAppointments();
      await getPatientRemainingCredits();
    };
    _init();
  }, []);

  const handleViewBillClose = () => {
    setViewBill(false);
  };

  const handleChargesDialogClose = () => {
    setPatientChargesDialog(false);
  };

  const handleViewBillOpen = (detail: PaymentDetail) => {
    setSelectedRow(detail);
    setViewBill(true);
  };

  const callback = async () => {
    await getPatientPaymentDetails();
    await getPatientAppointments();
    await getPatientRemainingCredits();
  };
  const handleStatusChange = async (
    appointmentId: string,
    appointmentStatus: string
  ) => {
    try {
      const paramsMap = new Map<string, string>([
        ["id", appointmentId],
        ["status", appointmentStatus],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.assistantDashboard.updateAppointmentStatus,
        paramsMap
      );
      await axios.put(filteredEndpoint);
      getPatientAppointments();
    } catch (error) {
      console.error(error);
    }
  };

  const showDialog = (appId: string) => {
    setAppointmentId(appId);
    setShowAppointmentDetails(true);
  };
  return (
    <div className="flex h-full w-full flex-col ">
      <div className="flex items-center justify-between">
        <div className="min-w-0 flex-1 mr-4">
          <h2 className="text-xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
            Appointment Details
          </h2>
        </div>
        <div className="flex flex-shrink-0 md:ml-4 md:mt-0 justify-end">
          <Button
            type="button"
            onClick={props.onClose}
            className="mr-2"
            variant={ButtonVariant.PRIMARY}
          >
            Close
          </Button>
        </div>
      </div>
      <hr className="mt-2 h-0.5 bg-white-300 opacity-100 dark:opacity-50" />
      <div className="hidden lg:flex flex-col md:flex-row mt-2">
        <div className="md:flex">
          <div className="flex flex-col mr-1">
            <div className="flex flex-row justify-between  px-2">
              <div className="flex font-bold py-2">
                Completed Appointment/Sessions
              </div>
            </div>
            <div className="">
              <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-x md:divide-y-0">
                <div className="px-4 py-5 sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">
                    Total
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                    {totalAppointemnts}
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">
                    Consultations
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                    {details?.appointmentsCount.Consultation ?? 0}
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">
                    Follow-up
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                    {details?.appointmentsCount["Follow-up"] ?? 0}
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">
                    Therapy
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                    {details?.appointmentsCount.Therapy ?? 0}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-left text-sm text-gray-500 rtl:text-right ">
                <thead className="text-xs uppercase text-gray-700 bg-tertiary-100 sticky top-0 z-10">
                  <tr>
                    <th scope="col" className="px-6 py-3 ">
                      Date/Time
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Type
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Bill Amount
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Payment Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {patientAppointments &&
                    patientAppointments.map((appt) => (
                      <tr className="border-b border-gray-200 dark:border-gray-700">
                        <td scope="row" className="px-6 py-4 ">
                          {moment(new Date(appt.apptdate)).format(
                            "DD-MM-YYYY hh:mm a"
                          )}
                        </td>
                        <td className="px-6 py-4">{appt.appointmentType}</td>
                        <td className="px-6 py-4 ">{appt.appointmentStatus}</td>
                        <td className="px-6 py-4">₹{appt.billAmount}</td>
                        <td className="px-6 py-4">
                          <div className="flex flex-row">
                            <div className="flex-shrink mr-2">
                              <OutlinedButton
                                variant={ButtonVariant.SECONDARY}
                                type="button"
                                onClick={() => showDialog(appt.id)}
                                children="View"
                              />
                            </div>
                            {appt.appointmentStatus &&
                              appt.appointmentStatus.toLowerCase() ===
                                "completed" && (
                                <div className="flex-shrink">
                                  {/*   {appt.paymentStatus === "Paid" && (
                                    <SolidButton
                                      variant={ButtonVariant.SECONDARY}
                                      type="button"
                                      onClick={() => {
                                        setSelectedAppointmentId(appt.id);
                                        setAppointmentDetails(appt);
                                        setShowPaymentDetails(true);
                                      }}
                                      children="Paid"
                                    />
                                  )} */}

                                  {appt.paymentStatus === "" && (
                                    <OutlinedButton
                                      variant={ButtonVariant.SECONDARY}
                                      type="button"
                                      onClick={() => {
                                        setSelectedAppointmentId(appt.id);
                                        setAppointmentDetails(appt);
                                        setAddPaymentDetailsForAppt(true);
                                      }}
                                      children="Add Bill Details"
                                    />
                                  )}
                                </div>
                              )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/*         <hr className="my-4 h-1 bg-slate-200" />
         */}
        {/*  <div className="flex md:basis-1/2">
          <div className="flex flex-col mr-1">
            <div className="flex flex-row md:justify-between pl-2 pr-1">
              <div className="flex font-bold py-2 mr-2">Payment Details </div>
              <div className="flex">
                <SolidButton
                  variant={ButtonVariant.PRIMARY}
                  type="button"
                  onClick={() => setAddPaymentDetails(true)}
                  className="mr-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    className=""
                    width="1.2em"
                    height="1.2em"
                    viewBox="0 0 24 24"
                  >
                    <g fill="none">
                      <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
                      <path
                        fill="currentColor"
                        d="M11 20a1 1 0 1 0 2 0v-7h7a1 1 0 1 0 0-2h-7V4a1 1 0 1 0-2 0v7H4a1 1 0 1 0 0 2h7z"
                      ></path>
                    </g>
                  </svg>
                  Add Payment
                </SolidButton>
              </div>
            </div>
            <div className="">
              <dl className="mt-5 grid grid-cols-3 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:divide-x md:divide-y-0">
                <div className="px-4 py-5 sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">
                    Total Payments
                  </dt>
                  <dd className="mt-1 md:text-3xl font-semibold tracking-tight text-gray-900">
                    ₹{total}
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">
                    Outstanding/Due Amount
                  </dt>
                  <dd className="mt-1 md:text-3xl font-semibold tracking-tight text-gray-900">
                    ₹{credits?.OutstandingAmount ?? 0}
                  </dd>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">
                    Remaining Credits
                  </dt>
                  <dd className="mt-1 md:text-3xl font-semibold tracking-tight text-gray-900">
                    {credits?.SessionCredits ?? 0}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-left text-sm text-gray-500 rtl:text-right ">
                <thead className="text-xs uppercase text-gray-700 bg-tertiary-100 sticky top-0 z-10">
                  <tr>
                    <th scope="col" className="px-6 py-3 ">
                      Date
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Paid
                    </th>
                    <th scope="col" className="px-6 py-3 ">
                      Type
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Sessions++
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Outstanding
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Download
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {details?.paymentDetails == null && <>No data found</>}
                  {details?.paymentDetails &&
                    details.paymentDetails.map((d) => (
                      <tr className="border-b border-gray-200 dark:border-gray-700">
                        <td scope="row" className="px-6 py-4">
                          {d.createdDate}
                        </td>
                        <td className="px-6 py-4">₹{d.amount}</td>
                        <td className=" px-6 py-4 ">{d.paymentType}</td>
                        <td className="px-6 py-4">{d.noOfSessions ?? 1}</td>
                        <td className="px-6 py-4">
                          {d.outstandingAmount && "₹" + d.outstandingAmount}
                        </td>
                        <td className="px-6 py-4">
                          <span
                            onClick={() => {
                              handleViewBillOpen(d);
                            }}
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                          >
                            Download/Print
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
                          </div> */}
      </div>
      <div className="flex flex-col md:flex-row mt-2 lg:hidden">
        <div className="flex flex-col mt-2">
          <div className="flex space-x-4">
            <button
              className={`px-4 py-2 rounded-md ${
                activeTab === "Appointments Invoice" ? "bg-gray-200" : ""
              }`}
              onClick={() => handleTabClick("Appointments Invoice")}
            >
              Appointments
            </button>
            {/*             <button
              className={`px-4 py-2 rounded-md ${
                activeTab === "Payments" ? "bg-gray-200" : ""
              }`}
              onClick={() => handleTabClick("Payments")}
            >
              Payments 
            </button>*/}
          </div>

          {/* Content */}
          <div className="md:flex md:flex-row lg:hidden">
            {activeTab === "Appointments Invoice" && (
              <div className="md:flex mr-5">
                <div className="flex flex-col mr-1">
                  <div className="flex flex-row justify-between mt-4 px-2">
                    <div className="flex font-bold py-2">
                      Completed Appointment/Sessions
                    </div>
                  </div>
                  <div className="">
                    <dl className="mt-5 grid grid-cols-2 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-x md:divide-y-0">
                      <div className="px-4 py-5 sm:p-6">
                        <dt className="truncate text-sm font-medium text-gray-500">
                          Total
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                          {totalAppointemnts}
                        </dd>
                      </div>
                      <div className="px-4 py-5 sm:p-6">
                        <dt className="truncate text-sm font-medium text-gray-500">
                          Consultations
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                          {details?.appointmentsCount.Consultation ?? 0}
                        </dd>
                      </div>
                      <div className="px-4 py-5 sm:p-6">
                        <dt className="truncate text-sm font-medium text-gray-500">
                          Follow-up
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                          {details?.appointmentsCount["Follow-up"] ?? 0}
                        </dd>
                      </div>
                      <div className="px-4 py-5 sm:p-6">
                        <dt className="truncate text-sm font-medium text-gray-500">
                          Therapy
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                          {details?.appointmentsCount.Therapy ?? 0}
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <ul className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2 bg-gray-50 p-1 ">
                    {patientAppointments &&
                      patientAppointments.map((appt) => (
                        <div className="flex flex-col justify-between rounded-2xl shadow-md bg-white mb-2 mr-1">
                          <div
                            className="flex flex-row rounded-xl bg-white-300 items-center justify-between p-4 pb-0"
                            onClick={() => {}}
                          >
                            <div className="flex flex-col w-2/3">
                              <span className="flex-initial text-md text-black font-bold w-full truncate">
                                {appt.appointmentType}
                              </span>
                              <span className="flex-initial text-sm text-gray-400">
                                {appt.appointmentStatus}
                              </span>
                              <span className="flex-initial text-sm text-gray-400">
                                ₹{appt.billAmount}
                              </span>
                              <hr className="mt-2 h-0.5 bg-white-300 opacity-100 dark:opacity-50" />
                            </div>
                            <div className="flex flex-col w-1/3">
                              <span className="flex-initial text-sm text-gray-400">
                                {moment(new Date(appt.apptdate)).format(
                                  "DD-MM-YYYY hh:mm a"
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4 mt-2">
                            <div className="flex flex-row w-full justify-end mr-2">
                              <OutlinedButton
                                variant={ButtonVariant.SECONDARY}
                                type="button"
                                onClick={() => showDialog(appt.id)}
                                children="View"
                              />
                            </div>
                            {appt.appointmentStatus &&
                              appt.appointmentStatus.toLowerCase() ===
                                "completed" && (
                                <div className="flex-shrink">
                                  {appt.paymentStatus === "Paid" && (
                                    <SolidButton
                                      variant={ButtonVariant.SECONDARY}
                                      type="button"
                                      onClick={() => {
                                        setSelectedAppointmentId(appt.id);
                                        setAppointmentDetails(appt);
                                        setShowPaymentDetails(true);
                                      }}
                                      children="Paid"
                                    />
                                  )}

                                  {appt.paymentStatus === "" && (
                                    <OutlinedButton
                                      variant={ButtonVariant.SECONDARY}
                                      type="button"
                                      onClick={() => {
                                        setSelectedAppointmentId(appt.id);
                                        setAppointmentDetails(appt);
                                        setAddPaymentDetailsForAppt(true);
                                      }}
                                      children="Pay"
                                    />
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                      ))}
                  </ul>
                </div>
              </div>
            )}

            {/* Payments Tab Content */}
            {/*            {activeTab === "Payments" && (
              <div className="flex">
                <div className="flex flex-col mr-1">
                  <div className="flex flex-row md:justify-between pl-2 pr-1 mt-4">
                    <div className="flex font-bold py-2 mr-2">
                      Payment Details{" "}
                    </div>
                    <div className="flex">
                      <SolidButton
                        variant={ButtonVariant.PRIMARY}
                        type="button"
                        onClick={() => setAddPaymentDetails(true)}
                        className="mr-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="img"
                          className=""
                          width="1.2em"
                          height="1.2em"
                          viewBox="0 0 24 24"
                        >
                          <g fill="none">
                            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
                            <path
                              fill="currentColor"
                              d="M11 20a1 1 0 1 0 2 0v-7h7a1 1 0 1 0 0-2h-7V4a1 1 0 1 0-2 0v7H4a1 1 0 1 0 0 2h7z"
                            ></path>
                          </g>
                        </svg>
                        Add Payment
                      </SolidButton>
                    </div>
                  </div>
                  <div className="">
                    <dl className="mt-5 grid grid-cols-2 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
                      <div className="px-4 py-5 sm:p-6">
                        <dt className="truncate text-sm font-medium text-gray-500">
                          Total Payments
                        </dt>
                        <dd className="mt-1 md:text-3xl font-semibold tracking-tight text-gray-900">
                          ₹{total}
                        </dd>
                      </div>
                      <div className="px-4 py-5 sm:p-6">
                        <dt className="truncate text-sm font-medium text-gray-500">
                          Outstanding/Due Amount
                        </dt>
                        <dd className="mt-1 md:text-3xl font-semibold tracking-tight text-gray-900">
                          ₹{credits?.OutstandingAmount ?? 0}
                        </dd>
                      </div>
                      <div className="px-4 py-5 sm:p-6">
                        <dt className="truncate text-sm font-medium text-gray-500">
                          Remaining Credits
                        </dt>
                        <dd className="mt-1 md:text-3xl font-semibold tracking-tight text-gray-900">
                          {credits?.SessionCredits ?? 0}
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <ul className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2 bg-gray-50 p-1 ">
                    {details?.paymentDetails == null && <>No data found</>}
                    {details?.paymentDetails &&
                      details.paymentDetails.map((d) => (
                        <div className="flex flex-col justify-between rounded-2xl shadow-md bg-white mb-2 mr-1">
                          <div
                            className="flex flex-row rounded-xl bg-white-300 justify-between p-4 pb-0"
                            onClick={() => { }}
                          >
                            <div className="flex flex-col w-1/5 bg-secondary-100 rounded-xl">
                              <span className="flex-initial text-md text-secondary-400 font-bold w-full truncate text-center">
                                ₹{d.amount}
                              </span>
                            </div>
                            <div className="flex flex-col w-1/3">
                              <span className="flex-initial text-md text-black font-bold w-full truncate">
                                {d.paymentMode}
                              </span>
                              <span className="flex-initial text-sm text-gray-400">
                                {d.noOfSessions ?? 1}
                              </span>
                            </div>
                            <div className="flex flex-col w-1/3 ">
                              <span className="flex-initial text-sm text-gray-400">
                                {d.createdDate}
                              </span>
                              {d.outstandingAmount > 0 && (
                                <span className="flex-initial text-sm text-gray-400">
                                  Unpaid{" "}
                                  {d.outstandingAmount &&
                                    "₹" + d.outstandingAmount}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4 mt-2">
                            <span
                              onClick={() => {
                                handleViewBillOpen(d);
                              }}
                              className="font-medium text-blue-600 dark:text-blue-500 hover:underline border-secondary-300"
                            >
                              Download/Print
                            </span>
                          </div>
                        </div>
                      ))}
                  </ul>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
      {addPaymentDetails && (
        <PaymentDetails
          onConfirm={() => callback()}
          patientId={props.patientId}
          outStandingAmount={credits?.OutstandingAmount}
          onClose={() => setAddPaymentDetails(false)}
          patientPersonalInformation={props.patientPersonalInformation}
        />
      )}
      {showAppointmentDetails && (
        <AppointmentDetails
          appId={appointmentId}
          setShowAppointmentDetails={setShowAppointmentDetails}
          setRescheduleAppointmentDialog={setRescheduleAppointmentDialog}
          setCancelDialog={setCancelDialog}
          setAppointment={setAppointment}
          appointment={appointment}
        />
      )}
      {showPaymentDetails && (
        <ViewPaymentDetails
          onClose={() => setShowPaymentDetails(false)}
          onConfirm={() => {}}
          appointmentId={selectedAppointmentId}
          appointmentDetails={appointmentDetails!}
        />
      )}
      {viewBillDialog && (
        <ViewBill
          selectedRow={selectedRow}
          handleViewBillClose={handleViewBillClose}
          patientPersonalInformation={props.patientPersonalInformation}
        />
      )}

      {addPaymentDetailsForAppt && (
        <BillingAndPaymentDetails
          appointmentDetails={appointmentDetails!}
          outStandingAmount={credits?.OutstandingAmount}
          onClose={() => setAddPaymentDetailsForAppt(false)}
          onConfirm={() =>
            handleStatusChange(selectedAppointmentId, "Completed")
          }
        />
      )}
      {cancelDialog && (
        <CancelAppointmentDialog
          setCancelDialog={setCancelDialog}
          appId={appointmentId}
          getAppointments={getPatientAppointments}
        />
      )}
      {rescheduleAppointmentDialog && (
        <>
          {appointment && (
            <RescheduleAppointmentPage
              onClose={() => setRescheduleAppointmentDialog(false)}
              appointmentDetails={appointment}
              getAppointments={getPatientAppointments}
            />
          )}
        </>
      )}
    </div>
  );
}
