import {
  getUserPersistedOnLocalStorage,
  setSession,
  setUserData,
} from '@authentication/context/jwt/utils';
import { AuthTokensType } from '@authentication/types';
import { STORAGE_KEYS } from '@constants/storage-keys';
import { endpoints } from '@utils/axios';
import axios from 'axios';

const useRefreshToken = () => {
  const refresh = async () => {
    const userData = getUserPersistedOnLocalStorage();
    const email = userData?.email;
    const refresh_token = sessionStorage.getItem(STORAGE_KEYS.APP_STATE.REFRESH_TOKEN);

    const data: Record<string, string> = {
      email: email ?? '',
      refresh_token: refresh_token ?? '',
    };

    const response = await axios.post(endpoints.auth.refresh, data);

    const tokens: AuthTokensType = {
      accessToken: response.data['data'].access_token,
      refreshToken: response.data['data'].refresh_token,
    };

    setSession(tokens.accessToken, tokens.refreshToken);

    setUserData(tokens.accessToken);

    return response.data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
