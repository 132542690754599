// To parse this data:
//
//   import { Convert, Posture } from "./file";
//
//   const posture = Convert.toPosture(json);

export type Vertigo = {
  id?: string;
  masterAssessmentId?: string;
  clientId?: string;
  entityId?: string;
  entityBranchId?: string;
  isSmoothPursuitChk?: boolean;
  smoothPursuitObservation?: string;
  isSaccadesChk?: boolean;
  saccadesObservation?: string;
  isFukudaStepTestChk?: boolean;
  fukudaStepTestObservation?: string;
  isMigraineChk?: boolean;
  migraineObservation?: string;
  isNeckTightnessChk?: boolean;
  neckTightnessObservation?: string;
  isAnxietyChk?: boolean;
  anxietyObservation?: string;
  isSleepChk?: boolean;
  sleepObservation?: string;
  isApetiteChk?: boolean;
  apetiteObservation?: string;
  isNauseaChk?: boolean;
  nauseaObservation?: string;
  isBowelBladderChk?: boolean;
  bowelBladderObservation?: string;
  isAcidityChk?: boolean;
  acidityObservation?: string;
  isThyroidVitLevelChk?: boolean;
  thyroidVitLevelObservation?: string;
  isHeadHeavinessChk?: boolean;
  headHeavinessObservation?: string;
  isEyeHeavinessChk?: boolean;
  eyeHeavinessObservation?: string;
  isTongueHeavinessChk?: boolean;
  tongueHeavinessObservation?: string;
  isSlurringOfSpeechChk?: boolean;
  slurringOfSpeechObservation?: string;
  isWeaknessChk?: boolean;
  weaknessObservation?: string;
  isFatigueChk?: boolean;
  fatigueObservation?: string;
  isHydrationChk?: boolean;
  hydrationObservation?: string;
  isStressChk?: boolean;
  stressObservation?: string;
  isFingureNoseChk?: boolean;
  fingureNoseObservation?: string;
  isDisdiakokinesisChk?: boolean;
  disdiakokinesisObservation?: string;
  isHeelShinChk?: boolean;
  heelShinObservation?: string;
  isTinnitusChk?: boolean;
  isTinnitusLeft?: boolean;
  isTinnitusRight?: boolean;
  tinnitusObservation?: string;
  isSNHLChk?: boolean;
  isSNHLLeft?: boolean;
  isSNHLRight?: boolean;
  sNHLObservation?: string;

  isOnPillowEyesOpenedChk?: boolean;
  isOnPillowEyesClosedChk?: boolean;

  onPillowEyesOpenObservation?: string;
  onPillowEyesClosedObservation?: string;

  isOnGroundEyesClosedChk?: boolean;
  isOnGroundEyesOpenedChk?: boolean;

  onGroundEyesOpenObservation?: string;
  onGroundEyesClosedObservation?: string;
  isPositionalTestACChk?: boolean;
  isPositionalTestACLeft?: boolean;
  isPositionalTestACRight?: boolean;
  positionalTestACObservation?: string;
  isPositionalTestPCChk?: boolean;
  isPositionalTestPCLeft?: boolean;
  isPositionalTestPCRight?: boolean;
  positionalTestPCObservation?: string;
  isPositionalTestHCChk?: boolean;
  isPositionalTestHCLeft?: boolean;
  isPositionalTestHCRight?: boolean;
  positionalTestHCObservation?: string;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toVertigo(json: string): Vertigo {
    return JSON.parse(json);
  }

  public static vertigoToJson(value: Vertigo): string {
    return JSON.stringify(value);
  }
}
