export const generateForm = (masterAssessmentId: string) => {
  const myAssessment = {
    id: '',
    masterAssessmentId: masterAssessmentId,
    jawJerk: 1,
    bicepsReflex: 1,
    tricepsReflex: 1,
    brachioradialisReflex: 1,
    fingerReflex: 1,
    abdominalReflex: 1,
    kneeReflex: 1,
    ankleReflex: 1,
    cornealReflex: 1,
    plantarReflex: 1,
    abdominalReflexSuperficial: 1,
    cremastericReflex: 1,
    analReflex: 1,
    bulbocavernosusReflex: 1,
  };
  return myAssessment;
};
