import { useFormContext, Controller } from 'react-hook-form';
import React from 'react';

// ----------------------------------------------------------------------

type TextAreaFieldProps = {
  placeholder?: string; // 'string' | React.ReactNode;
  type?: string; // 'text' | 'number' | 'password' | 'email' | 'search' | 'tel' | 'url' | 'date' | 'time' | 'datetime-local' | 'month' | 'week' | 'currency';
  rows?: number
};

type Props = TextAreaFieldProps & {
  name: string;
  label?: string;
  value?: string;
  required?: boolean;
  disabled?:boolean;
};

export default function TextAreaField({ name, label, placeholder, rows, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <>
      <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
        {label}
      </label>
      <div className="mt-1 w-full">
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <textarea
              {...field}
              {...other}
              // helperText={helperText}
              placeholder={placeholder}
              aria-invalid={error ? 'true' : 'false'}
              aria-describedby={`${name}-error`}
              value={other.value || ''}
              required={other.required}
              rows={rows}
              className="block w-full rounded-lg border-0 py-2 ring-1 placeholder:text-grey-400 sm:text-sm sm:leading-6"
            />
          )}
        />
      </div>
    </>
  );
}

// Example:
// <TextField
// name="email"
// type="email"
// autoComplete="email"
// required
// />

// <input
// id="email"
// name="email"
// type="email"
// autoComplete="email"
// required
// className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-grey-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
// />
