// To parse this data:
//
//   import { Convert, NerveTest } from "./file";
//
//   const nerveTest = Convert.toNerveTest(json);

export interface NerveTest {
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  nerveTestDetails: NerveTestDetail[];
}

export interface NerveTestDetail {
  name: string;
  observation: string;
}

// Converts JSON strings to/from your types
export class ConvertNerveTest {
  public static toNerveTest(json: string): NerveTest {
    return JSON.parse(json);
  }

  public static nerveTestToJson(value: NerveTest): string {
    return JSON.stringify(value);
  }
}
