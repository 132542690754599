// To parse this data:
//
//   import { Convert, Exercise } from "./file";
//
//   const exercise = Convert.toExercise(json);

export interface Exercise {
  id: string;
  entityId: string;
  title: string;
  difficulty: string;
  description: string;
  instructions: string;
  defaultExerciseConfig: DefaultExerciseConfig;
  tags: any[];
  media: string[];
  externalLinks: ExternalLink[];
  includedInPlatformLibrary: boolean;
  audit: Audit;
}

export interface Audit {
  createdBy: null;
  lastUpdatedBy: null;
  createdAt: Date;
  lastUpdatedAt: Date;
}

export interface DefaultExerciseConfig {
  sets: number;
  repetitions: number;
  hold: number;
  interval: number;
  side: string;
  weights: number;
  weightsUnit: string;
  intervalUnit: string;
  holdUnit: string;
  dailyFrequency: number;
}

export interface ExternalLink {
  externalLinkId: string;
  linkTitle: string;
  url: string;
  source: string;
  audit: Audit;
  isFree: boolean;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toExercise(json: string): Exercise {
    return JSON.parse(json);
  }

  public static exerciseToJson(value: Exercise): string {
    return JSON.stringify(value);
  }
}
