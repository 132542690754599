import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useContext } from "react";
import * as z from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";

import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

import Button from "@components/button/button";
import { ButtonVariant } from "@components/button/button";

import InputField from "@components/hook-form/input-field";

import { generateForm } from "./InitialTableData";
import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";
import { Convert, Reflex } from "./types/Reflex";
import { ValidationError } from "@components/hook-form";

export const ReflexSchema = z.object({
  id: z.string().optional(),
  masterAssessmentId: z.string().optional(),
  clientId: z.string().optional().default(""),
  entityId: z.string().optional().default(""),
  entityBranchId: z.string().optional().default(""),
  jawJerk: z.number().int().min(1).max(5),
  bicepsReflex: z.number().int().min(1).max(5),
  tricepsReflex: z.number().int().min(1).max(5),
  brachioradialisReflex: z.number().int().min(1).max(5),
  fingerReflex: z.number().int().min(1).max(5),
  abdominalReflex: z.number().int().min(1).max(5),
  kneeReflex: z.number().int().min(1).max(5),
  ankleReflex: z.number().int().min(1).max(5),
  cornealReflex: z.number().int().min(1).max(5),
  plantarReflex: z.number().int().min(1).max(5),
  abdominalReflexSuperficial: z.number().int().min(1).max(5),
  cremastericReflex: z.number().int().min(1).max(5),
  analReflex: z.number().int().min(1).max(5),
  bulbocavernosusReflex: z.number().int().min(1).max(5),
});
export type ReflexSchemaType = z.infer<typeof ReflexSchema>;

type ReflexAssessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function ReflexAssessmentComponent(
  props: ReflexAssessmentProps
) {
  const [romAssessment, setReflexAssessment] = React.useState<Reflex | null>(
    null
  );
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [error, setError] = React.useState("");

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);
  const userData = getUserPersistedOnLocalStorage();

  const initialize = () => {
    // set default values for the form
    return generateForm(props.masterAssessmentId);
  };

  // below is the api call to get the list of patients in a react hook
  React.useEffect(() => {
    const initializeReflexAssessment = async () => {
      if (props.assessmentId !== "000000000000000000000000") {
        try {
          const paramsMap = new Map<string, string>([
            ["mid", props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getReflexAssessment,
            paramsMap
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data["data"];
          const convertedReflexAssessment = Convert.toReflex(
            JSON.stringify(data)
          );
          setReflexAssessment(convertedReflexAssessment);

          methods.reset(convertedReflexAssessment);
          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          console.error("Error fetching Reflex assessment:", error);
          setError(error.message);
          setLoading(false);
        }
      }
    };
    initializeReflexAssessment();
  }, []);

  const methods = useForm<ReflexSchemaType>({
    resolver: zodResolver(ReflexSchema),
    defaultValues: ReflexSchema.parse(initialize()),
  });

  const onSubmit: SubmitHandler<ReflexSchemaType> = async (data) => {
    methods.trigger();
    if (props.assessmentId !== "000000000000000000000000") {
      try {
        ReflexSchema.parse(data);
        data.id = props.assessmentId;
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? "";
        data.entityBranchId = userData?.branch_id ?? "";
        const paramsMap = new Map<string, string>([
          ["id", props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateReflexAssessment,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Reflex Assessment updated successfully"
              : response.data.message
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
        setError(error.response.data["data"].toString());
      }
    } else {
      try {
        ReflexSchema.parse(data);
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? "";
        data.entityBranchId = userData?.branch_id ?? "";
        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createReflexAssessment,
          paramsMap
        );
        const response = await axios.post(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Reflex Assessment created successfully"
              : response.data.message
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
        setError(error.response.data["data"].toString());
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Reflex Assessment
        </h3>
      </div>

      <hr className="w-full" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col items-stretch">
            <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
              Deep Tendon Reflexes
            </div>
            <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    className="border px-2 py-1 border-slate-300 "
                    style={{ width: "75%" }}
                  >
                    Name
                  </th>
                  <th
                    className="border px-2 py-1 border-slate-300"
                    style={{ width: "25%" }}
                  >
                    Grading
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Jaw Jerk
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <InputField
                      type="number"
                      key={`jawJerk`}
                      name={`jawJerk`}
                      disabled={isDisabled}
                    />
                    {methods.formState.errors.jawJerk && (
                      <ValidationError
                        message={
                          methods.formState.errors.jawJerk?.message?.toString() ??
                          ""
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Biceps Reflex
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <InputField
                      type="number"
                      key={`bicepsReflex`}
                      name={`bicepsReflex`}
                      disabled={isDisabled}
                    />
                    {methods.formState.errors.bicepsReflex && (
                      <ValidationError
                        message={
                          methods.formState.errors.bicepsReflex?.message?.toString() ??
                          ""
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Triceps Reflex
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <InputField
                      type="number"
                      key={`tricepsReflex`}
                      name={`tricepsReflex`}
                      disabled={isDisabled}
                    />
                    {methods.formState.errors.tricepsReflex && (
                      <ValidationError
                        message={
                          methods.formState.errors.tricepsReflex?.message?.toString() ??
                          ""
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Brachioradialis Reflex
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <InputField
                      type="number"
                      key={`brachioradialisReflex`}
                      name={`brachioradialisReflex`}
                      disabled={isDisabled}
                    />
                    {methods.formState.errors.brachioradialisReflex && (
                      <ValidationError
                        message={
                          methods.formState.errors.brachioradialisReflex?.message?.toString() ??
                          ""
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Finger Reflex
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <InputField
                      type="number"
                      key={`fingerReflex`}
                      name={`fingerReflex`}
                      disabled={isDisabled}
                    />
                    {methods.formState.errors.fingerReflex && (
                      <ValidationError
                        message={
                          methods.formState.errors.fingerReflex?.message?.toString() ??
                          ""
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Abdominal Reflex
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <InputField
                      type="number"
                      key={`abdominalReflex`}
                      name={`abdominalReflex`}
                      disabled={isDisabled}
                    />
                    {methods.formState.errors.abdominalReflex && (
                      <ValidationError
                        message={
                          methods.formState.errors.abdominalReflex?.message?.toString() ??
                          ""
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Knee Reflex
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <InputField
                      type="number"
                      key={`kneeReflex`}
                      name={`kneeReflex`}
                      disabled={isDisabled}
                    />
                    {methods.formState.errors.kneeReflex && (
                      <ValidationError
                        message={
                          methods.formState.errors.kneeReflex?.message?.toString() ??
                          ""
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Ankle Reflex
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <InputField
                      type="number"
                      key={`ankleReflex`}
                      name={`ankleReflex`}
                      disabled={isDisabled}
                    />

                    {methods.formState.errors.ankleReflex && (
                      <ValidationError
                        message={
                          methods.formState.errors.ankleReflex?.message?.toString() ??
                          ""
                        }
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="flex flex-col items-stretch">
            <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
              Superficial Reflexes
            </div>
            <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    className="border px-2 py-1 border-slate-300 "
                    style={{ width: "75%" }}
                  >
                    Name
                  </th>
                  <th
                    className="border px-2 py-1 border-slate-300"
                    style={{ width: "25%" }}
                  >
                    Grading
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Corneal Reflex
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <InputField
                      type="number"
                      key={`cornealReflex`}
                      name={`cornealReflex`}
                      disabled={isDisabled}
                    />
                    {methods.formState.errors.cornealReflex && (
                      <ValidationError
                        message={
                          methods.formState.errors.cornealReflex?.message?.toString() ??
                          ""
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Plantar Reflex
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <InputField
                      type="number"
                      key={`plantarReflex`}
                      name={`plantarReflex`}
                      disabled={isDisabled}
                    />
                    {methods.formState.errors.plantarReflex && (
                      <ValidationError
                        message={
                          methods.formState.errors.plantarReflex?.message?.toString() ??
                          ""
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Abdominal Reflex
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <InputField
                      type="number"
                      key={`abdominalReflexSuperficial`}
                      name={`abdominalReflexSuperficial`}
                      disabled={isDisabled}
                    />
                    {methods.formState.errors.abdominalReflexSuperficial && (
                      <ValidationError
                        message={
                          methods.formState.errors.abdominalReflexSuperficial?.message?.toString() ??
                          ""
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Cremasteric Reflex
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <InputField
                      type="number"
                      key={`cremastericReflex`}
                      name={`cremastericReflex`}
                      disabled={isDisabled}
                    />
                    {methods.formState.errors.cremastericReflex && (
                      <ValidationError
                        message={
                          methods.formState.errors.cremastericReflex?.message?.toString() ??
                          ""
                        }
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex flex-col items-stretch">
            <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
              Visceral Reflexes
            </div>
            <table className="table-fixed  text-left text-xs border-collapse border border-slate-400 w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    className="border px-2 py-1 border-slate-300 "
                    style={{ width: "75%" }}
                  >
                    Name
                  </th>
                  <th
                    className="border px-2 py-1 border-slate-300"
                    style={{ width: "25%" }}
                  >
                    Grading
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Anal Reflex
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <InputField
                      type="number"
                      key={`analReflex`}
                      name={`analReflex`}
                      disabled={isDisabled}
                    />
                    {methods.formState.errors.analReflex && (
                      <ValidationError
                        message={
                          methods.formState.errors.analReflex?.message?.toString() ??
                          ""
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Bulbocavernous Reflex
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <InputField
                      type="number"
                      key={`bulbocavernosusReflex`}
                      name={`bulbocavernosusReflex`}
                      disabled={isDisabled}
                    />
                    {methods.formState.errors.bulbocavernosusReflex && (
                      <ValidationError
                        message={
                          methods.formState.errors.bulbocavernosusReflex?.message?.toString() ??
                          ""
                        }
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {methods.formState.errors && <p className="text-red-500"> {}</p>}

          <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
            <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
              <OutlinedButton
                type="button"
                onClick={() => {
                  props.postSubmitCallback();
                }}
                className="mr-2"
                variant={ButtonVariant.PRIMARY}
              >
                Close
              </OutlinedButton>
              {isDisabled ? (
                <Button
                  type="button"
                  key="EditButton"
                  onClick={() => {
                    setIsDisabled(false);
                  }}
                  variant={ButtonVariant.PRIMARY}
                >
                  Edit
                </Button>
              ) : props.assessmentId !== "000000000000000000000000" ? (
                <Button
                  type="submit"
                  key="UpdateButton"
                  onClick={() => {}}
                  variant={ButtonVariant.PRIMARY}
                >
                  Update
                </Button>
              ) : (
                <Button
                  type="submit"
                  key="CreateButton"
                  onClick={() => {}}
                  variant={ButtonVariant.PRIMARY}
                >
                  Create
                </Button>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
