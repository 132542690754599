import Button, { ButtonVariant } from '@components/button/button';
import { Dialog, Transition } from '@headlessui/react';
import { endpoints, replaceParams } from '@utils/axios';

import React, { Fragment, useEffect } from 'react';

import { Appointment } from '../types/Appointment';

import moment from 'moment';

import { RescheduleAppointmentDto } from '../types/RescheduleAppointmentDto';
import { toast } from 'react-toastify';
import useAxios from '@routes/hooks/use-axios';
import {
  AvailableTimeSlotConvert,
  AvailableTimeSlots,
  Freeslot,
} from '../types/AvailableTimeSlots';
import { ConvertFreeSlot, FreeSlot } from '../types/FreeSlot';
import DateSelector from './DatePicker';
import { getUserPersistedOnLocalStorage } from '@authentication/context/jwt/utils';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ValidationError } from '@components/hook-form';
import { OutlinedButton } from '@components/button';
import { CancelTokenSource } from 'axios';
import Applabel from '@components/hook-form/applabel';

interface AppointmentData {
  AppointmentDuration: number[];
  AppointmentType: string[];
  appointmentString: string[];
}

export const AppointmentSchema = z.object({
  appointmentType: z
    .string()
    .min(1, { message: 'Please select appointment type' })
    .default(''),
  physioId: z
    .string()
    .min(1, { message: 'Please select Physiotherapist' })
    .default(''),
  startTime: z.string().min(1, { message: 'Please select slot' }).default(''),
});

export type AppointmentType = z.infer<typeof AppointmentSchema>;

function RescheduleAppointmentPage(props: {
  onClose: () => void;
  appointmentDetails: Appointment;
  getAppointments: () => Promise<void>;
}) {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const [postInProgress, setPostInProgress] = React.useState<boolean>(false);
  const cancelToken = React.useRef<CancelTokenSource | null>(null);
  const [selectedEntityUsername, setSelectedEntityUsername] = React.useState<
    string | null
  >(null);
  const [selectedEntityUserId, setSelectedEntityUserId] = React.useState<
    string | null
  >(null);
  const [selectedEntityUserPhone, setSelectedEntityUserPhone] = React.useState<
    number | null
  >(null);
  const [appointmentDuration, setAppointmentDuration] = React.useState(0);
  const [selectAppointmentType, setSelectApppointmentType] = React.useState('');
  const [appointmentTypeData, setAppointmentTypeData] =
    React.useState<AppointmentData | null>(null);

  const axios = useAxios();
  const [availablePhysiotherapists, setAvailablePhysiotherapists] =
    React.useState<AvailableTimeSlots[] | null>(null);
  const [timeSlots, setTimeSlots] = React.useState<FreeSlot[] | null>(null);
  const [selectedTimeSlot, setSelectedTimeSlot] =
    React.useState<FreeSlot | null>(null);
  const [selectedTime, setSelectedTime] = React.useState<string | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<string>(
    new Date().toISOString().split('T')[0],
  );
  const [availableTimeSlots, setAvailableTimeSlots] = React.useState<
    AvailableTimeSlots[] | null
  >(null);
  const [notes, setNotes] = React.useState<string>('');
  const [multipleBookingWarning, setMultipleBookingWarning] =
    React.useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<AppointmentType>({ resolver: zodResolver(AppointmentSchema) });

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value != '') {
      setSelectedEntityUserId('');
      setSelectedTime('');
      setValue('physioId', '');
      setValue('startTime', '');
      setSelectedDate(e.target.value);
    }
  };

  const handleScrollableDateChange = (date: string) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    const getAppointmentType = async () => {
      try {
        const userData = getUserPersistedOnLocalStorage();
        const entityIdset = userData?.entity_id;

        // Ensure entityIdset is defined before using it
        if (entityIdset !== undefined) {
          // Create a Map with the entityIdset as the value
          const paramsMap = new Map<string, string>([['id', entityIdset]]);

          // Continue with your logic using paramsMap
          const filteredEndpoint = replaceParams(
            endpoints.appointment.fetchAppointmentDuration,
            paramsMap,
          );
          const response = await axios.get(filteredEndpoint);
          const data: AppointmentData = response.data['data'];
          setAppointmentTypeData(data);
        }
      } catch (error) {
        console.error('Error fetching appointment type:', error);
      }
    };
    getAppointmentType();
  }, []);

  useEffect(() => {
    setTimeSlots([]);
    setAvailableTimeSlots([]);
    setAvailablePhysiotherapists([]);
    setSelectedTime('');
    setSelectedEntityUserId('');
    setMultipleBookingWarning(false);
    const getAvailableTimeSlots = async () => {
      try {
        const response = await axios.get(
          endpoints.appointment.availableTimeSlots,
          {
            params: {
              requestedDate: moment(new Date(selectedDate)).format(
                'DD-MM-YYYY',
              ),
              requestedAppointmentDuration: appointmentDuration,
              requestedSlotInterval: 15,
            },
          },
        );
        const data = response.data['data']['availableEntityUsers'];
        if (data != null) {
          const convertedAvailableTimeSlots = data.map((slot: any) =>
            AvailableTimeSlotConvert.toAvailableTimeSlots(JSON.stringify(slot)),
          );
          const timeZone = moment.tz.guess();
          const convertedAvailableTimeSlotsLocalTime =
            convertedAvailableTimeSlots.map((available: AvailableTimeSlots) => {
              return available.freeslots.map((freeslot: Freeslot) => {
                const startMoment = moment.utc(freeslot.startTime, 'HH:mm A');
                freeslot.startTime = startMoment.tz(timeZone).format('hh:mm A');
                const endMoment = moment.utc(freeslot.endTime, 'HH:mm A');
                freeslot.endTime = endMoment.tz(timeZone).format('hh:mm A');

                return freeslot;
              });
            });
          setAvailableTimeSlots(convertedAvailableTimeSlots);
          setLoading(false);
        } else {
          setLoading(false);
          setAvailableTimeSlots(null);
        }
      } catch (error) {
        console.error('Error fetching available time slots...', error);
        setError(error.message);
        setAvailableTimeSlots(null);
        setLoading(false);
      }
    };

    if (
      appointmentDuration != null &&
      selectedDate != null &&
      appointmentDuration != 0
    ) {
      getAvailableTimeSlots();
    }
  }, [selectedDate, appointmentDuration]);

  const addTimeToTimeZoneAndConvertToUTC = (
    date: Date,
    timeString: string,
  ): Date => {
    //   Parse input date and time using Moment.js

    const inputDate = moment(date);
    const inputTime = moment(timeString, 'hh:mm A');

    // Combine date and time
    let resultDate = inputDate.set({
      hour: inputTime.hours(),
      minute: inputTime.minutes(),
    });
    // Convert to ISO string and return
    return new Date(moment(resultDate).toISOString());
  };

  const onSubmit: SubmitHandler<AppointmentType> = async parseData => {
    if (postInProgress) return;
    const userData = getUserPersistedOnLocalStorage();
    let appoinmentTypeValue: string = selectAppointmentType;
    // if (appointmentType === 30) {
    //   appoinmentTypeValue = 'Consultation';
    // } else if (appointmentType === 45) {
    //   appoinmentTypeValue = 'Therapy';
    // } else if (appointmentType === 15) {
    //   appoinmentTypeValue = 'Follow-up';
    // }

    if (cancelToken.current) {
      cancelToken.current.cancel('Request already in progress!');
    }

    cancelToken.current = axios.CancelToken.source();

    try {
      setPostInProgress(true);
      let newSelectedDate: Date = new Date();
      AppointmentSchema.parse(parseData);
      if (selectedTimeSlot) {
        let duration =
          selectedTimeSlot.endTimeMinutes - selectedTimeSlot.startTimeMinutes;
        // Adding time to the date Selected and converting it to UTC in 2023-12-20T00:00:00Z format
        newSelectedDate = addTimeToTimeZoneAndConvertToUTC(
          new Date(selectedDate),
          selectedTimeSlot.startTime,
        );
        // Converting String eg "10:00 AM" to Equivalent UTC String
        selectedTimeSlot.startTime = moment
          .utc(newSelectedDate)
          .format('hh:mm A');
        selectedTimeSlot.endTime = moment(selectedTimeSlot.startTime, 'hh:mm A')
          .add(duration, 'minutes')
          .format('hh:mm A');

        // Converting startTime Minutes to Equivalent UTC minutes
        selectedTimeSlot.startTimeMinutes =
          newSelectedDate.getUTCHours() * 60 + newSelectedDate.getUTCMinutes();
        selectedTimeSlot.endTimeMinutes =
          newSelectedDate.getUTCHours() * 60 +
          newSelectedDate.getUTCMinutes() +
          duration;
      }

      let data: RescheduleAppointmentDto = {
        id: props.appointmentDetails.id,
        clientId: props.appointmentDetails.clientId,
        clientName: props.appointmentDetails.clientName,
        clientPhone: props.appointmentDetails.clientPhone,
        date: moment(new Date(selectedDate)).format('DD-MM-YYYY'),
        appointmentType: appoinmentTypeValue,
        email: props.appointmentDetails.email,
        entityBranchId: userData?.branch_id ?? '',
        entityId: userData?.entity_id ?? '',
        notes: notes,
        endTime: selectedTimeSlot?.endTime ?? '',
        endTimeMinutes: selectedTimeSlot?.endTimeMinutes ?? 0,
        startTime: selectedTimeSlot?.startTime ?? '',
        startTimeMinutes: selectedTimeSlot?.startTimeMinutes ?? 0,
        entityUserId: selectedEntityUserId ?? '',
        entityUserName: selectedEntityUsername ?? '',
        appointmentStatus: 'Scheduled',
        appointmentSlot: 0,
        duration: appointmentDuration ?? 30,
        entityUserPhone: selectedEntityUserPhone ?? 0,
        statusChangeComment: '',
        apptdate: newSelectedDate,
      };
      const paramsMap = new Map<string, string>([
        ['appointmentId', props.appointmentDetails.id],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.appointment.rescheduleAppointment,
        paramsMap,
      );
      await axios.put(filteredEndpoint, data, {
        cancelToken: cancelToken.current.token,
      });
      setPostInProgress(false);
      closeModal();

      props.getAppointments();
      toast.success('Appointment Rescheduled Successfully');
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error['response']['data']['data']);
      setPostInProgress(false);
    }
  };
  let [isOpen, setIsOpen] = React.useState(true);

  function closeModal() {
    setIsOpen(false);
    props.onClose();
  }

  // const handleAppointmentTypeChange = (
  //   e: React.ChangeEvent<HTMLSelectElement>,
  // ) => {
  //   setSelectedEntityUserId('');
  //   setSelectedTime('');
  //   setValue('physioId', '');
  //   setValue('startTime', '');
  //   setMultipleBookingWarning(false);
  //   setAppointmentType(Number(e.target.value));
  //   clearErrors('appointmentType');
  // };
  const handleAppointmentTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    keys: string[],
    values: number[],
  ) => {
    const selectedValue = Number(e.target.value);
    const selectedIndex = values.indexOf(selectedValue);

    if (selectedIndex !== -1) {
      const selectedKey = keys[selectedIndex];
      setSelectApppointmentType(selectedKey.toString());
      setAppointmentDuration(selectedValue);
      clearErrors('appointmentType');
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold leading-6 px-6 pt-6 text-gray-900"
                  >
                    Reschedule Appointment
                  </Dialog.Title>
                  <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className="max-h-[75vh] overflow-auto">
                      <div className="flex flex-col mt-5">
                        <div className="flex flex-col space-y-2 lg:space-x-2 w-full lg:flex-row lg:justify-between items-center px-6 ">
                          <div className="flex flex-col w-full">
                            <div className="flex-shrink">
                              Patient Name -
                              <span className="font-bold">
                                {props.appointmentDetails.clientName}
                              </span>
                            </div>
                          </div>

                          <div className="flex flex-col w-full">
                            <Applabel label="Select Appointment Type" />
                            <select
                              {...register('appointmentType')}
                              name="appointmentType"
                              className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                              required
                              onChange={e => {
                                handleAppointmentTypeChange(
                                  e,
                                  appointmentTypeData?.AppointmentType ?? [],
                                  appointmentTypeData?.AppointmentDuration ??
                                  [],
                                );
                                setValue('appointmentType', e.target.value);
                                if (e.target.value !== '') {
                                  clearErrors('appointmentType');
                                }
                              }}
                            >
                              <option key="" value="">
                                Select Apppointment
                              </option>

                              {appointmentTypeData?.appointmentString.map(
                                (duration, index) => {
                                  return (
                                    <option
                                      key={
                                        appointmentTypeData.AppointmentType[
                                        index
                                        ]
                                      }
                                      value={
                                        appointmentTypeData.AppointmentDuration[
                                        index
                                        ]
                                      }
                                    >
                                      {duration}
                                    </option>
                                  );
                                },
                              )}
                            </select>
                            {errors.appointmentType && (
                              <ValidationError
                                message={
                                  errors.appointmentType?.message?.toString() ??
                                  ''
                                }
                              />
                            )}
                          </div>
                          <div className="flex flex-col w-full">
                            <Applabel label="Select Date" />
                            <input
                              name="appointmentDate"
                              className="flex lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                              type="date"
                              value={selectedDate}
                              onChange={handleDateChange}
                              required
                            ></input>
                          </div>
                        </div>

                        <div className="mt-4 w-full px-6 hidden md:block">
                          <DateSelector
                            handleScrollableDateChange={
                              handleScrollableDateChange
                            }
                          />
                        </div>
                        <div className="flex flex-col w-full mt-6">
                          <div className="flex flex-col md:flex-row lg:flex-row lg:justify-between lg:w-full px-6">
                            <div className="flex-1">
                              <label className="block text-xs font-medium leading-6 text-grey-900">
                                Select Physiotherapist
                              </label>
                              {!loading && !error && availableTimeSlots && (
                                <div className="bg-gray-50 max-h-56 overflow-y-auto">
                                  <ul className="divide-y divide-gray-100">
                                    {availableTimeSlots.map(
                                      (slot: AvailableTimeSlots) => (
                                        <li
                                          key={slot.entityUserId}
                                          className={`rounded p-3 hover:bg-gray-200 hover:text-black ${selectedEntityUserId ===
                                            slot.entityUserId
                                            ? 'bg-tertiary-400 text-white'
                                            : ''
                                            }`}
                                          onClick={() => {
                                            const convertedTimeSlot =
                                              slot.freeslots.map((slot: any) =>
                                                ConvertFreeSlot.toFreeSlot(
                                                  JSON.stringify(slot),
                                                ),
                                              );
                                            setSelectedTime('');
                                            setMultipleBookingWarning(false);
                                            setValue(
                                              'physioId',
                                              slot.entityUserName.toString(),
                                            );
                                            clearErrors('physioId');
                                            setValue('startTime', '');
                                            setTimeSlots(convertedTimeSlot);
                                            setSelectedEntityUserId(
                                              slot.entityUserId,
                                            );
                                            setSelectedEntityUsername(
                                              slot.entityUserName,
                                            );
                                            setSelectedEntityUserPhone(
                                              slot.entityUserPhone,
                                            );
                                          }}
                                        >
                                          {slot.entityUserName} (
                                          {slot.totalFreeSlots} free slots)
                                        </li>
                                      ),
                                    )}
                                  </ul>
                                </div>
                              )}
                              {errors.physioId && (
                                <ValidationError
                                  message={
                                    errors.physioId?.message?.toString() ?? ''
                                  }
                                />
                              )}
                            </div>
                            <div className="flex-1 md:ml-1 lg:ml-2">
                              <label className="block text-xs font-medium leading-6 text-grey-900">
                                Select Time-slot
                              </label>
                              <div className="grid grid-cols-4 gap-2 lg:grid-cols-4 max-h-56 overflow-y-auto">
                                {timeSlots &&
                                  timeSlots.map((slot: FreeSlot) => (
                                    <div
                                      key={slot.startTime}
                                      className={`items-center justify-between rounded text-center p-2 ${slot.appointmentsCount > 0 &&
                                        selectedTime !== slot.startTime
                                        ? 'bg-[#FFFACD]'
                                        : 'bg-gray-50 text-black'
                                        } border border-gray-300 ${selectedTime === slot.startTime
                                          ? 'bg-tertiary-500 text-white'
                                          : ''
                                        }`}
                                      onClick={() => {
                                        if (slot.appointmentsCount > 0) {
                                          setMultipleBookingWarning(true);
                                        } else {
                                          setMultipleBookingWarning(false);
                                        }
                                        setSelectedTime(slot.startTime);
                                        setValue('startTime', slot.startTime);
                                        clearErrors('startTime');
                                        setSelectedTimeSlot({
                                          appointmentsCount:
                                            slot.appointmentsCount,
                                          endTime: slot.endTime,
                                          startTime: slot.startTime,
                                          endTimeMinutes: slot.endTimeMinutes,
                                          startTimeMinutes:
                                            slot.startTimeMinutes,
                                        });
                                      }}
                                    >
                                      <div className="text-xs font-normal leading-none max-w-full flex-initial">
                                        {slot.startTime}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              {multipleBookingWarning && (
                                <div className="my-2 px-2 py-1 bg-[#FFCE42] rounded outline outline-1 outline-[#FFCE42]">
                                  <span>
                                    Warning! Selected slot is already booked for
                                    another appointment but you can still go
                                    ahead and book another appointment on the
                                    same slot.
                                  </span>
                                </div>
                              )}
                              {errors.startTime && (
                                <ValidationError
                                  message={
                                    errors.startTime?.message?.toString() ?? ''
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="mt-10 px-6">
                          <div className="flex flex-col">
                            <span className="text-lg font-bold">
                              Additional Notes
                            </span>
                            <span className="text-xs">
                              Private note specific to the appointment for
                              Physiotherapist's reference
                            </span>
                            <textarea
                              id="message"
                              onChange={e => setNotes(e.target.value)}
                              className="mt-2 block p-2.5 lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4 mt-2">
                      <div className="flex flex-row w-full justify-end">
                        <OutlinedButton
                          variant={ButtonVariant.PRIMARY}
                          type="button"
                          onClick={closeModal}
                          children="Close"
                          className="mr-2"
                        />
                        <Button
                          variant={ButtonVariant.SECONDARY}
                          type="submit"
                          children="Reschedule Appointment"
                        />
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default RescheduleAppointmentPage;
