// To parse this data:
//
//   import { Convert, Journal } from "./file";
//
//   const journal = Convert.toJournal(json);

export type Journal = {
  id?: string;
  clientID?: string;
  journalNotes?: JournalNote[];
};

export type JournalNote = {
  noteID?: string;
  date?: string;
  note?: string;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toJournal(json: string): Journal {
    return JSON.parse(json);
  }

  public static journalToJson(value: Journal): string {
    return JSON.stringify(value);
  }
}
