import { Button } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import useAxios from '@routes/hooks/use-axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ManagePatientContext } from './ManagePatient';
import { endpoints, replaceParams } from '@utils/axios';
import { Convert, TreatmentPlanHistory } from '../types/TreatmentPlanHistory';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import moment from 'moment';
import { QuickPatientAddDialog } from '@pages/appointments/book-appointment/QuickPatientAddDialog';
import { AddTreatmentPlan } from './AddTreatmentPlan';
import { ViewTreatmentPlan } from './ViewTreatmentPlan';
import Applabel from '@components/hook-form/applabel';

export const TreatmentPlanHistorySchema = z.object({
  id: z.string(),
  clientId: z.string(),
  entityId: z.string(),
  entityBranchId: z.string(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  clientAdvice: z.string(),
  precautions: z.string(),
  sessionCount: z.number(),
  sessionFrequency: z.number(),
  sessionFrequencyUnit: z.string(),
  type: z.string(),
  prescribedTherapies: z.array(z.string()),
});
export type TreatmentPlanHistorySchemaType = z.infer<
  typeof TreatmentPlanHistorySchema
>;

export default function TreatmentPlan() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<TreatmentPlanHistorySchemaType>({
    resolver: zodResolver(TreatmentPlanHistorySchema),
  });

  const [patientTreatmentPlan, setPatientTreatmentPlan] = React.useState<
    TreatmentPlanHistory[] | null
  >(null);

  const [loading, setLoading] = React.useState(true);
  const [isEditable, setIsEditable] = React.useState(false);
  const [quickPatientAddTreatmentPlan, setQuickPatientAddTreatmentPlan] =
    React.useState(false);
  const [showtreatmentPlan, setShowtreatmentPlan] = React.useState('');
  const [selected, setSelected] = React.useState<TreatmentPlanHistory | null>(
    null,
  );

  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);

  // const initialize = () => {
  //   // set default values for the form
  //   return {
  //     id: patientTreatmentPlan?.id ?? '',
  //     clientId: patientTreatmentPlan?.clientId,
  //     entityId: patientTreatmentPlan?.entityId,
  //     entityBranchId: patientTreatmentPlan?.entityBranchId,
  //     startDate: patientTreatmentPlan?.startDate ?? moment().toDate(),
  //     endDate: patientTreatmentPlan?.endDate ?? moment().toDate(),
  //     clientAdvice: patientTreatmentPlan?.clientAdvice ?? '',
  //     precautions: patientTreatmentPlan?.precautions,
  //     sessionCount: patientTreatmentPlan?.sessionCount,
  //     sessionFrequency: patientTreatmentPlan?.sessionFrequency,
  //     sessionFrequencyUnit: patientTreatmentPlan?.sessionFrequencyUnit,
  //     type: patientTreatmentPlan?.type,
  //     prescribedTherapies: patientTreatmentPlan?.prescribedTherapies,
  //   };
  // };

  React.useEffect(() => {
    setIsEditable(false);
    const getTreatmentPlans = async () => {
      try {
        const paramsMap = new Map<string, string>([
          ['patientId', patientId.patientId],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.treatmentPlan.view,
          paramsMap,
        );
        const response = await axios.get(filteredEndpoint);
        if (response.status === 200) {
          if (response.data && response.data['data']) {
            const data = response.data['data'];
            const convertedtreatmentPlanList = data.map(
              (treatment: TreatmentPlanHistory) =>
                Convert.toTreatmentPlanHistory(JSON.stringify(treatment)),
            );
            setPatientTreatmentPlan(convertedtreatmentPlanList);
            if (convertedtreatmentPlanList.length > 0) {
              setSelected(convertedtreatmentPlanList[0]);
            }
            //console the treatmentplan list
          }
        }
        setIsEditable(false);
        setLoading(false);
      } catch (error) {
        console.error('Error getting treatment plans', error);
        setLoading(false);
      }
    };
    getTreatmentPlans();
  }, []);

  const methods = useForm<TreatmentPlanHistory>({
    resolver: zodResolver(TreatmentPlanHistorySchema),
    // defaultValues: TreatmentPlanHistorySchema.parse(initialize()),
  });

  return (
    <div className="grid-cols-1 gap-x-8 gap-y-8 pt-2 md:grid-cols-3 bg-grey-200 ">
      <FormProvider {...methods}>
        <form>
          <div className="sticky top-0 right-0 z-1 md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="text-xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
                Treatment Plan
              </h2>
            </div>
            <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
              <Button
                variant={ButtonVariant.PRIMARY}
                type="button"
                className="ml-3"
                onClick={() => {
                  setQuickPatientAddTreatmentPlan(true);
                }}
                children="Add"
              />
            </div>
          </div>
          {quickPatientAddTreatmentPlan && (
            <AddTreatmentPlan
              onClose={() => setQuickPatientAddTreatmentPlan(false)}
            />
          )}
          <hr className="my-2" />
          {!loading &&
          patientTreatmentPlan &&
          patientTreatmentPlan != undefined &&
          patientTreatmentPlan.length > 0 ? (
            <>
              <div className="flex flex-col w-full space-y-4">
                <div className="flex flex-col ">
                  <Applabel label=" Prescriptions"></Applabel>
                  <select
                    onChange={e => {
                      const d = patientTreatmentPlan.map(single => {
                        if (single.id === e.target.value) setSelected(single);
                      });
                    }}
                    className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                  >
                    {patientTreatmentPlan.map(plan => (
                      <option key={plan.id} value={plan.id}>
                        {moment(plan.endDate).isAfter(new Date()) ? (
                          <span className="flex justify-center">
                            Active till{' '}
                            {moment(plan.endDate).format('MMMM Do YYYY')}
                          </span>
                        ) : (
                          <span className="flex justify-center">
                            Ended {moment(plan.endDate).format('MMMM Do YYYY')}
                          </span>
                        )}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {selected && (
                <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                  <div className="flex flex-col grow border-2 rounded-lg h-full mt-4 p-4">
                    <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                      <div className="flex flex-col  w-full md:w-1/3">
                        <span className="block text-sm font-medium leading-6 mt-2.5">
                          Prescription
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
                      <div className="flex flex-col  w-full md:w-1/3">
                        <span className="block text-sm font-medium leading-6 mt-2.5">
                          Start Date
                        </span>
                        <span className="block text-sm font-medium leading-6 text-gray-500 mt-2.5">
                          {moment(new Date(selected.startDate)).format(
                            'DD-MM-YYYY',
                          )}
                        </span>
                      </div>
                      <div className="flex flex-col  w-full md:w-1/3">
                        <span className="block text-sm font-medium leading-6 mt-2.5">
                          End Date
                        </span>
                        <span className="block text-sm font-medium leading-6 text-gray-500 mt-2.5">
                          {moment(new Date(selected.endDate)).format(
                            'DD-MM-YYYY',
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
                      <div className="flex flex-col  w-full">
                        <span className="block text-sm font-medium leading-6 mt-2.5">
                          Prescribed Therapies
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col  w-full mt-1">
                      <div className="flex text-sm font-medium leading-6 text-gray-500 mt-2.5">
                        {selected.prescribedTherapies
                          ? selected.prescribedTherapies.map(
                              (categoryOfTherapy, index) => {
                                return (
                                  <>
                                    {(index ? ' , ' : '') + categoryOfTherapy}
                                  </>
                                );
                              },
                            )
                          : 'Not Applicable'}
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
                      <div className="flex items-center py-2 mt-4">
                        <p className="block text-sm font-medium leading-6 mr-2">
                          Recommended
                        </p>
                        <span className="text-gray-500 text-sm font-medium">
                          {selected.sessionCount}
                        </span>
                        <p className="block text-sm font-medium leading-6 mr-2 ml-2">
                          sessions,
                        </p>
                        <span className="text-gray-500 text-sm font-medium">
                          {selected.sessionFrequency}
                        </span>
                        <p className="block text-sm font-medium leading-6 mr-2 ml-2">
                          per
                        </p>
                        <span className="text-gray-500 text-sm font-medium">
                          {selected.sessionFrequencyUnit}
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
                      <div className="flex flex-col  w-full">
                        <span className="block text-sm font-medium leading-6 mt-2.5">
                          Session Type
                        </span>
                        <span className="block text-sm font-medium leading-6 text-gray-500 mt-2.5">
                          {selected.type ? selected.type : 'Not Applicable'}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
                      <div className="flex flex-col  w-full">
                        <span className="block text-sm font-medium leading-6 mt-2.5">
                          Advice
                        </span>
                        <span className="block text-sm font-medium leading-6 text-gray-500 mt-2.5">
                          {selected.clientAdvice
                            ? selected.clientAdvice
                            : 'Not Applicable'}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
                      <div className="flex flex-col  w-full">
                        <span className="block text-sm font-medium leading-6 mt-2.5">
                          Precaution
                        </span>
                        <span className="block text-sm font-medium leading-6 text-gray-500 mt-2.5">
                          {selected.precautions
                            ? selected.precautions
                            : 'Not Applicable'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div>
              <p>No prescription added yet. Add a prescription to view</p>
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
}
