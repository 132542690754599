import { GuestGuard } from '@authentication/guard';
import LoadingScreen from '@components/loading/loading';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

export default function GeneralLayout() {
  return (
    <>
      <GuestGuard>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    </>
  );
}
