// To parse this data:
//
//   import { Convert, SessionCredits } from "./file";
//
//   const sessionCredits = Convert.toSessionCredits(json);

export interface SessionCredits {
  SessionCredits: number;
  OutstandingAmount: number;
}

// Converts JSON strings to/from your types
export class ConvertSessionCredit {
  public static toSessionCredits(json: string): SessionCredits {
    return JSON.parse(json);
  }

  public static sessionCreditsToJson(value: SessionCredits): string {
    return JSON.stringify(value);
  }
}
