// Divider component
import React from 'react';

export default function Divider() {
  return <div className="border-t-2 border-grey-200 m-2" />;
}

export function VerticalDivider() {
  return <div className="border-l-2 border-grey-200 m-2" />;
}
