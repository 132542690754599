import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import DeleteConfirmation from "@components/delete/DeleteConfirmation";
import Applabel from "@components/hook-form/applabel";
import { endpoints, replaceParams } from "@utils/axios";
import { MonthYearPicker } from "@utils/MonthYearPicker";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { WhatsappCreditDetails } from "./type/WhatsappCreditDetails";
import {
  WhatsappMessageDetails,
  WhatsappMsgConvert,
} from "./type/WhatsappMessageDetails";
import {
  filterRows,
  paginateRows,
  sortRows,
} from "@pages/appointments/appointment-index-table/Helpers";
import { Pagination } from "@pages/appointments/appointment-index-table/Pagination";
import { Toast } from "react-toastify/dist/components";
import { toast } from "react-toastify";
import { EntityBranch, EntityBranchConvert } from "./type/EntityBranch";
import NoData from "@components/no-data/nodata";

const Credits = () => {
  const userData = getUserPersistedOnLocalStorage();

  const msgTypes = ["All", "SMS", "WhatsApp"];
  const [currenMsgType, setCurrentMsgType] = React.useState<string>("All");

  const [whatsappCreditDetails, setWhatsappCreditDetails] =
    useState<WhatsappCreditDetails | null>(null);

  const [whatsappMessageDetails, setWhatsappMessageDetails] = React.useState<
    WhatsappMessageDetails[]
  >([]);

  const [branches, setBranches] = React.useState<EntityBranch[]>([]);
  const [selectedBranch, setSelectedBranch] = React.useState<string>("0");
  const [activePage, setActivePage] = useState<number>(1);
  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [sort, setSort] = useState<{ order: string; orderBy: string }>({
    order: "",
    orderBy: "",
  });
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [usedCredit, setUsedCredit] = React.useState(0);
  const rowsPerPage: number = 8;
  const filteredRows = useMemo(
    () => filterRows(rows, filters),
    [rows, filters]
  );
  const sortedRows = useMemo(
    () => sortRows(filteredRows, sort),
    [filteredRows, sort]
  );
  const calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage);

  const count: number = filteredRows.length;
  const totalPages: number = Math.ceil(count / rowsPerPage);

  const columns = [
    { accessor: "clientPhone", label: "Phone Number" },
    { accessor: "messageSentAt", label: "Message Sent On" },
    { accessor: "messageType", label: "Message Type" },
  ];

  const [dateSelected, setDateSelected] = useState<Date | null>(null);
  const [clearFilterCalled, setclearFilterCalled] = useState<boolean>(false);

  const getWhatsappCreditDetails = async () => {
    try {
      const userData = getUserPersistedOnLocalStorage();
      const paramsMap = new Map<string, string>([
        ["entityid", userData?.entity_id ?? ""],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.credits.whatsappCreditDetails,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      setWhatsappCreditDetails(data);
    } catch (error) {
      // toast.error(error.response.data.data);
      //console.log(error.response.data.data);
    }
  };

  const getWhatsappMessageDetails = async () => {
    try {
      const userData = getUserPersistedOnLocalStorage();
      const paramsMap = new Map<string, string>();

      if (
        userData?.user_role === "ENTITY_OWNER" ||
        userData?.user_role === "PLATFORM_ADMIN"
      ) {
        paramsMap.set("id", selectedBranch ?? "");
      } else {
        paramsMap.set("id", userData?.branch_id ?? "");
      }
      const filteredEndpoint = replaceParams(
        endpoints.credits.whatsappMessageDetails,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];

      if (data !== null) {
        const converted: [] = data.map((details: WhatsappMessageDetails) =>
          WhatsappMsgConvert.toWhatsappMessageDetails(JSON.stringify(details))
        );

        setWhatsappMessageDetails(converted);
        setRows(converted);
      } else {
        setWhatsappMessageDetails([]);
        setRows([]);
      }

      setLoading(false);
    } catch (error) {
      setError(`Error fetching message details: ${error}`);
    }
  };

  useEffect(() => {
    getWhatsappCreditDetails();
    getBranches();
  }, []);

  useEffect(() => {
    getWhatsappMessageDetails();
  }, [selectedBranch]);

  useEffect(() => {
    if (
      whatsappCreditDetails?.whatsappCreditCount ||
      whatsappCreditDetails?.whatsappRemainingCreditCount
    ) {
      const usedCount =
        whatsappCreditDetails.whatsappCreditCount -
        whatsappCreditDetails.whatsappRemainingCreditCount;
      setUsedCredit(usedCount);
    }
  }, [whatsappCreditDetails]);

  const handleWhatsappMessagesDateChange = (
    selectedMonth: number,
    selectedYear: number,
    startDate: Date,
    endDate: Date
  ) => {
    const filteredMessages: any = whatsappMessageDetails?.filter((msg) => {
      if (msg.messageSentAt) {
        const msgSentDate = new Date(msg.messageSentAt?.toString());
        const msgSentMonth = msgSentDate.getMonth() + 1; // Month is zero-based
        const msgSentYear = msgSentDate.getFullYear();

        return (
          msgSentMonth === selectedMonth &&
          msgSentYear === selectedYear &&
          msgSentDate >= startDate &&
          msgSentDate <= endDate
        );
      }
    });

    setDateSelected(startDate);
    setRows(filteredMessages);
    setclearFilterCalled(false);
  };

  const handleSelectChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedBranchId = event.target.value;
    setSelectedBranch(selectedBranchId);
  };

  const getBranches = async () => {
    const userData = getUserPersistedOnLocalStorage();
    const paramsMap = new Map<string, string>([
      ["entityId", userData?.entity_id ?? ""],
    ]);
    const filteredEndpoint = replaceParams(
      endpoints.ownerDashboard.branches,
      paramsMap
    );
    const response = await axios.get(filteredEndpoint);
    const data = response.data["data"];
    const converted = [];

    for (const branch of data) {
      const temp = EntityBranchConvert.toEntityBranch(JSON.stringify(branch));
      converted.push(temp);
    }

    setBranches(converted);
  };

  const handleSearch = (value: string) => {
    setActivePage(1);
    if (whatsappMessageDetails) {
      const filteredMessages: any = whatsappMessageDetails.filter(
        (app: WhatsappMessageDetails) =>
          app.messageType
            ?.replace(/\s/g, "")
            .trim()
            .toLowerCase()
            .includes(value.replace(/\s/g, "").trim().toLowerCase()) ||
          app.clientPhone
            ?.toString()
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          new Date(app.messageSentAt)
            .toLocaleString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: false, // Use 24-hour format
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
      );

      setRows(filteredMessages);
    }
  };

  const useData = getUserPersistedOnLocalStorage();

  //console.log("DET" + userData?.entity_id);

  return (
    <div className="h-full w-full">
      {whatsappCreditDetails === null ? (
        <NoData message="Whatsapp credits not set up for entity. For assistance with adding new credits, please reach out to our support team at support@gritup.io." />
      ) : (
        <>
          {userData?.user_role === "ENTITY_OWNER" &&
            (whatsappCreditDetails?.whatsappRemainingCreditCount === 0 ? (
              <div className="flex flex-col">
                <NoData message="Your credits have been exhausted. For assistance with adding new credits, please reach out to our support team at support@gritup.io." />
                <div className="flex justify-between w-full bg-gray-200 rounded-md mb-4 px-4">
                  <h6 className="my-4 font-bold">
                    Total Credits:{" "}
                    <span>{whatsappCreditDetails?.whatsappCreditCount}</span>
                  </h6>
                  <h6 className="my-4 font-bold">
                    Remaining Credits:{" "}
                    {whatsappCreditDetails?.whatsappRemainingCreditCount}
                  </h6>
                  <h6 className="my-4 font-bold">
                    Used Credits: <span>{usedCredit}</span>{" "}
                  </h6>
                </div>
              </div>
            ) : (
              <div className="flex justify-between w-full bg-gray-200 rounded-md mb-4 px-4">
                <h6 className="my-4 font-bold">
                  Total Credits:{" "}
                  <span>{whatsappCreditDetails?.whatsappCreditCount}</span>
                </h6>
                <h6 className="my-4 font-bold">
                  Remaining Credits:{" "}
                  {whatsappCreditDetails?.whatsappRemainingCreditCount}
                </h6>
                <h6 className="my-4 font-bold">
                  Used Credits: <span>{usedCredit}</span>{" "}
                </h6>
              </div>
            ))}
          <span className="text-xl font-bold text-black mb-2">
            Outgoing messages history
          </span>
          <div className="flex flex-col sm:flex-row justify-normal w-full mb-1">
            <div className="w-full self-center sm:mr-2">
              <SearchBar handleSearch={handleSearch} />
            </div>
            <div className="flex flex-row justify-between sm:justify-end w-full md:w-2/5 space-x-2">
              {(userData?.user_role === "ENTITY_OWNER" ||
                userData?.user_role === "PLATFORM_ADMIN") && (
                <div className="flex flex-col w-1/2">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Branch
                  </label>
                  <select
                    className="border border-1 rounded-md w-full cursor-pointer"
                    onChange={handleSelectChange}
                  >
                    <option key={"All"} value={"0"}>
                      All
                    </option>
                    {branches.map((branch) => (
                      <option key={branch.id} value={branch.id}>
                        {branch.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="flex flex-col w-1/2 ml-auto">
                <label className="block text-sm font-medium leading-6 text-gray-900 w-full">
                  Filter
                </label>
                <MonthYearPicker
                  onChange={handleWhatsappMessagesDateChange}
                  ferchedStartDate={
                    clearFilterCalled === true ? null : dateSelected
                  }
                />
                <div className="flex flex-col items-end">
                  <p
                    className="self-end underline hover:cursor-pointer"
                    onClick={() => {
                      getWhatsappMessageDetails();
                      setclearFilterCalled(true);
                    }}
                  >
                    Clear Filter
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <Table
              rows={whatsappMessageDetails || []}
              columns={columns}
              calculatedRows={calculatedRows}
              activePage={activePage}
              count={count}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              setActivePage={setActivePage}
              getWhatsappMessageDetails={getWhatsappMessageDetails}
            />
          </div>
          {count > 0 ? (
            <Pagination
              activePage={activePage}
              count={count}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              setActivePage={setActivePage}
            />
          ) : (
            <NoData message="No data found" />
          )}
        </>
      )}
    </div>
  );
};

interface Column {
  accessor: string;
  label: string;
  format?: (value: any) => string | JSX.Element;
}

interface TableProps {
  columns: Column[];
  rows: any[];

  calculatedRows: Record<string, any>[];
  activePage: number;
  count: number;
  rowsPerPage: number;
  totalPages: number;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
  getWhatsappMessageDetails: () => Promise<void>;
}

export const Table: React.FC<TableProps> = ({
  columns,
  rows,
  calculatedRows,
  activePage,
  count,
  rowsPerPage,
  totalPages,
  setActivePage,
  getWhatsappMessageDetails,
}) => {
  return (
    <>
      <table className="w-full  shadow outline outline-gray-200 outline-1 p-2 mb-4">
        <thead className="bg-gray-50 rounded-t-lg">
          <tr>
            {columns.map((column) => {
              return (
                <th
                  key={column.accessor}
                  className="px-4 py-3 border-b border-gray-300"
                >
                  <div className="flex items-center space-x-1">
                    <span>{column.label}</span>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="bg-white">
          {calculatedRows.map((row, index) => {
            return (
              <tr
                key={row.id}
                className={(() => {
                  // if not last row then add border
                  if (index !== calculatedRows.length - 1) {
                    return "border-b border-gray-200";
                  }

                  return "";
                })()}
              >
                <td
                  key={"clientPhone"}
                  className={`px-4 py-3 ${"text-gray-500"}`}
                >
                  {row["clientPhone"]}
                </td>

                <td
                  key={"messageSentAt"}
                  className={`px-4 py-3 ${"text-gray-500"}`}
                >
                  {
                    // Step 1: Parse the UTC timestamp into a Date object
                    new Date(row["messageSentAt"]).toLocaleString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: false, // Use 24-hour format
                      timeZone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                    })
                  }
                </td>

                <td
                  key={"messageType"}
                  className={`px-4 py-3 ${"text-gray-500"}`}
                >
                  {row["messageType"]}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

function SearchBar({ handleSearch }: { handleSearch: (value: any) => void }) {
  return (
    <div>
      <input
        type="text"
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search for mobile number, message sent on and type"
        className="w-full px-4 py-2 text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-0 focus:border-primary-500"
      />
    </div>
  );
}

export default Credits;
