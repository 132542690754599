import { Button, OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import InputField from "@components/hook-form/input-field";
import ValidationError from "@components/hook-form/validation-error";
import { Dialog, Transition } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useState, Fragment, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import Modal from "@components/modal";
import Applabel from "@components/hook-form/applabel";
import PhoneInput, {
  formatPhoneNumber,
  getCountryCallingCode,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { CountryCode, E164Number } from "libphonenumber-js";
import "react-phone-number-input/style.css";
import { convertPhoneNumberAndCodeToNumber } from "@utils/phoneUtils";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { Convert } from "../types/Consent";
import moment from "moment";
import ConsentDialog from "@pages/add-patient/view/ConsentDialog";
import { useNavigate } from "react-router-dom";
import { EntityBranch, EntityBranchConvert } from "./types/EntityBranch";

// export const ConsentSchema = z.object({
//   consentgiven: z.boolean().refine(val => val === true, {
//     message: 'Please Give Consent Before Proceeding',
//   }),
// });
// export type Consent = z.infer<typeof ConsentSchema>;
export const QuickAddPatientSchema = z.object({
  salutation: z.string().optional(),
  firstName: z.string().min(1, { message: "Enter first name" }),
  lastName: z.string().min(1, { message: "Enter last name" }),
  email: z
    .string()
    .email({ message: "Must be a valid email" })
    .optional()
    .or(z.literal("")),
  phoneCode: z.string().min(1, { message: "Phone Code is required" }),
  phone: z.string().superRefine((val, ctx) => {
    //
    if (!val || val.trim() === "") {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please enter Phone`,
        fatal: true,
      });
      return z.NEVER;
    }
    if (!isValidPhoneNumber(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        fatal: true,
        message: "Invalid phone number format",
      });

      return z.NEVER;
    }

    if (!isPossiblePhoneNumber(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Invalid phone number length`,
        fatal: true,
      });
      return z.NEVER;
    }
  }),

  // consent: ConsentSchema,
  sendOnboardEmail: z.boolean().refine(
    (val) => {
      return true;
    },
    { message: "Optional" }
  ),
  whatsAppConsent: z.boolean(),
});
export type QuickAddPatientSchemaType = z.infer<typeof QuickAddPatientSchema>;

export function QuickPatientAddDialog(props: {
  setQuickPatientAddDialog: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  let [isOpen, setIsOpen] = useState(true);
  const [formData, setFormData] = useState({});
  const axios = useAxios();
  const [phoneNumber, setPhoneNumber] = useState("91");
  const [isDisabled, setIsDisabled] = React.useState(false);
  const handlePhoneNumberChange = (value: E164Number | undefined) => {
    methods.setValue("phone", value?.toString() ?? "");
  };
  // const [consentDialog, setConsentDialog] = useState(false);
  // const [editorState, setEditorState] = React.useState(
  //   EditorState.createEmpty(),
  // );
  // const [consentId, setConsentId] = useState('');
  // const [consentVersion, setConsentVersion] = useState('');
  // const [consentTime, setConsentTime] = useState<moment.Moment>();

  // const userData = getUserPersistedOnLocalStorage();

  // const getClientConsent = async () => {
  //   try {
  //     const paramsMap = new Map<string, string>([
  //       ['id', userData?.entity_id ?? ''],
  //     ]);
  //     const filteredEndpoint = replaceParams(
  //       endpoints.entity.fetchClientConsent,
  //       paramsMap,
  //     );
  //     const response = await axios.get(filteredEndpoint);
  //     const data = response.data['data'];
  //     const converted = Convert.toConsent(JSON.stringify(data));
  //     const blocksFromHTML = convertFromHTML(converted.content);
  //     const initialContentState = ContentState.createFromBlockArray(
  //       blocksFromHTML.contentBlocks,
  //       blocksFromHTML.entityMap,
  //     );
  //     setEditorState(EditorState.createWithContent(initialContentState));
  //     methods.setValue('consent.consentgiven', false);
  //     setConsentId(converted.id);
  //     setConsentTime(moment(new Date()));
  //     setConsentVersion(converted.version);
  //   } catch (error) {
  //     console.error('Error fetching client consent:', error);
  //   }
  // };

  // React.useEffect(() => {
  //   getClientConsent();
  // }, []);

  function closeModal() {
    setIsOpen(false);
    props.setQuickPatientAddDialog(false);
  }

  const methods = useForm<QuickAddPatientSchemaType>({
    resolver: zodResolver(QuickAddPatientSchema),
  });
  useEffect(() => {
    getBranches();
    const countryCode = getCountryCallingCode("IN");
    methods.setValue("phoneCode", countryCode?.toString() ?? "");
    methods.setValue("phone", "");
  }, []);

  const errors = methods.formState.errors;
  const userData = getUserPersistedOnLocalStorage();
  const [branchId, setBranchId] = React.useState<string | null>(
    userData?.branch_id.toString() ?? ""
  );
  const [branches, setBranches] = React.useState<EntityBranch[] | null>(null); // this is the list of branches [{}]
  const [loading, setLoading] = React.useState(true);
  const [error, setApiError] = React.useState("");

  const getBranches = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["entityId", userData?.entity_id ?? ""],
      ]);
      const filteredEndpoint = replaceParams(endpoints.branch.list, paramsMap);

      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      const convertedBranchList = data.map((entity: any) =>
        EntityBranchConvert.toEntityBranch(JSON.stringify(entity))
      );

      setBranches(convertedBranchList);

      //console.log("Branches" + JSON.stringify(branches));

      setLoading(false);
    } catch (error) {
      console.error("Error fetching entity list:", error);
      setApiError(error.message);
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<QuickAddPatientSchemaType> = async (data) => {
    methods.trigger();
    setIsDisabled(true);
    try {
      const userData = getUserPersistedOnLocalStorage();
      const combinedData = { ...formData, ...data };
      const phoneData = convertPhoneNumberAndCodeToNumber({
        phoneNumber: combinedData.phone,
        phoneCode: combinedData.phoneCode,
      });

      QuickAddPatientSchema.parse(combinedData);
      await axios.post(
        `${endpoints.patient.onboard}/${methods.watch("sendOnboardEmail")}`,
        {
          entityId: userData?.entity_id,
          entityBranchId: branchId?.toString() ?? "",
          salutation: combinedData.salutation,
          firstName: combinedData.firstName,
          lastName: combinedData.lastName,
          email: combinedData.email,
          phone: phoneData.phoneNumber,
          phoneCode: phoneData.phoneCode,
          whatsAppConsent: combinedData.whatsAppConsent,
          isAppointmentNotificationEnabled: combinedData.whatsAppConsent,
          // consent: {
          //   time: consentTime,
          //   id: consentId,
          //   version: consentVersion,
          //   consentgiven: methods.getValues('consent.consentgiven'),
          // },
        }
      );

      closeModal();
      toast.success("Patient added");
    } catch (e) {}
  };

  const handleBranchSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setBranchId(event.target.value);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="flex flex-row w-full justify-between mx-4 my-2">
          <h3 className="text-md leading-6 font-bold text-gray-900">
            Onboard Patient
          </h3>
        </div>
        <hr className="w-full" />

        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            onChange={() =>
              setFormData({ ...formData, ...methods.getValues() })
            }
          >
            <div className="flex flex-col w-full h-full px-4 py-2 space-y-2">
              {userData?.user_role === "ENTITY_OWNER" ? (
                <>
                  <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
                    Select Branch
                  </label>

                  <select
                    onChange={handleBranchSelectChange}
                    name="entityBranchId"
                    className={`mr-4 lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                  >
                    {branches?.map((branch) => (
                      <option key={branch.id} value={branch.id}>
                        {branch.name}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                <div></div>
              )}
              <div className="flex flex-row">
                <div className="flex-shrink">
                  <InputField
                    name="salutation"
                    key="salutation"
                    label="Title"
                    placeholder="Title"
                    type="select"
                    mandatoryLabel={false}
                    generateOptions={() => {
                      return [
                        "Mr",
                        "Mrs",
                        "Ms",
                        "Miss",
                        "Dr",
                        "Prof",
                        "Rev",
                        "Hon",
                        "Capt",
                        "Lt. Col",
                      ].map((salutation) => {
                        return (
                          <option key={salutation} value={salutation}>
                            {salutation}
                          </option>
                        );
                      });
                    }}
                  />
                  {errors.salutation && (
                    <ValidationError
                      message={errors.salutation?.message?.toString() ?? ""}
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="flex-grow-0 w-1/2 mr-2">
                  <InputField
                    name="firstName"
                    key="firstName"
                    label="First Name"
                    placeholder="Enter first name"
                    type="text"
                    mandatoryLabel={true}
                  />
                  {errors.firstName && (
                    <ValidationError
                      message={errors.firstName?.message?.toString() ?? ""}
                    />
                  )}
                </div>
                <div className="flex-grow-0 w-1/2">
                  <InputField
                    name="lastName"
                    key="lastName"
                    label="Last Name"
                    placeholder="Enter last name"
                    type="text"
                    mandatoryLabel={true}
                  />
                  {errors.lastName && (
                    <ValidationError
                      message={errors.lastName?.message?.toString() ?? ""}
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col  w-full md:w-1/3">
                <Applabel label="Email" />
                <input
                  {...methods.register("email")}
                  placeholder="Patient Email"
                  className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                  value={methods.watch("email")}
                />
                {errors.email && (
                  <ValidationError
                    message={errors.email?.message?.toString() ?? ""}
                  />
                )}
              </div>
              <div className="flex flex-col md:w-1/2">
                <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
                  Mobile Number<span className="text-red-500"> *</span>
                </label>
                <div className="flex flex-row">
                  {" "}
                  <PhoneInput
                    onCountryChange={(country?: CountryCode | undefined) => {
                      if (country) {
                        const countryCode = getCountryCallingCode(country);
                        methods.setValue(
                          "phoneCode",
                          countryCode?.toString() ?? ""
                        );
                      }
                    }}
                    {...methods.register("phone")}
                    international={true}
                    defaultCountry="IN"
                    countryCallingCodeEditable={false}
                    placeholder="Enter phone number"
                    value={methods.watch("phone")}
                    onChange={(value?: E164Number | undefined) => {
                      methods.clearErrors("phone");
                      handlePhoneNumberChange(value);
                    }}
                    onBlur={() => {
                      methods.trigger("phone");
                    }}
                    className="w-full"
                  />
                </div>
                {errors.phone && (
                  <ValidationError
                    message={errors.phone?.message?.toString() ?? ""}
                  />
                )}
              </div>
              <div className="flex flex-row items-center mt-6">
                <input
                  {...methods.register("sendOnboardEmail")}
                  type="checkbox"
                  className="form-checkbox h-6 w-6 text-indigo-600 rounded-md focus:ring-2 focus:ring-indigo-600"
                  defaultChecked
                />
                <label className="ml-3 text-gray-800 font-medium">
                  Send onboarding and consent form to patient
                </label>
              </div>
              <div className="flex flex-row items-center mt-6">
                <input
                  {...methods.register("whatsAppConsent")}
                  type="checkbox"
                  className="form-checkbox h-6 w-6 text-indigo-600 rounded-md focus:ring-2 focus:ring-indigo-600"
                  defaultChecked
                />
                <label className="ml-3 text-gray-800 font-medium">
                  Send notifications, reminders, important updates through
                  WhatsApp
                </label>
              </div>
              {/* {consentDialog && (
                <ConsentDialog
                  setConsentDialog={setConsentDialog}
                  consentData={editorState}
                />
              )}
              <div className="flex flex-row items-center mt-6">
                <input
                  {...methods.register('consent.consentgiven')}
                  type="checkbox"
                  className="form-checkbox h-6 w-6 text-indigo-600 rounded-md focus:ring-2 focus:ring-indigo-600"
                  onChange={e => {
                    if (e.target.checked) {
                      setConsentTime(moment(new Date()));
                    }
                  }}
                />
                <label
                  className="ml-3 text-gray-800 font-medium hover:underline"
                  onClick={() => setConsentDialog(true)}
                >
                  I Agree to give my
                  <span className="text-[#4b6cd8]"> Consent</span> to the Data
                  Entered
                </label>
              </div>
              {errors.consent && (
                <ValidationError
                  message={
                    errors.consent.consentgiven?.message?.toString() ?? ''
                  }
                />
              )} */}
            </div>

            <div className="flex flex-row w-full mt-4 p-4 bg-gray-100 justify-end">
              <OutlinedButton
                variant={ButtonVariant.PRIMARY}
                type="button"
                onClick={closeModal}
                children="Close"
                className="mr-2"
              />
              <Button
                variant={ButtonVariant.PRIMARY}
                isDisabled={isDisabled}
                onClick={() => {
                  setFormData({ ...formData, ...methods.getValues() });
                }}
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
}
