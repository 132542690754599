import { Button, OutlinedButton } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import TextAreaField from '@components/hook-form/textarea-field';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { CheckIcon } from '@heroicons/react/24/outline';

import { endpoints, replaceParams } from '@utils/axios';
import axios from 'axios';
import { ManagePatientContext } from './ManagePatient';
import { Convert, DiagnosisDetails } from '../types/DiagnosisDetails';
import { ValidationError } from '@components/hook-form';
import { toast } from 'react-toastify';

export const TreatmentCategorySchema = z.object({
  orthopedic: z.boolean(),
  musculoskeletal: z.boolean(),
  geriatric: z.boolean(),
  womansHealth: z.boolean(),
  neurological: z.boolean(),
});
export type TreatmentCategory = z.infer<typeof TreatmentCategorySchema>;

export const DiagnosisDetailsSchema = z.object({
  treatmentCategory: TreatmentCategorySchema,
  chiefComplaint: z.string().optional(),
  provisionalDiagnosis: z.string().optional(),
});
export type DiagnosisDetailsType = z.infer<typeof DiagnosisDetailsSchema>;

export default function Diagnosis() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = React.useState(true);
  const [selectedCategoryTreatment, setSelectedCategoryTreatment] = useState<
    string[]
  >([]);
  const [diagnosis, setDiagnosis] = React.useState<DiagnosisDetails | null>(
    null,
  );
  const patientId = useContext(ManagePatientContext);
  const [error, setError] = React.useState('');

  const categoryOfTreatments = [
    'Orthopedic',
    'Musculoskeletal',
    'Geriatric',
    "Woman's Health",
    'Neurological',
  ];

  const initialize = () => {
    // set default values for the form
    return {
      TreatmentCategorySchema: diagnosis?.treatmentCategory ?? '',
      chiefComplaint: diagnosis?.chiefComplaint ?? '',
      provisionalDiagnosis: diagnosis?.provisionalDiagnosis ?? '',
    };
  };
  useEffect(() => {
    setIsDisabled(false);
    const getDiagnosisData = async () => {
      try {
        const paramsMap = new Map<string, string>([
          ['patientId', patientId.patientId],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.diagnosis.view,
          paramsMap,
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data['data']['diagnosis'];

        const convertedDiagnosis = Convert.toDiagnosisDetails(
          JSON.stringify(data),
        );
        setDiagnosis(convertedDiagnosis);

        methods.reset({ ...convertedDiagnosis });
        setIsDisabled(false);
        setLoading(false);
      } catch (error) {
        console.error('Error getting diagnosis data:', error);

        setLoading(false);
      }
    };
    getDiagnosisData();
  }, []);

  const methods = useForm<DiagnosisDetailsType>({
    resolver: zodResolver(DiagnosisDetailsSchema),
  });

  const onSubmit: SubmitHandler<DiagnosisDetailsType> = async data => {
    methods.trigger();
    try {
      DiagnosisDetailsSchema.parse(data);

      const chiefObject = { chiefComplaint: data.chiefComplaint };
      const provisionalObject = {
        provisionalDiagnosis: data.provisionalDiagnosis,
      };

      const paramsMap = new Map<string, string>([
        ['patientId', patientId.patientId],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.diagnosis.updateTreatmentCategory,
        paramsMap,
      );
      const response = await axios.put(
        filteredEndpoint,
        diagnosis?.treatmentCategory,
      );

      const filteredEndpointprovisional = replaceParams(
        endpoints.diagnosis.updateProvisionalDiagnosis,
        paramsMap,
      );
      const response1 = await axios.put(
        filteredEndpointprovisional,
        provisionalObject,
      );

      const filteredEndpointChief = replaceParams(
        endpoints.diagnosis.updateChiefComplaints,
        paramsMap,
      );
      const response2 = await axios.put(filteredEndpointChief, chiefObject);
      toast.success('Diagnosis details updated successfully');

      setIsDisabled(false);
    } catch (error) {
      console.error('Error submitting form:', error);
      setError(error.response.data['data'].toString());
    }
  };

  const handleToggleEdit = () => {
    setIsDisabled(prevState => !prevState);
  };

  const errors = methods.formState.errors;
  function getChipValue(chipText: string) {
    switch (chipText) {
      case 'Orthopedic':
        return diagnosis?.treatmentCategory.orthopedic;
      case 'Musculoskeletal':
        return diagnosis?.treatmentCategory.musculoskeletal;
      case 'Geriatric':
        return diagnosis?.treatmentCategory.geriatric;
      case "Woman's Health":
        return diagnosis?.treatmentCategory.womansHealth;
      case 'Neurological':
        return diagnosis?.treatmentCategory.neurological;
      default:
        return false;
    }
  }
  function setChipValue(categoryOfTreatment: any) {
    if (categoryOfTreatment === 'Orthopedic') {
      setDiagnosis({
        ...diagnosis,
        treatmentCategory: {
          ...diagnosis?.treatmentCategory,
          orthopedic: !diagnosis?.treatmentCategory.orthopedic,
        },
      });
    } else if (categoryOfTreatment === 'Musculoskeletal') {
      setDiagnosis({
        ...diagnosis,
        treatmentCategory: {
          ...diagnosis?.treatmentCategory,
          musculoskeletal: !diagnosis?.treatmentCategory.musculoskeletal,
        },
      });
    } else if (categoryOfTreatment === 'Geriatric') {
      setDiagnosis({
        ...diagnosis,
        treatmentCategory: {
          ...diagnosis?.treatmentCategory,
          geriatric: !diagnosis?.treatmentCategory.geriatric,
        },
      });
    } else if (categoryOfTreatment === "Woman's Health") {
      setDiagnosis({
        ...diagnosis,
        treatmentCategory: {
          ...diagnosis?.treatmentCategory,
          womansHealth: !diagnosis?.treatmentCategory.womansHealth,
        },
      });
    } else if (categoryOfTreatment === 'Neurological') {
      setDiagnosis({
        ...diagnosis,
        treatmentCategory: {
          ...diagnosis?.treatmentCategory,
          neurological: !diagnosis?.treatmentCategory.neurological,
        },
      });
    }
  }
  return (
    <div className="grid-cols-1 gap-x-8 gap-y-8 pt-2 md:grid-cols-3 bg-grey-200 ">
      <div className="sticky top-0 right-0 z-1 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
            Patient Diagnosis
          </h2>
        </div>
        <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          <Button
            variant={ButtonVariant.PRIMARY}
            type="button"
            className="ml-3"
            onClick={
              isDisabled ? methods.handleSubmit(onSubmit) : handleToggleEdit
            }
          >
            {isDisabled ? 'Save' : 'Edit'}
          </Button>
        </div>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && diagnosis && (
        <FormProvider {...methods}>
          <form>
            <hr className="my-2" />
            <div className="flex flex-row md:flex-row w-full mt-0 md:space-x-4">
              <div className="flex flex-col  w-full ">
                <span className="flex-none font-medium mb-0">
                  Category of Treatment
                </span>
                <span className="text-gray-500">
                  The Category of the course of treatment to be administered
                </span>
                <br />
                <div className="max-h-56  h overflow-y-auto">
                  <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-5">
                    {categoryOfTreatments.map(tr => {
                      const isActive = getChipValue(tr);
                      
                      return (
                        <div
                          key={tr}
                          className={`flex items-center justify-evenly rounded text-center text-sm p-2 max-h-10 hover:bg-tertiary-200 border 
                          ${
                            isActive
                              ? !isDisabled
                                ? 'bg-gray-100'
                                : 'bg-secondary-500'
                              : 'bg-white'
                          }`}
                          onClick={() => {
                            if (isDisabled) {
                              setChipValue(tr);
                            }
                          }}
                        >
                          {isActive ? (
                            <CheckIcon
                              className="h-4 w-4 mt-1 flex justify-evenly"
                              aria-hidden="true"
                            />
                          ) : (
                            <></>
                          )}

                          <span className="flex flex-col text-black">{tr}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 mt-6">
                  <div className="flex flex-col  w-full ">
                    <span className="flex-none font-medium mb-0">
                      Chief Complaint (C/O)
                    </span>
                    <span className="text-gray-500">
                      The Chief of complaint of the patient for the future
                      reference
                    </span>
                    <br />
                    <div className="flex flex-row w-full pr-2">
                      <TextAreaField
                        key="chiefComplaint"
                        name="chiefComplaint"
                        placeholder="Enter complaint of the patient "
                        rows={5}
                        disabled={!isDisabled}
                        value={methods.watch('chiefComplaint')}
                      />
                      {errors.chiefComplaint && (
                        <ValidationError
                          message={
                            errors.chiefComplaint?.message?.toString() ?? ''
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 mt-6">
                  <div className="flex flex-col  w-full ">
                    <span className="flex-none font-medium mb-0">
                      Provisional Diagnosis
                    </span>
                    <span className="text-gray-500">
                      The Provisional Diagnosis of the patient by
                      Physiotherapist
                    </span>
                    <br />
                    <div className="flex flex-row w-full pr-2">
                      <TextAreaField
                        key="provisionalDiagnosis"
                        name="provisionalDiagnosis"
                        placeholder="Enter provisional diagnosis of the patient "
                        rows={5}
                        disabled={!isDisabled}
                        value={methods.watch('provisionalDiagnosis')}
                      />
                      {errors.provisionalDiagnosis && (
                        <ValidationError
                          message={
                            errors.provisionalDiagnosis?.message?.toString() ??
                            ''
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  );
}
