import { useMemo, useState } from "react";

import React from "react";

import { Pagination } from "@pages/appointments/appointment-index-table/Pagination";
import {
  filterRows,
  paginateRows,
  sortRows,
} from "@pages/appointments/appointment-index-table/Helpers";

import moment from "moment";
import { RefundDetails1 } from "@pages/manage-patient-new/types/RefundDetails";

export default function RefundIndexPage(props: {
  patientId?: string;
  rows: RefundDetails1[];
  setRows: React.Dispatch<React.SetStateAction<RefundDetails1[]>>;
  refundDetails: RefundDetails1[] | undefined;
  setRefundDetails: React.Dispatch<
    React.SetStateAction<RefundDetails1[] | undefined>
  >;
}) {
  const columns = [
    { accessor: "amount", label: "Refund amount" },
    { accessor: "refundDate", label: "Date" },

    { accessor: "invoiceNo", label: "Invoice Number" },
  ];

  const [activePage, setActivePage] = useState<number>(1);
  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [sort, setSort] = useState<{ order: string; orderBy: string }>({
    order: "",
    orderBy: "",
  });

  const rowsPerPage: number = 10;
  const filteredRows = useMemo(
    () => filterRows(props.rows, filters),
    [props.rows, filters]
  );
  const sortedRows = useMemo(
    () => sortRows(filteredRows, sort),
    [filteredRows, sort]
  );

  const calculatedRows = useMemo(
    () => paginateRows(sortedRows, activePage, rowsPerPage),
    [sortedRows, activePage, rowsPerPage]
  );

  const count: number = filteredRows.length;
  const totalPages: number = Math.ceil(count / rowsPerPage);

  function handleSearch(value: string) {
    if (value === "") {
      setFilters({});
      setSort({ order: "", orderBy: "" });
      if (props.refundDetails) {
        props.setRows(props.refundDetails);
      } // Reset props.rows to the original refundDetails array
    } else {
      if (props.refundDetails) {
        const filteredRows = props.refundDetails.filter((row: any) => {
          return (
            moment(row.refundDate)
              .format("DD-MM-YYYY")
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            row.amount.toString().toLowerCase().includes(value.toLowerCase()) ||
            row.invoiceNo.toLowerCase().includes(value.toLowerCase())
          );
        });
        setFilters({}); // Reset filters
        setSort({ order: "", orderBy: "" }); // Reset sorting
        props.setRows(filteredRows);
      }
    }
  }

  return (
    <>
      <div className=" flex flex-row ">
        <div className="flex flex-col w-full  ">
          <div className="flex m-2 flex-row justify-normal w-full">
            <input
              type="text"
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              placeholder="Search for refunds by Invoice Number, Date and Amount"
              className="w-full h-min px-4 py-2 mb-4 text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-0 focus:border-primary-500"
            ></input>
          </div>
          <div className="flex flex-col w-full">
            <table className="w-full  shadow outline outline-gray-200 outline-1 p-2 mb-4">
              <thead className="bg-gray-50 rounded-t-lg">
                <tr>
                  {columns.map((column) => {
                    return (
                      <th
                        key={column.accessor}
                        className="px-4 py-3 border-b border-gray-300"
                      >
                        <div className="flex items-center space-x-1">
                          <span>{column.label}</span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="bg-white">
                {calculatedRows.map((row, index) => {
                  return (
                    <tr
                      key={row.id}
                      className={(() => {
                        if (index !== calculatedRows.length - 1) {
                          return "border-b border-gray-200";
                        }

                        return "";
                      })()}
                    >
                      <td
                        key={"amount"}
                        className={`px-4 py-3 ${"text-gray-500"}`}
                      >
                        ₹{row["amount"]}
                      </td>
                      <td
                        key={"refundDate"}
                        className={`px-4 py-3 ${"text-gray-500"}`}
                      >
                        {moment(row["refundDate"])
                          .format("DD-MM-YYYY")
                          .toString()}
                      </td>

                      <td
                        key={"invoiceNo"}
                        className={`px-4 py-3 ${"text-gray-500"}`}
                      >
                        {row["invoiceNo"] === "" ? "N/A" : row["invoiceNo"]}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* Billing table end  */}
          </div>
          {count > 0 ? (
            <Pagination
              activePage={activePage}
              count={count}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              setActivePage={setActivePage}
            />
          ) : (
            <p>No data found</p>
          )}
        </div>
        <hr className="my-4" />
      </div>
    </>
  );
}
