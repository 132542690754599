// To parse this data:
//
//   import { Convert, GaitAssessment } from "./file";
//
//   const gaitAssessment = Convert.toGaitAssessment(json);

export type GaitAssessment = {
  id?: null;
  masterAssessmentId?: string;
  frontalPlaneObservation?: string;
  saggitalPlaneObservation?: string;
  frontalQuality?: FrontalQuality[];
};

export type FrontalQuality = {
  name?: string;
  type?: string;
  isNormal?: boolean;
  isGood?: boolean;
  isPoor?: boolean;
  comments?: string;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toGaitAssessment(json: string): GaitAssessment {
    return JSON.parse(json);
  }

  public static gaitAssessmentToJson(value: GaitAssessment): string {
    return JSON.stringify(value);
  }
}
