// To parse this data:
//
//   import { Convert, FeedbackDetails } from "./file";
//
//   const feedbackDetails = Convert.toFeedbackDetails(json);

export type FeedbackDetails = {
    id:             string;
    clientId:       string;
    entityId:       string;
    entityBranchId: string;
    entityUserId:   string;
    feedbackdate:   Date;
    feedback:       Feedback[];
}

export type Feedback = {
    question: string;
    answer:   string;
}

// Converts JSON strings to/from your types
export class Convert {
    public static toFeedbackDetails(json: string): FeedbackDetails {
        return JSON.parse(json);
    }

    public static feedbackDetailsToJson(value: FeedbackDetails): string {
        return JSON.stringify(value);
    }
}
