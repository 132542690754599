// To parse this data:
//
//   import { Convert, AppointmentStatistics } from "./file";
//
//   const appointmentStatistics = Convert.toAppointmentStatistics(json);

export interface AppointmentStatistics {
  cancelledAppointments: number[];
  completedAppointments: number[];
  months: string[];
  noShowAppointments: number[];
  totalAppointmentsCount: number[];
}

// Converts JSON strings to/from your types
export class Conversion {
  public static toAppointmentStatistics(json: string): AppointmentStatistics {
    return JSON.parse(json);
  }

  public static appointmentStatisticsToJson(
    value: AppointmentStatistics,
  ): string {
    return JSON.stringify(value);
  }
}
