import { Button } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import { InputField } from "@components/hook-form";
import Applabel from "@components/hook-form/applabel";
import { Switch } from "@headlessui/react";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import { duration } from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { z } from "zod";

// Define the schema for the appointment setting data
export const AppointmentSettingSchema = z.object({
  appointmentType: z
    .string()
    .min(1, { message: "Appointment Type is required" }),
  duration: z.number(),
  charges: z.number(),
});

const AppointmentSettingsArraySchema = z.array(AppointmentSettingSchema);

interface AppointmentSettingComponentProps {
  entityId: string;
}

interface AppointmentSetting {
  appointmentType: string;
  duration: number;
  charges: number;
}

export default function AppointmentSettingComponent(props: {
  entityId: string;
}) {
  const [loading, setLoading] = useState(true);
  const [addingAppointmentType, setAddingAppointmentType] = useState(false);
  const [appointmentTypes, setAppointmentTypes] = useState<
    AppointmentSetting[]
  >([
    { appointmentType: "Consulting", duration: 30, charges: 0 },
    { appointmentType: "Therapy", duration: 45, charges: 0 },
    { appointmentType: "Follow-up", duration: 15, charges: 0 },
  ]);
  // const [dynamicAppointmentTypes, setDynamicAppointmentTypes] = useState<AppointmentSetting[]>(appointmentTypes);

  const [isEditable, setIsEditable] = useState(false);

  const [isError, setIsError] = useState(false);
  useEffect(() => {
    getAppointmentTypes();
  }, []);

  const getAppointmentTypes = async () => {
    try {
      const paramsMap = new Map<string, string>([["id", props.entityId]]);
      const filteredEndpoint = replaceParams(
        endpoints.entity.fetchAppointmentSetting,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];

      const appointmentTypesData =
        AppointmentSettingsArraySchema.safeParse(data);
      if (!appointmentTypesData.success) {
        throw new Error(appointmentTypesData.error.message);
      }

      setAppointmentTypes(appointmentTypesData.data);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      console.error("Error fetching appointment types:", error);
      setLoading(false);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const hasEmptyAppointmentType = appointmentTypes.some(
      (type) => type.appointmentType.trim() === ""
    );

    if (hasEmptyAppointmentType) {
      toast.error("Appointment types cannot be empty");
      setLoading(false);
      return;
    }

    try {
      const paramsMap = new Map<string, string>([["id", props.entityId]]);
      const filteredEndpoint = replaceParams(
        endpoints.entity.updateAppointmentSetting,
        paramsMap
      );

      const response = await axios.put(filteredEndpoint, {
        appointmentSettings: appointmentTypes,
      });

      if (response.data.status === 200) {
        toast.success("Appointment settings updated successfully");
        setIsEditable(false);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  const addAppointmentType = () => {
    setAppointmentTypes((prevAppointmentTypes) => [
      ...prevAppointmentTypes,
      { appointmentType: "", duration: 10, charges: 0 },
    ]);
    setAddingAppointmentType(true);
    setIsEditable(true);
  };

  const deleteAppointmentType = async (
    index: number,
    appointmentTypeValue?: string
  ) => {
    const updatedAppointmentTypes = [...appointmentTypes];
    updatedAppointmentTypes.splice(index, 1);
    setAppointmentTypes(updatedAppointmentTypes);

    if (appointmentTypeValue) {
      try {
        const paramsMap = new Map<string, string>([
          ["id", props.entityId],
          ["type", appointmentTypeValue],
        ]);

        const filteredEndpoint = replaceParams(
          endpoints.entity.deleteAppointmentType,
          paramsMap
        );
        await axios.delete(filteredEndpoint);
        toast.success("Appointment type deleted successfully");
        getAppointmentTypes();
      } catch (error) {
        console.error("Error deleting appointment type:", error);
        toast.error("Failed to delete appointment type");
      }
    }
  };

  const handleChangeAppointmentType = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    const newAppointmentTypes = [...appointmentTypes];
    newAppointmentTypes[index].appointmentType = value;
    setAppointmentTypes(newAppointmentTypes);
    setIsError(value.trim() === "");
  };
  return (
    <>
      <table className="w-full xl:w-1/2 md:w-full divide-y divide-gray-300 ">
        <thead>
          <tr>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
              Mode of Appointment Type
            </th>
            <th className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
              Duration
            </th>
            <th className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
              Charges per session
            </th>
          </tr>
        </thead>
        <tbody>
          {appointmentTypes.map((appointmentType, index) => (
            <tr key={index}>
              <td className="border-0 px-2 py-4">
                {addingAppointmentType &&
                index === appointmentTypes.length - 1 &&
                index >= 3 &&
                isEditable ? (
                  <>
                    <input
                      className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                      value={appointmentType.appointmentType}
                      onChange={(e) => handleChangeAppointmentType(e, index)}
                      placeholder="Appointment Type"
                    />
                    {isError && (
                      <p className="text-red-500 text-sm mt-1">
                        Appointment type cannot be empty
                      </p>
                    )}
                  </>
                ) : (
                  appointmentType.appointmentType
                )}
              </td>
              <td className="flex justify-center px-2 py-4 border-0">
                <>
                  <div className="flex items-center">
                    <input
                      className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                      type="number"
                      min={1}
                      max={120}
                      placeholder="Select duration"
                      value={appointmentType.duration}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        const newAppointmentTypes = [...appointmentTypes];
                        newAppointmentTypes[index].duration = value;
                        setAppointmentTypes(newAppointmentTypes);
                        setIsError(value <= 0);
                      }}
                    />
                    <span className="ml-1 mt-2">mins</span>
                  </div>
                </>
              </td>
              <td className="pl-6">
                <input
                  className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  type="number"
                  min={1}
                  placeholder="Select Amount"
                  value={appointmentType.charges}
                  onChange={(e) => {
                    const newAppointmentTypes = [...appointmentTypes];
                    newAppointmentTypes[index].charges = parseInt(
                      e.target.value
                    );
                    setAppointmentTypes(newAppointmentTypes);
                  }}
                />
              </td>
              <td className="px-3 py-4 text-center border-0">
                {index >= 3 && (
                  <Button
                    variant={ButtonVariant.SECONDARY}
                    onClick={() =>
                      deleteAppointmentType(
                        index,
                        appointmentType.appointmentType
                      )
                    }
                  >
                    Delete
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4">
        <Button
          variant={ButtonVariant.PRIMARY}
          onClick={addAppointmentType}
          isDisabled={
            appointmentTypes.length > 0 &&
            appointmentTypes[appointmentTypes.length - 1].appointmentType === ""
          }
        >
          Add Appointment Type
        </Button>
      </div>
      <div className="h-20 mt-10"></div>
      <div className="fixed items-start w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-4 md:-ml-6 lg:-ml-8 shadow bg-gray-100">
        <Button
          variant={ButtonVariant.PRIMARY}
          type="button"
          className="lg:ml-3"
          onClick={() => handleSave()}
        >
          Save
        </Button>
      </div>
    </>
  );
}
