import useAxios from '@routes/hooks/use-axios';
import { endpoints, replaceParams } from '@utils/axios';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ManagePatientContext } from '@pages/manage-patient/view/ManagePatient';
import Button from '@components/button/button';
import { ButtonVariant } from '@components/button/button';
import { toast } from 'react-toastify';
import { OutlinedButton } from '@components/button';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MuscleDetailsCard from './components/MuscleDetailsCard';
import MuscleDetails from './type/MuscleDetails';
import { Convert } from './type/MuscleStrength';
import { getUserPersistedOnLocalStorage } from '@authentication/context/jwt/utils';

type MuscleStrengthAssessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function MuscleStrengthAssessmentComponent(
  props: MuscleStrengthAssessmentProps,
) {
  const [musclesList, setMusclesList] = React.useState<MuscleDetails[]>([
    {
      id: uuidv4(),
      name: '',
      grading: '',
    },
  ]);

  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);
  const userData = getUserPersistedOnLocalStorage();

  React.useEffect(() => {
    const initializeAssessment = async () => {
      if (props.assessmentId !== '000000000000000000000000') {
        try {
          const paramsMap = new Map<string, string>([
            ['mid', props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getMuscleStrengthAssessment,
            paramsMap,
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data['data'].details;

          const converted = data.map((muscle: any) => ({
            ...muscle,
            id: uuidv4(),
          }));

          // const musclesListTotal = [...musclesList, ...converted];
          setMusclesList(converted);

          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          console.error('Error fetching Muscle Strength:', error);

          setLoading(false);
        }
      }
    };

    if (props.assessmentId !== '000000000000000000000000')
      initializeAssessment();
    else
      setMusclesList([
        {
          id: uuidv4(),
          name: '',
          grading: '',
        },
      ]);
  }, []);

  const onSubmit = async () => {
    if (props.assessmentId !== '000000000000000000000000') {
      try {
        //MuscleTightnessSchema.parse(data);
        const paramsMap = new Map<string, string>([
          ['id', props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateMuscleStrengthAssessment,
          paramsMap,
        );
        const response = await axios.put(filteredEndpoint, {
          id: props.assessmentId,
          masterAssessmentId: props.masterAssessmentId,
          clientId: props.patientId,
          entityId: userData?.entity_id ?? '',
          entityBranchId: userData?.branch_id ?? '',
          details: musclesList,
        });
        if (response.data.status === 200)
          toast.success(
            response.data.message === 'success'
              ? 'Muscle Strength updated successfully'
              : response.data.message,
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error('Error submitting form:', error);
      }
    } else {
      try {
        // MuscleTightnessSchema.parse(data);

        const paramsMap = new Map<string, string>([
          ['mid', props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createMuscleStrengthAssessment,
          paramsMap,
        );
        const response = await axios.post(filteredEndpoint, {
          masterAssessmentId: props.masterAssessmentId,
          clientId: props.patientId,
          entityId: userData?.entity_id ?? '',
          entityBranchId: userData?.branch_id ?? '',
          details: musclesList,
        });
        if (response.data.status === 200)
          toast.success(
            response.data.message === 'success'
              ? 'Muscle Strength created successfully'
              : response.data.message,
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error('Error submitting form:', error);
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Muscle Strength Assessment
        </h3>
      </div>
      <hr className="w-full" />

      {musclesList.map(muscle => (
        <MuscleDetailsCard
          muscle={muscle}
          muscleList={musclesList}
          setMuscleDetailsList={setMusclesList}
          isDisabled={isDisabled}
        />
      ))}
      <div className="mt-5">
        <Button
          variant={ButtonVariant.SECONDARY}
          type="button"
          className="ml-3 bg-success-500 hover:bg-success-500"
          isDisabled={isDisabled}
          onClick={() => {
            setMusclesList(all => [
              ...all,
              {
                id: uuidv4(),
                name: '',
                grading: '',
              },
            ]);
          }}
          children={
            <div className="flex flex-row items-center">
              <FontAwesomeIcon
                icon={faPlus}
                className="h-4 w-4 mr-2 "
                aria-hidden="true"
              />
              <span className="text-sm ">Add Muscle</span>
            </div>
          }
        />
      </div>
      <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
        <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
          <OutlinedButton
            type="button"
            onClick={() => {
              props.postSubmitCallback();
            }}
            className="mr-2"
            variant={ButtonVariant.PRIMARY}
          >
            Close
          </OutlinedButton>
          {isDisabled ? (
            <Button
              type="button"
              key="EditButton"
              onClick={() => {
                setIsDisabled(false);
              }}
              variant={ButtonVariant.PRIMARY}
            >
              Edit
            </Button>
          ) : props.assessmentId !== '000000000000000000000000' ? (
            <Button
              type="submit"
              key="UpdateButton"
              onClick={() => {
                onSubmit();
              }}
              variant={ButtonVariant.PRIMARY}
            >
              Update
            </Button>
          ) : (
            <Button
              type="submit"
              key="CreateButton"
              onClick={() => {
                onSubmit();
              }}
              variant={ButtonVariant.PRIMARY}
            >
              Create
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
