import { useEffect, useMemo, useState } from "react";

import React from "react";

import { OutlinedButton, Button } from "@components/button";
import { ButtonVariant } from "@components/button/button";

import { endpoints, replaceParams } from "@utils/axios";
import useAxios from "@routes/hooks/use-axios";
import { useNavigate } from "react-router-dom";
import { Combobox, Transition } from "@headlessui/react";

import { Pagination } from "@pages/appointments/appointment-index-table/Pagination";
import {
  filterRows,
  paginateRows,
  sortRows,
} from "@pages/appointments/appointment-index-table/Helpers";
import ExpensesComboBox from "@pages/expenses/ExpensesComboBox";
import Applabel from "@components/hook-form/applabel";
import SolidButton from "@components/button/button";
import * as z from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ValidationError } from "@components/hook-form";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { toast } from "react-toastify";
import moment from "moment";
import { MonthYearPicker } from "@utils/MonthYearPicker";
import RightOffcanvas from "@components/right-offcanvas";
import { PersonalInformationDetails } from "@pages/manage-patient/types/PatientPersonalDetails";
import {
  BillingDetails,
  ConvertBilling,
} from "@pages/referral-payout/types/BillingDetails";
import BillDetailsPage from "@pages/finances/view/BillDetailsPage";
import HospitalSearch from "@pages/add-patient/components/HospitalSearch";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Convert, Referring } from "./types/ReferredDocsAndHospitals";
import dayjs from "dayjs";
import NoData from "@components/no-data";

export default function ReferralPayout(props: {
  patientId?: string;
  patientInfo?: PersonalInformationDetails;
  getPatientBillingDetails?: () => Promise<void>;
}) {
  const [billing, setBilling] = React.useState<BillingDetails[] | undefined>(
    []
  );
  const [newBill, setNewBill] = React.useState(false);
  const [total, setTotal] = React.useState<number | null>(null);

  const [otherReferrals, setOtherReferrals] = React.useState<Referring[]>();
  const [otherReferral, setOtherReferral] = React.useState<string | null>("");

  const [referredHospitals, setReferredHospitals] =
    React.useState<Referring[]>();
  const [referredHospital, setReferredHospital] = React.useState<string | null>(
    ""
  );
  const [referredDoctors, setReferredDoctors] = React.useState<Referring[]>();
  const [referredDoctor, setReferredDoctor] = React.useState<string | null>("");

  //Start date & End Date. By Default it will be 6 months
  const [month, setMonth] = React.useState<number>(dayjs().month() + 1);
  const [year, setYear] = React.useState<number>(dayjs().year());
  const [loading, setLoading] = React.useState(true);
  const [selectedPerson, setSelectedPerson] = React.useState("");

  const axios = useAxios();
  const [rows, setRows] = useState<BillingDetails[]>([]);
  const [isRightOffcanvasBilling, setIsRightOffcanvasBilling] =
    React.useState(false);

  const [selectedInvoice, setSelectedInvoice] = React.useState("");
  const navigator = useNavigate();
  const [selectedOption, setSelectedOption] = useState("Hospital");

  const handleDownload = async () => {
    try {
      const response = await axios.get(
        endpoints.referredDocsAndHospitals.getBillsExcelSheet,
        {
          responseType: "blob",
          params: {
            referralhospitalcode: referredHospital,
            referraldoctorcode: referredDoctor,
            otherreferralcode: otherReferral,
            reqMonth: month,
            reqYear: year,
          },
        }
      );
      const filename = "billing.xlsx";
      //console.log(response.data);
      if (response.data !== null) {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      } else {
        toast.warning("No Data Available");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  //console.log(selectedOption);
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "Hospital") {
      setReferredDoctor(null);
      setOtherReferral(null);
    } else if (event.target.value === "Doctor") {
      setReferredHospital(null);
      setOtherReferral(null);
    } else if (event.target.value === "Other") {
      setReferredDoctor(null);
      setReferredHospital(null);
    }
  };

  function classNames(...classes: (string | boolean)[]) {
    return classes.filter(Boolean).join(" ");
  }

  const columns = [
    { accessor: "invoiceNumber", label: "Invoice Number" },
    { accessor: "invoiceDate", label: "Date" },
    { accessor: "clientName", label: "Patient Name" },

    { accessor: "totalAmount", label: "Amount" },
    { accessor: "status", label: "Status" },
    { accessor: "actions", label: "Actions" },
  ];

  const [activePage, setActivePage] = useState<number>(1);
  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [sort, setSort] = useState<{ order: string; orderBy: string }>({
    order: "",
    orderBy: "",
  });
  // Function to determine the border color based on the selected status
  const getTextColor = (selectedStatus: string) => {
    if (selectedStatus === "partlyPaid") {
      return "text-warning-500"; // Red border for 'pending' status
    } else if (selectedStatus === "paid") {
      return "text-success-500"; // Green border for 'paid' status
    } else {
      return "text-red-500"; // Default gray border
    }
  };
  const rowsPerPage: number = 10;
  const filteredRows = useMemo(
    () => filterRows(rows, filters),
    [rows, filters]
  );
  const sortedRows = useMemo(
    () => sortRows(filteredRows, sort),
    [filteredRows, sort]
  );

  const calculatedRows = useMemo(
    () => paginateRows(sortedRows, activePage, rowsPerPage),
    [sortedRows, activePage, rowsPerPage]
  );

  const count: number = filteredRows.length;
  const totalPages: number = Math.ceil(count / rowsPerPage);
  const userData = getUserPersistedOnLocalStorage();

  //console.log(referredHospital);

  const getBillingForHospital = async (
    selectedMonth: number,
    selectedYear: number
  ) => {
    try {
      const response = await axios.get(
        endpoints.referredDocsAndHospitals.getBillsForReferrals,
        {
          params: {
            referralhospitalcode: referredHospital,
            referraldoctorcode: referredDoctor,
            otherreferralcode: otherReferral,
            reqMonth: selectedMonth,
            reqYear: selectedYear,
          },
        }
      );
      const data = response.data["data"];
      //console.log(data);

      if (data !== null) {
        //console.log(data);
        let total: number = 0;
        const convertedList = data.map((expense: any) => {
          total += expense["totalAmount"];
          return ConvertBilling.toBilling(JSON.stringify(expense));
        });
        setTotal(total);
        setBilling(convertedList);
        setRows(convertedList);
      } else {
        setBilling([]);
        setRows([]);
        setTotal(null);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching expenses details:", error);

      setLoading(false);
    }
  };

  const getReferredDocsAndHospitals = async () => {
    try {
      const response = await axios.get(
        endpoints.referredDocsAndHospitals.getReferredDocsAndHospitals
      );
      const data = response.data["data"];
      if (data !== null) {
        const converted = Convert.toReferredDocsAndHospitals(
          JSON.stringify(data)
        );
        //console.log(converted);
        if (converted.ReferringHospitals)
          setReferredHospitals(converted.ReferringHospitals);
        if (converted.ReferringUsers)
          setReferredDoctors(converted.ReferringUsers);
        if (converted.OtherReferrals)
          setOtherReferrals(converted.OtherReferrals);
      } else {
        setOtherReferrals([]);
        setReferredHospitals([]);
        setReferredDoctors([]);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching expenses details:", error);

      setLoading(false);
    }
  };

  useEffect(() => {
    getReferredDocsAndHospitals();
  }, []);

  useEffect(() => {
    getBillingForHospital(month, year);
  }, [referredDoctor, referredHospital, otherReferral]);

  function createNewBill(): void {
    setNewBill(true);
  }

  function handleSearch(value: string) {
    if (value === "") {
      setFilters({});
      setSort({ order: "", orderBy: "" });
      if (billing) {
        setRows(billing);
      } // Reset rows to the original billing array
    } else {
      if (billing) {
        const filteredRows = billing.filter((row: any) => {
          return (
            row.invoiceNumber.toLowerCase().includes(value.toLowerCase()) ||
            row.clientName?.toLowerCase().includes(value.toLowerCase()) ||
            row.totalAmount
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            row.status.toLowerCase().includes(value.toLowerCase()) ||
            moment(row.dueDate)
              .format("DD-MM-YYYY")
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          );
        });
        setFilters({}); // Reset filters
        setSort({ order: "", orderBy: "" }); // Reset sorting
        setRows(filteredRows);
      }
    }
  }
  const handleBillingDateChange = (
    selectedMonth: number,
    selectedYear: number,
    startDate: Date,
    endDate: Date
  ) => {
    setMonth(selectedMonth);
    setYear(selectedYear);
    getBillingForHospital(selectedMonth, selectedYear);
  };

  //console.log("patient id" + props.patientId);
  //console.log("patient info" + props.patientInfo);
  //console.log("referred hospitals", referredHospitals);

  return (
    <>
      {/* For Widescreen layout */}
      <div className="flex flex-col space-y-2 md:flex-row md:items-end md:justify-between mb-4 w-full">
        <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
          <div className="flex flex-col">
            <Applabel label="Filter Billing By" />
            <div className="flex flex-col md:flex-row">
              {["Hospital", "Doctor", "Other"].map((option) => (
                <label key={option} className="text-lg mx-2">
                  <input
                    className={`h-4 w-4 border-gray-300 text-primary-600 focus:ring-indigo-600 mr-2`}
                    type="radio"
                    name="paymentMode"
                    value={option}
                    checked={selectedOption === option}
                    onChange={handleOptionChange}
                  />
                  {option}
                </label>
              ))}
            </div>
          </div>
          <div className="flex flex-col md:flex-row  mt-0 md:space-x-4">
            {selectedOption === "Hospital" ? (
              <div className="flex flex-col grow">
                <Applabel label="Referring Hospital" />

                <Combobox
                  as="div"
                  value={referredHospital}
                  onChange={setReferredHospital}
                >
                  <div className="relative">
                    <Combobox.Input
                      className="w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                      onChange={(event) => {}}
                      // displayValue={(person: string) => inputValue}
                      autoComplete="off"
                    />

                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>

                    {referredHospitals && referredHospitals.length > 0 && (
                      <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {referredHospitals.map((hospital: Referring) => (
                          <Combobox.Option
                            key={hospital.referralCode}
                            value={hospital.referralCode}
                            onClick={(event) => {}}
                            className={({ active }) =>
                              classNames(
                                "relative cursor-default select-none py-2 pl-3 pr-9",
                                active
                                  ? "bg-secondary-300 text-white"
                                  : "text-gray-900"
                              )
                            }
                          >
                            {({ active, selected }) => (
                              <>
                                <div className="flex flex-col">
                                  <span
                                    className={classNames(
                                      "truncate",
                                      selected && "font-semibold"
                                    )}
                                  >
                                    {hospital.referralName}
                                  </span>
                                </div>

                                {selected && (
                                  <span
                                    className={classNames(
                                      "absolute inset-y-0 right-0 flex items-center pr-4",
                                      active ? "text-white" : "text-indigo-600"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    )}
                  </div>
                </Combobox>
              </div>
            ) : selectedOption === "Doctor" ? (
              <div className="flex flex-col grow">
                <Applabel label="Referring Doctor" />
                <Combobox
                  as="div"
                  value={referredDoctor}
                  onChange={setReferredDoctor}
                >
                  <div className="relative">
                    <Combobox.Input
                      className="w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                      onChange={(event) => {}}
                      // displayValue={(person: string) => inputValue}
                      autoComplete="off"
                    />

                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>

                    {referredDoctors && referredDoctors.length > 0 && (
                      <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {referredDoctors.map((doctor: Referring) => (
                          <Combobox.Option
                            // key={doctor.id}
                            value={doctor.referralCode}
                            onClick={(event) => {}}
                            className={({ active }) =>
                              classNames(
                                "relative cursor-default select-none py-2 pl-3 pr-9",
                                active
                                  ? "bg-secondary-300 text-white"
                                  : "text-gray-900"
                              )
                            }
                          >
                            {({ active, selected }) => (
                              <>
                                <div className="flex flex-col">
                                  <span
                                    className={classNames(
                                      "truncate",
                                      selected && "font-semibold"
                                    )}
                                  >
                                    {doctor.referralName}
                                  </span>
                                </div>

                                {selected && (
                                  <span
                                    className={classNames(
                                      "absolute inset-y-0 right-0 flex items-center pr-4",
                                      active ? "text-white" : "text-indigo-600"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    )}
                  </div>
                </Combobox>
              </div>
            ) : (
              <div className="flex flex-col grow">
                <Applabel label="Other Referral" />
                <Combobox
                  as="div"
                  value={otherReferral}
                  onChange={setOtherReferral}
                >
                  <div className="relative">
                    <Combobox.Input
                      className="w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                      onChange={(event) => {}}
                      // displayValue={(person: string) => inputValue}
                      autoComplete="off"
                    />

                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>

                    {otherReferrals && otherReferrals.length > 0 && (
                      <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {otherReferrals.map((other: Referring) => (
                          <Combobox.Option
                            // key={other.id}
                            value={other.referralCode}
                            onClick={(event) => {}}
                            className={({ active }) =>
                              classNames(
                                "relative cursor-default select-none py-2 pl-3 pr-9",
                                active
                                  ? "bg-secondary-300 text-white"
                                  : "text-gray-900"
                              )
                            }
                          >
                            {({ active, selected }) => (
                              <>
                                <div className="flex flex-col">
                                  <span
                                    className={classNames(
                                      "truncate",
                                      selected && "font-semibold"
                                    )}
                                  >
                                    {other.referralName}
                                  </span>
                                </div>

                                {selected && (
                                  <span
                                    className={classNames(
                                      "absolute inset-y-0 right-0 flex items-center pr-4",
                                      active ? "text-white" : "text-indigo-600"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    )}
                  </div>
                </Combobox>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col-reverse space-y-2 md:flex-row md:justify-between md:items-end ">
          {total ? (
            <div
              className="flex mt-4
             md:mt-0 md:self-center"
            >
              <span className="text-xl font-bold leading-none text-gray-900 w-full">
                Total : {total}
              </span>
            </div>
          ) : (
            <></>
          )}
          <div className="flex flex-row space-x-2">
            {billing && billing.length > 0 && (
              <div className="flex w-1/2 sm:w-fit md:ml-2 justify-end">
                {/* Adjusted label width */}
                <label
                  onClick={handleDownload}
                  className="w-full h-min text-center px-2 py-2  text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500 cursor-pointer"
                  style={{ height: "2.5rem" }}
                >
                  Download
                </label>
              </div>
            )}
            <div className="">
              <MonthYearPicker onChange={handleBillingDateChange} />
            </div>
          </div>
        </div>
      </div>

      <div className=" flex flex-row ">
        <div className="flex flex-col w-full  ">
          <div className="flex flex-col w-full mt-4">
            {/* Billing table start */}
            <table className="w-full  shadow outline outline-gray-200 outline-1 p-2 mb-4">
              <thead className="bg-gray-50 rounded-t-lg">
                <tr>
                  {columns.map((column) => {
                    return (
                      <th
                        key={column.accessor}
                        className="px-4 py-3 border-b border-gray-300"
                      >
                        <div className="flex items-center space-x-1">
                          <span>{column.label}</span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="bg-white">
                {calculatedRows.map((row, index) => {
                  return (
                    <tr
                      key={row.id}
                      className={(() => {
                        // if not last row then add border
                        if (index !== calculatedRows.length - 1) {
                          return "border-b border-gray-200";
                        }

                        return "";
                      })()}
                    >
                      <td
                        key={"invoiceNumber"}
                        className={`px-4 py-3 ${"text-gray-500"}`}
                      >
                        {row["invoiceNumber"]}
                      </td>

                      <td
                        key={"invoiceDate"}
                        className={`px-4 py-3 ${"text-gray-500"}`}
                      >
                        {moment(row["invoiceDate"])
                          .format("DD-MM-YYYY")
                          .toString()}
                      </td>
                      <td
                        key={"clientName"}
                        className={`px-4 py-3 ${"text-gray-500"}`}
                      >
                        {row["clientName"]}
                      </td>

                      <td
                        key={"totalAmount"}
                        className={`px-4 py-3 ${"text-gray-500"}`}
                      >
                        {row["totalAmount"]}
                      </td>
                      <td
                        key={"status"}
                        className={`px-4 py-3 ${"text-gray-500"}`}
                      >
                        <span
                          className={`font-bold ${getTextColor(row["status"])}`}
                        >
                          {(() => {
                            switch (row["status"]) {
                              case "partlyPaid":
                                return "Partly Paid";
                              case "paid":
                                return "Paid";
                              case "pending":
                                return "Pending";
                            }
                          })()}
                        </span>
                      </td>

                      <td className={`px-4 py-3 ${"text-gray-500"}`}>
                        <div className="flex flex-row">
                          <div className="flex-shrink mr-2">
                            <OutlinedButton
                              variant={ButtonVariant.SECONDARY}
                              type="button"
                              onClick={() => {
                                setIsRightOffcanvasBilling(true);
                                setSelectedInvoice(row["invoiceNumber"]);
                              }}
                              children={
                                row["status"] === "paid" ? "View" : "Open"
                              }
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* Billing table end  */}
          </div>
          {count > 0 ? (
            <Pagination
              activePage={activePage}
              count={count}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              setActivePage={setActivePage}
            />
          ) : (
            <NoData message="No Bills Found" />
          )}
        </div>
        <hr className="my-4" />
      </div>
      <RightOffcanvas
        isOpen={isRightOffcanvasBilling}
        onClose={() => {
          setIsRightOffcanvasBilling(false);
          getBillingForHospital(dayjs().month() + 1, dayjs().year());
        }}
        sizeClasses="w-full md:w-[100vh]"
      >
        {isRightOffcanvasBilling && billing && (
          <BillDetailsPage
            onClose={() => {
              setIsRightOffcanvasBilling(false);
              getBillingForHospital(dayjs().month() + 1, dayjs().year());
            }}
            bill={
              billing.find((item) => item.invoiceNumber === selectedInvoice) ||
              ConvertBilling.toBilling("{}")
            }
            getPatientBillingDetails={props.getPatientBillingDetails}
          />
        )}
      </RightOffcanvas>
      <RightOffcanvas
        isOpen={newBill}
        onClose={() => {
          setNewBill(false);
        }}
        sizeClasses="w-full md:w-[100vh]"
      >
        {newBill && (
          <BillDetailsPage
            onClose={() => setNewBill(false)}
            bill={{
              invoiceNumber: "",
              invoiceDate: new Date(),
              dueDate: new Date(),
              entityId: userData?.entity_id,
              entityBranchId: userData?.branch_id,
              clientName:
                props.patientInfo?.firstName +
                " " +
                props.patientInfo?.lastName,
              clientId: props.patientId,
              items: [],
              totalAmount: 0,
              status: "pending",
              billForAppointments: false,
              totalPaid: 0,
              tax: 0,
              outStandingAmount: 0,
              paymentDetails: [],
            }}
          />
        )}
      </RightOffcanvas>
    </>
  );
}
