// To parse this data:
//
//   import { Convert, AvailableTimeSlots } from "./file";
//
//   const availableTimeSlots = Convert.toAvailableTimeSlots(json);

export interface AvailableTimeSlots {
  date: Date;
  totalSlots: number;
  totalFreeSlots: number;
  freeslots: Freeslot[];
}

export interface Freeslot {
  startTimeMinutes: number;
  endTimeMinutes: number;
  startTime: string;
  endTime: string;
  appointmentsCount: number;
  bookedAppointments: BookedAppointment[];
}

export interface BookedAppointment {
  id: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  entityUserId: string;
  clientName: string;
  email: string;
  clientPhone: number;
  entityUserName: string;
  appointmentType: string;
  date: string;
  apptdate: Date;
  startTime: string;
  endTime: string;
  startTimeMinutes: number;
  endTimeMinutes: number;
  notes: string;
  appointmentStatus: string;
  statusChangeComment: string;
  billAmount: number;
  paymentStatus: string;
  paymentType: string;
  paymentMode: string;
  meetingLink: string;
  modeOfConsultation: string;
  createdAt: Date;
  updatedAt: Date;
  meetLink: string;
  audit: Audit;
}

export interface Audit {
  createdBy: string;
  lastUpdatedBy: null;
  createdAt: Date;
  lastUpdatedAt: Date;
}

// Converts JSON strings to/from your types
export class AvailableTimeSlotsConvert {
  public static toAvailableTimeSlots(json: string): AvailableTimeSlots {
    return JSON.parse(json);
  }

  public static availableTimeSlotsToJson(value: AvailableTimeSlots): string {
    return JSON.stringify(value);
  }
}
