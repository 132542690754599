import React, { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const expenses = [
  { id: 1, name: "Salaries" },
  { id: 2, name: "Payouts" },
  { id: 3, name: "Rent" },
  { id: 4, name: "Utility Bills" },
  { id: 5, name: "Food" },
  { id: 6, name: "Travel" },
  { id: 7, name: "Supplies" },
  { id: 8, name: "Equipment" },
  { id: 9, name: "Office" },
  { id: 10, name: "Maintenance" },
  { id: 11, name: "Insurance" },
  { id: 12, name: "Marketing" },
  { id: 13, name: "Training" },
  { id: 14, name: "Other" },
];
type Props = {
  isDisabled: boolean;
  register?: any; //UseFormRegister<FieldValues>;
  setValue?: any; //UseFormSetValue<FieldValues>;
  watch?: any; //UseFormWatch<FieldValues>;
  name?: string;
  clearErrors?: any;
};

const ExpensesComboBox = ({
  isDisabled,
  register,
  setValue,
  watch,
  name,
  clearErrors,
}: Props) => {
  const [selectedExpense, setSelectedExpense] = useState(expenses[0]);

  const [query, setQuery] = useState("");
  useEffect(() => {
    setValue(name, expenses[0].name);
  }, []);

  const filterExpenses =
    query === ""
      ? expenses
      : expenses.filter((expense) =>
          expense.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <>
      <div className="flex">
        <div className="w-full">
          <Combobox
            value={selectedExpense}
            onChange={(value) => {
              setSelectedExpense(value);
              setValue(name, value.name);
              clearErrors(name);
            }}
          >
            <div className="relative">
              <div className="relative w-full cursor-default overflow-hidden rounded-lg text-left shadow-sm border-1  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm  border border-gray-300 ">
                <Combobox.Input
                  className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 border"
                  displayValue={(expense: Expenses) => expense.name}
                  onChange={(event) => setQuery(event.target.value)}
                  {...register(name)}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
              >
                <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-50">
                  {filterExpenses.length === 0 && query !== "" ? (
                    <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                      Nothing found.
                    </div>
                  ) : (
                    filterExpenses.map((expense) => (
                      <Combobox.Option
                        key={expense.id}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active
                              ? "bg-primary-200 text-white"
                              : "text-gray-900"
                          }`
                        }
                        value={expense}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {expense.name}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? "text-prime-500" : "text-teal-600"
                                }`}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
      </div>
    </>
  );
};

export default ExpensesComboBox;
