// To parse this data:
//
//   import { Convert, ADLGraphData } from "./file";
//
//   const aDLGraphData = Convert.toADLGraphData(json);

export type ADLGraphData = {
  data?: Datum[];
};

export type Datum = {
  date: Date;
  score: number;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toADLGraphData(json: string): ADLGraphData {
    return JSON.parse(json);
  }

  public static aDLGraphDataToJson(value: ADLGraphData): string {
    return JSON.stringify(value);
  }
}
