import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { ConvertPosture, Posture } from "../type/Posture";

type PostureProps = {
  postureAssessment: Posture | null;
};

const PostureAssessment = (props: PostureProps) => {
  return (
    <>
      {props.postureAssessment === null ? (
        <div></div>
      ) : (
        <div className="flex flex-col ">
          {(props.postureAssessment?.anteriorPosteriorFacialAsymmetry ||
            props.postureAssessment?.anteriorPosteriorCervicalSpine ||
            props.postureAssessment?.anteriorPosteriorShoulderAsymmetry ||
            props.postureAssessment?.anteriorPosteriorScapulae ||
            props.postureAssessment?.anteriorPosteriorThoracicSpine ||
            props.postureAssessment?.anteriorPosteriorLumbarSpine ||
            props.postureAssessment?.anteriorPosteriorAbdomen ||
            props.postureAssessment?.anteriorPosteriorPelvis ||
            props.postureAssessment?.anteriorPosteriorHipJoint ||
            props.postureAssessment?.anteriorPosteriorKneeJoint ||
            props.postureAssessment?.anteriorPosteriorAnkleJoint ||
            props.postureAssessment?.anteriorPosteriorFeet ||
            props.postureAssessment?.anteriorPosteriorSpine ||
            props.postureAssessment?.lateralHead ||
            props.postureAssessment?.lateralCervicalSpine ||
            props.postureAssessment?.lateralThoracicSpine ||
            props.postureAssessment?.lateralLumbarSpine ||
            props.postureAssessment?.lateralPelvis ||
            props.postureAssessment?.lateralHipJoint ||
            props.postureAssessment?.lateralKneeJoint ||
            props.postureAssessment?.lateralAnkleJoint) && (
            <div>
              <h3 className="mb-4 font-bold text-tertiary-500">
                Posture Assessment
              </h3>
              <div>
                {(props.postureAssessment?.lateralHead ||
                  props.postureAssessment?.lateralCervicalSpine ||
                  props.postureAssessment?.lateralThoracicSpine ||
                  props.postureAssessment?.lateralLumbarSpine ||
                  props.postureAssessment?.lateralPelvis ||
                  props.postureAssessment?.lateralHipJoint ||
                  props.postureAssessment?.lateralKneeJoint ||
                  props.postureAssessment?.lateralAnkleJoint) && (
                  <div className="flex flex-col items-stretch">
                    <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white ">
                      Lateral/Side View
                    </div>

                    <table className="table-fixed text-left text-lg border-collapse border border-slate-400 w-full">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            className="border px-2 py-1 border-slate-300 "
                            style={{ width: "30%" }}
                          >
                            Name
                          </th>
                          <th
                            className="border px-2 py-1 border-slate-300"
                            style={{ width: "70%" }}
                          >
                            Observation
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.postureAssessment?.lateralHead && (
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              Head
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <p>{props.postureAssessment?.lateralHead}</p>
                            </td>
                          </tr>
                        )}

                        {props.postureAssessment?.lateralCervicalSpine && (
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              Cervical Spine
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <p>
                                {props.postureAssessment?.lateralCervicalSpine}
                              </p>
                            </td>
                          </tr>
                        )}

                        {/* Repeat the same pattern for other conditions */}
                      </tbody>
                    </table>
                  </div>
                )}

                {(props.postureAssessment?.anteriorPosteriorFacialAsymmetry ||
                  props.postureAssessment?.anteriorPosteriorCervicalSpine ||
                  props.postureAssessment?.anteriorPosteriorShoulderAsymmetry ||
                  props.postureAssessment?.anteriorPosteriorScapulae ||
                  props.postureAssessment?.anteriorPosteriorThoracicSpine ||
                  props.postureAssessment?.anteriorPosteriorLumbarSpine ||
                  props.postureAssessment?.anteriorPosteriorAbdomen ||
                  props.postureAssessment?.anteriorPosteriorPelvis ||
                  props.postureAssessment?.anteriorPosteriorHipJoint ||
                  props.postureAssessment?.anteriorPosteriorKneeJoint ||
                  props.postureAssessment?.anteriorPosteriorAnkleJoint ||
                  props.postureAssessment?.anteriorPosteriorFeet ||
                  props.postureAssessment?.anteriorPosteriorSpine) && (
                  <div className="flex flex-col items-stretch">
                    <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                      Anterior and Posterior/Front View
                    </div>

                    <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            className="border px-2 py-1 border-slate-300 "
                            style={{ width: "30%" }}
                          >
                            Name
                          </th>
                          <th
                            className="border px-2 py-1 border-slate-300"
                            style={{ width: "70%" }}
                          >
                            Observation
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.postureAssessment
                          ?.anteriorPosteriorFacialAsymmetry && (
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              Facial Asymmetry(Bridge of Nose,Center of Chin)
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <p>
                                {
                                  props.postureAssessment
                                    ?.anteriorPosteriorFacialAsymmetry
                                }
                              </p>
                            </td>
                          </tr>
                        )}
                        {props.postureAssessment
                          ?.anteriorPosteriorCervicalSpine && (
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              Cervical Spine ( Lateral Flexion/Rotation)
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <p>
                                {
                                  props.postureAssessment
                                    ?.anteriorPosteriorCervicalSpine
                                }
                              </p>
                            </td>
                          </tr>
                        )}
                        {props.postureAssessment
                          ?.anteriorPosteriorShoulderAsymmetry && (
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              Shoulder Symmetry (level/ elevation,depression)
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <p>
                                {
                                  props.postureAssessment
                                    ?.anteriorPosteriorShoulderAsymmetry
                                }
                              </p>
                            </td>
                          </tr>
                        )}
                        {props.postureAssessment?.anteriorPosteriorScapulae && (
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              Scapulae
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <p>
                                {
                                  props.postureAssessment
                                    ?.anteriorPosteriorScapulae
                                }
                              </p>
                            </td>
                          </tr>
                        )}
                        {props.postureAssessment
                          ?.anteriorPosteriorThoracicSpine && (
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              Thoracic Spine
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <p>
                                {
                                  props.postureAssessment
                                    ?.anteriorPosteriorThoracicSpine
                                }
                              </p>
                            </td>
                          </tr>
                        )}
                        {props.postureAssessment
                          ?.anteriorPosteriorLumbarSpine && (
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              Lumbar Spine
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <p>
                                {
                                  props.postureAssessment
                                    ?.anteriorPosteriorLumbarSpine
                                }
                              </p>
                            </td>
                          </tr>
                        )}
                        {props.postureAssessment?.anteriorPosteriorAbdomen && (
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              Abdomen
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <p>
                                {
                                  props.postureAssessment
                                    ?.anteriorPosteriorAbdomen
                                }
                              </p>
                            </td>
                          </tr>
                        )}
                        {props.postureAssessment?.anteriorPosteriorPelvis && (
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              Pelvis: ( ASIS / PSIS level)
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <p>
                                {
                                  props.postureAssessment
                                    ?.anteriorPosteriorPelvis
                                }
                              </p>
                            </td>
                          </tr>
                        )}
                        {props.postureAssessment?.anteriorPosteriorHipJoint && (
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              Hip Joint: (level / abducted,adducted)
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <p>
                                {
                                  props.postureAssessment
                                    ?.anteriorPosteriorHipJoint
                                }
                              </p>
                            </td>
                          </tr>
                        )}
                        {props.postureAssessment
                          ?.anteriorPosteriorKneeJoint && (
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              Knee Joint
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <p>
                                {
                                  props.postureAssessment
                                    ?.anteriorPosteriorKneeJoint
                                }
                              </p>
                            </td>
                          </tr>
                        )}
                        {props.postureAssessment
                          ?.anteriorPosteriorAnkleJoint && (
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              Ankle Joint
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <p>
                                {
                                  props.postureAssessment
                                    ?.anteriorPosteriorAnkleJoint
                                }
                              </p>
                            </td>
                          </tr>
                        )}
                        {props.postureAssessment?.anteriorPosteriorFeet && (
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              Feet
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <p>
                                {props.postureAssessment?.anteriorPosteriorFeet}
                              </p>
                            </td>
                          </tr>
                        )}
                        {props.postureAssessment?.anteriorPosteriorSpine && (
                          <tr>
                            <td className="border px-2 py-1 border-slate-300 ">
                              Spine(Total Curve)
                            </td>
                            <td className="border px-2 py-1 text-center border-slate-300">
                              <p>
                                {
                                  props.postureAssessment
                                    ?.anteriorPosteriorSpine
                                }
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <hr className=" my-12 border-tertiary-500"></hr>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PostureAssessment;
