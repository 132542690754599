// To parse this data:
//
//   import { Convert, SpecialTest } from "./file";
//
//   const specialTest = Convert.toSpecialTest(json);

export interface SpecialTest {
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  assessmentDetails: AssessmentDetail[];
}

export interface AssessmentDetail {
  name: string;
  observation: string;
}

// Converts JSON strings to/from your types
export class ConvertSpecialTest {
  public static toSpecialTest(json: string): SpecialTest {
    return JSON.parse(json);
  }

  public static specialTestToJson(value: SpecialTest): string {
    return JSON.stringify(value);
  }
}
