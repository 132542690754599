// To parse this data:
//
//   import { Convert, Gait } from "./file";
//
//   const gait = Convert.toGait(json);

export type Gait = {
  id?: string;
  masterAssessmentID?: string;
  clientId?: string;
  entityId?: string;
  entityBranchId?: string;
  independent?: string;
  dependent?: string;
  walkingAid?: string;
  weightBearingStatus?: string;
  alteredTrunk?: string;
  circumduction?: string;
  hipHiking?: string;
  alteredHipRoatation?: string;
  alteredFootContact?: string;
  alteredBaseOfWalking?: string;
  initialContact?: string;
  alteredAnkleAndFootPlacement?: string;
  midStance?: string;
  terminalStance?: string;
  alteredKneeRange?: string;
  alteredHipRange?: string;
  heelOff?: string;
  toeOff?: string;
  preSwing?: string;
  initialSwing?: string;
  midSwing?: string;
  terminalSwing?: string;
  alteredTrunkPosition?: string;
  alteredLumbarLordosis?: string;
  initiationOfGait?: string;
  stepLength?: string;
  stepSymmetry?: string;
  strideLength?: string;
  strideDuration?: string;
  cadence?: string;
};

// Converts JSON strings to/from your types
export class ConvertGait {
  public static toGait(json: string): Gait {
    return JSON.parse(json);
  }

  public static gaitToJson(value: Gait): string {
    return JSON.stringify(value);
  }
}
