// To parse this data:
//
//   import { Convert, OutReferrals } from "./file";
//
//   const outReferrals = Convert.toOutReferrals(json);

export interface OutReferrals {
  OutReferrals: string[];
}

// Converts JSON strings to/from your types
export class ConvertOutReferrals {
  public static toOutReferrals(json: string): OutReferrals {
    return JSON.parse(json);
  }

  public static outReferralsToJson(value: OutReferrals): string {
    return JSON.stringify(value);
  }
}
