import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

interface MonthYearPickerProps {
  onChange?: (
    selectedMonth: number,
    selectedYear: number,
    startDate: Date,
    endDate: Date
  ) => void;
  ferchedStartDate?: Date | null;
}

export const MonthYearPicker: React.FC<MonthYearPickerProps> = ({
  onChange,
  ferchedStartDate,
}) => {
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(dayjs());
  // Get the first and last dates of the selected month and year
  const startDate = selectedDate?.startOf("month").toDate() || new Date();
  const lastDate = selectedDate?.endOf("month").toDate() || new Date();

  React.useEffect(() => {
    if (ferchedStartDate === null) {
      setSelectedDate(null);
    } else {
      setSelectedDate(dayjs(ferchedStartDate || undefined));
    }
  }, [ferchedStartDate]);

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    setSelectedDate(date);
    if (onChange && date) {
      const selectedDateMoment = dayjs(date);
      const firstDateOfMonth = date.startOf("month").toDate();
      const lastDateOfMonth = date.endOf("month").toDate();
      onChange(
        selectedDateMoment.month() + 1,
        selectedDateMoment.year(),
        firstDateOfMonth,
        lastDateOfMonth
      );
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={["month", "year"]}
        value={selectedDate}
        slotProps={{ textField: { size: "small" } }}
        onChange={handleDateChange}
        className="w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
      />
    </LocalizationProvider>
  );
};
