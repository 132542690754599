// To parse this data:
//
//   import { Convert, Entity } from "./file";
//
//   const entity = Convert.toEntity(json);

export type Entity = {
  id?: string;
  name?: string;
  ownerSalutation?: string;
  ownerFirstName?: string;
  ownerMiddleName?: string;
  ownerLastName?: string;
  email?: string;
  phone?: number;
  timezone?: string;
  logoUrl?: string;
  phoneCode?: number;
  address?: Address;
};

export type Address = {
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toEntity(json: string): Entity {
    return JSON.parse(json);
  }

  public static entityToJson(value: Entity): string {
    return JSON.stringify(value);
  }
}
