import useAxios from '@routes/hooks/use-axios';
import { endpoints, replaceParams } from '@utils/axios';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { paths } from '@routes/paths';
import { ManagePatientContext } from '@pages/manage-patient/view/ManagePatient';
import moment from 'moment';
import { getUserPersistedOnLocalStorage } from '@authentication/context/jwt/utils';

import { Grid } from 'gridjs-react';
import Button from '@components/button/button';
import { ButtonVariant } from '@components/button/button';

import InputField from '@components/hook-form/input-field';
import { Convert, GaitAssessment } from './types/GaitAssessment';
import { generateForm } from './InitialGaitAssessment';
import { toast } from 'react-toastify';
import { OutlinedButton } from '@components/button';

export const FrontalQualitySchema = z.object({
  name: z.string(),
  type: z.string(),
  isNormal: z.boolean(),
  isGood: z.boolean(),
  isPoor: z.boolean(),
  comments: z.string().optional(),
});
export type FrontalQuality = z.infer<typeof FrontalQualitySchema>;

export const GaitAssessmentSchema = z.object({
  masterAssessmentId: z.string(),
  frontalPlaneObservation: z.string(),
  saggitalPlaneObservation: z.string(),
  frontalQuality: z.array(FrontalQualitySchema),
});
export type GaitAssessmentType = z.infer<typeof GaitAssessmentSchema>;

type GaitAssessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function GaitAssessmentComponent(props: GaitAssessmentProps) {
  const [gaitAssessment, setGaitAssessment] =
    React.useState<GaitAssessment | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [error, setError] = React.useState('');

  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);

  const initialize = () => {
    // set default values for the form
    return generateForm(props.masterAssessmentId);
  };

  // below is the api call to get the list of patients in a react hook
  React.useEffect(() => {
    const initializeVitalAssessment = async () => {
      if (props.assessmentId !== '000000000000000000000000') {
        try {
          const paramsMap = new Map<string, string>([
            ['mid', props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getGaitAssessment,
            paramsMap,
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data['data'];
          const convertedGaitAssessment = Convert.toGaitAssessment(
            JSON.stringify(data),
          );
          setGaitAssessment(convertedGaitAssessment);

          methods.reset({ ...convertedGaitAssessment });
          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          console.error('Error fetching gait assessment:', error);
          setError(error.message);
          setLoading(false);
        }
      }
    };
    initializeVitalAssessment();
  }, []);

  const methods = useForm<GaitAssessmentType>({
    resolver: zodResolver(GaitAssessmentSchema),
    defaultValues: GaitAssessmentSchema.parse(initialize()),
  });

  const control = methods.control;

  const { fields: frontalQuality } = useFieldArray({
    control,
    name: 'frontalQuality',
  });

  const navigateToMasterAssessment = (masterAssessmentId: string) => {
    const paramsMap = new Map<string, string>([
      ['patientId', patientId.patientId],
      ['masterAssessmentId', masterAssessmentId],
    ]);
    navigate(
      `${replaceParams(paths.patient.masterAssessment.route, paramsMap)}`,
    );
  };

  const onSubmit: SubmitHandler<GaitAssessmentType> = async data => {
    methods.trigger();
    if (props.assessmentId !== '000000000000000000000000') {
      try {
        GaitAssessmentSchema.parse(data);
        const paramsMap = new Map<string, string>([
          ['id', props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateGaitAssessment,
          paramsMap,
        );
        const response = await axios.put(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === 'success'
              ? 'Gait Assessment updated successfully'
              : response.data.message,
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error('Error submitting form:', error);
        setError(error.response.data['data'].toString());
      }
    } else {
      try {
        GaitAssessmentSchema.parse(data);
        const paramsMap = new Map<string, string>([
          ['mid', props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createGaitAssessment,
          paramsMap,
        );
        const response = await axios.post(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === 'success'
              ? 'Gait Assessment created successfully'
              : response.data.message,
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error('Error submitting form:', error);
        setError(error.response.data['data'].toString());
      }
    }
  };

  const handleCheckboxChange = (
    name: string,
    index: number,
    checked: boolean,
  ) => {
    if (!checked) return; // if unchecked then do nothing

    methods.setValue(`frontalQuality.${index}.isGood`, false);
    methods.setValue(`frontalQuality.${index}.isPoor`, false);
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Gait Assessment
        </h3>
      </div>

      <hr className="w-full" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <table className="table-auto  text-left text-sm border-collapse border border-slate-400 w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="border md:px-6 px-2 py-4 border-slate-300">
                  Frontal Plane Observation
                </th>
                <th className="border md:px-6 px-2 py-4 border-slate-300">
                  Normal{' '}
                </th>
                <th className="border md:px-6 px-2 py-4 border-slate-300">
                  Good{' '}
                </th>
                <th className="border md:px-6 px-2 py-4 border-slate-300">
                  Poor{' '}
                </th>
              </tr>
            </thead>
            <tbody>
              {frontalQuality.map((value, index) => {
                return (
                  <>
                    <tr>
                      <td className="border px-2 py-4 border-slate-300">
                        {value.name}
                      </td>

                      <td className="border px-2 py-4 text-center border-slate-300">
                        <InputField
                          name={`frontalQuality[${index}].isNormal`}
                          key={`frontalQuality[${index}].isNormal`}
                          type="checkbox"
                          disabled={isDisabled}
                          onChange={e => {
                            if (e.target.checked) {
                              methods.setValue(
                                `frontalQuality.${index}.isGood`,
                                false,
                              );
                              methods.setValue(
                                `frontalQuality.${index}.isPoor`,
                                false,
                              );
                            }
                          }}
                        />
                      </td>
                      <td className="border px-2 py-4 text-center border-slate-300">
                        <InputField
                          name={`frontalQuality[${index}].isGood`}
                          type="checkbox"
                          disabled={isDisabled}
                          key={`frontalQuality[${index}].isGood`}
                          onChange={e => {
                            if (e.target.checked) {
                              methods.setValue(
                                `frontalQuality.${index}.isNormal`,
                                false,
                              );
                              methods.setValue(
                                `frontalQuality.${index}.isPoor`,
                                false,
                              );
                            }
                          }}
                        />
                      </td>
                      <td className="border px-6 py-4 text-center border-slate-300">
                        <InputField
                          name={`frontalQuality[${index}].isPoor`}
                          type="checkbox"
                          disabled={isDisabled}
                          key={`frontalQuality[${index}].isPoor`}
                          onChange={e => {
                            if (e.target.checked) {
                              methods.setValue(
                                `frontalQuality.${index}.isGood`,
                                false,
                              );
                              methods.setValue(
                                `frontalQuality.${index}.isNormal`,
                                false,
                              );
                            }
                          }}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>

          <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
            Frontal Plane Observations
          </label>

          <textarea
            id="frontalPlaneObservation"
            {...methods.register('frontalPlaneObservation')}
            rows={4}
            disabled={isDisabled}
            className="lock rounded-lg border-0 w-full  py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
          ></textarea>

          <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
            Saggital Plane Observations
          </label>

          <textarea
            id="saggitalPlaneObservation"
            {...methods.register('saggitalPlaneObservation')}
            rows={4}
            disabled={isDisabled}
            className="lock rounded-lg border-0 w-full  py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 "
          ></textarea>

          {error && <p className="text-red-500">{error}</p>}

          <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
            <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
              <OutlinedButton
                type="button"
                onClick={() => {
                  props.postSubmitCallback();
                }}
                className="mr-2"
                variant={ButtonVariant.PRIMARY}
              >
                Close
              </OutlinedButton>
              {isDisabled ? (
                <Button
                  type="button"
                  key="EditButton"
                  onClick={() => {
                    setIsDisabled(false);
                  }}
                  variant={ButtonVariant.PRIMARY}
                >
                  Edit
                </Button>
              ) : props.assessmentId !== '000000000000000000000000' ? (
                <Button
                  type="submit"
                  key="UpdateButton"
                  onClick={() => {}}
                  variant={ButtonVariant.PRIMARY}
                >
                  Update
                </Button>
              ) : (
                <Button
                  type="submit"
                  key="CreateButton"
                  onClick={() => {}}
                  variant={ButtonVariant.PRIMARY}
                >
                  Create
                </Button>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
