import React from 'react'

export default function ValidationError({ message }: { message: string }) {
  return (
    <>
    <div className="my-2 px-2 py-1 bg-error-100 rounded outline outline-1 outline-error-600">
        <span>{message}</span>
    </div>
    </>
  )
}
