import { v4 as uuidv4 } from 'uuid';
import {
  AssessmentParameterList,
  TypeOfParameter,
} from './types/DropDownTypes';

export const assessmentOptions: AssessmentParameterList[] = [
  {
    id: uuidv4(),
    value: 'Range of Motion',
    label: 'Range of Motion',

    parameters: [
      {
        id: uuidv4(),
        value: 'Hip Flexion',
        label: 'Hip Flexion',
      },
      {
        id: uuidv4(),
        value: 'Hip Extention',
        label: 'Hip Extention',
      },
      {
        id: uuidv4(),
        value: 'Leg Extention',
        label: 'Leg Extention',
      },
    ],
  },
  {
    id: uuidv4(),
    value: 'Pain',
    label: 'Pain',

    parameters: [
      {
        id: uuidv4(),
        value: 'Leg',
        label: 'Leg',
      },
      {
        id: uuidv4(),
        value: 'Back',
        label: 'Back',
      },
      {
        id: uuidv4(),
        label: 'Hamstring',
        value: 'Hamstring',
      },
    ],
  },
  {
    id: uuidv4(),
    value: 'Reflex',
    label: 'Reflex',

    parameters: [
      {
        id: uuidv4(),
        label: 'Quads',
        value: 'Quads',
      },
      {
        id: uuidv4(),
        label: 'Femor',
        value: 'Femor',
      },
      {
        id: uuidv4(),
        label: 'Toes',
        value: 'Toes',
      },
    ],
  },
];

export const typeOfParameter: TypeOfParameter[] = [
  { id: 'text', value: 'Text', label: 'Text' },
  { id: 'number', value: 'Number', label: 'Number' },
  { id: 'yes-no', value: 'Yes/No', label: 'Yes/No' },
  { id: 'lrbn', value: 'Left/Right/Both/None', label: 'Left/Right/Both/None' },
  {
    id: 'normal-good-poor',
    value: 'Normal/Good/Poor',
    label: 'Normal/Good/Poor',
  },
];
