import { OutlinedButton } from "@components/button";
import Button, { ButtonVariant } from "@components/button/button";
import { Switch } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { InputField, ValidationError } from "@components/hook-form";
import TextAreaField from "@components/hook-form/textarea-field";
import { endpoints } from "@utils/axios";
import axios from "axios";
import { Appointments } from "../appointments/types/Appointments";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import Modal from "@components/modal";
import { CancelTokenSource } from "axios";
import moment from "moment";
import Applabel from "@components/hook-form/applabel";
import { PersonalInformationDetails } from "@pages/manage-patient-new/types/PatientPersonalDetails";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { RadioGroup } from "@headlessui/react";

//original
export const PaymentDetailsSchema = z.object({
  amount: z.string().min(1, "Please add amount"),
  outstandingAmount: z.string().optional(),
  paymentMode: z.string().min(1, "Please add payment type").optional(),
  paymentStatus: z.string().optional(),
  notes: z.string().optional(),
  startDate: z.string().optional(),
  isOutStandingPay: z.boolean().default(true),
  noOfSessions: z
    .string()
    .min(1, "Please add session")
    .max(2, "Please add sessions less than 100.")
    .optional(),
});
export type PaymentDetailsSchemaType = z.infer<typeof PaymentDetailsSchema>;

const paymentTypes = [
  /** DO NOT CHANGE SEQUENCE OR ID */
  /*   { id: 1, title: 'Regular Payments', description: 'Consultation, Single session or Other' },
   */ {
    id: 1,
    title: "Advance payments",
    description: "Advance payments for sessions package",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function PaymentDetailsDialog(props: {
  onClose: () => void;
  onConfirm: () => void;
  appointmentDetails?: Appointments | null;
  patientPersonalInformation?: PersonalInformationDetails | null;
  patientId?: string;
  outStandingAmount?: number | null;
}) {
  let [isOpen, setIsOpen] = useState(true);

  const [isAdvancedPayment, setIsAdvancedPayment] = useState<boolean>(false);

  const [formData, setFormData] = useState({});
  const [postInProgress, setPostInProgress] = React.useState<boolean>(false);
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  const cancelToken = React.useRef<CancelTokenSource | null>(null);
  const methods = useForm({
    resolver: zodResolver(PaymentDetailsSchema),
  });
  function closeModal() {
    setIsOpen(false);
    props.onClose();
  }

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value != "") {
      setSelectedDate(new Date(e.target.value));
    }
  };

  const onSubmit: SubmitHandler<PaymentDetailsSchemaType> = async (data) => {
    if (postInProgress) return;
    methods.trigger();

    if (cancelToken.current) {
      cancelToken.current.cancel("Request already in progress!");
    }

    cancelToken.current = axios.CancelToken.source();

    try {
      setPostInProgress(true);
      const combinedData = { ...formData, ...data };

      PaymentDetailsSchema.parse(combinedData);
      const userData = getUserPersistedOnLocalStorage();

      await axios.post(
        `${endpoints.payment.add}`,
        props.appointmentDetails !== null &&
          props.appointmentDetails !== undefined
          ? {
              //This section is called when  the payment page is accessed from the appointment booking page or Patient profile page appointments table
              clientId: props.appointmentDetails.clientId,
              entityId: props.appointmentDetails.entityId,
              entityBranchId: props.appointmentDetails.entityBranchId,
              entityUserId: props.appointmentDetails.entityUserId,
              clientName: props.appointmentDetails.clientName,
              entityUserName: props.appointmentDetails.entityUserName,
              amount: Number(data.amount),
              outstandingAmount: Number(data.outstandingAmount),
              appointmentId: props.appointmentDetails.id,
              paymentMode: data.paymentMode,
              paymentStatus: "Paid",
              notes: data.notes,
              createdDate: props.appointmentDetails.date,
              createdBy: userData?.user_id,
              isAdvancePay: isAdvancedPayment,
              isOutStandingPay: data.isOutStandingPay,
              noOfSessions: Number(data.noOfSessions),
              startDate: selectedDate,
            }
          : {
              //This section is called  when the payment page is accessess from Patients  Profile Page
              clientId: props.patientId,
              entityId: userData?.entity_id ?? "",
              entityBranchId: userData?.branch_id,
              clientName: `${props.patientPersonalInformation?.firstName} ${props.patientPersonalInformation?.lastName}`,
              amount: Number(data.amount),
              outstandingAmount: Number(data.outstandingAmount),
              paymentMode: data.paymentMode,
              paymentStatus: "Paid",
              notes: data.notes,
              createdDate: moment(new Date()).format("DD-MM-YYYY"),
              createdBy: userData?.user_id,
              isAdvancePay: isAdvancedPayment,
              isOutStandingPay: data.isOutStandingPay,
              noOfSessions: Number(data.noOfSessions),
              startDate: selectedDate,
            },
        {
          cancelToken: cancelToken.current.token,
        }
      );
      setPostInProgress(false);
      closeModal();
      props.onConfirm();
    } catch (error) {
      console.error("Error submitting form:", error);
      setPostInProgress(false);
    }
  };
  const errors = methods.formState.errors;
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    paymentTypes[0]
  );
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (selectedPaymentType && selectedPaymentType.id === 1) {
      setIsAdvancedPayment(true);
    } else {
      setIsAdvancedPayment(false);
    }
  }, [selectedPaymentType]);
  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="flex flex-row w-full justify-between mx-4 my-4">
          <h3 className="text-md leading-6 font-bold text-gray-900">
            Payment Details
          </h3>
        </div>
        <hr className="w-full" />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col w-full h-full px-4 space-y-2">
              <RadioGroup
                value={selectedPaymentType}
                onChange={setSelectedPaymentType}
                className="mt-2 mb-4"
              >
                <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
                  Select a Payment Type
                </RadioGroup.Label>

                <div className="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  {paymentTypes.map((paymentTypeList) => (
                    <RadioGroup.Option
                      key={paymentTypeList.id}
                      value={paymentTypeList}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "border-indigo-600 ring-2 ring-indigo-600"
                            : "border-gray-300",
                          "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {paymentTypeList.title}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className="mt-2 flex items-start text-xs text-gray-500"
                              >
                                {paymentTypeList.description}
                              </RadioGroup.Description>
                            </span>
                          </span>
                          <CheckCircleIcon
                            className={classNames(
                              !checked ? "invisible" : "",
                              "h-5 w-5 text-primary-600 group-hover:text-primary-700"
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              active ? "border" : "border-2",
                              checked
                                ? "border-indigo-600"
                                : "border-transparent",
                              "pointer-events-none absolute -inset-px rounded-lg"
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
              <div
                className="inset-0 flex items-center mt-2 mb-2"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>

              {selectedPaymentType && selectedPaymentType.id === 1 && (
                <div className="flex flex-col sm:flex-row ">
                  <div className="flex flex-col w-2/3 sm:w-full mr-2">
                    <Applabel label="Number of sessions paid for" mandatory />
                    <input
                      {...methods.register("noOfSessions")}
                      placeholder="Add session credits"
                      onChange={(e) => {
                        if (e.target.value === "") {
                          methods.setError("noOfSessions", {
                            message: "Please add sessions",
                          });
                        } else if (Number(e.target.value) < 0) {
                          methods.setError("noOfSessions", {
                            message: "Sessions should be positive",
                          });
                        } else {
                          methods.clearErrors("noOfSessions");
                          methods.setValue("noOfSessions", e.target.value);
                        }
                      }}
                      type="number"
                      className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                    />
                    {errors.noOfSessions && (
                      <ValidationError
                        message={errors.noOfSessions?.message?.toString() ?? ""}
                      />
                    )}
                  </div>
                  <div className="flex flex-col w-2/3 sm:w-full mr-2">
                    <Applabel label="Sessions start date" mandatory />
                    <input
                      {...methods.register("startDate")}
                      className="flex lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                      type="date"
                      value={moment(selectedDate).format("YYYY-MM-DD")}
                      onChange={handleDateChange}
                    />
                    {errors.startDate && (
                      <ValidationError
                        message={errors.startDate?.message?.toString() ?? ""}
                      />
                    )}
                  </div>
                </div>
              )}

              <div className="flex flex-row ">
                <div className="flex flex-col w-2/3 mr-4">
                  <Applabel label="Amount Paid" mandatory />
                  <input
                    {...methods.register("amount")}
                    placeholder="Enter amount paid"
                    onChange={(e) => {
                      if (e.target.value === "") {
                        methods.setError("amount", {
                          message: "Please enter amount",
                        });
                      } else if (Number(e.target.value) < 0) {
                        methods.setError("amount", {
                          message: "amount should be positive",
                        });
                      } else {
                        methods.clearErrors("amount");
                        methods.setValue("amount", e.target.value);
                      }
                    }}
                    type="number"
                    className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                  />
                  {errors.amount && (
                    <ValidationError
                      message={errors.amount?.message?.toString() ?? ""}
                    />
                  )}
                </div>
                {/*   <div className="flex flex-col w-2/3 mr-2">
                  <Applabel label="Any Outstanding/Due Amount ?" />
                  <input {...methods.register('outstandingAmount')} placeholder="Enter outstanding amount"
                    onChange={e => {
                      if (e.target.value !== '' && Number(e.target.value) < 0) {
                        methods.setError('outstandingAmount', {
                          message: 'Amount should be positive',
                        });
                      } else {
                        methods.clearErrors('outstandingAmount');
                        methods.setValue('outstandingAmount', e.target.value);
                      }
                    }}
                    type="number"
                    className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                  />
                  {errors.outamount && (
                    <ValidationError
                      message={errors.outamount?.message?.toString() ?? ''}
                    />
                  )}

                </div> */}
              </div>
              {/*               <div className="flex flex-row ">
                <div className="flex flex-row w-2/3 mr-2 mt-1">
                  <div className=" border-red-100">
                    <input  {...methods.register('isOutStandingPay')} id="isOutStandingPay"
                      name="isOutStandingPay" defaultChecked type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                  </div>
                  <div className="ml-3 text-xs">Deduct from outstanding balance amount</div>
                </div>
                <div className="flex flex-col w-2/3 mr-2">
                  <div className="ml-3 text-xs leading-6">Total outstanding amount</div>
                  {props.outStandingAmount && props.outStandingAmount > 0 ? (
                    <div className="ml-3 text-base text-red-500">{props.outStandingAmount}</div>
                  ) : (
                    <div className="ml-3 text-sm">{props.outStandingAmount}</div>
                  )}
                </div>
              </div> */}
              <div className="flex flex-col ">
                <Applabel label="Payment Mode" />
                <div className="flex flex-wrap">
                  {["UPI", "Cash", "Card", "Online", "Other"].map((option) => (
                    <label key={option} className="flex-none text-sm mx-2">
                      <input
                        className={`h-4 w-4 border-gray-300 text-primary-600 focus:ring-indigo-600 mr-2`}
                        type="radio"
                        name="paymentMode"
                        value={option}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                {errors.paymentMode && (
                  <ValidationError
                    message={errors.paymentMode?.message?.toString() ?? ""}
                  />
                )}
              </div>

              <div className="mt-2">
                <TextAreaField
                  key="notes"
                  name="notes"
                  placeholder="Enter notes (max 3 lines)"
                  rows={3}
                  label="Notes"
                  value={methods.watch("notes")}
                />
                {errors.notes && (
                  <ValidationError
                    message={errors.notes?.message?.toString() ?? ""}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-row w-full mt-4 p-4 bg-gray-100 justify-end">
              <OutlinedButton
                variant={ButtonVariant.PRIMARY}
                onClick={closeModal}
                className="mr-2"
                type="button"
              >
                Cancel
              </OutlinedButton>
              <Button
                variant={ButtonVariant.PRIMARY}
                onClick={() => {
                  setFormData({
                    ...formData,
                    ...methods.getValues(),
                  });
                }}
                type="submit"
              >
                Mark Paid
              </Button>
              {/* <OutlinedButton
                variant={ButtonVariant.TERTIARY}
                onClick={props.onConfirm}
                className="ml-2"
                type="submit"
              >
                Pay Later
              </OutlinedButton> */}
            </div>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
}
