export interface Consent {
  id: string;
  entityId: string;
  content: string;
  title: string;
  version: string;
  audit: Audit;
}

export interface Audit {
  createdBy: null;
  lastUpdatedBy: null;
  createdAt: Date;
  lastUpdatedAt: Date;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toConsent(json: string): Consent {
    return JSON.parse(json);
  }

  public static consentToJson(value: Consent): string {
    return JSON.stringify(value);
  }
}
