import React from "react";
import { ScarDetails } from "../type/ScarDetails";

type ScarProps = {
  scar: ScarDetails | null;
};

const ScarAssessment = (props: ScarProps) => {
  return (
    <div>
      <div className="flex flex-col w-full h-full">
        <h3 className="mb-4 font-bold text-tertiary-500">Scar Assessment</h3>

        <div>
          <div className="flex flex-col items-stretch">
            <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
              Scar Assessments
            </div>
            <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    className="border px-2 py-1 border-slate-300 "
                    style={{ width: "75%" }}
                  >
                    Name
                  </th>
                  <th
                    className="border px-2 py-1 border-slate-300"
                    style={{ width: "25%" }}
                  >
                    Observation
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.scar &&
                  props.scar.assessmentDetails?.length > 0 &&
                  props.scar.assessmentDetails.map((e, index) =>
                    e.name !== "" ? (
                      <tr key={index}>
                        <td className="border px-2 py-1 text-center border-slate-300">
                          <p>{e.name}</p>
                        </td>
                        <td className="border px-2 py-1 text-center border-slate-300">
                          <p>{e.observation}</p>
                        </td>
                      </tr>
                    ) : (
                      <div></div>
                    )
                  )}
              </tbody>
            </table>
          </div>
        </div>
        <hr className=" my-12 border-tertiary-500"></hr>
      </div>
    </div>
  );
};

export default ScarAssessment;
