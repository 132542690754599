import React from "react";
import { SensoryDetails } from "../type/SensoryDetails";

type SensoryProps = {
  sensory: SensoryDetails | null;
};

const SensoryAssessment = (props: SensoryProps) => {
  return (
    <div>
      {props.sensory?.observations === "" ? (
        <div></div>
      ) : (
        <div className="flex flex-col w-full h-full">
          <h3 className="mb-4 font-bold text-tertiary-600">
            Sensory Assessment
          </h3>

          <div className="">
            <div className="mt-2">
              <div className="flex flex-row justify-between w-full bg-tertiary-100 px-2 py-1 font-medium">
                Observations
              </div>
              <p className="px-2 py-1 flex text-wrap text-lg">
                {props.sensory?.observations}
              </p>
            </div>
          </div>
          <hr className=" my-12 border-tertiary-500"></hr>
        </div>
      )}
    </div>
  );
};

export default SensoryAssessment;
