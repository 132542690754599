import { paths } from "@routes/paths";
import {
  HomeIcon,
  UsersIcon,
  FolderIcon,
  CalendarIcon,
  DocumentDuplicateIcon,
  ChartPieIcon,
  BookOpenIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";

const navigation = [
  {
    name: "Dashboard",
    href: paths.dashboard.route,
    icon: (
      <svg
        className="w-6 h-6 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 16"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 1v14h16m0-9-3-2-3 5-3-2-3 4"
        />
      </svg>
    ),
    current: true,
  },
  {
    name: "My Schedule",
    href: paths.mySchedule.route,
    icon: (
      <CalendarDaysIcon className="w-6 h-6 dark:text-white" />
      /*  <svg
        className="w-6 h-6 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 16"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 1v14h16m0-9-3-2-3 5-3-2-3 4"
        /> 
      </svg>*/
    ),
    current: false,
  },
  {
    name: "Assistant Dashboard",
    href: paths.assistantDashboard.route,
    icon: (
      <svg
        className="w-6 h-6  dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6.143 1H1.857A.857.857 0 0 0 1 1.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 6.143V1.857A.857.857 0 0 0 6.143 1Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 17 6.143V1.857A.857.857 0 0 0 16.143 1Zm-10 10H1.857a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 16.143v-4.286A.857.857 0 0 0 6.143 11Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286a.857.857 0 0 0 .857-.857v-4.286a.857.857 0 0 0-.857-.857Z"
        />
      </svg>
    ),
    current: true,
  },
  {
    name: "Entities",
    href: paths.entity.index.route,
    icon: (
      <svg
        className="w-6 h-6  dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 1h12M3 1v16M3 1H2m13 0v16m0-16h1m-1 16H3m12 0h2M3 17H1M6 4h1v1H6V4Zm5 0h1v1h-1V4ZM6 8h1v1H6V8Zm5 0h1v1h-1V8Zm-3 4h2a1 1 0 0 1 1 1v4H7v-4a1 1 0 0 1 1-1Z"
        />
      </svg>
    ),
    current: false,
  },
  {
    name: "Appointments",
    href: paths.appointment.index.route,
    icon: (
      <ClockIcon className="w-6 h-6 dark:text-white" />
    ),
    current: false,
  },
  {
    name: "Branches",
    href: paths.branch.index.route,
    icon: (
      <svg
        className="w-6 h-6  dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 14"
      >
        <path
          stroke="currentColor"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="2"
          d="M2 5h17m-9 8V6M2 1h16a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Z"
        />
      </svg>
    ),
    current: false,
  },
  {
    name: "Staff",
    href: paths.staff.index.route,
    icon: (
      <svg
        className="w-6 h-6  dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 16"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3.656 12.115a3 3 0 0 1 5.682-.015M13 5h3m-3 3h3m-3 3h3M2 1h16a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Zm6.5 4.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
        />
      </svg>
    ),
    current: false,
  },
  {
    name: "Patients",
    href: paths.patient.index.route,
    icon: (
      <svg
        className="w-6 h-6  dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 18"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M14 3a3 3 0 1 1-1.614 5.53M15 12a4 4 0 0 1 4 4v1h-3.348M10 4.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0ZM5 11h3a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"
        />
      </svg>
    ),
    current: false,
  },
  {
    name: "Gritup Library",
    href: paths.grituplibrary.index.route,
    icon: (
      <svg
        className="w-6 h-6"
        viewBox="115.00 200.00 180.0 100.0"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M 175 200 h 120 v 30 h -120 z" fill="currentColor" />
        <path d="M 145 235 h 120 v 30 h -120 z" fill="currentColor" />
        <path d="M 115 270 h 120 v 30 h -120 z" fill="currentColor" />
      </svg>
    ),
    current: false,
  },
  {
    name: "Exercises",
    href: paths.exercise.index.route,
    icon: (
      <svg
        className="w-6 h-6  dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 16 18"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 1.984v14.032a1 1 0 0 0 1.506.845l12.006-7.016a.974.974 0 0 0 0-1.69L2.506 1.139A1 1 0 0 0 1 1.984Z"
        />
      </svg>
    ),
    current: false,
  },
  {
    name: "Expenses",
    href: paths.expense.index.route,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        className="w-6 h-6  dark:text-white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
        />
      </svg>
    ),
    current: false,
  },
  {
    name: "Billings",
    href: paths.billing.index.route,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        className="w-6 h-6  dark:text-white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
        />
      </svg>
    ),
    current: false,
  },
/*   {
    name: "Credits",
    href: paths.credits.index.route,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="w-6 h-6 dark:text-white"
      >
        <rect x="1" y="4" width="22" height="16" rx="2" ry="2" />
        <line x1="1" y1="10" x2="23" y2="10" />
        <line x1="1" y1="14" x2="23" y2="14" />
        <line x1="9" y1="18" x2="15" y2="18" />
      </svg>
    ),
    current: false,
  }, */
];

export default navigation;
