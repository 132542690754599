// To parse this data:
//
//   import { Convert, ADLQuestions } from "./file";
//
//   const aDLQuestions = Convert.toADLQuestions(json);

export type ADLQuestions = {
  id?: string;
  masterAssessmentId?: string;
  entityId?: string;
  entityBranchId?: string;
  clientId?: string;
  categoryScoreDetails?: CategoryScoreDetail[];
};

export type CategoryScoreDetail = {
  category?: string;
  categoryCode?: string;
  scoreAnswered?: number;
  scoreAttempted?: number;

  questionScore?: QuestionScoreParameter[];
};

export type QuestionScoreParameter = {
  question?: string;
  questionId?: string;
  answer?: ObservationScoreParameter;
  maxScore?: number;
  observationScores?: ObservationScoreParameter[];
};

export type ObservationScoreParameter = {
  score?: number;
  text?: string;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toADLQuestions(json: string): ADLQuestions {
    return JSON.parse(json);
  }

  public static aDLQuestionsToJson(value: ADLQuestions): string {
    return JSON.stringify(value);
  }
}
