export type Oedema = {
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  pittingnonPitting?: string;
  measurement?: string;
  site?: string;
};

// Converts JSON strings to/from your types
export class ConvertOdema {
  public static toOedema(json: string): Oedema {
    return JSON.parse(json);
  }

  public static postureToJson(value: Oedema): string {
    return JSON.stringify(value);
  }
}
