import React, { useCallback, useEffect, useMemo, useState } from "react";
import { endpoints, replaceParams } from "@utils/axios";

import moment from "moment";
import useAxios from "@routes/hooks/use-axios";
import CountCard from "@components/count-card/count-card";
import Button, { ButtonVariant } from "@components/button/outline-button";

import WalkinAppointmentPage from "@pages/appointments/walkin-appointment/WalkinAppointmentPage";
import BookAppointmentPage from "@pages/appointments/book-appointment/BookAppointmentPage";
import { QuickPatientAddDialog } from "@pages/appointments/book-appointment/QuickPatientAddDialog";
import { Link, useNavigate } from "react-router-dom";
import { paths } from "@routes/paths";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { Tab } from "@headlessui/react";
import {
  Appointments,
  AppointmentConvert,
} from "@pages/dashboard/types/Appointments";
import {
  PatientStatistics,
  Convert,
} from "@pages/dashboard/types/PatientStats";
import {
  Conversion,
  AppointmentStatistics,
} from "@pages/dashboard/types/AppointmentStats";

import AppointmentDashboardCards from "@pages/dashboard/components/AppointmentDashboardCards";
import { EntityBranchConvert } from "@pages/dashboard/types/EntityBranch";
import { EntityBranch } from "@pages/appointments/types/EntityBranch";
import { AppointmentIndexCards } from "@pages/appointments/appointment-index-table/AppointmentIndexCards";
import { Appointment } from "@pages/appointments/types/Appointment";
import {
  filterRows,
  sortRows,
} from "@pages/appointments/appointment-index-table/Helpers";
import { OutlinedButton } from "@components/button";
import {
  CurrencyRupeeIcon,
  SquaresPlusIcon,
  UserPlusIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { MyAppointmentCard } from "@pages/appointments/appointment-index-table/MyAppointmentCard";

import PatientRecord from "@pages/manage-patient-new-v2/view/PatientRecord";

const MySchedule = () => {
  const [patientStat, setPatientStats] =
    React.useState<PatientStatistics | null>(null);
  const [AppointmentStat, setAppointmentStats] =
    React.useState<AppointmentStatistics | null>(null);
  const axios = useAxios();

  const [scheduledAppointmentList, setScheduledAppointmentList] =
    React.useState<Appointments[]>([]);
  const [waitingAppointmentList, setWaitingAppointmentList] = React.useState<
    Appointments[]
  >([]);
  const [ongoingAppointmentList, setOngoingAppointmentList] = React.useState<
    Appointments[]
  >([]);
  const [completedAppointmentList, setCompletedAppointmentList] =
    React.useState<Appointments[]>([]);
  const [quickPatientAddDialog, setQuickPatientAddDialog] =
    React.useState(false);
  const userData = getUserPersistedOnLocalStorage();
  const [branches, setBranches] = React.useState<EntityBranch[]>([]);
  const [selectedBranch, setSelectedBranch] = React.useState<string>("0");

  const [dateSwitcher, setDateSwitcher] = useState(
    moment(new Date()).format("DD-MM-YYYY")
  );

  const naviagtor = useNavigate();

  const navigateBilling = () => {
    naviagtor(paths.billing.index.route, {});
  };

  const getScheduledAppointmentList = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Scheduled",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setScheduledAppointmentList(toSet);
  };

  const getScheduledAppointmentListForEntityOwner = async () => {
    let paramsMap: Map<string, string>;
    paramsMap = new Map<string, string>([["id", selectedBranch]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Scheduled",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setScheduledAppointmentList(toSet);
  };

  const getWaitingAppointmentList = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Waiting",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setWaitingAppointmentList(toSet);
  };

  const getWaitingAppointmentListForEntityOwner = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", selectedBranch]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Waiting",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setWaitingAppointmentList(toSet);
  };

  const getOngoingAppointmentList = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Ongoing",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setOngoingAppointmentList(toSet);
  };

  const getOngoingAppointmentListForEntityOwner = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", selectedBranch]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Ongoing",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setOngoingAppointmentList(toSet);
  };

  const getCompletedAppointmentList = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Completed",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setCompletedAppointmentList(toSet);
  };

  const getCompletedAppointmentListForEntityOwner = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", selectedBranch]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Completed",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setCompletedAppointmentList(toSet);
  };

  const getPatientStatus = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.patientsCount,
      paramsMap
    );
    try {
      const response = await axios.get(filteredEndpoint, {
        params: {
          requestedDate: dateSwitcher,
        },
      });
      const data = response.data["data"];

      const converted = Convert.toPatientStatistics(JSON.stringify(data));
      const patientStats = converted;
      setPatientStats(patientStats);
    } catch (error) {
      console.error(error);
    }
  };

  const getPatientStatusForEntityOwner = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", selectedBranch]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.patientsCount,
      paramsMap
    );
    try {
      const response = await axios.get(filteredEndpoint, {
        params: {
          requestedDate: dateSwitcher,
        },
      });
      const data = response.data["data"];

      const converted = Convert.toPatientStatistics(JSON.stringify(data));
      const patientStats = converted;
      setPatientStats(patientStats);
    } catch (error) {
      console.error(error);
    }
  };

  const getAppointmentStatus = async () => {
    try {
      let paramsMap: Map<string, string>;

      paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

      const filteredEndpoint = replaceParams(
        endpoints.assistantDashboard.appointmentsCount,
        paramsMap
      );

      const response = await axios.get(filteredEndpoint, {
        params: {
          requestedDate: dateSwitcher,
        },
      });
      const data = response.data["data"];

      const converted = Conversion.toAppointmentStatistics(
        JSON.stringify(data)
      );
      const appointmentStats = converted;
      setAppointmentStats(appointmentStats);
    } catch (error) {}
  };

  const getAppointmentStatusForEntityOwner = async () => {
    try {
      let paramsMap: Map<string, string>;

      paramsMap = new Map<string, string>([["id", selectedBranch]]);

      const filteredEndpoint = replaceParams(
        endpoints.assistantDashboard.appointmentsCount,
        paramsMap
      );

      const response = await axios.get(filteredEndpoint, {
        params: {
          requestedDate: dateSwitcher,
        },
      });
      const data = response.data["data"];

      const converted = Conversion.toAppointmentStatistics(
        JSON.stringify(data)
      );
      const appointmentStats = converted;
      setAppointmentStats(appointmentStats);
    } catch (error) {}
  };

  // below is the api call to get the appointmentstatus and patient status in a react hook
  useEffect(() => {
    if (userData?.user_role === "ENTITY_OWNER") {
      getAppointmentStatusForEntityOwner();
      getPatientStatusForEntityOwner();
      getScheduledAppointmentListForEntityOwner();
      getWaitingAppointmentListForEntityOwner();
      getOngoingAppointmentListForEntityOwner();
      getCompletedAppointmentListForEntityOwner();
    } else {
      getAppointmentStatus();
      getPatientStatus();
      getScheduledAppointmentList();
      getWaitingAppointmentList();
      getOngoingAppointmentList();
      getCompletedAppointmentList();
    }
  }, [dateSwitcher, selectedBranch]);

  const appointmentData = AppointmentStat;
  const [bookAppointmentDialog, setBookAppointmentDialog] = useState(false);
  const [walkinAppointmentDialog, setWalkinAppointmentDialog] = useState(false);

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const getBranches = useCallback(async () => {
    const paramsMap = new Map<string, string>([
      ["entityId", userData?.entity_id ?? ""],
    ]);
    const filteredEndpoint = replaceParams(
      endpoints.ownerDashboard.branches,
      paramsMap
    );
    const response = await axios.get(filteredEndpoint);
    const data = response.data["data"];
    const converted = [];

    for (const branch of data) {
      const temp = EntityBranchConvert.toEntityBranch(JSON.stringify(branch));
      converted.push(temp);
    }

    setBranches(converted);
  }, [axios]);

  useEffect(() => {
    getBranches();
  }, []);

  const handleSelectChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedBranchId = event.target.value;
    setSelectedBranch(selectedBranchId);
  };
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [rows, setRows] = useState([]);
  const [appointments, setAppointments] = React.useState<
    Appointment[] | undefined
  >([]);
  const columns = [
    { accessor: "startTime", label: "Time" },
    { accessor: "date", label: "Date" },
    { accessor: "clientName", label: "Patient Name" },
    { accessor: "clientPhone", label: "Phone" },
    { accessor: "entityUserName", label: "Staff Name" },
    { accessor: "appointmentType", label: "Appointment Type" },
  ];
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [appointmentStatus, setAppointmentStatus] = useState("");
  const [appointmentType, setAppointmentType] = useState("");

  const [activePage, setActivePage] = useState<number>(1);
  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [sort, setSort] = useState<{ order: string; orderBy: string }>({
    order: "",
    orderBy: "",
  });
  const rowsPerPage: number = 8;

  const filteredRows = useMemo(
    () => filterRows(rows, filters),
    [rows, filters]
  );
  const sortedRows = useMemo(
    () => sortRows(filteredRows, sort),
    [filteredRows, sort]
  );

  const getAllAppointments = useCallback(async () => {
    try {
      const response = await axios.get(endpoints.appointment.list, {
        params: {
          branchId: selectedBranch,
          requestedFromDate: dateSwitcher,
          requestedToDate: dateSwitcher,
          requestedAppointmentStatus: appointmentStatus,
          requestedAppointmentType: appointmentType,
          userId: userData?.user_id,
          sort: "asc",
        },
      });
      const data = response.data["data"];
      //console.log(data);
      if (data === null) {
        setAppointments([]);
        setLoading(false);
        setRows([]);
      } else {
        const convertedAppointmentList = data.map((appointment: any) => {
          let appointmentShadow = AppointmentConvert.toAppointments(
            JSON.stringify(appointment)
          );
          let duration =
            appointment.endTimeMinutes - appointment.startTimeMinutes;

          appointmentShadow.apptdate = new Date(
            moment(appointmentShadow.apptdate).toLocaleString()
          );

          appointmentShadow.startTime = moment(
            appointmentShadow.apptdate
          ).format("hh:mm A");
          appointmentShadow.endTime = moment(appointment.startTime, "hh:mm A")
            .add(duration, "minutes")
            .format("hh:mm A");
          appointmentShadow.startTimeMinutes =
            appointmentShadow.apptdate.getHours() * 60 +
            appointmentShadow.apptdate.getMinutes();
          appointmentShadow.endTimeMinutes =
            appointmentShadow.apptdate.getHours() * 60 +
            appointmentShadow.apptdate.getMinutes() +
            duration;
          return appointmentShadow;
        });
        setAppointments(convertedAppointmentList);
        setLoading(false);
        setRows(convertedAppointmentList);
      }
    } catch (error) {
      console.error("Error fetching appointments list:", error);
      setError(error.message);
      setLoading(false);
    }
  }, [
    axios,
    startDate,
    endDate,
    appointmentType,
    appointmentStatus,
    dateSwitcher,
  ]);

  const [appointmentDetails, setAppointmentDetails] =
    useState<Appointments | null>(null);

  useEffect(() => {
    getAllAppointments();
  }, [getAllAppointments]);

  const [patientSelected, setPatientSelected] = useState("");
  const [key, setKey] = useState("");
  useEffect(() => {
    // Increment the key to force re-render of the div
    setKey(patientSelected);
  }, [patientSelected]);

  const [selectedAppointment, setSelectedAppointment] = useState<Record<
    string,
    any
  > | null>();

  const handleClick = (row: Record<string, any>) => {
    setSelectedAppointment(row);
    //console.log(row);
  };

  return (
    <div className="flex flex-col divide-y-4 divide-solid divide-gray-50 ">
      {/*       <div className=flex flex-col lg:flex-row justify-between pb-2 w-full gap-2">
       */}
      <div
        className={`${
          selectedAppointment ? "hidden lg:flex" : "flex"
        } flex-col lg:flex-row justify-between pb-2 w-full gap-2`}
      >
        <div className="flex">
          <Tab.Group>
            <Tab.List className="flex space-x-1 rounded-xl lg:ml-2">
              <Tab
                key={"Today"}
                onClick={() => {
                  setDateSwitcher(moment(new Date()).format("DD-MM-YYYY"));
                  setPatientSelected("");
                }}
                className={({ selected }) =>
                  classNames(
                    "w-full px-4  text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500",
                    selected
                      ? "bg-white text-primary-700 shadow border-primary-500"
                      : "text-primary-300"
                  )
                }
              >
                Today
              </Tab>
              <Tab
                key={"Tomorrow"}
                onClick={() => {
                  const currentDate = moment(dateSwitcher, "DD-MM-YYYY");
                  const nextDate = currentDate.add(1, "days");
                  setDateSwitcher(nextDate.format("DD-MM-YYYY"));
                  setPatientSelected("");
                }}
                className={({ selected }) =>
                  classNames(
                    "w-full px-4 text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500",
                    selected
                      ? "bg-white text-primary-700 shadow border-primary-500"
                      : "text-primary-300"
                  )
                }
              >
                Tomorrow
              </Tab>
            </Tab.List>
          </Tab.Group>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
          <Button
            variant={ButtonVariant.GRAY}
            className="mx-1"
            onClick={() => setBookAppointmentDialog(true)}
          >
            <SquaresPlusIcon className="w-5 h-5 text-secondary-500" />
            <span className="pl-1 text-xs"> Book Appointment </span>
          </Button>
          <Button
            variant={ButtonVariant.GRAY}
            className="mx-1"
            onClick={() => setQuickPatientAddDialog(true)}
          >
            <UserPlusIcon className="w-5 h-5 text-secondary-500" />
            <span className="pl-1 text-xs"> Add Pateint</span>
          </Button>
          {userData?.user_role === "ENTITY_OWNER" && (
            <Button
              variant={ButtonVariant.GRAY}
              onClick={navigateBilling}
              className="mx-1"
            >
              <CurrencyRupeeIcon className="w-5 h-5 text-secondary-500" />
              <span className="pl-1 text-xs"> Billing </span>
            </Button>
          )}
          {userData?.user_role === "ENTITY_OWNER" && (
            <select
              className="w-full sm:w-44 text-xs h-full border border-1 rounded-md cursor-pointer"
              disabled={false}
              onChange={handleSelectChange}
            >
              <option key={"All"} value={"0"}>
                All Branches
              </option>
              {branches.map((branch) => (
                <option key={branch.id} value={branch.id}>
                  {branch.name}
                </option>
              ))}
            </select>
          )}
        </div>

        {/*         <div className="flex sm:mt-0">
          <Button
            variant={ButtonVariant.GRAY}
            className="mx-1"
            onClick={() => setBookAppointmentDialog(true)}
          >
            <SquaresPlusIcon className="w-5 h-5 text-primary-500" />
            <span className="pl-1 text-sm"> Book Appointment </span>
          </Button>
          <Button
            variant={ButtonVariant.GRAY}
            className="mx-1"
            onClick={() => setQuickPatientAddDialog(true)}
          >
            <UserPlusIcon className="w-5 h-5 text-primary-500" />
            <span className="pl-1 text-sm"> Add Pateint</span>
          </Button>
          {userData?.user_role === "ENTITY_OWNER" && (
            <Button
              variant={ButtonVariant.GRAY}
              onClick={navigateBilling}
              className="mx-1"
            >
              <CurrencyRupeeIcon className="w-5 h-5 text-secondary-500" />
              <span className="pl-1 text-sm"> Billing </span>
            </Button>
          )}
          {userData?.user_role === "ENTITY_OWNER" && (
            <select
              className="w-full sm:w-44  h-full border border-1 rounded-md cursor-pointer"
              disabled={false}
              onChange={handleSelectChange}
            >
              <option key={"All"} value={"0"}>
                All Branches
              </option>
              {branches.map((branch) => (
                <option key={branch.id} value={branch.id}>
                  {branch.name}
                </option>
              ))}
            </select>
          )}
        </div> */}
      </div>

      <div className="flex w-full divide-x-4 divide-solid divide-gray-50 h-full ">
        <div
          className={`${
            selectedAppointment ? "hidden lg:flex" : "lg:flex"
          } flex-col w-full lg:basis-1/4 pr-3 border-r-0 gap-2`}
        >
          <div className="">
            <ul className="grid grid-cols-1 gap-2 p-1 ">
              {sortedRows
                .filter(
                  (row) =>
                    selectedAppointment && selectedAppointment["id"] === row.id
                )
                .map((row, index) => (
                  <div className="my-5">
                    <div className="flex text-base font-semibold justify-between mb-2 text-gray-600">
                      <div>Selected</div>
                      <div>
                        <OutlinedButton
                          variant={ButtonVariant.GHOST}
                          className=""
                          onClick={() => setSelectedAppointment(null)}
                        >
                          <XCircleIcon className="h-3 w-3" />
                          <span className="pl-1 text-sm"> Close</span>
                        </OutlinedButton>
                      </div>
                    </div>

                    <MyAppointmentCard
                      appointmentRecord={row}
                      getAppointments={getAllAppointments}
                      patientSelected={function (clientId: string): any {
                        setPatientSelected(clientId);
                      }}
                      isSelected={true}
                    />
                  </div>
                  /*      <div
                    key={index}
                    onClick={() => handleClick(row)}
                    className="border-2 mb-4"
                  >
                    <div className="text-primary-800 text-xs text-center font-bold">
                      Selected
                    </div>
                    <MyAppointmentCard
                      appointmentRecord={row}
                      getAppointments={function (): Promise<void> {
                        throw new Error("Function not implemented.");
                      }}
                      patientSelected={function (clientId: string): any {
                        setPatientSelected(clientId);
                      }}
                      isSelected={true}
                    />
                  </div> */
                ))}

              <span className="text-sm font-semibold text-left text-gray-600 mb-2">
                Total Appointments : {appointments?.length ?? 0}
              </span>

              {sortedRows.map((row, index) => (
                <div
                  key={index}
                  onClick={() => handleClick(row)}
                  className="opacity-[0.9] hover:opacity-100"
                >
                  <MyAppointmentCard
                    appointmentRecord={row}
                    getAppointments={getAllAppointments}
                    patientSelected={function (clientId: string): any {
                      setPatientSelected(clientId);
                    }}
                    isSelected={false}
                  />
                </div>
              ))}
            </ul>
          </div>
        </div>
        {selectedAppointment && (
          <div className="flex lg:basis-3/4 w-full mt-2">
            <div className="flex flex-col w-full">
              <div className="flex w-full justify-between p-2">
                <div className="font-bold text-primary-900 hidden lg:flex">
                  {selectedAppointment["clientName"]}
                  <span className="font-semibold text-gray-900 pl-2">
                    ({moment(selectedAppointment["apptdate"]).format("hh:mm A")}
                    )
                  </span>
                </div>
                <div className="font-bold text-primary-900 flex lg:hidden">
                  <MyAppointmentCard
                    appointmentRecord={selectedAppointment}
                    getAppointments={getAllAppointments}
                    patientSelected={function (clientId: string): any {
                      setPatientSelected(clientId);
                    }}
                    isSelected={true}
                  />
                </div>
                <div className="font-semibold">
                  <OutlinedButton
                    variant={ButtonVariant.GHOST}
                    className=""
                    onClick={() => setSelectedAppointment(null)}
                  >
                    <XCircleIcon className="h-3 w-3" />
                    <span className="pl-1 text-sm hidden lg:flex"> Close</span>
                    <span className="pl-1 text-sm flex lg:hidden"> Back</span>
                  </OutlinedButton>
                </div>
              </div>
              <div key={key} className="w-full  p-2">
                {selectedAppointment && (
                  <PatientRecord
                    inputPatientId={patientSelected}
                    defaultTab="pe"
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {bookAppointmentDialog && (
        <BookAppointmentPage
          setBookAppointmentDialog={setBookAppointmentDialog}
          setQuickPatientAddDialog={setQuickPatientAddDialog}
          getAppointments={getAllAppointments}
        />
      )}

      {walkinAppointmentDialog && (
        <WalkinAppointmentPage
          setWalkinAppointmentDialog={setWalkinAppointmentDialog}
          setQuickPatientAddDialog={setQuickPatientAddDialog}
          getAppointments={
            userData?.user_role === "ENTITY_OWNER"
              ? getScheduledAppointmentListForEntityOwner
              : getScheduledAppointmentList
          }
        />
      )}

      {quickPatientAddDialog && (
        <QuickPatientAddDialog
          setQuickPatientAddDialog={setQuickPatientAddDialog}
        />
      )}
    </div>
  );
};

export default MySchedule;
