import { Button, OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import useAxios from "@routes/hooks/use-axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ManagePatientNewContext } from "./ManagePatientNew";
import { endpoints, replaceParams } from "@utils/axios";

import { useForm } from "react-hook-form";

import Applabel from "@components/hook-form/applabel";
import { Convert, Journal } from "../types/Journal";
import moment from "moment";

export default function PatientJournal(props: {
  handleCloseRightOffCanvas: () => void;
  patientId: string;
  isPopup?: boolean;
}) {
  const [patientJournal, setPatientJournal] = React.useState<Journal | null>(
    null
  );

  const [loading, setLoading] = React.useState(true);
  const [isEditable, setIsEditable] = React.useState(false);

  const navigate = useNavigate();

  const axios = useAxios();

  //const patientId = useContext(ManagePatientNewContext);

  React.useEffect(() => {
    setIsEditable(false);
    const getJournalList = async () => {
      try {
        const paramsMap = new Map<string, string>([["id", props.patientId]]);
        const filteredEndpoint = replaceParams(
          endpoints.journal.view,
          paramsMap
        );
        const response = await axios.get(filteredEndpoint);
        if (response.status === 200) {
          if (response.data && response.data["data"]) {
            const data = response.data["data"];

            const convertedJournal = Convert.toJournal(JSON.stringify(data));
            const timeZone = moment.tz.guess();

            if (convertedJournal && convertedJournal.journalNotes) {
              convertedJournal.journalNotes = convertedJournal.journalNotes.map(
                (note) => {
                  note.date = moment
                    .utc(note.date)
                    .tz(timeZone)
                    .format("DD MMM YYYY");
                  return note;
                }
              );
              setPatientJournal(convertedJournal);
            }
          }
        }

        setIsEditable(false);
        setLoading(false);
      } catch (error) {
        console.error("Error getting Journal list", error);
        setLoading(false);
      }
    };
    getJournalList();
  }, []);

  return (
    <div className="grid-cols-1 gap-x-8 gap-y-8 pt-2 md:grid-cols-3 bg-grey-200 ">
      <form>
        {props.isPopup && (
        <div className="sticky top-0 right-0 z-1 flex items-center justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
              Journal
            </h2>
          </div>
          <OutlinedButton
            type="button"
            onClick={props.handleCloseRightOffCanvas}
            className="mr-2"
            variant={ButtonVariant.PRIMARY}
          >
            Close
          </OutlinedButton>
        </div>
        )}

        <hr className="my-2" />
        <div className="space-y-6">
          {!loading && patientJournal && patientJournal != undefined ? (
            <>
              {patientJournal?.journalNotes?.map((journal, activityItemIdx) => (
                <li className="relative flex gap-x-4 " onClick={() => {}}>
                  <div className="-bottom-6 absolute left-0 top-0 flex w-6 justify-center">
                    <div className="w-px bg-gray-200" />
                  </div>

                  <>
                    <svg
                      className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <circle cx="12" cy="12" r="8" strokeWidth="2" />
                    </svg>
                    <div
                      className={`flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 hover:border-2  border-primary-20`}
                    >
                      <div className="flex justify-between gap-x-4">
                        <div className="py-0.5 text-sm leading-5 text-gray-500">
                          Note{" : "}
                          <span className="font-medium text-gray-900">
                            {journal.note}
                          </span>
                        </div>
                        {activityItemIdx == 0 && (
                          <div className="bg-success-400 text-success-700 rounded-full py-1 px-4 ml-2 text-xs">
                            current
                          </div>
                        )}
                      </div>
                      <p className="text-sm leading-6 text-gray-500">
                        Date{" : "}
                        <span className="font-medium text-gray-900">
                          {journal.date}
                        </span>
                      </p>
                    </div>
                  </>
                </li>
              ))}
            </>
          ) : (
            <div>
              <p>No Journal added yet. Add a Journal to view</p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

// Setting default props
PatientJournal.defaultProps = {
  isPopup: true,
};