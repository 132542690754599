import React from "react";
import { MuscleWasting } from "../type/MuscleWastingTypes";

type MuscleWastingProps = {
  muscleWasting: MuscleWasting | null;
};

const MuscleWastingAssessment = (props: MuscleWastingProps) => {
  return (
    <div>
      {props.muscleWasting?.observations === "" ? (
        <div></div>
      ) : (
        <div className="flex flex-col w-full h-full ">
          <h3 className="my-b font-bold text-tertiary-500">Muscle Wasting </h3>
          <div className="">
            <div>
              <div className="flex flex-row justify-between w-full mt-2 bg-tertiary-100 px-2 py-1 font-medium">
                Observations
              </div>
              <p className="px-2 py-1 text-wrap text-lg">
                {props.muscleWasting?.observations}
              </p>
            </div>
          </div>
          <hr className=" my-12 border-tertiary-500"></hr>
        </div>
      )}
    </div>
  );
};

export default MuscleWastingAssessment;
