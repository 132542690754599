// These are required for maintaining or data structure from which matric will be build : It is stored in selecedData

export type Parameters = {
  id: string;
  name: string;
  values: Value[];
  validation: string;
  units: string;
};

export type Value = {
  date: string;
  value: any;
};

export type Assessment = {
  customAssessmentId: string;
  assessmentName: string;
  parameters: Parameters[];
};

// Converts JSON strings to/from your types
export class Convert {
  public static toAssessment(json: string): Assessment {
    return JSON.parse(json);
  }

  public static assessmentToJson(value: Assessment): string {
    return JSON.stringify(value);
  }
}
