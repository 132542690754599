import React, { useEffect, useState } from "react";

import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { Combobox } from "@headlessui/react";
import { Convert, Patient } from "../types/PatientType";
import {
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { motion } from "framer-motion";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

export default function PatientSearch(props: {
  data: Patient[];
  setSelectedPatientId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedPatientName: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedPatientEmail: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedPatientPhone: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedPatientBranchId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  setSelectedPatientAddress: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  register: UseFormRegister<{
    patientId: string;
    appointmentType: string;
    physioId: string;
    slotToBook: string;
  }>;
  setValue: UseFormSetValue<{
    patientId: string;
    appointmentType: string;
    physioId: string;
    slotToBook: string;
  }>;
  clearErrors: UseFormClearErrors<{
    patientId: string;
    appointmentType: string;
    physioId: string;
    slotToBook: string;
  }>;
}) {
  const userData = getUserPersistedOnLocalStorage();
  const [query, setQuery] = useState("");

  const [selectedPerson, setSelectedPerson] = useState(null);

  const filteredPeople =
    query === ""
      ? props.data
      : props.data.filter(
          (patient: {
            firstName: string;
            middleName: string;
            lastName: string;
            phone: number;
          }) =>
            patient.firstName
              .replace(/\s/g, "")
              .trim()
              .toLowerCase()
              .includes(query.toLowerCase()) ||
            (patient.firstName + patient.lastName)
              .replace(/\s/g, "")
              .trim()
              .toLowerCase()
              .includes(query.replace(/\s/g, "").trim().toLowerCase()) ||
            patient.middleName
              .replace(/\s/g, "")
              .trim()
              .toLowerCase()
              .includes(query.toLowerCase()) ||
            patient.lastName
              .replace(/\s/g, "")
              .trim()
              .toLowerCase()
              .includes(query.replace(/\s/g, "").trim().toLowerCase()) ||
            patient.phone
              .toString()
              .replace(/\s/g, "")
              .trim()
              .toLowerCase()
              .includes(query.replace(/\s/g, "").trim().toLowerCase())
        );

  useEffect(() => {
    if (selectedPerson != null) {
      const p: Patient = Convert.toPatient(JSON.stringify(selectedPerson));

      const { address } = p;
      let selectedPatientAddress = "";

      if (address.address) selectedPatientAddress += `${address.address}, `;
      if (address.city) selectedPatientAddress += `${address.city}, `;
      if (address.state) selectedPatientAddress += `${address.state}, `;
      if (address.country) selectedPatientAddress += `${address.country}, `;
      if (address.postalCode) selectedPatientAddress += `${address.postalCode}`;

      selectedPatientAddress = selectedPatientAddress.trim().replace(/,$/, "");

      props.setSelectedPatientName(p.firstName + " " + p.lastName);
      props.setSelectedPatientEmail(p.email);
      props.setSelectedPatientPhone(p.phone);
      props.setSelectedPatientId(p.id);
      props.setSelectedPatientBranchId(p.entityBranchId);
      props.setSelectedPatientAddress(selectedPatientAddress);
      props.setValue("patientId", p.id);
      props.clearErrors("patientId");
    }
  }, [selectedPerson]);

  return (
    <motion.div
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -50 }}
    >
      <Combobox as="div" value={selectedPerson} onChange={setSelectedPerson}>
        {/* <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        Select Patient
      </Combobox.Label> */}

        <div className="relative">
          <Combobox.Input
            className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(person: Patient) =>
              `${person?.firstName ?? ""} ${person?.lastName ?? ""}`
            }
            autoComplete="off"
          />

          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {filteredPeople.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredPeople.map((person) => (
                <Combobox.Option
                  key={person.id}
                  value={person}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",

                      active ? "bg-secondary-300 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <div className="flex flex-col">
                        <span
                          className={classNames(
                            "truncate",
                            selected && "font-semibold"
                          )}
                        >
                          {person.firstName}&nbsp;{person.lastName}
                        </span>
                        <span
                          className={classNames(
                            "ml-2 truncate text-gray-500",

                            active ? "text-indigo-200" : "text-gray-500"
                          )}
                        >
                          +{person?.phoneCode}{" "}
                          {userData?.user_role === "ENTITY_OWNER" ||
                          userData?.user_role === "PLATFORM_ADMIN"
                            ? `${person?.phone}`
                            : `${
                                person?.phone.toString().length > 4
                                  ? `${person?.phone}`
                                  : `******${person?.phone}`
                              }`}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",

                            active ? "text-white" : "text-indigo-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </motion.div>
  );
}
