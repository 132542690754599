import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { AvaialableExercises, Converto } from '../../type/AvailableExercises';
import { AssignExercise } from '../../type/AssignExercise';
import { Button } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import axios from 'axios';
import { endpoints, replaceParams } from '@utils/axios';
import { toast } from 'react-toastify';
import { ActiveExercise } from '@pages/manage-patient/type/ActiveExerciseProgram';
import Applabel from '@components/hook-form/applabel';
import {
  Convert,
  ExerciseMedia,
} from '@pages/manage-patient/type/ExerciseMedia';
import { SelectionState } from 'draft-js';
import { Converted, ExternalLink } from '@pages/manage-patient/type/ExternalLink';
import ReactPlayer from 'react-player';

type ViewIndividualExerciseProps = {
  exercise: ActiveExercise | undefined;
  postSubmitCallback: () => void;
};

export default function ViewIndividualExercise(
  props: ViewIndividualExerciseProps,
) {
  const [selectedExerciseMedia, setSelectedExerciseMedia] = useState<
    ExerciseMedia[] | null
  >();
  const [selectedExternalLink, setselectedExternalLink] = useState<
    ExternalLink[] | null
  >();
  useEffect(() => {
    const getSingleAssignedExerciseMedia = async () => {
      try {
        if (!props.exercise) return;
        const paramsMapExerciseId = new Map<string, string>([
          ['exerciseId', props.exercise.id],
        ]);
        const response = await axios.get(
          replaceParams(
            endpoints.managePatient.getSingleExerciseMedia,
            paramsMapExerciseId,
          ),
        );
        const data = response.data['data'];
        const converted = Convert.toExerciseMedia(JSON.stringify(data));
        setSelectedExerciseMedia(converted);
      } catch (error) {
        console.error('Error fetching exercise media:', error);
      }
    };

    const getSingleAssignedExternalLink = async () => {
      try {
        if (!props.exercise) return;
        const paramsMapExerciseId = new Map<string, string>([
          ['id', props.exercise.id],
        ]);
        const response = await axios.get(
          replaceParams(
            endpoints.managePatient.getSingleExternalLink,
            paramsMapExerciseId,
          ),
        );
        const data = response.data['data'];
        const convertData = Converted.toExternalLink(JSON.stringify(data));
        setselectedExternalLink(convertData);
      } catch (error) {
        console.error('Error fetching exercise media:', error);
      }
    };
    getSingleAssignedExerciseMedia();
    getSingleAssignedExternalLink();

  }, []);

  return (
    <>
      <div className="space-y-5">
        <div className="flex flex-row  w-full h-full items-start p-4 outline outline-gray-200 outline-1 rounded-xl shadow bg-white space-x-2 ">
          <div className="flex flex-col w-full">
            <Applabel label="Title" />
            <input
              disabled={true}
              className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
              value={props.exercise?.title}
            />
            <div className="flex flex-col ">
              <Applabel label="Instruction" />
              <textarea
                disabled={true}
                rows={4}
                placeholder="Instructions"
                className={`lock w-full row-span-4 rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                value={props.exercise?.instructions}
              />
            </div>
            <div className="grid grid-cols-3  space-x-2 ">
              <div className="flex flex-col flex-grow">
                <Applabel label="Sets" />
                <input
                  disabled={true}
                  type="number"
                  className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                  value={props.exercise?.config?.sets}
                />
              </div>

              <div className="flex flex-col flex-grow">
                <Applabel label="Reps" />
                <input
                  disabled={true}
                  type="number"
                  className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                  value={props.exercise?.config?.repetitions}
                />
              </div>
              <div className="flex flex-col flex-grow">
                <Applabel label="Hold" />
                <div className="w-full relative">
                  <input
                    disabled={true}
                    placeholder="Hold"
                    value={props.exercise?.config?.hold}
                    type="number"
                    className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center">
                    <select
                      disabled={true}
                      placeholder="Hold Unit"
                      className={`h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm`}
                      value={props.exercise?.config?.holdUnit}
                    >
                      {['Sec', 'Min'].map(intervalUnit => {
                        return (
                          <option key={intervalUnit} value={intervalUnit}>
                            {intervalUnit}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex flex-col flex-grow ">
                <Applabel label="Interval" />
                <div className="w-full relative">
                  <input
                    disabled={true}
                    placeholder="Interval"
                    value={props.exercise?.config?.interval}
                    type="number"
                    className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center">
                    <select
                      disabled={true}
                      placeholder="Interval Unit"
                      className={`h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm`}
                      value={props.exercise?.config?.intervalUnit}
                    >
                      {['Sec', 'Min'].map(intervalUnit => {
                        return (
                          <option key={intervalUnit} value={intervalUnit}>
                            {intervalUnit}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="flex flex-col flex-grow basis-2/6">
                <Applabel label="Side" />
                <div className="w-full relative">
                  <select
                    disabled={true}
                    placeholder="Side"
                    className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                    value={props.exercise?.config?.side}
                  >
                    <option key="" value="">
                      Select
                    </option>
                    {['None', 'Left', 'Right', 'Both'].map(difficulty => {
                      return (
                        <option key={difficulty} value={difficulty}>
                          {difficulty}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="flex flex-col flex-grow">
                <Applabel label="Weights" />
                <div className="w-full relative">
                  <input
                    disabled={true}
                    placeholder="Weights"
                    value={props.exercise?.config?.weights}
                    type="number"
                    className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center">
                    <select
                      placeholder="Weight Unit"
                      className={`h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm`}
                      value={props.exercise?.config?.weightsUnit}
                    >
                      {['Kgs', 'Lbs'].map(weightUnit => {
                        return (
                          <option key={weightUnit} value={weightUnit}>
                            {weightUnit}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col  w-full h-full items-start p-4 outline outline-gray-200 outline-1 rounded-xl shadow bg-white space-x-2 ">
          <Applabel label="Media" />

          <div className="grid grid-cols-2 gap-x-2 gap-y-4   w-full h-full items-start p-4 outline outline-gray-200 outline-1 rounded-xl shadow bg-white space-x-2 ">

            {selectedExternalLink == null || selectedExternalLink == undefined || selectedExternalLink.length == 0 ?
              null
              :
              (
                <ReactPlayer
                  config={{
                    file: {
                      attributes: { controlsList: 'nodownload' },
                    },
                  }}
                  controls={true}
                  url={selectedExternalLink[0]?.url}
                  width="100%"
                  height="100%"
                  onContextMenu={(e: any) => e.preventDefault()}
                />

              )
            }
            <div>
              {selectedExerciseMedia == null
                ? 'No Media Provided '
                : selectedExerciseMedia?.map(
                  (e: {
                    fileCategory: string;
                    url: string | undefined;
                    name: string | undefined;
                  }) =>
                    e.fileCategory == 'EXERCISE_VIDEO' && (
                      <video src={e.url} className="h-36 mr-2" controls controlsList="nodownload"
                        onContextMenu={(e: React.MouseEvent<HTMLVideoElement, MouseEvent>) => e.preventDefault()}></video>

                    )
                )}
            </div>
          </div>
          {selectedExerciseMedia?.filter(item => item.fileCategory == 'EXERCISE_IMAGE') == null ||
            selectedExerciseMedia?.filter(item => item.fileCategory == 'EXERCISE_IMAGE') == undefined ||
            selectedExerciseMedia?.filter(item => item.fileCategory == 'EXERCISE_IMAGE').length == 0
            ?
            null
            :
            <div className={`grid grid-cols-2 gap-x-2 gap-y-4 lg:grid-cols-4 mt-4 w-full h-full items-start p-4 outline outline-gray-200 outline-1 rounded-xl shadow bg-white space-x-2  
            ${selectedExerciseMedia?.filter(item => item.fileCategory == 'EXERCISE_IMAGE').length >= 4
                ? 'overflow-x-auto'
                : 'overflow-hidden'
              }`}>

              {selectedExerciseMedia?.map(
                (e: {
                  fileCategory: string;
                  url: string | undefined;
                  name: string | undefined;
                }) =>
                  e.fileCategory == 'EXERCISE_IMAGE' &&
                  <img
                    src={e.url}
                    alt={e.name}
                    className="h-36 w-36 mr-2"
                  ></img>
              )
              }

            </div>

          }

        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
        <div className="flex flex-row w-full p-4 bg-gray-100 justify-start">
          <Button
            type="button"
            key="EditButton"
            onClick={() => {
              props.postSubmitCallback();
            }}
            variant={ButtonVariant.PRIMARY}
          >
            Close
          </Button>
        </div>
      </div>
    </>
  );
}
