import React from "react";
import { Gait } from "../type/Gait";

type GaitProps = {
  gait: Gait | null;
};

const GaitAssessment = (props: GaitProps) => {
  return (
    <>
      {props.gait === null ? (
        <div></div>
      ) : (
        <div className="flex flex-col w-full h-full ">
          <h3 className="mb-4 font-bold text-tertiary-500">Gait Assessment</h3>

          <div className="flex flex-col items-stretch">
            <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    className="border px-2 py-1 border-slate-300 "
                    style={{ width: "30%" }}
                  >
                    Name
                  </th>
                  <th
                    className="border px-2 py-1 border-slate-300"
                    style={{ width: "70%" }}
                  >
                    Observation
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Independent
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <p>{props.gait?.independent}</p>
                  </td>
                </tr>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Dependent
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <p>{props.gait?.dependent}</p>
                  </td>
                </tr>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Walking Aid
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <p>{props.gait?.walkingAid}</p>
                  </td>
                </tr>
                <tr>
                  <td className="border px-2 py-1 border-slate-300 ">
                    Weight Bearing Status
                  </td>
                  <td className="border px-2 py-1 text-center border-slate-300">
                    <p>{props.gait?.weightBearingStatus}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {(props.gait?.alteredTrunkPosition ||
            props.gait?.circumduction ||
            props.gait?.hipHiking ||
            props.gait?.alteredHipRoatation ||
            props.gait?.alteredFootContact ||
            props.gait?.alteredBaseOfWalking) && (
            <div className="flex flex-col items-stretch">
              <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
                Gait Analysis Form
              </div>
              <div className="inline-flex items-center w-full justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-secondary-300 text-white ">
                Anterior & Posterior Assessment
              </div>
              <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      className="border px-2 py-1 border-slate-300 "
                      style={{ width: "30%" }}
                    >
                      Name
                    </th>
                    <th
                      className="border px-2 py-1 border-slate-300"
                      style={{ width: "70%" }}
                    >
                      Observation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.gait?.alteredTrunkPosition && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Altered Trunk Position/ Lateral Bending/ Rotation
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.alteredTrunkPosition}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.circumduction && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Circumduction
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.circumduction}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.hipHiking && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Hip Hiking
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.hipHiking}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.alteredHipRoatation && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Altered Hip Rotation
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.alteredHipRoatation}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.alteredFootContact && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Altered Foot Contact
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.alteredFootContact}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.alteredBaseOfWalking && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Altered Base Of Walking / Walking Stance
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.alteredBaseOfWalking}</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}

          {(props.gait?.initialContact ||
            props.gait?.alteredAnkleAndFootPlacement ||
            props.gait?.midStance ||
            props.gait?.terminalStance ||
            props.gait?.alteredKneeRange ||
            props.gait?.alteredHipRange ||
            props.gait?.heelOff ||
            props.gait?.toeOff ||
            props.gait?.preSwing ||
            props.gait?.initialSwing ||
            props.gait?.midSwing ||
            props.gait?.terminalSwing ||
            props.gait?.alteredTrunkPosition ||
            props.gait?.alteredLumbarLordosis) && (
            <div className="flex flex-col items-stretch">
              <div className="inline-flex items-center w-full justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-secondary-300 text-white ">
                Lateral Assessment
              </div>
              <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      className="border px-2 py-1 border-slate-300 "
                      style={{ width: "30%" }}
                    >
                      Name
                    </th>
                    <th
                      className="border px-2 py-1 border-slate-300"
                      style={{ width: "70%" }}
                    >
                      Observation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.gait?.initialContact && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Initial Contact/Heel Strike
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.initialContact}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.alteredAnkleAndFootPlacement && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Altered Ankle And Foot Placement / Range/ Foot
                        Clearance. Loading Response/Foot Flat
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.alteredAnkleAndFootPlacement}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.midStance && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Mid Stance
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.midStance}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.terminalStance && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Terminal Stance
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.terminalStance}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.alteredKneeRange && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Altered Knee Range
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.alteredKneeRange}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.alteredHipRange && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Altered Hip Range
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.alteredHipRange}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.heelOff && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Altered Hip Range
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.heelOff}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.toeOff && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Toe off
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.toeOff}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.preSwing && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Pre-Swing
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.preSwing}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.initialSwing && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Initial Swing/Acceleration
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.initialSwing}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.midSwing && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Mid Swing
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.midSwing}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.terminalSwing && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Terminal Swing/ Deceleration
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.terminalSwing}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.alteredTrunkPosition && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Altered Trunk Position- Anterior/Posterior Bending
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.alteredTrunkPosition}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.alteredLumbarLordosis && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Altered Lumbar Lordosis
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.alteredLumbarLordosis}</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}

          {(props.gait?.initiationOfGait ||
            props.gait?.stepLength ||
            props.gait?.stepSymmetry ||
            props.gait?.strideLength ||
            props.gait?.strideDuration ||
            props.gait?.cadence) && (
            <div className="flex flex-col items-stretch">
              <div className="inline-flex items-center w-full justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-secondary-300 text-white ">
                Other Gait Analysis
              </div>
              <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      className="border px-2 py-1 border-slate-300 "
                      style={{ width: "30%" }}
                    >
                      Name
                    </th>
                    <th
                      className="border px-2 py-1 border-slate-300"
                      style={{ width: "70%" }}
                    >
                      Observation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.gait?.initiationOfGait && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Initiation of Gait
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.initiationOfGait}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.stepLength && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Step Length
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.stepLength}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.stepSymmetry && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Step Symmetry
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.stepSymmetry}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.strideLength && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Stride Length
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.strideLength}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.strideDuration && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Stride Duration
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.strideDuration}</p>
                      </td>
                    </tr>
                  )}
                  {props.gait?.cadence && (
                    <tr>
                      <td className="border px-2 py-1 border-slate-300 ">
                        Cadence
                      </td>
                      <td className="border px-2 py-1 text-center border-slate-300">
                        <p>{props.gait?.cadence}</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}

          <hr className=" my-12 border-tertiary-500"></hr>
        </div>
      )}
    </>
  );
};

export default GaitAssessment;
