import React, {
  Component,
  createContext,
  createRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { Convert, Feedback } from '../types/Feedback';
import useAxios from '@routes/hooks/use-axios';
import { endpoints, replaceParams } from '@utils/axios';
import {
  ElementStore,
  ReactFormBuilder,
  ToolbarItem,
} from 'react-form-builder2';
import '../../../../node_modules/react-form-builder2/dist/app.css';
import Button from '@components/button/outline-button';
import { ButtonVariant } from '@components/button/button';
import { ValidationError } from '@components/hook-form';
import { toast } from 'react-toastify';

function FeedbackComponent(props: { entityId: string }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [noFormFields, setNoFormFields] = useState('');
  const axios = useAxios();
  const [feedbackFormData, setFeedbackFormData] = useState<Feedback | null>(
    null,
  );

  const [data, setData] = useState(null);

  const items = [
    {
      key: 'TextInput',
      canHaveAnswer: false,
      canHavePageBreakBefore: false,
      canHaveAlternateForm: false,
      canHaveOptionCorrect: false,
      canHaveOptionValue: false,
      canPopulateFromApi: false,
    },
    {
      key: 'NumberInput',
      canHaveAnswer: false,
      canHavePageBreakBefore: false,
      canHaveAlternateForm: false,
      canHaveOptionCorrect: false,
      canHaveOptionValue: false,
      canPopulateFromApi: false,
    },
    {
      key: 'TextArea',
      canHaveAnswer: false,
      canHavePageBreakBefore: false,
      canHaveAlternateForm: false,
      canHaveOptionCorrect: false,
      canHaveOptionValue: false,
      canPopulateFromApi: false,
    },
    {
      key: 'RadioButtons',
      canHaveAnswer: false,
      canHavePageBreakBefore: false,
      canHaveAlternateForm: false,
      canHaveOptionCorrect: false,
      canHaveOptionValue: true,
      canHaveDisplayHorizontal: false,
      canPopulateFromApi: false,
    },
    {
      key: 'Checkboxes',
      canHaveAnswer: false,
      canHavePageBreakBefore: false,
      canHaveAlternateForm: false,
      canHaveOptionCorrect: false,
      canHaveOptionValue: true,
      canHaveDisplayHorizontal: false,
      canPopulateFromApi: false,
    },
    {
      key: 'Dropdown',
      canHaveAnswer: false,
      canHavePageBreakBefore: false,
      canHaveAlternateForm: false,
      canHaveOptionCorrect: false,
      canHaveOptionValue: true,
      canHaveDisplayHorizontal: false,
      canPopulateFromApi: false,
    },
  ];

  useEffect(() => {
    // Fetch Feedback Form From Backend
    const getFeedbackForm = async () => {
      try {
        const paramsMap = new Map<string, string>([
          ['entityId', props.entityId],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.entity.fetchFeedbackForm,
          paramsMap,
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data['data'];
        const convertedFeedback = Convert.toFeedback(JSON.stringify(data));
        setFeedbackFormData(convertedFeedback);

        setLoading(false);
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.status === 500
        ) {
          setLoading(false);
        } else {
          console.error('Error fetching feedback form:', error);
          setError(error.message);
          setLoading(false);
        }
      }
    };
    getFeedbackForm();
  }, []);

  function handleSave() {
    if (
      ElementStore &&
      ElementStore?.state &&
      ElementStore?.state.data.length > 0
    ) {
      setNoFormFields('');
      var formFields = ElementStore?.state.data;
      formFields.map(function (field: any) {
        delete field.canHaveAnswer;
        delete field.canHavePageBreakBefore;
        delete field.canHaveAlternateForm;
        delete field.canHaveOptionCorrect;
        delete field.canPopulateFromApi;
        delete field.canHaveDisplayHorizontal;
        delete field.dirty;

        return field;
      });

      if (feedbackFormData && feedbackFormData.id) {
        var formData = {
          entityId: props.entityId,
          form: formFields,
        };
        const paramsMap = new Map<string, string>([
          ['id', feedbackFormData.id],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.entity.updateFeedbackForm,
          paramsMap,
        );
        axios
          .put(filteredEndpoint, formData)
          .then(res => {
            if (res.status === 200) {
              toast.success(
                res.data.message === 'success'
                  ? 'Patient feedback form updated successfully'
                  : res.data.message,
              );
            }
          })
          .catch(error => {
            toast.error(error.message);
          });
      } else {
        var formData = {
          entityId: props.entityId,
          form: formFields,
        };
        const filteredEndpoint = endpoints.entity.addFeedbackForm;
        axios
          .post(filteredEndpoint, formData)
          .then(res => {
            if (res.status === 200) {
              toast.success(
                res.data.message === 'success'
                  ? 'Patient feedback form added successfully'
                  : res.data.message,
              );
              setFeedbackFormData({
                entityId: props.entityId,
                form: formFields,
                id: res.data.data,
              });
            }
          })
          .catch(error => {
            toast.error(error.message);
          });
      }
    } else {
      setNoFormFields('Please add at least one field from toolbox');
    }
  }

  return (
    <>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && (
        <div>
          <div
            className="mb-3 mt-2 inline-flex w-full items-center rounded-lg bg-warning-100 px-3 py-3 text-base text-warning-800"
            role="alert"
          >
            <span className="mr-2">
              <svg
                viewBox="0 0 48 48"
                fill="currentColor"
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0h48v48h-48z" fill="none" />
                <path d="M22 34h4v-12h-4v12zm2-30c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z" />
              </svg>
            </span>
            To create a patient feedback form. Please click on toolbox items
            available on the right side.
          </div>
          {noFormFields && (
            <p className="z-[99] w-3/5">
              <ValidationError message={noFormFields} />
            </p>
          )}
          <div className="flex flex-col w-full space-y-4 mt-10">
            <ReactFormBuilder
              data={feedbackFormData?.form}
              toolbarItems={items}
            />
            <div className="fixed items-center w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-8 shadow bg-gray-100">
              <div className="flex flex-row ">
                <div>
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    type="button"
                    className="ml-3"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FeedbackComponent;
