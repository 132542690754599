// To parse this data:
//
//   import { Convert, Diagnosis } from "./file";
//
//   const diagnosis = Convert.toDiagnosis(json);

export interface Diagnosis {
  id: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  diagnosisDetails: DiagnosisDetail[];
  audit: Audit;
}

export interface Audit {
  createdBy: string;
  lastUpdatedBy: null;
  createdAt: Date;
  lastUpdatedAt: Date;
}

export interface DiagnosisDetail {
  code: string;
  name: string;
  isChecked?: boolean;
  value: string;
}

// Converts JSON strings to/from your types
export class ConvertDiagnosis {
  public static toDiagnosis(json: string): Diagnosis {
    return JSON.parse(json);
  }

  public static diagnosisToJson(value: Diagnosis): string {
    return JSON.stringify(value);
  }
}
