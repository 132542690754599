import { ApexOptions } from "apexcharts";
import { Assessment } from "./types/AssessmentTypes";
import ReactApexChart from "react-apexcharts";
import React from "react";
const barGraphOptions: ApexOptions = {
  series: [37, 28, 17, 11, 7],
  chart: {
    events: {
      mounted: function (chartContext, config) {},
      updated: function (chartContext, config) {},
    },
    height: 250,
    width: 500,
    toolbar: {
      show: false,
    },
  },
  stroke: {
    curve: "smooth",
  },
  markers: {
    size: 1,
  },
  colors: [
    "var(--color-primary-500)",
    "var(--color-secondary-500)",
    "var(--color-tertiary-500)",
  ],
  plotOptions: {
    bar: {
      borderRadius: 4,
      horizontal: true,
    },
  },
  labels: [
    // Add labels here
  ],
  dataLabels: {
    enabled: true,
    style: {
      fontFamily: "Inter, sans-serif",
    },
  },
  legend: {
    position: "bottom",
    fontFamily: "Inter, sans-serif",
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return value.toString();
      },
    },
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        return value;
      },
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  noData: {
    text: "No data added yet",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "14px",
      fontFamily: undefined,
    },
  },
};

const colorList = [
  "var(--color-primary-500)",
  "var(--color-secondary-500)",
  "var(--color-tertiary-500)",
];

type CustomAssessmentGraphsProps = {
  selectedData: Assessment[];
  columnLabels: string[];
};

export default function CustomAssessmentGraphs(
  props: CustomAssessmentGraphsProps
) {
  ////console.log('Selected Data ' + JSON.stringify(props.selectedData));
  return (
    <div className="grid grid-cols-1 gap-0 md:grid-cols-1 md:gap-2 lg:grid-cols-1  lg:gap-4 w-full">
      {props.selectedData.map((assessment, indexAssessment) => {
        ////console.log('Assessment' + JSON.stringify(assessment));
        const numericParameters = assessment.parameters.filter(
          (parameter) => parameter.validation === "Number"
        );

        if (numericParameters.length <= 0) {
          return <></>;
        }

        return (
          <div className="flex flex-col mx-2 m-10" key={indexAssessment}>
            {
              // if the assessment has parameters with validation as number
              // display the bar graph
              assessment.parameters.filter(
                (parameter) => parameter.validation === "Number"
              ).length > 0 ? (
                <>
                  <h3 className="text-md leading-6 font-bold text-gray-900">
                    {assessment.assessmentName}
                  </h3>
                  <hr className="w-full my-2" />
                </>
              ) : (
                <></>
              )
            }
            <div className="flex flex-col w-full">
              {assessment.parameters
                .filter((parameter) => parameter.validation === "Number")
                .map((parameter, index) => {
                  //   //console.log('Parameter :' + JSON.stringify(parameter));

                  const series = parameter.values.map((value) => {
                    // try to convert the value to a number
                    const numberValue = value
                      ? value.value
                        ? parseFloat(value.value.toString())
                        : 0
                      : 0;
                    return isNaN(numberValue) ? 0 : numberValue;
                  });

                  const options: ApexOptions = {
                    ...barGraphOptions,
                    labels: props.columnLabels.slice(2),
                    series: [
                      {
                        name: parameter.name,
                        data: series,
                        color: colorList[indexAssessment % colorList.length],
                      },
                    ],
                    title: {
                      text: parameter.name,
                      align: "center",
                      style: {
                        fontFamily: "Inter, sans-serif",
                      },
                    },

                    yaxis: {
                      labels: {
                        formatter: function (value) {
                          return value.toString();
                        },
                      },
                    },
                    xaxis: {
                      labels: {
                        formatter: function (value) {
                          return value;
                        },
                      },
                      axisTicks: {
                        show: false,
                      },
                      axisBorder: {
                        show: false,
                      },
                    },
                  };

                  return (
                    <div key={index} className="w-full">
                      <ReactApexChart
                        options={options}
                        series={options.series}
                        type="area"
                        height={"100%"}
                        width={"100%"}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
