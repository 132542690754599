import { Button, OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/outline-button";

import { Divider } from "@components/divider";
import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";
import useAxios from "@routes/hooks/use-axios";
import { replaceParams, endpoints } from "@utils/axios";
import moment from "moment";
import React, { useContext, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faA, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { paths } from "@routes/paths";
import RightOffcanvas from "@components/right-offcanvas/right-offcanvas";
import VitalAssessmentComponent from "@pages/vitals-assessment/VitalsAssessment";

import RangeOfMotionAssessmentComponent from "@pages/range-of-motion-assessment/RangeOfMotionAssessment";
import MuscleToneAssessmentComponent from "@pages/muscle-tone-assessment/MuscleToneAssessment";

import PainAssessmentComponent from "@pages/pain-assessment/PainAssessment";
import {
  Convert,
  MasterAssessmentLedger,
} from "@pages/manage-patient/types/MasterAssessmentLedger";
import { NewMasterAssessmentData } from "@pages/manage-patient-new/types//NewMasterAssessment";
import ReflexAssessmentComponent from "@pages/reflex-assessment/ReflexAssessment";
import OedemaAssessmentComponent from "@pages/assesment-all/oedema-assessment/OedemaAssessment";
import LimbLengthAssessmentComponent from "@pages/assesment-all/limb-length-assessment/LimbLengthAssessment";
import LimbGirthAssessmentComponent from "@pages/assesment-all/limb-girth-assessment/LimbGirthAssessment";
import MuscleWastingAssessmentComponent from "@pages/assesment-all/muscle-wasting/MuscleWastingAssessment";
import BalanceAssessmentComponent from "@pages/assesment-all/balance-assessment/BalanceAssessment";
import MuscleTightnessAssessmentComponent from "@pages/assesment-all/muscle-tightness-assessment/MuscleTightnessAssessment";
import MuscleStrengthAssessmentComponent from "@pages/assesment-all/strength-assessment/MuscleStrengthAssessment";
import SensoryAssessmentComponent from "@pages/assesment-all/sensory-assessment/SensoryAssessment";
import ScarAssessmentComponent from "@pages/assesment-all/scar-assessment/ScarAssessment";
import SpecialTestAssessmentComponent from "@pages/assesment-all/special-test-assessment/SpecialTestAssessment";
import NerveTestAssessmentComponent from "@pages/assesment-all/nerve-test-assessment/NerveTestAssessment";
import StructuralDeformitiesComponent from "@pages/assesment-all/structural-deformities/StructuralDeformities";
import CustomAssessmentComponent from "@pages/assesment-all/CustomAssessment/CustomAssessment";
import GaitAssessmentComponent from "@pages/assesment-all/gait-assessment/GaitAssessment";
import PostureAssessmentComponent from "@pages/assesment-all/posture-assessment/PostureAssessment";
import VertigoAssessmentComponent from "@pages/assesment-all/vertigo/VertigoAssessment";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import ADLAssessmentComponent from "@pages/assesment-all/ADL/ADL";
import { Drawer, DrawerContent, DrawerTrigger } from "@components/ui/drawer";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "@components/ui/sheet";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { PlusIcon } from "@heroicons/react/24/outline";

function isAssessmentComplete(
  masterAssessment: NewMasterAssessmentData,
  assessmentName: string
) {
  switch (assessmentName) {
    case "Vitals":
      return (
        masterAssessment.vitalAssessmentId !== null &&
        masterAssessment.vitalAssessmentId !== undefined &&
        masterAssessment.vitalAssessmentId !== "000000000000000000000000"
      );
    case "Pain":
      return (
        masterAssessment.painAssessmentId !== null &&
        masterAssessment.painAssessmentId !== undefined &&
        masterAssessment.painAssessmentId !== "000000000000000000000000"
      );
    case "Posture":
      return (
        masterAssessment.postureAssessmentId !== null &&
        masterAssessment.postureAssessmentId !== undefined &&
        masterAssessment.postureAssessmentId !== "000000000000000000000000"
      );
    case "ROM":
      return (
        masterAssessment.rangeOfMotionAssessmentId !== null &&
        masterAssessment.rangeOfMotionAssessmentId !== undefined &&
        masterAssessment.rangeOfMotionAssessmentId !==
          "000000000000000000000000"
      );
    case "MuscleTone":
      return (
        masterAssessment.muscleToneAssessmentId !== null &&
        masterAssessment.muscleToneAssessmentId !== undefined &&
        masterAssessment.muscleToneAssessmentId !== "000000000000000000000000"
      );
    case "GAIT":
      return (
        masterAssessment.gaitAssessmentId !== null &&
        masterAssessment.gaitAssessmentId !== undefined &&
        masterAssessment.gaitAssessmentId !== "000000000000000000000000"
      );
    case "Reflex":
      return (
        masterAssessment.reflexAssessmentId !== null &&
        masterAssessment.reflexAssessmentId !== undefined &&
        masterAssessment.reflexAssessmentId !== "000000000000000000000000"
      );
    case "Oedema":
      return (
        masterAssessment.oedemaAssessmentId !== null &&
        masterAssessment.oedemaAssessmentId !== undefined &&
        masterAssessment.oedemaAssessmentId !== "000000000000000000000000"
      );
    case "LimbLengthAssessment":
      return (
        masterAssessment.limbLengthAssessmentId !== null &&
        masterAssessment.limbLengthAssessmentId !== undefined &&
        masterAssessment.limbLengthAssessmentId !== "000000000000000000000000"
      );
    case "LimbGirthAssessment":
      return (
        masterAssessment.limbGirthAssessmentId !== null &&
        masterAssessment.limbGirthAssessmentId !== undefined &&
        masterAssessment.limbGirthAssessmentId !== "000000000000000000000000"
      );
    case "MuscleWastingAssessment":
      return (
        masterAssessment.muscleWastingAssessmentId !== null &&
        masterAssessment.muscleWastingAssessmentId !== undefined &&
        masterAssessment.muscleWastingAssessmentId !==
          "000000000000000000000000"
      );
    case "BalanceAssessment":
      return (
        masterAssessment.balanceAssessmentId !== null &&
        masterAssessment.balanceAssessmentId !== undefined &&
        masterAssessment.balanceAssessmentId !== "000000000000000000000000"
      );
    case "MuscleTightnessAssessment":
      return (
        masterAssessment.muscleTightnessAssessmentId !== null &&
        masterAssessment.muscleTightnessAssessmentId !== undefined &&
        masterAssessment.muscleTightnessAssessmentId !==
          "000000000000000000000000"
      );
    case "MuscleStrengthAssessment":
      return (
        masterAssessment.muscleStrengthAssessmentId !== null &&
        masterAssessment.muscleStrengthAssessmentId !== undefined &&
        masterAssessment.muscleStrengthAssessmentId !==
          "000000000000000000000000"
      );
    case "SensoryAssessment":
      return (
        masterAssessment.sensoryAssessmentId !== null &&
        masterAssessment.sensoryAssessmentId !== undefined &&
        masterAssessment.sensoryAssessmentId !== "000000000000000000000000"
      );
    case "ScarAssessment":
      return (
        masterAssessment.scarAssessmentId !== null &&
        masterAssessment.scarAssessmentId !== undefined &&
        masterAssessment.scarAssessmentId !== "000000000000000000000000"
      );
    case "CustomAssessment":
      return (
        masterAssessment.customAssessmentId !== null &&
        masterAssessment.customAssessmentId !== undefined &&
        masterAssessment.customAssessmentId !== "000000000000000000000000"
      );
    case "NerveTestAssessment":
      return (
        masterAssessment.nerveTestAssessmentId !== null &&
        masterAssessment.nerveTestAssessmentId !== undefined &&
        masterAssessment.nerveTestAssessmentId !== "000000000000000000000000"
      );
    case "StructuralDeformities":
      return (
        masterAssessment.structuralDeformitiesAssessmentId !== null &&
        masterAssessment.structuralDeformitiesAssessmentId !== undefined &&
        masterAssessment.structuralDeformitiesAssessmentId !==
          "000000000000000000000000"
      );
    case "SpecialTestAssessment":
      return (
        masterAssessment.specialTestsAssessmentId !== null &&
        masterAssessment.specialTestsAssessmentId !== undefined &&
        masterAssessment.specialTestsAssessmentId !== "000000000000000000000000"
      );
    case "VertigoAssessment":
      return (
        masterAssessment.vertigoAssessmentId !== null &&
        masterAssessment.vertigoAssessmentId !== undefined &&
        masterAssessment.vertigoAssessmentId !== "000000000000000000000000"
      );
    case "ADL":
      return (
        masterAssessment.adlAssessmentId !== null &&
        masterAssessment.adlAssessmentId !== undefined &&
        masterAssessment.adlAssessmentId !== "000000000000000000000000"
      );
    default:
      return false;
  }
}

const AssessmentComponents = {
  Vitals: VitalAssessmentComponent,
  GAIT: GaitAssessmentComponent,
  Pain: PainAssessmentComponent,
  Posture: PostureAssessmentComponent,

  ROM: RangeOfMotionAssessmentComponent,

  MuscleTone: MuscleToneAssessmentComponent,
  Reflex: ReflexAssessmentComponent,
  Oedema: OedemaAssessmentComponent,
  LimbLengthAssessment: LimbLengthAssessmentComponent,
  LimbGirthAssessment: LimbGirthAssessmentComponent,
  MuscleWastingAssessment: MuscleWastingAssessmentComponent,
  BalanceAssessment: BalanceAssessmentComponent,
  MuscleTightnessAssessment: MuscleTightnessAssessmentComponent,
  MuscleStrengthAssessment: MuscleStrengthAssessmentComponent,
  SensoryAssessment: SensoryAssessmentComponent,
  ScarAssessment: ScarAssessmentComponent,
  SpecialTestAssessment: SpecialTestAssessmentComponent,
  NerveTestAssessment: NerveTestAssessmentComponent,
  StructuralDeformities: StructuralDeformitiesComponent,
  CustomAssessment: CustomAssessmentComponent,
  VertigoAssessment: VertigoAssessmentComponent,
  ADL: ADLAssessmentComponent,
};

type AssessmentKey = keyof typeof AssessmentComponents;

export default function ViewMasterAssessmentNew(props: {
  patientId: string;
  masterAssessmentData: NewMasterAssessmentData | null;
  getMasterAssessmentLedger: () => Promise<void>;
}) {
  const [loading, setLoading] = React.useState(true);

  const [error, setError] = React.useState("");
  const [chipCategories, setChipCategories] = React.useState<string[]>([]);

  const [assessmentIndex, setAssessmentIndex] =
    React.useState<AssessmentKey>("Vitals");
  const axios = useAxios();
  let { patientId, masterAssessmentId } = useParams();

  const [isRightOffcanvasOpen, setIsRightOffcanvasOpen] = React.useState(false);

  const [rightCanvasSize, setRightCanvasSize] =
    React.useState("w-full md:w-[75vh]");

  const OffcanvasContentComponent = AssessmentComponents[assessmentIndex];

  const handleOpenRightOffCanvas = (assementName: AssessmentKey) => {
    if (
      assementName === "CustomAssessment" ||
      assementName === "MuscleTone" ||
      assementName === "ROM"
    ) {
      setRightCanvasSize("md:w-[90vw]");
    } else {
      setRightCanvasSize("w-full md:w-[100vh]");
    }
    setAssessmentIndex(assementName);
    setIsRightOffcanvasOpen(true);
  };

  const handleCloseRightOffCanvas = () => {
    setIsRightOffcanvasOpen(false);
    setisRightOffcanvasViewAsmt(true);
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    const getChipCategories = async () => {
      try {
        setLoading(true);

        const userData = getUserPersistedOnLocalStorage();

        const paramsMap = new Map<string, string>([
          ["id", userData?.entity_id ?? ""],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.assessmentSetup,
          paramsMap
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data["data"];
        data["assessmentCategories"].sort((a: string, b: string) => {
          if (a === "Common") {
            return -1; // "Common" should come before anything else
          } else if (b === "Common") {
            return 1; // "Common" should come after anything else
          } else {
            return 0; // Keep the original order
          }
        });
        setChipCategories(data["assessmentCategories"]);
        setSelectedCategory([
          "Common",
          "CustomAssessment",
          "Orthopedic/Muscular",
        ]);
        setLoading(false);
      } catch (error) {
        console.error("Error getting assessment categories", error);
        setLoading(false);
      }
    };
    getChipCategories();
  }, []);

  const AssessmentCards = [
    {
      key: "Vitals",
      name: "Vitals",
      categories: [
        "Orthopedic/Muscular",
        "Neurological",
        "Geriatric",
        "Pediatric",
        "Cardio",
        "Sports",
        "WomanHealth",
        "Common",
      ],
    },
    {
      key: "Pain",
      name: "Pain",
      categories: [
        "Orthopedic/Muscular",
        "Sports",
        "WomanHealth",
        "Neurological",
        "Cardio",
        "Geriatric",
        "Pediatric",
        "Common",
      ],
    },
    {
      key: "Posture",
      name: "Posture",
      categories: ["Orthopedic/Muscular"],
    },
    {
      key: "ROM",
      name: "ROM",
      categories: [
        "Orthopedic/Muscular",
        "Neurological",
        "Geriatric",
        "Common",
      ],
    },
    {
      key: "MuscleTone",
      name: "Muscle Tone",
      categories: ["Orthopedic/Muscular", "Neurological", "Geriatric"],
    },
    {
      key: "GAIT",
      name: "GAIT",
      categories: [
        "Orthopedic/Muscular",
        "Neurological",
        "Geriatric",
        "Pediatric",
      ],
    },
    {
      key: "Reflex",
      name: "Reflex",
      categories: ["Orthopedic/Muscular", "Neurological"],
    },
    {
      key: "Oedema",
      name: "Oedema",
      categories: ["Orthopedic/Muscular"],
    },
    {
      key: "LimbLengthAssessment",
      name: "Limb Length",
      categories: ["Orthopedic/Muscular"],
    },
    {
      key: "LimbGirthAssessment",
      name: "Limb Girth",
      categories: ["Orthopedic/Muscular"],
    },
    {
      key: "MuscleWastingAssessment",
      name: "Muscle Wasting",
      categories: ["Orthopedic/Muscular"],
    },
    {
      key: "BalanceAssessment",
      name: "Balance",
      categories: ["Neurological"],
    },
    {
      key: "MuscleTightnessAssessment",
      name: "Muscle Tightness",
      categories: ["Neurological", "Orthopedic/Muscular"],
    },
    {
      key: "MuscleStrengthAssessment",
      name: "Muscle Strength",
      categories: ["Neurological", "Orthopedic/Muscular"],
    },
    {
      key: "SensoryAssessment",
      name: "Sensory",
      categories: ["Neurological", "Orthopedic/Muscular"],
    },
    {
      key: "ScarAssessment",
      name: "Scar",
      categories: ["Neurological", "Orthopedic/Muscular"],
    },
    {
      key: "SpecialTestAssessment",
      name: "Special Test",
      categories: ["Neurological"],
    },
    {
      key: "NerveTestAssessment",
      name: "Nerve Test",
      categories: ["Neurological", "Orthopedic/Muscular"],
    },
    {
      key: "StructuralDeformities",
      name: "Structural Deformities",
      categories: ["Orthopedic/Muscular"],
    },
    {
      key: "CustomAssessment",
      name: "Custom Assessment",
      categories: ["Common"],
    },
    {
      key: "VertigoAssessment",
      name: "Vertigo Assessment",
      categories: ["Vertigo"],
    },
    {
      key: "ADL",
      name: "ADL",
      categories: ["Common"],
    },
  ] as const;

  const getActiveAssessmentId = (index: AssessmentKey) => {
    switch (index) {
      case "Vitals":
        return props.masterAssessmentData?.vitalAssessmentId;

      case "Pain":
        return props.masterAssessmentData?.painAssessmentId;

      case "GAIT":
        return props.masterAssessmentData?.gaitAssessmentId;

      case "MuscleTone":
        return props.masterAssessmentData?.muscleToneAssessmentId;

      case "Posture":
        return props.masterAssessmentData?.postureAssessmentId;

      case "ROM":
        return props.masterAssessmentData?.rangeOfMotionAssessmentId;

      case "Reflex":
        return props.masterAssessmentData?.reflexAssessmentId;

      case "Oedema":
        return props.masterAssessmentData?.oedemaAssessmentId;

      case "LimbLengthAssessment":
        return props.masterAssessmentData?.limbLengthAssessmentId;

      case "LimbGirthAssessment":
        return props.masterAssessmentData?.limbGirthAssessmentId;

      case "MuscleWastingAssessment":
        return props.masterAssessmentData?.muscleWastingAssessmentId;

      case "BalanceAssessment":
        return props.masterAssessmentData?.balanceAssessmentId;

      case "MuscleTightnessAssessment":
        return props.masterAssessmentData?.muscleTightnessAssessmentId;

      case "MuscleStrengthAssessment":
        return props.masterAssessmentData?.muscleStrengthAssessmentId;

      case "CustomAssessment":
        return props.masterAssessmentData?.customAssessmentId;

      case "SensoryAssessment":
        return props.masterAssessmentData?.sensoryAssessmentId;
      case "StructuralDeformities":
        return props.masterAssessmentData?.structuralDeformitiesAssessmentId;
      case "ScarAssessment":
        return props.masterAssessmentData?.scarAssessmentId;

      case "SpecialTestAssessment":
        return props.masterAssessmentData?.specialTestsAssessmentId;
      case "NerveTestAssessment":
        return props.masterAssessmentData?.nerveTestAssessmentId;
      case "VertigoAssessment":
        return props.masterAssessmentData?.vertigoAssessmentId;
      case "ADL":
        return props.masterAssessmentData?.adlAssessmentId;
      default:
        return "";
    }
  };

  const categoryChips = [
    "Orthopedic/Muscular",
    "Neurological",
    "Geriatric",
    "Pediatric",
    "Cardio",
    "Sports",
    "WomanHealth",
    "Common",
    "Vertigo",
  ];

  const [selectedCategory, setSelectedCategory] = React.useState<string[]>([]);
  const [isRightOffcanvasViewAsmt, setisRightOffcanvasViewAsmt] =
    React.useState(false);

  const filteredAssessmentCards = AssessmentCards.filter((card) =>
    card.categories.some((category) => selectedCategory.includes(category))
  );
  const initialCompletedAssesments = AssessmentCards.filter((card) =>
    isAssessmentComplete(props.masterAssessmentData!, card.key)
  );

  const postSubmitCallback = () => {
    props.getMasterAssessmentLedger();
    handleCloseRightOffCanvas();
  };

  return (
    <>
      <RightOffcanvas
        isOpen={isRightOffcanvasOpen}
        onClose={handleCloseRightOffCanvas}
        sizeClasses={"w-full"}
      >
        <OffcanvasContentComponent
          masterAssessmentId={props.masterAssessmentData?.id!}
          assessmentId={getActiveAssessmentId(assessmentIndex)!}
          patientId={patientId!}
          postSubmitCallback={postSubmitCallback}
        />
      </RightOffcanvas>

      {/*      <div className="min-w-0 flex-1">
        <span className="text-xl font-bold leading-7">
          Physical Assessments
        </span>
      </div> */}
      <div className="flex flex-row justify-end gap-2">
        <div>
          <OutlinedButton
            variant={ButtonVariant.GRAY}
            onClick={() => handleOpenRightOffCanvas("CustomAssessment")}
            className="border justify-end text-sm"
          >
            <PlusCircleIcon className="h-3 w-3 mr-2 md:flex hidden" />
            Add Custom Assessments
          </OutlinedButton>
        </div>
        <div>
          <OutlinedButton
            variant={ButtonVariant.GRAY}
            onClick={() => setisRightOffcanvasViewAsmt(true)}
            className="border justify-end"
          >
            <PlusCircleIcon className="h-3 w-3 mr-2 md:flex hidden" />
            Add Other Assessments
          </OutlinedButton>
          {isRightOffcanvasViewAsmt && (
            <RightOffcanvas
              isOpen={isRightOffcanvasViewAsmt}
              onClose={() => {
                setisRightOffcanvasViewAsmt(false);
              }}
              sizeClasses="w-full"
            >
              <div className="flex flex-col w-full">
                <div className="flex justify-end items-end w-full mb-2">
                  <OutlinedButton
                    variant={ButtonVariant.SECONDARY}
                    type="button"
                    onClick={() => {
                      setisRightOffcanvasViewAsmt(false);
                    }}
                    children="Close"
                  />
                </div>
                <div className="w-full">
                  <span className="text-md font-bold leading-7">
                    Assessment Types
                  </span>
                  <div className="flex flex-row flex-wrap w-full">
                    {chipCategories &&
                      chipCategories.map((tr: string) => {
                        return (
                          <div
                            key={tr}
                            className={`flex items-center justify-evenly mx-1 my-1 rounded-lg text-center text-sm p-2 max-h-10 hover:bg-tertiary-100 border transition ease-in-out duration-150 
                           ${
                             selectedCategory.some(
                               (category) => category === tr
                             )
                               ? "bg-tertiary-100 border-tertiary-500"
                               : "bg-white"
                           }`}
                            onClick={() => {
                              const categoryIndex = selectedCategory.findIndex(
                                (category) => category === tr
                              );

                              if (categoryIndex === -1) {
                                setSelectedCategory((previousCategory) => [
                                  ...previousCategory,
                                  tr,
                                ]);
                              } else {
                                setSelectedCategory((previousCategory) =>
                                  previousCategory.filter(
                                    (category) => category !== tr
                                  )
                                );
                              }
                            }}
                          >
                            <span
                              className={`flex flex-col  ${
                                selectedCategory.some(
                                  (category) => category === tr
                                )
                                  ? "text-tertiary-500 font-semibold"
                                  : "text-black font-semibold "
                              }`}
                            >
                              {tr}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                  <Divider />
                  <div className="relative pb-5 mt-4 sm:pb-0">
                    {(selectedCategory.includes("Sports") ||
                      selectedCategory.includes("Cardio") ||
                      selectedCategory.includes("WomanHealth") ||
                      selectedCategory.includes("Pediatric")) &&
                      selectedCategory.length === 1 && (
                        <div
                          className={`flex justify-evenly rounded-lg  text-sm p-2 hover:bg-tertiary-100 border transition ease-in-out duration-150  mb-4 bg-tertiary-100 border-tertiary-500`}
                        >
                          <span className={`flex flex-col`}>
                            New assessments in this category on the way. Stay
                            tuned!
                          </span>
                        </div>
                      )}

                    <ul className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 w-full">
                      {filteredAssessmentCards.length !== 0 ? (
                        filteredAssessmentCards.map((assessment) =>
                          isAssessmentComplete(
                            props.masterAssessmentData!,
                            assessment.key
                          ) ? (
                            <></>
                          ) : (
                            <li
                              key={assessment.key}
                              className="col-span-1 bg-white rounded-2xl shadow"
                            >
                              <div className="flex flex-row justify-between h-full p-4 border">
                                <div className="flex items-center justify-center flex-col">
                                  {assessment.name}
                                </div>
                                <div className="flex flex-col">
                                  <p className="text-sm text-gray-500 truncate">
                                    {isAssessmentComplete(
                                      props.masterAssessmentData!,
                                      assessment.key
                                    )
                                      ? "Completed"
                                      : ""}
                                  </p>
                                  {isAssessmentComplete(
                                    props.masterAssessmentData!,
                                    assessment.key
                                  ) ? (
                                    <OutlinedButton
                                      variant={ButtonVariant.GHOST}
                                      type="button"
                                      className="border text-primary-500"
                                      onClick={() => {
                                        handleOpenRightOffCanvas(
                                          assessment.key
                                        );
                                        setisRightOffcanvasViewAsmt(false);
                                      }}
                                    >
                                      <PlusIcon className="h-3 w-3 mr-2" />
                                      Add
                                    </OutlinedButton>
                                  ) : (
                                    <OutlinedButton
                                      variant={ButtonVariant.GHOST}
                                      type="button"
                                      className="border text-primary-500"
                                      onClick={() => {
                                        handleOpenRightOffCanvas(
                                          assessment.key
                                        );
                                        setisRightOffcanvasViewAsmt(false);
                                      }}
                                    >
                                      <PlusIcon className="h-3 w-3 mr-2" />
                                      Add
                                    </OutlinedButton>
                                  )}
                                </div>
                              </div>
                            </li>
                          )
                        )
                      ) : (
                        <>
                          <div className="bg-gray-50 rounded-2xl shadow p-10 text-base font-normal text-gray-500">
                            To add more select Assessment types from above
                          </div>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </RightOffcanvas>
          )}

          {/* <Sheet>
            <SheetTrigger asChild>
              <div className="items-end justify-end text-right">
                <OutlinedButton
                  variant={ButtonVariant.GRAY}
                  onClick={() => {}}
                  className="border justify-end"
                >
                  <PlusCircleIcon className="h-3 w-3 mr-2" />
                  Add Other Assessments
                </OutlinedButton>
              </div>
            </SheetTrigger>
            <SheetContent style={{ maxWidth: "75vw" }}>
              <div className="flex justify-end items-end w-full mb-2">
                <SheetClose className="border-2 rounded-md p-1 bg-primary-200 font-medium">
                  Close
                </SheetClose>
              </div>
              <span className="text-md font-bold leading-7">
                Assessment Types
              </span>
              <div className="flex flex-row flex-wrap w-full">
                {chipCategories &&
                  chipCategories.map((tr: string) => {
                    return (
                      <div
                        key={tr}
                        className={`flex items-center justify-evenly mx-1 my-1 rounded-lg text-center text-sm p-2 max-h-10 hover:bg-tertiary-100 border transition ease-in-out duration-150 
                           ${
                             selectedCategory.some(
                               (category) => category === tr
                             )
                               ? "bg-tertiary-100 border-tertiary-500"
                               : "bg-white"
                           }`}
                        onClick={() => {
                          const categoryIndex = selectedCategory.findIndex(
                            (category) => category === tr
                          );

                          if (categoryIndex === -1) {
                            setSelectedCategory((previousCategory) => [
                              ...previousCategory,
                              tr,
                            ]);
                          } else {
                            setSelectedCategory((previousCategory) =>
                              previousCategory.filter(
                                (category) => category !== tr
                              )
                            );
                          }
                        }}
                      >
                        <span
                          className={`flex flex-col  ${
                            selectedCategory.some((category) => category === tr)
                              ? "text-tertiary-500 font-semibold"
                              : "text-black font-semibold "
                          }`}
                        >
                          {tr}
                        </span>
                      </div>
                    );
                  })}
              </div>
              <Divider />
              <div className="relative pb-5 mt-4 sm:pb-0">
                {(selectedCategory.includes("Sports") ||
                  selectedCategory.includes("Cardio") ||
                  selectedCategory.includes("WomanHealth") ||
                  selectedCategory.includes("Pediatric")) &&
                  selectedCategory.length === 1 && (
                    <div
                      className={`flex justify-evenly rounded-lg  text-sm p-2 hover:bg-tertiary-100 border transition ease-in-out duration-150  mb-4 bg-tertiary-100 border-tertiary-500`}
                    >
                      <span className={`flex flex-col`}>
                        New assessments in this category on the way. Stay tuned!
                      </span>
                    </div>
                  )}

                <ul className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 w-full">
                  {filteredAssessmentCards.length !== 0 ? (
                    filteredAssessmentCards.map((assessment) =>
                      isAssessmentComplete(
                        props.masterAssessmentData!,
                        assessment.key
                      ) ? (
                        <></>
                      ) : (
                        <li
                          key={assessment.key}
                          className="col-span-1 bg-white rounded-2xl shadow"
                        >
                          <div className="flex flex-row justify-between h-full p-4 border">
                            <div className="flex items-center justify-center flex-col">
                              {assessment.name}
                            </div>
                            <div className="flex flex-col">
                              <p className="text-sm text-gray-500 truncate">
                                {isAssessmentComplete(
                                  props.masterAssessmentData!,
                                  assessment.key
                                )
                                  ? "Completed"
                                  : ""}
                              </p>
                              {isAssessmentComplete(
                                props.masterAssessmentData!,
                                assessment.key
                              ) ? (
                                <OutlinedButton
                                  variant={ButtonVariant.GHOST}
                                  type="button"
                                  className="border text-primary-500"
                                  onClick={() =>
                                    handleOpenRightOffCanvas(assessment.key)
                                  }
                                >
                                  <PlusIcon className="h-3 w-3 mr-2" />
                                  Add
                                </OutlinedButton>
                              ) : (
                                <OutlinedButton
                                  variant={ButtonVariant.GHOST}
                                  type="button"
                                  className="border text-primary-500"
                                  onClick={() =>
                                    handleOpenRightOffCanvas(assessment.key)
                                  }
                                >
                                  <PlusIcon className="h-3 w-3 mr-2" />
                                  Add
                                </OutlinedButton>
                              )}
                            </div>
                          </div>
                        </li>
                      )
                    )
                  ) : (
                    <>
                      <div className="bg-gray-50 rounded-2xl shadow p-10 text-base font-normal text-gray-500">
                        To add more select Assessment types from above
                      </div>
                    </>
                  )}
                </ul>
              </div>
            </SheetContent>
          </Sheet> */}
        </div>
      </div>

      <div className="relative pb-5 mt-4 sm:pb-0">
        <span className="text-sm font-semibold leading-7">
          Performed Assessments
        </span>
        <ul className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 w-full">
          {initialCompletedAssesments.map((assessment) => (
            <li
              key={assessment.key}
              className="col-span-1 bg-white rounded-2xl shadow"
            >
              <div className="flex flex-col justify-between h-full p-4">
                <div className="flex flex-col">
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {assessment.name}
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="text-sm text-gray-500 truncate">
                      {moment().format("MMMM Do YYYY")}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col">
                  <p className="text-sm text-gray-500 truncate">
                    {isAssessmentComplete(
                      props.masterAssessmentData!,
                      assessment.key
                    )
                      ? "Completed"
                      : ""}
                  </p>
                  {isAssessmentComplete(
                    props.masterAssessmentData!,
                    assessment.key
                  ) ? (
                    <OutlinedButton
                      variant={ButtonVariant.TERTIARY}
                      type="button"
                      className="mt-2"
                      onClick={() => {
                        handleOpenRightOffCanvas(assessment.key);
                      }}
                    >
                      View
                    </OutlinedButton>
                  ) : (
                    <OutlinedButton
                      variant={ButtonVariant.PRIMARY}
                      type="button"
                      className="mt-2"
                      onClick={() => handleOpenRightOffCanvas(assessment.key)}
                    >
                      Start
                    </OutlinedButton>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
