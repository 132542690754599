import { OutlinedButton } from "@components/button";
import Button, { ButtonVariant } from "@components/button/button";
import { Dialog, Transition } from "@headlessui/react";
import { endpoints, replaceParams } from "@utils/axios";
import React, { Fragment, useEffect, useState } from "react";
import { Appointment, Convert } from "../types/Appointment";
import useAxios from "@routes/hooks/use-axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "@components/modal";
import moment from "moment";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

export default function AppointmentDetails(props: {
  appId: string;
  setShowAppointmentDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setCancelDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setRescheduleAppointmentDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setAppointment: React.Dispatch<React.SetStateAction<Appointment | null>>;
  setTransferAppointmentDialog: React.Dispatch<React.SetStateAction<boolean>>;
  appointment: Appointment | null;
}) {
  const userData = getUserPersistedOnLocalStorage();
  let [isOpen, setIsOpen] = useState(true);

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const axios = useAxios();
  const [waitingTime, setWaitingTime] = useState("");
  const [sessionTime, setSessionTime] = useState("");

  function closeModal() {
    setIsOpen(false);
    props.setShowAppointmentDetails(false);
  }

  useEffect(() => {
    const getAppointmentById = async () => {
      try {
        const paramsMap = new Map<string, string>([["id", props.appId]]);
        const filteredEndpoint = replaceParams(
          endpoints.appointment.view,
          paramsMap
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data["data"];

        const convertedAppointment = Convert.toAppointment(
          JSON.stringify(data)
        );
        let duration =
          convertedAppointment.endTimeMinutes -
          convertedAppointment.startTimeMinutes;

        convertedAppointment.apptdate = new Date(
          moment(convertedAppointment.apptdate).toLocaleString()
        );

        convertedAppointment.sessionStartTime = new Date(
          moment(convertedAppointment.sessionStartTime).toLocaleString()
        );

        convertedAppointment.checkInTime = new Date(
          moment(convertedAppointment.checkInTime).toLocaleString()
        );

        convertedAppointment.sessionCompleteTime = new Date(
          moment(convertedAppointment.sessionCompleteTime).toLocaleString()
        );

        convertedAppointment.startTime = moment(
          convertedAppointment.apptdate
        ).format("hh:mm A");
        convertedAppointment.endTime = moment(
          convertedAppointment.startTime,
          "hh:mm A"
        )
          .add(duration, "minutes")
          .format("hh:mm A");

        getWaitingAndSessionTime(
          convertedAppointment.sessionStartTime,
          convertedAppointment.checkInTime,
          convertedAppointment.sessionCompleteTime
        );

        convertedAppointment.startTimeMinutes =
          convertedAppointment.apptdate.getHours() * 60 +
          convertedAppointment.apptdate.getMinutes();
        convertedAppointment.endTimeMinutes =
          convertedAppointment.apptdate.getHours() * 60 +
          convertedAppointment.apptdate.getMinutes() +
          duration;
        props.setAppointment(convertedAppointment);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching appointment by id:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    getAppointmentById();
  }, []);

  const getWaitingAndSessionTime = (
    sessionStartTime: Date,
    checkInTime: Date,
    sessionCompleteTime: Date
  ) => {
    if (
      moment(new Date(checkInTime)).format("(DD-MM-YYYY) HH:mm") ===
        "(01-01-2001) 05:30" ||
      moment(new Date(sessionStartTime)).format("(DD-MM-YYYY) HH:mm") ===
        "(01-01-2001) 05:30"
    ) {
      setWaitingTime("N/A");
      setSessionTime("N/A");
    } else {
      const startedSessionMoment = moment(sessionStartTime);
      const checkInMoment = moment(checkInTime);
      const completedSessionMoment = moment(sessionCompleteTime);

      // Calculate waiting time in minutes, rounded to the nearest minute
      let waitingTimeMinutes = Math.round(
        startedSessionMoment.diff(checkInMoment, "minutes", true)
      );
      waitingTimeMinutes = waitingTimeMinutes < 0 ? 0 : waitingTimeMinutes;

      const waitingHours = Math.floor(waitingTimeMinutes / 60);
      const waitingMins = waitingTimeMinutes % 60;
      const formattedWaitingTime = `${
        waitingHours > 0 ? `${waitingHours}hrs ` : ""
      }${waitingMins}mins`;

      // Calculate session time in minutes, rounded to the nearest minute
      let sessionTimeMinutes = Math.round(
        completedSessionMoment.diff(startedSessionMoment, "minutes", true)
      );
      sessionTimeMinutes = sessionTimeMinutes < 0 ? 0 : sessionTimeMinutes;

      const sessionHours = Math.floor(sessionTimeMinutes / 60);
      const sessionMins = sessionTimeMinutes % 60;
      const formattedSessionTime = `${
        sessionHours > 0 ? `${sessionHours}hrs ` : ""
      }${sessionMins}mins`;

      setWaitingTime(formattedWaitingTime);
      setSessionTime(formattedSessionTime);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="flex flex-row w-full justify-between mx-4 my-2">
          <h4 className="text-md leading-6 font-bold text-gray-900">
            View Appointment Details
          </h4>
        </div>
        <hr className="w-full" />
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {!loading && !error && props.appointment && (
          <div>
            <div className="flex flex-col w-full h-full px-4 py-2 space-y-2">
              <div className="w-full mb-4 sm:mb-0">
                <div className="flex flex-col">
                  <h1 className="text-lg font-semibold">Patient Information</h1>
                  <span className="text-sm">
                    {props.appointment?.clientName}
                  </span>
                  <span className="text-sm">
                    Contact Number:{" "}
                    {userData?.user_role === "ENTITY_OWNER" ||
                    userData?.user_role === "PLATFORM_ADMIN" ? (
                      <span>{props.appointment?.clientPhone}</span>
                    ) : props.appointment?.clientPhone.toString().length > 4 ? (
                      <span>{props.appointment?.clientPhone}</span>
                    ) : (
                      <span>******{props.appointment?.clientPhone}</span>
                    )}
                  </span>
                </div>
              </div>
              <div className="w-full">
                <div className="flex flex-col">
                  <h1 className="text-lg font-semibold">
                    Appointment Information
                  </h1>
                  <span className="text-sm">
                    Date:{" "}
                    {moment(props.appointment?.apptdate).format("DD-MM-YYYY")}
                  </span>
                  <span className="text-sm">
                    Slot: {props.appointment?.startTime}
                  </span>
                  <span className="text-sm">
                    Type: {props.appointment?.appointmentType}
                  </span>
                  <span>
                    Check in time:{" "}
                    {moment(new Date(props.appointment?.checkInTime)).format(
                      "(DD-MM-YYYY) HH:mm"
                    ) === "(01-01-2001) 05:30"
                      ? "N/A"
                      : moment(new Date(props.appointment?.checkInTime)).format(
                          "(DD-MM-YYYY) hh:mm A"
                        )}
                  </span>
                  <span>
                    Session start time:{" "}
                    {moment(
                      new Date(props.appointment?.sessionStartTime)
                    ).format("(DD-MM-YYYY) HH:mm") === "(01-01-2001) 05:30"
                      ? "N/A"
                      : moment(
                          new Date(props.appointment?.sessionStartTime)
                        ).format("(DD-MM-YYYY) hh:mm A")}
                  </span>
                  <span>
                    Session completed time:{" "}
                    {moment(
                      new Date(props.appointment?.sessionCompleteTime)
                    ).format("(DD-MM-YYYY) HH:mm") === "(01-01-2001) 05:30"
                      ? "N/A"
                      : moment(
                          new Date(props.appointment?.sessionCompleteTime)
                        ).format("(DD-MM-YYYY) hh:mm A")}
                  </span>
                </div>
                <div className="flex flex-row mt-2 w-full justify-center space-x-2">
                  <div className="flex items-center p-4 bg-white rounded-xl shadow-xs border border-1 w-1/2">
                    <div>
                      <p className="mb-2 text-sm font-medium text-gray-600 ">
                        Waiting Time
                      </p>
                      <p className="text-lg font-semibold text-gray-700 ">
                        {waitingTime}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center p-4 bg-white rounded-xl shadow-xs border border-1 w-1/2">
                    <div>
                      <p className="mb-2 text-sm font-medium text-gray-600 ">
                        Session Time
                      </p>
                      <p className="text-lg font-semibold text-gray-700 ">
                        {sessionTime}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <h1 className="text-lg font-semibold">
                  Consulting Physiotherapist
                </h1>
                <span className="text-sm">
                  {props.appointment?.entityUserName}
                </span>
              </div>

              <div className="flex flex-col">
                <h1 className="text-lg font-semibold">
                  Additional Information
                </h1>
                <span className="text-xs">
                  Private note specific to the appointment for Physiotherapist
                  reference
                </span>
                <span className="text-sm">
                  {props.appointment?.notes === ""
                    ? "No additional information provided"
                    : props.appointment?.notes}
                </span>
              </div>
            </div>

            <div className="flex flex-row w-full mt-4 p-4 bg-gray-100 justify-end">
              <OutlinedButton
                variant={ButtonVariant.SECONDARY}
                type="button"
                onClick={closeModal}
                children="Close"
                className="mb-2 sm:mr-2"
              />
              {props.appointment?.appointmentStatus === "Scheduled" && (
                <OutlinedButton
                  variant={ButtonVariant.SECONDARY}
                  type="button"
                  onClick={() => {
                    closeModal();
                    props.setCancelDialog(true);
                  }}
                  children="Cancel Appointment"
                  className="mb-2 sm:mr-2"
                />
              )}
              {props.appointment?.appointmentStatus === "Scheduled" && (
                <OutlinedButton
                  variant={ButtonVariant.SECONDARY}
                  type="button"
                  onClick={() => {
                    closeModal();
                    props.setRescheduleAppointmentDialog(true);
                  }}
                  children="Reschedule"
                  className="mb-2 sm:mr-2"
                />
              )}
              {props.appointment?.appointmentStatus === "Scheduled" && (
                <OutlinedButton
                  variant={ButtonVariant.SECONDARY}
                  type="button"
                  onClick={() => {
                    closeModal();
                    props.setTransferAppointmentDialog(true);
                  }}
                  children="Transfer"
                  className="mb-2 sm:mr-2"
                />
              )}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

export function CancelAppointmentDialog(props: {
  setCancelDialog: React.Dispatch<React.SetStateAction<boolean>>;
  appId: string;
  getAppointments: () => Promise<void>;
}) {
  let [isOpen, setIsOpen] = useState(true);
  const [cancellationReason, setCancellationReason] = useState("");
  const axios = useAxios();
  const navigator = useNavigate();
  function closeModal() {
    setIsOpen(false);
    props.setCancelDialog(false);
  }

  const cancelAppointment = async () => {
    try {
      const paramsMap = new Map<string, string>([["id", props.appId]]);
      const filteredEndpoint = replaceParams(
        endpoints.appointment.cancelAppointment,
        paramsMap
      );
      await axios.post(filteredEndpoint, {
        cancellationReason: cancellationReason,
      });
      closeModal();
      props.getAppointments();
      toast.success("Appointment Cancelled Successfully!");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="flex flex-row w-full justify-between mx-4 my-2">
          <h3 className="text-md leading-6 font-bold text-gray-900">
            Confirmation
          </h3>
        </div>
        <hr className="w-full" />
        <div className="flex flex-col w-full h-full px-4 py-2 space-y-2">
          <label>Specify the reason to cancel the appointment</label>
          <p className="text-sm text-gray-500 mt-2">
            <textarea
              id="message"
              onChange={(e) => setCancellationReason(e.target.value)}
              className="mt-2 block p-2.5 lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
            ></textarea>
          </p>
        </div>

        <div className="flex flex-row w-full mt-4 p-4 bg-gray-100 justify-end">
          <OutlinedButton
            variant={ButtonVariant.PRIMARY}
            type="button"
            onClick={closeModal}
            children="Close"
            className="mr-2"
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            type="button"
            onClick={cancelAppointment}
            children="Cancel Appointment"
            className="mr-2"
          />
        </div>
      </Modal>
    </>
  );
}
