// To parse this data:
//
//   import { Convert, ReferredDocsAndHospitals } from "./file";
//
//   const referredDocsAndHospitals = Convert.toReferredDocsAndHospitals(json);

export type ReferredDocsAndHospitals = {
  ReferringHospitals?: Referring[];
  ReferringUsers?: Referring[];
  OtherReferrals?: Referring[];
};

export type Referring = {
  id?: string;
  referralCode?: string;
  referralName?: string;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toReferredDocsAndHospitals(
    json: string
  ): ReferredDocsAndHospitals {
    return JSON.parse(json);
  }

  public static referredDocsAndHospitalsToJson(
    value: ReferredDocsAndHospitals
  ): string {
    return JSON.stringify(value);
  }
}
