import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import React from 'react';
import { mainRoutes } from './general';
import { authRoutes } from './auth';
import { securedRoutes } from './secured';
import { paths } from '@routes/paths';

let Router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Navigate to={paths.dashboard.route} />} />
      {mainRoutes}
      {authRoutes}
      {securedRoutes}
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>,
  ),
);

export default Router;
