export const generateForm = (masterAssessmentId: string) => {
  const myAssessment = {
    id: '',
    masterAssessmentId: masterAssessmentId,
    clientId: '',
    entityId: '',
    entityBranchId: '',
    hipFlexorsLeft: 0,
    hipFlexorsRight: 0,
    hipExtensorsLeft: 0,
    hipExtensorsRight: 0,
    hipAbductorsLeft: 0,
    hipAbductorsRight: 0,
    hipAdductorsLeft: 0,
    hipAdductorsRight: 0,
    hipMedialRotationLeft: 0,
    hipMedialRotationRight: 0,
    hipLateralRotationLeft: 0,
    hipLateralRotationRight: 0,
    shoulderFlexorsLeft: 0,
    shoulderFlexorsRight: 0,
    shoulderExtensorsLeft: 0,
    shoulderExtensorsRight: 0,
    shoulderAbductorsLeft: 0,
    shoulderAbductorsRight: 0,
    shoulderAdductorsLeft: 0,
    shoulderAdductorsRight: 0,
    shoulderMedialRotationLeft: 0,
    shoulderMedialRotationRight: 0,
    shoulderLateralRotationLeft: 0,
    shoulderLateralRotationRight: 0,
    shoulderElevatorsLeft: 0,
    shoulderElevatorsRight: 0,
    shoulderDepressorsLeft: 0,
    shoulderDepressorsRight: 0,
    shoulderAntepulsorsLeft: 0,
    shoulderAntepulsorsRight: 0,
    shoulderRetropulsorsLeft: 0,
    shoulderRetropulsorsRight: 0,
    kneeFlexorsLeft: 0,
    kneeFlexorsRight: 0,
    kneeExtensorsLeft: 0,
    kneeExtensorsRight: 0,
    elbowFlexorsLeft: 0,
    elbowFlexorsRight: 0,
    elbowExtensorsLeft: 0,
    elbowExtensorsRight: 0,
    ankleDorsiFlexorsLeft: 0,
    ankleDorsiFlexorsRight: 0,
    anklePlantarFlexorsLeft: 0,
    anklePlantarFlexorsRight: 0,
    ankleInversorsLeft: 0,
    ankleInversorsRight: 0,
    ankleEversorsLeft: 0,
    ankleEversorsRight: 0,
    forearmPronatorsLeft: 0,
    forearmPronatorsRight: 0,
    forearmSupinatorsLeft: 0,
    forearmSupinatorsRight: 0,
    footFlexorsLeft: 0,
    footFlexorsRight: 0,
    footExtensorsLeft: 0,
    footExtensorsRight: 0,
    wristFlexorsLeft: 0,
    wristFlexorsRight: 0,
    wristExtensorsLeft: 0,
    wristExtensorsRight: 0,
    trunkFlexorsLeft: 0,
    trunkFlexorsRight: 0,
    trunkExtensorLeft: 0,
    trunkExtensorRight: 0,
    trunkRBendingLeft: 0,
    trunkRBendingRight: 0,
    trunkLBendingLeft: 0,
    trunkLBendingRight: 0,
    trunkRRotationLeft: 0,
    trunkRRotationRight: 0,
    trunkLRotationLeft: 0,
    trunkLRotationRight: 0,
    fingersFlexorsLeft: 0,
    fingersFlexorsRight: 0,
    fingersExtensorsLeft: 0,
    fingersExtensorsRight: 0,
    fingersAbductorsLeft: 0,
    fingersAbductorsRight: 0,
    fingersOppositionLeft: 0,
    fingersOppositionRight: 0,
  };
  return myAssessment;
};
