import { Button, OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import InputField from "@components/hook-form/input-field";
import React, { useEffect, useState } from "react";
import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import { Convert, PainAssessment } from "./types/PainAssessment";
import * as z from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { Box, Slider } from "@mui/material";

export const PainAssessmentSchema = z.object({
  id: z.string().optional().default(""),
  masterAssessmentId: z.string().optional().default(""),
  clientId: z.string().optional().default(""),
  entityId: z.string().optional().default(""),
  entityBranchId: z.string().optional().default(""),
  onset: z.number().optional().default(0),
  progression: z.string().optional().default(""),
  painIntensity: z.number().optional().default(0),
  painSite: z.string().optional().default(""),
  painSide: z.string().optional().default(""),
  constantIntermittentLeft: z.boolean().optional().default(false),
  constantIntermittentRight: z.boolean().optional().default(false),
  sharpLeft: z.boolean().optional().default(false),
  sharpRight: z.boolean().optional().default(false),
  shootingLeft: z.boolean().optional().default(false),
  shootingRight: z.boolean().optional().default(false),
  burningLeft: z.boolean().optional().default(false),
  burningRight: z.boolean().optional().default(false),
  throbbingLeft: z.boolean().optional().default(false),
  throbbingRight: z.boolean().optional().default(false),
  numbnessLeft: z.boolean().optional().default(false),
  numbnessRight: z.boolean().optional().default(false),
  pinsAndNeedlesLeft: z.boolean().optional().default(false),
  pinsAndNeedlesRight: z.boolean().optional().default(false),
  aggravatingFactors: z.string().optional().default(""),
  relievingFactors: z.string().optional().default(""),
  activityLimitation: z.string().optional().default(""),
  headPainLeft: z.boolean().optional().default(false),
  headPainRight: z.boolean().optional().default(false),
  neckPainLeft: z.boolean().optional().default(false),
  neckPainRight: z.boolean().optional().default(false),
  axillaPainLeft: z.boolean().optional().default(false),
  axillaPainRight: z.boolean().optional().default(false),
  scapulaPainLeft: z.boolean().optional().default(false),
  scapulaPainRight: z.boolean().optional().default(false),
  shoulderPainLeft: z.boolean().optional().default(false),
  shoulderPainRight: z.boolean().optional().default(false),
  armPainLeft: z.boolean().optional().default(false),
  armPainRight: z.boolean().optional().default(false),
  elbowPainLeft: z.boolean().optional().default(false),
  elbowPainRight: z.boolean().optional().default(false),
  forearmPainLeft: z.boolean().optional().default(false),
  forearmPainRight: z.boolean().optional().default(false),
  wristPainLeft: z.boolean().optional().default(false),
  wristPainRight: z.boolean().optional().default(false),
  thumbPainLeft: z.boolean().optional().default(false),
  thumbPainRight: z.boolean().optional().default(false),
  palmPainLeft: z.boolean().optional().default(false),
  palmPainRight: z.boolean().optional().default(false),
  handPainLeft: z.boolean().optional().default(false),
  handPainRight: z.boolean().optional().default(false),
  chestPainLeft: z.boolean().optional().default(false),
  chestPainRight: z.boolean().optional().default(false),
  backPainLeft: z.boolean().optional().default(false),
  backPainRight: z.boolean().optional().default(false),
  sacrolliacPainLeft: z.boolean().optional().default(false),
  sacrolliacPainRight: z.boolean().optional().default(false),
  hipPainLeft: z.boolean().optional().default(false),
  hipPainRight: z.boolean().optional().default(false),
  thighPainLeft: z.boolean().optional().default(false),
  thighPainRight: z.boolean().optional().default(false),
  iTBandPainLeft: z.boolean().optional().default(false),
  iTBandPainRight: z.boolean().optional().default(false),
  kneePainLeft: z.boolean().optional().default(false),
  kneePainRight: z.boolean().optional().default(false),
  shinPainLeft: z.boolean().optional().default(false),
  shinPainRight: z.boolean().optional().default(false),
  anklePainLeft: z.boolean().optional().default(false),
  anklePainRight: z.boolean().optional().default(false),
  heelPainLeft: z.boolean().optional().default(false),
  heelPainRight: z.boolean().optional().default(false),
  greatToePainLeft: z.boolean().optional().default(false),
  greatToePainRight: z.boolean().optional().default(false),
  stiffnessMorning: z.boolean().optional().default(false),
  stiffnessEvening: z.boolean().optional().default(false),
  stiffnessNight: z.boolean().optional().default(false),
  stiffnessWholeDay: z.boolean().optional().default(false),
  sleepDisturbed: z.boolean().optional().default(false),
  sleepNormal: z.boolean().optional().default(false),
  travellingTwoWheeler: z.boolean().optional().default(false),
  travellingFourWheeler: z.boolean().optional().default(false),
  travellingPublicTransport: z.boolean().optional().default(false),
  footPainLeft: z.boolean().optional().default(false),
  footPainRight: z.boolean().optional().default(false),
  dailyTravelKm: z.number().optional().default(0),
});
export type PainAssessmentType = z.infer<typeof PainAssessmentSchema>;

type PainAssessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

function PainAssessmentComponent(props: PainAssessmentProps) {
  const [painAssessment, setPainAssessment] = useState<PainAssessment | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [isEditable, setIsEditable] = useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [error, setError] = useState("");
  const axios = useAxios();
  const [intensity, setIntensity] = useState(0);
  const [dailyKM, setDailyKM] = useState<Number | null>(null);
  const userData = getUserPersistedOnLocalStorage();

  const methods = useForm<PainAssessmentType>({
    resolver: zodResolver(PainAssessmentSchema),
  });

  useEffect(() => {
    const initializePainAssessment = async () => {
      if (props.assessmentId !== "000000000000000000000000") {
        setIsEditable(false);
        try {
          const paramsMap = new Map<string, string>([
            ["mid", props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getPainAssessment,
            paramsMap
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data["data"];

          const convertedPainAssessment = Convert.toPainAssessment(
            JSON.stringify(data)
          );
          setPainAssessment(convertedPainAssessment);

          setIntensity(convertedPainAssessment.painIntensity ?? 0);
          setDailyKM(convertedPainAssessment.dailyTravelKm ?? 0);
          methods.reset({ ...convertedPainAssessment });
          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching pain assessment:", error);
          setError(error.message);
          setLoading(false);
        }
      }
    };

    initializePainAssessment();
  }, []);

  const onSubmit: SubmitHandler<PainAssessmentType> = async (data) => {
    methods.trigger();

    if (props.assessmentId !== "000000000000000000000000") {
      try {
        data.painIntensity = intensity;
        data.id = props.assessmentId;
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? "";
        data.entityBranchId = userData?.branch_id ?? "";

        const paramsMap = new Map<string, string>([
          ["id", props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updatePainAssessment,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, data);

        props.postSubmitCallback();
        toast.success("Pain Assessment Updated");
      } catch (error) {
        console.error("Error submitting form:", error);
        setError(error.response.data["data"].toString());
      }
    } else {
      try {
        data.painIntensity = intensity;
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? "";
        data.entityBranchId = userData?.branch_id ?? "";

        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createPainAssessment,
          paramsMap
        );
        const response = await axios.post(filteredEndpoint, data);

        props.postSubmitCallback();
        toast.success("Pain Assessment Created");
      } catch (error) {
        console.error("Error submitting form:", error);
        setError(error.response.data["data"].toString());
      }
    }
  };

  function generatePainSiteOptions() {
    const options = [
      { value: "None", label: "None" },
      { value: "Head", label: "Head" },
      { value: "Neck", label: "Neck" },
      { value: "Shoulder", label: "Shoulder" },
      { value: "Wrist", label: "Wrist" },
      { value: "Hand", label: "Hand" },
      { value: "Elbow", label: "Elbow" },
      { value: "Chest", label: "Chest" },
      { value: "Back", label: "Back" },
      { value: "Hip", label: "Hip" },
      { value: "Knee", label: "Knee" },
      { value: "Ankle", label: "Ankle" },
      { value: "Foot", label: "Foot" },
    ];

    return options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));
  }

  function generatePainSideOptions() {
    const options = [
      { value: "None", label: "None" },
      { value: "Right", label: "Right" },
      { value: "Left", label: "Left" },
      { value: "Both", label: "Both" },
    ];

    return options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));
  }

  function generateProgressionOptions() {
    const options = [
      { value: "Improved", label: "Improved" },
      { value: "Stationary", label: "Stationary" },
      { value: "Deteriorated", label: "Deteriorated" },
    ];

    return options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));
  }

  const [mood, setMood] = useState<string | null>(null);

  // Define marks for the Slider
  const marks = [
    {
      value: 0,
      label: "",
    },
    {
      value: 0.5,
      label: "no pain",
    },
    {
      value: 1,
      label: "",
    },
    {
      value: 2,
      label: "mild",
    },
    {
      value: 3,
      label: "",
    },
    {
      value: 4,
      label: "moderate",
    },
    {
      value: 5,
      label: "",
    },
    {
      value: 6,
      label: "severe",
    },
    {
      value: 7,
      label: "",
    },
    {
      value: 8,
      label: "very severe",
    },
    {
      value: 9,
      label: "",
    },
    {
      value: 9.5,
      label: "worst",
    },
    {
      value: 10,
      label: "",
    },
  ];

  const handleChange = (event: any, newValue: any) => {
    if (newValue == -1) {
      setIntensity(0);
    }
    setIntensity(newValue);

    // Update mood based on intensity
    setMoodForIntensity(intensity);
  };

  useEffect(() => {
    // Update mood based on intensity
    setMoodForIntensity(intensity);
  }, [intensity]);

  function setMoodForIntensity(intensity: any) {
    if (intensity <= 1) {
      setMood("😃");
    } else if (intensity > 1 && intensity <= 3) {
      setMood("🙂");
    } else if (intensity > 3 && intensity <= 5) {
      setMood("😑");
    } else if (intensity > 5 && intensity <= 7) {
      setMood("🙁");
    } else if (intensity > 7 && intensity <= 9) {
      setMood("😣");
    } else if (intensity > 9) {
      setMood("😢");
    }
  }

  const onSleepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (checked) {
      const keys = ["sleepDisturbed", "sleepNormal"];
      keys.forEach((key) => {
        if (key !== name) {
          methods.setValue(key, false);
        }
      });
    }
    methods.setValue(name, checked);
  };

  return (
    <>
      <div className="flex flex-col h-full w-full">
        <div className="flex flex-row w-full justify-between mx-4 my-2">
          <h3 className="text-md leading-6 font-bold text-gray-900">
            Pain Assessment
          </h3>
        </div>
        <hr className="w-full" />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <table className="table-auto  text-left text-sm border-collapse border border-slate-400 w-full">
              <tbody>
                <tr className="border  border-slate-300">
                  <td className="px-2 py-4 w-1/4 border border-right border-slate-300">
                    Progression
                  </td>
                  <td className="flex justify-center px-2 py-4 ">
                    <InputField
                      type="select"
                      generateOptions={generateProgressionOptions}
                      name="progression"
                      className="lock w-1/2 rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                      disabled={isDisabled}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="flex flex-col mt-6 border border-slate-300">
              <div className="border px-2 py-4 w-full">Pain Intensity</div>
              <div className="flex justify-center px-2 py-8 border border-top border-slate-300 items-center">
                <Box sx={{ width: 500 }}>
                  <Slider
                    aria-label="Custom marks"
                    value={intensity}
                    onChange={handleChange}
                    step={1}
                    min={0}
                    max={10}
                    marks={marks}
                    disabled={isDisabled}
                    valueLabelDisplay="on"
                    sx={{
                      color: "transparent",
                      height: 14,
                      "& .MuiSlider-track": {
                        height: 14,
                        background: "transparent",
                      },
                      "& .MuiSlider-rail": {
                        height: 14,
                        background: "linear-gradient(to right, green, red)",
                        maxHeight: 14,
                      },
                      "& .MuiSlider-thumb": {
                        height: 24,
                        width: 24,
                        backgroundColor: "white",
                        border: "2px solid green",
                      },
                    }}
                  />
                </Box>
                <div className="flex flex-row w-14 ml-6">
                  <p style={{ fontSize: "35px" }}>{mood}</p>
                </div>
              </div>
            </div>

            <div className="flex flex-row w-full bg-orange-400 text-sm">
              <div className="w-1/2 my-4 mr-2 bg-green-200 border border-slate-300">
                <div className="table w-full">
                  <div className="table-header-group">
                    <div className="table-row">
                      <div className="table-cell text-left px-2 py-4 border border-slate-300 font-bold">
                        Pain Area
                      </div>
                      <div className="table-cell text-center px-2 py-4 border border-slate-300 font-bold">
                        Left
                      </div>
                      <div className="table-cell text-center px-2 py-4 border border-slate-300 font-bold">
                        Right
                      </div>
                    </div>
                  </div>
                  <div className="table-row-group">
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Head
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("headPainLeft")}
                          name="headPainLeft"
                          type="checkbox"
                          checked={methods.watch("headPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("headPainRight")}
                          name="headPainRight"
                          type="checkbox"
                          checked={methods.watch("headPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Neck
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("neckPainLeft")}
                          name="neckPainLeft"
                          type="checkbox"
                          checked={methods.watch("neckPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("neckPainRight")}
                          name="neckPainRight"
                          type="checkbox"
                          checked={methods.watch("neckPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Axilla
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("axillaPainLeft")}
                          name="axillaPainLeft"
                          type="checkbox"
                          checked={methods.watch("axillaPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("axillaPainRight")}
                          name="axillaPainRight"
                          type="checkbox"
                          checked={methods.watch("axillaPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Scapula
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("scapulaPainLeft")}
                          name="scapulaPainLeft"
                          type="checkbox"
                          checked={methods.watch("scapulaPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("scapulaPainRight")}
                          name="scapulaPainRight"
                          type="checkbox"
                          checked={methods.watch("scapulaPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Shoulder
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("shoulderPainLeft")}
                          name="shoulderPainLeft"
                          type="checkbox"
                          checked={methods.watch("shoulderPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("shoulderPainRight")}
                          name="shoulderPainRight"
                          type="checkbox"
                          checked={methods.watch("shoulderPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Arm
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("armPainLeft")}
                          name="armPainLeft"
                          type="checkbox"
                          checked={methods.watch("armPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("armPainRight")}
                          name="armPainRight"
                          type="checkbox"
                          checked={methods.watch("armPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Elbow
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("elbowPainLeft")}
                          name="elbowPainLeft"
                          type="checkbox"
                          checked={methods.watch("elbowPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("elbowPainRight")}
                          name="elbowPainRight"
                          type="checkbox"
                          checked={methods.watch("elbowPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Forearm
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("forearmPainLeft")}
                          name="forearmPainLeft"
                          type="checkbox"
                          checked={methods.watch("forearmPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("forearmPainRight")}
                          name="forearmPainRight"
                          type="checkbox"
                          checked={methods.watch("forearmPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Wrist
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("wristPainLeft")}
                          name="wristPainLeft"
                          type="checkbox"
                          checked={methods.watch("wristPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("wristPainRight")}
                          name="wristPainRight"
                          type="checkbox"
                          checked={methods.watch("wristPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Thumb
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("thumbPainLeft")}
                          name="thumbPainLeft"
                          type="checkbox"
                          checked={methods.watch("thumbPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("thumbPainRight")}
                          name="thumbPainRight"
                          type="checkbox"
                          checked={methods.watch("thumbPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Palm
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("palmPainLeft")}
                          name="palmPainLeft"
                          type="checkbox"
                          checked={methods.watch("palmPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("palmPainRight")}
                          name="palmPainRight"
                          type="checkbox"
                          checked={methods.watch("palmPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Hand
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("handPainLeft")}
                          name="handPainLeft"
                          type="checkbox"
                          checked={methods.watch("handPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("handPainRight")}
                          name="handPainRight"
                          type="checkbox"
                          checked={methods.watch("handPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* .................................................................................. */}
              <div className="w-1/2 my-4 ml-2 bg-green-200 border border-slate-300">
                <div className="table w-full">
                  <div className="table-header-group">
                    <div className="table-row">
                      <div className="table-cell text-left px-2 py-4 border border-slate-300 font-bold">
                        Pain Area
                      </div>
                      <div className="table-cell text-left px-2 py-4 border border-slate-300 font-bold text-center">
                        Left
                      </div>
                      <div className="table-cell text-left px-2 py-4 border border-slate-300 font-bold text-center">
                        Right
                      </div>
                    </div>
                  </div>
                  <div className="table-row-group">
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Chest
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("chestPainLeft")}
                          name="chestPainLeft"
                          type="checkbox"
                          checked={methods.watch("chestPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("chestPainRight")}
                          name="chestPainRight"
                          type="checkbox"
                          checked={methods.watch("chestPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Back
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("backPainLeft")}
                          name="backPainLeft"
                          type="checkbox"
                          checked={methods.watch("backPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("backPainRight")}
                          name="backPainRight"
                          type="checkbox"
                          checked={methods.watch("backPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Sacrolliac
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("sacrolliacPainLeft")}
                          name="sacrolliacPainLeft"
                          type="checkbox"
                          checked={methods.watch("sacrolliacPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("sacrolliacPainRight")}
                          name="sacrolliacPainRight"
                          type="checkbox"
                          checked={methods.watch("sacrolliacPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Hip
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("hipPainLeft")}
                          name="hipPainLeft"
                          type="checkbox"
                          checked={methods.watch("hipPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("hipPainRight")}
                          name="hipPainRight"
                          type="checkbox"
                          checked={methods.watch("hipPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Thigh
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("thighPainLeft")}
                          name="thighPainLeft"
                          type="checkbox"
                          checked={methods.watch("thighPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("thighPainRight")}
                          name="thighPainRight"
                          type="checkbox"
                          checked={methods.watch("thighPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        I-T Band
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("iTBandPainLeft")}
                          name="iTBandPainLeft"
                          type="checkbox"
                          checked={methods.watch("iTBandPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("iTBandPainRight")}
                          name="iTBandPainRight"
                          type="checkbox"
                          checked={methods.watch("iTBandPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Knee
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("kneePainLeft")}
                          name="kneePainLeft"
                          type="checkbox"
                          checked={methods.watch("kneePainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("kneePainRight")}
                          name="kneePainRight"
                          type="checkbox"
                          checked={methods.watch("kneePainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Shin
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("shinPainLeft")}
                          name="shinPainLeft"
                          type="checkbox"
                          checked={methods.watch("shinPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("shinPainRight")}
                          name="shinPainRight"
                          type="checkbox"
                          checked={methods.watch("shinPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Ankle
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("anklePainLeft")}
                          name="anklePainLeft"
                          type="checkbox"
                          checked={methods.watch("anklePainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("anklePainRight")}
                          name="anklePainRight"
                          type="checkbox"
                          checked={methods.watch("anklePainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Heel
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("heelPainLeft")}
                          name="heelPainLeft"
                          type="checkbox"
                          checked={methods.watch("heelPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("heelPainRight")}
                          name="heelPainRight"
                          type="checkbox"
                          checked={methods.watch("heelPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Great Toe
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("greatToePainLeft")}
                          name="greatToePainLeft"
                          type="checkbox"
                          checked={methods.watch("greatToePainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("greatToePainRight")}
                          name="greatToePainRight"
                          type="checkbox"
                          checked={methods.watch("greatToePainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell px-2 py-4 border border-slate-300">
                        Foot
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("footPainLeft")}
                          name="footPainLeft"
                          type="checkbox"
                          checked={methods.watch("footPainLeft")}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className="table-cell px-2 py-4 border border-slate-300 text-center">
                        <input
                          {...methods.register("footPainRight")}
                          name="footPainRight"
                          type="checkbox"
                          checked={methods.watch("footPainRight")}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-4 text-sm">
              <label
                htmlFor="Stiffness"
                className="block text-sm font-medium text-gray-700 col-span-4"
              >
                Stiffness
              </label>

              <div className="flex flex-row items-center space-x-2">
                <input
                  {...methods.register("stiffnessMorning")}
                  name="stiffnessMorning"
                  type="checkbox"
                  checked={methods.watch("stiffnessMorning")}
                  disabled={isDisabled}
                />
                <p>Morning</p>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <input
                  {...methods.register("stiffnessEvening")}
                  name="stiffnessEvening"
                  type="checkbox"
                  checked={methods.watch("stiffnessEvening")}
                  disabled={isDisabled}
                />
                <p>Evening</p>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <input
                  {...methods.register("stiffnessNight")}
                  name="stiffnessNight"
                  type="checkbox"
                  checked={methods.watch("stiffnessNight")}
                  disabled={isDisabled}
                />
                <p>Night</p>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <input
                  {...methods.register("stiffnessWholeDay")}
                  name="stiffnessWholeDay"
                  type="checkbox"
                  checked={methods.watch("stiffnessWholeDay")}
                  disabled={isDisabled}
                />
                <p>Whole Day</p>
              </div>

              <label
                htmlFor="Sleep"
                className="block text-sm font-medium text-gray-700 col-span-4"
              >
                Sleep
              </label>

              <div className="flex flex-row items-center space-x-2">
                <input
                  {...methods.register("sleepDisturbed")}
                  name="sleepDisturbed"
                  type="checkbox"
                  checked={methods.watch("sleepDisturbed")}
                  disabled={isDisabled}
                  onChange={onSleepChange}
                />
                <p>Disturbed</p>
              </div>
              <div className="flex flex-row col-span-3 items-center space-x-2">
                <input
                  {...methods.register("sleepNormal")}
                  name="sleepNormal"
                  type="checkbox"
                  checked={methods.watch("sleepNormal")}
                  disabled={isDisabled}
                  onChange={onSleepChange}
                />
                <p>Normal</p>
              </div>

              <label
                htmlFor="Travelling"
                className="block text-sm font-medium text-gray-700 col-span-4"
              >
                Travelling
              </label>

              <div className="flex flex-row items-center space-x-2">
                <input
                  {...methods.register("travellingTwoWheeler")}
                  name="travellingTwoWheeler"
                  type="checkbox"
                  checked={methods.watch("travellingTwoWheeler")}
                  disabled={isDisabled}
                />
                <p>2 Wheeler</p>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <input
                  {...methods.register("travellingFourWheeler")}
                  name="travellingFourWheeler"
                  type="checkbox"
                  checked={methods.watch("travellingFourWheeler")}
                  disabled={isDisabled}
                />
                <p>4 Wheeler</p>
              </div>
              <div className="flex flex-row col-span-2 items-center space-x-2">
                <input
                  {...methods.register("travellingPublicTransport")}
                  name="travellingPublicTransport"
                  type="checkbox"
                  checked={methods.watch("travellingPublicTransport")}
                  disabled={isDisabled}
                />
                <p>Public Transport</p>
              </div>
            </div>

            <div className="flex flex-row items-center mt-6">
              <p className="text-md mr-2">Daily Kilometers Travelled - </p>
              <InputField
                placeholder="Daily Travel Kilometers"
                type="number"
                value={Number(dailyKM)}
                onChange={(e) => {
                  setDailyKM(Number(e.target.value));
                }}
                name="dailyTravelKm"
                className="w-full rounded-lg border-2 border-gray-300 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm leading-6"
                disabled={isDisabled}
              />
            </div>

            <table className="table-auto  text-left text-sm border-collapse border border-slate-400 w-full mt-5">
              <thead className="bg-gray-50">
                <tr>
                  <th className="border lg:px-6 px-2 py-4 border-slate-300 text-center">
                    Pain Type
                  </th>
                  <th className="border lg:px-6 px-2 py-4 border-slate-300 text-center">
                    Left{" "}
                  </th>
                  <th className="border lg:px-6 px-2 py-4 border-slate-300 text-center">
                    Right{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  key={"ConstantIntermittent"}
                  className="border border-slate-300"
                >
                  <td className="px-2 py-4 border border-right border-slate-300">
                    Constant Intermittent
                  </td>
                  <td className="px-2 py-4 flex justify-center">
                    <input
                      {...methods.register("constantIntermittentLeft")}
                      name={`constantIntermittentLeft`}
                      type="checkbox"
                      checked={methods.watch("constantIntermittentLeft")}
                      disabled={isDisabled}
                    />
                  </td>
                  <td className="px-2 py-4 text-center border border-left border-slate-300">
                    <input
                      {...methods.register("constantIntermittentRight")}
                      name={`constantIntermittentRight`}
                      type="checkbox"
                      checked={methods.watch("constantIntermittentRight")}
                      disabled={isDisabled}
                    />
                  </td>
                </tr>

                <tr key={"Sharp"} className="border border-slate-300">
                  <td className="px-2 py-4 border border-right border-slate-300">
                    Sharp
                  </td>
                  <td className="px-2 py-4 flex justify-center">
                    <input
                      {...methods.register("sharpLeft")}
                      name={`sharpLeft`}
                      type="checkbox"
                      checked={methods.watch("sharpLeft")}
                      disabled={isDisabled}
                    />
                  </td>
                  <td className="px-2 py-4 text-center border border-left border-slate-300">
                    <input
                      {...methods.register("sharpRight")}
                      name={`sharpRight`}
                      type="checkbox"
                      checked={methods.watch("sharpRight")}
                      disabled={isDisabled}
                    />
                  </td>
                </tr>

                <tr key={"Shooting"} className="border border-slate-300">
                  <td className="px-2 py-4 border border-right border-slate-300">
                    Shooting
                  </td>
                  <td className="px-2 py-4 flex justify-center">
                    <input
                      {...methods.register("shootingLeft")}
                      name={`shootingLeft`}
                      type="checkbox"
                      checked={methods.watch("shootingLeft")}
                      disabled={isDisabled}
                    />
                  </td>
                  <td className="px-2 py-4 text-center border border-left border-slate-300">
                    <input
                      {...methods.register("shootingRight")}
                      name={`shootingRight`}
                      type="checkbox"
                      checked={methods.watch("shootingRight")}
                      disabled={isDisabled}
                    />
                  </td>
                </tr>

                <tr key={"Burning"} className="border border-slate-300">
                  <td className="px-2 py-4 border border-right border-slate-300">
                    Burning
                  </td>
                  <td className="px-2 py-4 flex justify-center">
                    <input
                      {...methods.register("burningLeft")}
                      name={`burningLeft`}
                      type="checkbox"
                      checked={methods.watch("burningLeft")}
                      disabled={isDisabled}
                    />
                  </td>
                  <td className="px-2 py-4 text-center border border-left border-slate-300">
                    <input
                      {...methods.register("burningRight")}
                      name={`burningRight`}
                      type="checkbox"
                      checked={methods.watch("burningRight")}
                      disabled={isDisabled}
                    />
                  </td>
                </tr>

                <tr key={"Throbbing"} className="border border-slate-300">
                  <td className="px-2 py-4 border border-right border-slate-300">
                    Throbbing
                  </td>
                  <td className="px-2 py-4 flex justify-center">
                    <input
                      {...methods.register("throbbingLeft")}
                      name={`throbbingLeft`}
                      type="checkbox"
                      checked={methods.watch("throbbingLeft")}
                      disabled={isDisabled}
                    />
                  </td>
                  <td className="px-2 py-4 text-center border border-left border-slate-300">
                    <input
                      {...methods.register("throbbingRight")}
                      name={`throbbingRight`}
                      type="checkbox"
                      checked={methods.watch("throbbingRight")}
                      disabled={isDisabled}
                    />
                  </td>
                </tr>

                <tr key={"Numbness"} className="border border-slate-300">
                  <td className="px-2 py-4 border border-right border-slate-300">
                    Numbness
                  </td>
                  <td className="px-2 py-4 flex justify-center">
                    <input
                      {...methods.register("numbnessLeft")}
                      name={`numbnessLeft`}
                      type="checkbox"
                      checked={methods.watch("numbnessLeft")}
                      disabled={isDisabled}
                    />
                  </td>
                  <td className="px-2 py-4 text-center border border-left border-slate-300">
                    <input
                      {...methods.register("numbnessRight")}
                      name={`numbnessRight`}
                      type="checkbox"
                      checked={methods.watch("numbnessRight")}
                      disabled={isDisabled}
                    />
                  </td>
                </tr>

                <tr key={"PinsAndNeedles"} className="border border-slate-300">
                  <td className="px-2 py-4 border border-right border-slate-300">
                    Pins And Needles
                  </td>
                  <td className="px-2 py-4 flex justify-center">
                    <input
                      {...methods.register("pinsAndNeedlesLeft")}
                      name={`pinsAndNeedlesLeft`}
                      type="checkbox"
                      checked={methods.watch("pinsAndNeedlesLeft")}
                      disabled={isDisabled}
                    />
                  </td>
                  <td className="px-2 py-4 text-center border border-left border-slate-300">
                    <input
                      {...methods.register("pinsAndNeedlesRight")}
                      name={`pinsAndNeedlesRight`}
                      type="checkbox"
                      checked={methods.watch("pinsAndNeedlesRight")}
                      disabled={isDisabled}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="mt-4">
              <div>
                <label
                  htmlFor="activityLimitation"
                  className="block text-sm font-medium text-gray-700"
                >
                  Activity Limitation
                </label>
                <InputField
                  placeholder="Activity Limitation"
                  type="textarea"
                  name="activityLimitation"
                  className="w-full rounded-lg border-2 border-gray-300 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm leading-6"
                  disabled={isDisabled}
                />
              </div>
              <div className="mt-2">
                <label
                  htmlFor="aggravatingFactors"
                  className="block text-sm font-medium text-gray-700"
                >
                  Aggravating Factors
                </label>
                <InputField
                  placeholder="Aggravating Factors"
                  name="aggravatingFactors"
                  type="textarea"
                  className="w-full rounded-lg border-2 border-gray-300 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm leading-6"
                  disabled={isDisabled}
                />
              </div>
              <div className="mt-2">
                <label
                  htmlFor="relievingFactors"
                  className="block text-sm font-medium text-gray-700"
                >
                  Relieving Factors
                </label>
                <InputField
                  placeholder="Relieving Factors"
                  name="relievingFactors"
                  type="textarea"
                  className="w-full rounded-lg border-2 border-gray-300 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm leading-6"
                />
              </div>
            </div>

            <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
              <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
                <OutlinedButton
                  type="button"
                  onClick={() => {
                    props.postSubmitCallback();
                  }}
                  className="mr-2"
                  variant={ButtonVariant.PRIMARY}
                >
                  Close
                </OutlinedButton>
                {isDisabled ? (
                  <Button
                    type="button"
                    key="EditButton"
                    onClick={() => {
                      setIsDisabled(false);
                    }}
                    variant={ButtonVariant.PRIMARY}
                  >
                    Edit
                  </Button>
                ) : props.assessmentId !== "000000000000000000000000" ? (
                  <Button
                    type="submit"
                    key="UpdateButton"
                    onClick={() => {}}
                    variant={ButtonVariant.PRIMARY}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    key="CreateButton"
                    onClick={() => {}}
                    variant={ButtonVariant.PRIMARY}
                  >
                    Create
                  </Button>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
}

export default PainAssessmentComponent;
