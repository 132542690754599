import { OutlinedButton } from "@components/button";
import Button, { ButtonVariant } from "@components/button/button";
import { Dialog, Transition } from "@headlessui/react";
import { endpoints } from "@utils/axios";
import axios from "axios";
import moment from "moment";

import React, { Fragment, useEffect } from "react";

function BranchChangePopUp(props: {
  branch_id: any;
  patientId: any;
  setBranchChangeDialog: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  let [isOpen, setIsOpen] = React.useState(true);
  let [apptCount, setAppCount] = React.useState(0);

  function closeModal() {
    props.setBranchChangeDialog(false);
    setIsOpen(false);
  }
  useEffect(() => {
    getPatientAppointments();
  });

  const getPatientAppointments = async () => {
    const response = await axios.get(endpoints.appointment.list, {
      params: {
        clientid: props.patientId,
        branchId: props.branch_id,
        requestedFromDate: moment().format("DD-MM-YYYY"),
        requestedToDate: moment().add(365, "days").format("DD-MM-YYYY"),
        requestedAppointmentStatus: "Scheduled",
      },
    });

    const data = response.data["data"];
    if (data) {
      setAppCount(data.length);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold leading-6 px-6 pt-6 text-gray-900"
                  >
                    Warning
                  </Dialog.Title>

                  <div className="max-h-[75vh] overflow-auto px-6">
                    {apptCount > 0 ? (
                      <div className="text-warning-700 mt-4">
                        There are {apptCount} future appointments for this
                        patient on existing branch. Please ensure to cancel
                        them. Go to "Appointment Details -&gt; View -&gt; Cancel
                        Appointment"
                      </div>
                    ) : (
                      <div className="mt-4">
                        You are transferring the patient to another branch.
                      </div>
                    )}
                  </div>
                  <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4 mt-2">
                    <div className="flex flex-row w-full justify-end">
                      <OutlinedButton
                        variant={ButtonVariant.PRIMARY}
                        type="button"
                        onClick={() => {
                          props.setBranchChangeDialog(false);
                          setIsOpen(false);
                        }}
                        children="Close"
                        className="mr-2"
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default BranchChangePopUp;
