import { useForm, SubmitHandler } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Button, { ButtonVariant } from '@components/button/button';
import { Convert, Exercise } from '../types/ExerciseType';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { endpoints, replaceParams } from '@utils/axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InputField, ValidationError } from '@components/hook-form';
import FileInputField from '@components/hook-form/file-field';
import ReactPlayer from 'react-player';
import { paths } from '@routes/paths';
import Applabel from '@components/hook-form/applabel';
import 'react-phone-input-2/lib/style.css';
import EConvert, { Entity } from '@pages/add-patient/types/EntityType';
import { getUserPersistedOnLocalStorage } from '@authentication/context/jwt/utils';
import { getValue } from '@testing-library/user-event/dist/utils';

export const DefaultExerciseConfigSchema = z.object({
  repetitions: z
    .union([
      z.coerce.number().int().nonnegative({
        message: 'Repetitions should be greater than or equal to zero',
      }),
      z.nan(),
    ])
    .optional(),
  sets: z
    .union([
      z.coerce.number().int().nonnegative({
        message: 'Sets should be greater than or equal to zero',
      }),
      z.nan(),
    ])
    .optional(),
  side: z.string().optional(),
  weights: z
    .union([
      z.coerce.number().int().nonnegative({
        message: 'Weights should be greater than or equal to zero',
      }),
      z.nan(),
    ])
    .optional(),
  interval: z
    .union([
      z.coerce.number().int().nonnegative({
        message: 'Interval should be greater than or equal to zero',
      }),
      z.nan(),
    ])
    .optional(),
  hold: z
    .union([
      z.coerce.number().int().nonnegative({
        message: 'Hold should be greater than or equal to zero',
      }),
      z.nan(),
    ])
    .optional(),
  dailyFrequency: z
    .union([
      z.coerce.number().int().min(1, { message: 'Daily Frequency should not be less than 1' }),
      z.nan(),
    ])
    .optional()
    .default(1),
  weightsUnit: z.string().optional(),
  intervalUnit: z.string().optional(),
  holdUnit: z.string().optional(),
});
export type DefaultExerciseConfig = z.infer<typeof DefaultExerciseConfigSchema>;

export const EntitySchema = z.object({
  id: z.string().optional().default(''),
  entityId: z.string().min(1, { message: 'Entity is required' }),
  title: z.string().min(1, { message: 'Title is required' }).max(360),
  instructions: z
    .string()
    .min(1, { message: 'Instruction is required' })
    .max(3000),
  difficulty: z.string().optional(),
  defaultExerciseConfig: DefaultExerciseConfigSchema,
  videoSource: z.string().optional(),
  youtubeLink: z.string().optional(),
  otherLink: z.string().optional(),
  uploadVideo: z.any().optional(),
  uploadImages: z.any().optional(),
  tags: z.any().array().optional().default([]),
  media: z.string().array().optional().default([]),
  includedInPlatformLibrary: z.boolean().optional(),
});

// Infer the TS type according to the zod schema.
export type EntitySchemaType = z.infer<typeof EntitySchema>;
const Tabs = ['Youtube', 'Upload Video', 'None'];

function AddExercisePage() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<EntitySchemaType>({ resolver: zodResolver(EntitySchema) });

  const [formData, setFormData] = React.useState<Exercise | null>(null); // Store form data here
  const [media, setMedia] = useState<any>([]);
  const [showMediaSection, setShowMediaSection] = useState(false);
  const navigator = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);

  const [entities, setEntities] = useState<Entity[] | null>(null);
  const [isPlatformAdmin, setIsPlatformAdmin] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const location = useLocation();

  const [videoSource, setVideoSource] = useState('None');
  const [videoLink, setVideoLink] = useState('');
  const [videoLinkId, setVideoLinkId] = useState('');
  const [isEdit, setIsEdit] = useState(1);

  const scrollToRef = useRef<HTMLDivElement | null>(null);

  const [videoMediaMessage, setVideoMediaMessage] = useState('');
  const [imageMediaMessage, setImageMediaMessage] = useState('');
  const [youtubeLinkValidationMsg, setYoutubeLinkValidationMsg] = useState('');
  const [otherLinkValidationMsg, setOtherLinkValidationMsg] = useState('');

  const [uploadVideoLink, setUploadVideoLink] = useState('');
  const [uploadVideoLinkId, setUploadVideoLinkId] = useState('');
  const [externalLink, setExternalLink] = useState(false);

  const userData = getUserPersistedOnLocalStorage();
  const [currentStep, setCurrentStep] = useState(
    Tabs.indexOf(videoSource ?? Tabs[0]),
  );

  useEffect(() => {
    const getExerciseData = async () => {
      try {
        const paramsMap = new Map<string, string>([['id', location.state.id]]);
        const filteredEndpoint = replaceParams(
          endpoints.exercise.view,
          paramsMap,
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data['data'];
        const convertedExercise = Convert.toExercise(JSON.stringify(data));
        // const { description, ...updatedData } = data;
        // const { includedInPlatformLibrary, ...updatedIncludedPlatform } = data;
        // const convertedExercise = Convert.toExercise(
        //   JSON.stringify(updatedData),
        // );
        reset({
          ...convertedExercise
        })
        // setFormData(convertedExercise);
        if (
          convertedExercise &&
          convertedExercise.externalLinks &&
          convertedExercise.externalLinks.length > 0
        ) {
          setVideoSource(convertedExercise.externalLinks[0].source);
          setCurrentStep(
            Tabs.indexOf(convertedExercise.externalLinks[0].source),
          );
          setVideoLink(convertedExercise.externalLinks[0].url);
          setVideoLinkId(convertedExercise.externalLinks[0].externalLinkId);
          setExternalLink(true);
        }
        setValue('id', convertedExercise.id);
        setValue('title', convertedExercise.title);
        setValue('instructions', convertedExercise.instructions);
        setValue('difficulty', convertedExercise.difficulty);
        setValue(
          'defaultExerciseConfig',
          convertedExercise.defaultExerciseConfig,
        );
        setValue('entityId', convertedExercise.entityId);

        // Fetch Media Files
        if (convertedExercise.media && convertedExercise.media.length > 0) {
          fetchMediaFiles(convertedExercise.id);
        }

        setLoading(false);
      } catch (error) {
        //console.error('Error fetching entity data:', error);
        //setError(error.message);
        toast.error(error.message);
        setLoading(false);
      }
    };

    const getEntities = async () => {
      try {
        const response = await axios.get(endpoints.entity.list);
        const data = response.data['data'];
        const convertedEntityList = data.map((entity: any) =>
          EConvert.toEntity(JSON.stringify(entity)),
        );

        setEntities(convertedEntityList);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    if (location && location.state && location.state.id) {
      getExerciseData();
      setShowMediaSection(true);
      if (location.state.edit === 0) {
        setIsDisabled(true);
      }
      if (location.state.edit === 0) {
        setIsEdit(0);
      }
    }

    if (userData?.user_role === 'PLATFORM_ADMIN') {
      getEntities();
      setIsPlatformAdmin(true);
    } else {
      setValue('entityId', userData?.entity_id ?? '');
    }
  }, []);

  const fetchMediaFiles = async (exerciseId: string) => {
    try {
      const paramsMap = new Map<string, string>([['id', exerciseId]]);
      const filteredEndpoint = replaceParams(
        endpoints.exercise.fetchMedia,
        paramsMap,
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data['data'];
      if (data && data.length > 0) {
        var imageFiles = [];
        for (let i = 0; i < data.length; i++) {
          var media = data[i];
          var type = media.contentType;
          if (
            type === 'video/mp4' ||
            type === 'video/m4a' ||
            type === 'video/fmp4'
          ) {
            setVideoSource(Tabs[1]);
            setCurrentStep(1);
            setVideoLink(media.url);
            setVideoLinkId(media.id);
            setUploadVideoLink(media.url);
            setUploadVideoLinkId(media.id);
          } else {
            var imageFile = {
              objectId: media.id,
              file: media.url,
              name: media.name,
              type: 'url',
            };
            imageFiles.push(imageFile);
          }
        }
        setMedia(imageFiles);
      }
    } catch (error) {
      //console.error('Error fetching media data:', error);
      //setError(error.message);
      toast.error(error.message);
      setLoading(false);
    }
  };

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const onSubmit: SubmitHandler<EntitySchemaType> = async data => {
    try {
      // prepare audit data
      const audit = {
        audit: {
          createdBy: null,
          lastUpdatedBy: null,
          createdAt: new Date(),
          lastUpdatedAt: new Date(),
        },
      };
      const externalLinksObj = { externalLinks: [] };

      const combinedData = {
        ...data,
        ...audit,
        ...externalLinksObj,
      };
      EntitySchema.parse(combinedData);
      const {
        videoSource,
        youtubeLink,
        otherLink,
        uploadVideo,
        uploadImages,
        ...rest
      } = combinedData;

      if (rest.id) {
        const paramsMap = new Map<string, string>([['id', rest.id]]);
        const filteredEndpoint = replaceParams(
          endpoints.exercise.updateExercise,
          paramsMap,
        );
        axios
          .put(filteredEndpoint, rest)
          .then(res => {
            if (res.status === 200) {
              if (formData) {
                setFormData({ ...formData, id: res.data.data.id });
              }
              setShowMediaSection(true);
              toast.success(
                res.data.message === 'success'
                  ? 'Exercise updated successfully'
                  : res.data.message,
              );
            }
          })
          .catch(error => {
            //console.error('Errors : ', error);
            toast.error(error.message);
          });
      } else {
        axios
          .post(`${endpoints.exercise.onboard}`, rest)
          .then(res => {
            if (res.status === 200) {
              if (formData) {
                setFormData({ ...formData, id: res.data.data.id });
              } else {
                var convertedExercise = Convert.toExercise(
                  JSON.stringify(rest),
                );
                convertedExercise.id = res.data.data.id;
                setFormData(convertedExercise);
              }
              setShowMediaSection(true);
              setValue('id', res.data.data.id);
              toast.success(
                res.data.message === 'success'
                  ? 'Exercise added successfully'
                  : res.data.message,
              );
              delay(1000).then(() => {
                scrollToRef?.current?.scrollIntoView({ behavior: 'smooth' });
              });
            }
          })
          .catch(error => {
            //console.error('Errors : ', error);
            toast.error(error.message);
          });
      }
    } catch (error) {
      //console.error('Error submitting form:', error);
      toast.error(error.message);
    }
  };

  function handleVideoSource(e: any) {
    setVideoSource(e.target.value);
    setVideoLink('');
  }

  function handleImagesUpload(e: any) {
    var files = media;

    if (e.target && e.target.files && e.target.files.length > 0) {
      var size = 0;
      var typeError = false;

      for (var j = 0; j < e.target.files.length; j++) {
        var file = e.target.files[j];
        var type = file.type;

        if (
          type != 'image/jpg' &&
          type != 'image/jpeg' &&
          type != 'image/png'
        ) {
          typeError = true;
          break;
        } else {
          size += file.size;
        }
      }

      if (typeError) {
        setImageMediaMessage(
          'Please select valid image files. Only files with extensions ".jpg", ".jpeg", ".png" allowed',
        );
        return false;
      } else if (size > 10000000) {
        setImageMediaMessage(
          'Please be aware that the maximum allowable file size for image uploads is 10MB. Your image file exceeds this limit.',
        );
        return false;
      } else {
        for (var i = 0; i < e.target.files.length; i++) {
          // Call API
          if (formData) {
            var file = e.target.files[i];
            var type = file.type;
            const paramsMap = new Map<string, string>([['id', formData?.id]]);
            const filteredEndpoint = replaceParams(
              endpoints.exercise.addMedia,
              paramsMap,
            );

            var formDataObj = new FormData();
            formDataObj.append('file', file);
            formDataObj.append('entityId', formData?.entityId);
            {
              let currentFile = file;
              axios
                .post(filteredEndpoint, formDataObj)
                .then(res => {
                  if (res.status === 200) {
                    var fileObj = {
                      objectId: res.data.data,
                      file: currentFile,
                      name: currentFile.name,
                      type: 'file',
                    };
                    files.push(fileObj);
                    toast.success(
                      res.data.message === 'success'
                        ? 'Image uploaded successfully'
                        : res.data.message,
                    );
                    setMedia(files);
                    navigator(paths.exercise.onboard.route, {
                      state: { id: formData.id, name: formData.title, edit: 1 },
                    });
                  }
                })
                .catch(error => {
                  toast.error(error.message);
                });
            }
          }
        }
      }
    }
  }

  function preview(e: any) {
    setVideoLink(e.target.value);
  }

  function removeImage(objectId: string, name: string) {
    if (confirm('Are you sure, you want to delete ' + name + ' image?')) {
      if (formData) {
        // Remove Image From Media
        const paramsMap = new Map<string, string>([
          ['id', formData.id],
          ['mediaId', objectId],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.exercise.deleteMedia,
          paramsMap,
        );

        axios
          .delete(filteredEndpoint)
          .then(res => {
            if (res.status === 200) {
              var files = media;
              var filteredFiles = files.filter(
                (x: any) => x.objectId != objectId,
              );
              setMedia(filteredFiles);
              toast.success(res.data.data);
            }
          })
          .catch(error => {
            //console.error('Errors : ', error);
            toast.error(error.message);
          });
      }
    }
  }

  function removeVideoOrExternalLink() {
    var msg = 'Are you sure, you want to delete uploaded video?';
    if (videoSource === 'youtube') {
      msg = 'Are you sure, you want to remove youtube link?';
    } else if (videoSource === 'other') {
      msg = 'Are you sure, you want to remove other link?';
    }
    if (confirm(msg)) {
      if (formData) {
        // Remove Image From Media
        var paramsMap = new Map<string, string>([['id', formData.id]]);
        var apiEndpoint = endpoints.exercise.deleteExternalLink;
        if (videoSource === 'Upload Video') {
          apiEndpoint = endpoints.exercise.deleteMedia;
          paramsMap.set('mediaId', videoLinkId);
        } else {
          paramsMap.set('linkId', videoLinkId);
        }
        const filteredEndpoint = replaceParams(apiEndpoint, paramsMap);

        axios
          .delete(filteredEndpoint)
          .then(res => {
            if (res.status === 200) {
              setVideoLinkId('');
              setVideoLink('');
              if (videoSource === 'Upload Video') {
                setUploadVideoLink('');
                setUploadVideoLinkId('');
              } else {
                setExternalLink(false);
              }
              setFormData({ ...formData, externalLinks: [] });
              toast.success(res.data.data);
            }
          })
          .catch(error => {
            //console.error('Errors : ', error);
            toast.error(error.message);
          });
      }
    }
  }

  function uploadVideoMedia(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      var file = e.target.files[0];
      var size = file.size;
      var type = file.type;
      if (type != 'video/mp4' && type != 'video/m4a' && type != 'video/fmp4') {
        setVideoMediaMessage(
          'Please select valid media file. Only files with extensions ".mp4", ".m4a", ".fmp4" allowed',
        );
        return false;
      } else if (size > 10000000) {
        setVideoMediaMessage(
          'Please note that the maximum allowable file size for video upload is 10MB. Your video file exceeds this limit.',
        );
        return false;
      } else if (
        type === 'video/mp4' ||
        type === 'video/m4a' ||
        type === 'video/fmp4'
      ) {
        if (formData) {
          const paramsMap = new Map<string, string>([['id', formData.id]]);
          const filteredEndpoint = replaceParams(
            endpoints.exercise.addMedia,
            paramsMap,
          );

          var formDataObj = new FormData();
          formDataObj.append('file', file);
          formDataObj.append('entityId', formData?.entityId);
          axios
            .post(filteredEndpoint, formDataObj)
            .then(res => {
              if (res.status === 200) {
                //
                toast.success(
                  res.data.message === 'success'
                    ? 'Video uploaded successfully'
                    : res.data.message,
                );
                // Get Media
                fetchMediaFiles(formData.id);
              }
            })
            .catch(error => {
              //console.error('Errors : ', error);
              toast.error(error.message);
            });
        }
      } else {
        setVideoMediaMessage(
          'Please select valid media file. Only files with extensions ".mp4", ".m4a", ".fmp4" allowed',
        );
        return false;
      }
    }
  }

  function saveExteralLink(e: any) {
    try {
      if (videoLink) {
        var isLinkValid = true;
        if (
          videoSource === 'Youtube' &&
          !/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/.test(
            videoLink,
          )
        ) {
          isLinkValid = false;
        }
        if (isLinkValid) {
          if (!ReactPlayer.canPlay(videoLink)) {
            if (videoSource === 'Youtube') {
              setYoutubeLinkValidationMsg('Please enter valid youtube link');
            } else if (videoSource === 'Other') {
              setOtherLinkValidationMsg('Please enter valid video link');
            }
          } else {
            setYoutubeLinkValidationMsg('');
            setOtherLinkValidationMsg('');
            const audit = {
              audit: {
                createdBy: null,
                lastUpdatedBy: null,
                createdAt: new Date(),
                lastUpdatedAt: new Date(),
              },
            };
            // prepare externalLink data
            var externalLinks: any = [];
            var externalLinkId = '';
            if (
              formData &&
              formData.externalLinks &&
              formData.externalLinks.length > 0
            ) {
              externalLinkId = formData.externalLinks[0].externalLinkId;
            }
            var externalLink = {
              linkTitle: formData ? formData.title : '' + ' Video',
              source: videoSource,
              url: videoLink,
              isFree: true,
              externalLinkId: externalLinkId,
              ...audit,
            };

            if (externalLinkId) {
              if (formData) {
                const paramsMap = new Map<string, string>([
                  ['id', formData.id],
                  ['linkId', videoLinkId],
                ]);
                const filteredEndpoint = replaceParams(
                  endpoints.exercise.updateExternalLink,
                  paramsMap,
                );
                axios
                  .put(filteredEndpoint, externalLink)
                  .then(res => {
                    if (res.status === 200) {
                      externalLinks.push(externalLink);
                      setFormData({
                        ...formData,
                        externalLinks: externalLinks,
                      });
                      toast.success(
                        res.data.message === 'success'
                          ? 'Link updated successfully'
                          : res.data.message,
                      );
                      setExternalLink(true);
                    }
                  })
                  .catch(error => {
                    //console.error('Errors : ', error);
                    toast.error(error.message);
                  });
              }
            } else {
              if (formData) {
                const paramsMap = new Map<string, string>([
                  ['id', formData.id],
                ]);
                const filteredEndpoint = replaceParams(
                  endpoints.exercise.addExternalLink,
                  paramsMap,
                );
                axios
                  .post(filteredEndpoint, externalLink)
                  .then(res => {
                    if (res.status === 200) {
                      externalLink.externalLinkId = res.data.data;
                      externalLinks.push(externalLink);
                      setVideoLinkId(res.data.data);
                      setFormData({
                        ...formData,
                        externalLinks: externalLinks,
                      });
                      toast.success(
                        res.data.message === 'success'
                          ? 'Link added successfully'
                          : res.data.message,
                      );
                      setExternalLink(true);
                    }
                  })
                  .catch(error => {
                    //console.error('Errors : ', error);
                    toast.error(error.message);
                  });
              }
            }
          }
        } else {
          if (videoSource === 'Youtube') {
            setYoutubeLinkValidationMsg('Please enter valid youtube link');
          } else if (videoSource === 'Other') {
            setOtherLinkValidationMsg('Please enter valid video link');
          }
        }
      } else {
        if (videoSource === 'Youtube') {
          setYoutubeLinkValidationMsg('Please enter valid youtube link');
        } else if (videoSource === 'Other') {
          setOtherLinkValidationMsg('Please enter valid video link');
        }
      }
    } catch (error) {
      //console.error("Error in save external link:", error);
      toast.error(error.message);
    }
  }

  function checkDuplicateTitle() {
    var title = watch('title');
    if (title && !formData) {
      const paramsMap = new Map<string, string>([['name', watch('title')]]);
      const filteredEndpoint = replaceParams(
        endpoints.exercise.checkExerciseTitle,
        paramsMap,
      );
      axios
        .get(filteredEndpoint)
        .then(res => {
          clearErrors('title');
        })
        .catch(error => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.status === 500
          ) {
            setError('title', {
              type: 'custom',
              message: 'Exercise with ' + title + ' title already exists',
            });
            setValue('title', '');
          } else {
            toast.error(error.message);
            setValue('title', '');
          }
        });
    }
  }
  function handleChangeVideo(source: string) {
    if (source === 'Upload Video') {
      setVideoLink(uploadVideoLink);
      setVideoLinkId(uploadVideoLinkId);
    } else if (source === 'Youtube' || source === 'Other') {
      if (
        formData &&
        formData.externalLinks &&
        formData.externalLinks.length > 0
      ) {
        var isSourcePresent: boolean = false;
        for (let i = 0; i < formData.externalLinks.length; i++) {
          const link = formData.externalLinks[i];
          if (link.source === source) {
            isSourcePresent = true;
            setVideoLink(link.url);
            setVideoLinkId(link.externalLinkId);
          }
        }
        if (!isSourcePresent) {
          setVideoLink('');
          setVideoLinkId('');
        }
      } else {
        setVideoLink('');
        setVideoLinkId('');
      }
    } else {
      setVideoLink('');
      setVideoLinkId('');
    }
  }

  return (
    <>
      {loading && <p>Loading...</p>}
      {!loading && (
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-row justify-between">
            <h1 className="text-2xl font-bold">
              {location && location.state && location.state.id
                ? location.state.edit === 1
                  ? 'Manage'
                  : 'View'
                : 'Add'}{' '}
              Exercise
            </h1>
            <Button
              variant={ButtonVariant.PRIMARY}
              onClick={() => {
                navigator(paths.exercise.index.route);
              }}
              type="button"
            >
              Back
            </Button>
          </div>

          <hr className="my-4" />

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <fieldset disabled={isDisabled}>
              <div className="mb-32">
                {!loading && entities && (
                  <div className="flex flex-col w-full space-y-4">
                    <div className="flex flex-col ">
                      <Applabel label="Select Entity" mandatory />
                      <select
                        {...register('entityId')}
                        onChange={e => setValue('entityId', e.target.value)}
                        className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                      >
                        <option key="" value="">
                          Select
                        </option>
                        {entities!.map(entity => (
                          <option key={entity.id} value={entity.id}>
                            {entity.name}
                          </option>
                        ))}
                      </select>
                      {errors.entityId && (
                        <ValidationError
                          message={errors.entityId?.message?.toString() ?? ''}
                        />
                      )}
                    </div>
                  </div>
                )}
                {!loading && !isPlatformAdmin && (
                  <div className="flex flex-col w-full space-y-4">
                    <div className="flex flex-col ">
                      <input
                        {...register('entityId')}
                        name="entityId"
                        className={`hidden lock w-full rounded-lg bg-gray-100 border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm `}
                        value={watch('entityId')}
                      />

                      {errors.entityId && (
                        <ValidationError
                          message={errors.entityId?.message?.toString() ?? ''}
                        />
                      )}
                    </div>
                  </div>
                )}
                <div className="flex flex-col w-full space-y-4">
                  <div className="flex flex-col w-full xl:w-2/3 pr-2">
                    <Applabel label="Title" mandatory />
                    <input
                      {...register('title')}
                      placeholder="Enter exercise title"
                      value={watch('title')}
                      type="text"
                      onBlur={checkDuplicateTitle}
                      className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                    />
                    {errors.title && (
                      <ValidationError
                        message={errors.title?.message?.toString() ?? ''}
                      />
                    )}
                  </div>

                  <div className="flex flex-col w-full xl:w-2/3 pr-2">
                    <Applabel label="Instructions" mandatory />
                    <textarea
                      {...register('instructions')}
                      placeholder="Enter instructions to perform the exercise"
                      rows={5}
                      value={watch('instructions')}
                      className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                    />
                    {errors.instructions && (
                      <ValidationError
                        message={errors.instructions?.message?.toString() ?? ''}
                      />
                    )}
                  </div>

                  <hr className="my-2" />

                  <span className="flex-none text-lg font-medium mb-0">
                    Default Configuration
                  </span>

                  <hr className="my-2" />
                  <div>
                    <div className="flex flex-col md:flex-row md:space-x-4">
                      <div className="flex flex-col w-full md:w-1/4 pr-2">
                        <Applabel label="Daily Frequency" />
                        <input
                          {...register('defaultExerciseConfig.dailyFrequency')}
                          placeholder="Daily Frequency"
                          value={
                            watch('defaultExerciseConfig.dailyFrequency') || 1
                          }
                          type="number"
                          min={1}
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        />
                        {errors.defaultExerciseConfig &&
                          errors.defaultExerciseConfig?.dailyFrequency && (
                            <ValidationError
                              message={
                                errors.defaultExerciseConfig?.dailyFrequency?.message?.toString() ??
                                ''
                              }
                            />
                          )}
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:space-x-4">
                      <div className="flex flex-col w-full md:w-1/4 pr-2">
                        <Applabel label="Difficulty" />
                        <select
                          {...register('difficulty')}
                          placeholder="Difficulty"
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          value={watch('difficulty')}
                        >
                          <option key="" value="">
                            Select
                          </option>
                          {['Easy', 'Intermediate', 'Hard'].map(difficulty => {
                            return (
                              <option key={difficulty} value={difficulty}>
                                {difficulty}
                              </option>
                            );
                          })}
                        </select>
                        {errors.difficulty && (
                          <ValidationError
                            message={
                              errors.difficulty?.message?.toString() ?? ''
                            }
                          />
                        )}
                      </div>

                      <div className="flex flex-col w-full md:w-1/4 pr-2">
                        <Applabel label="Repetitions" />
                        <input
                          {...register('defaultExerciseConfig.repetitions')}
                          placeholder="Repetitions"
                          value={watch('defaultExerciseConfig.repetitions')}
                          type="number"
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        />
                        {errors.defaultExerciseConfig &&
                          errors.defaultExerciseConfig?.repetitions && (
                            <ValidationError
                              message={
                                errors.defaultExerciseConfig?.repetitions?.message?.toString() ??
                                ''
                              }
                            />
                          )}
                      </div>

                      <div className="flex flex-col w-full md:w-1/4 pr-2">
                        <Applabel label="Sets" />
                        <input
                          {...register('defaultExerciseConfig.sets')}
                          placeholder="Sets"
                          value={watch('defaultExerciseConfig.sets')}
                          type="number"
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        />
                        {errors.defaultExerciseConfig &&
                          errors.defaultExerciseConfig?.sets && (
                            <ValidationError
                              message={
                                errors.defaultExerciseConfig?.sets?.message?.toString() ??
                                ''
                              }
                            />
                          )}
                      </div>
                      <div className="flex flex-col w-full md:w-1/4 pr-2">
                        <Applabel label="Weights" />
                        <div className="w-full relative">
                          <input
                            {...register('defaultExerciseConfig.weights')}
                            placeholder="Weights"
                            value={watch('defaultExerciseConfig.weights')}
                            type="number"
                            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center">
                            <select
                              {...register('defaultExerciseConfig.weightsUnit')}
                              placeholder="Weight Unit"
                              className={`h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm`}
                              value={watch('defaultExerciseConfig.weightsUnit')}
                            >
                              {['Kgs', 'Lbs'].map(weightUnit => {
                                return (
                                  <option key={weightUnit} value={weightUnit}>
                                    {weightUnit}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        {errors.defaultExerciseConfig &&
                          errors.defaultExerciseConfig?.weights && (
                            <ValidationError
                              message={
                                errors.defaultExerciseConfig?.weights?.message?.toString() ??
                                ''
                              }
                            />
                          )}
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:space-x-4">
                      <div className="relative flex flex-col w-full md:w-1/3 pr-2">
                        <Applabel label="Interval" />
                        <div className="w-full relative">
                          <input
                            {...register('defaultExerciseConfig.interval')}
                            placeholder="Interval"
                            value={watch('defaultExerciseConfig.interval')}
                            type="number"
                            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center">
                            <select
                              {...register(
                                'defaultExerciseConfig.intervalUnit',
                              )}
                              placeholder="Interval Unit"
                              className={`h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm`}
                              value={watch(
                                'defaultExerciseConfig.intervalUnit',
                              )}
                            >
                              {['Sec', 'Min'].map(intervalUnit => {
                                return (
                                  <option
                                    key={intervalUnit}
                                    value={intervalUnit}
                                  >
                                    {intervalUnit}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        {errors.defaultExerciseConfig &&
                          errors.defaultExerciseConfig?.interval && (
                            <ValidationError
                              message={
                                errors.defaultExerciseConfig?.interval?.message?.toString() ??
                                ''
                              }
                            />
                          )}
                      </div>
                      <div className="flex flex-col w-full md:w-1/3 pr-2">
                        <Applabel label="Side" />
                        <select
                          {...register('defaultExerciseConfig.side')}
                          placeholder="Side"
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          value={watch('defaultExerciseConfig.side')}
                        >
                          <option key="" value="">
                            Select
                          </option>
                          {['None', 'Left', 'Right', 'Both'].map(difficulty => {
                            return (
                              <option key={difficulty} value={difficulty}>
                                {difficulty}
                              </option>
                            );
                          })}
                        </select>
                        {errors.defaultExerciseConfig &&
                          errors.defaultExerciseConfig?.side && (
                            <ValidationError
                              message={
                                errors.defaultExerciseConfig?.side?.message?.toString() ??
                                ''
                              }
                            />
                          )}
                      </div>
                      <div className="relative flex flex-col w-full md:w-1/3 pr-2">
                        <Applabel label="Hold" />
                        <div className="w-full relative">
                          <input
                            {...register('defaultExerciseConfig.hold')}
                            placeholder="Hold"
                            value={watch('defaultExerciseConfig.hold')}
                            type="number"
                            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center">
                            <select
                              {...register('defaultExerciseConfig.holdUnit')}
                              placeholder="Hold Unit"
                              className={`h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm`}
                              value={watch('defaultExerciseConfig.holdUnit')}
                            >
                              {['Sec', 'Min'].map(intervalUnit => {
                                return (
                                  <option
                                    key={intervalUnit}
                                    value={intervalUnit}
                                  >
                                    {intervalUnit}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        {errors.defaultExerciseConfig &&
                          errors.defaultExerciseConfig?.hold && (
                            <ValidationError
                              message={
                                errors.defaultExerciseConfig?.hold?.message?.toString() ??
                                ''
                              }
                            />
                          )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="hidden md:hidden lg:hidden xl:block flex flex-row">
                    <div className="flex flex-row mt-0 space-x-4">
                      <div className="flex flex-col w-3/12">
                        <Applabel label="Difficulty" />
                        <select
                          {...register('difficulty')}
                          placeholder="Difficulty"
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          value={watch('difficulty')}
                        >
                          <option key="" value="">
                            Select
                          </option>
                          {['Easy', 'Intermediate', 'Hard'].map(difficulty => {
                            return (
                              <option key={difficulty} value={difficulty}>
                                {difficulty}
                              </option>
                            );
                          })}
                        </select>
                        {errors.difficulty && (
                          <ValidationError
                            message={
                              errors.difficulty?.message?.toString() ?? ''
                            }
                          />
                        )}
                      </div>
                      <div className="flex flex-col w-3/12">
                        <Applabel label="Repetitions" />
                        <input
                          {...register('defaultExerciseConfig.repetitions')}
                          placeholder="Repetitions"
                          value={watch('defaultExerciseConfig.repetitions')}
                          type="number"
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        />
                        {errors.defaultExerciseConfig &&
                          errors.defaultExerciseConfig?.repetitions && (
                            <ValidationError
                              message={
                                errors.defaultExerciseConfig?.repetitions?.message?.toString() ??
                                ''
                              }
                            />
                          )}
                      </div>
                      <div className="flex flex-col w-3/12">
                        <Applabel label="Sets" />
                        <input
                          {...register('defaultExerciseConfig.sets')}
                          placeholder="Sets"
                          value={watch('defaultExerciseConfig.sets')}
                          type="number"
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        />
                        {errors.defaultExerciseConfig &&
                          errors.defaultExerciseConfig?.sets && (
                            <ValidationError
                              message={
                                errors.defaultExerciseConfig?.sets?.message?.toString() ??
                                ''
                              }
                            />
                          )}
                      </div>
                      <div className="flex flex-col w-3/12">
                        <Applabel label="Weights" />
                        <div className="w-full relative">
                          <input
                            {...register('defaultExerciseConfig.weights')}
                            placeholder="Weights"
                            value={watch('defaultExerciseConfig.weights')}
                            type="number"
                            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center">
                            <select
                              {...register('defaultExerciseConfig.weightsUnit')}
                              placeholder="Weight Unit"
                              className={`h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm`}
                              value={watch('defaultExerciseConfig.weightsUnit')}
                            >
                              {['Kgs', 'Lbs'].map(weightUnit => {
                                return (
                                  <option key={weightUnit} value={weightUnit}>
                                    {weightUnit}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        {errors.defaultExerciseConfig &&
                          errors.defaultExerciseConfig?.weights && (
                            <ValidationError
                              message={
                                errors.defaultExerciseConfig?.weights?.message?.toString() ??
                                ''
                              }
                            />
                          )}
                      </div>
                      <div className="flex flex-col w-3/12">
                        <Applabel label="Interval" />
                        <div className="w-full relative">
                          <input
                            {...register('defaultExerciseConfig.interval')}
                            placeholder="Interval"
                            value={watch('defaultExerciseConfig.interval')}
                            type="number"
                            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center">
                            <select
                              {...register(
                                'defaultExerciseConfig.intervalUnit',
                              )}
                              placeholder="Interval Unit"
                              className={`h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm`}
                              value={watch(
                                'defaultExerciseConfig.intervalUnit',
                              )}
                            >
                              {['Sec', 'Min'].map(intervalUnit => {
                                return (
                                  <option
                                    key={intervalUnit}
                                    value={intervalUnit}
                                  >
                                    {intervalUnit}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        {errors.defaultExerciseConfig &&
                          errors.defaultExerciseConfig?.interval && (
                            <ValidationError
                              message={
                                errors.defaultExerciseConfig?.interval?.message?.toString() ??
                                ''
                              }
                            />
                          )}
                      </div>
                      <div className="flex flex-col w-3/12">
                        <Applabel label="Side" />
                        <select
                          {...register('defaultExerciseConfig.side')}
                          placeholder="Side"
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          value={watch('defaultExerciseConfig.side')}
                        >
                          <option key="" value="">
                            Select
                          </option>
                          {['None', 'Left', 'Right', 'Both'].map(difficulty => {
                            return (
                              <option key={difficulty} value={difficulty}>
                                {difficulty}
                              </option>
                            );
                          })}
                        </select>
                        {errors.defaultExerciseConfig &&
                          errors.defaultExerciseConfig?.side && (
                            <ValidationError
                              message={
                                errors.defaultExerciseConfig?.side?.message?.toString() ??
                                ''
                              }
                            />
                          )}
                      </div>
                      <div className="flex flex-col w-3/12">
                        <Applabel label="Hold" />
                        <div className="w-full relative">
                          <input
                            {...register('defaultExerciseConfig.hold')}
                            placeholder="Hold"
                            value={watch('defaultExerciseConfig.hold')}
                            type="number"
                            className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center">
                            <select
                              {...register('defaultExerciseConfig.holdUnit')}
                              placeholder="Hold Unit"
                              className={`h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm`}
                              value={watch('defaultExerciseConfig.holdUnit')}
                            >
                              {['Sec', 'Min'].map(intervalUnit => {
                                return (
                                  <option
                                    key={intervalUnit}
                                    value={intervalUnit}
                                  >
                                    {intervalUnit}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        {errors.defaultExerciseConfig &&
                          errors.defaultExerciseConfig?.hold && (
                            <ValidationError
                              message={
                                errors.defaultExerciseConfig?.hold?.message?.toString() ??
                                ''
                              }
                            />
                          )}
                      </div>
                    </div>
                  </div> */}

                  <div className={!showMediaSection ? 'hidden' : ''}>
                    <hr className="my-2" />

                    <span className="flex-none text-lg font-medium w-1/6 mb-0">
                      Exercise Videos
                    </span>

                    <hr className="my-2" />
                    <div className="flex flex-col md:w-8/12 w-full h-full outline outline-offset-4 outline-gray-200 outline-1 rounded-xl my-4 whitespace-nowrap">
                      <div className="flex flex-row w-full">
                        {Tabs.map((tab, index) => (
                          <div
                            className={`flex px-4 py-2 justify-between rounded-xl cursor-pointer w-1/2 ${index === currentStep
                              ? 'bg-primary-100 text-primary'
                              : 'bg-transparent text-gray-500'
                              }`}
                            key={tab}
                            onClick={() => {
                              setVideoSource(Tabs[index]);
                              setCurrentStep(index);
                              //setVideoLink('');
                              handleChangeVideo(tab);
                            }}
                          >
                            <span className=" flex-initial text-black">
                              {tab}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-col w-full md:flex-row">
                      <div
                        className={
                          'flex flex-col w-full md:w-1/2 youtubeLink ' +
                          (videoSource != 'Youtube' ? 'hidden' : '')
                        }
                      >
                        <Applabel label="Youtube Link" />

                        <input
                          name="youtubeLink"
                          key="youtubeLink"
                          type="text"
                          onChange={e => preview(e)}
                          onClick={e =>
                            externalLink && videoSource === 'Youtube'
                              ? alert('Please remove the exixting Video Link')
                              : undefined
                          }
                          readOnly={
                            externalLink && videoSource === 'Youtube'
                              ? true
                              : false
                          }
                          onBlur={e =>
                            !externalLink && videoSource === 'Youtube'
                              ? saveExteralLink(e)
                              : undefined
                          }
                          value={videoSource === 'Youtube' ? videoLink : ''}
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        />

                        {youtubeLinkValidationMsg && (
                          <ValidationError message={youtubeLinkValidationMsg} />
                        )}
                        {isEdit === 1 ? (
                          videoLink && videoSource === 'Youtube' ? (
                            <Button
                              variant={ButtonVariant.PRIMARY}
                              onClick={() => removeVideoOrExternalLink()}
                              type="button"
                              className="w-[20vh] justify-center mt-8"
                            >
                              Remove Link
                            </Button>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}
                      </div>

                      <div
                        className={
                          'flex flex-col w-full md:w-1/2 videoLink ' +
                          (videoSource != 'Other' ? 'hidden' : '')
                        }
                      >
                        <Applabel label="Video Link" />
                        <input
                          name="otherLink"
                          key="otherLink"
                          type="text"
                          // disabled={videoLink && videoSource === 'other' ? true : false}
                          onChange={e => preview(e)}
                          onBlur={e => saveExteralLink(e)}
                          value={videoSource === 'Other' ? videoLink : ''}
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        />
                        {otherLinkValidationMsg && (
                          <ValidationError message={otherLinkValidationMsg} />
                        )}
                        {isEdit === 1 ? (
                          videoLink && videoSource === 'Other' ? (
                            <Button
                              variant={ButtonVariant.PRIMARY}
                              onClick={() => removeVideoOrExternalLink()}
                              type="button"
                              className="w-[20vh] justify-center mt-8"
                            >
                              Remove Link
                            </Button>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}
                      </div>

                      <div
                        className={
                          'flex flex-col w-full md:w-1/2 uploadVideo ' +
                          (videoSource != 'Upload Video' ? 'hidden' : '')
                        }
                      >
                        <Applabel label="Upload Video" />
                        {!videoLink && videoSource === 'Upload Video' ? (
                          <div className="border border-dashed border-gray-500 relative">
                            <input
                              name="uploadVideo"
                              key="uploadVideo"
                              type="file"
                              // disabled={videoLink && videoSource === 'Upload Video' ? true : false}
                              accept="video/mp4,video/m4a,video/fmp4"
                              onChange={e => uploadVideoMedia(e)}
                              className={`cursor-pointer relative block opacity-0 w-full h-full p-20 z-50`}
                            />

                            <div
                              className="font-medium text-gray-600 text-center p-10 absolute top-0 right-0 left-0 m-auto"
                            // {...(videoLink && videoSource === 'Upload Video' ? { onClick: () => { alert('Please remove the existing Video') } } : {})}
                            >
                              <h4>
                                Drop files anywhere to upload
                                <br />
                                or
                              </h4>
                              <p className="">Select Files</p>
                            </div>
                          </div>
                        ) : (
                          <div className="border border-dashed border-gray-500 relative">
                            <input
                              name="uploadVideo"
                              key="uploadVideo"
                              accept="video/mp4,video/m4a,video/fmp4"
                              onClick={() =>
                                alert('Please remove the existing Video')
                              }
                              className={`cursor-pointer relative block opacity-0 w-full h-full p-20 z-50`}
                            />

                            <div className="font-medium text-gray-600 text-center p-10 absolute top-0 right-0 left-0 m-auto">
                              <h4>
                                Drop files anywhere to upload
                                <br />
                                or
                              </h4>
                              <p className="">Select Files</p>
                            </div>
                          </div>
                        )}
                        {videoMediaMessage && (
                          <ValidationError message={videoMediaMessage ?? ''} />
                        )}

                        {isEdit === 1 ? (
                          videoLink && videoSource === 'Upload Video' ? (
                            <Button
                              variant={ButtonVariant.PRIMARY}
                              onClick={() => removeVideoOrExternalLink()}
                              type="button"
                              className="w-[22vh] justify-center mt-8"
                            >
                              Remove Video
                            </Button>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}
                      </div>

                      <div
                        className={
                          'flex flex-col w-full md:w-1/2 lg:pl-8 md:pl-8 mt-2 ' +
                          (videoLink != '' ? '' : 'hidden')
                        }
                      >
                        <ReactPlayer
                          config={{
                            file: {
                              attributes: { controlsList: 'nodownload' },
                            },
                          }}
                          controls={true}
                          url={videoLink}
                          width="100%"
                          height="100%"
                          onContextMenu={(e: any) => e.preventDefault()}
                        />
                      </div>
                    </div>
                    <hr className="my-2" />

                    <span className="flex-none text-lg font-medium w-1/6 mb-0">
                      Exercise Images
                    </span>

                    <hr className="my-2" />

                    <div
                      ref={scrollToRef}
                      className="flex flex-col md:flex-row md:w-full"
                    >
                      <div className="flex flex-col w-full md:w-2/3 mb-4 pr-2">
                        <Applabel label="Upload Images" />
                        <div className="border border-dashed border-gray-500 relative">
                          <input
                            name="uploadImages"
                            key="uploadImages"
                            type="file"
                            multiple
                            accept="image/jpg,image/jpeg,image/png"
                            onChange={e => handleImagesUpload(e)}
                            className={`cursor-pointer relative block opacity-0 w-full h-full p-20 z-50`}
                          />
                          <div className="font-medium text-gray-600 text-center p-10 absolute top-0 right-0 left-0 m-auto">
                            <h4>
                              Drop files anywhere to upload
                              <br />
                              or
                            </h4>
                            <p className="">Select Files</p>
                          </div>
                        </div>
                        {imageMediaMessage && (
                          <ValidationError message={imageMediaMessage ?? ''} />
                        )}
                      </div>
                    </div>
                    <div className="relative -mb-6 w-full overflow-x-auto pb-6 flex flex-col md:flex-row">
                      {media.map((image: any, index: number) => (
                        <div className="group relative mr-6">
                          <div
                            className={`aspect-h-1 aspect-w-1 w-full rounded-md bg-gray-200 p-2${media.length >= 5
                              ? 'overflow-x-auto'
                              : 'overflow-hidden'
                              }`}
                          >
                            <img
                              key={index}
                              src={
                                image.type === 'file'
                                  ? URL.createObjectURL(image.file)
                                  : image.file
                              }
                              className="h-full w-full object-cover object-center group-hover:opacity-75 lg:w-[30vh] md:w-[30vh] lg:h-[30vh] md:h-[30vh]"
                            />
                          </div>
                          {isEdit === 1 ? (
                            <Button
                              variant={ButtonVariant.PRIMARY}
                              onClick={() =>
                                removeImage(image.objectId, image.name)
                              }
                              type="button"
                              className="w-[20vh] justify-center mt-2"
                            >
                              <span className="sr-only">Close menu</span>
                              <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </Button>
                          ) : (
                            ''
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <div className="fixed items-start w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-8 shadow bg-gray-100">
              {!isDisabled ? (
                <div className="flex flex-row">
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    type="submit"
                    className="ml-3"
                  >
                    Submit
                  </Button>
                </div>
              ) : (
                ''
              )}
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default AddExercisePage;
