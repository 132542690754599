export interface Feedback {
  id: string;
  entityId: string;
  form: any;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toFeedback(json: string): Feedback {
    return JSON.parse(json);
  }

  public static feedbackToJson(value: Feedback): string {
    return JSON.stringify(value);
  }
}
