// To parse this data:
//
//   import { Convert, RefundDetails } from "./file";
//
//   const refundDetails = Convert.toRefundDetails(json);

export interface RefundDetails1 {
  id: string;
  entityId: string;
  entityBranchId: string;
  clientId: string;
  amount: number;
  invoiceNo: string;
  notes: string;
  refundDate: Date;
  createdDate: Date;
  createdBy: string;
}

// Converts JSON strings to/from your types
export class ConvertRefundDetails1 {
  public static toRefundDetails1(json: string): RefundDetails1 {
    return JSON.parse(json);
  }

  public static refundDetails1ToJson(value: RefundDetails1): string {
    return JSON.stringify(value);
  }
}
