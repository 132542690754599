import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { Fragment, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as z from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { paths } from "@routes/paths";
import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";
import moment from "moment";
import Button from "@components/button/button";
import { ButtonVariant } from "@components/button/button";

import { ValidationError } from "@components/hook-form";
import CountryInputSelect from "@components/hook-form/country-input-select";
import Applabel from "@components/hook-form/applabel";

// import PhoneInput, { CountryData } from 'react-phone-input-2';
import { PhoneNumberUtil } from "google-libphonenumber";
import "react-international-phone/style.css";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";
import Modal from "@components/modal";
import { ManagePatientNewContext } from "./ManagePatientNew";
import { Dialog, Transition } from "@headlessui/react";

import DoctorSearch from "../components/DoctorSearch";
import HospitalSearch from "../components/HospitalSearch";
import PhoneInput, {
  formatPhoneNumber,
  getCountryCallingCode,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { CountryCode, E164Number } from "libphonenumber-js";
import {
  convertPhoneNumberAndCodeToNumber,
  convertPhoneNumberAndCodeToString,
} from "@utils/phoneUtils";
import OtherReferralSearch from "../components/OtherReferralSearch";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import {
  Convert,
  PersonalInformationDetails,
} from "@pages/manage-patient-new/types/PatientPersonalDetails";
import {
  ConvertReferringUsersHospitals,
  ReferringUsersHospitals,
} from "@pages/manage-patient-new/types/ReferringUsersHospitals";

export const AddressSchema = z.object({
  address: z.string().min(1, { message: "Address is required" }),
  city: z.string().min(1, { message: "City is required" }),
  state: z.string().min(1, { message: "State is required" }),
  country: z.string().min(1, { message: "Country is required" }),
  postalCode: z.string().min(1, { message: "Postal Code/zip is required" }),
});
export type Address = z.infer<typeof AddressSchema>;

export const PersonalInformationSchema = z.object({
  salutation: z.string().optional(),
  firstName: z.string().min(1, { message: "First name is required" }),
  middleName: z.string().optional(),
  lastName: z.string().min(1, { message: "Last name is required" }),
  email: z
    .string()
    .email({ message: "Must be a valid email" })
    .optional()
    .or(z.literal("")),
  isActive: z.boolean().optional(),
  isOnboarded: z.boolean().optional(),
  phoneCode: z.string().min(1, { message: "Phone Code is required" }),
  phone: z.string().superRefine((val, ctx) => {
    //
    if (!val || val.trim() === "") {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please enter Phone`,
        fatal: true,
      });
      return z.NEVER;
    }
    if (!isValidPhoneNumber(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        fatal: true,
        message: "Invalid phone number format",
      });

      return z.NEVER;
    }

    if (!isPossiblePhoneNumber(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Invalid phone number length`,
        fatal: true,
      });
      return z.NEVER;
    }
  }),
  emergencyPhoneCode: z.string().optional(),
  emergencyPhone: z
    .string()
    .refine(
      (val) => {
        if (!val || val.trim() === "") return true; // Allow empty string if field is optional
        return isValidPhoneNumber(val);
      },
      { message: "Invalid emergency phone number format" }
    )
    .optional(),
  birthPlace: z.string().optional(),
  bloodGroup: z.string(),
  address: z.union([AddressSchema, z.null()]),
  age: z.coerce.number().nonnegative(),
  gender: z.string().optional(),
  dob: z.string().refine(
    (val) => {
      const dobDate = new Date(val);
      return !isNaN(dobDate.getTime());
    },
    { message: "Please enter date of birth" }
  ),
  whatsAppConsent: z.boolean(),
  referringUser: z.string().optional().default(""),
  referringHospital: z.string().optional().default(""),
  otherReferral: z.string().optional().default(""),
});

export type PersonalInformationType = z.infer<typeof PersonalInformationSchema>;

export default function EditPersonalInformation(props: {
  onClose: () => void;
  setAddPaymentDetails: React.Dispatch<React.SetStateAction<boolean>>;
  getPatientData: () => Promise<void>;
  patientId: string | undefined;
}) {
  const [referringData, setReferringData] =
    React.useState<ReferringUsersHospitals | null>(null); // This is the list of doctors
  const [selectedDoctorName, setSelectedDoctorName] = useState("");
  const [selectedHospitalName, setSelectedHospitalName] = useState("");
  const [selectedOtherReferralName, setSelectedOtherReferralName] =
    useState("");

  const [checkFirstName, setCheckFirstName] = useState("");
  const [checkMiddleName, setCheckMiddleName] = useState("");
  const [checkLastName, setCheckLastName] = useState("");
  const [checkPhoneNumber, setCheckPhoneNumber] = useState("");
  const [checkPhoneCode, setCheckPhoneCode] = useState("");

  const handlePhoneNumberChange = (value: E164Number | undefined) => {
    setValue("phone", value?.toString() ?? "");
  };
  const handleEmergencyPhoneNumberChange = (value: E164Number | undefined) => {
    setValue("emergencyPhone", value?.toString() ?? "");
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<PersonalInformationType>({
    resolver: zodResolver(PersonalInformationSchema),
  });

  const handleToggleEdit = () => {
    setIsEditable((prevState) => !prevState);
  };

  const onSubmit: SubmitHandler<PersonalInformationType> = async (data) => {
    try {
      const phoneData = convertPhoneNumberAndCodeToNumber({
        phoneNumber: data.phone,
        phoneCode: data.phoneCode,
      });

      const emergencyPhoneData = convertPhoneNumberAndCodeToNumber({
        phoneNumber: data.emergencyPhone,
        phoneCode: data.emergencyPhoneCode,
      });
      PersonalInformationSchema.parse(data);
      const paramsMap = new Map<string, string>([["patientId", patientId!]]);
      const filteredEndpoint = replaceParams(
        endpoints.patient.updateInformation,
        paramsMap
      );
      let nameChange = false;
      let phoneChange = false;

      if (
        checkFirstName !== data.firstName ||
        checkMiddleName !== data.middleName ||
        checkLastName !== data.lastName
      ) {
        nameChange = true;
      }
      if (
        checkPhoneNumber !== phoneData.phoneNumber.toString() ||
        checkPhoneCode !== phoneData.phoneCode.toString()
      ) {
        phoneChange = true;
      }

      const response = await axios.put(
        filteredEndpoint,
        {
          ...data,
          phone: phoneData.phoneNumber,
          phoneCode: phoneData.phoneCode,
          emergencyPhone: emergencyPhoneData.phoneNumber,
          emergencyPhoneCode: emergencyPhoneData.phoneCode,
          isAppointmentNotificationEnabled: data.whatsAppConsent,
          whatsAppConsent: data.whatsAppConsent,
        },
        {
          params: {
            isClientNameChanged: nameChange,
            isClientPhoneChanged: phoneChange,
          },
        }
      );

      setIsEditable(false);
      // window.location.reload();
      // props.postSubmitCallback();
      //   navigateToMasterAssessment(response.data['data']);
      setPhoneCode(`${phoneData.phoneCode}`);
      setPhone(`${phoneData.phoneNumber}`);
      setMobileNumber(false);
      await getPatients();
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiError(error.response.data["data"].toString());
    }
  };

  const phoneUtil = PhoneNumberUtil.getInstance();
  const isPhoneValid = (phone: string) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const getCountryCode = (phoneNumber: string) => {
    let code = phoneUtil.parseAndKeepRawInput(phoneNumber).getCountryCode();

    if (code) {
      return code;
    } else {
      return 91;
    }
  };
  const getPhoneNumber = (PhoneInput: string) => {
    let val = phoneUtil.parseAndKeepRawInput(PhoneInput).getNationalNumber();
    return val;
    //setValue('phone', val!)
  };
  const userData = getUserPersistedOnLocalStorage();
  const [patientPersonalInformation, setPatientPersonalInformation] =
    React.useState<PersonalInformationDetails | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [isEditable, setIsEditable] = React.useState(false);
  const [apiError, setApiError] = React.useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState("");
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(true);
  const axios = useAxios();
  const [mobileNumber, setMobileNumber] = useState<boolean>(false);
  //const patientId = useContext(ManagePatientNewContext);
  const [Phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");

  let { patientId: paramPatientId } = useParams();
  let patientId =
    props.patientId !== undefined ? props.patientId : paramPatientId;

  // below is the api call to get the list of patients in a react hooks

  const getPatients = async () => {
    try {
      const paramsMap = new Map<string, string>([["patientId", patientId!]]);
      const filteredEndpoint = replaceParams(endpoints.patient.view, paramsMap);
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];

      const convertedPersonalInformation = Convert.toPersonalInformation(
        JSON.stringify(data)
      );
      setPatientPersonalInformation(convertedPersonalInformation);
      setPhoneNumber(data.phoneCode.toString() + data.phone.toString());
      setPhone(data.phone.toString());
      setPhoneCode(data.phoneCode.toString());

      if (data.referringHospital != "") {
        setSelectedHospitalName(data.referringHospital);
      }
      if (data.referringUser != "") {
        setSelectedDoctorName(data.referringUser);
      }
      if (data.otherReferral != "") {
        setSelectedOtherReferralName(data.otherReferral);
      }
      if (data.emergencyPhone != 0) {
        setEmergencyPhoneNumber(
          data.emergencyPhoneCode.toString() + data.emergencyPhone.toString()
        );
      }

      setCheckFirstName(data.firstName);
      setCheckMiddleName(data.middleName);
      setCheckLastName(data.lastName);
      setCheckPhoneNumber(data.phone.toString());
      setCheckPhoneCode(data.phoneCode.toString());

      var dob = convertedPersonalInformation.dob;
      if (dob && convertedPersonalInformation) {
        convertedPersonalInformation.dob = moment(dob).format("YYYY-MM-DD");
      }
      const phoneNumberDataString = convertPhoneNumberAndCodeToString({
        phoneNumber: convertedPersonalInformation.phone ?? 0,
        phoneCode: convertedPersonalInformation.phoneCode ?? 0,
      });
      const emergencyPhoneNumberDataString = convertPhoneNumberAndCodeToString({
        phoneNumber: convertedPersonalInformation.emergencyPhone ?? 0,
        phoneCode: convertedPersonalInformation.emergencyPhoneCode ?? 0,
      });
      reset({
        ...convertedPersonalInformation,
        phone: phoneNumberDataString.phoneNumber,
        phoneCode: phoneNumberDataString.phoneCode,
        emergencyPhone: emergencyPhoneNumberDataString.phoneNumber,
        emergencyPhoneCode: emergencyPhoneNumberDataString.phoneCode,
      });
      setIsEditable(false);
      setLoading(false);
    } catch (error) {
      console.error("Error getting patient information", error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setIsEditable(false);

    getPatients();
    fetchDoctorsList();
  }, []);

  const handleRequestFormLink = async () => {
    try {
      const response = await axios.post(
        endpoints.patient.resendOnboardFormLink,
        {},
        {
          params: {
            id: patientId!,
          },
        }
      );
      toast.success("Onboarding form link sent");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Something went wrong. Please try again after sometime.");
    }
  };

  function closeModal() {
    setIsOpen(false);
    props.onClose();
    props.getPatientData();
  }

  const fetchDoctorsList = async () => {
    try {
      const response = await axios.get(endpoints.patient.getReferrals);

      const data = response.data["data"];
      const converted =
        ConvertReferringUsersHospitals.toReferringUsersHospitals(
          JSON.stringify(data)
        );

      setReferringData(converted);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching staff list:", error);

      setLoading(false);
    }
  };

  const handleDateChange = (event: any) => {
    const value = event.target.value;
    if (value) {
      const birthdate = new Date(value);
      const currentDate = new Date();
      let age = currentDate.getFullYear() - birthdate.getFullYear();
      if (
        birthdate.getMonth() > currentDate.getMonth() ||
        (birthdate.getMonth() === currentDate.getMonth() &&
          birthdate.getDate() > currentDate.getDate())
      ) {
        age = age - 1;
      }

      setValue("age", age);
      setValue("dob", moment(value).format("YYYY-MM-DD"));

      clearErrors("dob");
      clearErrors("age");
    } else {
      setValue("age", 0);
      setValue("dob", "");
      clearErrors("dob");
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all w-4/5">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 px-6 pt-6 text-gray-900"
                >
                  <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                    <div className="min-w-0 flex-1">
                      <h2 className="text-xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
                        Personal Information
                      </h2>
                    </div>

                    <div className="flex mt-4 md:mt-0">
                      <Button
                        variant={ButtonVariant.PRIMARY}
                        type="button"
                        className="mr-2"
                        // isDisabled={!isValid}
                        onClick={
                          isEditable ? handleSubmit(onSubmit) : handleToggleEdit
                        }
                      >
                        {isEditable ? "Save" : "Edit"}
                      </Button>
                      <OutlinedButton
                        type="button"
                        onClick={closeModal}
                        className="mr-2"
                        variant={ButtonVariant.PRIMARY}
                      >
                        Close
                      </OutlinedButton>
                    </div>
                  </div>
                </Dialog.Title>
                <div className="p-4 overflow-auto h-[75vh]">
                  <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <hr className="my-2" />
                    {!patientPersonalInformation?.isOnboarded && (
                      <div className="flex flex-col justify-start lg:flex-row lg:justify-between lg:items-center p-2 rounded-2xl shadow bg-gradient-to-r from-primary-50 to-primary-50">
                        <div className="flex flex-row lg:flex-col">
                          <div className="flex flex-col justify-start">
                            <span className="text-2xl font-bold text-black">
                              Patient Onboard Form Link
                            </span>
                            <span className="text-sm text-black">
                              Patient not onboarded yet. Please send onboard
                              link.
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-row mt-2 justify-start lg:flex-col lg:justify-normal lg:m-0">
                          <div className="flex flex-col mt-4 lg:flex-row lg:mt-0">
                            <Button
                              variant={ButtonVariant.PRIMARY}
                              type="button"
                              onClick={handleRequestFormLink}
                              children="Send Email"
                              className="mb-2 lg:mb-0 lg:mr-2"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                      <div className="flex flex-col grow">
                        <Applabel label="Referring Hospital" />

                        <HospitalSearch
                          data={referringData?.ReferringHospitals || []}
                          setSelectedHospitalName={setSelectedHospitalName}
                          selectedHospitalName={selectedHospitalName}
                          isEditable={!isEditable}
                          register={register}
                          setValue={setValue}
                          clearErrors={clearErrors}
                        />
                        {errors.referringHospital && (
                          <ValidationError
                            message={
                              errors.referringHospital?.message?.toString() ??
                              ""
                            }
                          />
                        )}
                      </div>
                      <div className="flex flex-col grow">
                        <Applabel label="Referring Doctor" />

                        <DoctorSearch
                          data={referringData?.ReferringUsers || []}
                          setSelectedDoctorName={setSelectedDoctorName}
                          selectedDoctorName={selectedDoctorName}
                          isEditable={!isEditable}
                          register={register}
                          setValue={setValue}
                          clearErrors={clearErrors}
                        />
                        {errors.referringUser && (
                          <ValidationError
                            message={
                              errors.referringUser?.message?.toString() ?? ""
                            }
                          />
                        )}
                      </div>

                      <div className="flex flex-col grow">
                        <Applabel label="Other Referral" />

                        <OtherReferralSearch
                          data={referringData?.OtherReferrals || []}
                          setSelectedOtherReferralName={
                            setSelectedOtherReferralName
                          }
                          selectedOtherReferralName={selectedOtherReferralName}
                          isEditable={!isEditable}
                          register={register}
                          setValue={setValue}
                          clearErrors={clearErrors}
                        />
                        {errors.otherReferral && (
                          <ValidationError
                            message={
                              errors.otherReferral?.message?.toString() ?? ""
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                      <div className="flex flex-col  w-full md:w-1/4">
                        <Applabel label="Title" />
                        <select
                          {...register("salutation")}
                          placeholder="Salutation"
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          disabled={!isEditable}
                          value={watch("salutation")}
                        >
                          <option key="" value="">
                            Select
                          </option>
                          {[
                            "Mr",
                            "Mrs",
                            "Ms",
                            "Miss",
                            "Dr",
                            "Prof",
                            "Rev",
                            "Hon",
                            "Capt",
                            "Lt. Col",
                          ].map((salutation) => {
                            return (
                              <option key={salutation} value={salutation}>
                                {salutation}
                              </option>
                            );
                          })}
                        </select>
                        {errors.salutation && (
                          <ValidationError
                            message={
                              errors.salutation?.message?.toString() ?? ""
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                      <div className="flex flex-col  w-full md:w-1/3">
                        <Applabel label="First Name" mandatory />
                        <input
                          {...register("firstName")}
                          disabled={!isEditable}
                          placeholder="Patient First Name"
                          value={watch("firstName")}
                          type="text"
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        />
                        {errors.firstName && (
                          <ValidationError
                            message={
                              errors.firstName?.message?.toString() ?? ""
                            }
                          />
                        )}
                      </div>
                      <div className="flex flex-col  w-full md:w-1/3">
                        <Applabel label="Middle Name" />
                        <input
                          {...register("middleName")}
                          placeholder="Patient Middle Name"
                          disabled={!isEditable}
                          value={watch("middleName")}
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        />
                        {errors.middleName && (
                          <ValidationError
                            message={
                              errors.middleName?.message?.toString() ?? ""
                            }
                          />
                        )}
                      </div>
                      <div className="flex flex-col  w-full md:w-1/3">
                        <Applabel label="Last Name" mandatory />
                        <input
                          {...register("lastName")}
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          placeholder="Patient Last Name"
                          disabled={!isEditable}
                          value={watch("lastName")}
                        />
                        {errors.lastName && (
                          <ValidationError
                            message={errors.lastName?.message?.toString() ?? ""}
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                      <div className="flex flex-col  w-full md:w-1/3">
                        <Applabel label="Date of Birth" mandatory />
                        <input
                          {...register("dob")}
                          className={`flex lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          type="date"
                          disabled={!isEditable}
                          value={moment(watch("dob")).format("YYYY-MM-DD")}
                          onChange={handleDateChange}
                        />
                        {errors.dob && (
                          <ValidationError
                            message={errors.dob?.message?.toString() ?? ""}
                          />
                        )}
                      </div>
                      <div className="flex flex-col  w-full md:w-1/3">
                        <Applabel label="Age" />
                        <input
                          {...register("age")}
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          type="number"
                          min={1}
                          max={200}
                          placeholder="Patient Age"
                          disabled
                          value={watch("age")}
                        />
                        {errors.age && (
                          <ValidationError
                            message={errors.age?.message?.toString() ?? ""}
                          />
                        )}
                      </div>
                      <div className="flex flex-col  w-full md:w-1/3">
                        <Applabel label="Place of Birth" />
                        <input
                          {...register("birthPlace")}
                          placeholder="Patient Place of Birth"
                          disabled={!isEditable}
                          value={watch("birthPlace")}
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        />
                        {errors.birthPlace && (
                          <ValidationError
                            message={
                              errors.birthPlace?.message?.toString() ?? ""
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 mb-4">
                      <div className="flex flex-col  w-full md:w-1/3">
                        <Applabel label="Gender" />
                        <select
                          {...register("gender")}
                          placeholder="Patient Gender"
                          disabled={!isEditable}
                          value={watch("gender")}
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                        >
                          <option key="" value="">
                            Select
                          </option>
                          {["Male", "Female", "Other"].map((Gender) => {
                            return (
                              <option key={Gender} value={Gender}>
                                {Gender}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="flex flex-col  w-full md:w-1/3">
                        <Applabel label="Email" />
                        <input
                          {...register("email")}
                          placeholder="Patient Email"
                          disabled={!isEditable}
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          value={watch("email")}
                          required
                        />
                        {errors.email && (
                          <ValidationError
                            message={errors.email?.message?.toString() ?? ""}
                          />
                        )}
                      </div>
                      <div className="flex flex-col  w-full md:w-1/3">
                        <div className="flex flex-row items-center">
                          <Applabel label="Mobile Number" mandatory />
                        </div>

                        <div className=" flex flex-row">
                          {mobileNumber === false ? (
                            <input
                              disabled={true}
                              className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                              value={
                                userData?.user_role === "ENTITY_OWNER" ||
                                userData?.user_role === "PLATFORM_ADMIN"
                                  ? `+${phoneCode} ${Phone}`
                                  : Phone != null
                                  ? Phone.toString().length > 4
                                    ? `+${phoneCode} ${Phone}`
                                    : `+${phoneCode} ******${Phone}`
                                  : ""
                              }
                              required
                            />
                          ) : (
                            <div className="flex flex-row w-full">
                              <PhoneInput
                                onCountryChange={(
                                  country?: CountryCode | undefined
                                ) => {
                                  if (country) {
                                    const countryCode =
                                      getCountryCallingCode(country);
                                    setValue(
                                      "phoneCode",
                                      countryCode?.toString() ?? ""
                                    );
                                  }
                                }}
                                // {...register("phone")}
                                international={true}
                                disabled={!isEditable}
                                defaultCountry="IN"
                                countryCallingCodeEditable={false}
                                placeholder="Enter phone number"
                                // value={watch("phone")}
                                onChange={(value?: E164Number | undefined) => {
                                  clearErrors("phone");
                                  handlePhoneNumberChange(value);
                                }}
                                onBlur={() => {
                                  trigger("phone");
                                }}
                                className="w-full"
                              />
                            </div>
                          )}
                          <Button
                            isDisabled={!isEditable}
                            variant={ButtonVariant.PRIMARY}
                            type="button"
                            onClick={() => {
                              setMobileNumber(true);
                            }}
                            children="Change"
                            className="ml-2 mb-2 lg:mb-0 lg:mr-2"
                          />
                        </div>

                        {errors.phone && (
                          <ValidationError
                            message={errors.phone?.message?.toString() ?? ""}
                          />
                        )}
                      </div>
                    </div>
                    <span className="flex-none font-medium w-1/6 mb-0">
                      Correspondence Address
                    </span>
                    <br />
                    <span className="text-gray-500">
                      Please fill the patient's most recent correspondence
                      address below
                    </span>
                    <hr className="my-2" />
                    <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
                      <div className="flex flex-col grow">
                        <Applabel label="Address" mandatory />

                        <input
                          {...register("address.address")}
                          placeholder="Patient Address"
                          disabled={!isEditable}
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          required
                        />
                        {errors.address?.address && (
                          <ValidationError
                            message={
                              errors.address?.address?.message?.toString() ?? ""
                            }
                          />
                        )}
                      </div>
                      <div className="flex flex-col grow">
                        <Applabel label="Postal/Zip code" mandatory />

                        <input
                          {...register("address.postalCode")}
                          placeholder="Patient Zip Code"
                          disabled={!isEditable}
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          required
                          value={watch("address.postalCode")}
                        />
                        {errors.address?.postalCode && (
                          <ValidationError
                            message={
                              errors.address.postalCode?.message?.toString() ??
                              ""
                            }
                          />
                        )}
                      </div>
                    </div>
                    <CountryInputSelect
                      isDisabled={!isEditable}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      country="address.country"
                      state="address.state"
                      city="address.city"
                      isRequired={true}
                      clearErrors={clearErrors}
                      errors={errors}
                    />

                    <span className="flex-none font-medium w-1/6 mb-0">
                      Medical Details
                    </span>
                    <br />
                    <span className="text-gray-500">
                      Please enter the patient's medical information below
                    </span>
                    <hr className="my-2" />
                    <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 mb-4">
                      <div className="flex flex-col  w-full md:w-1/3">
                        <Applabel label="Blood Group" />

                        <select
                          {...register("bloodGroup")}
                          placeholder="BloodGroup"
                          className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                          disabled={!isEditable}
                          value={watch("bloodGroup")}
                        >
                          <option key="" value="">
                            Select
                          </option>
                          {[
                            "A +ve",
                            "A -ve",
                            "B +ve",
                            "B -ve",
                            "AB +ve",
                            "AB -ve",
                            "O +ve",
                            "O -ve",
                          ].map((bloodGroup) => {
                            return (
                              <option key={bloodGroup} value={bloodGroup}>
                                {bloodGroup}
                              </option>
                            );
                          })}
                        </select>
                        {errors.bloodGroup && (
                          <ValidationError
                            message={
                              errors.bloodGroup?.message?.toString() ?? ""
                            }
                          />
                        )}
                      </div>
                      <div className="flex flex-col  w-full md:w-1/3">
                        <Applabel label="Emergency Phone" />
                        <div className="flex flex-row">
                          {" "}
                          <PhoneInput
                            onCountryChange={(
                              country?: CountryCode | undefined
                            ) => {
                              if (country) {
                                const countryCode =
                                  getCountryCallingCode(country);
                                setValue(
                                  "emergencyPhoneCode",
                                  countryCode?.toString() ?? ""
                                );
                              }
                            }}
                            {...register("emergencyPhone")}
                            international={true}
                            countryCallingCodeEditable={false}
                            placeholder="Enter emergency phone number"
                            value={watch("emergencyPhone")}
                            onChange={(value?: E164Number | undefined) => {
                              clearErrors("emergencyPhone");
                              handleEmergencyPhoneNumberChange(value);
                            }}
                            onBlur={() => {
                              trigger("emergencyPhone");
                            }}
                            className="w-full"
                          />
                        </div>
                        {errors.emergencyPhone && (
                          <ValidationError
                            message={
                              errors.emergencyPhone?.message?.toString() ?? ""
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row items-center mt-6">
                      <input
                        {...register("whatsAppConsent")}
                        type="checkbox"
                        disabled={!isEditable}
                        className="form-checkbox h-6 w-6 text-indigo-600 rounded-md focus:ring-2 focus:ring-indigo-600"
                      />
                      <label className="ml-3 text-gray-800 font-medium">
                        Send notifications, reminders, important updates through
                        WhatsApp
                      </label>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
