import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import StaffInformationComponent from "./StaffInformationComponent";
import Appointments from "./Appointments";
import { useParams, useSearchParams } from "react-router-dom";
import ConfigurationStep from "./ConfigurationStep";
import Button, { ButtonVariant } from "@components/button/button";
import { paths } from "@routes/paths";
import MonthlyAppointmentStats from "./MonthlyAppointmentStats";
interface Tab {
  name: string;
  current: boolean;
  key: string;
}

function ViewBranchPage() {
  const [activeTab, setActiveTab] = useState("information");
  let { staffId } = useParams();
  const navigator = useNavigate();
  const [timeZone, setStaffTimeZone] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showBillingStats = queryParams.get("p");

  const userData = getUserPersistedOnLocalStorage();

  const params = useParams();

  const tabComponents: Record<string, React.ReactNode> = {
    information: (
      <StaffInformationComponent
        staffId={staffId ?? ""}
        setStaffTimeZone={setStaffTimeZone}
      />
    ),
    workinghours: (
      <ConfigurationStep staffId={staffId ?? ""} timeZone={timeZone} />
    ),
    appointments: <Appointments staffId={staffId ?? ""} />,
    mas: <MonthlyAppointmentStats staffId={staffId ?? ""} />,
  };

  const tabs: Tab[] = [
    {
      name: "Information",
      key: "information",
      current: activeTab === "information",
    },
    {
      name: "Working Hours",
      key: "workinghours",
      current: activeTab === "workinghours",
    },
    {
      name: "Appointments",
      key: "appointments",
      current: activeTab === "appointments",
    },
    {
      name: "Monthly Appointment & Billing Stats",
      key: "mas",
      current: activeTab === "mas",
    },
  ];

  React.useEffect(() => {
    if (showBillingStats === "true") {
      setActiveTab("mas");
    }
  }, []);

  function handleTabClick(tabName: string) {
    setActiveTab(tabName);
  }
  return (
    <>
      <div className="flex flex-col">
        <div className="flex-shrink">
          <div className="flex flex-col justify-start lg:flex-row lg:justify-between lg:items-center border-gray-200 pb-5 sm:pb-0">
            <div className="flex flex-row justify-between lg:justify-between w-full">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Staff Details
              </h3>
              <Button
                variant={ButtonVariant.PRIMARY}
                onClick={() => {
                  navigator(-1);
                }}
                type="button"
              >
                Back
              </Button>
            </div>
          </div>
          <hr className="my-2"></hr>
          <div className="mt-2 flex flex-row justify-between lg:justify-between w-full">
            <div className="block md:hidden w-full">
              <label htmlFor="current-tab" className="sr-only">
                Select a tab
              </label>
              <select
                id="current-tab"
                name="current-tab"
                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                value={activeTab}
                onChange={(e) => {
                  handleTabClick(e.target.value);
                }}
              >
                {tabs.map((tab) => (
                  <option key={tab.name} value={tab.key}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-8 sm:mt-4 hidden md:block">
              <nav className="-mb-px flex space-x-8">
                {tabs.map((tab) => (
                  <button
                    key={tab.name}
                    className={`${
                      tab.current
                        ? "border-primary-500 text-primary-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    } whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium focus:outline-none`}
                    onClick={() => handleTabClick(tab.key)}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>

        {tabComponents[activeTab]}
      </div>
    </>
  );
}

export default ViewBranchPage;
