// To parse this data:
//
//   import { Convert, BillingDetails } from "./file";
//
//   const billingDetails = Convert.toBillingDetails(json);

export type BillingDetails = {
  clientOutStandingBillAmount?: number;
  clientTotalBillAmount?: number;
  clientTotalBillAmountPaid?: number;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toBillingDetails(json: string): BillingDetails {
    return JSON.parse(json);
  }

  public static billingDetailsToJson(value: BillingDetails): string {
    return JSON.stringify(value);
  }
}
