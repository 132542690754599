import React, { createContext, useEffect, useState } from 'react';
import PersonalInformation from './PersonalInformation';
import MedicalHistory from './MedicalHistory';
import AssessmentHistory from './AssessmentHistory';
import Diagnosis from './Diagnosis';
import TreatmentPlan from './TreatmentPlan';
import PatientExtras from './PatientExtras';
import PatientFeedback from './PatientFeedback';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  HomeIcon,
  UsersIcon,
  InboxArrowDownIcon,
  DevicePhoneMobileIcon,
  CalendarDaysIcon,
  CakeIcon,
  GlobeAsiaAustraliaIcon,
  BuildingOffice2Icon,
  HeartIcon,
  FolderIcon,
  CalendarIcon,
  DocumentDuplicateIcon,
  ChartPieIcon,
  AtSymbolIcon,
} from '@heroicons/react/24/outline';
import { endpoints, replaceParams } from '@utils/axios';
import axios from 'axios';
import {
  Convert,
  PersonalInformationDetails,
} from '../types/PatientPersonalDetails';
import NewHomeExerciseProgram from './HomeExerciseProgramComponents/HomeExerciseProgram';
export const ManagePatientContext = createContext({ patientId: '' });

const Tabs = [
  'Personal Information',
  'Medical History',
  'Assessment History',
  'Diagnosis',
  'Treatment Plan',
  'Exercise Program',
  'Feedback',
  'Extra',
];

const steps = [
  PersonalInformation,
  MedicalHistory,
  AssessmentHistory,
  Diagnosis,
  TreatmentPlan,
  NewHomeExerciseProgram,
  PatientFeedback,
  PatientExtras,
];

export default function ManagePatient() {
  let [activeTab, setActiveTab] = useSearchParams({ tab: Tabs[0] });
  const [patientPersonalInformation, setPatientPersonalInformation] =
    React.useState<PersonalInformationDetails | null>(null);
  const [currentStep, setCurrentStep] = useState(
    Tabs.indexOf(activeTab.get('tab') ?? Tabs[0]),
  );

  let { patientId } = useParams();

  const TabContentComponent = steps[currentStep];

  if (!patientId) {
    return <div>Invalid patient id</div>;
  }

  React.useEffect(() => {
    const getPatients = async () => {
      try {
        const paramsMap = new Map<string, string>([['patientId', patientId!]]);
        const filteredEndpoint = replaceParams(
          endpoints.patient.view,
          paramsMap,
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data['data'];
        const convertedPersonalInformation = Convert.toPersonalInformation(
          JSON.stringify(data),
        );
        setPatientPersonalInformation(convertedPersonalInformation);
      } catch (error) {
        console.error('Error getting patient information', error);
      }
    };
    getPatients();
  }, []);

  return (
    <ManagePatientContext.Provider value={{ patientId: patientId }}>
      <div className="flex flex-col h-full space-y-3">
        <div className="flex flex-col lg:flex-row w-full h-max">
          <div className="flex flex-row lg:hidden w-full h-10 bg-gray-100 py-2"></div>
          <div className=" flex flex-col mr-4 grow xl:w-1/5 xl:h-full py-2  justify-between">
            <div className="grid grid-cols-4  lg:grid-rows-1 lg:grid-cols-1 gap-4  ">
              {Tabs.map((tab, index) => (
                <div
                  className={`flex  px-4 py-2 w-full justify-between rounded-xl ${
                    index === currentStep
                      ? 'bg-primary-100 text-primary'
                      : 'bg-transparent text-gray-500'
                  }`}
                  key={tab}
                  onClick={() => {
                    setActiveTab({ tab: Tabs[index] });
                    setCurrentStep(index);
                  }}
                >
                  <span className=" flex-initial text-black">{tab}</span>
                </div>
              ))}
            </div>
            <div className="flex flex-row w-full border rounded-xl p-4  lg:flex-col justify-evenly  lg:space-y-4  mb-7 overflow-hidden">
              <div className="flex  flex-row space-x-2 items-center">
                <UsersIcon className="text-gray-400 group-hover:text-tertiary-600 h-8 w-8 shrink-0" />
                <div className="flex flex-col">
                  <span className="flex-none font-medium text-xs text-gray-500 mb-0">
                    Full name
                  </span>
                  <span className="font-semibold text-sm">
                    {patientPersonalInformation?.firstName}
                  </span>
                </div>
              </div>

              <div className="flex flex-row space-x-2 items-center">
                <DevicePhoneMobileIcon className="text-gray-400 group-hover:text-tertiary-600 h-8 w-8 shrink-0" />
                <div className="flex flex-col">
                  <span className="flex-none font-medium text-xs text-gray-500 mb-0">
                    Phone Number
                  </span>
                  <span className="font-semibold text-sm">
                    {patientPersonalInformation?.phoneCode}
                    {patientPersonalInformation?.phone}
                  </span>
                </div>
              </div>
              <div className="flex flex-row space-x-2 items-center">
                <AtSymbolIcon className="text-gray-400 group-hover:text-tertiary-600 h-8 w-8 shrink-0" />
                <div className="flex flex-col">
                  <span className="flex-none font-medium text-xs text-gray-500 mb-0">
                    Email
                  </span>
                  <span className="font-semibold text-sm truncate w-28">
                    {patientPersonalInformation?.email ?? 'Not Known'}
                  </span>
                </div>
              </div>
              <div className="flex flex-row space-x-2 items-center">
                <HeartIcon className="text-gray-400 group-hover:text-tertiary-600 h-8 w-8 shrink-0" />
                <div className="flex flex-col">
                  <span className="flex-none font-medium text-xs text-gray-500 mb-0">
                    Gender
                  </span>
                  <span className="font-semibold text-sm">
                    {patientPersonalInformation?.gender ?? 'Not Known'}
                  </span>
                </div>
              </div>
              <div className="flex flex-row space-x-2 items-center">
                <CalendarDaysIcon className="text-gray-400 group-hover:text-tertiary-600 h-8 w-8 shrink-0" />
                <div className="flex flex-col">
                  <span className="flex-none font-medium text-xs text-gray-500 mb-0">
                    Age
                  </span>
                  <span className="font-semibold text-sm">
                    {patientPersonalInformation?.age}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full md:w-4/4 md:h-full  px-4 py-2 border-l border-gray-200 ">
            <TabContentComponent />
          </div>
        </div>
      </div>
    </ManagePatientContext.Provider>
  );
}
