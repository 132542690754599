import { formatPhoneNumber } from 'react-phone-number-input';

export type PhoneNumberDataString = {
  phoneNumber: string | undefined;
  phoneCode: string | undefined;
};

export type PhoneNumberDataInt = {
  phoneNumber: number;
  phoneCode: number;
};

export function convertPhoneNumberAndCodeToNumber(
  phoneNumberData: PhoneNumberDataString,
) {
  let phoneConverted;
  let phoneCodeConverted;
  if (phoneNumberData.phoneNumber) {
    // Extract and format phone number
    const phoneNumber = formatPhoneNumber(phoneNumberData.phoneNumber);
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
    phoneConverted = parseInt(numericPhoneNumber.trim());
  } else {
    phoneConverted = 0;
  }

  if (phoneNumberData.phoneCode) {
    // Extract and format phone code
    const numericPhoneCode = phoneNumberData.phoneCode.replace(/\D/g, '');
    phoneCodeConverted = parseInt(numericPhoneCode.trim());
  } else {
    phoneCodeConverted = 0;
  }
  // Return converted phone number and phone code
  return {
    phoneNumber: phoneConverted,
    phoneCode: phoneCodeConverted,
  };
}

export function convertPhoneNumberAndCodeToString(
  phoneNumberData: PhoneNumberDataInt,
) {
  const phoneNumberWithCountryCode =
    '+' +
    phoneNumberData.phoneCode.toString() +
    ' ' +
    phoneNumberData.phoneNumber.toString();
  return {
    phoneNumber:
      phoneNumberData.phoneNumber !== 0 ? phoneNumberWithCountryCode : '',
    phoneCode:
      phoneNumberData.phoneCode !== 0
        ? phoneNumberData.phoneCode.toString()
        : '',
  };
}
