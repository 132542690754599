import {
  getUserPersistedOnLocalStorage,
  setSession,
  setUserData,
} from '@authentication/context/jwt/utils';
import { useAuthContext } from '@authentication/hooks/use-auth-context';
import useRefreshToken from '@authentication/hooks/use-refresh-token';
import { AuthTokensType } from '@authentication/types';
import { STORAGE_KEYS } from '@constants/storage-keys';
import { endpoints } from '@utils/axios';
import axios from 'axios';

import { log } from 'console';
import { useCallback, useEffect } from 'react';

const useAxios = () => {
  const refresh = useRefreshToken();
  const { reset } = useAuthContext();

  useEffect(() => {
    const responseIntercept = axios.interceptors.response.use(
      response => response,
      async error => {
        const prevRequest = error?.config;

        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          try {
            const refresh_token = sessionStorage.getItem(
              STORAGE_KEYS.APP_STATE.REFRESH_TOKEN,
            );
            const data: Record<string, string> = {
              refresh_token: refresh_token ?? '',
            };

            const response = await axios.post(endpoints.auth.refresh, data);

            const tokens: AuthTokensType = {
              accessToken: response.data['data'].access_token,
              refreshToken: response.data['data'].refresh_token,
            };

            setSession(tokens.accessToken, tokens.refreshToken);

            setUserData(tokens.accessToken);

            prevRequest.headers['Authorization'] =
              'Bearer ' + tokens.accessToken;
            const retryReq = await axios.request(prevRequest);
            return retryReq;
          } catch (error) {
            if (error?.response?.status === 403) {
              reset();
            }
          }
        }
        return Promise.reject(error);
      },
    );
    return () => {
      //  axios.interceptors.request.eject(requestIntercept);
      axios.interceptors.response.eject(responseIntercept);
    };
  }, [sessionStorage.getItem(STORAGE_KEYS.APP_STATE.ACCESS_TOKEN), refresh]);

  return axios;
};

export default useAxios;
