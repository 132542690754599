// To parse this data:
//
//   import { Convert, MuscleTightness } from "./file";
//
//   const muscleTightness = Convert.toMuscleTightness(json);

export interface MuscleTightness {
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  details: Detail[];
}

export interface Detail {
  name: string;
  grading: string;
}

// Converts JSON strings to/from your types
export class ConvertMuscleTightness {
  public static toMuscleTightness(json: string): MuscleTightness {
    return JSON.parse(json);
  }

  public static muscleTightnessToJson(value: MuscleTightness): string {
    return JSON.stringify(value);
  }
}
