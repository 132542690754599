import React, { useEffect, useState } from "react";

import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { Combobox } from "@headlessui/react";
import { Convert, Patient } from "../types/PatientType";
import {
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

export default function PatientSearch(props: {
  data: Patient[];
  patientId: React.Dispatch<React.SetStateAction<string | null>>;
  patientName: React.Dispatch<React.SetStateAction<string | null>>;
  patientEmail: React.Dispatch<React.SetStateAction<string | null>>;
  patientPhone: React.Dispatch<React.SetStateAction<number | null>>;
  register: UseFormRegister<{
    patientId: string;
    appointmentType: string;
    physioId: string;
    startTime: string;
  }>;
  setValue: UseFormSetValue<{
    patientId: string;
    appointmentType: string;
    physioId: string;
    startTime: string;
  }>;
  clearErrors: UseFormClearErrors<{
    patientId: string;
    appointmentType: string;
    physioId: string;
    startTime: string;
  }>;
}) {
  const userData = getUserPersistedOnLocalStorage();
  const [query, setQuery] = useState("");

  const [selectedPerson, setSelectedPerson] = useState(null);

  const filteredPeople =
    query === ""
      ? props.data
      : props.data.filter(
          (patient: {
            firstName: string;
            middleName: string;
            lastName: string;
            phone: number;
          }) =>
            patient.firstName
              .replace(/\s/g, "")
              .trim()
              .toLowerCase()
              .includes(query.toLowerCase()) ||
            (patient.firstName + patient.lastName)
              .replace(/\s/g, "")
              .trim()
              .toLowerCase()
              .includes(query.replace(/\s/g, "").trim().toLowerCase()) ||
            patient.middleName
              .replace(/\s/g, "")
              .trim()
              .toLowerCase()
              .includes(query.toLowerCase()) ||
            patient.lastName
              .replace(/\s/g, "")
              .trim()
              .toLowerCase()
              .includes(query.replace(/\s/g, "").trim().toLowerCase()) ||
            patient.phone
              .toString()
              .replace(/\s/g, "")
              .trim()
              .toLowerCase()
              .includes(query.replace(/\s/g, "").trim().toLowerCase())
        );

  useEffect(() => {
    if (selectedPerson != null) {
      const d: Patient = Convert.toPatient(JSON.stringify(selectedPerson));
      props.patientId(d.id);
      props.patientName(d.firstName + " " + d.lastName);
      props.patientEmail(d.email);
      props.patientPhone(d.phone);
      props.setValue("patientId", d.id);
      props.clearErrors("patientId");
    }
  }, [selectedPerson]);

  return (
    <Combobox as="div" value={selectedPerson} onChange={setSelectedPerson}>
      {/* <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        Select Patient
      </Combobox.Label> */}

      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(person: Patient) =>
            `${person?.firstName ?? ""} ${person?.lastName ?? ""}`
          }
          autoComplete="off"
        />

        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredPeople.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map((person) => (
              <Combobox.Option
                key={person.id}
                value={person}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",

                    active ? "bg-secondary-300 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex flex-col">
                      <span
                        className={classNames(
                          "truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {person.firstName}&nbsp;{person.lastName}
                      </span>
                      <span
                        className={classNames(
                          "ml-2 truncate text-gray-500",

                          active ? "text-indigo-200" : "text-gray-500"
                        )}
                      >
                        +{person?.phoneCode}{" "}
                        {userData?.user_role === "ENTITY_OWNER" ||
                        userData?.user_role === "PLATFORM_ADMIN"
                          ? `${person?.phone}`
                          : `${
                              person?.phone.toString().length > 4
                                ? `${person?.phone}`
                                : `******${person?.phone}`
                            }`}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",

                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
