// To parse this data:
//
//   import { Convert } from "./file";
//
//   const exerciseMedia = Convert.toExerciseMedia(json);

export interface ExerciseMedia {
  id: string;
  ownerId: string;
  name: string;
  contentType: string;
  size: number;
  url: string;
  fileCategory: string;
  audit: Audit;
}

export interface Audit {
  uploadedBy: string;
  uploadedAt: Date;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toExerciseMedia(json: string): ExerciseMedia[] {
    return JSON.parse(json);
  }

  public static exerciseMediaToJson(value: ExerciseMedia[]): string {
    return JSON.stringify(value);
  }
}
