import React from "react";
import { Reflex } from "../type/Reflex";

type ReflexProps = {
  refleAssessment: Reflex | null;
};

const ReflexAssessment = (props: ReflexProps) => {
  return (
    <div className="flex flex-col w-full h-full">
      <h3 className="mb-4 font-bold text-tertiary-500">Reflex Assessment</h3>

      <div className="flex flex-col items-stretch">
        <div className="inline-flex items-center justify-center my-2 rounded-lg text-lg px-2 py-1 font-normal lg:rounded-xl lg:text-lg lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
          Deep Tendon Reflexes
        </div>
        <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
          <thead className="bg-gray-50">
            <tr>
              <th
                className="border px-2 py-1 border-slate-300 "
                style={{ width: "75%" }}
              >
                Name
              </th>
              <th
                className="border px-2 py-1 border-slate-300"
                style={{ width: "25%" }}
              >
                Grading
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-2 py-1 border-slate-300 ">Jaw Jerk</td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.refleAssessment?.jawJerk}</p>
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1 border-slate-300 ">
                Biceps Reflex
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.refleAssessment?.bicepsReflex}</p>
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1 border-slate-300 ">
                Triceps Reflex
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.refleAssessment?.tricepsReflex}</p>
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1 border-slate-300 ">
                Brachioradialis Reflex
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.refleAssessment?.brachioradialisReflex}</p>
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1 border-slate-300 ">
                Finger Reflex
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.refleAssessment?.fingerReflex}</p>
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1 border-slate-300 ">
                Abdominal Reflex
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.refleAssessment?.fingerReflex}</p>
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1 border-slate-300 ">
                Knee Reflex
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.refleAssessment?.kneeReflex}</p>
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1 border-slate-300 ">
                Ankle Reflex
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.refleAssessment?.ankleReflex}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex flex-col items-stretch">
        <div className="inline-flex items-center justify-center my-2 rounded-lg text-lg px-2 py-1 font-normal lg:rounded-xl lg:text-lg lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
          Superficial Reflexes
        </div>
        <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
          <thead className="bg-gray-50">
            <tr>
              <th
                className="border px-2 py-1 border-slate-300 "
                style={{ width: "75%" }}
              >
                Name
              </th>
              <th
                className="border px-2 py-1 border-slate-300"
                style={{ width: "25%" }}
              >
                Grading
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-2 py-1 border-slate-300 ">
                Corneal Reflex
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.refleAssessment?.cornealReflex}</p>
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1 border-slate-300 ">
                Plantar Reflex
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.refleAssessment?.plantarReflex}</p>
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1 border-slate-300 ">
                Abdominal Reflex
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.refleAssessment?.abdominalReflexSuperficial}</p>
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1 border-slate-300 ">
                Cremasteric Reflex
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.refleAssessment?.abdominalReflexSuperficial}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex flex-col items-stretch">
        <div className="inline-flex items-center justify-center my-2 rounded-lg text-lg px-2 py-1 font-normal lg:rounded-xl lg:text-lg lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-500 text-white ">
          Visceral Reflexes
        </div>
        <table className="table-fixed  text-left text-lg border-collapse border border-slate-400 w-full">
          <thead className="bg-gray-50">
            <tr>
              <th
                className="border px-2 py-1 border-slate-300 "
                style={{ width: "75%" }}
              >
                Name
              </th>
              <th
                className="border px-2 py-1 border-slate-300"
                style={{ width: "25%" }}
              >
                Grading
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-2 py-1 border-slate-300 ">
                Anal Reflex
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.refleAssessment?.analReflex}</p>
              </td>
            </tr>
            <tr>
              <td className="border px-2 py-1 border-slate-300 ">
                Bulbocavernous Reflex
              </td>
              <td className="border px-2 py-1 text-center border-slate-300">
                <p>{props.refleAssessment?.bulbocavernosusReflex}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr className=" my-12 border-tertiary-500"></hr>
    </div>
  );
};

export default ReflexAssessment;
