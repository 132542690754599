// To parse this data:
//
//   import { Convert, LimbLength } from "./file";
//
//   const limbLength = Convert.toLimbLength(json);

export interface LimbLength {
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  trueLimbLengthLeft: number;
  trueLimbLengthRight: number;
  apparentLimbLengthLeft: number;
  apparentLimbLengthRight: number;
  humeralLimbLengthLeft: number;
  humeralLimbLengthRight: number;
  ulnarLimbLengthLeft: number;
  ulnarLimbLengthRight: number;
  femoralLimbLengthLeft: number;
  femoralLimbLengthRight: number;
  tibialLimbLengthLeft: number;
  tibialLimbLengthRight: number;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toLimbLength(json: string): LimbLength {
    return JSON.parse(json);
  }

  public static limbLengthToJson(value: LimbLength): string {
    return JSON.stringify(value);
  }
}
