import { OutlinedButton } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import SolidButton from "@components/button/button";

interface DeleteConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (id: string) => void;
  selectedExpenseId: string;
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({ isOpen, onClose, onConfirm, selectedExpenseId }) => {
  const [confirmationCode, setConfirmationCode] = useState(generateRandomCode());
  const [userInput, setUserInput] = useState('');

  const handleDelete = () => {
    // Check if the user input matches the confirmation code
    if (userInput === confirmationCode) {
      // Proceed with deletion
      onConfirm(selectedExpenseId);
      // Reset user input
      setUserInput('');
      // Close confirmation dialog
      onClose();
    } else {
      // Incorrect code, show error or alert
      alert('Incorrect confirmation code. Please try again.');
      // Reset user input
      setUserInput('');
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-xl font-bold leading-6 px-6 pt-6 text-gray-900">
                  Delete Confirmation
                </Dialog.Title>
                <div className="max-h-[75vh] overflow-auto px-6 pt-6">

                  <p className="mb-4">To confirm deletion, please enter code {confirmationCode}</p>
                  <input
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Enter confirmation code"
                    className="w-full border rounded-lg px-4 py-2 mb-4"
                  />
                </div>

                <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4 mt-2">
                  <div className="flex flex-row w-full justify-end">

                    <SolidButton
                      variant={ButtonVariant.PRIMARY}
                      className="mr-2"
                      onClick={handleDelete}
                    >
                      Confirm
                    </SolidButton>
                    <OutlinedButton
                      variant={ButtonVariant.SECONDARY}
                      type="button"
                      onClick={onClose}
                      children="Cancel"
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const generateRandomCode = () => {
  // Generate a random 4-digit number
  const randomNumber = Math.floor(1000 + Math.random() * 9000);
  return randomNumber.toString();
};

export default DeleteConfirmation;
