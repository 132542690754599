// To parse this data:
//
//   import { Convert, Entity } from "./file";
//
//   const entity = Convert.toEntity(json);

export interface Entity {
  id: string;
  name: string;
  email: string;
  phone: number;
  phoneCode: number;
  address: Address;
  isActive: boolean;
  logoUrl: string;
  logoId: string;
}

export interface Address {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toEntity(json: string): Entity {
    return JSON.parse(json);
  }

  public static entityToJson(value: Entity): string {
    return JSON.stringify(value);
  }
}
