import Modal from "@components/modal";
import React, { useState } from "react";
import { Column, TreatmentProtocolDetail } from "../types/TreatmentProtocol";
import { ButtonVariant } from "@components/button/button";
import { OutlinedButton } from "@components/button";
import { toast } from "react-toastify";

function AddStaticData(props: {
  addStaticData: boolean;
  setAddStaticData: React.Dispatch<React.SetStateAction<boolean>>;
  staticProtocolIndex: number;
  array: TreatmentProtocolDetail[];
  headers: Column[];
  setArray: React.Dispatch<React.SetStateAction<TreatmentProtocolDetail[]>>;
}) {
  const [staticFieldData, setStaticFieldData] = useState<(string | boolean)[]>(
    new Array(props.headers.length).fill("")
  );

  const handleStaticFieldChange = (index: number, value: string | boolean) => {
    const newData = [...staticFieldData];
    newData[index] = value;
    setStaticFieldData(newData);
  };

  const handleAddRow = () => {
    for (let i = 0; i < props.headers.length; i++) {
      const column = props.headers[i];
      if (column.type === "static" && !staticFieldData[i]) {
        toast.error("Please fill in all input fields.");
        return;
      }
    }

    const newData = [...props.array];
    const currentProtocol = newData[props.staticProtocolIndex];

    const newRow: (string | boolean)[] = props.headers.map((column) => {
      switch (column.type) {
        case "input-text":
        case "input-textarea":
          return "";
        case "input-checkbox":
          return false;
        case "static":
          const columnIndex = column.position;
          if (typeof staticFieldData[columnIndex] === "string") {
            return staticFieldData[columnIndex] || "";
          } else {
            return "";
          }
        default:
          return "";
      }
    });

    currentProtocol.rows.push(newRow);

    props.setArray(newData);

    props.setAddStaticData(false);
    toast.info(`Row added for ${currentProtocol.name}`);
  };

  return (
    <Modal
      isOpen={props.addStaticData}
      onClose={() => props.setAddStaticData(false)}
    >
      <div className="flex flex-col w-full h-full ">
        <h4 className="text-md leading-6 font-bold text-gray-900 p-4">
          Add row
        </h4>
        <hr className="w-full" />
        <div className="flex flex-col w-full p-4">
          {props.headers.map(
            (column, index) =>
              column.type === "static" && (
                <div key={index} className="my-2">
                  <label className="mr-2">
                    {column.header}
                    <span className="text-red-500"> *</span>{" "}
                  </label>
                  <input
                    type="text"
                    value={staticFieldData[index] as string}
                    onChange={(e) =>
                      handleStaticFieldChange(index, e.target.value)
                    }
                    className="border rounded-md px-2 py-1"
                  />
                </div>
              )
          )}
        </div>
        <div className="flex flex-row w-full mt-4 p-4 bg-gray-100 md:justify-end lg:justify-end justify-evenly">
          <OutlinedButton
            variant={ButtonVariant.SECONDARY}
            type="button"
            onClick={() => props.setAddStaticData(false)}
            children="Close"
          />

          <OutlinedButton
            variant={ButtonVariant.SECONDARY}
            type="button"
            onClick={handleAddRow}
            children="Add new record"
            className="ml-2"
          />
        </div>
      </div>
    </Modal>
  );
}

export default AddStaticData;
