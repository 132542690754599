import { endpoints, replaceParams } from '@utils/axios';
import React, { useCallback, useEffect } from 'react';
import { Convert, StaffList } from '../types/StaffList';
import useAxios from '@routes/hooks/use-axios';
import { OutlinedButton } from '@components/button';
import { ButtonVariant } from '@components/button/outline-button';
import { useNavigate } from 'react-router-dom';
import { paths } from '@routes/paths';

function StaffTable({
  staffList,
  searchQuery,
  viewStaffDetails,
}: {
  staffList: StaffList[];
  searchQuery: string;
  viewStaffDetails: (staffId: string) => void;
}) {
  const filteredStaff = staffList.filter((staff: StaffList) =>
    staff.firstName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-2 w-full hidden xl:block">
        <div className="sm:flex sm:items-center"></div>
        <div className="mt-2 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Staff Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Staff Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Staff Phone
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Active/InActive
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredStaff.map(staff => (
                    <tr key={staff.id}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {staff.salutation}. {staff.firstName} {staff.lastName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {staff.email}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {staff.phone}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {staff.isActive ? 'Active' : 'InActive'}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {staff.role.role}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <OutlinedButton
                          variant={ButtonVariant.SECONDARY}
                          type="button"
                          onClick={() => viewStaffDetails(staff.id)}
                          children="View"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="block xl:hidden w-full">
        <ul className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4 bg-gray-50 -mx-8 p-8 ">
          {filteredStaff.map(staff => (
            <div className="flex flex-col justify-between rounded-2xl shadow-md bg-white overflow-hidden">
              <div className="flex flex-row rounded-xl bg-white-300 items-center justify-between p-4">
                <div className="flex flex-col w-11/12">
                  <span className="flex-initial text-md text-black font-bold">
                    {staff.salutation}. {staff.firstName} {staff.lastName}
                  </span>
                  <span className="flex-initial truncate text-secondary-400">
                    {staff.email}
                  </span>
                  <span className="flex-initial text-secondary-400">
                    {staff.phone}
                  </span>
                  <span className="flex-initial text-400 text-black">
                    {staff.isActive ? 'Active' : 'InActive'}
                  </span>
                  <span className="flex-initial text-tertiary-400">
                    {staff.role.role}
                  </span>
                </div>
              </div>
              <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4 mt-2">
                <div className="flex flex-row w-full justify-between">
                  <OutlinedButton
                    variant={ButtonVariant.SECONDARY}
                    type="button"
                    onClick={() => viewStaffDetails(staff.id)}
                    children="View"
                  />
                </div>
              </div>
            </div>
          ))}
        </ul>
      </div>
    </>
  );
}

function SearchBar({
  searchQuery,
  onSearch,
}: {
  searchQuery: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <div>
      <input
        type="text"
        value={searchQuery}
        onChange={onSearch}
        placeholder="Search for Staff Name"
        className="w-full px-4 py-2 mb-4 text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500"
      />
    </div>
  );
}

function StaffComponent(props: { entityId: string }) {
  const [staffList, setStaffList] = React.useState<StaffList[] | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const axios = useAxios();
  const navigator = useNavigate();
  const [searchQuery, setSearchQuery] = React.useState('');

  const getStaffList = useCallback(async () => {
    try {
      const paramsMap = new Map<string, string>([['entityId', props.entityId]]);
      const filteredEndpoint = replaceParams(endpoints.entity.staff, paramsMap);
      const response = await axios.get(filteredEndpoint);
      const data = response.data['data'];

      const convertedStaffList = data.map((entity: any) =>
        Convert.toStaffList(JSON.stringify(entity)),
      );
      setStaffList(convertedStaffList);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching staff list:', error);
      setError(error.message);
      setLoading(false);
    }
  }, [axios]);

  useEffect(() => {
    getStaffList();
  }, [getStaffList]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(event.target.value);

  const viewStaffDetails = (staffId: string) => {
    const paramsMap = new Map<string, string>([['staffId', staffId]]);
    navigator(replaceParams(paths.staff.view.route, paramsMap));
  };

  return (
    <>
      <div className="mt-4 px-4 sm:px-6 lg:px-2">
        <SearchBar searchQuery={searchQuery} onSearch={handleSearch} />
      </div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {(!loading && !error && staffList?.length == 0) ||
        (staffList == null && <p>No staff</p>)}
      {!loading && !error && staffList && (
        <StaffTable
          staffList={staffList}
          searchQuery={searchQuery}
          viewStaffDetails={viewStaffDetails}
        />
      )}
    </>
  );
}

export default StaffComponent;
