import React from "react";
import { LimbGirth } from "../type/LimbGirthTypes";

type LimbGirthProps = {
  limbGirth: LimbGirth | null;
};

const LimbGirthAssessment = (props: LimbGirthProps) => {
  return (
    <div>
      {props.limbGirth?.observations === "" ? (
        <div></div>
      ) : (
        <div className="flex flex-col w-full h-full ">
          <h3 className="mb-4 font-bold text-tertiary-500">Limb Girth</h3>
          <div className="">
            <div>
              <div className="flex flex-row justify-between w-full mt-2 bg-tertiary-100 px-2 py-1 font-medium">
                Observations
              </div>
              <p className="px-2 py-1 text-wrap text-lg">
                {props.limbGirth?.observations}
              </p>
            </div>
          </div>
          <hr className=" my-12 border-tertiary-500"></hr>
        </div>
      )}
    </div>
  );
};

export default LimbGirthAssessment;
