import { endpoints, replaceParams } from "@utils/axios";
import React, { useEffect } from "react";
import { Appointment, Convert } from "../types/Appointment";
import useAxios from "@routes/hooks/use-axios";
import moment from "moment";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

function Appointments(props: { staffId: string }) {
  const userData = getUserPersistedOnLocalStorage();
  const [appointments, setAppointments] = React.useState<Appointment[] | null>(
    null
  );
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const axios = useAxios();
  useEffect(() => {
    const getAppointments = async () => {
      try {
        const paramsMap = new Map<string, string>([["staffId", props.staffId]]);
        const filteredEndpoint = replaceParams(
          endpoints.staff.appointments,
          paramsMap
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data["data"];

        if (data != null) {
          const convertedAppointmentList = data.map((appointment: any) => {
            let appointmentShadow = Convert.toAppointment(
              JSON.stringify(appointment)
            );
            let duration =
              appointment.endTimeMinutes - appointment.startTimeMinutes;

            appointmentShadow.apptdate = new Date(
              moment(appointmentShadow.apptdate).toLocaleString()
            );

            appointmentShadow.startTime = moment(
              appointmentShadow.apptdate
            ).format("hh:mm A");
            appointmentShadow.endTime = moment(appointment.startTime, "hh:mm A")
              .add(duration, "minutes")
              .format("hh:mm A");
            appointmentShadow.startTimeMinutes =
              appointmentShadow.apptdate.getHours() * 60 +
              appointmentShadow.apptdate.getMinutes();
            appointmentShadow.endTimeMinutes =
              appointmentShadow.apptdate.getHours() * 60 +
              appointmentShadow.apptdate.getMinutes() +
              duration;
            return appointmentShadow;
          });
          setAppointments(convertedAppointmentList);
          setLoading(false);
        } else {
          setAppointments([]);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching appointments:", error);
        setError(error.message);
        setLoading(false);
      }
    };
    getAppointments();
  }, []);

  return (
    <>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && appointments?.length == 0 && (
        <p>No appointments</p>
      )}

      {!loading && !error && appointments?.length != 0 && (
        <div>
          <div className="px-4 sm:px-6 lg:px-8 hidden xl:block">
            <div className="sm:flex sm:items-center"></div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-30">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Appointment Date
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Patient Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Patient Phone
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Bill Amount
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Payment Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {appointments!.map((appointment) => (
                        <tr key={appointment.id}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {appointment.date} {appointment.startTime}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {appointment.appointmentStatus}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {appointment.clientName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {userData?.user_role === "ENTITY_OWNER" ||
                            userData?.user_role === "PLATFORM_ADMIN" ? (
                              <span>{appointment.clientPhone}</span>
                            ) : appointment.clientPhone.toString().length >
                              4 ? (
                              <span>{appointment.clientPhone}</span>
                            ) : (
                              <span>******{appointment.clientPhone}</span>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            ₹{appointment.billAmount}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {appointment.paymentStatus}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="block xl:hidden w-full">
            <ul className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 bg-gray-50 -mx-8 p-8 ">
              {appointments!.map((appointment) => (
                <div className="flex flex-col justify-between rounded-2xl shadow-md bg-white">
                  <div className="flex flex-row rounded-xl bg-white-300 items-center justify-between p-4">
                    <div className="flex flex-col w-3/4">
                      <span className="flex-initial truncate text-md text-black font-bold">
                        {appointment.clientName}
                      </span>
                      <span className="flex-initial truncate text-secondary-400">
                        {appointment.email}
                      </span>
                      <span className="flex-initial text-secondary-400">
                        {appointment.clientPhone}
                      </span>
                      <span className="flex-initial text-400 text-black">
                        {appointment.date}
                      </span>
                      <span className="flex-initial text-tertiary-400">
                        {appointment.startTime}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Appointments;
