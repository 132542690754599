import { zodResolver } from "@hookform/resolvers/zod";
import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import {
  Convert,
  DiagnosisDetails,
} from "@pages/manage-patient-new/types/DiagnosisDetails";
import { ValidationError } from "@components/hook-form";
import { ManagePatientNewContext } from "./ManagePatientNew";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { NewMasterAssessmentData } from "@pages/manage-patient-new/types/NewMasterAssessment";
import DiagnosisCard from "../components/DiagnosisCard";
import { v4 as uuidv4 } from "uuid";
import DiagnosisWithId from "@pages/manage-patient-new/types/DiagnosisWithId";
import DiagnosisMobileCard from "../components/DiagnosisMobileCard";

export default function Diagnosis(props: {
  patientId: string;
  masterAssessmentData: NewMasterAssessmentData | null;
  getMasterAssessmentLedger: () => Promise<void>;
}) {
  const [loading, setLoading] = React.useState(true);
  const [diagnosis, setDiagnosis] = React.useState<DiagnosisWithId[]>([]);
  const patientId = useContext(ManagePatientNewContext);
  const [error, setError] = React.useState("");

  const userData = getUserPersistedOnLocalStorage();
  const getDiagnosisData = React.useCallback(async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["id", props.masterAssessmentData?.diagnosisId ?? ""],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.diagnosis.fetch,
        paramsMap
      );

      let data, response;
      response = await axios.get(filteredEndpoint);
      data = response.data["data"].diagnosisDetails;

      if (data === null) {
        const paramsMap = new Map<string, string>([
          ["entityid", props.masterAssessmentData?.entityId ?? ""],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.diagnosis.fetchEntityDiagnosisSetup,
          paramsMap
        );
        const response = await axios.get(filteredEndpoint);
        data = response.data["data"].diagnosisValues;
      }

      const converted = data.map((d: any) => ({
        ...d,
        id: uuidv4(),
      }));
      setDiagnosis(converted);

      setLoading(false);
    } catch (error) {
      console.error("Error getting diagnosis data:", error);

      setLoading(false);
    }
  }, [
    props.masterAssessmentData?.diagnosisId,
    props.masterAssessmentData?.entityId,
  ]);

  useEffect(() => {
    const _init = async () => {
      await getDiagnosisData();
    };
    _init();
  }, [getDiagnosisData, props.masterAssessmentData]);

  const onSubmit = async () => {
    if (
      props.masterAssessmentData?.diagnosisId === "000000000000000000000000"
    ) {
      try {
        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentData?.id!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.diagnosis.add,
          paramsMap
        );

        const response = await axios.post(filteredEndpoint, {
          clientId: props.patientId,
          entityId: props.masterAssessmentData?.entityId,

          diagnosisDetails: diagnosis,
        });

        props.getMasterAssessmentLedger();
      } catch (error) {
        console.error("Error submitting form:", error);
        // setApiError(error.response.data['data'].toString());
      }
    } else {
      try {
        const paramsMap = new Map<string, string>([
          ["id", props.masterAssessmentData?.diagnosisId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.diagnosis.update,
          paramsMap
        );

        const response = await axios.put(filteredEndpoint, {
          clientId: props.patientId,
          entityId: props.masterAssessmentData?.entityId,
          diagnosisDetails: diagnosis,
        });
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <form>
      <div className="flex flex-row md:flex-row w-full md:mt-0  hidden sm:block">
        <div className="flex flex-col  w-full ">
          <table className="table-autos text-xs border-collapse border border-slate-400 w-full">
            <tbody>
              {diagnosis &&
                diagnosis !== null &&
                diagnosis.map((d) => (
                  <DiagnosisCard
                    diagnosis={d}
                    diagnosisList={diagnosis || []}
                    setDiagnosis={setDiagnosis}
                    onSubmit={onSubmit}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-full md:mt-0  block sm:hidden">
        {diagnosis &&
          diagnosis !== null &&
          diagnosis.map((d) => (
            <DiagnosisMobileCard
              diagnosis={d}
              diagnosisList={diagnosis || []}
              setDiagnosis={setDiagnosis}
              onSubmit={onSubmit}
            ></DiagnosisMobileCard>
          ))}
      </div>
    </form>
  );
}
