import React, { useEffect, useState } from "react";
import { Button } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import Applabel from "@components/hook-form/applabel";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import { toast } from "react-toastify";
import {
  ContentState,
  Editor,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import RichTextEditor from "@components/rich-text-editor/RichTextEditor";
import draftToHtml from "draftjs-to-html";

export default function LetterHeadPage({ branchId }: { branchId: string }) {
  const userData = getUserPersistedOnLocalStorage();
  const [headerEditorState, setHeaderEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const [signatureEditorState, setSignatureEditorState] = React.useState(
    EditorState.createEmpty()
  );

  useEffect(() => {
    getBranchLetterHead();
  }, []);

  const getBranchLetterHead = async () => {
    try {
      const paramsMap = new Map<string, string>([["id", branchId ?? ""]]);
      const filteredEndpoint = replaceParams(
        endpoints.branch.letterHead,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];

      const headerBlocksFromHTML = convertFromHTML(data.header);
      const initialContentState = ContentState.createFromBlockArray(
        headerBlocksFromHTML.contentBlocks,
        headerBlocksFromHTML.entityMap
      );
      const signatureBlocksFromHTML = convertFromHTML(data.footer);
      const initialSContentState = ContentState.createFromBlockArray(
        signatureBlocksFromHTML.contentBlocks,
        signatureBlocksFromHTML.entityMap
      );
      setHeaderEditorState(EditorState.createWithContent(initialContentState));
      setSignatureEditorState(
        EditorState.createWithContent(initialSContentState)
      );
    } catch (error) {
      console.error("Error fetching branch letterhead:", error);
      toast.error("Failed to fetch branch letterhead.");
    }
  };

  const handleSave = async () => {
    try {
      const paramsMap = new Map<string, string>([["id", branchId ?? ""]]);

      const filteredEndpoint = replaceParams(
        endpoints.branch.letterHead,
        paramsMap
      );

      const response = await axios.put(filteredEndpoint, {
        letterHeader: draftToHtml(
          convertToRaw(headerEditorState.getCurrentContent())
        ),
        letterFooter: draftToHtml(
          convertToRaw(signatureEditorState.getCurrentContent())
        ),
      });
      if (response.data.status === 200) {
        toast.success("Letterhead saved successfully.");
      } else {
        toast.error(response.data.message || "Failed to save letterhead.");
      }
    } catch (error) {
      console.error("Error saving letterhead:", error);
      toast.error("Failed to save letterhead.");
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col w-full md:w-1/2">
        <div className="flex flex-col my-4">
          <h5 className=" font-medium leading-6 text-gray-900 mb-2">Header</h5>
          <RichTextEditor
            editorState={headerEditorState}
            setEditorState={setHeaderEditorState}
          ></RichTextEditor>
        </div>
        <br></br>
        <div className="flex flex-col">
          <h5 className=" font-medium leading-6 text-gray-900 mb-2">
            Signature{" "}
          </h5>
          <RichTextEditor
            editorState={signatureEditorState}
            setEditorState={setSignatureEditorState}
          ></RichTextEditor>
        </div>
      </div>
      <div className="my-4">
        <div className="fixed items-start w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-4 lg:-ml-8 shadow bg-gray-100">
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
