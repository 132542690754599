import React from "react";

// -------------------------------------------------
export enum ButtonVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
  GRAY = "gray",
}

const VARIANT_MAPS: Record<ButtonVariant, string> = {
  [ButtonVariant.PRIMARY]:
    "bg-primary-600  hover:bg-primary-500 focus:ring-primary-500 focus:ring-offset-primary-200 text-white shadow-sm",
  [ButtonVariant.SECONDARY]:
    "bg-secondary-600 hover:bg-secondary-500 focus:ring-secondary-500 focus:ring-offset-secondary-200 text-white shadow-sm",
  [ButtonVariant.TERTIARY]:
    "bg-tertiary-600 hover:bg-tertiary-500 focus:ring-tertiary-500 focus:ring-offset-tertiary-200 text-white shadow-sm",
  [ButtonVariant.GRAY]:
    "bg-gray-600 hover:bg-gray-500 focus:ring-gray-500 focus:ring-offset-gray-200 text-white shadow-sm",
};

type ButtonProps = {
  children: React.ReactNode;
  variant: ButtonVariant;
  type?: "button" | "submit" | "reset";
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
  onClick?: () => void;
  otherProps?: any;
};

// -------------------------------------------------

export default function Button(props: ButtonProps) {
  const bgClass = VARIANT_MAPS[props.variant];
  const className =
    `inline-flex items-center rounded-xl text-sm px-4 py-2 font-medium focus:outline-none transition ease-in-out duration-150 hover:outline hover:shadow ${bgClass} ${props.className}`.trim();
  const onClick = props.isLoading ? () => {} : props.onClick;
  const disabled = props.isLoading || props.isDisabled;
  const children = props.isLoading ? "Loading..." : props.children;

  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled}
      type={props.type}
      {...props.otherProps}
    >
      {children}
    </button>
  );
}
