export const generateForm = (masterAssessmentId: string) => {
  const myAssessment = {
    masterAssessmentId: masterAssessmentId,
    lateralView: {
      upperBody: {
        forConditions: [
          {
            name: 'Lordosis',
            category: 'Lumbar Spine',
            exists: 'false',
          },
          {
            name: 'Flat',
            category: 'Lumbar Spine',
            exists: 'false',
          },
          {
            name: 'Kyphosis',
            category: 'Thorac Spine',
            exists: 'false',
          },
          {
            name: 'Flat',
            category: 'Thorac Spine',
            exists: 'false',
          },
          {
            name: 'Rotation',
            category: 'Trunk',
            exists: 'false',
          },
          {
            name: 'Forward',
            category: 'Shoulders',
            exists: 'false',
          },
          {
            name: 'Forward',
            category: 'Head Position',
            exists: 'false',
          },
          {
            name: 'Back',
            category: 'Head Position',
            exists: 'false',
          },
        ],
      },
      lowerBody: {
        forSide: [
          {
            name: 'Dorsiflexion',
            category: 'Ankle',
            right: false,
            left: false,
          },
          {
            name: 'Plantarflexion',
            category: 'Ankle',
            right: false,
            left: false,
          },
          {
            name: 'Flexed',
            category: 'Knee',
            right: false,
            left: false,
          },
          {
            name: 'Hyperextended',
            category: 'Knee',
            right: false,
            left: false,
          },
          {
            name: 'Anterior Translation',
            category: 'Pelvis',
            right: false,
            left: false,
          },
        ],
        forConditions: [
          {
            name: 'Tile: Anterior',
            category: 'Other',
            exists: 'false',
          },
          {
            name: 'Posterior',
            category: 'Other',
            exists: 'false',
          },
        ],
      },
    },
    anteriorPosteriorView: {
      upperBody: {
        forSide: [
          {
            name: 'Scoliosis',
            category: 'Spine',
            right: false,
            left: false,
          },
          {
            name: 'Deviation',
            category: 'Scapula',
            right: false,
            left: false,
          },
          {
            name: 'Deviation',
            category: 'Shoulder',
            right: false,
            left: false,
          },
          {
            name: 'Tilt',
            category: 'Head',
            right: false,
            left: false,
          },
          {
            name: 'Rotation',
            category: 'Head',
            right: false,
            left: false,
          },
        ],
      },
      lowerBody: {
        forSide: [
          {
            name: 'Toe In',
            category: 'Foot and Ankle Complex',
            right: false,
            left: false,
          },
          {
            name: 'Toe Out',
            category: 'Foot and Ankle Complex',
            right: false,
            left: false,
          },
          {
            name: 'Pronation',
            category: 'Foot and Ankle Complex',
            right: false,
            left: false,
          },
          {
            name: 'Flat Feet',
            category: 'Foot and Ankle Complex',
            right: false,
            left: false,
          },
          {
            name: 'High Arch',
            category: 'Foot and Ankle Complex',
            right: false,
            left: false,
          },
          {
            name: 'Knock Legs',
            category: 'Knee/Hip',
            right: false,
            left: false,
          },
          {
            name: 'Bow Legs',
            category: 'Knee/Hip',
            right: false,
            left: false,
          },
        ],
      },
    },
    ankleComplex: {
      frontView: {
        forSide: [
          {
            name: 'Medial Deviation of Knee',
            category: 'Front View',
            right: false,
            left: false,
          },
          {
            name: 'Medial Deviation of Hip',
            category: 'Front View',
            right: false,
            left: false,
          },
          {
            name: 'Stance Foot Toe Out',
            category: 'Front View',
            right: false,
            left: false,
          },
          {
            name: 'Excessive Pronation of Placing Foot',
            category: 'Front View',
            right: false,
            left: false,
          },
        ],
      },
      sideView: {
        forSide: [
          {
            name: 'Heel Lifting on Stance Foot',
            category: 'Side View',
            right: false,
            left: false,
          },
          {
            name: 'Heel Lifting on Placing Foot',
            category: 'Side View',
            right: false,
            left: false,
          },
          {
            name: 'Poor Control of Tibia on Placing Foot',
            category: 'Side View',
            right: false,
            left: false,
          },
          {
            name: 'Poor Control of Tibia on Stance Foot',
            category: 'Side View',
            right: false,
            left: false,
          },
          {
            name: 'Excessive Flexion Of Trunk',
            category: 'Side View',
            right: false,
            left: false,
          },
        ],
      },
    },
    hipComplex: {
      frontViewSteppingLeg: {
        forSide: [
          {
            name: 'Knee Not Stacking (Over Foot)',
            category: 'Front View Stepping Leg',
            right: false,
            left: false,
          },
          {
            name: 'Hip Not Stacking (Over Foot)',
            category: 'Front View Stepping Leg',
            right: false,
            left: false,
          },
          {
            name: 'Head Not Stacking (Over Foot)',
            category: 'Front View Stepping Leg',
            right: false,
            left: false,
          },
        ],
      },
      frontViewTrunk: {
        forSide: [
          {
            name: 'Excessive Flexion of Trunk',
            category: 'Front View Trunk',
            right: false,
            left: false,
          },
          {
            name: 'Excessive Lateral Flexion of Trunk',
            category: 'Front View Trunk',
            right: false,
            left: false,
          },
        ],
      },
      frontViewStanceLeg: {
        forSide: [
          {
            name: 'Lateral Border of Foot off Ground',
            category: 'Front View Stance Leg',
            right: false,
            left: false,
          },
        ],
      },
    },
    thoracicSpine: {
      backView: {
        forSide: [
          {
            name: 'Scapula - Segmented Movement',
            category: 'Back View',
            right: false,
            left: false,
          },
          {
            name: 'Spine- Segmented Movement',
            category: 'Back View',
            right: false,
            left: false,
          },
          {
            name: 'Excessive Trunk Rotation',
            category: 'Back View',
            right: false,
            left: false,
          },
          {
            name: 'Head Does Not Follow Hand (without prompting)',
            category: 'Back View',
            right: false,
            left: false,
          },
          {
            name: 'Segmented Head Movement',
            category: 'Back View',
            right: false,
            left: false,
          },
          {
            name: 'Stance Leg Not Straight',
            category: 'Back View',
            right: false,
            left: false,
          },
        ],
      },
    },
  };
  return myAssessment;
};
