// To parse this data:
//
//   import { Convert } from "./file";
//
//   const ratingOfEachStaff = Convert.toRatingOfEachStaff(json);

export interface RatingOfEachStaff {
  entityuser: string;
  rating: number;
}

// Converts JSON strings to/from your types
export class RatingOfEachStaffConvert {
  public static toRatingOfEachStaff(json: string): RatingOfEachStaff[] {
    return JSON.parse(json);
  }

  public static ratingOfEachStaffToJson(value: RatingOfEachStaff[]): string {
    return JSON.stringify(value);
  }
}
