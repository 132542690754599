import { Disclosure } from "@headlessui/react/dist/components/disclosure/disclosure";
import { RadioGroup } from "@headlessui/react/dist/components/radio-group/radio-group";
import { Tab } from "@headlessui/react/dist/components/tabs/tabs";
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon";
import ChevronUpIcon from "@heroicons/react/24/outline/ChevronUpIcon";
import React from "react";
import { ADLCategory } from "../type/ADLtypes/ADLcategory";
import { ADLQuestions } from "../type/ADLtypes/ADLQuestions";

type ADLProps = {
  adlCategories: ADLCategory[];
  adlQuestions: ADLQuestions | null;
};

const ADLAssessment = (props: ADLProps) => {
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  var allIsNotAnswered = props.adlQuestions?.categoryScoreDetails?.every(
    (e) => e.scoreAnswered === 0
  );

  //console.log(allIsNotAnswered + "AHd");

  return (
    <div className="flex flex-col w-full h-full ">
      {allIsNotAnswered ? (
        <div></div>
      ) : (
        <div>
          <h3 className="mb-4 font-bold text-tertiary-500">ADL Assessment</h3>
          <div className="w-full">
            {props.adlQuestions &&
              props.adlQuestions.categoryScoreDetails &&
              props.adlQuestions.categoryScoreDetails.map((val, index) => {
                return val.scoreAnswered === 0 ? (
                  <div key={index}></div>
                ) : (
                  <div key={index} className="w-full">
                    <div className="w-full rounded-2xl bg-white ">
                      <div className="flex w-full justify-between items-center rounded-lg bg-tertiary-100 px-4 py-2 text-left text-sm font-medium text-black">
                        <span>
                          <div className="flex flex-row justify-between items-center">
                            <div className="inline-flex items-center justify-center my-2 rounded-lg text-xl px-2 py-1 font-semibold lg:rounded-xl lg:text-xl lg:px-4 lg:py-2 lg:font-medium focus:outline-none text-black w-full">
                              {val.category}
                            </div>
                          </div>
                        </span>
                        <div className="flex flex-row items-center">
                          <div className="inline-flex items-center rounded-lg text-lg px-1 py-1 font-normal w-34 lg:rounded-xl lg:text-lg lg:px-2 lg:py-2 lg:font-medium focus:outline-none transition ease-in-out duration-150 hover:shadow bg-tertiary-50 text-black shadow-sm border border-gray-600 whitespace-nowrap">
                            Score: {val.scoreAnswered}/{val.scoreAttempted}
                          </div>
                        </div>
                      </div>
                      <div className="px-4">
                        {val.questionScore &&
                          val.questionScore.map((questionVal, questIndex) => {
                            return (
                              <>
                                {questionVal.answer?.score === 0 ? (
                                  <div></div>
                                ) : (
                                  <div className="my-8">
                                    <div className="font-semibold text-lg mb-4">
                                      {questIndex + 1} {"]"}{" "}
                                      {questionVal.question}
                                    </div>
                                    <div>
                                      {questionVal.answer?.score}{" "}
                                      {questionVal.answer?.text}
                                    </div>
                                  </div>
                                )}
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <hr className=" my-12 border-tertiary-500"></hr>
        </div>
      )}
    </div>
  );
};

export default ADLAssessment;
