// To parse this data:
//
//   import { Convert, AvaialableExercises } from "./file";
//
//   const avaialableExercises = Convert.toAvaialableExercises(json);

export interface AvaialableExercises {
  id: string;
  title: string;
  difficulty: string;
  description: string;
  instructions: string;
  defaultExerciseConfig: DefaultExerciseConfig;
  tags: any[];
  media: string[];
  externalLinks: ExternalLink[];
  includedInPlatformLibrary: boolean;
  audit: Audit;
}

export interface Audit {
  createdBy: null;
  lastUpdatedBy: null;
  createdAt: Date;
  lastUpdatedAt: Date;
}

export interface DefaultExerciseConfig {
  sets: number;
  repetitions: number;
  hold: number;
  interval: number;
  side: string;
  weights: number;
}

export interface ExternalLink {
  externalLinkId: string;
  linkTitle: string;
  url: string;
  audit: Audit;
  isFree: boolean;
}

// Converts JSON strings to/from your types
export class Converto {
  public static toAvaialableExercises(json: string): AvaialableExercises {
    return JSON.parse(json);
  }

  public static avaialableExercisesToJson(value: AvaialableExercises): string {
    return JSON.stringify(value);
  }
}
