// To parse this data:
//
//   import { Convert, FreeSlot } from "./file";
//
//   const freeSlot = Convert.toFreeSlot(json);

export interface FreeSlot {
  appointmentsCount: number;
  startTimeMinutes: number;
  endTimeMinutes: number;
  startTime: string;
  endTime: string;
}

// Converts JSON strings to/from your types
export class ConvertFreeSlot {
  public static toFreeSlot(json: string): FreeSlot {
    return JSON.parse(json);
  }

  public static freeSlotToJson(value: FreeSlot): string {
    return JSON.stringify(value);
  }
}
