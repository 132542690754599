// To parse this data:
//
//   import { Convert, MuscleStrength } from "./file";
//
//   const muscleStrength = Convert.toMuscleStrength(json);

export interface MuscleStrength {
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  details: Detail[];
}

export interface Detail {
  name: string;
  grading: string;
}

// Converts JSON strings to/from your types
export class ConvertMuscleStrength {
  public static toMuscleStrength(json: string): MuscleStrength {
    return JSON.parse(json);
  }

  public static muscleStrengthToJson(value: MuscleStrength): string {
    return JSON.stringify(value);
  }
}
