import {} from './RangeOfMotionAssessment';

export const generateForm = (masterAssessmentId: string) => {
  const myAssessment = {
    id: '',
    masterAssessmentId: masterAssessmentId,
    clientId: '',
    entityId: '',
    entityBranchId: '',
    hipFlexionLeft: 0,
    hipFlexionRight: 0,
    hipExtensionLeft: 0,
    hipExtensionRight: 0,
    hipAbductionLeft: 0,
    hipAbductionRight: 0,
    hipAdductionLeft: 0,
    hipAdductionRight: 0,
    hipMedialRotationLeft: 0,
    hipMedialRotationRight: 0,
    hipLateralRotationLeft: 0,
    hipLateralRotationRight: 0,
    shoulderFlexionLeft: 0,
    shoulderFlexionRight: 0,
    shoulderExtensionLeft: 0,
    shoulderExtensionRight: 0,
    shoulderAbductionLeft: 0,
    shoulderAbductionRight: 0,
    shoulderAdductionLeft: 0,
    shoulderAdductionRight: 0,
    shoulderMedialRotationLeft: 0,
    shoulderMedialRotationRight: 0,
    shoulderLateralRotationLeft: 0,
    shoulderLateralRotationRight: 0,
    kneeFlexionLeft: 0,
    kneeFlexionRight: 0,
    kneeExtensionLeft: 0,
    kneeExtensionRight: 0,
    elbowFlexionLeft: 0,
    elbowFlexionRight: 0,
    elbowExtensionLeft: 0,
    elbowExtensionRight: 0,
    ankleFootDorsiFlexionLeft: 0,
    ankleFootDorsiFlexionRight: 0,
    ankleFootPlantarFlexionLeft: 0,
    ankleFootPlantarFlexionRight: 0,
    ankleFootInversionLeft: 0,
    ankleFootInversionRight: 0,
    ankleFootEversionLeft: 0,
    ankleFootEversionRight: 0,
    forearmPronationLeft: 0,
    forearmPronationRight: 0,
    forearmSupinationLeft: 0,
    forearmSupinationRight: 0,
    neckFlexionLeft: 0,
    neckFlexionRight: 0,
    neckExtensionLeft: 0,
    neckExtensionRight: 0,
    neckLateroFlexionRLeft: 0,
    neckLateroFlexionRRight: 0,
    neckLateroFlexionLLeft: 0,
    neckLateroFlexionLRight: 0,
    neckRotationRLeft: 0,
    neckRotationRRight: 0,
    neckRotationLLeft: 0,
    neckRotationLRight: 0,
    wristFlexionLeft: 0,
    wristFlexionRight: 0,
    wristExtensionLeft: 0,
    wristExtensionRight: 0,
    wristAbductionLeft: 0,
    wristAbductionRight: 0,
    wristAdductionLeft: 0,
    wristAdductionRight: 0,
    trunkGlobalFlexionLeft: 0,
    trunkGlobalFlexionRight: 0,
    trunkThoracicFlexionLeft: 0,
    trunkThoracicFlexionRight: 0,
    trunkLumbarFlexionLeft: 0,
    trunkLumbarFlexionRight: 0,
    trunkGlobalExtensionLeft: 0,
    trunkGlobalExtensionRight: 0,
    trunkLateroFlexionRLeft: 0,
    trunkLateroFlexionRRight: 0,
    trunkLateroFlexionLLeft: 0,
    trunkLateroFlexionLRight: 0,
    trunkRotationRLeft: 0,
    trunkRotationRRight: 0,
    trunkRotationLLeft: 0,
    trunkRotationLRight: 0,
    fingersThumboppositionLeft: 0,
    fingersThumboppositionRight: 0,
    fingersMPFlexionRight: 0,
    fingersMPFlexionLeft: 0,
    fingersMPExtensionLeft: 0,
    fingersMPExtensionRight: 0,
    fingersIPFlexionLeft: 0,
    fingersIPFlexionRight: 0,
  };
  return myAssessment;
};
