import useAxios from '@routes/hooks/use-axios';
import { endpoints, replaceParams } from '@utils/axios';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { paths } from '@routes/paths';
import { ManagePatientContext } from '@pages/manage-patient/view/ManagePatient';
import Button from '@components/button/button';
import { ButtonVariant } from '@components/button/button';
import { toast } from 'react-toastify';
import { OutlinedButton } from '@components/button';
import { Convert } from './types/StructuralDeformitiesType';
import { getUserPersistedOnLocalStorage } from '@authentication/context/jwt/utils';

export const StructuralDeformitiesSchema = z.object({
  id: z.string().optional().default(''),
  masterAssessmentId: z.string().optional().default(''),
  clientId: z.string().optional().default(''),
  entityId: z.string().optional().default(''),
  entityBranchId: z.string().optional().default(''),
  observations: z.union([z.null(), z.string()]).optional(),
});
export type StructuralDeformitiesSchemaType = z.infer<
  typeof StructuralDeformitiesSchema
>;

type StructuralDeformitiesProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function StructuralDeformitiesComponent(
  props: StructuralDeformitiesProps,
) {
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);
  const userData = getUserPersistedOnLocalStorage();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<StructuralDeformitiesSchemaType>({
    resolver: zodResolver(StructuralDeformitiesSchema),
    defaultValues: { masterAssessmentId: props.masterAssessmentId },
  });

  React.useEffect(() => {
    const initializeAssessment = async () => {
      if (props.assessmentId !== '000000000000000000000000') {
        try {
          const paramsMap = new Map<string, string>([
            ['mid', props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getStructuralDeformitiesAssessment,
            paramsMap,
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data['data'];
          const convertedStructuralDeformitiesAssessment =
            Convert.toStructuralDeformitiesType(JSON.stringify(data));

          reset(convertedStructuralDeformitiesAssessment);
          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          console.error(
            'Error fetching Structural Deformities assessment:',
            error,
          );

          setLoading(false);
        }
      }
    };
    initializeAssessment();
  }, []);

  const onSubmit: SubmitHandler<StructuralDeformitiesSchemaType> = async (
    data: any,
  ) => {
    if (props.assessmentId !== '000000000000000000000000') {
      try {
        StructuralDeformitiesSchema.parse(data);
        data.id = props.assessmentId;
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? '';
        data.entityBranchId = userData?.branch_id ?? '';
        const paramsMap = new Map<string, string>([
          ['id', props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateStructuralDeformitiesAssessment,
          paramsMap,
        );
        const response = await axios.put(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === 'success'
              ? 'Structural Deformities Assessment updated successfully'
              : response.data.message,
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error('Error submitting form:', error);
      }
    } else {
      try {
        StructuralDeformitiesSchema.parse(data);
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? '';
        data.entityBranchId = userData?.branch_id ?? '';
        const paramsMap = new Map<string, string>([
          ['mid', props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createStructuralDeformitiesAssessment,
          paramsMap,
        );
        const response = await axios.post(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === 'success'
              ? 'Structural Deformities Assessment created successfully'
              : response.data.message,
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error('Error submitting form:', error);
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Structural Deformitites
        </h3>
      </div>

      <hr className="w-full" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4">
          <div>
            <label
              htmlFor="observation"
              className="block text-sm font-medium text-gray-700"
            >
              Observations
            </label>
            <textarea
              rows={5}
              {...register('observations')}
              placeholder="Observations"
              name="observations"
              className="w-full rounded-lg border-2 border-gray-300 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm leading-6"
              disabled={isDisabled}
            />
          </div>
        </div>
        <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
          <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
            <OutlinedButton
              type="button"
              onClick={() => {
                props.postSubmitCallback();
              }}
              className="mr-2"
              variant={ButtonVariant.PRIMARY}
            >
              Close
            </OutlinedButton>
            {isDisabled ? (
              <Button
                type="button"
                key="EditButton"
                onClick={() => {
                  setIsDisabled(false);
                }}
                variant={ButtonVariant.PRIMARY}
              >
                Edit
              </Button>
            ) : props.assessmentId !== '000000000000000000000000' ? (
              <Button
                type="submit"
                key="UpdateButton"
                onClick={() => {}}
                variant={ButtonVariant.PRIMARY}
              >
                Update
              </Button>
            ) : (
              <Button
                type="submit"
                key="CreateButton"
                onClick={() => {}}
                variant={ButtonVariant.PRIMARY}
              >
                Create
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
