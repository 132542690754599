// To parse this data:
//
//   import { Convert, Posture } from "./file";
//
//   const posture = Convert.toPosture(json);

export type Posture = {
  masterAssessmentId?: string;
  lateralView?: LateralView;
  anteriorPosteriorView?: AnteriorPosteriorView;
  ankleComplex?: AnkleComplex;
  hipComplex?: HipComplex;
  thoracicSpine?: ThoracicSpine;
};

export type AnkleComplex = {
  frontView?: FrontView;
  sideView?: FrontView;
};

export type FrontView = {
  forSide?: ForSide[];
};

export type ForSide = {
  name?: string;
  category?: string;
  right?: boolean;
  left?: boolean;
};

export type AnteriorPosteriorView = {
  upperBody?: FrontView;
  lowerBody?: FrontView;
};

export type HipComplex = {
  frontViewSteppingLeg?: FrontView;
  frontViewTrunk?: FrontView;
  frontViewStanceLeg?: FrontView;
};

export type LateralView = {
  upperBody?: UpperBody;
  lowerBody?: LowerBody;
};

export type LowerBody = {
  forSide?: ForSide[];
  forConditions?: ForCondition[];
};

export type ForCondition = {
  name?: string;
  category?: string;
  exists?: string;
};

export type UpperBody = {
  forConditions?: ForCondition[];
};

export type ThoracicSpine = {
  backView?: FrontView;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toPosture(json: string): Posture {
    return JSON.parse(json);
  }

  public static postureToJson(value: Posture): string {
    return JSON.stringify(value);
  }
}
