import React from "react";
import useAxios from "@routes/hooks/use-axios";
import { OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import { endpoints, replaceParams } from "@utils/axios";
import { Convert, PatientProgress } from "../types/PatientProgress";
import moment from "moment";


export default function ProgressTracker(props: {
  handleCloseRightOffCanvas: () => void;
  patientId: string;
  isPopup?: boolean;
}) {
  const axios = useAxios();
  const [progress, setProgress] = React.useState<PatientProgress | null>(null);
  const [error, setError] = React.useState("");

  const getOverallProgressDetails = React.useCallback(async () => {
    try {
      const paramsMap = new Map<string, string>([["id", props.patientId]]);
      const filteredEndpoint = replaceParams(
        endpoints.patient.getPatientProgress,
        paramsMap
      );

      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      if (data != null) {
        const converted = Convert.toPatientProgress(JSON.stringify(data));
        setProgress(converted);
      }
    } catch (error) {
      console.error("Error fetching patients list:", error);
      setError(error.message);
    }
  }, [axios]);

  React.useEffect(() => {
    const _init = async () => {
      await getOverallProgressDetails();
    };
    _init();
  }, []);


  return (
    <div className="flex flex-col">
      {props.isPopup && (
        <div className="flex items-center justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
              Progress Tracker
            </h2>
          </div>
          <div className="flex mt-4 flex-shrink-0 md:ml-4 md:mt-0 justify-end">
            <OutlinedButton
              type="button"
              onClick={props.handleCloseRightOffCanvas}
              className="mr-2"
              variant={ButtonVariant.PRIMARY}
            >
              Close
            </OutlinedButton>
          </div>
        </div>
      )}
      <hr className="my-2" />
      <div className="flex flex-col justify-between p-4 leading-normal w-full">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
          Activity Monitor
        </h5>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          Monitor your patient's overall progress and adherence to your
          prescribed exercise program.
        </p>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-2">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Prescibed
              </th>
              <th scope="col" className="px-6 py-3">
                Performed
              </th>
              <th scope="col" className="px-6 py-3">
                Percentage Completed
              </th>
            </tr>
          </thead>
          <tbody>
            {error && <>No data found</>}
            {progress?.exerciseTrackingCountsDaily === null ||
              (progress?.exerciseTrackingCountsDaily.length === 0 && (
                <>No data found</>
              ))}
            {progress?.exerciseTrackingCountsDaily != null &&
              progress?.exerciseTrackingCountsDaily.length > 0 &&
              progress?.exerciseTrackingCountsDaily.slice()
                .sort((a, b) => moment(b.Date).valueOf() - moment(a.Date).valueOf())
                .map((exercise) => (
                  <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {moment(exercise.Date).format("DD-MM-YYYY")}
                    </th>
                    <td className="px-6 py-4">{exercise.PrescribedCount}</td>
                    <td className="px-6 py-4">{exercise.CompletedCount}</td>
                    <td className="px-6 py-4">
                      {exercise.DailyPercentProgress}%
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
