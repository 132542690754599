// To parse this data:
//
//   import { Convert, NewMasterAssessment } from "./file";
//
//   const newMasterAssessment = Convert.toNewMasterAssessment(json);

export interface NewMasterAssessmentData {
  id: string;
  clientId: string;
  entityId: string;
  entityUserId: string;
  entityUserName: string;
  assessmentNo: number;
  assessmentDate: Date;
  diagnosisId: string;
  medicalHistoryId: string;
  treatmentPlanId: string;
  vitalAssessmentId: string;
  painAssessmentId: string;
  postureAssessmentId: string;
  reflexAssessmentId: string;
  gaitAssessmentId: string;
  rangeOfMotionAssessmentId: string;
  muscleToneAssessmentId: string;
  oedemaAssessmentId: string;
  limbLengthAssessmentId: string;
  limbGirthAssessmentId: string;
  muscleWastingAssessmentId: string;
  balanceAssessmentId: string;
  muscleTightnessAssessmentId: string;
  muscleStrengthAssessmentId: string;
  structuralDeformitiesAssessmentId: string;
  specialTestsAssessmentId: string;
  nerveTestAssessmentId: string;
  sensoryAssessmentId: string;
  scarAssessmentId: string;
  customAssessmentId: string;
  vertigoAssessmentId: string;
  adlAssessmentId: string;
  treatmentProtocolId: string;
  activetill: Date;
}

export interface Audit {
  createdBy: null;
  lastUpdatedBy: null;
  createdAt: Date;
  lastUpdatedAt: Date;
}

// Converts JSON strings to/from your types
export class NewMasterAssessmentDataConvert {
  public static toNewMasterAssessment(json: string): NewMasterAssessmentData {
    return JSON.parse(json);
  }

  public static newMasterAssessmentToJson(
    value: NewMasterAssessmentData
  ): string {
    return JSON.stringify(value);
  }
}
