import React from "react";
import { Custom } from "../type/CustomAssessment";

type CustomProps = {
  customAssessment: Custom[] | null;
};

const CustomAssessment = (props: CustomProps) => {
  //console.log("Custom Assessment" + JSON.stringify(props.customAssessment));

  return (
    <>
      {props.customAssessment === null ||
      props.customAssessment.length === 0 ? (
        <div></div>
      ) : (
        <div className="flex flex-col w-full h-full">
          <h3 className="mb-4 font-bold text-tertiary-600">
            Custom Assessment
          </h3>

          <div className="flex flex-row justify-between w-full bg-tertiary-100 px-2 py-1 font-medium">
            <span className="w-24 ">Assessment</span>
            <span className="w-24 pl-2">Parameters</span>
            {props.customAssessment?.[0].parameters?.[0].values.map((dates) => (
              <span className="w-24 flex flex-row justify-center">
                {dates.date}
              </span>
            ))}
          </div>
          <div className="fle flex-col ">
            {props.customAssessment?.map((ass) => (
              <div className="flex flex-row justify-between w-full mt-4 px-2 py-1 font-medium border-b-2 border-tertiary-200">
                <span className="w-24 ">{ass.assessmentName}</span>
                <span className="w-24 pl-2">{ass.parameters[0].name}</span>
                {ass.parameters[0].values.map((val) => (
                  <span className="w-24 flex flex-row justify-center">
                    {" "}
                    {val.value}{" "}
                  </span>
                ))}
              </div>
            ))}
          </div>
          <hr className=" my-12 border-tertiary-500"></hr>
        </div>
      )}
    </>
  );
};

export default CustomAssessment;
