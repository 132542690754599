import React from 'react';

interface PaginationProps {
  activePage: number;
  count: number;
  rowsPerPage: number;
  totalPages: number;
  setActivePage: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  activePage,
  count,
  rowsPerPage,
  totalPages,
  setActivePage,
}) => {
  const beginning: number =
    activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1;
  const end: number =
    activePage === totalPages ? count : beginning + rowsPerPage - 1;

  return (
    <>
      <div className="mt-2 flex flex-row justify-between items-center">
        <div className="pagination flex flex-col md:flex-row lg:flex-row">
          <div className='flex flex-row mb-2'>
            {activePage !== 1 ? (
              <button
                disabled={activePage === 1}
                onClick={() => setActivePage(1)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring focus:ring-blue-200"
              >
                First
              </button>
            ) : null}
            {activePage !== 1 ? (
              <button
                disabled={activePage === 1}
                onClick={() => setActivePage(activePage - 1)}
                className="ml-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring focus:ring-blue-200"
              >
                Previous
              </button>
            ) : null}
          </div>
          <div className='flex flex-row'>
            {activePage !== totalPages ? (
              <button
                disabled={activePage === totalPages}
                onClick={() => setActivePage(activePage + 1)}
                className="ml-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring focus:ring-blue-200"
              >
                Next
              </button>
            ) : null}
            {activePage !== totalPages ? (
              <button
                disabled={activePage === totalPages}
                onClick={() => setActivePage(totalPages)}
                className="ml-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring focus:ring-blue-200"
              >
                Last
              </button>
            ) : null}
          </div>
        </div>

        <div className="flex flex-col justify-center items-end">
          <p className="text-sm font-bold text-gray-500">
            Page {activePage} of {totalPages}
          </p>
          <span className="mt-2 text-sm text-gray-500">
            Rows {beginning === end ? end : `${beginning} - ${end}`} of {count}
          </span>
        </div>
      </div>
    </>
  );
};
