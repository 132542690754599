export function convertTo24HourFormat(timeStr: string): string {
  const [time, period] = timeStr.split(' ');

  let [hours, minutes] = time.split(':');
  let iHours = parseInt(hours, 10);

  if (period === 'PM' && iHours < 12) {
    iHours += 12;
  } else if (period === 'AM' && iHours === 12) {
    iHours = 0;
  }

  const formattedHours = iHours.toString().padStart(2, '0');
  return `${formattedHours}:${minutes}`;
}
