import { replaceParams, endpoints } from "@utils/axios";
import React, { Fragment, useEffect, useState } from "react";

import useAxios from "@routes/hooks/use-axios";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { z } from "zod";
import { Convert, Entity } from "../types/Entity";
import Button, { ButtonVariant } from "@components/button/button";
import { InputField, ValidationError } from "@components/hook-form";
import { isEditableInput } from "@testing-library/user-event/dist/utils";
import { useNavigate } from "react-router-dom";
import { paths } from "@routes/paths";
import ValidationSuccess from "@components/hook-form/validation-success";
import { toast } from "react-toastify";
import Applabel from "@components/hook-form/applabel";

import CountryInputSelect from "@components/hook-form/country-input-select";
import TimezoneData from "@assets/timezones";
import tzmoment from "moment-timezone";
import { Dialog, Transition } from "@headlessui/react";

import axios from "axios";
import PhoneInput, {
  formatPhoneNumber,
  getCountryCallingCode,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { CountryCode, E164Number } from "libphonenumber-js";
import {
  convertPhoneNumberAndCodeToNumber,
  convertPhoneNumberAndCodeToString,
} from "@utils/phoneUtils";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

interface DummyProfilePictureContainerProps {
  getPatientData: () => Promise<void>;
  selectedImage: string;
  setSelectedImage: React.Dispatch<React.SetStateAction<string>>;
  entityId: string;
}

function DummyProfilePictureContainer({
  getPatientData,
  selectedImage,
  setSelectedImage,
  entityId,
}: DummyProfilePictureContainerProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [displayImage, setDisplayImage] = React.useState<string>("");
  const [imageDimensions, setImageDimensions] = React.useState<{
    width: number;
    height: number;
  } | null>(null);

  useEffect(() => {
    setDisplayImage(selectedImage);
    if (selectedImage) {
      const image = new Image();
      image.onload = () => {
        setImageDimensions({ width: image.width, height: image.height });
      };
      image.src = selectedImage;
    }
  }, [selectedImage]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const userData = getUserPersistedOnLocalStorage();

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      // Check file size
      if (file.size <= 1024 * 1024) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result as string);
          const image = new Image();
          image.onload = () => {
            setImageDimensions({ width: image.width, height: image.height });
          };
          image.src = reader.result as string;
        };
        reader.readAsDataURL(file);

        const formData = new FormData();
        formData.append("file", file);

        try {
          const paramsMap = new Map<string, string>([["id", entityId]]);
          const filteredEndpoint = replaceParams(
            endpoints.entity.updateProfilePicture,
            paramsMap
          );

          axios.put(filteredEndpoint, formData).then(async (response) => {
            if (response.status === 200) {
              toast.success(
                response.data.message === "success"
                  ? "Image uploaded successful"
                  : response.data.message
              );
              window.location.reload();
              await getPatientData();
            }
          });
        } catch (e) {}
      } else {
        toast.error(
          "File size exceeds the limit of 2MB. Please choose a smaller file."
        );
        // You can add code here to handle image selection or any other action.
      }
    }
  };

  return (
    <div className="flex flex-col">
      {userData?.user_role === "PLATFORM_ADMIN" ? (
        <div className="flex flex-col">
          {displayImage !== "" ? (
            <div className="flex shrink-0 bg-gray-300 rounded-lg border border-gray-300 mt-2 max-h-56 overflow-hidden">
              <img
                src={displayImage}
                alt="Profile"
                className="w-full h-full object-center object-cover"
                onClick={openModal}
              />
            </div>
          ) : (
            <div className="flex justify-center bg-gray-300 rounded-lg p-3 border border-gray-300 mt-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="text-gray-500"
              >
                <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
              </svg>
            </div>
          )}

          <label
            htmlFor="imageInput"
            className="mt-2 w-full cursor-pointer text-center text-secondary-500 border border-gray-400 p-1 text-sm rounded justify-center"
          >
            Change
          </label>

          <input
            type="file"
            id="imageInput"
            accept="image/*"
            className="hidden"
            onChange={handleImageChange}
          />

          <a
            href="https://imageresizer.com/"
            target="_blank"
            className="text-center text-gray-500 text-sm mt-1"
          >
            Click here to crop & resize image (if too large)
          </a>
        </div>
      ) : (
        <div></div>
      )}

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto "
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            <div className="inline-block align-middle my-16 p-4 w-full max-w-lg text-left overflow-hidden shadow-xl transform transition-all bg-white">
              <img
                src={displayImage}
                alt="Profile"
                className="w-full h-full object-cover rounded-lg"
              />

              <button
                className="absolute top-0 right-1 m-4 text-xl cursor-pointer"
                onClick={closeModal}
              >
                &times;
              </button>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

// TO DO  : Change Country state city from optional to required with respective messages

interface Timezone {
  value: string;
  abbr: string;
  text: string;
  utc: string[];
}

export const EntityAddressSchema = z.object({
  address: z.string().min(1, { message: "Address is required" }),
  city: z.string().optional(),
  state: z.string().optional(),
  country: z.string().optional(),
  postalCode: z.string().min(1, { message: "Postal Code/zip is required" }),
});
export type EntityAddress = z.infer<typeof EntityAddressSchema>;

export const CustomThemeSchema = z.object({
  colorScheme: z.object({
    primaryColor: z.string().optional(),
    secondaryColor: z.string().optional(),
    tertiaryColor: z.string().optional(),
  }),
});
export type CustomTheme = z.infer<typeof CustomThemeSchema>;

export const EntitySchema = z.object({
  id: z.string(),
  name: z.string().min(1, { message: "Entity name is required" }),
  ownerSalutation: z.string().min(1, { message: "Title is required" }),
  ownerFirstName: z.string().min(1, { message: "First name is required" }),
  ownerMiddleName: z.string().optional(),
  ownerLastName: z.string().min(1, { message: "Last name is required" }),
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .email({ message: "Must be a valid email" }),

  phoneCode: z.string().min(1, { message: "Phone Code is required" }),
  phone: z.string().superRefine((val, ctx) => {
    //
    if (!val || val.trim() === "") {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please enter Phone`,
        fatal: true,
      });
      return z.NEVER;
    }
    if (!isValidPhoneNumber(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        fatal: true,
        message: "Invalid phone number format",
      });

      return z.NEVER;
    }

    if (!isPossiblePhoneNumber(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Invalid phone number length`,
        fatal: true,
      });
      return z.NEVER;
    }
  }),
  timezone: z.string().optional(),
  address: EntityAddressSchema,
  customTheme: CustomThemeSchema,
});
export type EntitySchemaType = z.infer<typeof EntitySchema>;

function EntityInformation(props: {
  entityId: string;
  setEntityTimeZone: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [entity, setEntity] = React.useState<Entity | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [submitted, setSubmitted] = React.useState(false);

  const [apiError, setApiError] = React.useState("");
  const [phoneNumber, setPhoneNumber] = useState("91");
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState("91");

  const [success, setSuccess] = React.useState<string | null>(null);

  const [formData, setFormData] = useState({}); // Store form data here
  const [isEditable, setIsEditable] = React.useState(false);
  const handlePhoneNumberChange = (value: E164Number | undefined) => {
    setValue("phone", value?.toString() ?? "");
  };

  useEffect(() => {
    setIsEditable(false);

    const getEntityData = async () => {
      try {
        const paramsMap = new Map<string, string>([
          ["entityId", props.entityId],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.entity.view,
          paramsMap
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data["data"];

        setPhoneNumber(
          data.phoneCode.toString().toString() +
            data.phone.toString().toString()
        );

        const convertedEntity = Convert.toEntity(JSON.stringify(data));
        setEntity(convertedEntity);

        props.setEntityTimeZone(convertedEntity.timezone ?? "");
        if (convertedEntity.logoUrl === "") {
          setSelectedImage("");
        } else {
          setSelectedImage(convertedEntity?.logoUrl ?? "");
        }

        //Todo: Set rgba colors
        setPrimaryColor(
          convertedEntity?.customTheme?.colorScheme?.primaryColor === "" ||
            convertedEntity.customTheme?.colorScheme?.primaryColor === undefined
            ? "#4b23c2"
            : convertedEntity?.customTheme?.colorScheme?.primaryColor
        );
        setSecondaryColor(
          convertedEntity?.customTheme?.colorScheme?.secondaryColor === "" ||
            convertedEntity.customTheme?.colorScheme?.secondaryColor ===
              undefined
            ? "#2B79DD"
            : convertedEntity?.customTheme?.colorScheme?.secondaryColor
        );
        setTertiaryColor(
          convertedEntity?.customTheme?.colorScheme?.tertiaryColor === "" ||
            convertedEntity.customTheme?.colorScheme?.tertiaryColor ===
              undefined
            ? "#B35297"
            : convertedEntity?.customTheme?.colorScheme?.tertiaryColor
        );
        // setPrimaryColor(convertedEntity.primaryColor);
        // setSecondaryColor(convertedEntity.secondaryColor);
        // setTertiaryColor(convertedEntity.tertiaryColor);

        const phoneNumberDataString = convertPhoneNumberAndCodeToString({
          phoneNumber: convertedEntity.phone ?? 0,
          phoneCode: convertedEntity.phoneCode ?? 0,
        });

        reset({
          ...convertedEntity,
          phone: phoneNumberDataString.phoneNumber,
          phoneCode: phoneNumberDataString.phoneCode,
          customTheme: {
            colorScheme: {
              primaryColor:
                convertedEntity?.customTheme?.colorScheme?.primaryColor ===
                  "" ||
                convertedEntity.customTheme?.colorScheme?.primaryColor ===
                  undefined
                  ? "#4b23c2"
                  : convertedEntity?.customTheme?.colorScheme?.primaryColor,
              secondaryColor:
                convertedEntity?.customTheme?.colorScheme?.secondaryColor ===
                  "" ||
                convertedEntity.customTheme?.colorScheme?.secondaryColor ===
                  undefined
                  ? "#2B79DD"
                  : convertedEntity?.customTheme?.colorScheme?.secondaryColor,
              tertiaryColor:
                convertedEntity?.customTheme?.colorScheme?.tertiaryColor ===
                  "" ||
                convertedEntity.customTheme?.colorScheme?.tertiaryColor ===
                  undefined
                  ? "#B35297"
                  : convertedEntity?.customTheme?.colorScheme?.tertiaryColor,
            },
          },
        });
        setIsEditable(false);

        setLoading(false);
      } catch (error) {
        toast.error(error.message);

        console.error("Error fetching entity data:", error);

        setLoading(false);
      }
    };
    getEntityData();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<EntitySchemaType>({
    resolver: zodResolver(EntitySchema),
  });

  const handleToggleEdit = () => {
    setIsEditable((prevState) => !prevState);
  };
  const axios = useAxios();
  const [selectedImage, setSelectedImage] = React.useState<string>("");

  const onSubmit: SubmitHandler<EntitySchemaType> = async (data) => {
    setLoading(true);
    try {
      const combinedData = { ...formData, ...data };
      const phoneData = convertPhoneNumberAndCodeToNumber({
        phoneNumber: combinedData.phone,
        phoneCode: combinedData.phoneCode,
      });

      combinedData.customTheme.colorScheme.primaryColor = primaryColor;
      combinedData.customTheme.colorScheme.secondaryColor = secondaryColor;
      combinedData.customTheme.colorScheme.tertiaryColor = tertiaryColor;

      EntitySchema.parse(combinedData);

      const paramsMap = new Map<string, string>([["id", combinedData.id]]);
      const filteredEndpoint = replaceParams(
        endpoints.entity.update,
        paramsMap
      );
      const response = await axios.put(filteredEndpoint, {
        ...combinedData,
        phone: phoneData.phoneNumber,
        phoneCode: phoneData.phoneCode,
      });
      if (response.data.status === 200)
        toast.success(
          response.data.message === "success"
            ? "Entity Information updated successfully"
            : response.data.message
        );

      setLoading(false);
      setIsEditable(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiError(error.response.data["data"].toString());

      setLoading(false);
      setIsEditable(true);
    }
  };

  function checkDuplicateEmail() {
    var email = watch("email");
    if (entity?.email !== email && email.trim() !== "") {
      if (email) {
        const paramsMap = new Map<string, string>([["email", watch("email")]]);
        const filteredEndpoint = replaceParams(
          endpoints.staff.checkStaffEmail,
          paramsMap
        );
        axios
          .get(filteredEndpoint)
          .then((res) => {
            clearErrors("email");
          })
          .catch((error) => {
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.status === 500
            ) {
              setError("email", {
                type: "custom",
                message:
                  "Account with this email already exists. Please contact support.",
              });
              setValue("email", "");
            } else {
              toast.error(error.message);
              setValue("email", "");
            }
          });
      }
    }
  }

  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [tertiaryColor, setTertiaryColor] = useState("");
  const userData = getUserPersistedOnLocalStorage();

  function hexToRgba(name: string, hex: string) {
    // Remove '#' if present
    // hex = hex.replace("#", "");

    // const bigint = parseInt(hex, 16);
    // const r = (bigint >> 16) & 255;
    // const g = (bigint >> 8) & 255;
    // const b = bigint & 255;

    if (name === "customTheme.colorScheme.primaryColor") {
      // setPrimaryColor(`rgba(${r}, ${g}, ${b}, 1)`);
      setPrimaryColor(`${hex}`);
    } else if (name === "customTheme.colorScheme.secondaryColor") {
      // setSecondaryColor(`rgba(${r}, ${g}, ${b}, 1)`);
      setSecondaryColor(`${hex}`);
    } else if (name === "customTheme.colorScheme.tertiaryColor") {
      // setTertiaryColor(`rgba(${r}, ${g}, ${b}, 1)`);
      setTertiaryColor(`${hex}`);
    }
  }

  // function rgbaToHex(rgba: string): string {
  //   const match = rgba.match(/\d+/g);
  //   if (!match || match.length !== 4) {
  //     console.error("Invalid RGBA format");
  //     return "#ffffff";
  //   }

  //   const r = parseInt(match[0]);
  //   const g = parseInt(match[1]);
  //   const b = parseInt(match[2]);
  //   const a = parseFloat(match[3]);

  //   if (a < 0 || a > 1) {
  //     console.error("Alpha value must be between 0 and 1");
  //     return "#ffffff";
  //   }

  //   const hex = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  //   return "#" + hex;
  // }

  return (
    <>
      {loading && <p>Loading...</p>}

      {!loading && (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="flex flex-col w-full space-y-4 items-center md:items-start mt-2">
            <DummyProfilePictureContainer
              getPatientData={async () => {}}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              entityId={entity?.id ?? ""}
            />

            <div className="flex flex-col w-full md:w-2/3 pr-2">
              <Applabel label="Timezone" mandatory />
              <select
                {...register("timezone")}
                value={watch("timezone")}
                disabled={!isEditable}
                placeholder="Timezone"
                className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
              >
                {TimezoneData.timezones.map(
                  (timezone: Timezone, index: number) =>
                    timezone.utc.map((tz) => (
                      <option key={tz} value={tz}>
                        {tz} -{timezone.text}
                      </option>
                    ))
                )}
              </select>
            </div>
            {/* Entity Name */}
            <div className="flex flex-col w-full md:w-2/3 pr-2">
              <Applabel label="Entity Name" mandatory />

              <input
                type="text"
                key="name"
                {...register("name")}
                value={watch("name")}
                disabled={!isEditable}
                placeholder="Entity Name"
                className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
              />
              {errors.name && (
                <ValidationError
                  message={errors.name?.message?.toString() ?? ""}
                />
              )}
            </div>

            {/* Entity Owner */}
            <div className="flex flex-col md:flex-row w-full justify-start items-start mt-0 md:space-x-4">
              <div className="flex flex-col w-full md:w-1/3">
                <div className="flex flex-row w-full justify-start space-x-2">
                  <div className="flex flex-col ">
                    <Applabel label="Title" mandatory />
                    <select
                      {...register("ownerSalutation")}
                      placeholder="Salutation"
                      className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                      disabled={!isEditable}
                      value={watch("ownerSalutation")}
                    >
                      <option key="" value="">
                        Select
                      </option>
                      {[
                        "Mr",
                        "Mrs",
                        "Ms",
                        "Miss",
                        "Dr",
                        "Prof",
                        "Rev",
                        "Hon",
                        "Capt",
                      ].map((salutation) => {
                        return (
                          <option key={salutation} value={salutation}>
                            {salutation}
                          </option>
                        );
                      })}
                    </select>
                    {errors.ownerSalutation && (
                      <ValidationError
                        message={
                          errors.ownerSalutation?.message?.toString() ?? ""
                        }
                      />
                    )}
                  </div>

                  <div className="flex flex-col grow">
                    <Applabel label="First Name" mandatory />
                    <input
                      {...register("ownerFirstName")}
                      disabled={!isEditable}
                      placeholder="Entity Owner First Name"
                      value={watch("ownerFirstName")}
                      type="text"
                      className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                    />
                    {errors.ownerFirstName && (
                      <ValidationError
                        message={
                          errors.ownerFirstName?.message?.toString() ?? ""
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full md:w-1/3">
                <Applabel label="Middle Name" />
                <input
                  {...register("ownerMiddleName")}
                  placeholder="Entity Owner Middle Name"
                  disabled={!isEditable}
                  value={watch("ownerMiddleName")}
                  className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                />
                {errors.ownerMiddleName && (
                  <ValidationError
                    message={errors.ownerMiddleName?.message?.toString() ?? ""}
                  />
                )}
              </div>

              <div className="flex flex-col w-full md:w-1/3">
                <Applabel label="Last Name" mandatory />
                <input
                  {...register("ownerLastName")}
                  className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                  placeholder="Entity Owner Last Name"
                  disabled={!isEditable}
                  value={watch("ownerLastName")}
                />
                {errors.ownerLastName && (
                  <ValidationError
                    message={errors.ownerLastName?.message?.toString() ?? ""}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
              <div className="flex flex-col md:w-1/3">
                <Applabel label="Entity owner email" mandatory />
                <input
                  {...register("email")}
                  placeholder="Entity owner email"
                  disabled={!isEditable}
                  className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                  value={watch("email")}
                  onBlur={checkDuplicateEmail}
                  required
                />
                {errors.email && (
                  <ValidationError
                    message={errors.email?.message?.toString() ?? ""}
                  />
                )}
              </div>

              <div className="flex flex-col md:w-1/4">
                <Applabel label="Mobile Number" mandatory />
                <div className="flex flex-row">
                  <PhoneInput
                    onCountryChange={(country?: CountryCode | undefined) => {
                      if (country) {
                        const countryCode = getCountryCallingCode(country);
                        setValue("phoneCode", countryCode?.toString() ?? "");
                      }
                    }}
                    {...register("phone")}
                    disabled={!isEditable}
                    international={true}
                    defaultCountry="IN"
                    countryCallingCodeEditable={false}
                    placeholder="Enter phone number"
                    value={watch("phone")}
                    onChange={(value?: E164Number | undefined) => {
                      clearErrors("phone");
                      handlePhoneNumberChange(value);
                    }}
                    onBlur={() => {
                      trigger("phone");
                    }}
                    className="w-full"
                  />
                </div>
                {errors.phone && (
                  <ValidationError
                    message={errors.phone?.message?.toString() ?? ""}
                  />
                )}
              </div>

              <div className="flex flex-col md:w-1/3"></div>
            </div>
            {/* Entity Address */}
            <hr className="my-2" />
            <span className="flex-none text-lg font-medium w-1/6 mb-0">
              Address
            </span>
            <hr className="my-2" />
            <div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
              <div className="flex flex-col grow">
                <Applabel label="Address" mandatory />

                <input
                  {...register("address.address")}
                  placeholder="Entity Address"
                  disabled={!isEditable}
                  className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                  required
                />
                {errors.address?.address && (
                  <ValidationError
                    message={errors.address?.address?.message?.toString() ?? ""}
                  />
                )}
              </div>
              <div className="flex flex-col grow">
                <Applabel label="Postal/Zip code" mandatory />

                <input
                  {...register("address.postalCode")}
                  placeholder="Entity Zip Code"
                  disabled={!isEditable}
                  className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                  required
                  value={watch("address.postalCode")}
                />
                {errors.address?.postalCode && (
                  <ValidationError
                    message={
                      errors.address.postalCode?.message?.toString() ?? ""
                    }
                  />
                )}
              </div>
            </div>

            <CountryInputSelect
              isDisabled={!isEditable}
              register={register}
              setValue={setValue}
              watch={watch}
              country="address.country"
              state="address.state"
              city="address.city"
            />

            {userData?.user_role === "PLATFORM_ADMIN" ? (
              <div className="flex flex-col md:flex-row w-full justify-start items-start mt-0 md:space-x-4">
                <div className="flex flex-col w-full md:w-1/3">
                  <div className="flex flex-row w-full justify-start space-x-2">
                    <div className="flex flex-col grow">
                      <Applabel label="Primary color" />
                      <input
                        {...register("customTheme.colorScheme.primaryColor")}
                        name="customTheme.colorScheme.primaryColor"
                        // value={rgbaToHex(primaryColor)}
                        value={primaryColor}
                        type="color"
                        disabled={!isEditable}
                        className={`h-14 w-14`}
                        onChange={(e) => {
                          hexToRgba(e.target.name, e.target.value);
                        }}
                      />
                      <>{primaryColor}</>

                      {errors?.customTheme?.colorScheme?.primaryColor && (
                        <ValidationError
                          message={
                            errors?.customTheme?.colorScheme?.primaryColor?.message?.toString() ??
                            ""
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full md:w-1/3">
                  <Applabel label="Secondary color" />
                  <input
                    {...register("customTheme.colorScheme.secondaryColor")}
                    // value={rgbaToHex(secondaryColor)}
                    value={secondaryColor}
                    name={"customTheme.colorScheme.secondaryColor"}
                    disabled={!isEditable}
                    type="color"
                    className={`h-14 w-14`}
                    onChange={(e) => {
                      hexToRgba(e.target.name, e.target.value);
                    }}
                  />
                  <>{secondaryColor}</>
                  {errors?.customTheme?.colorScheme?.secondaryColor && (
                    <ValidationError
                      message={
                        errors.customTheme.colorScheme.secondaryColor?.message?.toString() ??
                        ""
                      }
                    />
                  )}
                </div>

                <div className="flex flex-col w-full md:w-1/3">
                  <Applabel label="Tertiary color" />
                  <input
                    {...register("customTheme.colorScheme.tertiaryColor")}
                    // value={rgbaToHex(tertiaryColor)}
                    value={tertiaryColor}
                    name="customTheme.colorScheme.tertiaryColor"
                    type="color"
                    disabled={!isEditable}
                    className={`h-14 w-14`}
                    onChange={(e) => {
                      hexToRgba(e.target.name, e.target.value);
                    }}
                  />
                  <>{tertiaryColor}</>
                  {errors?.customTheme?.colorScheme?.tertiaryColor && (
                    <ValidationError
                      message={
                        errors?.customTheme?.colorScheme?.tertiaryColor?.message?.toString() ??
                        ""
                      }
                    />
                  )}
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <div className="h-20 mt-10"></div>

          <div className="fixed items-center w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-4 md:-ml-6 lg:-ml-8 shadow bg-gray-100">
            <div className="flex flex-row ">
              <div>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  type="button"
                  className="lg:ml-3"
                  onClick={
                    isEditable ? handleSubmit(onSubmit) : handleToggleEdit
                  }
                >
                  {isEditable ? "Save" : "Edit"}
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default EntityInformation;
