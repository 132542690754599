import React, { useEffect, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import {
  SubmitErrorHandler,
  SubmitHandler,
  UseFormClearErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

export default function OutReferralSearch(props: {
  data: string[];
  setSelectedOutReferralName: React.Dispatch<React.SetStateAction<string>>;
  selectedOutReferralName: string;
  register: UseFormRegister<{
    advice?: string | undefined;
    precautions?: string | undefined;
    testadviced?: string | undefined;
    outReferral: string;
    sessionCount?: number | undefined;
    sessionFrequency?: number | undefined;
    sessionFrequencyUnit?: string | undefined;
    type?: string | undefined;
    prescribedThrapeuticProcedures?: string[] | undefined;
    shortTermGoals?: string | undefined;
    midTermGoals?: string | undefined;
    longTermGoals?: string | undefined;
  }>;
  setValue: UseFormSetValue<{
    advice?: string | undefined;
    precautions?: string | undefined;
    testadviced?: string | undefined;
    outReferral: string;
    sessionCount?: number | undefined;
    sessionFrequency?: number | undefined;
    sessionFrequencyUnit?: string | undefined;
    type?: string | undefined;
    prescribedThrapeuticProcedures?: string[] | undefined;
    shortTermGoals?: string | undefined;
    midTermGoals?: string | undefined;
    longTermGoals?: string | undefined;
  }>;
  clearErrors: UseFormClearErrors<{
    advice?: string | undefined;
    precautions?: string | undefined;
    testadviced?: string | undefined;
    outReferral: string;
    sessionCount?: number | undefined;
    sessionFrequency?: number | undefined;
    sessionFrequencyUnit?: string | undefined;
    type?: string | undefined;
    prescribedThrapeuticProcedures?: string[] | undefined;
    shortTermGoals?: string | undefined;
    midTermGoals?: string | undefined;
    longTermGoals?: string | undefined;
  }>;

  handleSubmit: UseFormHandleSubmit<
    {
      advice?: string | undefined;
      precautions?: string | undefined;
      testadviced?: string | undefined;
      outReferral: string;
      sessionCount?: number | undefined;
      sessionFrequency?: number | undefined;
      sessionFrequencyUnit?: string | undefined;
      type?: string | undefined;
      prescribedThrapeuticProcedures?: string[] | undefined;
      shortTermGoals?: string | undefined;
      midTermGoals?: string | undefined;
      longTermGoals?: string | undefined;
    },
    undefined
  >;
  onSubmit: SubmitHandler<{
    advice?: string | undefined;
    precautions?: string | undefined;
    testadviced?: string | undefined;
    outReferral: string;
    sessionCount?: number | undefined;
    sessionFrequency?: number | undefined;
    sessionFrequencyUnit?: string | undefined;
    type?: string | undefined;
    prescribedThrapeuticProcedures?: string[] | undefined;
    shortTermGoals?: string | undefined;
    midTermGoals?: string | undefined;
    longTermGoals?: string | undefined;
  }>;
}) {
  const [query, setQuery] = useState("");
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const filteredPeople =
    query === ""
      ? props.data
      : props.data.filter((doctor: string) =>
          [doctor].join(" ").toLowerCase().includes(query.toLowerCase())
        );

  useEffect(() => {}, []);

  useEffect(() => {
    if (selectedPerson != null) {
      const p: string = selectedPerson;
      props.setSelectedOutReferralName(p);
      props.setValue("outReferral", p);
      props.clearErrors("outReferral");
    }
  }, [selectedPerson]);

  useEffect(() => {
    props.setSelectedOutReferralName(query.trim().replace(/\s+/g, " "));
    props.setValue("outReferral", query.trim().replace(/\s+/g, " "));
    props.clearErrors("outReferral");
  }, [query]);

  useEffect(() => {
    if (props.selectedOutReferralName != "") {
      setQuery(props.selectedOutReferralName);
      setInputValue(props.selectedOutReferralName);
    }
  }, [props.selectedOutReferralName]);

  return (
    <Combobox
      as="div"
      value={selectedPerson}
      onChange={setSelectedPerson}
      onBlur={props.handleSubmit(props.onSubmit)}
    >
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
          onChange={(event) => {
            setInputValue(event.target.value);
            setQuery(event.target.value);
          }}
          displayValue={(person: string) => inputValue}
          autoComplete="off"
        />

        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredPeople.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map((person: string) => (
              <Combobox.Option
                // key={person.id}
                value={person}
                onClick={(event) => {
                  setInputValue(person);
                  setQuery(person);
                }}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-secondary-300 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex flex-col">
                      <span
                        className={classNames(
                          "truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {person}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
