import {
  getUserPersistedOnLocalStorage,
  getUserRoleFromString,
} from '@authentication/context/jwt/utils';
import { TokenDecodedType, UserRole } from '@authentication/types';
import { m } from 'framer-motion';
import React from 'react';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  roles?: UserRole[];
  children: React.ReactNode;
};

export default function RoleBasedGuard({
  roles,
  children,
}: RoleBasedGuardProp) {
  // Logic here to get current user role
  const user: TokenDecodedType | null = getUserPersistedOnLocalStorage();

  const currentRole = getUserRoleFromString();

  if (
    currentRole === 'unauthorized' ||
    currentRole === null ||
    currentRole === undefined ||
    (typeof roles !== 'undefined' && !roles.includes(currentRole))
  ) {
    return (
      // a forbidden page with tailwind css

      <div className="flex flex-col items-center justify-center h-full">
        <div className="text-4xl font-bold text-gray-400">403</div>
        <div className="text-2xl font-medium text-gray-400">Forbidden</div>
      </div>
    );
  }

  return <> {children} </>;
}
