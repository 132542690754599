import React from "react";
import { Oedema } from "../type/Oedema";

type OedemaProps = {
  oedemaAssessment: Oedema | null;
};

const OedemaAssessment = (props: OedemaProps) => {
  return (
    <div className="flex flex-col w-full h-full ">
      {props.oedemaAssessment?.pittingnonPitting === "" &&
      props.oedemaAssessment?.measurement === "" &&
      props.oedemaAssessment?.site === "" ? (
        <div></div>
      ) : (
        <div>
          <h3 className="mb-4 font-bold text-tertiary-600">
            Oedema Assessment
          </h3>

          <div className="">
            {props.oedemaAssessment?.pittingnonPitting === "" ? (
              <div></div>
            ) : (
              <div>
                <div className="flex flex-row justify-between w-full mt-4 bg-tertiary-100 px-2 py-1 font-medium">
                  Pitting/Non-Pitting
                </div>
                <p className="px-2 py-1">
                  {props.oedemaAssessment?.pittingnonPitting}
                </p>
              </div>
            )}

            {props.oedemaAssessment?.measurement === "" ? (
              <div></div>
            ) : (
              <div className="mt-2">
                <div className="flex flex-row justify-between w-full mt-4 bg-tertiary-100 px-2 py-1 font-medium">
                  Measurement
                </div>
                <p className="px-2 py-1">
                  {props.oedemaAssessment?.measurement}
                </p>
              </div>
            )}

            {props.oedemaAssessment?.site === "" ? (
              <div></div>
            ) : (
              <div className="mt-2">
                <div className="flex flex-row justify-between w-full mt-4 bg-tertiary-100 px-2 py-1 font-medium">
                  Site
                </div>
                <p className="px-2 py-1">{props.oedemaAssessment?.site}</p>
              </div>
            )}
          </div>
          <hr className=" my-12 border-tertiary-500"></hr>
        </div>
      )}
    </div>
  );
};

export default OedemaAssessment;
