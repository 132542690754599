import useAxios from '@routes/hooks/use-axios';
import { endpoints, replaceParams } from '@utils/axios';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { paths } from '@routes/paths';
import { ManagePatientContext } from '@pages/manage-patient/view/ManagePatient';
import moment from 'moment';
import { getUserPersistedOnLocalStorage } from '@authentication/context/jwt/utils';
import { Convert, VitalAssessment } from './types/VitalAssessment';
import { Grid } from 'gridjs-react';
import Button from '@components/button/button';
import { ButtonVariant } from '@components/button/button';
import { toast } from 'react-toastify';
import { OutlinedButton } from '@components/button';

export const VitalAssessmentSchema = z.object({
  id: z.string().optional().default(''),
  masterAssessmentId: z.string().optional().default(''),
  clientId: z.string().optional().default(''),
  entityId: z.string().optional().default(''),
  entityBranchId: z.string().optional().default(''),
  bodyTemperature: z.number().optional(),
  bmi: z.number().optional(),
  oxygenPercentage: z.number().optional(),
  bloodPressureSyst: z.number().optional(),
  bloodPressureDias: z.number().optional(),
  pulseRate: z.number().optional(),
  respiratoryRate: z.number().optional(),
  height: z.number().optional(),
  weight: z.number().optional(),
  other: z.string().optional(),
});
export type VitalAssessmentType = z.infer<typeof VitalAssessmentSchema>;

type VitalsAssessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function VitalAssessmentComponent(props: VitalsAssessmentProps) {
  const [vitalAssessment, setVitalAssessment] =
    React.useState<VitalAssessment | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [error, setError] = React.useState('');

  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);
  const userData = getUserPersistedOnLocalStorage();

  const initialize = () => {
    // set default values for the form
    return {
      id: '',
      masterAssessmentId: props.masterAssessmentId,
      clientId: '',
      entityId: '',
      entityBranchId: '',
      other: '',
      bodyTemperature: vitalAssessment?.bodyTemperature ?? 0,
      bmi: vitalAssessment?.bmi ?? 0,
      oxygenPercentage: vitalAssessment?.oxygenPercentage ?? 0,
      bloodPressureSyst: vitalAssessment?.bloodPressureSyst ?? 0,
      bloodPressureDias: vitalAssessment?.bloodPressureDias ?? 0,
      pulseRate: vitalAssessment?.pulseRate ?? 0,
      respiratoryRate: vitalAssessment?.respiratoryRate ?? 0,
      height: vitalAssessment?.height ?? 0,
      weight: vitalAssessment?.weight ?? 0,
    };
  };

  // below is the api call to get the list of patients in a react hook
  React.useEffect(() => {
    const initializeVitalAssessment = async () => {
      if (props.assessmentId !== '000000000000000000000000') {
        try {
          const paramsMap = new Map<string, string>([
            ['masterAssessmentId', props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getVitalAssessment,
            paramsMap,
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data['data'];
          const convertedVitalAssessment = Convert.toVitalAssessment(
            JSON.stringify(data),
          );
          setVitalAssessment(convertedVitalAssessment);
          methods.reset(convertedVitalAssessment);
          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          console.error('Error fetching vital assessment:', error);
          setError(error.message);
          setLoading(false);
        }
      }
    };
    initializeVitalAssessment();
  }, []);

  const methods = useForm<VitalAssessmentType>({
    resolver: zodResolver(VitalAssessmentSchema),
    defaultValues: VitalAssessmentSchema.parse(initialize()),
  });

  const onSubmit: SubmitHandler<VitalAssessmentType> = async data => {
    methods.trigger();
    if (props.assessmentId !== '000000000000000000000000') {
      try {
        VitalAssessmentSchema.parse(data);
        data.id = props.assessmentId;
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? '';
        data.entityBranchId = userData?.branch_id ?? '';
        const paramsMap = new Map<string, string>([
          ['vitalAssessmentId', props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateVitalAssessment,
          paramsMap,
        );
        const response = await axios.put(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === 'success'
              ? 'Vitals Assessment updated successfully'
              : response.data.message,
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error('Error submitting form:', error);
        setError(error.response.data['data'].toString());
      }
    } else {
      try {
        VitalAssessmentSchema.parse(data);

        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? '';
        data.entityBranchId = userData?.branch_id ?? '';
        const paramsMap = new Map<string, string>([
          ['masterAssessmentId', props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createVitalAssessment,
          paramsMap,
        );
        const response = await axios.post(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === 'success'
              ? 'Vitals Assessment created successfully'
              : response.data.message,
          );

        props.postSubmitCallback();
        //   navigateToMasterAssessment(response.data['data']);
      } catch (error) {
        toast.error(error.message);

        console.error('Error submitting form:', error);
        setError(error.response.data['data'].toString());
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Vital Assessment
        </h3>
      </div>

      <hr className="w-full" />

      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-col w-full justify-between mx-4 my-2">
            <span className="text-base leading-6 text-gray-900 mb-2">
              Body Temperature
            </span>
            <input
              type="number"
              placeholder="Body Temperature (F)"
              maxLength={3}
              className="w-full px-4 py-2 text-sm border rounded-lg focus:outline-none focus:border-blue-500"
              value={methods.watch('bodyTemperature')}
              {...methods.register('bodyTemperature', {
                required: true,
                valueAsNumber: true,
                max: 110,
                min: 90,
                maxLength: 3,
                disabled: isDisabled,
              })}
            />
          </div>
          <div className="flex flex-col w-full justify-between mx-4 my-2">
            <span className="text-base leading-6 text-gray-900 mb-2">BMI</span>
            <input
              type="number"
              placeholder="BMI"
              maxLength={3}
              value={methods.watch('bmi')}
              className="w-full px-4 py-2 text-sm border rounded-lg focus:outline-none focus:border-blue-500"
              {...methods.register('bmi', {
                required: true,
                valueAsNumber: true,
                max: 50,
                min: 10,
                maxLength: 3,
                disabled: isDisabled,
              })}
            />
          </div>
          <div className="flex flex-col w-full justify-between mx-4 my-2">
            <span className="text-base leading-6 text-gray-900 mb-2">SpO2</span>
            <input
              type="number"
              placeholder="SpO2"
              maxLength={3}
              value={methods.watch('oxygenPercentage')}
              className="w-full px-4 py-2 text-sm border rounded-lg focus:outline-none focus:border-blue-500"
              {...methods.register('oxygenPercentage', {
                required: true,
                valueAsNumber: true,
                max: 100,
                min: 50,
                maxLength: 3,
                disabled: isDisabled,
              })}
            />
          </div>
          <div className="flex flex-col w-full justify-between mx-4 my-2">
            <span className="text-base leading-6 text-gray-900 mb-2">
              Blood Pressure - Systolic
            </span>
            <input
              type="number"
              placeholder="Blood Pressure - Systolic"
              maxLength={3}
              className="w-full px-4 py-2 text-sm border rounded-lg focus:outline-none focus:border-blue-500"
              {...methods.register('bloodPressureSyst', {
                required: true,
                valueAsNumber: true,
                max: 200,
                min: 50,
                maxLength: 3,
                disabled: isDisabled,
              })}
            />
          </div>
          <div className="flex flex-col w-full justify-between mx-4 my-2">
            <span className="text-base leading-6 text-gray-900 mb-2">
              Blood Pressure - Diastolic
            </span>
            <input
              type="number"
              placeholder="Blood Pressure - Diastolic"
              maxLength={3}
              className="w-full px-4 py-2 text-sm border rounded-lg focus:outline-none focus:border-blue-500"
              {...methods.register('bloodPressureDias', {
                required: true,
                valueAsNumber: true,
                max: 200,
                min: 50,
                maxLength: 3,
                disabled: isDisabled,
              })}
            />
          </div>
          <div className="flex flex-col w-full justify-between mx-4 my-2">
            <span className="text-base leading-6 text-gray-900 mb-2">
              Pulse Rate
            </span>
            <input
              type="number"
              placeholder="Pulse Rate"
              maxLength={3}
              className="w-full px-4 py-2 text-sm border rounded-lg focus:outline-none focus:border-blue-500"
              {...methods.register('pulseRate', {
                required: true,
                valueAsNumber: true,
                max: 200,
                min: 50,
                maxLength: 3,
                disabled: isDisabled,
              })}
            />
          </div>
          <div className="flex flex-col w-full justify-between mx-4 my-2">
            <span className="text-base leading-6 text-gray-900 mb-2">
              Respiratory Rate
            </span>
            <input
              type="number"
              placeholder="Respiratory Rate"
              maxLength={3}
              className="w-full px-4 py-2 text-sm border rounded-lg focus:outline-none focus:border-blue-500"
              {...methods.register('respiratoryRate', {
                required: true,
                valueAsNumber: true,
                max: 200,
                min: 50,
                maxLength: 3,
                disabled: isDisabled,
              })}
            />
          </div>
          <div className="flex flex-col w-full justify-between mx-4 my-2">
            <span className="text-base leading-6 text-gray-900 mb-2">
              Height
            </span>
            <input
              type="number"
              placeholder="Height"
              maxLength={3}
              className="w-full px-4 py-2 text-sm border rounded-lg focus:outline-none focus:border-blue-500"
              {...methods.register('height', {
                required: true,
                valueAsNumber: true,
                max: 200,
                min: 50,
                maxLength: 3,
                disabled: isDisabled,
              })}
            />
          </div>
          <div className="flex flex-col w-full justify-between mx-4 my-2">
            <span className="text-base leading-6 text-gray-900 mb-2">
              Weight
            </span>
            <input
              type="number"
              placeholder="Weight"
              maxLength={3}
              className="w-full px-4 py-2 text-sm border rounded-lg focus:outline-none focus:border-blue-500"
              {...methods.register('weight', {
                required: true,
                valueAsNumber: true,
                max: 200,
                min: 50,
                maxLength: 3,
                disabled: isDisabled,
              })}
            />
          </div>
        </div>

        {error && <p className="text-red-500">{error}</p>}

        <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
          <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
            <OutlinedButton
              type="button"
              onClick={() => {
                props.postSubmitCallback();
              }}
              className="mr-2"
              variant={ButtonVariant.PRIMARY}
            >
              Close
            </OutlinedButton>
            {isDisabled ? (
              <Button
                type="button"
                key="EditButton"
                onClick={() => {
                  setIsDisabled(false);
                }}
                variant={ButtonVariant.PRIMARY}
              >
                Edit
              </Button>
            ) : props.assessmentId !== '000000000000000000000000' ? (
              <Button
                type="submit"
                key="UpdateButton"
                onClick={() => {}}
                variant={ButtonVariant.PRIMARY}
              >
                Update
              </Button>
            ) : (
              <Button
                type="submit"
                key="CreateButton"
                onClick={() => {}}
                variant={ButtonVariant.PRIMARY}
              >
                Create
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
