// To parse this data:
//
//   import { Convert } from "./file";
//
//   const revenueByEachStaff = Convert.toRevenueByEachStaff(json);

export interface RevenueByEachStaff {
  entityuser: string;
  totalbillamount: number;
}

// Converts JSON strings to/from your types
export class RevenueByEachStaffConvert {
  public static toRevenueByEachStaff(json: string): RevenueByEachStaff[] {
    return JSON.parse(json);
  }

  public static revenueByEachStaffToJson(value: RevenueByEachStaff[]): string {
    return JSON.stringify(value);
  }
}
