import React, { useEffect, useState } from "react";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@components/ui/tabs";
import { OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/outline-button";
import ProgressTracker from "./ProgressTracker";
import PatientJournal from "./PatientJournal";
import FeedbackRating from "./FeedbackRating";

export default function PatientActivity(props: {
  handleCloseRightOffCanvas: () => void;
  patientId: string;
}) {

  return (
    <>
      <div className="sticky top-0 right-0 z-1 flex items-center justify-between mb-5 ">
        <div className="min-w-0 flex-1 ">
          <h2 className="text-xl font-bold leading-7 sm:truncate sm:text-2xl sm:tracking-tight">
            Patient Activities
          </h2>
        </div>
        <OutlinedButton
          type="button"
          onClick={props.handleCloseRightOffCanvas}
          className="mr-2"
          variant={ButtonVariant.PRIMARY}
        >
          Close
        </OutlinedButton>
      </div>
      <Tabs defaultValue="progressTracker" className="">

        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value="progressTracker">Progress Tracker</TabsTrigger>
          <TabsTrigger value="journal">Journal</TabsTrigger>
          <TabsTrigger value="feedback">Feedback</TabsTrigger>

        </TabsList>
        <TabsContent value="progressTracker">
          <ProgressTracker
            handleCloseRightOffCanvas={props.handleCloseRightOffCanvas}
            patientId={props.patientId}
            isPopup={false}
          />
        </TabsContent>
        <TabsContent value="journal">
          <PatientJournal
            handleCloseRightOffCanvas={props.handleCloseRightOffCanvas}
            patientId={props.patientId}
            isPopup={false}
          />
        </TabsContent>
        <TabsContent value="feedback">
          <FeedbackRating
            handleCloseRightOffCanvas={props.handleCloseRightOffCanvas}
            patientId={props.patientId}
            isPopup={false}
          />
        </TabsContent>
      </Tabs>
    </>
  )
}
