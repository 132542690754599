// To parse this data:
//
//   import { Convert, DiagnosisDetails } from "./file";
//
//   const diagnosisDetails = Convert.toDiagnosisDetails(json);

export type DiagnosisDetails = {
    treatmentCategory:    TreatmentCategory;
    chiefComplaint:       string;
    provisionalDiagnosis: string;
}

export type TreatmentCategory = {
    orthopedic:      boolean;
    musculoskeletal: boolean;
    geriatric:       boolean;
    womansHealth:    boolean;
    neurological:    boolean;
}

// Converts JSON strings to/from your types
export class Convert {
    public static toDiagnosisDetails(json: string): DiagnosisDetails {
        return JSON.parse(json);
    }

    public static diagnosisDetailsToJson(value: DiagnosisDetails): string {
        return JSON.stringify(value);
    }
}
