// To parse this data:
//
//   import { Convert, RevenueStatistics } from "./file";
//
//   const revenueStatistics = Convert.toRevenueStatistics(json);

export type RevenueStatistics = {
  averageRevenueByStaff?: number;
  averageRevenueByPatient?: number;
  lastMonth?: number;
  lastSixMonths?: string[];
  lastSixMonthsRevenue?: number[];
  totalPaidAmount?: number;
  totalRevenue?: number;
  totalUnPaidAmount?: number;
  totalExpenseAmount?: number;
};

// Converts JSON strings to/from your types
export default class Convert {
  public static toRevenueStatistics(json: string): RevenueStatistics {
    return JSON.parse(json);
  }

  public static revenueStatisticsToJson(value: RevenueStatistics): string {
    return JSON.stringify(value);
  }
}
