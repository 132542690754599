// To parse this data:
//
//   import { Convert, PatientList } from "./file";
//
//   const patientList = Convert.toPatientList(json);

export interface PatientList {
  id: string;
  entityId: string;
  entityBranchId: string;
  salutation: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  password: string;
  isActive: boolean;
  isOnboarded: boolean;
  phone: number;
  phoneCode: number;
  emergencyPhone: number;
  emergencyPhoneCode: number;
  birthPlace: string;
  bloodGroup: string;
  notes: string;
  address: Address;
  age: number;
  gender: string;
  dob: Date;
  medicalHistory: MedicalHistory;
  diagnosis: Diagnosis;
  reports: any[];
  audit: Audit;
  nextConsultation: string;
  consultingEntityUser: string;
  activeExerciseProgram: ActiveExerciseProgram[];
}

export interface ActiveExerciseProgram {
  id: string;
  title: string;
  description: string;
  instructions: string;
  config: Config;
}

export interface Config {
  sets: number;
  repetitions: number;
  hold: number;
  interval: number;
  side: string;
  weights: number;
}

export interface Address {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

export interface Audit {
  createdBy: null;
  lastUpdatedBy: null;
  createdAt: Date;
  lastUpdatedAt: Date;
}

export interface Diagnosis {
  treatmentCategory: TreatmentCategory;
  chiefComplaint: string;
  provisionalDiagnosis: string;
}

export interface TreatmentCategory {
  orthopedic: boolean;
  musculoskeletal: boolean;
  geriatric: boolean;
  womansHealth: boolean;
  neurological: boolean;
}

export interface MedicalHistory {
  anyHealthCondition: boolean;
  healthConditionDetails: string;
  anySurgery: boolean;
  surgeryDetails: string;
  anyPastTreatment: boolean;
  pastTreatmentDetails: string;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toPatientList(json: string): PatientList {
    return JSON.parse(json);
  }

  public static patientListToJson(value: PatientList): string {
    return JSON.stringify(value);
  }
}
