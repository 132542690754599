// To parse this data:
//
//   import { Convert, PatientsGraphData } from "./file";
//
//   const patientsGraphData = Convert.toPatientsGraphData(json);

export interface PatientsGraphData {
  months: string[];
  newClients: number[];
  recurringClients: number[];
  totalactiveClients: number[];
}

// Converts JSON strings to/from your types
export class Convertit {
  public static toPatientsGraphData(json: string): PatientsGraphData {
    return JSON.parse(json);
  }

  public static patientsGraphDataToJson(value: PatientsGraphData): string {
    return JSON.stringify(value);
  }
}
