import React from 'react';

type CountCardProps = {
  number: number | undefined;
  text: string | undefined;
  textColor: string;
};

const CountCard = (props: CountCardProps) => {
  const textClassName = `px-2 lg:text-sm md:text-sm font-bold ${props.textColor}`;
  const numberClassName = `px-2 py-1 bg-gray-100 rounded-lg font-bold ${props.textColor}`;
  return (
    <div className="flex items-center p-2 rounded-lg mb-2 grow truncate shadow-md">
      <span className={numberClassName}>{props.number}</span>
      <h6 className={textClassName}>{props.text}</h6>
    </div>
  );
};

export default CountCard;
