// To parse this data:
//
//   import { Convert, ScarDetails } from "./file";
//
//   const scarDetails = Convert.toScarDetails(json);

export interface ScarDetails {
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;
  assessmentDetails: AssessmentDetail[];
}

export interface AssessmentDetail {
  name: string;
  observation: string;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toScarDetails(json: string): ScarDetails {
    return JSON.parse(json);
  }

  public static scarDetailsToJson(value: ScarDetails): string {
    return JSON.stringify(value);
  }
}
