import { endpoints, replaceParams } from "@utils/axios";
import React, { useCallback } from "react";

import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import Convert, { RevenueStatistics } from "../types/RevenueStats";
import { EntityBranchConvert, EntityBranch } from "../types/EntityBranch";
import moment from "moment";
import useAxios from "@routes/hooks/use-axios";

import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import RevenueCountCard from "@components/count-card/revenue-count-card";
import {
  AppointmentStatistics,
  Conversion,
} from "../types/AppointmentStatistics";
import { Convertit, PatientsGraphData } from "../types/PatientsGraphData";
import { toast } from "react-toastify";
import {
  RevenueByEachStaff,
  RevenueByEachStaffConvert,
} from "../types/RevenueByEachStaff";
import { MonthYearPicker } from "@utils/MonthYearPicker";
import dayjs from "dayjs";
import {
  OccupancyByEachStaff,
  OccupancyByEachStaffConvert,
} from "../types/OccupancyByEachStaff";
import {
  RatingOfEachStaff,
  RatingOfEachStaffConvert,
} from "../types/RatingOfEachStaff";

const mainChartColors = {
  borderColor: "#F3F4F6",
  labelColor: "#6B7280",
  opacityFrom: 0.45,
  opacityTo: 0,
};

const staffBySkillsOptions: ApexOptions = {
  series: [37, 28, 17, 11, 7],
  colors: ["#FF6B6B", "#4CAF50", "#3498DB", "#F39C12", "#9B59B6"],
  chart: {
    height: 420,
    width: "100%",
    type: "pie",
  },
  stroke: {
    colors: ["white"],
  },
  plotOptions: {
    pie: {
      dataLabels: {
        offset: -25,
      },
    },
  },
  labels: [
    "Orthopedic",
    "Neurological",
    "Sports",
    "Cardiopulmonary",
    "Pediatric",
  ],
  dataLabels: {
    enabled: true,
    style: {
      fontFamily: "Inter, sans-serif",
    },
  },
  legend: {
    position: "bottom",
    fontFamily: "Inter, sans-serif",
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return value + "%";
      },
    },
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        return value + "%";
      },
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
};

const patientSatisfactionOptions: ApexOptions = {
  series: [70, 25, 5],
  labels: ["Excellent", "Good", "Poor"],
  colors: ["#fdd93b", "#4d4df7", "#77aaff"],
  chart: {
    type: "donut",
    height: 400,
    fontFamily: "Inter, sans-serif",
    toolbar: {
      show: false,
    },
  },
  responsive: [
    {
      breakpoint: 430,
      options: {
        chart: {
          height: 300,
        },
      },
    },
  ],
  stroke: {
    colors: ["#ffffff"],
  },
  states: {
    hover: {
      filter: {
        type: "darken",
        value: 0.9,
      },
    },
  },
  tooltip: {
    shared: true,
    followCursor: false,
    fillSeriesColor: false,
    inverseOrder: true,
    style: {
      fontSize: "14px",
      fontFamily: "Inter, sans-serif",
    },
    x: {
      show: true,
      formatter: function (_, { seriesIndex, w }) {
        const label = w.config.labels[seriesIndex];
        return label;
      },
    },
    y: {
      formatter: function (value) {
        return value + "%";
      },
    },
  },
  grid: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
};

const staffByOcupancyOptions: ApexOptions = {
  series: [52.8, 26.8, 20.4],
  colors: ["#3498DB", "#27AE60", "#FFC300"],
  chart: {
    height: 420,
    width: "100%",
    type: "pie",
  },
  stroke: {
    colors: ["white"],
  },
  plotOptions: {
    pie: {
      dataLabels: {
        offset: -25,
      },
    },
  },
  labels: ["Full Time Employee", "Full-time Contract", "Part-time Contract"],
  dataLabels: {
    enabled: true,
    style: {
      fontFamily: "Inter, sans-serif",
    },
  },
  legend: {
    position: "bottom",
    fontFamily: "Inter, sans-serif",
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return value + "%";
      },
    },
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        return value + "%";
      },
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
};

const staffByVisitsOptions: ApexOptions = {
  series: [20, 30, 20, 40],
  colors: ["#FF6B6B", "#4CAF50", "#3498DB", "#F39C12"],
  chart: {
    height: 420,
    width: "100%",
    type: "pie",
  },
  stroke: {
    colors: ["white"],
  },
  plotOptions: {
    pie: {
      dataLabels: {
        offset: -25,
      },
    },
  },
  labels: ["Home-Visits", "In-clinic", "Remote", "Both(home + in-clinic)"],
  dataLabels: {
    enabled: true,
    style: {
      fontFamily: "Inter, sans-serif",
    },
  },
  legend: {
    position: "bottom",
    fontFamily: "Inter, sans-serif",
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return value + "%";
      },
    },
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        return value + "%";
      },
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
};

function PieChartForStaffBySkills() {
  return (
    <div className="flex flex-col justify-center p-4 bg-white border border-gray-200 rounded-lg ">
      <div className="w-full">
        <span className="text-lg font-bold leading-none text-gray-900 ">
          Staff by Specialization
        </span>
      </div>
      <div className="mt-2 w-full" id="new-products-chart">
        <ReactApexChart
          options={staffBySkillsOptions}
          series={staffBySkillsOptions.series}
          type={staffBySkillsOptions.chart?.type}
        />
      </div>
    </div>
  );
}

function PieChartForStaffByVisits() {
  return (
    <div className="flex flex-col justify-between p-4 bg-white border border-gray-200 rounded-lg ">
      <div className="w-full">
        <span className="text-lg font-bold leading-none text-gray-900 ">
          Staff by Availability
        </span>
      </div>
      <div className="mt-2 w-full" id="new-products-chart">
        <ReactApexChart
          options={staffByVisitsOptions}
          series={staffByVisitsOptions.series}
          type={staffByVisitsOptions.chart?.type}
        />
      </div>
    </div>
  );
}

function PieChartForStaffByOccupancy() {
  return (
    <div className="flex flex-col justify-between p-4 bg-white border border-gray-200 rounded-lg ">
      <div className="w-full">
        <span className="text-lg font-bold leading-none text-gray-900 ">
          Staff by Occupancy
        </span>
      </div>
      <div className="mt-2 w-full" id="new-products-chart">
        <ReactApexChart
          options={staffByOcupancyOptions}
          series={staffByOcupancyOptions.series}
          type={staffByOcupancyOptions.chart?.type}
        />
      </div>
    </div>
  );
}

function LastSixMonthsRevenue(props: {
  lastSixMonthsRevenue: number[] | undefined;
  lastSixMonths: string[] | undefined;
}) {
  const revenueOptions: ApexOptions = {
    chart: {
      type: "bar",

      fontFamily: "Inter, sans-serif",
      foreColor: mainChartColors.labelColor,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "70%",
        borderRadiusApplication: "end",
        borderRadius: 8,
      },
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
    },
    grid: {
      show: true,
      borderColor: "#000000",

      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 10,
        right: 2,
        top: 10,
      },
    },
    series: [
      {
        name: "Revenue",
        data: props.lastSixMonthsRevenue ?? [],
        color: "#6841DD",
      },
    ],

    xaxis: {
      floating: false,
      categories: props.lastSixMonths ?? [],
      labels: {
        show: true,
        style: {
          fontFamily: "Inter, sans-serif",
          cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: [mainChartColors.labelColor],
          fontSize: "14px",
          fontWeight: 500,
        },
        formatter: function (value) {
          return "₹" + value.toFixed(0);
        },
      },
    },
    legend: {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Inter, sans-serif",
      labels: {
        colors: [mainChartColors.labelColor],
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  return (
    <div className="flex flex-col justify-between p-4 bg-white border border-gray-200 rounded-lg h-full">
      <div className="w-full">
        <span className="text-lg font-bold leading-none text-gray-900 ">
          Revenue
        </span>
      </div>
      <div id="main-chart" className="h-full">
        <ReactApexChart
          options={revenueOptions}
          series={revenueOptions.series}
          type={revenueOptions.chart?.type}
          height={"100%"}
          width={"100%"}
        />
      </div>
    </div>
  );
}

function LastSixMonthsClientStatistics(props: {
  patientsGraphData: PatientsGraphData | null;
}) {
  const clientOptions: ApexOptions = {
    chart: {
      type: "area",
      fontFamily: "Inter, sans-serif",
      foreColor: mainChartColors.labelColor,
      toolbar: {
        show: false,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: mainChartColors.opacityFrom,
        opacityTo: mainChartColors.opacityTo,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
    },
    grid: {
      show: true,
      borderColor: "#000000",

      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 10,
        left: 10,
        right: 2,
      },
    },
    series: [
      {
        name: "Total Active Patients",
        data: props.patientsGraphData?.totalactiveClients ?? [],
        color: "#6841DD",
      },
      {
        name: "Repeat Patients",
        data: props.patientsGraphData?.recurringClients ?? [],
        color: "#C275AC",
      },
      {
        name: "New Patients",
        data: props.patientsGraphData?.newClients ?? [],
        color: "#6EA3E8",
      },
    ],
    markers: {
      size: 5,
      strokeColors: "#ffffff",
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    xaxis: {
      categories: props.patientsGraphData?.months ?? [],
      labels: {
        style: {
          colors: [mainChartColors.labelColor],
          fontSize: "14px",
          fontWeight: 500,
        },
      },
      axisBorder: {
        color: mainChartColors.borderColor,
      },
      axisTicks: {
        color: mainChartColors.borderColor,
      },
      crosshairs: {
        show: true,
        position: "back",
        stroke: {
          color: mainChartColors.borderColor,
          width: 1,
          dashArray: 10,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: [mainChartColors.labelColor],
          fontSize: "14px",
          fontWeight: 500,
        },
      },
    },
    legend: {
      position: "top",
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Inter, sans-serif",
      labels: {
        colors: [mainChartColors.labelColor],
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };
  return (
    <div className="flex flex-col justify-between p-4 bg-white border border-gray-200 rounded-lg h-full">
      <div className="w-full">
        <span className="text-lg font-bold leading-none text-gray-900">
          Patients Statistics
        </span>
      </div>
      <div id="main-chart" className="h-full">
        <ReactApexChart
          options={clientOptions}
          series={clientOptions.series}
          type={clientOptions.chart?.type}
          height={"100%"}
          width={"100%"}
        />
      </div>
    </div>
  );
}

function OverallPatientSatisfaction() {
  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <div className="flex items-center justify-between pb-4 mb-4 border-b border-gray-200 dark:border-gray-700">
        <div>
          <span className="text-lg font-bold leading-none text-gray-900 ">
            Overall Patient Satisfaction
          </span>
        </div>
      </div>
      <div id="traffic-by-device">
        <ReactApexChart
          options={patientSatisfactionOptions}
          series={patientSatisfactionOptions.series}
          type={patientSatisfactionOptions.chart?.type}
        />
      </div>
      <div className="flex flex-row items-center justify-between pt-4 lg:justify-evenly">
        <div className="flex flex-row mx-2 items-center">
          <span className="w-3 h-3 inline-block bg-[#fdd93b] rounded-full me-2"></span>
          <div className="flex flex-col">
            <h6 className="text-gray-500 text-sm">Excellent</h6>
            <h4 className="text-lg font-bold">7,000</h4>
          </div>
        </div>
        <div className="flex flex-row mx-2 items-center">
          <span className="w-3 h-3 inline-block bg-[#4d4df7] rounded-full me-2"></span>
          <div className="flex flex-col">
            <h6 className="text-gray-500 text-sm">Good</h6>
            <h4 className="text-lg font-bold">2,500</h4>
          </div>
        </div>
        <div className="flex flex-row mx-2 items-center">
          <span className="w-3 h-3 inline-block bg-[#77aaff] rounded-full me-2"></span>
          <div className="flex flex-col">
            <h6 className="text-gray-500 text-sm">Poor</h6>
            <h4 className="text-lg font-bold">500</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

function TopPerformerByRevenue(props: { branchId: string }) {
  const axios = useAxios();

  const [staffRevenue, setStaffRevenue] = React.useState<RevenueByEachStaff[]>(
    []
  );

  const handleDateChange = (
    selectedMonth: number,
    selectedYear: number,
    startDate: Date,
    endDate: Date
  ) => {
    getRevenueByStaff(selectedMonth, selectedYear);
  };

  const getRevenueByStaff = async (
    selectedMonth: number,
    selectedYear: number
  ) => {
    try {
      const paramsMap = new Map<string, string>([["id", props.branchId]]);
      const filteredEndpoint = replaceParams(
        endpoints.ownerDashboard.getStaffRevenue,
        paramsMap
      );

      const response = await axios.get(filteredEndpoint, {
        params: {
          reqMonth: selectedMonth,
          reqYear: selectedYear,
        },
      });
      const data = response.data["data"];

      if (data !== null) {
        const convertedList: RevenueByEachStaff[] = data.map((staff: any) => {
          let shadow = RevenueByEachStaffConvert.toRevenueByEachStaff(
            JSON.stringify(staff)
          );

          return shadow;
        });

        setStaffRevenue(convertedList);
      } else {
        setStaffRevenue([]);
      }
    } catch (error) {
      setStaffRevenue([]);
    }
  };

  React.useEffect(() => {
    const _init = async () => {
      await getRevenueByStaff(dayjs().month() + 1, dayjs().year());
    };

    if (props.branchId !== "") _init();
  }, [props.branchId]);

  return (
    <div className="w-full  p-3 bg-white border border-gray-100 rounded-lg shadow">
      <div className="flex items-center justify-between mb-4">
        <span className="mt-2 ml-2 text-lg font-bold leading-none text-gray-900 ">
          By Revenue
        </span>
      </div>
      <MonthYearPicker onChange={handleDateChange} />
      <div
        className="mt-2 flow-root h-[300px] overflow-y-auto"
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "rgb(229 228 226) white",
        }}
      >
        <ul
          role="list"
          className="divide-y divide-gray-200 dark:divide-gray-700 p-3"
        >
          {staffRevenue.map((staff) => {
            return (
              <li className="py-3 sm:py-4">
                <div className="flex items-center">
                  <div className="flex-1 min-w-0 ms-4">
                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                      {staff.entityuser}
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    ₹{staff.totalbillamount}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

function TopPerformerByOccupancy(props: { branchId: string }) {
  const axios = useAxios();

  const [staffOccupancy, setStaffOccupancy] = React.useState<
    OccupancyByEachStaff[]
  >([]);

  const handleDateChange = (
    selectedMonth: number,
    selectedYear: number,
    startDate: Date,
    endDate: Date
  ) => {
    getOccupancyByStaff(selectedMonth, selectedYear);
  };

  const getOccupancyByStaff = async (
    selectedMonth: number,
    selectedYear: number
  ) => {
    try {
      const paramsMap = new Map<string, string>([["id", props.branchId]]);
      const filteredEndpoint = replaceParams(
        endpoints.ownerDashboard.getStaffOccupancy,
        paramsMap
      );

      const response = await axios.get(filteredEndpoint, {
        params: {
          reqMonth: selectedMonth,
          reqYear: selectedYear,
        },
      });
      const data = response.data["data"];

      if (data !== null) {
        const convertedList: OccupancyByEachStaff[] = data.map((staff: any) => {
          let shadow = OccupancyByEachStaffConvert.toOccupancyByEachStaff(
            JSON.stringify(staff)
          );

          return shadow;
        });

        setStaffOccupancy(convertedList);
      } else {
        setStaffOccupancy([]);
      }
    } catch (error) {
      setStaffOccupancy([]);
    }
  };

  React.useEffect(() => {
    const _init = async () => {
      await getOccupancyByStaff(dayjs().month() + 1, dayjs().year());
    };
    if (props.branchId !== "") _init();
  }, [props.branchId]);

  return (
    <div className="w-full  p-3 bg-white border border-gray-100 rounded-lg shadow">
      <div className="flex items-center justify-between mb-4">
        <span className="mt-2 ml-2 text-lg font-bold leading-none text-gray-900 ">
          By Occupancy
        </span>
      </div>
      <MonthYearPicker onChange={handleDateChange} />
      <div
        className="mt-2 flow-root h-[300px] overflow-y-auto"
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "rgb(229 228 226) white",
        }}
      >
        <ul
          role="list"
          className="divide-y divide-gray-200 dark:divide-gray-700 p-3"
        >
          {staffOccupancy.map((staff) => {
            return (
              <li className="py-3 sm:py-4">
                <div className="flex items-center">
                  <div className="flex-1 min-w-0 ms-4">
                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                      {staff.entityuser}
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    {staff.totalappointments}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

function TopPerformerByPatientRatings(props: { branchId: string }) {
  const axios = useAxios();

  const [staffRating, setStaffRating] = React.useState<RatingOfEachStaff[]>([]);

  const handleDateChange = (
    selectedMonth: number,
    selectedYear: number,
    startDate: Date,
    endDate: Date
  ) => {
    getStaffRating(selectedMonth, selectedYear);
  };

  const getStaffRating = async (
    selectedMonth: number,
    selectedYear: number
  ) => {
    try {
      const paramsMap = new Map<string, string>([["id", props.branchId]]);
      const filteredEndpoint = replaceParams(
        endpoints.ownerDashboard.getStaffRating,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint, {
        params: {
          reqMonth: selectedMonth,
          reqYear: selectedYear,
        },
      });
      const data = response.data["data"];

      if (data !== null) {
        const convertedList: RatingOfEachStaff[] = data.map((staff: any) => {
          let shadow = RatingOfEachStaffConvert.toRatingOfEachStaff(
            JSON.stringify(staff)
          );

          return shadow;
        });

        setStaffRating(convertedList);
      } else {
        setStaffRating([]);
      }
    } catch (error) {
      setStaffRating([]);
    }
  };

  React.useEffect(() => {
    const _init = async () => {
      await getStaffRating(dayjs().month() + 1, dayjs().year());
    };

    if (props.branchId !== "") _init();
  }, [props.branchId]);

  return (
    <div className="w-full  p-3 bg-white border border-gray-100 rounded-lg shadow">
      <div className="flex items-center justify-between mb-4">
        <span className="mt-2 ml-2 text-lg font-bold leading-none text-gray-900 ">
          By Patient Ratings
        </span>
      </div>
      <MonthYearPicker onChange={handleDateChange} />
      <div
        className="mt-2 flow-root h-[300px] overflow-y-auto"
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "rgb(229 228 226) white",
        }}
      >
        <ul
          role="list"
          className="divide-y divide-gray-200 dark:divide-gray-700 p-3"
        >
          {staffRating.map((staff) => {
            const filledStars = Math.floor(staff.rating);

            const halfStar = staff.rating - filledStars >= 0.5 ? 1 : 0;
            return (
              <li className="py-3 sm:py-4">
                <div className="flex items-center">
                  <div className="flex-1 min-w-0 ms-4">
                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                      {staff.entityuser}
                    </p>
                  </div>
                  <div className="flex items-center">
                    {[...Array(filledStars)].map((_, index) => (
                      <svg
                        key={index}
                        className="w-4 h-4 text-[#FFDF00] ms-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                    ))}

                    {halfStar === 1 && (
                      <svg
                        className="w-4 h-4 text-[#FFDF00] ms-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                    )}

                    {[...Array(5 - filledStars - halfStar)].map((_, index) => (
                      <svg
                        key={index + filledStars + halfStar}
                        className="w-4 h-4 ms-1 text-gray-300 dark:text-gray-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                    ))}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

function LastSixMonthsAppointmentStatistics(props: {
  lastSixMonthsAppointmentData: AppointmentStatistics | null;
}) {
  const appointmentOptions: ApexOptions = {
    chart: {
      type: "line",
      fontFamily: "Inter, sans-serif",
      foreColor: mainChartColors.labelColor,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
    },
    grid: {
      show: true,
      borderColor: "#000000",
      position: "back",

      padding: {
        top: 10,
        left: 10,
        right: 2,
      },
    },
    series: [
      {
        name: "Cancelled Appointments",
        data: props.lastSixMonthsAppointmentData?.cancelledAppointments ?? [],
        color: "#f94d47",
        type: "line",
      },
      {
        name: "Completed Appointments",
        data: props.lastSixMonthsAppointmentData?.completedAppointments ?? [],
        color: "#7fd298",
        type: "line",
      },
      {
        name: "No Shows",
        data: props.lastSixMonthsAppointmentData?.noShowAppointments ?? [],
        color: "#f3bd71",
        type: "line",
      },
      {
        name: "Total Appointments",
        data: props.lastSixMonthsAppointmentData?.totalAppointmentsCount ?? [],
        type: "column",

        color: "#8E70E5",
      },
    ],
    markers: {
      size: 5,
      strokeColors: "#ffffff",
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    xaxis: {
      categories: props.lastSixMonthsAppointmentData?.months ?? [],
      labels: {
        style: {
          colors: [mainChartColors.labelColor],
          fontSize: "14px",
          fontWeight: 500,
        },
      },
      axisBorder: {
        color: "#9ca3af",
      },
      axisTicks: {
        color: "#9ca3af",
      },
      crosshairs: {
        show: true,
        position: "back",
        stroke: {
          color: "#9ca3af",
          width: 1,
          dashArray: 10,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: [mainChartColors.labelColor],
          fontSize: "14px",
          fontWeight: 500,
        },
      },
    },
    legend: {
      position: "top",
      fontSize: "14px",
      fontWeight: 500,

      fontFamily: "Inter, sans-serif",
      labels: {
        colors: [mainChartColors.labelColor],
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800 h-full w-full">
      <div className="flex items-center justify-between mb-4">
        <div className="flex-shrink-0 w-full">
          <span className="text-lg font-bold leading-none text-gray-900 w-full">
            Appointment Statistics
          </span>
        </div>
      </div>
      <div id="main-chart" className="h-full pb-4">
        <ReactApexChart
          options={appointmentOptions}
          series={appointmentOptions.series}
          type={appointmentOptions.chart?.type}
          height={"100%"}
          width={"100%"}
        />
      </div>
    </div>
  );
}

export default function StaffDashboard() {
  const axios = useAxios();

  //To get branches and set first branch as default
  const [branches, setBranches] = React.useState<EntityBranch[]>([]);
  const [selectedBranch, setSelectedBranch] = React.useState<string>("");

  //Start date & End Date. By Default it will be 6 months
  const [startDate, setStartDate] = React.useState<string>(
    moment().subtract(6, "months").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState<string>(
    moment().format("YYYY-MM-DD")
  );
  //To get Revenue data
  const [revenueStat, setRevenueStats] =
    React.useState<RevenueStatistics | null>(null);

  //To get Appointment stats
  const [lastSixMonthsAppointmentData, setLastSixMonthsAppointmentData] =
    React.useState<AppointmentStatistics | null>(null);

  //To get Patient stats
  const [patientsGraphData, setPatientsGraphData] =
    React.useState<PatientsGraphData | null>(null);

  const userData = getUserPersistedOnLocalStorage();

  const getBranches = useCallback(async () => {
    const userData = getUserPersistedOnLocalStorage();
    const paramsMap = new Map<string, string>([
      ["entityId", userData?.entity_id ?? ""],
    ]);
    const filteredEndpoint = replaceParams(
      endpoints.ownerDashboard.branches,
      paramsMap
    );
    const response = await axios.get(filteredEndpoint);
    const data = response.data["data"];
    const converted = [];

    for (const branch of data) {
      const temp = EntityBranchConvert.toEntityBranch(JSON.stringify(branch));
      converted.push(temp);
    }

    setBranches(converted);
    if (userData?.user_role === "ENTITY_OWNER") {
      setSelectedBranch("0");
    }

    if (userData?.user_role === "BRANCH_ADMIN") {
      setSelectedBranch(userData.branch_id);
    }
  }, [axios]);

  React.useEffect(() => {
    const _init = async () => {
      await getBranches();
    };

    _init();
  }, []);

  const changeDataByBranch = async () => {
    await getRevenueData();
    await getLastSixMonthsAppointmentsData();
    await getPatientsGraphData();
  };

  React.useEffect(() => {
    if (selectedBranch != "") {
      changeDataByBranch();
    }
  }, [selectedBranch, startDate, endDate]);

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = moment(new Date(e.target.value)).format("YYYY-MM-DD");

    if (moment(newStartDate).diff(moment(endDate), "days") > 0) {
      toast.error("Start Date cannot be greater than End Date");
      return;
    }

    if (moment(endDate).diff(moment(newStartDate), "months") > 12) {
      toast.error("Date range cannot exceed 12 months");
      return;
    }

    setStartDate(newStartDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = moment(new Date(e.target.value)).format("YYYY-MM-DD");

    if (moment(startDate).diff(moment(newEndDate), "days") > 0) {
      toast.error("End Date cannot be before Start Date");
      return;
    }

    if (moment(newEndDate).diff(moment(startDate), "months") > 12) {
      toast.error("Date range cannot exceed 12 months");
      return;
    }

    setEndDate(newEndDate);
  };

  const getPatientsGraphData = async () => {
    try {
      const paramsMap = new Map<string, string>([["id", selectedBranch]]);
      const response = await axios.get(
        replaceParams(endpoints.ownerDashboard.patientStats, paramsMap),
        {
          params: {
            requestedFromDate: moment(startDate, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            ),
            requestedToDate: moment(endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
          },
        }
      );
      const data = response.data["data"];

      const converted = Convertit.toPatientsGraphData(JSON.stringify(data));
      setPatientsGraphData(converted);
    } catch (error) {
      console.error(error);
    }
  };

  const getRevenueData = async () => {
    try {
      const paramsMap = new Map<string, string>([["id", selectedBranch]]);
      const response = await axios.get(
        replaceParams(endpoints.ownerDashboard.revenueCount, paramsMap),
        {
          params: {
            requestedFromDate: moment(startDate, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            ),
            requestedToDate: moment(endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
          },
        }
      );
      const data = response.data["data"];

      const converted = Convert.toRevenueStatistics(JSON.stringify(data));
      const revenueStats = converted;
      setRevenueStats(revenueStats);
    } catch (error) {}
  };

  const getLastSixMonthsAppointmentsData = async () => {
    try {
      const paramsMap = new Map<string, string>([["id", selectedBranch]]);
      const response = await axios.get(
        replaceParams(endpoints.ownerDashboard.appointmentsCountv2, paramsMap),
        {
          params: {
            requestedFromDate: moment(startDate, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            ),
            requestedToDate: moment(endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
          },
        }
      );
      const data = response.data["data"];
      const converted = Conversion.toAppointmentStatistics(
        JSON.stringify(data)
      );
      setLastSixMonthsAppointmentData(converted);
    } catch (error) {}
  };

  const handleSelectChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedBranchId = event.target.value;
    setSelectedBranch(selectedBranchId);
  };

  return (
    <div className="px-2 pt-2">
      <div className="flex flex-col md:flex-row justify-end mb-4 w-full">
        {/* <div className="flex flex-col md:flex-row">
          <div className="flex flex-col md:mr-2">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Start Date
            </label>
            <input
              type="date"
              value={startDate}
              className="flex lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              onChange={handleStartDateChange}
            />
          </div>
          <div className="flex flex-col">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              End Date
            </label>
            <input
              type="date"
              value={endDate}
              className="flex lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              onChange={handleEndDateChange}
            />
          </div>
        </div> */}
        {userData?.user_role === "ENTITY_OWNER" && (
          <div className="flex flex-col md:w-1/5">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Branch
            </label>
            <select
              className="border border-1 rounded-md"
              disabled={false}
              onChange={handleSelectChange}
            >
              <option key={"All"} value={"0"}>
                All
              </option>
              {branches.map((branch) => (
                <option key={branch.id} value={branch.id}>
                  {branch.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      {/* 
      <div className="flex flex-col w-full h-full mt-2">
        <div className="h-[400px] mb-4">
          <LastSixMonthsClientStatistics
            patientsGraphData={patientsGraphData}
          />
        </div>
        <div className="h-[400px] mb-4">
          <LastSixMonthsRevenue
            lastSixMonthsRevenue={revenueStat?.lastSixMonthsRevenue}
            lastSixMonths={revenueStat?.lastSixMonths}
          />
        </div>
        <div className="h-[400px] mb-4">
          <LastSixMonthsAppointmentStatistics
            lastSixMonthsAppointmentData={lastSixMonthsAppointmentData}
          />
        </div>
      </div> */}
      {/* <p className="mt-4 text-xl font-bold text-secondary-600 ">
        Patient Statistics
      </p> */}
      {/* <hr className="my-2 "></hr> */}
      {/* <div className="flex flex-col mt-4">
        <OverallPatientSatisfaction />
      </div>
      <p className="mt-4 text-xl font-bold text-secondary-600 ">
        Staff Statistics
      </p>
      <hr className="my-2"></hr>
      <div className="grid w-full md:grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
        <PieChartForStaffBySkills />
        <PieChartForStaffByVisits />
        <PieChartForStaffByOccupancy />
      </div> */}
      <p className="mt-4 text-xl font-bold text-secondary-600 ">
        Top Staff Performers
      </p>
      <hr className="my-2"></hr>
      <div className="grid w-full md:grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
        <TopPerformerByRevenue branchId={selectedBranch} />
        <TopPerformerByOccupancy branchId={selectedBranch} />
        <TopPerformerByPatientRatings branchId={selectedBranch} />
      </div>
    </div>
  );
}
