// To parse this data:
//
//   import { Convert, PainAssessment } from "./file";
//
//   const painAssessment = Convert.toPainAssessment(json);

export interface PainAssessment {
  dailyTravelKm: number;
  id: string;
  masterAssessmentId: string;
  clientId: string;
  entityId: string;
  entityBranchId: string;

  onset: number;
  progression: string;
  painIntensity: number;
  painSite: string;
  painSide: string;

  constantIntermittentLeft: boolean;
  constantIntermittentRight: boolean;
  sharpLeft: boolean;
  sharpRight: boolean;
  shootingLeft: boolean;
  shootingRight: boolean;
  burningLeft: boolean;
  burningRight: boolean;
  throbbingLeft: boolean;
  throbbingRight: boolean;
  numbnessLeft: boolean;
  numbnessRight: boolean;
  pinsAndNeedlesLeft: boolean;
  pinsAndNeedlesRight: boolean;
  aggravatingFactors: string;
  relievingFactors: string;
  activityLimitation: string;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toPainAssessment(json: string): PainAssessment {
    return JSON.parse(json);
  }

  public static painAssessmentToJson(value: PainAssessment): string {
    return JSON.stringify(value);
  }
}
