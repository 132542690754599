import { Button, OutlinedButton } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import { Divider } from '@components/divider';
import { ManagePatientContext } from '@pages/manage-patient/view/ManagePatient';
import useAxios from '@routes/hooks/use-axios';
import { replaceParams, endpoints } from '@utils/axios';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import {
  Convert,
  MasterAssessmentLedger,
} from '../types/MasterAssessmentLedger';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faA, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { paths } from '@routes/paths';
import RightOffcanvas from '@components/right-offcanvas/right-offcanvas';
import VitalAssessmentComponent from '@pages/vitals-assessment/VitalsAssessment';
import GaitAssessmentComponent from '@pages/gait-assesment/GaitAssessment';
import RangeOfMotionAssessmentComponent from '@pages/range-of-motion-assessment/RangeOfMotionAssessment';
import MuscleToneAssessmentComponent from '@pages/muscle-tone-assessment/MuscleToneAssessment';
import PostureAssessmentComponent from '@pages/posture-assessment/PostureAssessment';
import PainAssessmentComponent from '@pages/pain-assessment/PainAssessment';

function isAssessmentComplete(
  masterAssessment: MasterAssessmentLedger,
  assessmentName: string,
) {
  switch (assessmentName) {
    case 'Vitals':
      return (
        masterAssessment.vitalAssessmentId !== null &&
        masterAssessment.vitalAssessmentId !== undefined &&
        masterAssessment.vitalAssessmentId !== '000000000000000000000000'
      );
    case 'Pain':
      return (
        masterAssessment.painAssessmentId !== null &&
        masterAssessment.painAssessmentId !== undefined &&
        masterAssessment.painAssessmentId !== '000000000000000000000000'
      );
    case 'Posture':
      return (
        masterAssessment.postureAssessmentId !== null &&
        masterAssessment.postureAssessmentId !== undefined &&
        masterAssessment.postureAssessmentId !== '000000000000000000000000'
      );
    case 'ROM':
      return (
        masterAssessment.rangeOfMotionAssessmentId !== null &&
        masterAssessment.rangeOfMotionAssessmentId !== undefined &&
        masterAssessment.rangeOfMotionAssessmentId !==
          '000000000000000000000000'
      );
    case 'MuscleTone':
      return (
        masterAssessment.muscleToneAssessmentId !== null &&
        masterAssessment.muscleToneAssessmentId !== undefined &&
        masterAssessment.muscleToneAssessmentId !== '000000000000000000000000'
      );
    case 'GAIT':
      return (
        masterAssessment.gaitAssessmentId !== null &&
        masterAssessment.gaitAssessmentId !== undefined &&
        masterAssessment.gaitAssessmentId !== '000000000000000000000000'
      );
    default:
      return false;
  }
}

const AssessmentComponents = {
  Vitals: VitalAssessmentComponent,
  GAIT: GaitAssessmentComponent,
  Pain: PainAssessmentComponent,
  Posture: PostureAssessmentComponent,

  ROM: RangeOfMotionAssessmentComponent,

  MuscleTone: MuscleToneAssessmentComponent,
};

type AssessmentKey = keyof typeof AssessmentComponents;

export default function ViewMasterAssessment() {
  const [masterAssessmentLedger, setMasterAssessmentLedger] =
    React.useState<MasterAssessmentLedger | null>(null);
  const [loading, setLoading] = React.useState(true);

  const [error, setError] = React.useState('');

  const [assessmentIndex, setAssessmentIndex] =
    React.useState<AssessmentKey>('Vitals');
  const axios = useAxios();
  let { patientId, masterAssessmentId } = useParams();

  const [isRightOffcanvasOpen, setIsRightOffcanvasOpen] = React.useState(false);

  const OffcanvasContentComponent = AssessmentComponents[assessmentIndex];

  const handleOpenRightOffCanvas = (assementName: AssessmentKey) => {
    setAssessmentIndex(assementName);
    setIsRightOffcanvasOpen(true);
  };

  const handleCloseRightOffCanvas = () => {
    setIsRightOffcanvasOpen(false);
  };

  const navigate = useNavigate();

  const AssessmentCards = [
    'Vitals',
    'Pain',
    'Posture',
    'ROM',
    'MuscleTone',
    'GAIT',
  ] as const;
  const fetchMasterAssessmentData = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ['masterAssessmentId', masterAssessmentId!],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.assessment.getMasterAssessment,
        paramsMap,
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data['data'];

      const convertedMasterAssessmentLedger = Convert.toMasterAssessmentLedger(
        JSON.stringify(data),
      );
      setMasterAssessmentLedger(convertedMasterAssessmentLedger);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching master assessment data:', error);
      setError(error.message);
      setLoading(false);
    }
  };

  const getActiveAssessmentId = (index: AssessmentKey) => {
    switch (index) {
      case 'Vitals':
        return masterAssessmentLedger?.vitalAssessmentId;

      case 'Pain':
        return masterAssessmentLedger?.painAssessmentId;

      case 'GAIT':
        return masterAssessmentLedger?.gaitAssessmentId;

      case 'MuscleTone':
        return masterAssessmentLedger?.muscleToneAssessmentId;

      case 'Posture':
        return masterAssessmentLedger?.postureAssessmentId;

      case 'ROM':
        return masterAssessmentLedger?.rangeOfMotionAssessmentId;

      default:
        return '';
    }
  };

  useEffect(() => {
    fetchMasterAssessmentData();
  }, []);

  const navigateToMasterAssessment = () => {
    const paramsMap = new Map<string, string>([['patientId', patientId!]]);
    navigate({
      pathname: replaceParams(paths.patient.manage.route, paramsMap),
      search: `?tab=Assessment%20History`,
    });
  };

  const postSubmitCallback = () => {
    fetchMasterAssessmentData();
    handleCloseRightOffCanvas();
  };

  return (
    <>
      <RightOffcanvas
        isOpen={isRightOffcanvasOpen}
        onClose={handleCloseRightOffCanvas}
        sizeClasses="w-full md:w-[100vh]"
      >
        <OffcanvasContentComponent
          masterAssessmentId={masterAssessmentId!}
          assessmentId={getActiveAssessmentId(assessmentIndex)!}
          patientId={patientId!}
          postSubmitCallback={postSubmitCallback}
        />
      </RightOffcanvas>

      <div className="sticky top-0 right-0 z-10 md:flex md:items-center md:justify-between -m-8 h-20 p-2 shadow bg-gray-100">
        {/* A back button and title */}
        <div className="flex flex-row items-center justify-between">
          <button
            className="w-10 h-10 p-2 mr-2 text-gray-500 rounded-full hover:bg-gray-200"
            onClick={navigateToMasterAssessment}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <h2 className="text-lg font-bold leading-7 sm:truncate sm:text-xl sm:tracking-tight">
            Assessment
          </h2>
        </div>
      </div>

      <Divider />

      <div className="relative pb-5 mt-16 sm:pb-0">
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {!loading && !error && masterAssessmentLedger && (
          // a grid of 3 columns on desktop, 2 columns on tablet, and 1 column on mobile
          <ul className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 w-full">
            {AssessmentCards.map(assessmentName => (
              <li
                key={assessmentName}
                className="col-span-1 bg-white rounded-2xl shadow"
              >
                <div className="flex flex-col justify-between h-full p-4">
                  <div className="flex flex-col">
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {assessmentName}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-sm text-gray-500 truncate">
                        {moment(masterAssessmentLedger.assessmentDate).format(
                          'MMMM Do YYYY',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm text-gray-500 truncate">
                      {isAssessmentComplete(
                        masterAssessmentLedger,
                        assessmentName,
                      )
                        ? 'Completed'
                        : 'Incomplete'}
                    </p>
                    {isAssessmentComplete(
                      masterAssessmentLedger,
                      assessmentName,
                    ) ? (
                      <OutlinedButton
                        variant={ButtonVariant.TERTIARY}
                        type="button"
                        className="mt-2"
                        onClick={() => handleOpenRightOffCanvas(assessmentName)}
                      >
                        View
                      </OutlinedButton>
                    ) : (
                      <Button
                        variant={ButtonVariant.PRIMARY}
                        type="button"
                        className="mt-2"
                        onClick={() => handleOpenRightOffCanvas(assessmentName)}
                      >
                        Start
                      </Button>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}
