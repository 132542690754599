import { endpoints, replaceParams } from '@utils/axios';
import axios from 'axios';
import React, { Fragment } from 'react';
import { Convert } from '../types/Consent';
import { ContentState, Editor, EditorState, convertFromHTML } from 'draft-js';
import { Button } from '@components/button';
import { ButtonVariant } from '@components/button/button';
import RichTextEditor from '../components/RichTextEditor';

interface IProps {
  entityId: string;
}

const ConsentComponent: React.FC<IProps> = ({ entityId }) => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(),
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [isConsentLoaded, setIsConsentLoaded] = React.useState(false);

  const getClientConsent = async () => {
    try {
      const paramsMap = new Map<string, string>([['id', entityId]]);
      const filteredEndpoint = replaceParams(
        endpoints.entity.fetchClientConsent,
        paramsMap,
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data['data'];
      const converted = Convert.toConsent(JSON.stringify(data));
      const blocksFromHTML = convertFromHTML(converted.content);
      const initialContentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      setEditorState(EditorState.createWithContent(initialContentState));
      setIsConsentLoaded(true);
    } catch (error) {
      console.error('Error fetching client consent:', error);
    }
  };

  React.useEffect(() => {
    getClientConsent();
  }, []);

  function ReadOnlyEditor() {
    return (
      <div className="h-screen">
        <Editor readOnly={true} editorState={editorState} onChange={() => {}} />
      </div>
    );
  }

  return (
    <>
      <h4 className="my-2">Consent</h4>
      {isConsentLoaded ? (
        editorState.getCurrentContent().hasText() ? (
          <ReadOnlyEditor></ReadOnlyEditor>
        ) : (
          <div className="grid border border-gray-300 h-96 rounded-md">
            <div className="place-self-center flex flex-col justify-center items-center">
              {' '}
              <p>
                No Consent provided. Please add consent by clicking on Add
                Consent
              </p>
              <Button
                className="w-32"
                variant={ButtonVariant.PRIMARY}
                type="submit"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Add Consent
              </Button>
            </div>
          </div>
        )
      ) : (
        <div>Loading...</div>
      )}

      <div className="fixed items-start w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-8 shadow bg-gray-100">
        <Button
          className="ml-3"
          variant={ButtonVariant.PRIMARY}
          type="submit"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Edit
        </Button>
      </div>
      <RichTextEditor
        entityId={entityId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        getClientConsent={getClientConsent}
        initialEditorState={editorState}
      ></RichTextEditor>
    </>
  );
};

export default ConsentComponent;
