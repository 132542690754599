import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './fab-button.css';

enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

const VARIANT_MAPS: Record<ButtonVariant, string> = {
  [ButtonVariant.PRIMARY]:
    'bg-primary-600  hover:bg-primary-500 focus:ring-primary-500 focus:ring-offset-primary-200 text-white shadow-sm',
  [ButtonVariant.SECONDARY]:
    'bg-secondary-600 hover:bg-secondary-500 focus:ring-secondary-500 focus:ring-offset-secondary-200 text-white shadow-sm',
  [ButtonVariant.TERTIARY]:
    'bg-tertiary-600 hover:bg-tertiary-500 focus:ring-tertiary-500 focus:ring-offset-tertiary-200 text-white shadow-sm',
};

type FabButtonProps = {
  children: React.ReactNode;
  variant: ButtonVariant;
  className?: string;
  onClick?: () => void;
  otherProps?: any;
};

export default class FabButton extends Component<FabButtonProps> {
  render() {
    const bgClass = VARIANT_MAPS[this.props.variant];
    const className =
      `fixed m-2 bottom-4 right-4 rounded-xl shadow-md ${bgClass} ${this.props.className}`.trim();
    const onClick = this.props.onClick;
    const children = this.props.children;

    return (
      <button className={className} onClick={onClick}>
        {children}
      </button>
    );
  }
}
