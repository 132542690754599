import { lazy } from 'react';
import { Outlet, Route } from 'react-router-dom';
import { GeneralLayout } from '@layouts/general';
import React from 'react';
import { paths } from '@routes/paths';

// ----------------------------------------------------------------------

const LoginPage = lazy(() => import('@pages/login'));
const ForgotPasswordPage = lazy(() => import('@pages/forgot-password'));
const ResetPasswordPage = lazy(() => import('@pages/reset-password'));

// ----------------------------------------------------------------------

export const authRoutes = (
  <Route element={<GeneralLayout />}>
    <Route path={paths.auth.login} element={<LoginPage />} />
    <Route path={paths.auth.forgotPassword} element={<ForgotPasswordPage />} />
    <Route path={paths.auth.resetPassword} element={<ResetPasswordPage />} />
  </Route>
);
