import React from "react";
import { RangeOfMotionAssessment } from "../type/RangeOfMotion";

type ROMProps = {
  romAssessment: RangeOfMotionAssessment | null;
};

const RangeOfMotion = (props: ROMProps) => {
  return (
    <>
      {props.romAssessment === null ? (
        <div></div>
      ) : (
        <div className="flex flex-col ">
          <h3 className="mb-4 font-bold text-tertiary-500">Range Of Motion</h3>
          <div>
            <div className="flex flex-col">
              <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
                <div className="flex flex-col items-stretch">
                  <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white">
                    Hip
                  </div>
                  <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Name
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Left{" "}
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Right{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 ">
                          Flexion
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.hipFlexionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.hipFlexionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 ">
                          Extension
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.hipExtensionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.hipExtensionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 ">
                          Abduction
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.hipAbductionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.hipAbductionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 ">
                          Adduction
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.hipAdductionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.hipAdductionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 ">
                          Medial Rotation
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.hipMedialRotationLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.hipMedialRotationRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 ">
                          Lateral Rotation
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.hipLateralRotationLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.hipLateralRotationRight}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="flex flex-col items-stretch">
                  <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white">
                    Shoulder
                  </div>
                  <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Name
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Left{" "}
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Right{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 break-all">
                          Flexion
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.shoulderFlexionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.shoulderFlexionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 break-all">
                          Extension
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.shoulderExtensionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.shoulderExtensionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 break-all">
                          Abduction
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.shoulderAbductionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.shoulderAbductionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 break-all">
                          Adduction
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.shoulderAdductionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.shoulderAdductionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 break-all">
                          Medial Rotation
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>
                            {props.romAssessment?.shoulderMedialRotationLeft}
                          </p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>
                            {props.romAssessment?.shoulderMedialRotationRight}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300 break-all">
                          Lateral Rotation
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>
                            {props.romAssessment?.shoulderLateralRotationLeft}
                          </p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>
                            {props.romAssessment?.shoulderLateralRotationRight}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-4">
              <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
                <div className="flex flex-col items-stretch">
                  <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white ">
                    Knee
                  </div>
                  <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Name
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Left{" "}
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Right{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Flexion
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.kneeFlexionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.kneeFlexionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Extension
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.kneeExtensionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.kneeExtensionRight}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="flex flex-col items-stretch">
                  <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white ">
                    Elbow
                  </div>
                  <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Name
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Left{" "}
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Right{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Flexion
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.elbowFlexionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.elbowFlexionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Extension
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.elbowExtensionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.elbowExtensionRight}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-4">
              <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
                <div className="flex flex-col items-stretch">
                  <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white ">
                    Ankle
                  </div>
                  <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="border px-2 py-1 border-slate-300 align-center">
                          Name
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Left{" "}
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Right{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Dorsi Flexion
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>
                            {props.romAssessment?.ankleFootDorsiFlexionLeft}
                          </p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>
                            {props.romAssessment?.ankleFootDorsiFlexionRight}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Plantar Flexion
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>
                            {props.romAssessment?.ankleFootPlantarFlexionLeft}
                          </p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>
                            {props.romAssessment?.ankleFootPlantarFlexionRight}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Inversion
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.ankleFootInversionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.ankleFootInversionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Eversion
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.ankleFootEversionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.ankleFootEversionRight}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="flex flex-col items-stretch">
                  <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white ">
                    Fore-arm
                  </div>
                  <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Name
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Left{" "}
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Right{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Pronation
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.forearmPronationLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.forearmPronationRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Supination
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.forearmSupinationLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.forearmSupinationRight}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-4">
              <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
                <div className="flex flex-col items-stretch">
                  <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white ">
                    Neck
                  </div>
                  <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Name
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Left{" "}
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Right{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Flexion
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.neckFlexionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.neckFlexionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Extension
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.neckExtensionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.neckExtensionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Latero-Flexion R
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.neckLateroFlexionRLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.neckLateroFlexionRRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Latero-Flexion L
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.neckLateroFlexionLLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.neckLateroFlexionLRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Rotation R
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.neckRotationRLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.neckRotationRRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Rotation L
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.neckRotationLLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.neckRotationLRight}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="flex flex-col items-stretch">
                  <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white ">
                    Wrist
                  </div>
                  <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Name
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Left{" "}
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Right{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Flexion
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.wristFlexionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.wristFlexionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Extension
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.wristExtensionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.wristExtensionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Abduction
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.wristAbductionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.wristAbductionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Adduction
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.wristAdductionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.wristAdductionRight}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-4">
              <div className="flex flex-col md:flex-row lg:flex-row space-x-2">
                <div className="flex flex-col items-stretch">
                  <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white ">
                    Trunk
                  </div>
                  <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Name
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Left{" "}
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Right{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Global Flexion
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.trunkGlobalFlexionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.trunkGlobalFlexionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Thoracic Flexion
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.trunkThoracicFlexionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>
                            {props.romAssessment?.trunkThoracicFlexionRight}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Lumbar Flexion
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.trunkLumbarFlexionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.trunkLumbarFlexionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Global Extension
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.trunkGlobalExtensionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>
                            {props.romAssessment?.trunkGlobalExtensionRight}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Latero-Flexion R
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.trunkLateroFlexionRLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.trunkLateroFlexionRRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Latero-Flexion L
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.trunkLateroFlexionLLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.trunkLateroFlexionLRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Rotation R
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.trunkRotationRLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.trunkRotationRRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Rotation L
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.trunkRotationLLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.trunkRotationLRight}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="flex flex-col items-stretch">
                  <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none bg-tertiary-500 text-white ">
                    Fingers
                  </div>
                  <table className="table-fixed text-left p-2 text-lg border-collapse border border-slate-400 w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Name
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Left{" "}
                        </th>
                        <th className="border px-2 py-1 border-slate-300 text-left p-2">
                          Right{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          Thumb Opposition
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>
                            {props.romAssessment?.fingersThumboppositionLeft}
                          </p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>
                            {props.romAssessment?.fingersThumboppositionRight}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          MP Flexion
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.fingersMPFlexionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.fingersMPFlexionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          MP Extension
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.fingersMPExtensionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.fingersMPExtensionRight}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-2 py-1 border-slate-300">
                          IP Flexion
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.fingersIPFlexionLeft}</p>
                        </td>
                        <td className="border px-2 py-1 text-left p-2 border-slate-300">
                          <p>{props.romAssessment?.fingersIPFlexionRight}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <hr className=" my-12 border-tertiary-500"></hr>
        </div>
      )}
    </>
  );
};

export default RangeOfMotion;
