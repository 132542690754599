// To parse this data:
//
//   import { Convert, EntityUserDetailsWithFreeTimeSlots } from "./file";
//
//   const entityUserDetailsWithFreeTimeSlots = Convert.toEntityUserDetailsWithFreeTimeSlots(json);

export interface EntityUserDetailsWithFreeTimeSlots {
  entityUserId: string;
  entityUserName: string;
  entityUserPhone: number;
  isGoogleAuthEnabled: boolean;
  availableSlots: AvailableSlot[];
}

export interface AvailableSlot {
  date: Date;
  totalSlots: number;
  totalFreeSlots: number;
  freeslots: Freeslot[];
}

export interface Freeslot {
  startTimeMinutes: number;
  endTimeMinutes: number;
  startTime: string;
  endTime: string;
  appointmentsCount: number;
}

// Converts JSON strings to/from your types
export class EntityUserDetailsWithFreeTimeSlotsConvert {
  public static toEntityUserDetailsWithFreeTimeSlots(
    json: string,
  ): EntityUserDetailsWithFreeTimeSlots {
    return JSON.parse(json);
  }

  public static entityUserDetailsWithFreeTimeSlotsToJson(
    value: EntityUserDetailsWithFreeTimeSlots,
  ): string {
    return JSON.stringify(value);
  }
}
