export const generateForm = (masterAssessmentId: string) => {
  const myAssessment = {
    id: '',
    masterAssessmentId: masterAssessmentId,
    clientId: '',
    entityId: '',
    entityBranchId: '',
    trueLimbLengthLeft: 0,
    trueLimbLengthRight: 0,
    apparentLimbLengthLeft: 0,
    apparentLimbLengthRight: 0,
    humeralLimbLengthLeft: 0,
    humeralLimbLengthRight: 0,
    ulnarLimbLengthLeft: 0,
    ulnarLimbLengthRight: 0,
    femoralLimbLengthLeft: 0,
    femoralLimbLengthRight: 0,
    tibialLimbLengthLeft: 0,
    tibialLimbLengthRight: 0,
  };
  return myAssessment;
};
