// To parse this data:
//
//   import { Convert, MedicalHistoryDetails } from "./file";
//
//   const medicalHistoryDetails = Convert.toMedicalHistoryDetails(json);

export interface MedicalHistoryDetails {
  id: string;
  clientId: string;
  entityId: string;
  entityUserId: string;
  anyHealthCondition: boolean;
  healthConditionDetails: string;
  anySurgery: boolean;
  surgeryDetails: string;
  anyPastTreatment: boolean;
  pastTreatmentDetails: string;
  // anyMedication: boolean;
  // medicationDetails: string;
  // anyAllergies: boolean;
  // allergiesDetails: string;
  // anyFamilyHistory: boolean;
  // familyHistoryDetails: string;
  // anyOther: boolean;
  // otherDetails: string;
  // audit: Audit;
}

export interface Audit {
  createdBy: null;
  lastUpdatedBy: null;
  createdAt: Date;
  lastUpdatedAt: Date;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toMedicalHistoryDetails(json: string): MedicalHistoryDetails {
    return JSON.parse(json);
  }

  public static medicalHistoryDetailsToJson(
    value: MedicalHistoryDetails,
  ): string {
    return JSON.stringify(value);
  }
}
