// To parse this data:
//
//   import { Convert, BranchList } from "./file";
//
//   const branchList = Convert.toBranchList(json);

export interface BranchList {
  id: string;
  entityId: string;
  name: string;
  adminSalutation: string;
  adminFirstName: string;
  adminMiddleName: string;
  adminLastName: string;
  email: string;
  phone: number;
  phoneCode: number;
  address: Address;
  isActive: boolean;
  isPrimary: boolean;
  audit: Audit;
}

export interface Address {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

export interface Audit {
  createdBy: null;
  lastUpdatedBy: null;
  createdAt: Date;
  lastUpdatedAt: Date;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toBranchList(json: string): BranchList {
    return JSON.parse(json);
  }

  public static branchListToJson(value: BranchList): string {
    return JSON.stringify(value);
  }
}
