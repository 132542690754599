import React, { useEffect, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import {
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

export interface Address {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

export default function DoctorSearch(props: {
  data: string[];
  setSelectedDoctorName: React.Dispatch<React.SetStateAction<string>>;

  register: UseFormRegister<{
    salutation?: string | undefined;
    firstName: string;
    middleName?: string | undefined;
    lastName: string;
    entityId: string;
    entityBranchId: string;
    email?: string | undefined;
    isActive?: boolean | undefined;
    phone: string;
    phoneCode: string;
    emergencyPhone?: string | undefined;
    emergencyPhoneCode?: string | undefined;
    birthPlace?: string | undefined;
    bloodGroup: string;
    age: number;
    gender?: string | undefined;
    dob: string;
    sendOnboardEmail: boolean;
    whatsAppConsent: boolean;
    referringUser: string;
    referringHospital: string;
    otherReferral: string;
    address?: Address | null;
  }>;
  setValue: UseFormSetValue<{
    salutation?: string | undefined;
    firstName: string;
    middleName?: string | undefined;
    lastName: string;
    entityId: string;
    entityBranchId: string;
    email?: string | undefined;
    isActive?: boolean | undefined;
    phone: string;
    phoneCode: string;
    emergencyPhone?: string | undefined;
    emergencyPhoneCode?: string | undefined;
    birthPlace?: string | undefined;
    bloodGroup: string;
    age: number;
    gender?: string | undefined;
    dob: string;
    sendOnboardEmail: boolean;
    whatsAppConsent: boolean;
    referringUser: string;
    referringHospital: string;
    otherReferral: string;
    address?: Address | null;
  }>;
  clearErrors: UseFormClearErrors<{
    salutation: string;
    firstName: string;
    middleName?: string | undefined;
    lastName: string;
    entityId: string;
    entityBranchId: string;
    email?: string | undefined;
    isActive?: boolean | undefined;
    phone: string;
    phoneCode: string;
    emergencyPhone?: string | undefined;
    emergencyPhoneCode?: string | undefined;
    birthPlace?: string | undefined;
    bloodGroup: string;
    age: number;
    gender?: string | undefined;
    dob: string;
    sendOnboardEmail: boolean;
    whatsAppConsent: boolean;
    referringUser: string;
    referringHospital: string;
    otherReferral: string;
    address?: Address | null;
  }>;
}) {
  const [query, setQuery] = useState("");
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const filteredPeople =
    query === ""
      ? props.data
      : props.data.filter((doctor: string) =>
          [doctor].join(" ").toLowerCase().includes(query.toLowerCase())
        );

  useEffect(() => {
    if (selectedPerson != null) {
      const p: string = selectedPerson;
      props.setSelectedDoctorName(p);
      props.setValue("referringUser", p);
      props.clearErrors("referringUser");
    }
  }, [selectedPerson]);

  useEffect(() => {
    props.setSelectedDoctorName(query.trim().replace(/\s+/g, " "));
    props.setValue("referringUser", query.trim().replace(/\s+/g, " "));
    props.clearErrors("referringUser");
  }, [query]);

  useEffect(() => {}, [query]);

  return (
    <Combobox as="div" value={selectedPerson} onChange={setSelectedPerson}>
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
          onChange={(event) => {
            setInputValue(event.target.value);
            setQuery(event.target.value);
          }}
          displayValue={(person: string) => inputValue}
          autoComplete="off"
        />

        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredPeople.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map((person: string) => (
              <Combobox.Option
                // key={person.id}
                value={person}
                onClick={(event) => {
                  setInputValue(person);
                  setQuery(person);
                }}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-secondary-300 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex flex-col">
                      <span
                        className={classNames(
                          "truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {person}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
